import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { decrypt, encrypt } from '../../../utils/crypt';
import { SumOneYear, SumOneDay, ResOneYear } from '../../../utils/opDates';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
  },
  check: {
    padding: '5px',
    color: colors.primary,
  },
}));

function CreateContracts(props) {
  const { changeBreadcrump } = props;
  const classes = useStyles();
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [constracts, setConstracts] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState({ id: '', nombre: '' });
  const [loading, setLoading] = useState(false);
  const [dateValid, setDateValid] = useState({ fecha_inicio: false, vencimiento: false });
  const [oneActive, setOneActive] = useState(false);
  const [onePending, setOnePending] = useState(false);
  const [form, setForm] = useState({
    id_organizaciones: '',
    tipo_contrato_vp: '',
    vencimiento: null,
    cantidad_emisiones: 0,
    fecha_inicio: '',
    id_planes: '',
  });
  const activeDate =
    ((form.id_planes === 4 || form.id_planes === 5) && !props.match.params.id) || (!oneActive && !onePending);

  const [t] = useTranslation(['alerts', 'buttons', 'breadCrumps', 'forms']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!props.match.params.id) {
      if (form.id_planes === '') {
        setForm({
          ...form,
          cantidad_emisiones: 0,
          fecha_inicio: '',
          vencimiento: '',
        });
      } else {
        if (onePending) {
          const dataVencimiento = constracts.find(
            (item) => item.id_organizaciones === form.id_organizaciones && item.estado === 0
          ).vencimiento;
          const inicio = SumOneDay(dataVencimiento);
          const vencimiento = SumOneYear(inicio);
          setForm({
            ...form,
            cantidad_emisiones:
              form.id_planes === 4 ? 10 : plans.find((item) => item.id === form.id_planes)?.cantidad_emisiones,
            vencimiento: vencimiento,
            fecha_inicio: inicio,
          });
          setDateValid({ fecha_inicio: false, vencimiento: false });
        } else if (oneActive) {
          const dataVencimiento = constracts.find(
            (item) => item.id_organizaciones === form.id_organizaciones && item.estado === 1
          ).vencimiento;
          const inicio = SumOneDay(dataVencimiento);
          const vencimiento = SumOneYear(inicio);
          setForm({
            ...form,
            cantidad_emisiones:
              form.id_planes === 4 ? 10 : plans.find((item) => item.id === form.id_planes)?.cantidad_emisiones,
            vencimiento: vencimiento,
            fecha_inicio: inicio,
          });
          setDateValid({ fecha_inicio: false, vencimiento: false });
        } else {
          const currentDate = moment().format('YYYY-MM-DD');
          const vencimiento = SumOneYear(currentDate);
          setForm({
            ...form,
            cantidad_emisiones:
              form.id_planes === 4 ? 10 : plans.find((item) => item.id === form.id_planes)?.cantidad_emisiones,
            vencimiento: vencimiento,
            fecha_inicio: currentDate,
          });
          setDateValid({ fecha_inicio: false, vencimiento: false });
        }
      }
    } else {
      setForm({
        ...form,
        cantidad_emisiones:
          form.id_planes === 4
            ? form.cantidad_emisiones
            : form.id_planes === ''
            ? 0
            : plans.find((item) => item.id === form.id_planes)?.cantidad_emisiones,
      });
    }
    // eslint-disable-next-line
  }, [form.id_planes]);

  useEffect(() => {
    const active = constracts.find((item) => item.id_organizaciones === form.id_organizaciones && item.estado === 1);
    const pending = constracts.find((item) => item.id_organizaciones === form.id_organizaciones && item.estado === 0);

    if (active) {
      setOneActive(true);
    } else {
      setOneActive(false);
    }
    if (pending) {
      setOnePending(true);
    } else {
      setOnePending(false);
    }
    if (!props.match.params.id) {
      setForm({
        ...form,
        vencimiento: '',
        cantidad_emisiones: '',
        fecha_inicio: '',
        id_planes: '',
      });
    }
    // eslint-disable-next-line
  }, [form.id_organizaciones]);

  const loadData = async () => {
    setLoading(true);
    await getPlans();
    await getOrganizations();
    await getContracts();
    if (props.match.params.id) {
      await getContract();
      changeBreadcrump(t('breadCrumps:breadCrumps.contracts-organization-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.contracts-organization-create', { returnObjects: true }));
    }
    setLoading(false);
  };

  const getPlans = async () => {
    const { data } = await axios.get(`/others/getPlans`);
    setPlans(data.plans);
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations`);
    setOrganizations(data.organizations.rows);
  };

  const getContracts = async () => {
    const { data } = await axios.post(`/contract/getContracts`);
    setConstracts(data.contracts.rows);
  };

  const getContract = async () => {
    const contractId = decrypt(props.match.params.id);
    const { data } = await axios.get(`/contract/${contractId}`);
    setOrganization(data.contract?.organizaciones);
    setForm(data.contract);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleCancel = () => {
    history.push(`/contracts/${encrypt(form.id_organizaciones)}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleToggle();
    const numbers = /^([0-9])*$/;
    //Fecha de vencimiento del contrato activo
    const dateActive = constracts.find(
      (item) => item.id_organizaciones === form.id_organizaciones && item.estado === 1
    )?.vencimiento;

    //Fecha de vencimiento del contrato pendiente
    const datePending = constracts.find(
      (item) => item.id_organizaciones === form.id_organizaciones && item.estado === 0
    )?.vencimiento;

    //Validar si el contrato aún no inicia
    const noActive = moment().format('YYYY-MM-DD') !== form.fecha_inicio;

    if (dateValid.fecha_inicio || dateValid.vencimiento) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('forms:invalid-dates'),
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (!numbers.test(form.cantidad_emisiones)) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('forms:invalid-number-emissions'),
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (form.cantidad_emisiones === 0) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('forms:enter-number-emissions'),
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (form.id_planes === 4 && form.cantidad_emisiones < 10) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('forms:minimun-emissions'),
        showConfirmButton: false,
        timer: 2000,
      });
    } else if (!props.match.params.id && onePending && form.fecha_inicio <= datePending) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: `${t('forms:contract-on-hold')} ${moment(datePending).format('DD/MM/YYYY')}, ${t(
          'forms:date-new-contract'
        )}.`,
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (!props.match.params.id && oneActive && form.fecha_inicio <= dateActive) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: `${t('forms:contract-active')} ${moment(dateActive).format('DD/MM/YYYY')}, ${t(
          'forms:date-new-contract'
        )}.`,
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (form.fecha_inicio > form.vencimiento) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('forms:expiration-date'),
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      if (!props.match.params.id) {
        if (oneActive || onePending || noActive) {
          form.estado = 0;
        }
        axios
          .post(`/contract/`, form)
          .then((res) => {
            handleClose();
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.created'),
              showConfirmButton: false,
              timer: 1500,
            });
            history.push(`/contracts/${encrypt(form.id_organizaciones)}`);
          })
          .catch((error) => {
            handleClose();
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.created-error'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        const id = decrypt(props.match.params.id);
        axios
          .put(`/contract/${id}`, form)
          .then((res) => {
            handleClose();
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.edited'),
              showConfirmButton: false,
              timer: 1500,
            });
            history.push(`/contracts/${encrypt(form.id_organizaciones)}`);
          })
          .catch((error) => {
            handleClose();
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.edited-error'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    }
  };

  const loadFillDataPlans = () => {
    return plans.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {data.nombre} {data.id !== 4 && data.id !== 5 && `(${data.cantidad_emisiones} emisiones)`}
        </MenuItem>
      );
    });
  };

  const onlyNumber = (event) => {
    const value = String(event.target.value);
    // eslint-disable-next-line no-useless-escape
    const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;

    if (!patron.test(value)) {
      event.target.value = value.substr(value.length);
    }
  };

  const handleInputDate = (event) => {
    let actualDate = moment().format('YYYY-MM-DD');
    let currentDate = event.target.value;
    if (actualDate > currentDate) {
      setDateValid({ ...dateValid, [event.target.name]: true });
    } else {
      setDateValid({ ...dateValid, [event.target.name]: false });
    }
    if (event.target.name !== 'vencimiento') {
      const vencimiento = SumOneYear(currentDate);
      setForm({
        ...form,
        vencimiento: form.id_planes !== 4 ? vencimiento : form.vencimiento,
        [event.target.name]: event.target.value,
      });
    } else {
      const inicio = ResOneYear(currentDate);
      setForm({
        ...form,
        fecha_inicio: form.id_planes !== 4 ? inicio : form.fecha_inicio,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleOrganizations = (event, newOrganization) => {
    setOrganization(newOrganization);
    setForm({ ...form, id_organizaciones: newOrganization.id });
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <Typography align="center" component="h6" variant="h6">
            {t('forms:contracts')}
          </Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl required variant="outlined" className="w-100">
                  <Autocomplete
                    value={organization}
                    onChange={handleOrganizations}
                    options={organizations}
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField {...params} margin="normal" label={t('forms:organization')} variant="outlined" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl required variant="outlined" className="w-100">
                  <InputLabel id="modulosLabel">{t('forms:plan-type')}</InputLabel>
                  <Select
                    labelId="id_planes"
                    label={t('forms:plan-type')}
                    value={form.id_planes}
                    onChange={handleInput}
                    name="id_planes"
                  >
                    <MenuItem value="">
                      <em>{t('forms:option')}</em>
                    </MenuItem>
                    {loadFillDataPlans()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <TextField
                    label={t('forms:number-emissions')}
                    required
                    type="number"
                    name="cantidad_emisiones"
                    defaultValue={null}
                    value={form.cantidad_emisiones}
                    variant="outlined"
                    onKeyUp={onlyNumber}
                    onChange={(form.id_planes === 4 || form.id_planes === 5) && handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={5}>
                <FormControl className="w-100">
                  <TextField
                    required
                    disabled={activeDate ? false : true}
                    error={dateValid.fecha_inicio}
                    label={t('forms:start-date')}
                    type="date"
                    variant="outlined"
                    name="fecha_inicio"
                    value={form.fecha_inicio}
                    onChange={handleInputDate}
                    InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={5}>
                <FormControl className="w-100">
                  <TextField
                    disabled={activeDate ? false : true}
                    required
                    error={dateValid.vencimiento}
                    label={t('forms:expired-date')}
                    type="date"
                    variant="outlined"
                    name="vencimiento"
                    value={form.vencimiento}
                    onChange={handleInputDate}
                    InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(CreateContracts);
