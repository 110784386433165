import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  IconButton,
  Link,
  Avatar,
  Menu,
  MenuItem,
  Collapse,
} from '@material-ui/core';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Appbar from '../components/Appbar';
import colors from '../assets/styles/colors';
import axios from '../api';
import Skeleton from '@material-ui/lab/Skeleton';
import Revoked from '../assets/img/icon_revocado.png';
import Expired from '../assets/img/icon_expirado.png';
import Swal from 'sweetalert2';
import { setCarnet } from '../actions';
import { decrypt } from '../utils/crypt';
import { isBefore } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';

function CarnetView(props) {
  const { user, carnet, setCarnet, carnetId, setOpenDialog, styles } = props;
  const classes = useStyles();
  const history = useHistory();
  const [copiedLink, setCopiedLink] = useState([false, '']);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const [stateCarnet, setStateCarnet] = useState('');
  const [openPrivates, setOpenPrivates] = useState(false);
  const [openPublics, setOpenPublics] = useState(false);
  const [fieldsPrivates, setFieldsPrivates] = useState([]);
  const [fieldsPublics, setFieldsPublics] = useState([]);

  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);
  if (user === null) {
    history.push('/');
  }

  useEffect(() => {
    getCarnets().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && window.GS) {
      // eslint-disable-next-line no-undef
      GS.reset();
    }
  }, [loading]);

  const getCarnets = async () => {
    setLoading(true);
    const id = decrypt(carnetId);
    const {
      data: { data },
    } = await axios.get(`/carnets/getCarnet/${id}`);

    setCarnet({
      ...data.carnet,
      pdfCarnet: data?.pdfCarnet,
      photoOrganization: data?.photoOrganization,
      photoCarnet: data?.photoCarnet,
      resizingPhotoCarnet: data?.resizingPhotoCarnet,
    });

    setFieldsPrivates(
      data.carnet?.carnetsEmitidos?.plantillasCarnets?.carnetCamposAdicionales.filter(
        (item) => item.privacidad_vp === 50
      )
    );
    setFieldsPublics(
      data.carnet?.carnetsEmitidos?.plantillasCarnets?.carnetCamposAdicionales.filter(
        (item) => item.privacidad_vp === 49
      )
    );

    data.carnet.carnetsEmitidos?.estado === 0
      ? setStateCarnet('Revoked')
      : data.carnet.carnetsEmitidos?.fecha_expiracion !== null &&
        isBefore(new Date(data.carnet.carnetsEmitidos?.fecha_expiracion), new Date())
      ? setStateCarnet('Expired')
      : setStateCarnet('Verified');
    setLoading(false);
  };

  if (copiedLink[0] === true) {
    Swal.fire({
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
      },
      icon: 'success',
      iconColor: colors.primary,
      buttonsStyling: false,
      title: t('certificateBadgeTemplate:details.copied'),
      text: `${copiedLink[1]} ${t('certificateBadgeTemplate:details.copied-document')}`,
    }).then(() => {
      setCopiedLink(false, '');
    });
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Appbar closeButton={true} handleClose={handleClose} />
      <Container className={(classes.container, classes.root)} maxWidth={'xl'}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4} className={'text-center'}>
            <img src={carnet?.photoCarnet} alt="" className={classes.img} />
          </Grid>
          <Grid item xs={12} md={8}>
            {!loading && (
              <div className={classes.header}>
                <Grid container spacing={2} alignItems="center" justify="space-between">
                  <Grid item xs={12} md={12} xl={5}>
                    <Grid container>
                      <Typography variant="h4" gutterBottom className={classes.textTitle}>
                        {loading ? (
                          <Skeleton variant="text" width={250} />
                        ) : (
                          `${carnet.cuentas?.usuarios?.primer_nombre} ${
                            carnet.cuentas?.usuarios?.segundo_nombre || ''
                          } ${carnet.cuentas?.usuarios?.primer_apellido} ${
                            carnet.cuentas?.usuarios?.segundo_apellido || ''
                          }`
                        )}
                      </Typography>
                      <img
                        alt="img_verificado"
                        src={
                          stateCarnet === 'Verified'
                            ? styles.icon_verificado
                            : stateCarnet === 'Revoked'
                            ? Revoked
                            : Expired
                        }
                        className={classes.img_verificado}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {loading ? (
                      <Skeleton variant="rect" height={30} />
                    ) : (
                      <div className={classes.contentCenter}>
                        <Link
                          href={`/carnet/verify/${carnetId}`}
                          target="_blank"
                          underline="none"
                          variant="inherit"
                          className={classes.buttonVerificate}
                        >
                          {t('buttons:buttons.verify')}
                        </Link>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {loading ? (
                      <Skeleton variant="circle" className={classes.img_user} />
                    ) : (
                      <Avatar
                        variant="square"
                        aria-label="img_certificate"
                        src={carnet?.photoOrganization}
                        className={classes.img_org}
                      />
                    )}
                  </Grid>
                  <Grid item xs={10} md={5}>
                    <Typography className={classes.textNameOrganization}>
                      {loading ? (
                        <Skeleton variant="text" width={250} />
                      ) : (
                        carnet?.carnetsEmitidos?.plantillasCarnets?.organizaciones?.nombre
                      )}
                    </Typography>
                    {loading ? (
                      <Skeleton variant="text" width={150} className={classes.linkOrganization} />
                    ) : (
                      <Link
                        className={classes.linkOrganization}
                        href={`/organization/${carnet?.carnetsEmitidos?.plantillasCarnets?.organizaciones?.url_perfil}`}
                        target="_blank"
                      >
                        {t('certificateBadgeTemplate:details.more-information')}
                      </Link>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} className={'text-center'}>
                    <Typography className={classes.textBolderDate}>
                      {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.date-certification')}:`}
                    </Typography>
                    <Typography className={classes.text}>
                      {loading ? (
                        <Skeleton />
                      ) : (
                        moment(carnet?.carnetsEmitidos?.fecha_emision).format('DD-MM-YYYY')
                      )}
                    </Typography>
                  </Grid>
                  {loading ? (
                    <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
                  ) : (
                    fieldsPublics.length > 0 && (
                      <Grid
                        container
                        className={classes.menuItem}
                        justify="space-between"
                        onClick={() => (openPublics ? setOpenPublics(false) : setOpenPublics(true))}
                      >
                        <Grid item lg={11}>
                          <Typography className={classes.textMenuItem}>
                            {t('certificateBadgeTemplate:details.information-public')}
                          </Typography>
                        </Grid>
                        <Grid item lg={1}>
                          {openPublics ? (
                            <RemoveOutlined sx={expandeMenuItemStyle} />
                          ) : (
                            <AddOutlined sx={expandeMenuItemStyle} />
                          )}
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Grid container className={classes.descriptions}>
                    <Collapse in={openPublics} timeout="auto">
                      <Grid item lg={12} className={classes.content_descriptions}>
                        {fieldsPublics.map((data, index) =>
                          carnet?.carnetsEmitidos?.carnetsCamposValores.map((item) =>
                            data.id === item.id_carnets_campos_adicionales ? (
                              <Typography key={`fieldsAdditionals${index}`} className={classes.text}>
                                <b>{data.nombre_campo}: </b>
                                {item.valor}
                              </Typography>
                            ) : (
                              ''
                            )
                          )
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                  {loading ? (
                    <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
                  ) : (
                    fieldsPrivates.length > 0 && (
                      <Grid
                        container
                        className={classes.menuItem}
                        justify="space-between"
                        onClick={() => (openPrivates ? setOpenPrivates(false) : setOpenPrivates(true))}
                      >
                        <Grid item lg={11}>
                          <Typography className={classes.textMenuItem}>
                            {t('certificateBadgeTemplate:details.information-private')}
                          </Typography>
                        </Grid>
                        <Grid item lg={1}>
                          {openPrivates ? (
                            <RemoveOutlined sx={expandeMenuItemStyle} />
                          ) : (
                            <AddOutlined sx={expandeMenuItemStyle} />
                          )}
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Grid container className={classes.descriptions}>
                    <Collapse in={openPrivates} timeout="auto">
                      <Grid item lg={12} className={classes.content_descriptions}>
                        {fieldsPrivates.map((data, index) =>
                          carnet?.carnetsEmitidos?.carnetsCamposValores.map((item) =>
                            data.id === item.id_carnets_campos_adicionales ? (
                              <Typography key={`fieldsAdditionals${index}`} className={classes.text}>
                                <b>{data.nombre_campo}: </b>
                                {item.valor}
                              </Typography>
                            ) : (
                              ''
                            )
                          )
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={() => document.getElementById('linkedinShare').click()}>
          <IconButton className={classes.LinkedInIconMenu}>
            <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
          </IconButton>
          <spam className={classes.textLinkedMenu}> Agregar a las actividades</spam>
        </MenuItem>
      </Menu>

      <Grid container className={classes.footer}>
        <Grid item lg={12}>
          <span className={classes.textParagraph}>{t('certificateBadgeTemplate:details.right-reserved')}</span>
          <p className={classes.textName}>{styles.organizationName}</p>
          <span className={classes.textParagraph}>{moment().format('YYYY')}</span>
        </Grid>
      </Grid>
    </>
  );
}

const expandeMenuItemStyle = {
  color: '#ffffff',
  float: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '1em',
  },
  img: {
    width: '95%',
    height: '95%',
    maxWidth: '-webkit-fill-available',
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  textBolder: {
    marginTop: '1em',
    color: '#000000',
    fontWeight: 'bold',
    display: 'inline',
  },
  textTitle: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textName: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textNameOrganization: {
    color: '#000000',
    fontWeight: 'bold',
  },
  text: {
    color: '#000000',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#000000',
    margin: '1em 0 1em 0',
  },
  link: {
    color: colors.primary,
    marginLeft: '2em',
    textDecoration: 'underline',
  },
  linkOrganization: {
    color: colors.primary,
    textDecoration: 'underline',
  },
  header: {
    marginTop: '3em',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1em',
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '1em',
    },
  },
  buttonVerificate: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '1em',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    width: '90%',
    '&:hover': {
      backgroundColor: colors.secondary,
      textDecoration: 'none',
      color: '#ffffff',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  container_redes: {
    marginBottom: '1em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  LinkedInIconMenu: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    '&:hover': {
      backgroundColor: '#018ec5',
    },
  },
  textLinkedMenu: {
    fontSize: '.8em',
    marginLeft: '.5em',
  },
  img_user: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  img_org: {
    marginLeft: '0em',
    width: theme.spacing(13),
    height: theme.spacing(13),
    '& img': {
      height: 'min-content',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2em',
    },
  },
  textBolderDate: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '.4em',
  },
  menuItem: {
    borderRadius: 10,
    padding: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  textMenuItem: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  descriptions: {
    justifyContent: 'left',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    marginBottom: 10,
    textAlign: 'center',
  },
  content_descriptions: {
    margin: '1em',
  },
  footer: {
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  mySwal: {
    zIndex: 3000,
  },
  contentCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    carnet: state.carnet || {},
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  setCarnet,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarnetView);
