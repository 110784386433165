import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableRow, Button, makeStyles, Tooltip, IconButton } from '@material-ui/core';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { changeBreadcrump } from '../../../actions';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import axios from '../../../api';
import colors from '../../../assets/styles/colors';
import Swal from 'sweetalert2';

function Reminders(props) {
  const { page, rowsPerPage, permission, changeBreadcrump } = props;
  const history = useHistory();
  const classes = useStyles();
  const [reminderParameters, setReminderParameters] = useState([]);
  const [filtro, setFiltradas] = useState([]);

  useEffect(() => {
    if (permission.includes('Leer')) {
      getReminderParameter();
      changeBreadcrump([{ name: 'Ajustes' }, { name: 'Recordatorios' }]);
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const getReminderParameter = async () => {
    const { data } = await axios.post(`/reminderParameter/getReminders`);
    setReminderParameters(data.reminders);
    setFiltradas(data.reminders);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'create':
        history.push(`/reminders/create`);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: '¿Está seguro que desea eliminar este parámetro?',
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/reminderParameter/${id}`);
      setReminderParameters(reminderParameters.filter((item) => item.id !== id));
      setFiltradas(reminderParameters.filter((item) => item.id !== id));
      if (data) {
        Swal.fire({
          text: 'Eliminado exitosamente.',
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: 'No se pudo eliminar.',
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      {permission.includes('Crear') ? (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>Gestionar</span>
          </Button>
        </ModuleHeader>
      ) : (
        <ModuleHeader />
      )}
      <Search items={reminderParameters} setItems={setFiltradas} />
      <Table columns={columns} rows={filtro}>
        <TableBody>
          {filtro.length > 0 ? (
            <>
              {filtro.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">
                    {row.tipo_recordatorio === 0
                      ? 'Insignias'
                      : row.tipo_recordatorio === 1
                      ? 'Certificados'
                      : row.tipo_recordatorio === 2 && 'Carnets'}
                  </TableCell>
                  <TableCell align="center">{`${row.primer_recordatorio} días`}</TableCell>
                  <TableCell align="center">{`${row.segundo_recordatorio} días`}</TableCell>
                  <TableCell align="center">
                    {permission.includes('Eliminar') && (
                      <Tooltip title="Eliminar">
                        <IconButton aria-label="delete" onClick={(e) => handleClick(e, row.id, 'delete')}>
                          <DeleteForeverOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableCell align="center" colSpan="3">
              No hay datos registrados
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const columns = [
  { id: 'type', label: 'Tipo de recordatorio', align: 'center' },
  { id: 'primer', label: 'Primer recordatorio', align: 'center' },
  { id: 'segundo', label: 'Primer recordatorio', align: 'center' },
  { id: 'actions', label: '', align: 'center' },
];

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: "50px",
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'recordatorios')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
