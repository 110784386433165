import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Divider,
  Button,
  Card,
  makeStyles,
  CardContent,
  Avatar,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { ContentCopyOutlined, ArchiveOutlined, DeleteForeverOutlined, LaunchOutlined } from '@mui/icons-material';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Backdrop from '../../../../components/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';
import Template from './Index';
import colors from '../../../../assets/styles/colors';
import axios from '../../../../api';
import EditIcon from '../../../../assets/static/icons/EditIcon';
import CloseIcon from '@material-ui/icons/Close';
import { changeBreadcrump, setReturnRoute } from '../../../../actions';
import { encrypt, decrypt } from '../../../../utils/crypt';
import { useTranslation } from 'react-i18next';

function Details(props) {
  const { user, changeBreadcrump, permission, setReturnRoute, details } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [documentsTemplate, setDocumentsTemplate] = useState({});
  const [documentCertify, setDocumentCertify] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles({ documentsTemplate });
  const [templateCopy, setTemplateCopy] = useState({});
  const [permissionDepartament, setPermissionDepartament] = useState(false);
  const [t] = useTranslation(['certificateBadgeTemplate', 'tables', 'alerts', 'buttons', 'breadCrumps']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getDocumentsTemplate();
    setLoading(false);
  };

  const getDocumentsTemplate = async () => {
    try {
      const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
      const isDeparmentCreaterRoll =
        user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;
      setDocumentsTemplate(details);
      setDocumentCertify(details?.documentosEmitidos);
      getDocumentTemplateState(details?.estado);
      setTemplateCopy({
        ...details,
        copy: true,
        nombre: `${details?.nombre} copia`,
        nombre_privado: `${details?.nombre_privado === null ? '' : `${details?.nombre_privado} copia`}`,
        id_usuarios: user.data_user?.id,
        typeImagen: details?.imagen.includes('Pdf') ? '' : 'newImage',
        documentsFields: details?.documentoCampos.map((item) => ({
          fieldName: item.nombre,
          fieldType: item.tipo_campo_vp,
          exampleText: item.texto_ejemplo,
          fontFamily: { family: item.tipo_letra },
          fontSize: item.tamano_letra,
          fontColor: { hex: item.color },
          rectColor: { hex: item.background_color },
          fontStyle: {
            bold: item.bold,
            italic: item.italic,
            underline: item.underline,
            strikethrough: item.strikethrough,
          },
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
          page: item.page,
          align: item.formato_letra || 'center',
        })),
      });
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        details?.plantillasDocumentosHistorial[0].id_usuarios === user.data_user?.id
          ? setPermissionDepartament(false)
          : setPermissionDepartament(true);
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const getDocumentTemplateState = (estado) => {
    if (estado === 1) {
      changeBreadcrump(t('breadCrumps:breadCrumps.documents-details-published', { returnObjects: true }));
      setReturnRoute(t('breadCrumps:breadCrumps.documents-details-published', { returnObjects: true })[2]?.route);
    } else {
      if (estado === 0) {
        changeBreadcrump(t('breadCrumps:breadCrumps.documents-details-edited', { returnObjects: true }));
        setReturnRoute(t('breadCrumps:breadCrumps.documents-details-edited', { returnObjects: true })[2]?.route);
      } else {
        changeBreadcrump(t('breadCrumps:breadCrumps.documents-details-archived', { returnObjects: true }));
      }
    }
  };

  const documentIssued = () => {
    return (
      <>
        {permission.includes('Editar') && (
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.edit')}>
              <Button disabled={permissionDepartament} className={classes.button} onClick={goEdit}>
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {permission.includes('Copiar') && (
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.copy')}>
              <Button
                disabled={permissionDepartament}
                className={classes.button}
                onClick={(e) => goCopy(e, templateCopy)}
              >
                <ContentCopyOutlined />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {documentCertify !== 0
          ? permission.includes('Archivar') && (
              <Grid item lg={3} md={3} xs={5}>
                <Tooltip title={t('tables:table.tooltip.archive')}>
                  <Button
                    disabled={permissionDepartament}
                    className={classes.button}
                    onClick={(e) => handleClick(e, documentsTemplate?.id, 'archive')}
                  >
                    <ArchiveOutlined />
                  </Button>
                </Tooltip>
              </Grid>
            )
          : permission.includes('Eliminar') && (
              <Grid item lg={3} md={3} xs={5}>
                <Tooltip title={t('tables:table.tooltip.delete')}>
                  <Button
                    disabled={permissionDepartament}
                    className={classes.button}
                    onClick={(e) => handleClick(e, documentsTemplate?.id, 'delete')}
                  >
                    <DeleteForeverOutlined />
                  </Button>
                </Tooltip>
              </Grid>
            )}
      </>
    );
  };

  const loadButtons = () => {
    if (documentsTemplate?.estado === 0) {
      return (
        <>
          {permission.includes('Publicar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.publish')}>
                <Button className={classes.button} onClick={(e) => handleClick(e, documentsTemplate?.id, 'publish')}>
                  <LaunchOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Editar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.edit')}>
                <Button className={classes.button} onClick={goEdit}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Copiar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.copy')}>
                <Button
                  disabled={permissionDepartament}
                  className={classes.button}
                  onClick={(e) => goCopy(e, templateCopy)}
                >
                  <ContentCopyOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Eliminar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.delete')}>
                <Button className={classes.button} onClick={(e) => handleClick(e, documentsTemplate?.id, 'delete')}>
                  <DeleteForeverOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      );
    }
    if (documentsTemplate?.estado === 1) {
      return <>{documentIssued()}</>;
    }
    if (documentsTemplate?.estado === 2) {
      return (
        <>
          {permission.includes('Publicar') && (
            <Grid item lg={2} md={2} xs={5}>
              <Tooltip title={t('tables:table.tooltip.publish')}>
                <Button className={classes.button} onClick={(e) => handleClick(e, documentsTemplate?.id, 'publish')}>
                  <LaunchOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      );
    }
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'archive':
        modalArchive(id);
        break;
      case 'publish':
        modalPublish(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalArchive = (id) => {
    Swal.fire({
      text: t('alerts:alerts.archive-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendArchive(id);
      }
    });
  };

  const modalPublish = (id) => {
    Swal.fire({
      text: t('alerts:alerts.publish-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendPublish(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendPublish = async (id) => {
    try {
      const { data } = await axios.put(`/documentTemplate/updateState/${id}`, {
        estado: 1,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      });
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.published'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/templates/documents/published');
        });
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const sendArchive = async (id) => {
    try {
      const { data } = await axios.put(`/documentTemplate/updateState/${id}`, {
        estado: 2,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      });
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.archived'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/templates/documents/archived');
        });
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/documentTemplate/${id}`, { data: { id_usuarios: user.data_user?.id } });
      if (data) {
        if (documentsTemplate?.estado === 0) {
          Swal.fire({
            text: t('alerts:alerts.deleted'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push('/templates/documents/edited');
          });
        } else {
          Swal.fire({
            text: t('alerts:alerts.deleted'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push('/templates/documents/published');
          });
        }
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const goEdit = () => {
    history.push(`/templates/documents/edit/${encrypt(documentsTemplate?.id)}`);
  };
  const goCopy = (e, templateCopy) => {
    Swal.fire({
      text: t('alerts:alerts.copy-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendCopy(templateCopy, e);
      }
    });
  };

  const sendCopy = (templateCopy, e) => {
    e.preventDefault();
    setLoadingBackdrop(true);
    axios
      .post(`/documentTemplate`, templateCopy)
      .then((res) => {
        setLoading(false);
        history.push('/templates/documents/edited');
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts.copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        setLoadingBackdrop(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.copied-error'),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Template Index={0} Buttons={loadButtons()} documentsId={decrypt(props.match.params.id)}>
      <Card>
        <CardContent className={classes.root} maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item lg={5} md={5} xs={12}>
              <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                {loading ? (
                  <Skeleton variant="circle">
                    <Avatar className={classes.avatar} />
                  </Skeleton>
                ) : (
                  <Tooltip title={t('tables:table.tooltip.view-template')}>
                    <Avatar
                      alt="img-prev"
                      src={
                        documentsTemplate.linkPhotoTemplate
                          ? documentsTemplate.linkPhotoTemplate
                          : documentsTemplate.linkPhoto
                      }
                      variant="square"
                      className={classes.avatar}
                      onClick={() => setOpen(true)}
                    >
                      <Brightness5Icon className={classes.icon} />
                    </Avatar>
                  </Tooltip>
                )}
              </div>
            </Grid>
            <Grid item lg={7} md={7} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={6} xs={12}>
                  <Typography variant="h4" gutterBottom className={classes.textTitle}>
                    {loading ? <Skeleton /> : `${documentsTemplate?.nombre}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={12}>
                  <Typography variant="h6" gutterBottom className={classes.textTitle}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      `${documentsTemplate?.nombre_privado === null ? '' : documentsTemplate?.nombre_privado}`
                    )}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={12}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.created')}: </span>
                      <span className={classes.text}>
                        {moment(documentsTemplate?.fecha_creacion).format('DD-MM-YYYY')}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.root} />
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={
              documentsTemplate.linkPhotoTemplate ? documentsTemplate.linkPhotoTemplate : documentsTemplate.linkPhoto
            }
            className={classes.img}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loadingBackdrop} />
    </Template>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: (props) => ({
    maxWidth: '70vw',
    height: props.documentsTemplate?.height,
  }),
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: "50px",
    padding: '8px 30px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  buttonForm: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'plantillas documentos')
      .map((item) => item.acciones.nombre),
    details: state.details,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  setReturnRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
