import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, makeStyles, Grid, Tooltip, IconButton } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { FindInPageOutlined } from '@mui/icons-material';
import DateFnsUtils from '@date-io/date-fns';
import api from '../../api';
import Skeleton from '@material-ui/lab/Skeleton';
import IssueCardDetails from './IssueCardDetails';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';

function IssueCard() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [t] = useTranslation(['contracts', 'tables', 'alerts']);

  useEffect(() => {
    getContractsCountForYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const getContractsCountForYears = async () => {
    setLoading(true);
    const { data } = await api.post(`/contract/getContractsCountForYears`, { selectedYear });
    setCount(data.contracts);
    setLoading(false);
  };

  const handleClickShow = () => setShow((show) => !show);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.root} elevation={0}>
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" component="h6" className={classes.title}>
                {loading ? <Skeleton /> : t('contracts:total-issue')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {loading ? (
                <Skeleton height={30} />
              ) : (
                <DatePicker
                  autoOk
                  disableFuture
                  size="small"
                  views={['year']}
                  value={selectedYear}
                  onChange={setSelectedYear}
                  cancelLabel={t('alerts:alerts.cancel')}
                />
              )}
            </Grid>
          </Grid>

          <div className={classes.container_typography}>
            {loading ? (
              <Skeleton width={200} height={50} />
            ) : (
              <Typography variant="h5" component="h5">
                <Tooltip title={t('tables:table.tooltip.details')}>
                  <IconButton aria-label="view" onClick={() => handleClickShow()}>
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Tooltip>{' '}
                {show ? new Intl.NumberFormat('de-DE').format(count.total) : '*'.repeat(count.total.toString().length)}{' '}
                <Tooltip title={t('tables:table.tooltip.details')}>
                  <IconButton aria-label="view" onClick={() => setOpen(true)}>
                    <FindInPageOutlined />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
      <IssueCardDetails
        count={count}
        open={open}
        setOpen={setOpen}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        loading={loading}
      />
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1rem',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  container_typography: {
    marginTop: '.5em',
    marginBottom: '-1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default IssueCard;
