import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, Card, CardContent, Typography, Button } from '@material-ui/core';
import colors from '../assets/styles/colors';
import api from '../api';
import { decrypt } from '../utils/crypt';
import Backdrop from '../components/Backdrop';
import { BroadcastChannel } from 'broadcast-channel';
import { loginRequest } from '../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
  },
  card: {
    minWidth: 275,
  },
  img: {
    width: theme.spacing(40),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

function CombineAccept(props) {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [Logo, setLogo] = useState('');
  const { user, loginRequest } = props;
  const logoutChannel = new BroadcastChannel('logout');

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLogo(styles.logo435x149);
    setLoading(true);
    if (props.match.params.accountCurrentId) {
      const ids = props.match.params.accountCurrentId.split('+');
      const accountCurrent = decrypt(`${ids[0]}`);
      const accountCombine = decrypt(`${ids[1]}`);

      acceptAll(accountCurrent, accountCombine).catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      setMessage('Se rechazó la combinación de las cuentas');
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (ruta, e) => {
    if (ruta === 0) {
      logoutChannel.postMessage('reload');
      history.push('/dashboard');
    } else {
      history.push('/sign-in');
    }
  };

  const acceptAll = async (accountCurrent, accountCombine) => {
    const { data: userCurrent } = await api.post(`/user/getAccounts`, { id: accountCurrent });
    const { data: userCombine } = await api.post(`/user/getAccounts`, { id: accountCombine });

    if (userCurrent?.accounts?.length > 0) {
      if (userCurrent?.accounts[0]?.id_usuarios === userCombine?.accounts[0]?.id_usuarios) {
        setMessage(
          `Las cuentas '${userCurrent?.accounts[0]?.correo}' y '${userCombine?.accounts[0]?.correo}' ya han sido combinadas`
        );
      } else {
        await api.put(`user/updateAccount/${userCombine?.accounts[0]?.id}`, {
          defecto: userCombine?.accounts[0]?.id,
          id_usuarios: userCurrent?.accounts[0]?.id_usuarios,
        });
        setMessage('Las cuentas se han combinado exitosamente');
        user?.data_user?.cuentas.push({
          id: userCombine?.accounts[0]?.id,
          correo: userCombine?.accounts[0]?.correo,
          defecto: userCombine?.accounts[0]?.defecto,
        });
        loginRequest(user);
      }
    }
  };

  return (
    <div className="App">
      <div className="App-header">
        <Card className={classes.card}>
          <CardContent>
            <img src={Logo} alt="logo" className={classes.img} />
            <Typography>{message}</Typography>
            <form className={classes.root}>
              <div className={`text-center ${classes.root}`}>
                {user ? (
                  <Button
                    variant="contained"
                    size="small"
                    className={`my-2 ${classes.button}`}
                    onClick={(e) => handleSubmit(0)}
                  >
                    Ir al dashboard
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    className={`my-2 ${classes.button}`}
                    onClick={(e) => handleSubmit(1)}
                  >
                    Iniciar sesión
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
        <Backdrop loading={loading} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CombineAccept);
