import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import md5 from 'crypto-js/md5';
import axiosFile from 'axios';
import {
  makeStyles,
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from '@material-ui/core';
import { Brightness5Outlined, AddToPhotosOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { env } from '../../../config/environment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    //padding: '8px 40px',
  },
  card: {
    backgroundColor: colors.background,
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
  },
  text: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  contentInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      alignItems: 'left',
    },
  },
  icon: {
    margin: 20,
    fontSize: '1em',
  },
}));

function FormSocialNetworks(props) {
  const { changeBreadcrump } = props;
  const classes = useStyles();
  const history = useHistory();
  const [form, setForm] = useState({
    nombre: '',
    icono: null,
    iconoPrev: '',
  });
  const [loading, setLoading] = useState(false);
  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'forms']);

  useEffect(() => {
    if (props.match.params.id) {
      getSocialNetwoks().catch(() => {
        history.push('/500');
        window.location.reload();
      });
      changeBreadcrump(t('breadCrumps:breadCrumps.socialMedia-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.socialMedia-create', { returnObjects: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFile = (e) => {
    const icono = e.target.files[0];
    setForm({
      ...form,
      icono,
      iconoPrev: URL.createObjectURL(icono),
    });
  };

  const getSocialNetwoks = async () => {
    const id = props.match.params.id;
    const { data } = await axios.get(`/socialNetworks/${id}`);
    setForm({ ...data.socialNetworks, icono: data.socialNetworks.linkIcons, iconoPrev: data.socialNetworks.linkIcons });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleCancel = () => {
    history.push('/socialNetworks');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleToggle();

    if (!props.match.params.id) {
      const formPhoto = new FormData();
      formPhoto.append('file', form.icono);

      axios
        .post(`/socialNetworks/`, { ...form, icono: form.icono?.name })
        .then((res) => {
          if (form.icono?.name) {
            const md5Route = md5(`certika-social-networks-icons-${res.data.socialNetworksId}`).toString();
            axiosFile({
              method: 'post',
              url: `${env.apiURL}/file/upload-social-network-logo/${md5Route}`,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formPhoto,
            }).then(() => {
              handleClose();
              history.push('/socialNetworks');
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.created'),
                showConfirmButton: false,
                timer: 1500,
              });
            });
          } else {
            handleClose();
            history.push('/socialNetworks');
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.created'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.created-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = props.match.params.id;
      const formPhoto = new FormData();
      formPhoto.append('file', form.icono);

      axios
        .put(`/socialNetworks/${id}`, { ...form, icono: form.icono?.name })
        .then(() => {
          if (form.icono?.name) {
            const md5Route = md5(`certika-social-networks-icons-${id}`).toString();
            axiosFile({
              method: 'post',
              url: `${env.apiURL}/file/upload-social-network-logo/${md5Route}`,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formPhoto,
            }).then(() => {
              handleClose();
              history.push('/socialNetworks');
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.created'),
                showConfirmButton: false,
                timer: 1500,
              });
            });
          } else {
            handleClose();
            history.push('/socialNetworks');
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.edited'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography component="h6" variant="h6">
              {t('forms:socialMedia-details')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={1}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Avatar alt="img-prev" src={form?.iconoPrev} variant="rounded" className={classes.avatar}>
                    <Brightness5Outlined sx={{ margin: 3, fontSize: '1em' }} />
                  </Avatar>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="body2" className={classes.text}>
                  {t('forms:icon-square')}
                </Typography>

                <div className={classes.contentInput}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    name="imagen"
                    type="file"
                    onChange={(e) => handleFile(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" component="span">
                      <AddToPhotosOutlined />
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('forms:socialMedia-name')}
                    name="nombre"
                    value={form.nombre}
                    variant="outlined"
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(FormSocialNetworks);
