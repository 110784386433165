import React, { useEffect } from 'react';
import { TextField, Button, makeStyles, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { setSearchValue } from '../actions';
import axios from '../api';
import Swal from 'sweetalert2';
/* Translations */
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import colors from '../assets/styles/colors';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    marginBottom: '.5em',
  },
  input: {
    width: '100%',
    margin: 10,
    backgroundColor: 'white',
  },
}));

const SearchBar = (props) => {
  const {
    tableName,
    items,
    setItems,
    groupId,
    setCountItems,
    changePage,
    organizationId,
    deparmentCreater,
    userGroupId,
    setLoading,
    type,
    columns,
    setSearchValue,
    searchValue,
  } = props;
  const classes = useStyles();

  /* translation */
  const [t] = useTranslation(['search', 'alerts', 'buttons']);

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = async (e, value = null) => {
    //e.preventDefault();
    if (changePage) changePage(0);
    if (setLoading) setLoading(true);
    const filtro = value ? '' : searchValue;

    switch (tableName) {
      case 'BadgesIssued':
        await axios
          .post('/badgesIssued/getIssuedSearch', {
            id_grupos_insignias_emitidas: groupId,
            id_organizaciones: organizationId,
            filtro,
          })
          .then((res) => {
            setItems(res.data?.issued);
            setCountItems(res.data?.issued?.length);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'groupIssued':
        await axios
          .post('/groupsBadgesIssued/getGroups/search', { type: type, filtro, id_organizaciones: organizationId })
          .then((res) => {
            setItems(res.data?.groups);
            setCountItems(res.data?.groups?.length);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'templateBadge':
        await axios
          .post('/templateBadge/search', { filtro, type, id_organizaciones: organizationId })
          .then((res) => {
            if (deparmentCreater?.isDeparmentCreater || deparmentCreater?.isDeparmentCreaterRoll) {
              const deparmentCreaterData = res.data.templatesBadges?.filter(
                (templateCertificate) =>
                  templateCertificate.plantillasInsigniasHistorial[0].id_usuarios === deparmentCreater?.userId
              );
              setItems(deparmentCreaterData);
              setCountItems(deparmentCreaterData.length);
              setLoading(false);
            } else {
              setItems(res.data?.templatesBadges);
              setCountItems(res.data?.templatesBadges?.length);
              setLoading(false);
            }
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'collections':
        await axios
          .post('/collections/getCollections/search', { filtro, tipo: groupId })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.collections);
            } else {
              setItems(res.data.collections.filter((item) => item.id_organizaciones === organizationId));
            }
            setLoading(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.error-ocurred'),
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
          });
        break;
      case 'badgeCollectionTemplates':
        await axios
          .post('/badgesTemplatesGroupsTemplates/getBadgesTemplatesGroupsTemplates/search', {
            filtro,
            id_grupos_plantillas: groupId,
            estado: 1,
          })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.plantillasInsigniasGruposPlantillas);
            } else {
              setItems(
                res.data.plantillasInsigniasGruposPlantillas.filter(
                  (item) => item.plantillasInsignias?.id_organizaciones === organizationId
                )
              );
            }
            setLoading(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.error-ocurred'),
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
          });
        break;
      case 'recomendations':
        await axios
          .post('/recommendation/getRecommendations/search', { filtro, type })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.recommendations);
            } else {
              if (type === 'badges') {
                setItems(
                  res.data.recommendations.filter(
                    (item) => item.plantillasInsignias?.id_organizaciones === organizationId
                  )
                );
              } else if (type === 'certificates') {
                setItems(
                  res.data.recommendations.filter(
                    (item) => item.plantillasCertificados?.id_organizaciones === organizationId
                  )
                );
              }
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'badgeTemplateRecomendations':
        await axios
          .post('/recommendation/getRecommendationTemplate/search', {
            filtro,
            type: 'badges',
            id_plantillas: groupId,
          })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.recommendations);
            } else {
              setItems(
                res.data.recommendations.filter(
                  (item) => item.plantillasInsignias?.id_organizaciones === organizationId
                )
              );
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'certificateTemplateRecomendations':
        await axios
          .post('/recommendation/getRecommendationTemplate/search', {
            filtro,
            type: 'certificates',
            id_plantillas_certificados: groupId,
          })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.recommendations);
            } else {
              setItems(
                res.data.recommendations.filter(
                  (item) => item.plantillasCertificados?.id_organizaciones === organizationId
                )
              );
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'templateCertificate':
        await axios
          .post('/certificateTemplate/search', {
            filtro,
            type,
            id_organizaciones: organizationId,
          })
          .then((res) => {
            if (deparmentCreater?.isDeparmentCreater || deparmentCreater?.isDeparmentCreaterRoll) {
              const deparmentCreaterData = res.data.certificateTemplates?.filter(
                (templateCertificate) =>
                  templateCertificate.plantillasCertificadosHistorial[0].id_usuarios === deparmentCreater?.userId
              );
              setItems(deparmentCreaterData);
              setCountItems(deparmentCreaterData.length);
            } else {
              setItems(res.data?.certificateTemplates);
              setCountItems(res.data?.certificateTemplates?.length);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'certificateCollectionTemplates':
        await axios
          .post('/certificatesTemplatesGroupsTemplates/getCertificatesTemplatesGroupsTemplates/search', {
            filtro,
            id_grupos_plantillas: groupId,
            estado: 1,
          })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.plantillasCertificadosGruposPlantillas);
            } else {
              setItems(
                res.data.plantillasCertificadosGruposPlantillas.filter(
                  (item) => item.plantillasCertificados?.id_organizaciones === organizationId
                )
              );
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'CertificatesIssued':
        await axios
          .post('/certificatesIssued/getIssuedSearch', {
            id_grupos_certificados_emitidos: groupId,
            id_organizaciones: organizationId,
            filtro,
          })
          .then((res) => {
            if (res.data?.issued.length > 0) {
              const fields = [];
              // eslint-disable-next-line array-callback-return
              res.data?.issued[0].certificadosCamposValores.map((field) => {
                if (
                  field.certificadoCampos?.tipo_campo_vp !== 48 &&
                  field.certificadoCampos?.nombre !== 'certificateCode'
                ) {
                  fields.push({
                    id: `${field.certificadoCampos?.nombre}`,
                    label: `${field.certificadoCampos?.nombre}`,
                    align: 'center',
                    orden: `${field.certificadoCampos?.nombre}`,
                  });
                }
              });
              const fieldsConcat = columns.startColumns.concat(fields).concat(columns.finalColumns);
              columns.setColumns(fieldsConcat);
              setItems(res.data?.issued);
              setCountItems(res.data?.issued?.length);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'templateDocument':
        await axios
          .post('/documentTemplate/search', {
            filtro,
            type,
            id_organizaciones: organizationId,
          })
          .then((res) => {
            if (deparmentCreater?.isDeparmentCreater || deparmentCreater?.isDeparmentCreaterRoll) {
              const deparmentCreaterData = res.data.documentTemplates?.filter(
                (template) => template.plantillasDocumentosHistorial[0].id_usuarios === deparmentCreater?.userId
              );
              setItems(deparmentCreaterData);
            } else {
              setItems(res.data?.documentTemplates);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'groupDocuments':
        await axios
          .post('/documentsCollections/getDocumentsCollections/search', {
            filtro,
            id_organizaciones: organizationId,
          })
          .then((res) => {
            setItems(res.data?.documentsCollections);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'filesDocuments':
        await axios
          .post('/documentsCertify/getDocumentsCertifyByFiles/search', {
            filtro,
            id_organizaciones: organizationId,
            id_colecciones_documentos: groupId,
          })
          .then((res) => {
            setItems(res.data?.documentsCertify);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'templateCarnets':
        await axios
          .post('/carnetTemplate/search', {
            filtro,
            type,
            id_organizaciones: organizationId,
          })
          .then((res) => {
            if (deparmentCreater?.isDeparmentCreater || deparmentCreater?.isDeparmentCreaterRoll) {
              const deparmentCreaterData = res.data.carnetTemplates?.filter(
                (template) => template.plantillasCarnetsHistorial[0].id_usuarios === deparmentCreater?.userId
              );
              setItems(deparmentCreaterData);
              setCountItems(deparmentCreaterData.length);
            } else {
              setItems(res.data?.carnetTemplates);
              setCountItems(res.data?.carnetTemplates.length);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'CarnetIssued':
        await axios
          .post('/carnetsIssued/getCarnetsIssued/search', {
            id_grupos_carnets_emitidos: groupId,
            id_organizaciones: organizationId,
            filtro,
          })
          .then((res) => {
            if (res.data?.issued.length > 0) {
              const fields = [];
              // eslint-disable-next-line array-callback-return
              res.data?.issued[0].carnetsCamposValores.map((field) => {
                if (field.carnetCampos?.tipo_campo_vp !== 48 && field.carnetCampos?.nombre !== 'certificateCode') {
                  fields.push({
                    id: `${field.carnetCampos?.nombre}`,
                    label: `${field.carnetCampos?.nombre}`,
                    align: 'center',
                    orden: `${field.carnetCampos?.nombre}`,
                  });
                }
              });
              const fieldsConcat = columns.startColumns.concat(fields).concat(columns.finalColumns);
              columns.setColumns(fieldsConcat);
              setItems(res.data?.issued);
              setCountItems(res.data?.issued?.length);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'helpCenterCarnets':
        await axios
          .post('/helpCenter/getHelpCenters/search', { filtro })
          .then((res) => {
            setItems(res.data?.helpCenters);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'valueParameters':
        setItems(items.filter((data) => data.parametros?.nombre.toLowerCase().includes(filtro.toLowerCase())));
        setLoading(false);
        break;
      case 'Permissions':
        await axios
          .post('/permission/getPermissions/search', { filtro })
          .then((res) => {
            setItems(
              res.data?.permissions.sort((a, b) =>
                `${a.gruposUsuarios?.nombre.toLowerCase()}${a.acciones?.modulos?.nombre.toLowerCase()}` <
                `${b.gruposUsuarios?.nombre.toLowerCase()}${b.acciones?.modulos?.nombre.toLowerCase()}`
                  ? -1
                  : 1
              )
            );
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'users':
        await axios
          .post('/user/getUsers/search', { filtro, id_organizaciones: organizationId })
          .then((res) => {
            setItems(res.data?.users);
            setCountItems(res.data?.users?.length);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'organizations':
        await axios
          .post('/organization/getOrganizations/search', { filtro, id: organizationId })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.organizations);
              setCountItems(res.data?.organizations?.length);
            } else {
              if (res.data?.organizations.length > 0) {
                const organizationsRelations = res.data.organizations
                  ?.filter((item) => item.id === organizationId)[0]
                  .organizacionesRelaciones.map((item) => item.organizacionesRelacionadas);

                const organizationsAll = res.data.organizations
                  ?.filter((item) => item.id === organizationId)
                  .concat(organizationsRelations);
                setItems(organizationsAll);
                setCountItems(organizationsAll.length);
              }
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'userGroupsOrganization':
        await axios
          .post('/userGroup/organizations/getUserGroupsOrganization/search', { filtro })
          .then((res) => {
            if (userGroupId === 1) {
              setItems(res.data?.groups);
            } else {
              const groups = res.data.groups.filter((item) => item.id_organizaciones === organizationId);
              setItems(groups);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'contracts':
        await axios
          .post('/contract/getContractsCountForOrganizations/search', { filtro })
          .then((res) => {
            setItems(res.data?.contracts);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'Habilities':
        await axios
          .post('/hability/getHabilities/search', { filtro })
          .then((res) => {
            setItems(res.data?.habilities);
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      case 'Payments':
        await axios
          .post('/payment/getPayments/search', {
            id_organizaciones: organizationId,
            filtro,
          })
          .then((res) => {
            if (res.data?.payments.length > 0) {
              setItems(res.data?.payments);
              //setCountItems(res.data?.payments?.length);
            }
            setLoading(false);
          })
          .catch((e) => {
            handleError();
          });
        break;
      default:
        setItems(
          items.filter(
            (data) =>
              data.nombre?.toLowerCase().includes(filtro.toLowerCase()) ||
              data.organizaciones?.nombre.toLowerCase().includes(filtro.toLowerCase()) ||
              data.modulos?.nombre.toLowerCase().includes(filtro.toLowerCase()) ||
              data.vencimiento?.toLowerCase().includes(filtro.toLowerCase()) ||
              data.codigo?.toLowerCase().includes(filtro.toLowerCase()) ||
              data.fecha_certificacion?.toLowerCase().includes(filtro.toLowerCase()) ||
              data.nombre_privado?.toLowerCase().includes(filtro.toLowerCase()) ||
              data.planes?.nombre?.toLowerCase().includes(filtro.toLowerCase()) ||
              data?.valor_estado?.toLowerCase().includes(filtro.toLowerCase()) ||
              moment(data.fecha_inicio).format('DD/MM/YYYY').toLowerCase().includes(filtro.toLowerCase()) ||
              moment(data.vencimiento).format('DD/MM/YYYY').toLowerCase().includes(filtro.toLowerCase())
          )
        );
        if (setLoading) setLoading(false);
        break;
    }
  };

  const handleClear = async (e) => {
    setSearchValue('');
    await handleSubmit(e, 'clear');
  };

  const handleError = async () => {
    Swal.fire({
      icon: 'error',
      iconColor: colors.primary,
      text: t('alerts:alerts.error-ocurred'),
      showConfirmButton: false,
      timer: 3000,
    });
    setLoading(false);
  };

  const handleEnter = async (e) => {
    if (e.charCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <div className={classes.header}>
      <TextField
        id="filtro"
        label={t('search.search')}
        variant="outlined"
        type="text"
        name="filtro"
        size="small"
        value={searchValue}
        onChange={handleChange}
        onKeyPress={handleEnter}
        className={classes.input}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchValue && (
                <Tooltip title={t('buttons:buttons.cancel')}>
                  <IconButton onClick={() => handleClear()}>
                    <CloseOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Tooltip title={t('search.search')}>
        <Button color="primary" variant="contained" onClick={handleSubmit} disableElevation>
          <SearchOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  setSearchValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
