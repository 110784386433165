import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  FindInPageOutlined,
  ContentCopyOutlined,
  DeleteForeverOutlined,
  CloseOutlined,
  LaunchOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import Template from '../Templates';
import SkeletonTables from '../../../components/SkeletonTable';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, setReturnRoute, setDetails } from '../../../actions';
import { changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import Swal from 'sweetalert2';
import EditIcon from '../../../assets/static/icons/EditIcon';
import Backdrop from '../../../components/Backdrop';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/styles/colors';

function Drafts(props) {
  const {
    page,
    rowsPerPage,
    changeBreadcrump,
    user,
    organizationId,
    permission,
    changePage,
    setReturnRoute,
    setDetails,
    searchValue,
  } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingCopy, setLoadingCopy] = useState(false);
  const [templatesDocuments, setTemplateDocuments] = useState([]);
  const [filtro, setFiltradas] = useState(templatesDocuments);
  const [open, setOpen] = useState(false);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [imgTemplate, setImgTemplate] = useState('');
  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);
  const classes = useStyles();

  useEffect(() => {
    changePage(0);
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    changeBreadcrump(breadCrumps());
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    if (!searchValue || searchValue === '') {
      await getTemplatesDocuments();
    }
    setLoading(false);
  };

  const breadCrumps = () => {
    return t('breadCrumps:breadCrumps.documents-edition', { returnObjects: true });
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;
  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

  const getTemplatesDocuments = async () => {
    if (isSuper) {
      const { data } = await axios.post(`/documentTemplate/getDocumentTemplatesEdited`);
      setTemplateDocuments(data.documentTemplates);
      setFiltradas(data.documentTemplates);
    } else {
      const { data } = await axios.post(`/documentTemplate/getDocumentTemplatesEdited`, {
        id_organizaciones: organizationId,
      });
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        const deparmentCreaterData = data.documentTemplates?.filter(
          (template) => template.plantillasDocumentosHistorial[0].id_usuarios === user.data_user.id
        );
        setTemplateDocuments(deparmentCreaterData);
        setFiltradas(deparmentCreaterData);
      } else {
        setTemplateDocuments(data.documentTemplates);
        setFiltradas(data.documentTemplates);
      }
    }
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'copy':
        modalCopy(e, id);
        break;
      case 'edit':
        setReturnRoute('/templates/documents/edited');
        history.push(`/templates/documents/edit/${encrypt(id)}`);
        break;
      case 'publish':
        modalPublish(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalCopy = (e, id) => {
    Swal.fire({
      text: t('alerts:alerts.copy-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendCopy(id, e);
      }
    });
  };

  const modalPublish = (id) => {
    Swal.fire({
      text: t('alerts:alerts.publish-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendPublish(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-documents'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendCopy = async (id, e) => {
    e.preventDefault();
    setLoadingCopy(true);
    const template = templatesDocuments?.filter((template) => template.id === id);
    const templateCopy = {
      ...template[0],
      copy: true,
      nombre: `${template[0]?.nombre} copia`,
      nombre_privado: `${template[0]?.nombre_privado === null ? '' : `${template[0]?.nombre_privado} copia`}`,
      id_usuarios: user.data_user?.id,
      id_organizaciones: organizationId,
      typeImagen: template[0]?.imagen.includes('Pdf') ? '' : 'newImage',
      documentsFields: template[0]?.documentoCampos.map((item) => ({
        fieldName: item.nombre,
        fieldType: item.tipo_campo_vp,
        exampleText: item.texto_ejemplo,
        fontFamily: { family: item.tipo_letra },
        fontSize: item.tamano_letra,
        fontColor: { hex: item.color },
        rectColor: { hex: item.background_color },
        fontStyle: {
          bold: item.bold,
          italic: item.italic,
          underline: item.underline,
          strikethrough: item.strikethrough,
        },
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height,
        page: item.page,
        align: item.formato_letra || 'center',
      })),
    };
    axios
      .post(`/documentTemplate`, templateCopy)
      .then((res) => {
        setLoadingCopy(false);
        loadData();
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts.copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        setLoadingCopy(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.copied-error'),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const sendPublish = async (id) => {
    const { data } = await axios
      .put(`documentTemplate/updateState/${id}`, {
        estado: 1,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.published'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/templates/documents/published');
      });
    }
  };

  const sendDelete = async (id) => {
    const { data } = await axios
      .delete(`/documentTemplate/${id}`, {
        data: { id_usuarios: user.data_user?.id },
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')} className={classes.iconButton}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const copy_permission = (id) => {
    if (permission.includes('Copiar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.copy')}>
          <IconButton aria-label="copy" onClick={(e) => handleClick(e, id, 'copy')} className={classes.iconButton}>
            <ContentCopyOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const publish_permission = (id) => {
    if (permission.includes('Publicar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.publish')}>
          <IconButton
            aria-label="publish"
            onClick={(e) => handleClick(e, id, 'publish')}
            className={classes.iconButton}
          >
            <LaunchOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')} className={classes.iconButton}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const handleOpen = (linkPhotoTemplate, width, height) => {
    setImgTemplate({ photo: linkPhotoTemplate, width: width, height: height });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const organizationColumn = () => {
    if (isSuper) {
      return t('tables:table.columns-documents-edited-super', { returnObjects: true });
    } else {
      return t('tables:table.columns-documents-edited', { returnObjects: true });
    }
  };

  const organizationName = (row) => {
    if (isSuper) {
      return <TableCell align="center">{row.organizaciones.nombre}</TableCell>;
    }
  };

  const goToDetails = (row) => {
    setDetails(row);
    history.push(`/templates/documents/edited/details/${encrypt(row.id)}`);
  };

  return (
    <Template Index={1}>
      <Search
        tableName="templateDocument"
        type="edited"
        items={templatesDocuments}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        deparmentCreater={{ isDeparmentCreater, isDeparmentCreaterRoll, userId: user.data_user.id }}
        setLoading={setLoading}
      />
      <Table
        columns={organizationColumn()}
        rows={filtro}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
      >
        <TableBody>
          {filtro.length > 0 ? (
            loading ? (
              <>
                <SkeletonTables columns={organizationColumn()} photo={1} />
              </>
            ) : (
              <>
                {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <Tooltip title={t('tables:table.tooltip.view-template')}>
                          <Avatar
                            alt="Remy Sharp"
                            variant="square"
                            src={row.linkPhotoTemplate}
                            className={classes.large}
                            onClick={() => handleOpen(row.linkPhotoTemplate, row.width, row.height)}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{row.nombre}</TableCell>
                      <TableCell align="center">{row.nombre_privado}</TableCell>
                      {organizationName(row)}
                      <TableCell align="center">
                        {moment(row.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell align="center">{row.estado === 0 ? t('tables:table.state.edited') : ''}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.details')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={() => goToDetails(row)}
                            className={classes.iconButton}
                          >
                            <FindInPageOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{edit_permission(row.id)}</TableCell>
                      <TableCell align="center">{publish_permission(row.id)}</TableCell>
                      <TableCell align="center">{copy_permission(row.id)}</TableCell>
                      <TableCell align="center">{delete_permission(row.id)}</TableCell>
                    </TableRow>
                  ))}
              </>
            )
          ) : loading ? (
            <SkeletonTables columns={organizationColumn()} photo={1} />
          ) : (
            <TableCell align="center" colSpan="6">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.btnClose}`}
            onClick={handleClose}
          >
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={imgTemplate.photo}
            className={imgTemplate.width > imgTemplate.height ? classes.img_horizontal : classes.img_vertical}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loadingCopy} />
    </Template>
  );
}

const useStyles = makeStyles((theme) => ({
  large: {
    width: 'auto',
    height: theme.spacing(10),
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnClose: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
  img_vertical: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
  img_horizontal: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
  iconButton: {
    margin: '0em -.4em',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'plantillas documentos')
      .map((item) => item.acciones.nombre),
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
  setReturnRoute,
  setDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Drafts);
