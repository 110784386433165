import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Divider,
  Button,
  Card,
  makeStyles,
  CardContent,
  Avatar,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import {
  ContentCopyOutlined,
  ArchiveOutlined,
  DeleteForeverOutlined,
  VisibilityOutlined,
  LaunchOutlined,
  CloseOutlined,
  Brightness5,
} from '@mui/icons-material';

import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';
import Template from './Index';
import colors from '../../../../assets/styles/colors';
import axios from '../../../../api';
import EditIcon from '../../../../assets/static/icons/EditIcon';
import { changeBreadcrump, setReturnRoute } from '../../../../actions';
import { encrypt, decrypt } from '../../../../utils/crypt';
import PreviewCertificate from '../../../../components/PreviewDialogCertificate';
import Backdrop from '../../../../components/Backdrop';
import { useTranslation } from 'react-i18next';

function Details(props) {
  const { user, changeBreadcrump, permission, setReturnRoute } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [diplomaTemplate, setDiplomaTemplate] = useState({});
  const [diplomaIssued, setDiplomaIssued] = useState([]);
  const [skills, setSkills] = useState([]);
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [type, setType] = useState({});
  const [criteria, setCriteria] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openSessions, setOpenSessions] = useState(false);
  const classes = useStyles({ diplomaTemplate });
  const [vista, setVista] = useState({});
  const [templateCopy, setTemplateCopy] = useState({});
  const [permissionDepartament, setPermissionDepartament] = useState(false);
  const [t] = useTranslation(['certificateBadgeTemplate', 'tables', 'alerts', 'buttons', 'breadCrumps']);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getDiplomaTemplate();
    setLoading(false);
  };

  const getDiplomaTemplate = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
      const isDeparmentCreaterRoll =
        user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

      const { data } = await axios.get(`/diplomaTemplate/${id}`);
      setDiplomaTemplate(data.diplomaTemplate);
      setDiplomaIssued(data.diplomaTemplate?.diplomaTemplate?.diplomasEmitidos);
      getTime(data.diplomaTemplate?.diplomaTemplate?.tiempo_vp);
      getLevel(data.diplomaTemplate?.diplomaTemplate?.nivel_vp);
      getCosts(data.diplomaTemplate?.diplomaTemplate?.costo_vp);
      getTypes(data.diplomaTemplate?.diplomaTemplate?.tipo_vp);
      setSkills(data.diplomaTemplate?.skillsTemplate);
      setCriteria(data.diplomaTemplate?.criterion);
      getdiplomaTemplateState(data.diplomaTemplate?.diplomaTemplate?.estado);
      setTemplateCopy({
        ...data.diplomaTemplate?.diplomaTemplate,
        copy: true,
        nombre: `${data.diplomaTemplate?.diplomaTemplate?.nombre} copia`,
        nombre_privado: `${data.diplomaTemplate?.diplomaTemplate?.nombre_privado} copia`,
        criterios: data.diplomaTemplate?.criterion,
        id_habilidades: data.diplomaTemplate?.skillsTemplate.map((item) => ({
          id_habilidades: item.id_habilidades,
        })),
        id_usuarios: user.data_user?.id,
        diplomaFields: data.diplomaTemplate?.diplomaTemplate?.diplomaCampos.map((item) => ({
          fieldName: item.nombre,
          fieldType: item.tipo_campo_vp,
          exampleText: item.texto_ejemplo,
          fontFamily: { family: item.tipo_letra },
          fontSize: item.tamano_letra,
          fontColor: { hex: item.color },
          rectColor: { hex: item.background_color },
          fontStyle: {
            bold: item.bold,
            italic: item.italic,
            underline: item.underline,
            strikethrough: item.strikethrough,
          },
          x: parseInt(item.x),
          y: parseInt(item.y),
          width: item.width,
          height: item.height,
          page: item.page,
          align: item.formato_letra || 'center',
        })),
      });
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        data.diplomaTemplate?.templatesCertificatesHistory?.filter((item) => parseInt(item.accion) === 34)[0]
          .id_usuarios === user.data_user?.id
          ? setPermissionDepartament(false)
          : setPermissionDepartament(true);
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeCertificate = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeCertificate[0]);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelCertificate = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelCertificate[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costBadge = data.costs.filter((data) => data.id === costo_vp);
    setCost(costBadge[0]);
  };

  const getTypes = async (tipo_vp) => {
    const { data } = await axios.post(`/others/getTypes`);
    const TypeBadge = data.types.filter((data) => data.id === tipo_vp);
    setType(TypeBadge[0]);
  };

  const getdiplomaTemplateState = (estado) => {
    if (estado === 1) {
      changeBreadcrump(t('breadCrumps:breadCrumps.diploma-details-published', { returnObjects: true }));
    } else {
      if (estado === 0) {
        changeBreadcrump(t('breadCrumps:breadCrumps.diploma-details-edited', { returnObjects: true }));
      } else {
        changeBreadcrump(t('breadCrumps:breadCrumps.diploma-details-archived', { returnObjects: true }));
      }
    }
  };

  const certificatesIssued = () => {
    return (
      <>
        <Grid item lg={3} md={3} xs={5}>
          <Tooltip title={t('tables:table.tooltip.preview')}>
            <Button className={classes.button} onClick={(e) => handleOpenDialog()}>
              <VisibilityOutlined />
            </Button>
          </Tooltip>
        </Grid>
        {permission.includes('Editar') && (
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.edit')}>
              <Button disabled={permissionDepartament} className={classes.button} onClick={goEdit}>
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {permission.includes('Copiar') && (
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.copy')}>
              <Button
                disabled={permissionDepartament}
                className={classes.button}
                onClick={(e) => goCopy(e, templateCopy)}
              >
                <ContentCopyOutlined />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {diplomaIssued !== 0
          ? permission.includes('Archivar') && (
              <Grid item lg={3} md={3} xs={5}>
                <Tooltip title={t('tables:table.tooltip.archive')}>
                  <Button
                    disabled={permissionDepartament}
                    className={classes.button}
                    onClick={(e) => handleClick(e, diplomaTemplate.diplomaTemplate?.id, 'archive')}
                  >
                    <ArchiveOutlined />
                  </Button>
                </Tooltip>
              </Grid>
            )
          : permission.includes('Eliminar') && (
              <Grid item lg={3} md={3} xs={5}>
                <Tooltip title={t('tables:table.tooltip.delete')}>
                  <Button
                    disabled={permissionDepartament}
                    className={classes.button}
                    onClick={(e) => handleClick(e, diplomaTemplate.diplomaTemplate?.id, 'delete')}
                  >
                    <DeleteForeverOutlined />
                  </Button>
                </Tooltip>
              </Grid>
            )}
      </>
    );
  };

  const loadButtons = () => {
    if (diplomaTemplate.diplomaTemplate?.estado === 0) {
      return (
        <>
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.preview')}>
              <Button className={classes.button} onClick={(e) => handleOpenDialog()}>
                <VisibilityOutlined />
              </Button>
            </Tooltip>
          </Grid>
          {permission.includes('Publicar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.publish')}>
                <Button
                  className={classes.button}
                  onClick={(e) => handleClick(e, diplomaTemplate.diplomaTemplate?.id, 'publish')}
                >
                  <LaunchOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Editar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.edit')}>
                <Button className={classes.button} onClick={goEdit}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Copiar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.copy')}>
                <Button
                  disabled={permissionDepartament}
                  className={classes.button}
                  onClick={(e) => goCopy(e, templateCopy)}
                >
                  <ContentCopyOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Eliminar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.delete')}>
                <Button
                  className={classes.button}
                  onClick={(e) => handleClick(e, diplomaTemplate.diplomaTemplate?.id, 'delete')}
                >
                  <DeleteForeverOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      );
    }
    if (diplomaTemplate.diplomaTemplate?.estado === 1) {
      return <>{certificatesIssued()}</>;
    }
    if (diplomaTemplate.diplomaTemplate?.estado === 2) {
      return (
        <>
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.preview')}>
              <Button className={classes.button} onClick={(e) => handleOpenDialog()}>
                <VisibilityOutlined />
              </Button>
            </Tooltip>
          </Grid>
          {permission.includes('Publicar') && (
            <Grid item lg={2} md={2} xs={5}>
              <Tooltip title={t('tables:table.tooltip.publish')}>
                <Button
                  className={classes.button}
                  onClick={(e) => handleClick(e, diplomaTemplate.diplomaTemplate?.id, 'publish')}
                >
                  <LaunchOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      );
    }
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'archive':
        modalArchive(id);
        break;
      case 'publish':
        modalPublish(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalArchive = (id) => {
    Swal.fire({
      text: t('alerts:alerts.archive-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendArchive(id);
      }
    });
  };

  const modalPublish = (id) => {
    Swal.fire({
      text: t('alerts:alerts.publish-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendPublish(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendPublish = async (id) => {
    try {
      const { data } = await axios.put(`/diplomaTemplate/updateState/${id}`, {
        estado: 1,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      });
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.published'),
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/diplomas/templates/published');
        });
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const sendArchive = async (id) => {
    try {
      const { data } = await axios.put(`/diplomaTemplate/updateState/${id}`, {
        estado: 2,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      });
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.archived'),
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/diplomas/templates/archived');
        });
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/diplomaTemplate/${id}`, { data: { id_usuarios: user.data_user?.id } });
      if (data) {
        if (diplomaTemplate.diplomaTemplate.estado === 0) {
          Swal.fire({
            text: t('alerts:alerts.deleted'),
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push('/diplomas/templates/edited');
          });
        } else {
          Swal.fire({
            text: t('alerts:alerts.deleted'),
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push('/diplomas/templates/published');
          });
        }
      }
    } catch (error) {
      history.push('/500');
    }
  };

  const goEdit = () => {
    setReturnRoute(`/diplomas/templates/published/details/${props.match.params.id}`);
    history.push(`/diplomas/templates/edit/${encrypt(diplomaTemplate.diplomaTemplate.id)}`);
  };

  const goCopy = (e, templateCopy) => {
    Swal.fire({
      text: t('alerts:alerts.copy-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendCopy(templateCopy, e);
      }
    });
  };

  const sendCopy = (templateCopy, e) => {
    e.preventDefault();
    setLoadingBackdrop(true);
    axios
      .post(`/diplomaTemplate`, templateCopy)
      .then((res) => {
        setLoading(false);
        history.push('/diplomas/templates/edited');
        Swal.fire({
          icon: 'success',
          text: t('alerts:alerts.copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        setLoadingBackdrop(false);
        Swal.fire({
          icon: 'error',
          text: t('alerts:alerts.copied-error'),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleOpenDialog = () => {
    setVista({
      ...diplomaTemplate?.diplomaTemplate,
      criterios: diplomaTemplate?.criterion,
      plantillasHabilidades: diplomaTemplate?.skillsTemplate,
      linkPhotoTemplate: diplomaTemplate?.linkPhotoTemplate[0],
      photoOrganization: diplomaTemplate?.linkPhotoOrganization,
      niveles: level?.valor_parametro,
      costos: cost?.valor_parametro,
      tiempos: time?.valor_parametro,
      tipos: type?.valor_parametro,
    });

    setOpenPreview(true);
  };
  const handleCloseDialog = () => {
    setOpenPreview(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSessions = () => {
    setOpenSessions(false);
  };

  return (
    <Template Index={0} Buttons={loadButtons()} diplomaId={decrypt(props.match.params.id)}>
      <Card>
        <CardContent className={classes.root} maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item lg={5} md={5} xs={12}>
              <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                {loading ? (
                  <Skeleton variant="circle">
                    <Avatar className={classes.avatar} />
                  </Skeleton>
                ) : (
                  <Tooltip title={t('tables:table.tooltip.view-template')}>
                    <Avatar
                      alt="img-prev"
                      src={
                        diplomaTemplate.linkPhotoTemplate
                          ? diplomaTemplate.linkPhotoTemplate[0]
                          : diplomaTemplate.linkPhoto && diplomaTemplate.linkPhoto[0]
                      }
                      variant="square"
                      className={classes.avatar}
                      onClick={() => setOpen(true)}
                    >
                      <Brightness5 sx={{ margin: 4, fontSize: '4em' }} />
                    </Avatar>
                  </Tooltip>
                )}
              </div>
            </Grid>
            <Grid item lg={7} md={7} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={6} xs={12}>
                  <Typography variant="h4" gutterBottom className={classes.textTitle}>
                    {loading ? <Skeleton /> : `${diplomaTemplate.diplomaTemplate?.nombre}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={12}>
                  <Typography variant="h6" gutterBottom className={classes.textTitle}>
                    {loading ? <Skeleton /> : `${diplomaTemplate.diplomaTemplate?.nombre_privado}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={12}>
                  <Typography className={classes.textParagraph}>
                    {loading ? <Skeleton /> : `${diplomaTemplate.diplomaTemplate?.descripcion}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={12}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.issued-by')}: </span>
                      <span className={classes.text}>{diplomaTemplate.diplomaTemplate?.organizaciones?.nombre}</span>
                    </>
                  )}
                </Grid>
                <Grid item lg={12} md={6} xs={12}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.created')}: </span>
                      <span className={classes.text}>
                        {moment(diplomaTemplate.diplomaTemplate?.fecha_creacion).format('DD-MM-YYYY')}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.root} />
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12} justify="center">
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span className={classes.textBolder}>
                    {skills.length > 0 ? `${t('certificateBadgeTemplate:details.skills')}:` : ''}
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {loading ? (
                    <Skeleton variant="rect" width="100%" height="5em" />
                  ) : (
                    <>
                      {skills.map((data) => (
                        <Box display="flex">
                          <Box borderRadius={10} {...defaultPropsUser}>
                            <Typography>{data.habilidades.descripcion}</Typography>
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <Grid item lg={5} md={5} xs={12}>
              <Grid container spacing={1}>
                {type?.valor_parametro && (
                  <Grid item lg={12} md={6} xs={6}>
                    {loading ? (
                      <>
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.type')}: </span>
                        <span className={classes.text}>{type.valor_parametro}</span>
                      </>
                    )}
                  </Grid>
                )}
                {level?.valor_parametro && (
                  <Grid item lg={12} md={6} xs={6}>
                    {loading ? (
                      <>
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.level')}: </span>
                        <span className={classes.text}>{level.valor_parametro}</span>
                      </>
                    )}
                  </Grid>
                )}
                {loading ? (
                  <>
                    <Grid item lg={12} md={6} xs={6}>
                      <Skeleton variant="text" />
                    </Grid>
                  </>
                ) : (
                  <>
                    {diplomaTemplate?.diplomaTemplate?.cantidad_tiempo !== null &&
                    time?.valor_parametro !== undefined ? (
                      <Grid item lg={12} md={6} xs={6}>
                        <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.time')}: </span>
                        <span
                          className={classes.text}
                        >{`${diplomaTemplate?.diplomaTemplate?.cantidad_tiempo} ${time?.valor_parametro}`}</span>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </>
                )}
                {cost?.valor_parametro && (
                  <Grid item lg={12} md={6} xs={6}>
                    {loading ? (
                      <>
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.cost')}: </span>
                        <span className={classes.text}>{cost.valor_parametro}</span>
                      </>
                    )}
                  </Grid>
                )}
                {criteria?.length > 0 && (
                  <>
                    <Grid item lg={12} md={6} xs={12}>
                      <Typography className={classes.textBolder}>
                        {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.requirements-certificate')}: `}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {loading ? (
                        <>
                          <Skeleton variant="text" width={200} />
                        </>
                      ) : (
                        <>
                          {criteria.map((data, index) => (
                            <Typography key={`criteria${index}`} className={classes.text} gutterBottom>
                              {data.descripcion_criterio}
                            </Typography>
                          ))}
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.buttonForm}`} onClick={handleClose}>
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={
              diplomaTemplate.linkPhotoTemplate
                ? diplomaTemplate.linkPhotoTemplate[0]
                : diplomaTemplate.linkPhoto && diplomaTemplate.linkPhoto[0]
            }
            className={classes.img}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openPreview} onClose={handleCloseDialog} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle align="center" onClose={handleCloseDialog}>
          {t('dialogs:dialog.preview-title-template-certificate')}
        </DialogTitle>
        <DialogContent dividers>
          <PreviewCertificate vista={vista}></PreviewCertificate>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.buttonForm}`} onClick={handleCloseDialog}>
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSessions} onClose={handleCloseSessions} fullWidth={true} maxWidth={'lg'}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography className={classes.textBolder} style={{ marginTop: '12px' }}>
                Sesiones
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Backdrop loading={loadingBackdrop} />
    </Template>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: (props) => ({
    maxWidth: '70vw',
    height: props.diplomaTemplate?.diplomaTemplate?.height,
  }),
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
    margin: '1em 0 1em 0',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '8px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  certificate: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(195px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(323px, 1fr))',
      margin: '30px 30px 0px 30px',
      '& div': {
        marginBottom: 20,
        justifySelf: 'center',
      },
    },
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(423px, 1fr))',
      margin: '30px 30px 0px 30px',
      '& div': {
        marginBottom: 20,
        justifySelf: 'center',
      },
    },
  },
  buttonForm: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'certificados')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  setReturnRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
