import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  FormControl,
  Button,
  Typography,
  Avatar,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { BusinessOutlined, CloseOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import axiosFile from 'axios';
import { useTranslation } from 'react-i18next';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import LinkIcon from '../../../assets/img/cadena.png';
import { changeBreadcrump } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import { env } from '../../../config/environment';
import colors from '../../../assets/styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.1em',
    padding: '10px 40px',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    padding: 5,
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '4em',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  añadir: {
    marginTop: '1em',
    marginRight: '5rem',
  },
  iconMargin: {
    margin: theme.spacing(2),
  },
  añadirButton: {
    color: '#0056b3',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  },
}));

function CreateOrganizations(props) {
  const { organizationId, changeBreadcrump, user } = props;
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({
    identificacion: '',
    nombre: '',
    logo: null,
    telefono: '',
    celular: '',
    correo: '',
    direccion: '',
    sitio_web: '',
    biografia: '',
    id_paises: null,
    id_ciudades: null,
    url_perfil: '',
    prefijo_telefono: null,
    img_prev: '',
    id_organizaciones:
      organizationId === env.idCmlogica ? organizationId : organizationId === env.idOrquidea ? organizationId : '',
    email_usuario: user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_organizaciones
      ? ''
      : user?.data_user?.cuentas[0]?.correo,
  });

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps', 'users']);

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [redesSociales, setRedesSociales] = useState([]);
  const [socialNetworks, setsocialNetworks] = useState([{ index: 1, id_redes_sociales: '', link: '' }]);
  const [cities, setCities] = useState([]);
  const [prefix, setPrefix] = useState([]);
  const patron = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  );

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    await getCountries();
    await getRedesSociales();
    setPrefix('');
    if (props.match.params.id) {
      await getOrganizations();
      changeBreadcrump(t('breadCrumps:breadCrumps.settings-organizations-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.settings-organizations-create', { returnObjects: true }));
    }
  };

  useEffect(() => {
    getCities(form.id_paises).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.id_paises]);

  const getOrganizations = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/organization/${id}`);
    const { organizations, socialNetworkUserOrganizations } = data.organization;
    setForm({
      ...organizations,
      logo: organizations?.linkPhoto,
      img_prev: organizations?.linkPhoto,
    });

    if (socialNetworkUserOrganizations?.length > 0) {
      const socialNetworkUserOrganizationsSinLink = socialNetworkUserOrganizations.filter(
        (item) => item.link !== organizations.sitio_web,
      );
      if (socialNetworkUserOrganizationsSinLink.length > 0) {
        setsocialNetworks(
          socialNetworkUserOrganizationsSinLink.map((item, index) => ({
            index,
            id_redes_sociales: item.id_redes_sociales,
            user_name: item.username,
            icono: item.linkIcons,
            link: item.link,
          })),
        );
      } else {
        setsocialNetworks([{ index: 1, id_redes_sociales: '', link: '', icono: '' }]);
      }
    }

    if (organizations.id_paises) {
      const CitiesPrefix = await getPrefix(organizations.id_paises).catch(() => {
        history.push('/500');
        window.location.reload();
      });
      const item = CitiesPrefix.find((item) => item.id === organizations.id_ciudades);
      setPrefix(`(${item.prefijo_telefono})`);
    }
  };

  const getCountries = async () => {
    const { data } = await axios.post(`/others/getCountries`);
    setCountries(data.countries);
  };

  const getRedesSociales = async () => {
    const { data } = await axios.post(`/socialNetworks/getSocialNetworks`);
    setRedesSociales(data.socialNetworks);
    return data.socialNetworks;
  };

  const getCities = async (idPais) => {
    if (idPais) {
      const { data } = await axios.post(`/others/getCities`, { idPais });
      setCities(data.cities);
    } else {
      setCities(['']);
    }
  };

  const getIdentification = async (identificacion) => {
    const { data } = await axios.post(`/organization/getNit`, { identificacion });
    return data.nit;
  };

  const getPrefix = async (idPais) => {
    const { data } = await axios.post(`/others/getCities`, { idPais });
    return data.cities;
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCountry = (event) => {
    setPrefix('');
    if (event.target.value === '') {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
        id_ciudades: '',
      });
    }
  };

  const handleChangeCity = async (event) => {
    if (event.target.value === '') {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
      setPrefix('');
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
      const CitiesPrefix = await getPrefix(form.id_paises).catch(() => {
        history.push('/500');
        window.location.reload();
      });
      const item = CitiesPrefix.find((item) => item.id === event.target.value);
      if (item.prefijo_telefono) {
        setPrefix(`(${item.prefijo_telefono})`);
      } else {
        setPrefix('');
      }
    }
  };

  const handleChangeSocialNetwork = (event, index) => {
    if (event.target.name === 'id_redes_sociales') {
      socialNetworks[index]['icono'] = redesSociales.find((redSocial) => redSocial.id === event.target.value).linkIcons;
    }

    socialNetworks[index][event.target.name] = event.target.value;
    setsocialNetworks([...socialNetworks]);
  };

  const addSocialNetworks = () => {
    const last = socialNetworks[socialNetworks.length - 1];
    setsocialNetworks([...socialNetworks, { index: last.index + 1, id_redes_sociales: '', link: '' }]);
  };

  const deleteSocialNetworks = (e, index) => {
    if (socialNetworks.length !== 1) {
      const socialNetworksDeleted = socialNetworks.filter((item) => item.index !== e);
      setsocialNetworks(socialNetworksDeleted);
    } else {
      setsocialNetworks([{ index: 1, id_redes_sociales: '', link: '' }]);
    }
  };

  const handleFile = (e) => {
    const logo = e.target.files[0];
    setForm({
      ...form,
      logo,
      img_prev: URL.createObjectURL(logo),
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleCancel = () => {
    history.push('/organizations');
  };

  const validadLink = (obj) => {
    if ('link' in obj) {
      if (obj.id_redes_sociales !== 2) {
        if (obj.id_redes_sociales !== 6) {
          if (!patron.test(obj.link)) {
            if (obj.link.length === 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      }
    }
  };

  const linkRedes = socialNetworks.filter(validadLink);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleToggle();

    let nit = '';
    if (form.identificacion) {
      nit = await getIdentification(form.identificacion).catch((e) => {
        history.push('/500');
        window.location.reload();
      });
    }

    if (linkRedes.length > 0) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.url-social-invalid'),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let { validador, mensage } = '';
      // eslint-disable-next-line
      socialNetworks.map((valida, index) => {
        if (valida.id_redes_sociales === '' && valida.link !== '') {
          validador = false;
          mensage = t('alerts:alerts.select-social-network');
        }
        if (valida.link === '' && valida.id_redes_sociales !== '') {
          validador = false;
          mensage = t('alerts:alerts.link-social-network');
        }
        if (valida.id_redes_sociales === 2 && !valida.link.includes('https://')) {
          socialNetworks[index].link = `https://api.whatsapp.com/send?phone=${valida.link}`;
        } else if (valida.id_redes_sociales === 6 && !valida.link.includes('https://')) {
          if (valida.link.includes('@')) {
            const noArroba = valida.link.split('@');
            socialNetworks[index].link = `https://telegram.me/${noArroba[1]}`;
          } else {
            socialNetworks[index].link = `https://telegram.me/${valida.link}`;
          }
        } else {
          if (!valida.link.includes('https://')) {
            socialNetworks[index].link = `https://${valida.link}`;
          }
          if (valida.link.includes('twitter')) {
            const user_name = valida.link.split('https://twitter.com/');
            valida['user_name'] = `@${user_name[1]}`;
            return valida;
          }
        }
      });
      if (validador === false) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: `${mensage}`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        if (!patron.test(form.sitio_web) && form.sitio_web) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.url-website-error'),
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          if (form.sitio_web && !form.sitio_web.includes('https://') && !form.sitio_web.includes('http://')) {
            form.sitio_web = `https://${form.sitio_web}`;
          }
          if (form.logo) {
            if (!props.match.params.id) {
              const formPhoto = new FormData();
              formPhoto.append('file', form.logo);

              if (form.identificacion && nit !== null) {
                Swal.fire({
                  icon: 'error',
                  iconColor: colors.primary,
                  text: t('alerts:alerts.nit-error'),
                  showConfirmButton: false,
                  timer: 2500,
                });
              } else {
                axios
                  .post(`/organization/`, { ...form, logo: form.logo?.name, redesSociales: socialNetworks })
                  .then((res) => {
                    if (form.logo?.name) {
                      const md5Route = md5(`certika-organization-${res.data.organizationId.id}`).toString();
                      axiosFile({
                        method: 'post',
                        url: `${env.apiURL}/file/upload-organization-image/${md5Route}`,
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: formPhoto,
                      }).then(() => {
                        handleClose();
                        history.push('/organizations');
                        Swal.fire({
                          icon: 'success',
                          iconColor: colors.primary,
                          text: t('alerts:alerts.created'),
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      });
                    } else {
                      handleClose();
                      history.push('/organizations');
                      Swal.fire({
                        icon: 'success',
                        iconColor: colors.primary,
                        text: t('alerts:alerts.created'),
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  })
                  .catch((error) => {
                    handleClose();
                    Swal.fire({
                      icon: 'error',
                      iconColor: colors.primary,
                      text: t('alerts:alerts.created-error'),
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    history.push('/500');
                    window.location.reload();
                  });
              }
            } else {
              const id = decrypt(props.match.params.id);
              const formPhoto = new FormData();
              formPhoto.append('file', form.logo);

              axios
                .put(`/organization/${id}`, { ...form, logo: form.logo?.name, redesSociales: socialNetworks })
                .then((res) => {
                  if (form.logo.name) {
                    const md5Route = md5(`certika-organization-${id}`).toString();
                    axiosFile({
                      method: 'post',
                      url: `${env.apiURL}/file/upload-organization-image/${md5Route}`,
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      data: formPhoto,
                    }).then(() => {
                      handleClose();
                      history.push('/organizations');
                      Swal.fire({
                        icon: 'success',
                        iconColor: colors.primary,
                        text: t('alerts:alerts.edited'),
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    });
                  } else {
                    handleClose();
                    history.push('/organizations');
                    Swal.fire({
                      icon: 'success',
                      iconColor: colors.primary,
                      text: t('alerts:alerts.edited'),
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                })
                .catch((error) => {
                  handleClose();
                  Swal.fire({
                    icon: 'error',
                    iconColor: colors.primary,
                    text: t('alerts:alerts.edited-error'),
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  history.push('/500');
                  window.location.reload();
                });
            }
          } else {
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.logo-error'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    }
  };

  const loadFillDataCountries = () => {
    return countries.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataCities = () => {
    return cities.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataRedesSociales = () => {
    const redesSocialesSinLink = redesSociales.filter((item) => item.nombre !== 'Link');
    return redesSocialesSinLink
      .sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))
      .map((data) => {
        return (
          <MenuItem key={data.id} value={data.id}>
            {data.nombre}
          </MenuItem>
        );
      });
  };

  const onlyNumber = (event, id) => {
    const value = String(event.target.value);
    // eslint-disable-next-line no-useless-escape
    const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;
    if (!patron.test(value) && id === 2) {
      event.target.value = value.substr(value.length);
    }
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography component="h6" variant="h6">
              {t('users:organization.logo')}
            </Typography>
            <Grid container spacing={4} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Avatar alt="img-prev" src={form.img_prev} variant="rounded" className={classes.avatar}>
                    <BusinessOutlined sx={{ fontSize: '4em' }} />
                  </Avatar>
                </div>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Typography variant="body2" className={classes.text}>
                  {t('users:users.message-photo')}
                </Typography>

                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  name="logo"
                  type="file"
                  onChange={(e) => handleFile(e)}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    disableElevation
                    color="primary"
                    variant="outlined"
                    component="span"
                    className={classes.button}
                  >
                    {t('buttons:buttons.upload-logo')}
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:organization.nit')}
                    variant="outlined"
                    name="identificacion"
                    value={form.identificacion}
                    onChange={handleInput}
                    disabled={props.match.params.id > 0}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:organization.name')}
                    variant="outlined"
                    name="nombre"
                    value={form.nombre}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:users.email')}
                    variant="outlined"
                    name="correo"
                    type="email"
                    value={form.correo}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:organization.address')}
                    variant="outlined"
                    name="direccion"
                    value={form.direccion}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={12}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.biography')}
                    name="biografia"
                    value={form.biografia}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel>{t('users:users.country')} *</InputLabel>
                  <Select
                    id="pais"
                    name="id_paises"
                    required
                    label={t('users:users.country')}
                    onChange={handleChangeCountry}
                    value={props.match.params.id ? form.id_paises + '' : form.id_paises}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCountries()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel id="ciudadesLabel">{t('users:users.city')} *</InputLabel>
                  <Select
                    id="ciudades"
                    name="id_ciudades"
                    required
                    labelId="ciudadesLabel"
                    value={props.match.params.id ? form.id_ciudades + '' : form.id_ciudades}
                    onChange={handleChangeCity}
                    label={t('users:users.city')}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCities()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:users.phone')}
                    variant="outlined"
                    name="telefono"
                    type="number"
                    value={`${form.telefono}`}
                    onChange={handleInput}
                    onKeyUp={(e) => onlyNumber(e, 2)}
                    InputProps={
                      prefix ? { startAdornment: <InputAdornment position="start">{prefix}</InputAdornment> } : ''
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.cellphone')}
                    variant="outlined"
                    name="celular"
                    type="number"
                    value={`${form.celular}`}
                    onChange={handleInput}
                    onKeyUp={(e) => onlyNumber(e, 2)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:users.web-site')}
                    variant="outlined"
                    name="sitio_web"
                    placeholder="https://"
                    value={form.sitio_web}
                    onChange={handleInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {<Avatar alt="Remy Sharp" src={LinkIcon} className={classes.small} />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('users:organization.social-media')}
            </Typography>
            {socialNetworks.map((item, index) => (
              <Grid container key={`grid-red-social-${index}`} spacing={1} className={classes.root}>
                <Grid item xs={2} key={`red-social-${index}`}>
                  <FormControl variant="outlined" className="w-100">
                    <InputLabel id="redesSocialesLabel">
                      {t('selects:selects-autocompletes.social-network-select')}
                    </InputLabel>
                    <Select
                      id="redesSociales"
                      name="id_redes_sociales"
                      labelId="redesSocialesLabel"
                      value={item.id_redes_sociales}
                      onChange={(e) => handleChangeSocialNetwork(e, index)}
                      label="Redes sociales"
                    >
                      <MenuItem value="">
                        <em>{t('selects:selects-autocompletes.select-option')}</em>
                      </MenuItem>
                      {loadFillDataRedesSociales()}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={item.id_redes_sociales === 3 ? 5 : 9} key={`link-red-social-${index}`}>
                  <FormControl className="w-100">
                    <TextField
                      label={
                        item.id_redes_sociales === 2
                          ? t('users:organization.number-wpp')
                          : item.id_redes_sociales === 6
                            ? t('users:organization.name-user')
                            : t('users:organization.url-social')
                      }
                      variant="outlined"
                      name="link"
                      placeholder={
                        item.id_redes_sociales === 2
                          ? t('users:organization.number-wpp')
                          : item.id_redes_sociales === 6
                            ? t('users:organization.name-user')
                            : 'https://'
                      }
                      value={
                        item.id_redes_sociales === 2
                          ? item.link.split('=')[1]
                          : item.id_redes_sociales === 6
                            ? item.link.split('me/')[1]
                            : item.link
                      }
                      onChange={(e) => handleChangeSocialNetwork(e, index)}
                      onKeyUp={(e) => onlyNumber(e, item.id_redes_sociales)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {item.icono ? (
                              <>
                                <Avatar alt="Remy Sharp" src={item.icono} className={classes.small} />
                              </>
                            ) : (
                              <span> </span>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                {item.id_redes_sociales === 3 && (
                  <Grid item xs={4} key={`user-name${index}`}>
                    <FormControl className="w-100">
                      <TextField
                        label="Usuario"
                        variant="outlined"
                        name="user_name"
                        placeholder="Usuario"
                        value={item.user_name}
                        onChange={(e) => handleChangeSocialNetwork(e, index)}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={1} key={`delete-red-social-${index}`}>
                  <Tooltip title="Eliminar Red Social">
                    <IconButton
                      aria-label="delete"
                      className={classes.iconMargin}
                      size="small"
                      onClick={(e) => deleteSocialNetworks(item.index)}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
            <div className={`text-right ${classes.añadir}`}>
              <Button onClick={addSocialNetworks} className={classes.añadirButton}>
                {t('users:organization.add-social-media')}
              </Button>
            </div>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganizations);
