import React, { useState } from 'react';
import moment from 'moment';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../assets/styles/colors';
import CertificateCard from '../components/CertificateCard';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import replaceVignettes from '../utils/replaceVignettes';
import VerifiedEn from '../assets/img/icon_verified.png';

function CertificateTemplate(props) {
  const { vista, styles } = props;
  const classes = useStyles();
  const [openRequirements, setOpenRequirements] = useState(false);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openRecomendation, setOpenRecomendation] = useState(false);
  const [openFieldsAdditionals, setOpenFieldAdditionals] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [t] = useTranslation(['certificateBadgeTemplate']);
  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  let valida = '';
  if (!vista.costos && !vista.niveles && !vista.tiempos) {
    valida = 'No hay atributos';
  }
  return (
    <>
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={'text-center'}>
          <img src={vista.linkPhotoTemplate} alt="certificate-img" className={classes.img} />
        </Grid>
      </Grid>
      <Container className={classes.container} maxWidth={'lg'}>
        <Grid container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={7} lg={7}>
              <Typography variant="h4" gutterBottom className={classes.textName}>
                <>
                  {vista.nombre}
                  <img
                    alt="img_verificado"
                    src={languajeEn ? VerifiedEn : styles.icon_verificado}
                    className={classes.img_verificado}
                  />{' '}
                </>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.textParagraph} gutterBottom>
                {vista?.descripcion}
              </Typography>
            </Grid>

            <Grid item xs={12} md={1} lg={1}>
              <Typography className={classes.textBolderDate}>
                {t('certificateBadgeTemplate:details.created-by')}:
              </Typography>
            </Grid>

            <Grid item xs={12} md={9} lg={10}>
              <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  {vista.id_organizacion_hija ? (
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid item xs={1}>
                        <Avatar
                          variant="square"
                          aria-label="img_certificate"
                          src={vista.photoOrganizationHija}
                          className={classes.img_org}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.textNameOrganization}>
                          {vista.organizacionesHijas?.nombre}
                        </Typography>
                        <Link
                          className={classes.linkOrganization}
                          href={`/organization/${vista.organizacionesHijas?.url_perfil}`}
                          target="_blank"
                        >
                          {t('certificateBadgeTemplate:details.more-information')}
                        </Link>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid item xs={1}>
                        <Avatar
                          variant="square"
                          aria-label="img_certificate"
                          src={vista.photoOrganization}
                          className={classes.img_org}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.textNameOrganization}>{vista.organizaciones?.nombre}</Typography>
                        <Link
                          className={classes.linkOrganization}
                          href={`/organization/${vista.organizaciones?.url_perfil}`}
                          target="_blank"
                        >
                          {t('certificateBadgeTemplate:details.more-information')}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={2} lg={1} className={'text-center'}>
              <Typography className={classes.textBolderDate}>{t('details.date-create')}:</Typography>
              <Typography className={classes.text}>{moment(vista.fecha_creacion).format('DD-MM-YYYY')}</Typography>
            </Grid>
            {vista.criterios.length > 0 && (
              <Grid
                container
                className={classes.menuItem}
                justify="space-between"
                onClick={() => (openRequirements ? setOpenRequirements(false) : setOpenRequirements(true))}
              >
                <Grid item lg={11}>
                  <Typography className={classes.textMenuItem}>{t('details.requirements')}</Typography>
                </Grid>
                <Grid item lg={1}>
                  {openRequirements ? (
                    <RemoveOutlined sx={expandeMenuItemStyle} />
                  ) : (
                    <AddOutlined sx={expandeMenuItemStyle} />
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openRequirements} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {vista.criterios.map((data, index) =>
                    data.link === null || data.link === '' ? (
                      <Typography key={`criteria${index}`} className={classes.text}>
                        {data.descripcion_criterio}
                      </Typography>
                    ) : (
                      <a
                        href={data.link}
                        target="_blank"
                        rel="noreferrer"
                        key={`criteria${index}`}
                        className={classes.link}
                        gutterBottom
                      >
                        {data.descripcion_criterio}
                      </a>
                    )
                  )}
                </Grid>
              </Collapse>
            </Grid>

            {valida.length === 0 && (
              <Grid
                container
                className={classes.menuItem}
                justify="space-between"
                onClick={() => (openAttributes ? setOpenAttributes(false) : setOpenAttributes(true))}
              >
                <Grid item lg={11}>
                  <Typography className={classes.textMenuItem}>{t('details.attributes')}</Typography>
                </Grid>
                <Grid item lg={1}>
                  {openAttributes ? (
                    <RemoveOutlined sx={expandeMenuItemStyle} />
                  ) : (
                    <AddOutlined sx={expandeMenuItemStyle} />
                  )}
                </Grid>
              </Grid>
            )}

            <Grid container className={classes.descriptions}>
              <Grid item xs={12} md={12} lg={12}>
                <Collapse in={openAttributes} timeout="auto">
                  <Grid item lg={12} className={classes.content_descriptions}>
                    <Grid container justify="space-evenly" spacing={2} align="center">
                      {vista.niveles !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {vista.niveles}
                          </Typography>
                          <Typography>{vista.niveles ? t('details.level') : ''}</Typography>
                        </Grid>
                      )}

                      {vista.cantidad_tiempo !== null && vista?.tiempos !== undefined ? (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {`${vista?.cantidad_tiempo} ${vista?.tiempos}`}
                          </Typography>
                          <Typography>{t('details.time')}</Typography>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {vista.costos !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {vista?.costos}
                          </Typography>
                          <Typography>{vista.costos ? t('details.cost') : ''}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>

            {vista.plantillasHabilidades.length > 0 && (
              <Grid
                container
                className={classes.menuItem}
                justify="space-between"
                onClick={() => (openSkill ? setOpenSkill(false) : setOpenSkill(true))}
              >
                <Grid item lg={11}>
                  <Typography className={classes.textMenuItem}>{t('details.skills')}</Typography>
                </Grid>
                <Grid item lg={1}>
                  {openSkill ? <RemoveOutlined sx={expandeMenuItemStyle} /> : <AddOutlined sx={expandeMenuItemStyle} />}
                </Grid>
              </Grid>
            )}

            <Grid container className={classes.descriptions}>
              <Collapse in={openSkill} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {vista.plantillasHabilidades.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography className={'text-left'}>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
            </Grid>

            {vista?.recomendaciones?.length > 0 && (
              <Grid
                container
                className={classes.menuItem}
                justify="space-between"
                onClick={() => (openRecomendation ? setOpenRecomendation(false) : setOpenRecomendation(true))}
              >
                <Grid item lg={11}>
                  <Typography className={classes.textMenuItem}>{t('details.recommendations')}</Typography>
                </Grid>
                <Grid item lg={1}>
                  {openRecomendation ? (
                    <RemoveOutlined sx={expandeMenuItemStyle} />
                  ) : (
                    <AddOutlined sx={expandeMenuItemStyle} />
                  )}
                </Grid>
              </Grid>
            )}

            {vista?.recomendaciones?.length > 0 && (
              <Grid container className={classes.descriptions}>
                <Grid item xs={12} md={12} lg={12}>
                  <Collapse in={openRecomendation} timeout="auto">
                    <Grid item xs={12} md={12} lg={12} className={classes.content_descriptions}>
                      <Grid container justify="flex-start" spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <div className={classes.certificate}>
                            {vista.recomendaciones.map((recommendation) => (
                              <CertificateCard
                                publicCertificate={true}
                                templateCertificate={true}
                                certificateImage={recommendation?.linkPhotoCertificate}
                                certificateName={recommendation?.plantillasCertificadosRecomendadas?.nombre}
                                organizationName={vista?.organizaciones?.nombre}
                              />
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            )}

            {vista?.camposAdicionales?.length > 0 &&
              vista?.camposAdicionales?.map((data, index) => (
                <>
                  <Grid
                    container
                    className={classes.menuItem}
                    justify="space-between"
                    onClick={() =>
                      openFieldsAdditionals ? setOpenFieldAdditionals(false) : setOpenFieldAdditionals(index + 1)
                    }
                  >
                    <Grid item lg={11}>
                      <Typography key={`field-title${index}`} className={classes.textMenuItem}>
                        {data.titulo_campo}
                      </Typography>
                    </Grid>
                    <Grid item lg={1}>
                      {openFieldsAdditionals === index + 1 ? (
                        <RemoveOutlined sx={expandeMenuItemStyle} />
                      ) : (
                        <AddOutlined sx={expandeMenuItemStyle} />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={classes.descriptions_fields}>
                    <Collapse in={openFieldsAdditionals === index + 1} timeout="auto">
                      <Grid item lg={12} className={classes.content_descriptions_fields}>
                        <Typography key={`field-description${index}`} className={classes.text}>
                          {data.descripcion_campo.includes('-')
                            ? replaceVignettes(data.descripcion_campo)
                            : data.descripcion_campo}
                        </Typography>
                        {data.link && (
                          <Link href={data.link} target="_blank" rel="noreferrer" className={classes.link}>
                            {t('details.additional')} <FontAwesomeIcon icon={faShareSquare} size="xs" />
                          </Link>
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              ))}

            {vista?.url && (
              <Grid
                container
                className={classes.menuItem}
                justify="space-between"
                onClick={() => (openMoreInfo ? setOpenMoreInfo(false) : setOpenMoreInfo(true))}
              >
                <Grid item lg={11}>
                  <Typography className={classes.textMenuItem}>
                    {t('details.additional')} <FontAwesomeIcon icon={faShareSquare} size="xs" />{' '}
                  </Typography>
                </Grid>
                <Grid item lg={1}>
                  {openMoreInfo ? (
                    <RemoveOutlined sx={expandeMenuItemStyle} />
                  ) : (
                    <AddOutlined sx={expandeMenuItemStyle} />
                  )}
                </Grid>
              </Grid>
            )}

            <Grid container className={classes.descriptions}>
              <Collapse in={openMoreInfo} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  <Link href={vista.url} target="_blank" rel="noreferrer" className={classes.link}>
                    {t('details.additional')} <FontAwesomeIcon icon={faShareSquare} size="xs" />
                  </Link>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Grid container className={classes.footer}>
        <Grid item lg={12}>
          <span className={classes.textParagraph}>{t('details.right-reserved')}</span>
          <p className={classes.textName}>{styles.organizationName}</p>
          <span className={classes.textParagraph}>{moment().format('YYYY')}</span>
        </Grid>
      </Grid>
    </>
  );
}

const expandeMenuItemStyle = {
  color: '#ffffff',
  float: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '2em',
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  img: {
    maxWidth: '-webkit-fill-available',
  },
  textBolder: {
    marginTop: '1em',
    color: '#000000',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '2em',
  },
  textName: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textNameOrganization: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '4em',
    [theme.breakpoints.up('sm')]: {
      color: '#000000',
      fontWeight: 'bold',
      marginLeft: '1em',
    },
  },
  text: {
    color: '#000000',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#000000',
    margin: '1em 0 1em 0',
  },
  link: {
    color: colors.primary,
    marginLeft: '2em',
    textDecoration: 'underline',
  },
  linkOrganization: {
    color: colors.primary,
    marginLeft: '4em',
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      color: colors.primary,
      marginLeft: '1em',
      textDecoration: 'underline',
    },
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    marginTop: '1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  grid: {
    backgroundColor: colors.footer,
    marginBottom: '1em',
  },
  skeleton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    borderRadius: '10px',
    borderColor: '#ffffff',
    backgroundColor: 'white',
    paddingBottom: 2,
    marginTop: '1em',
  },
  img_user: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  img_org: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '& img': {
      height: 'min-content',
    },
  },
  cardContent: {
    padding: '0',
  },
  textBolderDate: {
    marginTop: '2em',
    color: '#000000',
    fontWeight: 'bold',
  },
  menuItem: {
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  textMenuItem: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  descriptions: {
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    //marginBottom: 10,
    textAlign: 'center',
  },
  content_descriptions: {
    margin: '1em',
  },
  descriptions_fields: {
    justifyContent: 'left',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  content_descriptions_fields: {
    margin: '1em',
    whiteSpace: 'pre-line',
    textAlign: 'left',
  },
  footer: {
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  certificate: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(190px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(323px, 1fr))',
      margin: '30px 30px 0px 30px',
      '& div': {
        marginBottom: 20,
        justifySelf: 'center',
      },
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    styles: state.styles,
  };
};

export default connect(mapStateToProps, null)(CertificateTemplate);
