import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography, CircularProgress, Fade, Button } from '@material-ui/core';
import { ArrowBackOutlined } from '@mui/icons-material';
import Appbar from '../components/Appbar';
import Verified from '../assets/img/insignia_verificado.svg';
import Revoked from '../assets/img/verificado_revocado.svg';
import Expired from '../assets/img/verificado_vencido.svg';
import moment from 'moment';
import { getPaymentMethodType } from '../utils/responseCodesPayu';

function ResponsePagePayment({ history, location }) {
  const classes = useStyles();
  const [loadIcon, setLoadIcon] = useState(false);
  const [payment, setPayment] = useState({});
  /* translation */
  const [t] = useTranslation(['payments']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoadIcon(true);
    const params = new URLSearchParams(location?.search);
    setPayment({
      transactionState: params.get('transactionState'),
      description: params.get('description'),
      processingDate: params.get('processingDate'),
      amount: params.get('TX_VALUE'),
      currency: params.get('currency'),
      transactionId: params.get('transactionId'),
      referenceCode: params.get('referenceCode'),
      payMethodType: params.get('polPaymentMethodType'),
    });
    setLoadIcon(false);
  };

  return (
    <>
      <Appbar />
      <Container maxWidth={'lg'} className={classes.container}>
        <Grid container spacing={0} className={classes.root}>
          <Grid item xs={12} md={12} className={`text-center ${classes.grid}`}>
            {loadIcon === false ? (
              <>
                <img
                  alt="img_verificado"
                  src={
                    payment?.transactionState === '4' ? Verified : payment?.transactionState === '7' ? Expired : Revoked
                  }
                  className={classes.img_verified}
                />
                <Typography variant="h6" className={classes.textVerify}>
                  {payment?.transactionState === '4'
                    ? t('payments:approved')
                    : payment?.transactionState === '7'
                    ? t('payments:pending')
                    : payment?.transactionState === '6'
                    ? t('payments:declined')
                    : payment?.transactionState === '5'
                    ? t('payments:expired')
                    : t('payments:error')}
                </Typography>
                <Typography className={classes.text}>
                  {payment?.transactionState === '4' || payment?.transactionState === '7'
                    ? t('payments:message-approved')
                    : t('payments:message-error')}
                </Typography>
                <br />
              </>
            ) : (
              <Fade
                in={loadIcon}
                style={{
                  transitionDelay: loadIcon ? '800ms' : '0ms',
                }}
                unmountOnExit
              >
                <CircularProgress size={50} />
              </Fade>
            )}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} md={6} align="right">
                <Typography className={classes.textTitle}>{t('payments:description')}</Typography>
              </Grid>
              <Grid item xs={6} md={6} align="left">
                <Typography className={classes.text}>{payment.description}</Typography>
              </Grid>

              <Grid item xs={6} md={6} align="right">
                <Typography className={classes.textTitle}>{t('payments:value-paid')}</Typography>
              </Grid>
              <Grid item xs={6} md={6} align="left">
                <Typography className={classes.text}>
                  {`$${parseInt(payment.amount).toLocaleString('es-MX')} ${payment.currency}`}
                </Typography>
              </Grid>

              <Grid item xs={6} md={6} align="right">
                <Typography className={classes.textTitle}>{t('payments:payment-method')}</Typography>
              </Grid>
              <Grid item xs={6} md={6} align="left">
                <Typography className={classes.text}>
                  {getPaymentMethodType(parseInt(payment.payMethodType))}
                </Typography>
              </Grid>

              <Grid item xs={6} md={6} align="right">
                <Typography className={classes.textTitle}>{t('payments:payment-date')}</Typography>
              </Grid>
              <Grid item xs={6} md={6} align="left">
                <Typography className={classes.text}>{moment(payment.processingDate).format('DD/MM/YYYY')}</Typography>
              </Grid>

              <Grid item xs={6} md={6} align="right">
                <Typography className={classes.textTitle}>{t('payments:transaction-id')}</Typography>
              </Grid>
              <Grid item xs={6} md={6} align="left">
                <Typography className={classes.text}>{payment.transactionId}</Typography>
              </Grid>

              <Grid item xs={6} md={6} align="right">
                <Typography className={classes.textTitle}>{t('payments:reference-code')}</Typography>
              </Grid>
              <Grid item xs={6} md={6} align="left">
                <Typography className={classes.text}>{payment.referenceCode}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={`text-center ${classes.grid}`}>
              <Button
                onClick={() => history.push('/payments')}
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
              >
                <ArrowBackOutlined /> {t('payments:view-pay')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '2em',
  },
  grid: {
    backgroundColor: theme.palette.footer.main,
    borderRadius: '10px',
    padding: '1em',
    marginBottom: '1em',
  },
  img_verified: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  img_status_small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  textVerify: {
    fontWeight: 'bold',
  },
  card: {
    borderRadius: '10px',
    borderColor: '#5c5c5c',
    padding: '.5em',
    backgroundColor: 'white',
    paddingBottom: 0,
  },
  cardHeader: {
    padding: '.5em',
  },
  text: {
    color: '#5c5c5c',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  divider: {
    backgroundColor: '#5c5c5c',
  },
  button: {
    margin: '0.5em',
    borderRadius: 5,
    padding: '9px 40px',
  },
}));

export default ResponsePagePayment;
