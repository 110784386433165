import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Button, Paper } from '@material-ui/core';
import api from '../api';
import { decrypt } from '../utils/crypt';
import Backdrop from '../components/Backdrop';
import { env } from '../config/environment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: window.location.origin === props.env.mincienciasURL ? '20px' : 0,
      height: '100vh',
      justifyContent: 'space-around',
    },
  }),
  container_card: {
    padding: '20px',
    borderRadius: '15px',
    margin: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
      margin: '0',
    },
  },
  container__form: {
    '& h1': {
      margin: '25px 0px',
    },
    '& img': {
      width: '90%',
    },
  },
  root: {
    marginTop: '2em',
  },
  card: {
    minWidth: 275,
  },
  img: {
    //width: '100%',
    height: '100%',
  },
  button: {
    margin: '0.5em',
    width: '100%',
    padding: '9px 40px',
  },
  container__image: (props) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: window.location.origin === props.env.mincienciasURL ? '83.5vw' : '50vw',
    },
  }),
}));

function Verify(props) {
  const classes = useStyles({ env });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [styles, setStyles] = useState('');
  const [t] = useTranslation(['loginRegister', 'buttons']);

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setStyles({ Logo: styles.logo435x149, LoginLogo: styles.icono_login, LoginBackground: styles.login_background });
    setLoading(true);
    const accountId = decrypt(props.match.params.accountId);
    api.get(`user/verify/${accountId}`); /* .catch(() => {
      history.push('/500');
      window.location.reload();
    }); */
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    history.push('/sign-in');
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: window.location.origin === env.mincienciasURL ? 'none' : `url(${styles?.LoginBackground})`,
      }}
    >
      <Paper elevation={0} className={classes.container_card}>
        <div className={classes.container__form}>
          <img src={styles.Logo} alt="logo" />
          <h3 style={{ textAlign: 'center' }}>{t('loginRegister:message-verify')}</h3>
          <form onSubmit={handleSubmit}>
            <div className={`text-center ${classes.root}`}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
                type="submit"
                disableElevation
              >
                {t('buttons:buttons.sign-in')}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
      <div className={classes.container__image}>
        <img src={styles.LoginLogo} className={classes.img} alt="login-logo" />
      </div>
      <Backdrop loading={loading} />
    </div>
  );
}

export default Verify;
