import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Dashboard from '../containers/Dashboard';
import DashboardOrganization from '../containers/DashboardOrganization';
import Certificate from '../containers/certificateBadges/Certificate';
import BadgeVerify from '../containers/certificateBadges/Verify';
import CertificateVerify from '../containers/certificateCertificates/Verify';
import DocumentVerify from '../containers/certificateDocuments/Verify';
import Document from '../containers/certificateDocuments/Document';
import Carnet from '../containers/certificateCarnet/Carnet';
import CarnetVerify from '../containers/certificateCarnet/Verify';
import Layout from '../containers/Layout';
import Moduls from '../containers/ajustes/modules/Moduls';
import FormModuls from '../containers/ajustes/modules/Form';
import Actions from '../containers/ajustes/Actions';
import Permissions from '../containers/ajustes/permissions/Permissions';
import Users from '../containers/ajustes/users/Users';
import Organizations from '../containers/ajustes/organizations/Organizations';
import FormOrganizations from '../containers/ajustes/organizations/Form';
import UserGroupsOrganization from '../containers/ajustes/userGroupsOrganization/userGroupsOrganization';
import FormuserGroupsOrganization from '../containers/ajustes/userGroupsOrganization/Form';
import UsersGroup from '../containers/ajustes/userGroups/UsersGroup';
import FormUserGroup from '../containers/ajustes/userGroups/Form';
import FormUser from '../containers/ajustes/users/Form';
import FormPermission from '../containers/ajustes/permissions/Form';
import Signup from '../containers/Signup';
import VerifyAccount from '../containers/Verify';
import Login from '../containers/Login';
import Recommendations from '../containers/badges/recommendations/Recommendations';
import FormRecomendations from '../containers/badges/recommendations/Form';
import TemplatesRecommendations from '../containers/badges/recommendations/TemplatesRecommendations';
import HistoryRecommendations from '../containers/badges/recommendations/History';

import RecommendationsCertificate from '../containers/certificates/recommendations/Recommendations';
import FormRecomendationCertificate from '../containers/certificates/recommendations/Form';
import TemplatesRecommendationsCertificate from '../containers/certificates/recommendations/TemplatesRecommendations';
import HistoryRecommendationsCertificate from '../containers/certificates/recommendations/History';

import Published from '../containers/badges/templates/Published';
import Published_Details from '../containers/badges/templates/published/Details';
import Published_Insights from '../containers/badges/templates/published/Insights';
import Published_History from '../containers/badges/templates/published/History';
import Drafts from '../containers/badges/templates/Drafts';
import Archived from '../containers/badges/templates/Archived';
import CertificatePublished from '../containers/certificates/templates/Published';
import CertificateDetails from '../containers/certificates/templates/certificate/Details';
import CertificateInsights from '../containers/certificates/templates/certificate/Insights';
import CertificateHistory from '../containers/certificates/templates/certificate/History';
import CertificateEdited from '../containers/certificates/templates/Drafts';
import CertificateArchived from '../containers/certificates/templates/Archived';
import CreateCertificate from '../containers/certificates/templates/Create';
import EditCertificate from '../containers/certificates/templates/Create';
import CertificateTemplate from '../containers/CertificateTemplate';
import Certificado from '../containers/certificateCertificates/Certificate';
import CertificateRejected from '../containers/CertificateReject';

import CarnetPublished from '../containers/carnets/templates/Published';
import CarnetDetails from '../containers/carnets/templates/carnet/Details';
import CarnetHistory from '../containers/carnets/templates/carnet/History';
import CarnetEdited from '../containers/carnets/templates/Drafts';
import CarnetArchived from '../containers/carnets/templates/Archived';
import CreateCarnet from '../containers/carnets/templates/Create';
import EditCarnet from '../containers/carnets/templates/Create';

/* import CollectionsBadges from '../containers/badges/collections/Collections';
import FormCollectionsBadges from '../containers/badges/collections/Form';
import CollectionsGroupsBadges from '../containers/badges/collections/CollectionsGroupsBadges';
import HistoryCollectionsBadges from '../containers/badges/collections/History';

import CollectionsCertificates from '../containers/certificates/collections/Collections';
import FormCollectionsCertificates from '../containers/certificates/collections/Form';
import CollectionsGroupsCertificates from '../containers/certificates/collections/CollectionsGroupsBadges';
import HistoryCollectionsCertificates from '../containers/certificates/collections/History'; */

import Analytics from '../containers/analytics/Index';
import StatisticsExport from '../containers/analytics/StatisticsExport';

import TemplateId from '../containers/developers/Template-IDs';
import TemplateIdDocument from '../containers/developers/Template-IDs-documents';
import TemplateIdCertificate from '../containers/developers/Template-IDs-certificates';

import GroupsBadgesIssued from '../containers/badges/badgesIssued/GroupsBadgesIssued';
import BadgesIssued from '../containers/badges/badgesIssued/BadgesIssued';
import Issue from '../containers/badges/Issue';
import CertificateIssue from '../containers/certificates/Issue';
import GroupsCertificateIssued from '../containers/certificates/certificateIssued/GroupsCertificateIssued';
import CertificateIssued from '../containers/certificates/certificateIssued/CertificateIssued';
import Create_Template from '../containers/badges/templates/Create';
import Edit_Template from '../containers/badges/templates/Create';
import Habilities from '../containers/ajustes/habilities/Habilities';
import FormHabilities from '../containers/ajustes/habilities/Form';
import TemplatesHabilities from '../containers/ajustes/habilities/HabilitiesTemplates';
import BadgeRejected from '../containers/BadgeReject';
import BadgeAccept from '../containers/BadgeAccept';
import Badge from '../containers/Badge';
import BadgeTemplate from '../containers/BadgeTemplate';
import SocialNetworks from '../containers/ajustes/socialNetworks/SocialNetworks';
import FormSocialNetworks from '../containers/ajustes/socialNetworks/FormSocialNetworks';
import LinkedinShare from '../containers/LinkedinShare';
import LinkedinShareCertificates from '../containers/LinkedinShareCertificates';
import ForgetPassword from '../containers/account/ForgetPass';
import ChangePassword from '../containers/account/ChangePass';
import ProfileSettings from '../containers/ProfileSettings';
import CombineAccount from '../containers/account/CombineAccount';
import CombineAccept from '../containers/CombineAccept';
import ValueParameter from '../containers/ajustes/parameterValueParameter/ValueParameters';
import FormValueParameters from '../containers/ajustes/parameterValueParameter/Form';
import Contracts from '../containers/ajustes/contracts/Contract';
import ContractsOrganization from '../containers/ajustes/contracts/ContractOrganizations';
import FormContracts from '../containers/ajustes/contracts/Form';
//import VerifyFile from '../containers/VerifyFile';
import VerifyFile2 from '../containers/VerifyFile2';
import Page500 from '../containers/Page500';
import Page404 from '../containers/Page404';
import notFound from '../containers/notFound';
import ComponentTemp from '../containers/ComponentTemp';

import CreateTemplateDocuments from '../containers/documents/templates/Create';
import EditTemplateDocuments from '../containers/documents/templates/Create';
import DocumentsPublished from '../containers/documents/templates/Published';
import DocumentsPublishedDetails from '../containers/documents/templates/published/Details';
import DocumentsPublishedHistory from '../containers/documents/templates/published/History';
import DocumentsDrafts from '../containers/documents/templates/Drafts';
import DocumentsArchived from '../containers/documents/templates/Archived';
import CertifyDocuments from '../containers/documents/Certify';
import DocumentsFolders from '../containers/documents/files/Folders';
import DocumentSign from '../containers/documents/Sign';
import DocumentsFiles from '../containers/documents/files/Files';
import DocumentsFilesHistory from '../containers/documents/files/History';
import Signatories from '../containers/documents/Signatories';

import CarnetIssue from '../containers/carnets/Issue';
import GroupsCarnetIssued from '../containers/carnets/carnetIssued/GroupsCarnetIssued';
import CarnetIssued from '../containers/carnets/carnetIssued/CarnetIssued';

import HelpCenter from '../containers/HelpCenter';
import ReportesCarnets from '../containers/carnets/carnetIssued/ReportsCarnets';

import Reminders from '../containers/ajustes/reminders/Reminders';
import RemindersForm from '../containers/ajustes/reminders/Form';
import Backdrop from '../components/Backdrop';

import Payments from '../containers/ajustes/Payments';
import ResponsePage from '../containers/ResponsePagePayment';
import SignDiplomas from '../containers/diplomas/SignDiplomas';
import DiplomaIssue from '../containers/diplomas/Issue';

import DiplomasPublished from '../containers/diplomas/templates/Published';
import DiplomasDrafts from '../containers/diplomas/templates/Drafts';
import DiplomasArchived from '../containers/diplomas/templates/Archived';
import DiplomasTemplateCreate from '../containers/diplomas/templates/Create';
import DiplomasTemplateEdit from '../containers/diplomas/templates/Create';
import DiplomasPublishedDetails from '../containers/diplomas/templates/diploma/Details';
import DiplomasPublishedHistory from '../containers/diplomas/templates/diploma/History';

import RegisterOrganization from '../containers/RegisterOrganization';

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (window.localStorage.getItem('state')) {
        setLoading(true);
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return loading ? (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="sign-in" />
        </Route>
        <Redirect from="/verificar-certificado/proof" to="/verify/proof-certificate" />
        <Redirect from="/verificar/:verificationType?" to="/verify/:verificationType?" />
        <Switch location={location} key={location.pathname}>
          <Route path="/sign-up/certificate/:certificateId?/:reject?" component={Signup} />
          <Route path="/sign-up/signatorie/:documentTemplateId/:idSignature" component={Signup} />
          <Route path="/sign-up/carnet/:carnetId?/:reject?" component={Signup} />
          <Route exact path="/sign-up/:badgeId?/:reject?" component={Signup} />
          <Route path="/account/verify/:accountId" exact component={VerifyAccount} />
          <Route path="/account/forget" exact component={ForgetPassword} />
          <Route path="/account/forget/:accountId" exact component={ChangePassword} />
          <Route path="/account/changePassword" exact component={ChangePassword} />
          <Route path="/account/combine" exact component={CombineAccount} />
          <Route path="/account/combine/accept/:accountCurrentId" exact component={CombineAccept} />
          <Route path="/account/combine/accept/" exact component={CombineAccept} />
          <Route exact path="/sign-in/:badgeId?" component={Login} />
          <Route path="/sign-in/certificate/:certificateId?" component={Login} />
          <Route path="/sign-in/carnet/:carnetId?" component={Login} />
          <Route path="/sign-in/signatorie/:documentTemplateId?/:idSignature?" component={Login} />
          <Route path="/dashboard/:userUrl?" exact component={Dashboard} />
          <Route path="/dashboard/badge/:badgeId?" component={Dashboard} />
          <Route path="/dashboard/certificate/:certificateId?" component={Dashboard} />
          <Route path="/dashboard/carnet/:carnetId?" component={Dashboard} />
          <Route path="/dashboard/document/:documentTemplateId?/:idSignature?" component={Dashboard} />
          <Route path="/organization/:id" component={DashboardOrganization} />
          <Route path="/badge/reject/:badgeId" exact component={BadgeRejected} />
          <Route path="/badge/accept/:badgeId" exact component={BadgeAccept} />
          <Route path="/certificate/reject/:certificateId" exact component={CertificateRejected} />
          <Route path="/badges/:issuedBadgeId" exact component={Certificate} />
          <Route path="/certificados/:issuedBadgeId" exact component={Certificate} />
          <Route path="/certificates/verify/:issuedBadgeId" exact component={BadgeVerify} />
          <Route path="/certificates/verify/:verificationType/:issuedBadgeId" exact component={BadgeVerify} />
          <Route path="/document/:documentId" exact component={Document} />
          <Route path="/documents/verify/:documentId" exact component={DocumentVerify} />
          <Route path="/badge/:badgeId" component={Badge} />
          <Route exact path="/template/:badgeId" component={BadgeTemplate} />
          <Route exact path="/share" component={LinkedinShare} />
          <Route path="/share/certificate" component={LinkedinShareCertificates} />
          <Route path="/profileSettings" exact component={ProfileSettings} />
          <Route path="/verify/:verificationType?" component={VerifyFile2} />
          {/* <Route path="/new/verify/:verificationType?" component={VerifyFile2} /> */}
          <Route path="/new-organization" component={RegisterOrganization} />
          <Route path="/signatorie/:documentTemplateId?/:idSignature?" component={Signatories} />

          <Route exact path="/500" component={Page500} />
          <Route exact path="/404" component={Page404} />
          <Route exact path="/analytics/exportStatistics/:series" component={StatisticsExport} />
          <Route path="/template/certificate/:certificateId" component={CertificateTemplate} />
          <Route path="/certificate/:certificateId" exact component={Certificado} />
          <Route path="/verified-certificate/:certificateId" exact component={CertificateVerify} />
          <Route path="/certificate/verify/:certificateId" exact component={CertificateVerify} />
          <Route path="/verified-certificate/:verificationType/:certificateId" exact component={CertificateVerify} />
          <Route path="/carnet/:carnetId" exact component={Carnet} />
          <Route path="/carnet/verify/:carnetId" exact component={CarnetVerify} />
          <Route path="/verified-documents/:documentId" exact component={DocumentVerify} />
          <Route path="/verified-documents/:verificationType/:documentId" exact component={DocumentVerify} />
          <Route path="/verified-carnet/:carnetId" exact component={CarnetVerify} />
          <Route path="/verified-carnet/:verificationType/:carnetId" exact component={CarnetVerify} />
          <Route path="/helpCenter/:type/:id" exact component={HelpCenter} />
          <Route exact path="/create-url-perfil" component={ComponentTemp} />
          <Route exact path="/responsePage" component={ResponsePage} />
          <Route path="/document/signature/:documentTemplateId/:idSignature" exact component={Signatories} />

          {/* <Route exact path="/badgeIssued/whatsapp" component={BadgeIssuedWhatsapp} /> */}

          <Layout>
            <Route path="/modules" exact component={Moduls} />
            <Route path="/modules/create" component={FormModuls} />
            <Route path="/modules/edit/:id" component={FormModuls} />
            <Route path="/actions" exact component={Actions} />

            <Route path="/permissions" exact component={Permissions} />
            <Route path="/permissions/create" component={FormPermission} />
            <Route path="/permissions/edit/:id" component={FormPermission} />

            <Route path="/users" exact component={Users} />
            <Route path="/users/create" component={FormUser} />
            <Route path="/users/edit/:id" component={FormUser} />

            <Route path="/organizations" exact component={Organizations} />
            <Route path="/organizations/create" component={FormOrganizations} />
            <Route path="/organizations/edit/:id" component={FormOrganizations} />

            <Route path="/userGroupsOrganization" exact component={UserGroupsOrganization} />
            <Route path="/userGroupsOrganization/create" component={FormuserGroupsOrganization} />
            <Route path="/userGroupsOrganization/edit/:id" component={FormuserGroupsOrganization} />

            <Route path="/userGroups" exact component={UsersGroup} />
            <Route path="/userGroups/create" component={FormUserGroup} />
            <Route path="/userGroups/edit/:id" component={FormUserGroup} />

            <Route path="/recommendations" exact component={Recommendations} />
            <Route path="/recommendations/create" component={FormRecomendations} />
            <Route path="/recommendations/edit/:id" component={FormRecomendations} />
            <Route path="/recommendations/TemplatesRecommendations/:id" component={TemplatesRecommendations} />
            <Route path="/recommendations/history/:id" component={HistoryRecommendations} />

            <Route path="/recommendations/certificate" exact component={RecommendationsCertificate} />
            <Route path="/recommendations/certificate/create" component={FormRecomendationCertificate} />
            <Route path="/recommendations/certificate/edit/:id" component={FormRecomendationCertificate} />
            <Route
              path="/recommendations/certificate/TemplatesRecommendations/:id"
              component={TemplatesRecommendationsCertificate}
            />
            <Route path="/recommendations/certificate/history/:id" component={HistoryRecommendationsCertificate} />

            <Route path="/badgesIssued" exact component={GroupsBadgesIssued} />
            <Route path="/badgesIssued/badges/:id" component={BadgesIssued} />

            <Route path="/issue" component={Issue} />
            <Route path="/certificates/issue" component={CertificateIssue} />
            <Route path="/carnets/issue" component={CarnetIssue} />
            <Route path="/diplomas/issue" component={DiplomaIssue} />

            <Route path="/templates/published" exact component={Published} />
            <Route path="/templates/create" exact component={Create_Template} />
            <Route path="/templates/edit/:id" component={Edit_Template} />

            <Route path="/templates/published/details/:id" component={Published_Details} />

            <Route path="/templates/published/insights/:id" component={Published_Insights} />
            <Route path="/templates/published/history/:id" component={Published_History} />

            <Route path="/templates/edited" exact component={Drafts} />
            <Route path="/templates/edited/details/:id" component={Published_Details} />
            <Route path="/templates/archived" exact component={Archived} />
            <Route path="/templates/archived/details/:id" component={Published_Details} />

            <Route path="/certificates/published" exact component={CertificatePublished} />
            <Route path="/certificates/create" exact component={CreateCertificate} />
            <Route path="/certificates/edit/:id" component={EditCertificate} />

            <Route path="/certificates/details/:id" component={CertificateDetails} />
            <Route path="/certificates/history/:id" component={CertificateHistory} />

            <Route path="/certificates/certificate/insights/:id" component={CertificateInsights} />

            <Route path="/certificates/edited" exact component={CertificateEdited} />
            <Route path="/certificates/archived" exact component={CertificateArchived} />

            <Route path="/certificates/issued" exact component={GroupsCertificateIssued} />
            <Route path="/certificates/issued/:id" component={CertificateIssued} />

            <Route path="/carnets/issued" exact component={GroupsCarnetIssued} />
            <Route path="/carnets/issued/:id" component={CarnetIssued} />

            {/* <Route path="/collections" exact component={CollectionsBadges} />
            <Route path="/collections/create" component={FormCollectionsBadges} />
            <Route path="/collections/edit/:id" component={FormCollectionsBadges} />
            <Route path="/collections/badges/:id" component={CollectionsGroupsBadges} />
            <Route path="/collections/history/:id" component={HistoryCollectionsBadges} />

            <Route path="/collections/certificate" exact component={CollectionsCertificates} />
            <Route path="/collections/certificate/create" component={FormCollectionsCertificates} />
            <Route path="/collections/certificate/edit/:id" component={FormCollectionsCertificates} />
            <Route path="/collections/certificates/:id" component={CollectionsGroupsCertificates} />
            <Route path="/collections/certificate/history/:id" component={HistoryCollectionsCertificates} />
 */}
            <Route path="/templates/documents/create" exact component={CreateTemplateDocuments} />
            <Route path="/templates/documents/edit/:id" component={EditTemplateDocuments} />

            <Route path="/templates/documents/published" exact component={DocumentsPublished} />
            <Route path="/templates/documents/published/details/:id" component={DocumentsPublishedDetails} />
            <Route path="/templates/documents/published/history/:id" component={DocumentsPublishedHistory} />

            <Route path="/templates/documents/edited" exact component={DocumentsDrafts} />
            <Route path="/templates/documents/edited/details/:id" component={DocumentsPublishedDetails} />

            <Route path="/templates/documents/archived" exact component={DocumentsArchived} />
            <Route path="/templates/documents/archived/details/:id" component={DocumentsPublishedDetails} />

            <Route path="/certify" component={CertifyDocuments} />
            <Route path="/documents/files/" exact component={DocumentsFolders} />
            <Route path="/documents/sign/files/" exact component={DocumentsFolders} />
            <Route path="/documents/files/:id" exact component={DocumentsFiles} />
            <Route path="/documents/sign/files/:id" exact component={DocumentsFiles} />
            <Route path="/documents/files/history/:id" exact component={DocumentsFilesHistory} />
            <Route path="/documents/sign/files/history/:id" exact component={DocumentsFilesHistory} />
            <Route path="/documents/sign" exact component={DocumentSign} />
            <Route path="/documents/sign/edit/:id_template" exact component={DocumentSign} />
            <Route path="/documents/signed/:id" exact component={DocumentSign} />

            <Route path="/carnets/published" exact component={CarnetPublished} />
            <Route path="/carnets/create" exact component={CreateCarnet} />
            <Route path="/carnets/edit/:id" component={EditCarnet} />

            <Route path="/carnets/details/:id" component={CarnetDetails} />
            <Route path="/carnets/history/:id" component={CarnetHistory} />

            <Route path="/carnets/edited" exact component={CarnetEdited} />
            <Route path="/carnets/archived" exact component={CarnetArchived} />

            <Route path="/reports/:type" exact component={ReportesCarnets} />

            <Route path="/skills" exact component={Habilities} />
            <Route path="/skills/create" component={FormHabilities} />
            <Route path="/skills/edit/:id" component={FormHabilities} />
            <Route path="/skills/templates/:id" component={TemplatesHabilities} />

            <Route path="/analytics" component={Analytics} />

            <Route path="/developers/template-ids/badges" component={TemplateId} />
            <Route path="/developers/template-ids/documents" component={TemplateIdDocument} />
            <Route path="/developers/template-ids/certificates" component={TemplateIdCertificate} />

            <Route path="/socialNetworks" exact component={SocialNetworks} />
            <Route path="/socialNetworks/create" component={FormSocialNetworks} />
            <Route path="/socialNetworks/edit/:id" component={FormSocialNetworks} />

            <Route path="/valueParameters" exact component={ValueParameter} />
            <Route path="/valueParameters/create" component={FormValueParameters} />
            <Route path="/valueParameters/edit/:id" component={FormValueParameters} />

            <Route path="/contracts/" exact component={Contracts} />
            <Route path="/contracts/:organizations" exact component={ContractsOrganization} />
            <Route path="/contract/create/" component={FormContracts} />
            <Route path="/contracts/edit/:id" component={FormContracts} />

            <Route path="/reminders" exact component={Reminders} />
            <Route path="/reminders/create" exact component={RemindersForm} />

            <Route path="/payments" exact component={Payments} />

            <Route path="/diplomas/templates/published" exact component={DiplomasPublished} />
            <Route path="/diplomas/templates/edited" exact component={DiplomasDrafts} />
            <Route path="/diplomas/templates/archived" exact component={DiplomasArchived} />
            <Route path="/diplomas/templates/create" exact component={DiplomasTemplateCreate} />
            <Route path="/diplomas/templates/edit/:id" component={DiplomasTemplateEdit} />
            <Route path="/diplomas/templates/published/details/:id" component={DiplomasPublishedDetails} />
            <Route path="/diplomas/templates/published/history/:id" component={DiplomasPublishedHistory} />

            <Route path="/diplomas/sign" exact component={SignDiplomas} />

            {/* <Route path="/templates/documents/published/details/:id" component={DocumentsPublishedDetails} />
            <Route path="/templates/documents/published/history/:id" component={DocumentsPublishedHistory} /> */}

            <Route exact path="*">
              <Redirect to="/not-found" />
              <Route exact path="/not-found" component={notFound} />
            </Route>
          </Layout>
        </Switch>
      </Switch>
    </BrowserRouter>
  ) : (
    <Backdrop loading={!loading} />
  );
};

export default App;
