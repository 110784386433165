import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableRow, IconButton, Tooltip, Button, makeStyles } from '@material-ui/core/';
import { FindInPageOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function Habilities(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user, organizationId, changePage } = props;
  const history = useHistory();
  const classes = useStyles();
  const [habilities, setHabilities] = useState([]);
  const [templates, setTemplate] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [countItems, setCountItems] = useState();

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await getHabilities();
    await getTemplate();
    changeBreadcrump([{ name: 'Insignias' }, { name: 'Habilidades' }]);
    setLoading(false);
  };

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    return params;
  };

  const getHabilities = async () => {
    const params = getParams(0, rowsPerPage);
    const { data } = await axios.post(`/hability/getHabilities`, params);
    setHabilities(data.habilities?.rows);
    setFiltradas(data.habilities?.rows);
    setCountItems(data.habilities?.count);
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/hability/getHabilities`, params);

    const array = [
      ...habilities,
      ...data.habilities?.rows.filter((row) => !habilities.find((item) => item.id === row.id)),
    ];

    setHabilities(array);
    setFiltradas(array);
    setLoading(false);
  };

  const getTemplate = async () => {
    const { data } = await axios.post(`/templateHability/getTemplateHabilities/`);
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1) {
      setTemplate(data.templateHabilities);
    } else {
      setTemplate(
        data.templateHabilities.filter((item) => item.plantillasInsignias?.id_organizaciones === organizationId)
      );
    }
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/skills/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/skills/create/`);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: '¿Está seguro que desea eliminar este registro?',
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/hability/${id}`);
      setHabilities(habilities.filter((item) => item.id !== id));
      setFiltradas(habilities.filter((item) => item.id !== id));
      if (data) {
        Swal.fire({
          text: 'Eliminado exitosamente.',
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: 'No se pudo eliminar.',
        icon: 'error',
        iconColor: colors.primary,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const loadFillDataTemplatesTable = (id) => {
    let n = 0;
    templates.map((info) => info.id_habilidades === id && (n += 1));
    if (n === 0) {
      return <TableCell align="center">No Asignada</TableCell>;
    } else {
      return (
        <TableCell align="center">
          {n}
          <Tooltip title="Ver Plantillas Asignadas">
            <IconButton aria-label="detalles" onClick={(e) => toLink(e, id)}>
              <FindInPageOutlined />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    }
  };

  const toLink = (e, id) => {
    props.history.push(`/skills/templates/${encrypt(id)}`);
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>Crear</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title="Editar">
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title="Eliminar">
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName="Habilities"
        items={habilities}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
      />
      <Table
        columns={columns}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.descripcion}</TableCell>
                    {loadFillDataTemplatesTable(row.id)}
                    <TableCell align="center">{edit_permission(row.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="9">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const columns = [
  { id: 'habilities', label: 'Habilidades', align: 'center', orden: 'descripcion' },
  { id: 'templates', label: 'Plantillas', align: 'center' },
  { id: '', label: '', align: '' },
  { id: '', label: '', align: '' },
];

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'habilidades')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Habilities);
