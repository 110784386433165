import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Card, CardContent, Typography, Button } from '@material-ui/core';
import colors from '../assets/styles/colors';
import api from '../api';
import { decrypt } from '../utils/crypt';
import Backdrop from '../components/Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
  },
  card: {
    minWidth: 275,
  },
  img: {
    width: theme.spacing(40),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

function BadgeReject(props) {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(false);
  const [Logo, setLogo] = useState('');

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLogo(styles.logo435x149);
    setLoading(true);
    const badgeId = decrypt(props.match.params.badgeId);
    api
      .get(`/badgesIssued/reject/${badgeId}`)
      .then((res) => {
        setLoading(false);
        setMessage(res.data.message);
        setAccount(res.data.account);
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    const badgeId = props.match.params.badgeId;
    account ? history.push('/sign-in') : history.push(`/sign-up/${badgeId}/reject`);
  };

  return (
    <div className="App">
      <div className="App-header">
        <Card className={classes.card}>
          <CardContent>
            <img src={Logo} alt="logo" className={classes.img} />
            <Typography>{message}</Typography>
            <form className={classes.root} onSubmit={handleSubmit}>
              <div className={`text-center ${classes.root}`}>
                <Button variant="contained" size="small" className={`my-2 ${classes.button}`} type="submit">
                  {account ? 'Iniciar sesión' : 'Registrarme'}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
        <Backdrop loading={loading} />
      </div>
    </div>
  );
}

export default BadgeReject;
