import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import {
  WorkspacePremiumOutlined,
  CardMembershipOutlined,
  BadgeOutlined,
  DescriptionOutlined,
  DataSaverOffOutlined,
  SettingsOutlined,
  FiberNew,
  SchoolOutlined,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { changePage, setFormDocuments, setDocumentsFields, setSearchValue } from '../actions';
import { env } from '../config/environment';
import { useTranslation } from 'react-i18next';
import Chip from '../components/ChipVersion';
import CardContract from '../components/CardContract';
import IssueCard from './issueCard/IssueCard';
import Skeleton from '@material-ui/lab/Skeleton';
import colors from '../assets/styles/colors';
import axiosFile from 'axios';
import CardOrganizations from '../components/Card-Organizations';
import { encrypt } from '../utils/crypt';
import ButtonSignatureIcon from '../assets/img/signature-secondary.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
  },
  icon: {
    marginRight: '.1em',
  },
  new: {
    color: colors.primary,
    fontSize: '30px',
    width: '200px',
  },
  iconsButtons: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
  },
}));

const Menu = (props) => {
  const { permission, changePage, user, setFormDocuments, setDocumentsFields, formDocuments, setSearchValue } = props;
  const classes = useStyles();
  const history = useHistory();
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [panel4, setPanel4] = useState(false);
  const [panel5, setPanel5] = useState(false);
  const [panel6, setPanel6] = useState(false);
  const [panel7, setPanel7] = useState(false);
  const [panel8, setPanel8] = useState(false);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation(['menu']);

  useEffect(() => {
    setActions(permission.filter((data) => data.acciones.nombre.toLowerCase() === 'leer'));
    setTimeout(function () {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  const clicExpanded = (event, panel) => {
    switch (panel) {
      case 1:
        setPanel1(!panel1);
        setPanel2(false);
        setPanel3(false);
        setPanel4(false);
        setPanel5(false);
        setPanel6(false);
        setPanel7(false);
        setPanel8(false);
        break;
      case 2:
        setPanel2(!panel2);
        setPanel1(false);
        setPanel3(false);
        setPanel4(false);
        setPanel5(false);
        setPanel6(false);
        setPanel7(false);
        setPanel8(false);
        break;
      case 3:
        setPanel3(!panel3);
        setPanel1(false);
        setPanel2(false);
        setPanel4(false);
        setPanel5(false);
        setPanel6(false);
        setPanel7(false);
        setPanel8(false);
        break;
      case 4:
        setPanel4(!panel4);
        setPanel1(false);
        setPanel2(false);
        setPanel3(false);
        setPanel5(false);
        setPanel6(false);
        setPanel7(false);
        setPanel8(false);
        break;
      case 5:
        setPanel5(!panel5);
        setPanel1(false);
        setPanel2(false);
        setPanel3(false);
        setPanel4(false);
        setPanel6(false);
        setPanel7(false);
        setPanel8(false);
        break;
      case 6:
        setPanel6(!panel6);
        setPanel1(false);
        setPanel2(false);
        setPanel3(false);
        setPanel4(false);
        setPanel5(false);
        setPanel7(false);
        setPanel8(false);
        break;
      case 7:
        setPanel7(!panel7);
        setPanel1(false);
        setPanel2(false);
        setPanel3(false);
        setPanel4(false);
        setPanel5(false);
        setPanel6(false);
        setPanel8(false);
        break;
      case 8:
        setPanel8(!panel8);
        setPanel1(false);
        setPanel2(false);
        setPanel3(false);
        setPanel4(false);
        setPanel5(false);
        setPanel6(false);
        setPanel7(false);
        break;
      default:
        break;
    }
  };

  const ruta = async (event, ruta) => {
    if (
      (!window.location.pathname.includes('certify') && ruta === '/certify') ||
      (!window.location.pathname.includes('/templates/documents/published') &&
        ruta === '/templates/documents/published') ||
      (!window.location.pathname.includes('sign') && ruta === '/documents/sign')
    ) {
      if (formDocuments?.fileName) {
        const infoDelete = new FormData();
        infoDelete.append('fileName', formDocuments?.fileName);
        infoDelete.append('numPages', formDocuments?.pages);
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/delete-files-local`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: infoDelete,
        });
      }
      setFormDocuments(null);
      setDocumentsFields(null);
    }
    changePage(0);
    setSearchValue('');
    history.push(ruta);
  };

  const badges_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('plantillas') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/templates/published')}>
              <ListItemText inset primary={t('menu:templates')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('recomendaciones') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/recommendations')}>
              <ListItemText inset primary={t('menu:recommendations')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('emitir') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/issue')}>
              <ListItemText inset primary={t('menu:issue')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('insignias emitidas') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/badgesIssued')}>
              <ListItemText inset primary={t('menu:badge-issued')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('insignias reportes') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/reports/badge')}>
              <ListItemText inset primary={t('menu:reports')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('ids plantillas') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/developers/template-ids/badges')}>
              <ListItemText inset primary={t('menu:id-templates-badges')} />
            </ListItem>
          </List>
        )}
      </div>
    );
  };

  const certificates_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('certificados') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/certificates/published')}>
              <ListItemText inset primary={t('menu:templates')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('recomendaciones certificados') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/recommendations/certificate')}>
              <ListItemText inset primary={t('menu:recommendations')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('emitir certificados') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/certificates/issue')}>
              <ListItemText inset primary={t('menu:issue')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('certificados emitidos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/certificates/issued')}>
              <ListItemText inset primary={t('menu:certificate-issued')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('certificados reportes') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/reports/certificate')}>
              <ListItemText inset primary={t('menu:reports')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('ids plantillas certificados') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/developers/template-ids/certificates')}>
              <ListItemText inset primary={t('menu:id-templates-certificates')} />
            </ListItem>
          </List>
        )}
      </div>
    );
  };

  const documents_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('plantillas documentos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/templates/documents/published')}>
              <ListItemText inset primary={t('menu:templates')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('certificar') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/certify')}>
              <ListItemText inset primary={t('menu:certify')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('archivos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/documents/files')}>
              <ListItemText inset primary={t('menu:archives')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('ids plantillas documentos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/developers/template-ids/documents')}>
              <ListItemText inset primary={t('menu:id-templates-documents')} />
            </ListItem>
          </List>
        )}
      </div>
    );
  };

  const signatures_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('firma digital') && (
          <>
            <List component="div" disablePadding>
              <ListItem button onClick={(e) => ruta(e, '/documents/sign')}>
                <ListItemText inset primary={t('menu:sign')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button onClick={(e) => ruta(e, '/documents/sign/files')}>
                <ListItemText inset primary={t('menu:archives')} />
              </ListItem>
            </List>
          </>
        )}
      </div>
    );
  };

  const carnets_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('carnets') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/carnets/published')}>
              <ListItemText inset primary={t('menu:templates')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('colecciones carnets') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '#')}>
              <ListItemText inset primary={t('menu:collections')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('emitir carnets') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/carnets/issue')}>
              <ListItemText inset primary={t('menu:issue')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('carnets emitidos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/carnets/issued')}>
              <ListItemText inset primary={t('menu:carnet-issued')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('carnets reportes') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/reports/carnet')}>
              <ListItemText inset primary={t('menu:reports')} />
            </ListItem>
          </List>
        )}
      </div>
    );
  };

  const diplomas_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('plantillas diplomas') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/diplomas/templates/published')}>
              <ListItemText inset primary={t('menu:templates')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('emitir diplomas') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/diplomas/issue')}>
              <ListItemText inset primary={t('menu:issue')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('firmar diplomas') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/diplomas/sign')}>
              <ListItemText inset primary={t('menu:sign')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('diplomas emitidos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/diplomas/groupsIssued')}>
              <ListItemText inset primary={t('menu:diplomas-issued')} />
            </ListItem>
          </List>
        )}
      </div>
    );
  };

  const settings_options = () => {
    let modulos = '';
    // eslint-disable-next-line
    actions.map((item) => {
      modulos = `${modulos}${item.acciones.modulos.nombre.toLowerCase()}`;
    });
    return (
      <div>
        {modulos.includes('valores parámetros') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/valueParameters')}>
              <ListItemText inset primary={t('menu:parameter-values')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('módulos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/modules')}>
              <ListItemText inset primary={t('menu:modules')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('grupos de usuarios') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/userGroups')}>
              <ListItemText inset primary={t('menu:user-groups')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('acciones') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/actions')}>
              <ListItemText inset primary={t('menu:actions')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('permisos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/permissions')}>
              <ListItemText inset primary={t('menu:permits')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('usuarios') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/users')}>
              <ListItemText inset primary={t('menu:users')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('organizaciones') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/organizations')}>
              <ListItemText inset primary={t('menu:organizations')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('grupos usuarios organizaciones') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/userGroupsOrganization')}>
              <ListItemText inset primary={t('menu:user-groups-organizations')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('redes sociales') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/socialNetworks')}>
              <ListItemText inset primary={t('menu:social-media')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('contratos') && (
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={(e) =>
                ruta(
                  e,
                  user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 1 ||
                    user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 14
                    ? '/contracts'
                    : `/contracts/${encrypt(user?.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones)}`
                )
              }
            >
              <ListItemText inset primary={t('menu:contracts')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('habilidades') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/skills')}>
              <ListItemText inset primary={t('menu:skills')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('recordatorios') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/reminders')}>
              <ListItemText inset primary={t('menu:reminders')} />
            </ListItem>
          </List>
        )}
        {modulos.includes('pagos') && (
          <List component="div" disablePadding>
            <ListItem button onClick={(e) => ruta(e, '/payments')}>
              <ListItemText inset primary={t('menu:payments')} />
            </ListItem>
          </List>
        )}
      </div>
    );
  };

  const load_badges_options = () => {
    let n = 0;
    actions.map(
      (data) =>
        (data.acciones.modulos.nombre.toLowerCase() === 'plantillas' ||
          data.acciones.modulos.nombre.toLowerCase() === 'recomendaciones' ||
          data.acciones.modulos.nombre.toLowerCase() === 'insignias emitidas' ||
          data.acciones.modulos.nombre.toLowerCase() === 'colecciones' ||
          data.acciones.modulos.nombre.toLowerCase() === 'emitir') &&
        (n += 1)
    );

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 1)}>
            <ListItemIcon>
              <WorkspacePremiumOutlined />
            </ListItemIcon>
            <ListItemText primary={t('menu:badges')} />
          </ListItem>
          <Collapse in={panel1} timeout="auto" unmountOnExit>
            {badges_options()}
          </Collapse>
        </>
      );
    }
  };

  const load_certificates_options = () => {
    let n = 0;
    actions.map(
      (data) =>
        (data.acciones.modulos.nombre.toLowerCase() === 'certificados' ||
          data.acciones.modulos.nombre.toLowerCase() === 'recomendaciones certificados' ||
          data.acciones.modulos.nombre.toLowerCase() === 'certificados emitidos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'colecciones certificados' ||
          data.acciones.modulos.nombre.toLowerCase() === 'emitir certificados') &&
        (n += 1)
    );

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 4)}>
            <ListItemIcon>
              <CardMembershipOutlined />
            </ListItemIcon>
            <ListItemText primary={t('menu:certificates')} />
          </ListItem>
          <Collapse in={panel4} timeout="auto" unmountOnExit>
            {certificates_options()}
          </Collapse>
        </>
      );
    }
  };

  const load_documents_options = () => {
    let n = 0;
    actions.map(
      (data) =>
        (data.acciones.modulos.nombre.toLowerCase() === 'plantillas documentos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'archivos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'firmar' ||
          data.acciones.modulos.nombre.toLowerCase() === 'certificar') &&
        (n += 1)
    );

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 5)}>
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            <ListItemText primary={t('menu:documents')} />
          </ListItem>
          <Collapse in={panel5} timeout="auto" unmountOnExit>
            {documents_options()}
          </Collapse>
        </>
      );
    }
  };

  const load_digital_signature_options = () => {
    let n = 0;
    actions.map((data) => data.acciones.modulos.nombre.toLowerCase() === 'firma digital' && (n += 1));

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 8)}>
            <ListItemIcon>
              <img className={classes.iconsButtons} src={ButtonSignatureIcon} alt="Signatue" />
            </ListItemIcon>
            <ListItemText primary={t('menu:digital_signature')} />
            <ListItemIcon>
              <FiberNew className={classes.new} />
            </ListItemIcon>
          </ListItem>
          <Collapse in={panel8} timeout="auto" unmountOnExit>
            {signatures_options()}
          </Collapse>
        </>
      );
    }
  };

  const load_carnets_options = () => {
    let n = 0;
    actions.map(
      (data) =>
        (data.acciones.modulos.nombre.toLowerCase() === 'carnets' ||
          data.acciones.modulos.nombre.toLowerCase() === 'carnets emitidos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'colecciones carnets' ||
          data.acciones.modulos.nombre.toLowerCase() === 'carnets reportes' ||
          data.acciones.modulos.nombre.toLowerCase() === 'emitir carnets') &&
        (n += 1)
    );

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 6)}>
            <ListItemIcon>
              <BadgeOutlined />
            </ListItemIcon>
            <ListItemText primary={t('menu:carnets')} />
            <ListItemIcon></ListItemIcon>
          </ListItem>
          <Collapse in={panel6} timeout="auto" unmountOnExit>
            {carnets_options()}
          </Collapse>
        </>
      );
    }
  };

  const load_diplomas_options = () => {
    let n = 0;
    actions.map(
      (data) =>
        (data.acciones.modulos.nombre.toLowerCase() === 'plantillas diplomas' ||
          data.acciones.modulos.nombre.toLowerCase() === 'diplomas emitidos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'firmar diplomas' ||
          data.acciones.modulos.nombre.toLowerCase() === 'emitir diplomas') &&
        (n += 1)
    );

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 7)}>
            <ListItemIcon>
              <SchoolOutlined />
            </ListItemIcon>
            <ListItemText primary={'Diplomas'} />
            <ListItemIcon>
              <FiberNew className={classes.new} />
            </ListItemIcon>
          </ListItem>
          <Collapse in={panel7} timeout="auto" unmountOnExit>
            {diplomas_options()}
          </Collapse>
        </>
      );
    }
  };

  const load_analytics_options = () => {
    let n = 0;
    actions.map((data) => data.acciones.modulos.nombre.toLowerCase() === 'analítica' && (n += 1));

    if (n > 0) {
      return (
        <ListItem button onClick={(e) => ruta(e, '/analytics')}>
          <ListItemIcon>
            <DataSaverOffOutlined />
          </ListItemIcon>
          <ListItemText primary={t('menu:analytics')} />
        </ListItem>
      );
    }
  };

  const load_settings_options = () => {
    let n = 0;
    actions.map(
      (data) =>
        (data.acciones.modulos.nombre.toLowerCase() === 'modulos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'acciones' ||
          data.acciones.modulos.nombre.toLowerCase() === 'permisos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'grupos de usuarios' ||
          data.acciones.modulos.nombre.toLowerCase() === 'usuarios' ||
          data.acciones.modulos.nombre.toLowerCase() === 'organizaciones' ||
          data.acciones.modulos.nombre.toLowerCase() === 'grupos usuarios organizaciones' ||
          data.acciones.modulos.nombre.toLowerCase() === 'valores parámetros' ||
          data.acciones.modulos.nombre.toLowerCase() === 'redes sociales' ||
          data.acciones.modulos.nombre.toLowerCase() === 'contratos' ||
          data.acciones.modulos.nombre.toLowerCase() === 'habilidades' ||
          data.acciones.modulos.nombre.toLowerCase() === 'recordatorios') &&
        (n += 1)
    );

    if (n > 0) {
      return (
        <>
          <ListItem button onClick={(e) => clicExpanded(e, 3)}>
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText primary={t('menu:settings')} />
          </ListItem>
          <Collapse in={panel3} timeout="auto" unmountOnExit>
            {settings_options()}
          </Collapse>
        </>
      );
    }
  };

  return (
    <Fragment>
      {user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios !== 1 &&
        user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios !== 14 && <CardOrganizations />}
      {/* <CardProfile /> */}
      {loading ? (
        <Skeleton variant="rect" height={224} />
      ) : (
        <List component="nav" className={classes.root}>
          {load_badges_options()}
          {load_certificates_options()}
          {load_carnets_options()}
          {load_documents_options()}
          {load_digital_signature_options()}
          {load_diplomas_options()}
          {load_analytics_options()}
          {load_settings_options()}
        </List>
      )}
      {user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios !== 1 &&
      user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios !== 14 ? (
        <CardContract />
      ) : (
        <IssueCard />
      )}
      <Chip />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    permission: state.permission,
    user: state.user,
    formDocuments: state.formDocuments,
  };
};

const mapDispatchToProps = {
  changePage,
  setFormDocuments,
  setDocumentsFields,
  setSearchValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
