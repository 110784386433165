import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Avatar,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@material-ui/core';
import {
  FindInPageOutlined,
  ContentCopyOutlined,
  ArchiveOutlined,
  DeleteForeverOutlined,
  VisibilityOutlined,
  CloseOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import Template from '../Templates';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage, setReturnRoute } from '../../../actions';
import SkeletonTables from '../../../components/SkeletonTable';
import { encrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import Swal from 'sweetalert2';
import EditIcon from '../../../assets/static/icons/EditIcon';
import PreviewCertificate from '../../../components/PreviewDialogCertificate';
import { useTranslation } from 'react-i18next';

function Published(props) {
  const { page, rowsPerPage, changeBreadcrump, user, organizationId, permission, changePage, setReturnRoute } = props;
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [diplomaTemplates, setDiplomaTemplates] = useState([]);
  const [filtro, setFiltradas] = useState(diplomaTemplates);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [imgTemplate, setImgTemplate] = useState('');
  const [vista, setVista] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [countItems, setCountItems] = useState();
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    changeBreadcrump(t('breadCrumps:breadCrumps.diplomas-published', { returnObjects: true }));
    return () => {
      changePage(0);
    }; // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getDiplomaTemplates();
    await getLevel();
    await getCosts();
    await getTime();
    setLoading(false);
  };

  const getLevel = async () => {
    const { data } = await axios.post(`/others/getLevels`);
    setLevel(data.levels);
  };

  const getCosts = async () => {
    const { data } = await axios.post(`/others/getCosts`);
    setCost(data.costs);
  };

  const getTime = async () => {
    const { data } = await axios.post(`/others/getTime`);
    setTime(data.time);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    if (isDeparmentCreater || isDeparmentCreaterRoll) params.id_usuarios = user.data_user.id;

    return params;
  };

  const getDiplomaTemplates = async () => {
    try {
      const params = getParams(0, rowsPerPage);
      const { data } = await axios.post(`/diplomaTemplate/getDiplomaTemplatesPublished`, params);

      setDiplomaTemplates(data.diplomaTemplates?.rows);
      setFiltradas(data.diplomaTemplates?.rows);
      setCountItems(data?.diplomaTemplates?.count);
    } catch (error) {
      history.push('/500');
      window.location.reload();
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/diplomaTemplate/getDiplomaTemplatesPublished`, params);

    const array = [
      ...diplomaTemplates,
      ...data.diplomaTemplates?.rows.filter((row) => !diplomaTemplates.find((item) => item.id === row.id)),
    ];

    setDiplomaTemplates(array);
    setFiltradas(array);
    setLoading(false);
  };

  const handleClick = (e, id, action, archive) => {
    switch (action) {
      case 'copy':
        modalCopy(e, id, archive);
        break;
      case 'edit':
        setReturnRoute('/diplomas/templates/published');
        history.push(`/diplomas/templates/edit/${encrypt(id)}`);
        break;
      case 'archive':
        modalArchive(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalCopy = (e, id, archive) => {
    Swal.fire({
      text: t('alerts:alerts.copy-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendCopy(id, e, archive);
      }
    });
  };

  const modalArchive = (id) => {
    Swal.fire({
      text: t('alerts:alerts.archive-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendArchive(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-diploma'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendCopy = (id, e, archive) => {
    e.preventDefault();
    setLoading(true);
    const template = diplomaTemplates?.filter((template) => template.id === id);
    const templateCopy = {
      ...template[0],
      copy: true,
      nombre: `${template[0]?.nombre} copia`,
      nombre_privado: `${template[0]?.nombre_privado} copia`,
      id_habilidades: template[0]?.plantillasHabilidades.map((item) => ({
        id_habilidades: item.id_habilidades,
      })),
      id_usuarios: user.data_user?.id,
      id_organizaciones: organizationId,
      diplomaFields: template[0]?.diplomaCampos.map((item) => ({
        fieldName: item.nombre,
        fieldType: item.tipo_campo_vp,
        exampleText: item.texto_ejemplo,
        fontFamily: { family: item.tipo_letra },
        fontSize: item.tamano_letra,
        fontColor: { hex: item.color },
        rectColor: { hex: item.background_color },
        fontStyle: {
          bold: item.bold,
          italic: item.italic,
          underline: item.underline,
          strikethrough: item.strikethrough,
        },
        x: parseInt(item.x),
        y: parseInt(item.y),
        width: item.width,
        height: item.height,
        page: item.page,
        align: item.formato_letra || 'center',
      })),
    };
    axios
      .post(`/diplomaTemplate`, templateCopy)
      .then((res) => {
        setLoading(false);
        history.push('/diplomas/templates/edited');
        Swal.fire({
          icon: 'success',
          text: t('alerts:alerts.copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          text: t('alerts:alerts.copied-error'),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const sendArchive = async (id) => {
    const { data } = await axios
      .put(`/diplomaTemplate/updateState/${id}`, {
        estado: 2,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.archived'),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/diplomas/archived');
      });
    }
  };

  const sendDelete = async (id) => {
    const { data } = await axios
      .delete(`/diplomaTemplate/${id}`, {
        data: { id_usuarios: user.data_user?.id },
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const edit_permission = (id, idUser) => {
    if (permission.includes('Editar')) {
      let permissionDepartament = false;
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        permissionDepartament = idUser === user.data_user.id ? false : true;
      }
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton
            disabled={permissionDepartament}
            aria-label="edit"
            onClick={(e) => handleClick(e, id, 'edit')}
            className={classes.iconButton}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const archive_permission = (id, idUser) => {
    if (permission.includes('Archivar')) {
      let permissionDepartament = false;
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        permissionDepartament = idUser === user.data_user.id ? false : true;
      }
      return (
        <Tooltip title={t('tables:table.tooltip.archive')}>
          <IconButton
            disabled={permissionDepartament}
            aria-label="archive"
            onClick={(e) => handleClick(e, id, 'archive')}
            className={classes.iconButton}
          >
            <ArchiveOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id, idUser) => {
    if (permission.includes('Eliminar')) {
      let permissionDepartament = false;
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        permissionDepartament = idUser === user.data_user.id ? false : true;
      }
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton
            disabled={permissionDepartament}
            aria-label="delete"
            onClick={(e) => handleClick(e, id, 'delete')}
            className={classes.iconButton}
          >
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const organizationColumn = () => {
    if (isSuper) {
      return t('tables:table.columns-diploma-published-archived-super', { returnObjects: true });
    } else {
      return t('tables:table.columns-diploma-published-archived', { returnObjects: true });
    }
  };

  const organizationName = (row) => {
    if (isSuper) {
      return <TableCell align="center">{row.organizaciones.nombre}</TableCell>;
    }
  };

  const copy_permission = (id, idUser, archive) => {
    if (permission.includes('Copiar')) {
      let permissionDepartament = false;
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        permissionDepartament = idUser === user.data_user.id ? false : true;
      }
      return (
        <Tooltip title={t('tables:table.tooltip.copy')}>
          <IconButton
            disabled={permissionDepartament}
            aria-label="copy"
            onClick={(e) => handleClick(e, id, 'copy', archive)}
            className={classes.iconButton}
          >
            <ContentCopyOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const handleOpen = (linkPhotoTemplate, width, height) => {
    setImgTemplate({ photo: linkPhotoTemplate, width: width, height: height });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImgTemplate('');
  };

  const handleOpenDialog = (id) => {
    const template = diplomaTemplates?.find((template) => template.id === id);
    const niveles = level.find((item) => item.id === template.nivel_vp);
    const tiempos = time.find((item) => item.id === template.tiempo_vp);
    const costos = cost.find((item) => item.id === template.costo_vp);
    setVista({
      ...template,
      niveles: niveles?.valor_parametro,
      tiempos: tiempos?.valor_parametro,
      costos: costos?.valor_parametro,
    });
    setOpenPreview(true);
  };

  const handleCloseDialog = () => {
    setOpenPreview(false);
  };

  return (
    <Template Index={0}>
      <Search
        tableName="templateDiploma"
        type="published"
        items={diplomaTemplates}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        deparmentCreater={{ isDeparmentCreater, isDeparmentCreaterRoll, userId: user.data_user.id }}
        setLoading={setLoading}
      />
      <Table
        columns={organizationColumn()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {filtro.length > 0 ? (
            loading ? (
              <>
                <SkeletonTables columns={organizationColumn()} photo={1} />
              </>
            ) : (
              <>
                {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <Tooltip title={t('tables:table.tooltip.view-template')}>
                          <Avatar
                            alt="Remy Sharp"
                            variant="square"
                            src={row.linkPhotoTemplate}
                            className={classes.large}
                            onClick={() => handleOpen(row.linkPhotoTemplate, row.width, row.height)}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{row.nombre}</TableCell>
                      <TableCell align="center">{row.nombre_privado}</TableCell>
                      {organizationName(row)}
                      <TableCell align="center">
                        {moment(row.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell align="center">{row.diplomasEmitidos}</TableCell>
                      <TableCell align="center">{row.estado === 1 ? t('tables:table.state.published') : ' '}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.details')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={() => history.push(`/diplomas/templates/published/details/${encrypt(row.id)}`)}
                            className={classes.iconButton}
                          >
                            <FindInPageOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {edit_permission(row.id, row.plantillasDiplomasHistorial[0].id_usuarios)}
                      </TableCell>
                      <TableCell align="center">
                        {row.diplomasEmitidos > 0
                          ? copy_permission(row.id, row.plantillasDiplomasHistorial[0].id_usuarios, true)
                          : copy_permission(row.id, row.plantillasDiplomasHistorial[0].id_usuarios)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.preview')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={(e) => handleOpenDialog(row.id)}
                            className={classes.iconButton}
                          >
                            <VisibilityOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {row.diplomasEmitidos > 0
                          ? archive_permission(row.id, row.plantillasDiplomasHistorial[0].id_usuarios)
                          : delete_permission(row.id, row.plantillasDiplomasHistorial[0].id_usuarios)}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )
          ) : loading ? (
            <SkeletonTables columns={organizationColumn()} photo={1} />
          ) : (
            <TableCell align="center" colSpan="9">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.btnClose}`} onClick={handleClose}>
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={imgTemplate.photo}
            className={imgTemplate.width > imgTemplate.height ? classes.img_horizontal : classes.img_vertical}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openPreview} onClose={handleCloseDialog} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle align="center" onClose={handleCloseDialog}>
          {t('dialogs:dialog.preview-title-template-diploma')}
        </DialogTitle>
        <DialogContent dividers>
          <PreviewCertificate vista={vista} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.buttonForm}`} onClick={handleCloseDialog}>
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Template>
  );
}

const useStyles = makeStyles((theme) => ({
  large: {
    width: 'auto',
    height: theme.spacing(10),
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnClose: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  buttonForm: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  img_vertical: {
    maxWidth: '30vw',
    height: 'auto',
  },
  img_horizontal: {
    maxWidth: '70vw',
    height: 'auto',
  },
  iconButton: {
    margin: '0em -.4em',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'plantillas diplomas')
      .map((item) => item.acciones.nombre),
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
  setReturnRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
