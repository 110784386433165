import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  FormControl,
  TextField,
} from '@material-ui/core';
import { HighlightOffOutlined, FindInPageOutlined, CloseOutlined } from '@mui/icons-material';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import SkeletonTables from '../../../components/SkeletonTable';
import Backdrop from '../../../components/Backdrop';
import axios from '../../../api';
import colors from '../../../assets/styles/colors';
import { changePage, changeBreadcrump, sortData, getComparator } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import { useTranslation } from 'react-i18next';

function ReportsCarnets(props) {
  const { page, rowsPerPage, permission, changeBreadcrump } = props;
  const history = useHistory();
  const classes = useStyles();
  const [reportsCarnets, setReportsCarnets] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [formMessages, setFormMessages] = useState([]);
  const [answer, setAnswer] = useState(false);
  const [formAnswer, setFormAnswer] = useState({
    mensaje_reporte: '',
    estado: 3,
  });
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs']);
  const [typeReport, setTypeReport] = useState(props.match.params.type);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData();
    } else {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTypeReport(props.match.params.type);
    // eslint-disable-next-line
  }, [props.match.params.type]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [typeReport]);

  const loadData = async () => {
    try {
      setLoading(true);
      await getReports();
      switch (typeReport) {
        case 'carnet':
          changeBreadcrump(t('breadCrumps:breadCrumps.reports-carnet', { returnObjects: true }));
          break;
        case 'badge':
          changeBreadcrump(t('breadCrumps:breadCrumps.reports-badge', { returnObjects: true }));
          break;
        case 'certificate':
          changeBreadcrump(t('breadCrumps:breadCrumps.reports-certificate', { returnObjects: true }));
          break;
        default:
          break;
      }
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  const getReports = async () => {
    const { data } = await axios.post(`/helpCenter/getHelpCenters`, { type: typeReport });
    setReportsCarnets(data.helpCenters);
    setFiltradas(data.helpCenters);
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-report'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id_issued) => {
    try {
      setLoadingBackdrop(true);
      await axios.put(`/helpCenter/${id_issued}`, { estado: -1, type: typeReport });
      setLoadingBackdrop(false);
      Swal.fire({
        title: t('alerts:alerts.delete-report-success-title'),
        text: t('alerts:alerts.delete-report-success-text'),
        icon: 'success',
        iconColor: colors.primary,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
      getReports();
    } catch (error) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        text: t('alerts:alerts.error-ocurred'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const toLink = async (e, idIssued) => {
    setLoadingBackdrop(true);
    const { data } = await axios.post(`/helpCenter/getHelpCentersByIssued/`, { type: typeReport, id: idIssued });
    setLoadingBackdrop(false);
    setFormMessages(data.helpCenters);
    setAnswer(false);
    setFormAnswer({ ...formAnswer, type: typeReport, id: idIssued });
    setOpen(true);
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <TableCell align="center">
          <Tooltip title={t('tables:table.tooltip.delete')}>
            <IconButton aria-label="delete" onClick={(e) => modalDelete(id)}>
              <HighlightOffOutlined />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    }
  };

  const close = () => {
    if (formAnswer.mensaje_reporte !== '') {
      Swal.fire({
        text: t('alerts:alerts.close-report'),
        icon: 'warning',
        iconColor: colors.primary,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: t('buttons:buttons.cancel'),
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.value) {
          setOpen(false);
          setAnswer(false);
          setFormAnswer({ ...formAnswer, mensaje_reporte: '' });
        }
      });
    } else {
      setOpen(false);
      setAnswer(false);
    }
  };

  const closeAnswer = () => {
    if (formAnswer.mensaje_reporte !== '') {
      Swal.fire({
        text: t('alerts:alerts.close-report-message'),
        icon: 'warning',
        iconColor: colors.primary,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.value) {
          setAnswer(false);
          setFormAnswer({ ...formAnswer, mensaje_reporte: '' });
        }
      });
    } else {
      setAnswer(false);
    }
  };

  const closeCase = () => {
    Swal.fire({
      text: t('alerts:alerts.close-case'),
      icon: 'warning',
      iconColor: colors.primary,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: t('buttons:buttons.cancel'),
      confirmButtonText: t('buttons:buttons.accept'),
      buttonsStyling: false,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        setLoadingBackdrop(true);
        setOpen(false);
        try {
          await axios.put(`/helpCenter/${formAnswer.id}`, { estado: 2, type: typeReport });
          setLoadingBackdrop(false);
          Swal.fire({
            title: t('alerts:alerts.close-case-success-title'),
            text: t('alerts:alerts.close-case-success-text'),
            icon: 'success',
            iconColor: colors.primary,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
          setOpen(false);
          setAnswer(false);
          setFormAnswer({ ...formAnswer, mensaje_reporte: '' });
          getReports();
        } catch (error) {
          Swal.fire({
            customClass: {
              container: classes.mySwal,
            },
            text: t('alerts:alerts.error-ocurred'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 3000,
          });
          setOpen(true);
          setLoadingBackdrop(false);
        }
      }
    });
  };

  const handleInput = (event) => {
    setFormAnswer({
      ...formAnswer,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formAnswer.mensaje_reporte === '') {
      return Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        text: t('alerts:alerts.no-answer'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    try {
      setLoadingBackdrop(true);
      setOpen(false);
      axios.post('/helpCenter', formAnswer).then((res) => {
        setLoadingBackdrop(false);
        Swal.fire({
          title: t('alerts:alerts.send-message-success-title'),
          text: t('alerts:alerts.send-message-success-text'),
          icon: 'success',
          iconColor: colors.primary,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => {
          getReports();
          formMessages.push({
            mensaje_reporte: formAnswer.mensaje_reporte,
            estado: formAnswer.estado,
            fecha_envio: moment().format('DD/MM/YYYY HH:mm:ss'),
          });
          setFormAnswer({ ...formAnswer, mensaje_reporte: '' });
          setOpen(true);
          setAnswer(false);
        });
      });
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.error-ocurred'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const columns = () => {
    return t('tables:table.columns-reports-carnets', { returnObjects: true });
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="helpCenterCarnets"
        items={reportsCarnets}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
      />
      <Table
        columns={columns()}
        rows={filtro}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{moment(row.fecha_envio).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">
                      {row.carnetsEmitidos?.email_receptor ||
                        row.insigniasEmitidas?.email_receptor ||
                        row.certificadosEmitidos?.email_receptor}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-carnet')}>
                        <a
                          href={`${window.location.origin}/${
                            typeReport === 'badge' ? 'certificates' : typeReport
                          }/verify/${encrypt(
                            row.id_carnets_emitidos || row.id_insignias_emitidas || row.id_certificados_emitidos
                          )}`}
                          target="blank"
                        >
                          {row.carnetsEmitidos?.codigo_carnet ||
                            row.insigniasEmitidas?.codigo_insignia ||
                            row.certificadosEmitidos?.codigo_certificado}
                        </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      {row.carnetsEmitidos?.plantillasCarnets?.organizaciones?.nombre ||
                        row.insigniasEmitidas?.plantillasInsignias?.organizaciones?.nombre ||
                        row.certificadosEmitidos?.plantillasCertificados?.organizaciones?.nombre}
                    </TableCell>
                    <TableCell align="center">
                      {row.estado === 0
                        ? t('tables:table.state-reports-carnets.waiting')
                        : row.estado === 1
                        ? t('tables:table.state-reports-carnets.process')
                        : row.estado === 2
                        ? t('tables:table.state-reports-carnets.finished')
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-message')}>
                        <IconButton
                          aria-label="detalles"
                          onClick={(e) =>
                            toLink(
                              e,
                              row.id_carnets_emitidos || row.id_insignias_emitidas || row.id_certificados_emitidos
                            )
                          }
                        >
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {delete_permission(
                      row.id_carnets_emitidos || row.id_insignias_emitidas || row.id_certificados_emitidos
                    )}
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="6">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={open} fullWidth={true} maxWidth={'md'}>
        <DialogTitle align="center"> {t('dialogs:dialog.reports')}</DialogTitle>
        <DialogContent dividers>
          {formMessages.map((messages) => (
            <>
              <div className={classes.spam}>
                <spam>
                  {messages.estado === 3
                    ? 'SuperAdmin'
                    : messages.carnetsEmitidos?.email_receptor ||
                      messages.insigniasEmitidas?.email_receptor ||
                      messages.certificadosEmitidos?.email_receptor}
                </spam>
                <spam>{moment(messages.fecha_envio).format('DD/MM/YYYY HH:mm:ss')}</spam>
              </div>
              <FormControl className="w-100">
                <TextField name="mensaje_reporte" variant="outlined" multiline value={messages.mensaje_reporte} />
              </FormControl>
              <Divider />
            </>
          ))}

          {answer && (
            <>
              <div className={classes.spam}>
                <spam>SuperAdmin</spam>
                <Button
                  disableElevation
                  variant="contained"
                  className={`my-2 ${classes.btnClose}`}
                  onClick={closeAnswer}
                >
                  <CloseOutlined />
                </Button>
              </div>
              <FormControl className="w-100">
                <TextField
                  name="mensaje_reporte"
                  variant="outlined"
                  multiline
                  value={formAnswer.mensaje_reporte}
                  onChange={handleInput}
                />
              </FormControl>
            </>
          )}

          <div className={`text-center ${classes.root}`} style={{ marginTop: '1.5em' }}>
            {formMessages[0]?.estado !== 2 && (
              <>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  onClick={answer ? handleSubmit : () => setAnswer(true)}
                >
                  {answer ? t('buttons:buttons.send') : t('buttons:buttons.answer')}
                </Button>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  onClick={closeCase}
                >
                  {t('buttons:buttons.finished')}
                </Button>
              </>
            )}

            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={close}
            >
              {t('buttons:buttons.close')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Backdrop loading={loadingBackdrop} />
    </>
  );
}

const useStyles = makeStyles(() => ({
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0.5em',
    padding: '10px 40px',
  },
  spam: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnClose: {
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: '50px',
    padding: '1px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'carnets reportes')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCarnets);
