import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import colors from '../assets/styles/colors';
import { useHistory } from 'react-router-dom';
import Backdrop from '../components/Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${JSON.parse(window.localStorage.state)?.styles?.bg_Error})`,
  },
  texto: {
    marginTop: '3em',
    width: '-webkit-fill-available',
  },
  toolbar: {
    backgroundColor: colors.primary,
    padding: '10%',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: colors.primary,
      padding: '3%',
    },
  },
  footer: {
    backgroundColor: colors.primary,
    padding: '7%',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: colors.primary,
      padding: '5%',
    },
  },
}));

function Page500() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [page500, setPage500] = useState({});

  useEffect(
    () => {
      const styles = JSON.parse(window.localStorage.state)?.styles;
      setPage500({ texto: styles.error500_texto, page500: styles.error500_pj });
      setTimeout(function () {
        setLoading(true);
      }, 2000);
      setTimeout(function () {
        pushLogin();
      }, 5000);
    }, // eslint-disable-next-line
    []
  );

  const pushLogin = async () => {
    setLoading(false);
    history.push('/');
    window.location.reload();
  };

  return (
    <>
      <Backdrop loading={loading} />
      <div className={classes.toolbar} />
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} lg={6}>
          <img src={page500?.texto} alt="500 ERROR" className={classes.texto} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <img src={page500?.page500} style={{ width: '-webkit-fill-available' }} alt="500 NOT FOUND" />
        </Grid>
      </Grid>
      <div className={classes.footer} />
    </>
  );
}

export default Page500;
