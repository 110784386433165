import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Select,
  MenuItem,
  CardActions,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Slide,
  Box,
} from '@material-ui/core';
import { CardMembershipOutlined, CloseOutlined } from '@mui/icons-material';
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import Backdrop from '../../../components/Backdrop';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { setFormDiplomas, changeBreadcrump, setDiplomaFields, setSaveDesign } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import defaultFields from '../../../utils/defaultFields';
import { env } from '../../../config/environment';
import onlyNumber from '../../../utils/validations/onlyNumber';
import { useTranslation } from 'react-i18next';
import DesignDiplomas from './DesignDiplomas';
import capitalize from '../../../utils/capitalize';

function CreateTemplate(props) {
  const {
    user,
    organizationId,
    formDiplomas,
    returnRoute,
    setFormDiplomas,
    diplomaFields,
    setDiplomaFields,
    changeBreadcrump,
    saveDesign,
    setSaveDesign,
  } = props;
  const history = useHistory();
  const classes = useStyles({ formDiplomas });
  const imageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [costs, setCosts] = useState([]);
  const [time, setTime] = useState([]);
  const [typeCriteria, setTypeCriteria] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [diplomaIssued, setDiplomaIssued] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDesignDiploma, setOpenDesignDiploma] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [skillValue, setSkillValue] = useState({});
  const isModifying = props.match.params.id ? true : false;
  const isSuperUser = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1;
  const md5OrganizationRoute = md5(`certika-organization-${isSuperUser ? 1 : organizationId}`).toString();
  const md5UserRoute = md5(`certika-user-${isSuperUser ? 1 : user?.data_user?.id}`).toString();

  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'breadCrumps',
    'tables',
  ]);

  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getSkillsOptions();
      await getTypes();
      await getLeves();
      await getCosts();
      await getTime();
      await getTypeCriteria();
      await getOrganizations();

      if (isModifying) {
        await getDiplomaTemplate();
        changeBreadcrump(t('breadCrumps:breadCrumps.diploma-template-edit', { returnObjects: true }));
        setSaveDesign(true);
      } else {
        setFormDiplomas({
          ...formDiplomas,
          img_prev:
            formDiplomas?.img_prev && formDiplomas?.img_prev[0]?.split(':', 1)[0] === 'blob'
              ? null
              : formDiplomas.img_prev,
          id_organizaciones:
            user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
          id_usuarios: user.data_user?.id,
          page: 1,
        });
        changeBreadcrump(t('breadCrumps:breadCrumps.diploma-template-create', { returnObjects: true }));
      }
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  const isSuper = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ? true : false;

  const getSkillsOptions = async () => {
    const { data } = await axios.post(`/hability/getHabilities`);
    setSkillsOptions(data.habilities.rows);
  };

  const getTypes = async () => {
    const { data } = await axios.post(`/others/getTypes`);
    setTypes(data.types);
  };

  const getLeves = async () => {
    const { data } = await axios.post(`/others/getLevels`);
    setLevels(data.levels);
  };

  const getCosts = async () => {
    const { data } = await axios.post(`/others/getCosts`);
    setCosts(data.costs);
  };

  const getTime = async () => {
    const { data } = await axios.post(`/others/getTime`);
    setTime(data.time);
  };

  const getTypeCriteria = async () => {
    const { data } = await axios.post(`/others/getTypeCriteria`);
    setTypeCriteria(data.typeCriteria);
  };

  const getOrganizations = async () => {
    if (isSuper) {
      const { data } = await axios.post(`/organization/getOrganizations`);
      setOrganizations(data.organizations.rows);
    } else {
      const { data } = await axios.get(`/organization/${organizationId}`);
      setOrganizations(data.organization.organizations);
    }
  };

  const getDiplomaTemplate = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const { data } = await axios.get(`/diplomaTemplate/${id}`);
      const {
        diplomaTemplate,
        criterion,
        skillsTemplate,
        linkPhoto,
        linkPhotoTemplate,
      } = data.diplomaTemplate;
      setDiplomaIssued(data.diplomaTemplate?.diplomaTemplate?.diplomasEmitidos);
      setDiplomaFields(
        data.diplomaTemplate?.diplomaTemplate?.diplomaCampos.map((field) => ({
          id: field.id,
          fieldType: field.tipo_campo_vp,
          exampleText: field.texto_ejemplo,
          fieldName: field.nombre,
          align: field.formato_letra ? field.formato_letra : 'center',
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width === 0 ? 430 : field.width,
          height: field.height === 0 ? field.tamano_letra : field.height,
          page: field.page,
        }))
      );
      delete data.diplomaTemplate?.diplomaTemplate?.diplomaCampos;
      setFormDiplomas({
        ...diplomaTemplate,
        img_prev: linkPhoto,
        img_template: linkPhotoTemplate,
        id_usuarios: user.data_user?.id,
        skills: skillsTemplate.map((item, index) => ({
          index,
          id: item.id,
          id_habilidades: item.id_habilidades,
          id_plantillas_diplomas: item.id_plantillas,
          descripcion: item.habilidades.descripcion,
        })),
        activities: criterion.map((item, index) => ({
          index,
          id: item.id,
          tipo_criterio_vp: item.tipo_criterio_vp,
          descripcion_criterio: item.descripcion_criterio,
          link: item.link,
        })),
        estado: diplomaTemplate.estado,
        page: 1,
      });
    } catch (error) {
      history.push('/500');
    }
  };

  const nombrePrivado = () => {
    if (diplomaIssued !== 0 && props.match.params.id) {
      return true;
    } else {
      return false;
    }
  };

  const handleInput = (event) => {
    setFormDiplomas({
      ...formDiplomas,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputCriteria = (event, index) => {
    formDiplomas.activities?.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setFormDiplomas({ ...formDiplomas, activities: formDiplomas.activities });
  };

  const handleCancel = () => {
    setFormDiplomas(null);
    setDiplomaFields(null);

    history.push(returnRoute);
  };

  const handleFile = (e) => {
    const imagen = e.target.files;
    if (imagen.length > 3) {
      return Swal.fire({
        icon: 'error',
        text: t('alerts:alerts.max-template-certificate-alert'),
        showConfirmButton: true,
      });
    }
    if (imagen.length > 0) {
      let uris = [];
      let elementImg = [];
      for (let i = 0; i < imagen.length; i++) {
        let url = URL.createObjectURL(imagen[i]);
        uris.push(url);
        let img = document.createElement('img');
        img.src = url;
        elementImg.push(img);
      }
      setImage({ imagen: imagen, elements: elementImg });
      setFormDiplomas({
        ...formDiplomas,
        img_prev: uris,
        img_template: null,
        imagen: 'newImage',
        pages: uris.length,
        page: 1,
      });
      diplomaFields && setDiplomaFields(diplomaFields.map((field) => ({ ...field, x: 50, y: 50 })));
      setSaveDesign(false);
    }
  };

  const addActivity = () => {
    const last = formDiplomas.activities[formDiplomas.activities?.length - 1];
    setFormDiplomas({ ...formDiplomas, activities: [...formDiplomas.activities, { index: last.index + 1 }] });
  };

  const deleteActivity = (e, index) => {
    let criterionDeleted;
    if (formDiplomas.activities.length === 0) {
      criterionDeleted = [{ index: 1 }];
    } else {
      criterionDeleted = formDiplomas.activities?.filter((item) => item.index !== index);
    }
    setFormDiplomas({ ...formDiplomas, activities: criterionDeleted });
  };

  const cantidadTiempo = (cantidad) => {
    if (cantidad === '' || cantidad < 1) {
      formDiplomas.cantidad_tiempo = null;
      return formDiplomas.cantidad_tiempo;
    } else {
      return cantidad;
    }
  };

  const requiredCantidadTiempo = (cantidad) => {
    if (cantidad > 1) {
      return false;
    } else {
      formDiplomas.cantidad_tiempo = null;
      return true;
    }
  };

  const goToDesignDiploma = async () => {
    const haveUploadedAnImage = formDiplomas.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const md5OrganizationRoute = md5(`certika-organization-${organizationId}`).toString();
    const isANewImage = image && image?.imagen[0]?.name ? true : false;
    const isBlod = formDiplomas?.img_prev[0]?.split(':', 1)[0] === 'blob' ? true : false;
    const isBlodHorizontal = imageRef.current?.naturalWidth > imageRef.current?.naturalHeight ? true : false;
    const isHorizontal = formDiplomas.width > formDiplomas.height ? true : false;
    if (isANewImage) {
      if (
        isBlod &&
        isBlodHorizontal &&
        (imageRef.current?.naturalWidth > 950 ||
          imageRef.current?.naturalHeight > 700 ||
          imageRef.current?.naturalWidth < 750 ||
          imageRef.current?.naturalHeight < 550)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.horizontal-certificates'),
          showConfirmButton: true,
        });
      }
      if (
        isBlod &&
        !isBlodHorizontal &&
        (imageRef.current?.naturalWidth > 668 ||
          imageRef.current?.naturalHeight > 900 ||
          imageRef.current?.naturalWidth < 420 ||
          imageRef.current?.naturalHeight < 690)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.vertical-certificates'),
          showConfirmButton: true,
        });
      }
      if (
        !isBlod &&
        isHorizontal &&
        (formDiplomas.width > 950 || formDiplomas.height > 700 || formDiplomas.width < 750 || formDiplomas.height < 550)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.horizontal-certificates'),
          showConfirmButton: true,
        });
      }
      if (
        !isBlod &&
        !isHorizontal &&
        (formDiplomas.width > 668 || formDiplomas.height > 900 || formDiplomas.width < 420 || formDiplomas.height < 690)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.vertical-certificates'),
          showConfirmButton: true,
        });
      }

      for (let i = 1; i < formDiplomas.img_prev.length; i++) {
        if (
          image?.elements[0]?.naturalWidth !== image?.elements[i]?.naturalWidth ||
          image?.elements[0]?.naturalHeight !== image?.elements[i]?.naturalHeight
        ) {
          return Swal.fire({
            icon: 'error',
            text: t('alerts:alerts.no-equals-format'),
            showConfirmButton: true,
          });
        }
      }
      setLoading(true);

      for (let i = 0; i < formDiplomas.img_prev.length; i++) {
        const certificateImageForm = new FormData();
        certificateImageForm.append('file', image?.imagen[i]);
        certificateImageForm.append(
          'route',
          `${md5OrganizationRoute}/diplomasTemplate/diplomas-temp/temp${formDiplomas?.pages > 1 ? i : ''}`
        );

        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/upload-diploma`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: certificateImageForm,
        });
      }

      if (!diplomaFields?.length > 0) {
        const { dataQr, dataImg, dataCode, dataButtonVerify } = defaultFields();
        setDiplomaFields([dataQr, dataImg, dataCode, dataButtonVerify]);
      }

      let urls = [];
      for (let i = 0; i < formDiplomas.img_prev.length; i++) {
        const { data } = await axios.post('/file/getFile', {
          keyFile: `${md5OrganizationRoute}/diplomasTemplate/diplomas-temp/temp${formDiplomas?.pages > 1 ? i : ''}`,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        urls.push(data?.url);
      }

      setFormDiplomas({
        ...formDiplomas,
        img_prev: urls,
        width: imageRef.current?.naturalWidth,
        height: imageRef.current?.naturalHeight,
      });
    }

    const indexField = diplomaFields?.findIndex((item) => item.fieldName === 'certificateCode');
    if (indexField !== undefined && indexField !== -1) {
      diplomaFields[indexField].exampleText = diplomaFields[indexField].exampleText.includes(':')
        ? diplomaFields[indexField].exampleText.split(':')[0]
        : '';
    }
    setOpenDesignDiploma(true);
    setLoading(false);
  };

  const saveTemplate = async (e) => {
    e.preventDefault();

    const haveUploadedAnImage = formDiplomas.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const certificateDesigned = diplomaFields?.length > 0;
    if (!certificateDesigned) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.no-design-certificate'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!saveDesign) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.no-accepted-design-certificate'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const isANewTemplate = !formDiplomas.id ? true : false;
    setLoading(true);
    delete formDiplomas.img_template;
    try {
      if (isANewTemplate) {
        await axios.post(`/diplomaTemplate`, {
          ...formDiplomas,
          criterios: formDiplomas.activities,
          id_habilidades: formDiplomas.skills,
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
          diplomaFields,
        });
      } else {
        await axios.put(`/diplomaTemplate/${formDiplomas.id}`, {
          ...formDiplomas,
          criterios: formDiplomas.activities,
          id_habilidades: formDiplomas.skills,
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
          diplomaFields,
        });
      }
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setLoading(false);
    Swal.fire({
      icon: 'success',
      text: t('alerts:alerts.saved'),
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      if (isANewTemplate) {
        history.push('/diplomas/templates/edited');
      } else {
        history.push(returnRoute);
      }
      setFormDiplomas(null);
      setDiplomaFields(null);
    });
  };

  const loadFillDataTypes = () => {
    return types.map((data) => (
      <MenuItem key={`type-${data.id}`} value={data.id}>
        {languajeEn ? data.valor_parametro_en : data.valor_parametro}
      </MenuItem>
    ));
  };

  const loadFillDataLevels = () => {
    return levels.map((data) => {
      return (
        <MenuItem key={`level-${data.id}`} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataCosts = () => {
    return costs.map((data) => {
      return (
        <MenuItem key={`cost-${data.id}`} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataTime = () => {
    return time.map((data) => {
      return (
        <MenuItem key={`time-${data.id}`} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataTypeCriteria = () => {
    return typeCriteria.map((data) => (
      <MenuItem key={`type-criteria-${data.id}`} value={data.id}>
        {languajeEn ? data.valor_parametro_en : data.valor_parametro}
      </MenuItem>
    ));
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const loadFillDataOrganizationsRelations = () => {
    const arrayOrganizations = organizations?.organizacionesRelaciones.map((data) => data.organizacionesRelacionadas);

    return arrayOrganizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSkill = (e, newSkill) => {
    setFormDiplomas({ ...formDiplomas, skills: [...formDiplomas.skills, newSkill.pop()] });
    setSkillsOptions(skillsOptions.filter((item) => item.descripcion.trim() !== e.target.innerText));
  };

  const deleteSkill = (hability) => {
    setFormDiplomas({
      ...formDiplomas,
      skills: formDiplomas?.skills.filter((item) => item !== hability),
    });
    if (hability.id_habilidades) {
      setSkillsOptions([...skillsOptions, hability]);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChip = (skill, index) => {
    if (typeof skill === 'string') {
      setOpenEdit(true);
      setSkillValue({ value: skill, index: index });
    }
  };

  const editSkill = () => {
    formDiplomas?.skills?.splice(skillValue.index, 1, skillValue.value);
    setOpenEdit(false);
  };

  const handleChangePage = (event, value) => {
    setFormDiplomas({
      ...formDiplomas,
      page: value,
    });
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0">
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={saveTemplate}>
            <Typography component="h6" variant="h6">
              {t('certificateBadgeTemplate:badges-certificate-form.details-diploma')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Tooltip title={t('tables:table.tooltip.view-template')}>
                    <Avatar
                      imgProps={{ ref: imageRef }}
                      alt="img-prev"
                      src={
                        formDiplomas?.img_template
                          ? formDiplomas?.img_template[formDiplomas?.page - 1]
                          : formDiplomas?.img_prev
                          ? formDiplomas?.img_prev[formDiplomas?.page - 1]
                          : null
                      }
                      variant="rounded"
                      className={classes.avatar}
                      onClick={() => setOpen(formDiplomas?.img_prev ? true : false)}
                    >
                      <CardMembershipOutlined sx={{ margin: 4, fontSize: '4em' }} />
                    </Avatar>
                  </Tooltip>
                  <Box component="span" display="flex" justifyContent="center">
                    <Pagination
                      color="secondary"
                      count={formDiplomas?.pages || 0}
                      page={formDiplomas?.page || 1}
                      onChange={handleChangePage}
                      size="small"
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="body2">
                  {t('certificateBadgeTemplate:badges-certificate-form.diploma-img')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.diploma-img-max-file')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.diploma-recommended-size')}
                  <b>{t('certificateBadgeTemplate:badges-certificate-form.horizontal')}</b>
                  {t('certificateBadgeTemplate:badges-certificate-form.least')}
                  <b>950 x 660 </b>
                  {t('certificateBadgeTemplate:badges-certificate-form.pixels')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.diplomas')}
                  <b>{t('certificateBadgeTemplate:badges-certificate-form.vertical')} 668 x 890 </b>
                  {t('certificateBadgeTemplate:badges-certificate-form.pixels')}
                </Typography>
                <div className={classes.contentInput}>
                  <input
                    multiple
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    name="imagen"
                    type="file"
                    onChange={(e) => handleFile(e)}
                    disabled={nombrePrivado()}
                  />
                  <Grid container spacing={2}>
                    <Grid item>
                      <label htmlFor="contained-button-file">
                        <Button variant="outlined" color="primary" component="span" disabled={nombrePrivado()}>
                          {t('buttons:buttons.upload-image')}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        disabled={nombrePrivado()}
                        onClick={goToDesignDiploma}
                      >
                        {t('buttons:buttons.design-diploma')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.diploma-public-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    required
                    label=""
                    name="nombre"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={formDiplomas?.nombre}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.diploma-private-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label=""
                    name="nombre_privado"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={formDiplomas?.nombre_privado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.description')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    required
                    label=""
                    name="descripcion"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    value={formDiplomas?.descripcion}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.additional')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    label=""
                    name="url"
                    variant="outlined"
                    size="small"
                    placeholder="http://"
                    onChange={handleInput}
                    value={formDiplomas?.url}
                    type="url"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.skills')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl>
                  <Autocomplete
                    disabled={nombrePrivado()}
                    multiple
                    disableClearable
                    freeSolo
                    className={classes.autocomplete}
                    onChange={(e, newSkill) => handleSkill(e, newSkill)}
                    options={skillsOptions
                      .sort((a, b) => (a.descripcion.trim() < b.descripcion.trim() ? -1 : 1))
                      .map((skill) => ({
                        id_habilidades: skill.id,
                        descripcion: skill.descripcion,
                      }))}
                    getOptionLabel={(option) => option.descripcion}
                    renderTags={() => null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        variant="outlined"
                        label={t('selects:selects-autocompletes.skill-select')}
                      />
                    )}
                  />
                </FormControl>
                {formDiplomas?.skills?.length > 0 ? (
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      {formDiplomas.skills.map((hability, index) => (
                        <Grid key={`skill-chip-${index}`}>
                          <Chip
                            disabled={nombrePrivado()}
                            onDelete={() => deleteSkill(hability)}
                            onClick={() => handleChip(hability, index)}
                            variant="outlined"
                            label={
                              <Typography className={classes.skills}>
                                {typeof hability === 'string' ? hability : hability.descripcion}
                              </Typography>
                            }
                            className={classes.chip}
                          />
                        </Grid>
                      ))}
                    </CardContent>
                  </Card>
                ) : (
                  ''
                )}
              </Grid>
              {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        id="organizacion"
                        name="id_organizaciones"
                        value={formDiplomas.id_organizaciones || ''}
                        disabled={nombrePrivado()}
                        onChange={handleInput}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganization()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {organizations?.organizacionesRelaciones?.length > 0 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={nombrePrivado()}
                        id="organizacion"
                        name="id_organizacion_hija"
                        onChange={handleInput}
                        value={formDiplomas.id_organizacion_hija}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganizationsRelations()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.criteria-diploma-title')}
            </Typography>
            {formDiplomas.activities.map((item, index) => (
              <Grid container key={`grid-activity-${index}`} spacing={2} className={classes.root}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1" className={classes.text} align="right">
                    {`${t('certificateBadgeTemplate:badges-certificate-form.criterion')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <Typography variant="body1" align="left">
                        {`${t('certificateBadgeTemplate:badges-certificate-form.type')}${
                          item.descripcion_criterio ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl variant="outlined" className={`w-100 ${classes.fieldCard}`} size="small">
                        <Select
                          name="tipo_criterio_vp"
                          value={item.tipo_criterio_vp || ''}
                          required={item.descripcion_criterio ? true : false}
                          disabled={nombrePrivado()}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                        >
                          <MenuItem value="">
                            <em>{t('selects:selects-autocompletes.select-option')}</em>
                          </MenuItem>

                          {loadFillDataTypeCriteria()}
                        </Select>
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {`${t('certificateBadgeTemplate:badges-certificate-form.description')}${
                          item.tipo_criterio_vp ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          required={item.tipo_criterio_vp ? true : false}
                          disabled={nombrePrivado()}
                          name="descripcion_criterio"
                          variant="outlined"
                          size="small"
                          multiline
                          value={item.descripcion_criterio || ''}
                          rows={4}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {t('certificateBadgeTemplate:badges-certificate-form.criterion-url')}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          disabled={nombrePrivado()}
                          type="url"
                          name="link"
                          variant="outlined"
                          size="small"
                          placeholder="http://"
                          value={item.link || ''}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                    </CardContent>

                    <CardActions>
                      {formDiplomas.activities?.length > 0 && (
                        <Button disabled={nombrePrivado()} size="small" onClick={(e) => deleteActivity(e, item.index)}>
                          <Typography color="textSecondary" variant="body2">
                            <CloseOutlined />
                            {t('buttons:buttons.delete')}
                          </Typography>
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <Button variant="outlined" color="primary" onClick={addActivity} disabled={nombrePrivado()}>
                  {t('buttons:buttons.add-criterion')}
                </Button>
              </Grid>
            </Grid>

            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.attributes')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.type')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="tipo"
                    name="tipo_vp"
                    defaultValue=""
                    value={formDiplomas.tipo_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataTypes()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.level')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="nivel"
                    name="nivel_vp"
                    value={formDiplomas.nivel_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataLevels()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.time')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="tiempo"
                    name="tiempo_vp"
                    value={formDiplomas?.tiempo_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value={''}>
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataTime()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.amount-time')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <TextField
                    disabled={requiredCantidadTiempo(formDiplomas.tiempo_vp) || nombrePrivado()}
                    required={!requiredCantidadTiempo(formDiplomas.tiempo_vp)}
                    type="number"
                    name="cantidad_tiempo"
                    value={cantidadTiempo(formDiplomas.cantidad_tiempo)}
                    variant="outlined"
                    size="small"
                    onKeyUp={onlyNumber}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.cost')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="costo"
                    name="costo_vp"
                    value={formDiplomas?.costo_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCosts()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider />
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} sm="auto">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  fullWidth
                >
                  {t('buttons:buttons.save')}
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleCancel}
                  className={`my-2 ${classes.button}`}
                  fullWidth
                >
                  {t('buttons:buttons.cancel')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.buttonForm}`} onClick={handleClose}>
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={
              formDiplomas?.img_template
                ? formDiplomas?.img_template[formDiplomas?.page - 1]
                : formDiplomas?.img_prev
                ? formDiplomas?.img_prev[formDiplomas?.page - 1]
                : null
            }
            className={classes.img}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loading} />
      <Dialog open={openDesignDiploma} fullScreen TransitionComponent={Transition}>
        <DesignDiplomas
          setOpenDesignDiploma={setOpenDesignDiploma}
          user={user}
          md5OrganizationRoute={md5OrganizationRoute}
          md5UserRoute={md5UserRoute}
          handleCancelGeneral={handleCancel}
          handleSubmit={saveTemplate}
        />
      </Dialog>
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth={true} maxWidth={'sm'}>
        <DialogContent dividers>
          <FormControl className="w-100">
            <TextField
              autoFocus
              required
              multiline
              id="nombre"
              label={t('dialogs:dialog.skill')}
              variant="outlined"
              onChange={(e) => setSkillValue({ ...skillValue, value: e.target.value })}
              defaultValue={skillValue.value}
            />
          </FormControl>
        </DialogContent>
        <DialogActions center>
          <Button variant="contained" className={`my-2 ${classes.buttonDialog}`} onClick={editSkill}>
            {t('buttons:buttons.save')}
          </Button>
          <Button variant="contained" className={`my-2 ${classes.buttonDialog}`} onClick={handleCloseEdit}>
            {t('buttons:buttons.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  card: {
    backgroundColor: colors.background,
  },
  fieldCard: {
    backgroundColor: '#ffffff',
  },
  contentInput: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      alignItems: 'left',
    },
  },
  text: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  autocomplete: {
    width: 590,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    marginBottom: 10,
  },
  skills: {
    whiteSpace: 'normal',
  },
  chip: {
    height: '100%',
    marginBottom: '0.5em',
  },
  img: (props) => ({
    maxWidth: '70vw',
    height:
      props.formDiplomas?.img_prev && props.formDiplomas?.img_prev[0]?.split(':', 1)[0] === 'blob'
        ? props.imageRef?.current?.naturalHeight
        : props.formDiplomas?.height,
  }),
  buttonForm: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  buttonDialog: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
  button: {
    borderRadius: 5,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    formDiplomas: state.formDiplomas
      ? {
          ...state.formDiplomas,
          activities: state.formDiplomas.activities?.length > 0 ? state.formDiplomas.activities : [{ index: 1 }],
        }
      : {
          activities: [{ index: 1 }],
          skills: [],
        },
    diplomaFields: state.diplomaFields,
    returnRoute: state.returnRoute,
    saveDesign: state.saveDesign,
  };
};

const mapDispatchToProps = {
  setDiplomaFields,
  changeBreadcrump,
  setFormDiplomas,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
