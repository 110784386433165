import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Button, Grid, Card, CardContent, TextField, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.1em',
    padding: '8px 40px',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    padding: 5,
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '4em',
  },
}));

function CreateHabilities(props) {
  const { changeBreadcrump } = props;
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({
    descripcion: '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.id) {
      getHabilities().catch(() => {
        history.push('/500');
        window.location.reload();
      });
      changeBreadcrump([{ name: 'Insignias' }, { name: 'Habilidades', route: '/skills' }, { name: 'Editar' }]);
    } else {
      changeBreadcrump([{ name: 'Insignias' }, { name: 'Habilidades', route: '/skills' }, { name: 'Crear' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHabilities = async () => {
    const habilityId = decrypt(props.match.params.id);
    const { data } = await axios.get(`/hability/${habilityId}`);
    setForm(data.hability);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleCancel = () => {
    history.push('/skills');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(!loading);

    if (!props.match.params.id) {
      axios
        .post(`/hability/`, form)
        .then((res) => {
          setLoading(false);
          history.push('/skills');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: 'Creado exitosamente.',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: 'No se ha podido crear.',
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = props.match.params.id;
      axios
        .put(`/hability/${id}`, form)
        .then((res) => {
          handleClose();
          history.push('/skills');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: 'Editado exitosamente.',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: 'No se ha podido editar.',
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography component="h6" variant="h6">
              Descripción de la Habilidad.
            </Typography>
            <Grid container spacing={4} className={classes.root}>
              <Grid item xs={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Describa la habilidad"
                    name="descripcion"
                    variant="outlined"
                    value={form.descripcion}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                Guardar
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(CreateHabilities);
