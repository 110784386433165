import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MailOutline } from '@mui/icons-material';
import colors from '../../assets/styles/colors';
import BadgeRemindersModal from './BadgesRemindersModal';
import CertificatesRemindersModal from './CertificatesRemindersModal';
import Swal from 'sweetalert2';
import axios from '../../api';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0.5em',
  },
}));

const ReminderComponent = (props) => {
  const { selectLoading = false, issuedType, groupId, setLoadingBackdrop } = props;
  const classes = useStyles();
  const [t] = useTranslation(['alerts', 'buttons']);
  const [openDialogBadges, setOpenDialogBadges] = useState(false);
  const [openDialogCertificates, setOpenDialogCertificates] = useState(false);

  const remindersModal = () => {
    if (issuedType[0] === 0) {
      setOpenDialogBadges(true);
    } else if (issuedType[0] === 1) {
      setOpenDialogCertificates(true);
    } else if (issuedType[0] === 2) {
      modalForwadEmailCarnets();
    }
  };

  const modalForwadEmailCarnets = () => {
    Swal.fire({
      title: t('alerts:alerts.see-carnet'),
      text: t('alerts:alerts.resend-carnet'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmail();
      }
    });
  };

  const sendForwadEmail = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/carnetsIssued/forwardEmail/${groupId}`);
      if (data.issued.length > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.carnets-to-see'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <>
      <Button
        disableElevation
        color="primary"
        disabled={selectLoading}
        variant="contained"
        className={`my-2 ${classes.button}`}
        onClick={() => remindersModal()}
      >
        <MailOutline sx={{ marginRight: '0.3em' }} /> {t('buttons:buttons.reminders')}
      </Button>
      <BadgeRemindersModal
        openDialog={openDialogBadges}
        setOpenDialog={setOpenDialogBadges}
        setLoadingBackdrop={setLoadingBackdrop}
        groupId={groupId}
      />
      <CertificatesRemindersModal
        openDialog={openDialogCertificates}
        setOpenDialog={setOpenDialogCertificates}
        setLoadingBackdrop={setLoadingBackdrop}
        groupId={groupId}
      />
    </>
  );
};

export default ReminderComponent;
