import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Typography, IconButton, Grid, Avatar, Divider } from '@material-ui/core';
import {
  WorkspacePremiumOutlined,
  CardMembershipOutlined,
  BadgeOutlined,
  DescriptionOutlined,
  CloseOutlined
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const IssueCardDetails = (props) => {
  const { count, open = false, setOpen, selectedYear, setSelectedYear, loading } = props;
  const classes = useStyles();
  const [t] = useTranslation(['contracts', 'alerts', 'menu']);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle align="center" onClose={closeDialog} />
      <DialogContent>
        {loading ? (
          <Skeleton />
        ) : (
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={5} sm={5} className={classes.grid} align="right">
              <Typography variant="h6" component="h6" className={classes.title}>
                {t('contracts:total-issue')}
              </Typography>
            </Grid>
            <Grid item xs={5} sm={5} className={classes.grid} align="left">
              <Typography variant="h5" component="h5">
                {new Intl.NumberFormat('de-DE').format(count.total)}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <DatePicker
                autoOk
                disableFuture
                size="small"
                views={['year']}
                value={selectedYear}
                onChange={setSelectedYear}
                cancelLabel={t('alerts:alerts.cancel')}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.container} spacing={0}>
          <Grid item xs={3} sm={3} className={classes.grid} align="center">
            {loading ? (
              <Skeleton variant="circle" width={56} height={56} />
            ) : (
              <Avatar className={classes.avatar}>
                <WorkspacePremiumOutlined fontSize="large" />
              </Avatar>
            )}
            <Typography variant="h5" component="h5" className={classes.text}>
              {loading ? <Skeleton /> : new Intl.NumberFormat('de-DE').format(count.totalBadges)}
            </Typography>
            <Typography variant="h6" component="h6" color="textSecondary">
              {loading ? <Skeleton /> : t('menu:badges')}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={3} sm={3} className={classes.grid} align="center">
            {loading ? (
              <Skeleton variant="circle" width={56} height={56} />
            ) : (
              <Avatar className={classes.avatar}>
                <CardMembershipOutlined fontSize="large" />
              </Avatar>
            )}
            <Typography variant="h5" component="h5" className={classes.text}>
              {loading ? <Skeleton /> : new Intl.NumberFormat('de-DE').format(count.totalCertificates)}
            </Typography>
            <Typography variant="h6" component="h6" color="textSecondary">
              {loading ? <Skeleton /> : t('menu:certificates')}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={3} sm={3} className={classes.grid} align="center">
            {loading ? (
              <Skeleton variant="circle" width={56} height={56} />
            ) : (
              <Avatar className={classes.avatar}>
                <BadgeOutlined fontSize="large" />
              </Avatar>
            )}
            <Typography variant="h5" component="h5" className={classes.text}>
              {loading ? <Skeleton /> : new Intl.NumberFormat('de-DE').format(count.totalCarnets)}
            </Typography>
            <Typography variant="h6" component="h6" color="textSecondary">
              {loading ? <Skeleton /> : t('menu:carnets')}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={3} sm={3} className={classes.grid} align="center">
            {loading ? (
              <Skeleton variant="circle" width={56} height={56} />
            ) : (
              <Avatar className={classes.avatar}>
                <DescriptionOutlined fontSize="large" />
              </Avatar>
            )}
            <Typography variant="h5" component="h5" className={classes.text}>
              {loading ? <Skeleton /> : new Intl.NumberFormat('de-DE').format(count.totalDocuments)}
            </Typography>
            <Typography variant="h6" component="h6" color="textSecondary">
              {loading ? <Skeleton /> : t('menu:documents')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1.5em',
    marginBottom: '1em',
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    marginTop: '.5em',
  },
  divider: {
    margin: '0 -0.1em',
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default IssueCardDetails;
