import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

function CertificateCard(props) {
  const {
    certificateImage,
    certificateName,
    certificatePrivateName,
    organizationName,
    issuedId,
    publicCertificate,
    templateCertificate = false,
    format,
    open,
    certificateId,
    openDialogCertificate,
    setRecommendation,
    isCarnets,
    isDocument = false,
    waitDocument,
  } = props;
  const classes = useStyles({ isDocument });
  const history = useHistory();

  const handleClick = () => {
    if (issuedId) {
      if (publicCertificate) {
        if (templateCertificate) {
          if (openDialogCertificate) {
            setRecommendation(issuedId);
            open(false);
            setTimeout(function () {
              pushRecomendation();
            }, 300);
          } else {
            open(true);
            certificateId(issuedId);
          }
        } else {
          let enlace = document.createElement('a');
          enlace.href = `/${isCarnets ? 'carnet' : 'certificate'}/${issuedId}`;
          enlace.target = 'blank';
          enlace.click();
        }
      } else {
        if (templateCertificate) {
          history.push(`/${isCarnets ? 'carnets' : 'certificates'}/details/${issuedId}`);
          window.location.reload();
        } else {
          open(true);
          certificateId(issuedId);
        }
      }
    }
  };

  const pushRecomendation = () => {
    open(true);
  };

  return (
    <div className={classes.certificate} onClick={handleClick}>
      {templateCertificate ? (
        <img src={certificateImage} alt="certificate" className={classes.certificate__image_template} />
      ) : (
        <>
          <img
            src={certificateImage}
            alt="certificate"
            className={format === 0 ? classes.certificate__image_Vertical : classes.certificate__image_horizontal}
          />
        </>
      )}
      <p className={classes.certificate__organization_name_font_small}>{organizationName}</p>
      <h3 className={certificateName.length > 75 ? classes.certificate__name_font_small : classes.certificate__name}>
        {certificateName}
      </h3>
      {waitDocument !== '' && <p className={classes.nameWait}>{waitDocument}</p>}
      <h6 className={classes.certificate__name_private}>{certificatePrivateName}</h6>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  certificate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: '10px',
    height: 343,
    width: 253,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '10px',
      height: 400,
      width: 323,
      cursor: 'pointer',
    },
  },
  certificate__image_horizontal: {
    marginTop: '0.5em',
    marginBottom: '2em',
    height: '150px',
    '& img': {
      height: 'min-content',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '0.5em',
      marginBottom: '2em',
      height: '200px',
      '& img': {
        height: 'min-content',
      },
    },
  },
  certificate__image_Vertical: {
    border: ({ isDocument }) => isDocument && '1px solid #F3F3F3',
    borderRadius: ({ isDocument }) => isDocument && '10px',
    margin: '.5em .5em',
    height: '150px',
    '& img': {
      height: 'min-content',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '.5em .5em',
      height: '260px',
      '& img': {
        height: 'min-content',
      },
    },
  },
  certificate__image_template: {
    marginTop: '0.5em',
    marginBottom: '2em',
    height: '150px',
    maxWidth: '-webkit-fill-available',
    '& img': {
      height: 'min-content',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '0.5em',
      marginBottom: '2em',
      height: '225px',
      maxWidth: '-webkit-fill-available',
      '& img': {
        height: 'min-content',
      },
    },
  },
  certificate__name: {
    fontSize: '1.3em',
    marginBottom: 0,
    color: '#676767',
    textAlign: 'center',
  },
  certificate__name_font_small: {
    fontSize: '0.8em',
    marginBottom: 0,
    color: '#676767',
    textAlign: 'center',
  },
  certificate__name_private: {
    marginBottom: 0,
    color: '#676767',
    textAlign: 'center',
  },
  certificate__organization_name_font_small: {
    fontSize: '0.8em',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '1em',
  },
  nameWait: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
  },
}));

export default CertificateCard;
