import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Paper,
  Tabs,
  Tab,
  Card,
  CardContent,
  FormControl,
  TextField,
  Button,
  DialogActions,
  Slide,
} from '@material-ui/core';
import { FileDownloadOutlined, CloseOutlined, ImageSearchOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { sortData, getComparator } from '../../actions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2';
import colors from '../../assets/styles/colors';
import axios from '../../api';
import Backdrop from '../Backdrop';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import TableComponent from '../Table';
import EditIcon from '../../assets/static/icons/EditIcon';
import ViewPDF from '../PreviewPdfDocument';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Files = (props) => {
  const { page, rowsPerPage, open = false, setOpen, rows, loadData, fieldsAdditionals } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [value, setValue] = React.useState(0);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [form, setForm] = useState({ descripcion: '', link: '' });
  const [error, setError] = useState({});
  const [viewPreview, setViewPreview] = useState(null);
  const [dialogPreview, setDialogPreview] = useState(false);

  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons', 'tables']);

  const closeDialog = () => {
    setOpen(false);
  };

  const closeDialogForm = () => {
    setForm({ descripcion: '', link: '' });
    setError({});
    setOpenForm(false);
  };

  const downloadPdf = (link) => {
    let enlace = document.createElement('a');
    enlace.download = link;
    enlace.href = link;
    enlace.click();
  };

  const deletePdf = (id) => {
    setOpen(false);
    Swal.fire({
      text: t('alerts:alerts.delete-additinal-document'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id).catch((e) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.error-ocurred'),
            showConfirmButton: false,
            timer: 1500,
          });
        });
      } else {
        setOpen(true);
      }
    });
  };

  const sendDelete = async (id) => {
    setLoading(true);
    const { data } = await axios.delete(`/documentsFieldAdditionals/${id}`);
    if (data) {
      setLoading(false);
      setOpen(false);
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const columnsFilesAdditionals = () => {
    return t('tables:table.columns-files-additionals', { returnObjects: true });
  };

  const columnsFieldsAdditionals = () => {
    return t('tables:table.columns-fields-additionals', { returnObjects: true });
  };

  const deleteField = (id) => {
    setOpen(false);
    Swal.fire({
      text: t('alerts:alerts.delete-additinal-link'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDeleteField(id).catch((e) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.error-ocurred'),
            showConfirmButton: false,
            timer: 1500,
          });
        });
      } else {
        setOpen(true);
      }
    });
  };

  const sendDeleteField = async (id) => {
    setLoading(true);
    const { data } = await axios.delete(`/documentsFieldAdditionals/${id}`);
    if (data) {
      setLoading(false);
      setOpen(false);
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const handleInputFieldAdditional = (event) => {
    // eslint-disable-next-line no-useless-escape
    const urlRegEx = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;

    if (event.target.name === 'link') {
      if (urlRegEx.test(event.target.value) || event.target.value === '') {
        setError({ ...error, [event.target.name]: false });
      } else {
        setError({ ...error, [event.target.name]: true });
      }
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const editField = (id, descripcion, link) => {
    setForm({ descripcion, link, id });
    setOpenForm(true);
  };

  const handleSubmitEdit = async () => {
    setLoading(true);
    await axios
      .put(`/documentsFieldAdditionals/${form.id}`, form)
      .then((res) => {
        if (res.data) {
          setLoading(false);
          setOpenForm(false);
          setOpen(false);
          Swal.fire({
            text: t('alerts:alerts.edited'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            loadData();
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setOpenForm(false);
        setOpen(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.error-ocurred'),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handlePreview = (linkPdf) => {
    setViewPreview(linkPdf);
    setDialogPreview(true);
  };

  const handleClose = () => {
    setDialogPreview(false);
  };

  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth={false} maxWidth={'md'}>
        <DialogTitle align="center" onClose={closeDialog} />
        <DialogContent>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} sm={12} className={classes.grid} align="center">
              <Paper>
                <Tabs
                  className={classes.paper}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Documentos" {...a11yProps(0)} />
                  <Tab label="Links" {...a11yProps(1)} />
                </Tabs>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.grid} align="center">
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <TableComponent
                    columns={columnsFilesAdditionals()}
                    rows={rows}
                    setDirection={setOrdenDirection}
                    setOrderBy={setValueToOrderBy}
                  >
                    <TableBody>
                      {rows.length > 0 ? (
                        sortData(rows, getComparator(ordenDirection, valueToOrderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="center">{row.descripcion}</TableCell>
                              <TableCell align="center">
                                <Tooltip title={t('tables:table.tooltip.preview')}>
                                  <IconButton aria-label="preview" onClick={() => handlePreview(row.linkPdf)}>
                                    <ImageSearchOutlined />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Descargar">
                                  <IconButton aria-label="download" onClick={() => downloadPdf(row.linkPdf)}>
                                    <FileDownloadOutlined />
                                  </IconButton>
                                </Tooltip>
                                {row.tipo !== 3 && (
                                  <Tooltip title="Eliminar">
                                    <IconButton aria-label="delete" onClick={() => deletePdf(row.id)}>
                                      <DeleteForeverOutlined />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan="3">
                            {t('tables:table.empty-table')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </TableComponent>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <TableComponent
                    columns={columnsFieldsAdditionals()}
                    rows={fieldsAdditionals}
                    setDirection={setOrdenDirection}
                    setOrderBy={setValueToOrderBy}
                  >
                    <TableBody>
                      {fieldsAdditionals.length > 0 ? (
                        sortData(fieldsAdditionals, getComparator(ordenDirection, valueToOrderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.descripcion}</TableCell>
                              <TableCell align="left">{row.link}</TableCell>
                              <TableCell align="center">
                                <Tooltip title="Editar">
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => editField(row.id, row.descripcion, row.link)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Eliminar">
                                  <IconButton aria-label="delete" onClick={() => deleteField(row.id)}>
                                    <DeleteForeverOutlined />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan="4">
                            {t('tables:table.empty-table')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </TableComponent>
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </DialogContent>
        <Backdrop loading={loading} />
      </Dialog>
      <Dialog open={openForm} onClose={closeDialogForm} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle align="center" onClose={closeDialogForm} />
        <DialogContent>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Card variant="outlined" className={classes.card}>
                <CardContent>
                  <Typography variant="body1" align="left" className={classes.text}>
                    {`${t('certificateBadgeTemplate:badges-certificate-form.field-description')}`}
                  </Typography>
                  <FormControl className="w-100">
                    <TextField
                      multiline
                      name="descripcion"
                      variant="outlined"
                      size="small"
                      value={form.descripcion}
                      onChange={handleInputFieldAdditional}
                      className={classes.fieldCard}
                    />
                  </FormControl>
                  <Typography variant="body1" align="left" className={classes.text}>
                    {`${t('certificateBadgeTemplate:badges-certificate-form.field-url')}${'*'}`}
                  </Typography>
                  <FormControl className="w-100" required>
                    <TextField
                      label=""
                      name="link"
                      variant="outlined"
                      size="small"
                      placeholder="http://"
                      onChange={handleInputFieldAdditional}
                      value={form.link}
                      className={classes.fieldCard}
                      type="url"
                      required
                      error={error.link}
                      helperText={error.link && 'Url no válido.'}
                    />
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => handleSubmitEdit()}
            disabled={error.link}
          >
            {t('buttons:buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={dialogPreview} TransitionComponent={Transition}>
        <ViewPDF pdf={viewPreview} download={false} handleClose={handleClose} fullView={true} />
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: '2.8px solid #cacaca',
    borderRadius: '10px',
    boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.2)',
    marginBottom: '1rem',
  },
  container: {
    marginTop: '.5em',
    marginBottom: '.5em',
  },
  alert: {
    backgroundColor: theme.palette.primary.light,
  },
  divider: {
    marginTop: '-.5rem',
  },

  button: {
    margin: '0.5em',
    padding: '.5em 1em',
    borderRadius: '10px',
  },
  container__input_root: {
    borderRadius: '10px',
  },
  text: {
    marginTop: '1em',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
  };
};

export default connect(mapStateToProps, null)(Files);
