import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { changeBreadcrump } from '../../actions';
import { useTranslation } from 'react-i18next';
import { getResponseCodePol, getStatePol, getPaymentMethodType } from '../../utils/responseCodesPayu';
import Table from '../../components/Table';
import ModuleHeader from '../../components/ModuleHeader';
import Search from '../../components/SearchBar';
import axios from '../../api';
import moment from 'moment';
import SkeletonTables from '../../components/SkeletonTable';

function Payments(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user, organizationId } = props;
  const history = useHistory();
  const [payments, setPayments] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [loading, setLoading] = useState(true);
  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);
  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  useEffect(() => {
    if (permission.includes('Leer')) {
      getPayments();
      changeBreadcrump(breadCrumps());
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const breadCrumps = () => {
    return t('breadCrumps:breadCrumps.payments', { returnObjects: true });
  };

  const getPayments = async () => {
    const params = {};
    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }
    const { data } = await axios.post(`/payment/getPayments`, params);
    setPayments(data.payments);
    setFiltradas(data.payments);
    setLoading(false);
  };

  const paymentColumn = () => {
    if (isSuper) {
      return t('tables:table.payments', { returnObjects: true });
    } else {
      return t('tables:table.payments', { returnObjects: true });
    }
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="Payments"
        items={payments}
        setItems={setFiltradas}
        setLoading={setLoading}
        loadData={getPayments}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
      />
      <Table columns={paymentColumn()} rows={filtro}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={paymentColumn()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {filtro.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">{moment(row.fecha_hora).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                  <TableCell align="center">{getStatePol(row.estado_transaccion)}</TableCell>
                  <TableCell align="center">{getResponseCodePol(row.codigo_respuesta_estado_transaccion)}</TableCell>
                  <TableCell align="center">{row.firma}</TableCell>
                  <TableCell align="center">{row.codigo_referencia}</TableCell>
                  <TableCell align="center">{row.id_referencia}</TableCell>
                  <TableCell align="center">{row.planes?.nombre}</TableCell>
                  <TableCell align="center">{row.cantidad_emisiones.toLocaleString('es-MX')}</TableCell>
                  <TableCell align="center">{row.valor.toLocaleString('es-MX')}</TableCell>
                  <TableCell align="center">{row.moneda}</TableCell>
                  <TableCell align="center">{getPaymentMethodType(row.metodo_pago)}</TableCell>
                  <TableCell align="center">{row.descripcion}</TableCell>
                  <TableCell align="center">{`${row.usuarios?.primer_nombre} ${row.usuarios?.primer_apellido}`}</TableCell>
                  <TableCell align="center">{row.organizaciones?.nombre}</TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableCell align="center" colSpan="14">
              No hay datos registrados
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'pagos')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
