import React from 'react';
import { makeStyles, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/styles/colors';
import Swal from 'sweetalert2';
import axios from '../../api';

const CertificatesRemindersModal = (props) => {
  const { openDialog = false, setOpenDialog, setLoadingBackdrop, groupId } = props;
  const classes = useStyles();
  const [t] = useTranslation(['alerts', 'buttons']);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const modalForwadEmail = () => {
    setOpenDialog(false);
    Swal.fire({
      title: t('alerts:alerts.certificate-no-accepted'),
      text: t('alerts:alerts.resend-certificate'),
      icon: 'warning',
      showCancelButton: true,
      iconColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmail();
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  const sendForwadEmail = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/certificatesIssued/forwardEmail/${groupId}`);
      if (data.issued.length > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,

          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.certificates-to-accept'),
          icon: 'error',
          iconColor: colors.primary,

          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const modalForwadEmailSignup = () => {
    setOpenDialog(false);
    Swal.fire({
      title: t('alerts:alerts.create-account'),
      text: t('alerts:alerts.email-account'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmailSignup();
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  const sendForwadEmailSignup = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/certificatesIssued/forwardEmail/signup/${groupId}`);
      if (data.issued > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.no-accouts'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const modalForwadEmailShare = () => {
    setOpenDialog(false);
    Swal.fire({
      title: t('alerts:alerts.share-certificate'),
      text: t('alerts:alerts.email-share-certificate'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmailShare();
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  const sendForwadEmailShare = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/certificatesIssued/forwardEmail/share/${groupId}`);
      if (data.issued > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.no-share-certificate'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>{t('dialogs:dialog.choose-option')}</DialogTitle>
        <DialogContent dividers>
          <div className={`text-center ${classes.containerButton}`}>
            <Button
              disableElevation
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={modalForwadEmail}
            >
              {t('buttons:buttons.reminder')}
              <br />
              {t('buttons:buttons.certificate-no-accepted')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={modalForwadEmailSignup}
            >
              {t('buttons:buttons.reminder')}
              <br />
              {t('buttons:buttons.create-account')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={modalForwadEmailShare}
            >
              {t('buttons:buttons.reminder')}
              <br />
              {t('buttons:buttons.share-certificate')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '10px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

export default CertificatesRemindersModal;
