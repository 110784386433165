import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isBefore } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  Link,
  Chip,
  Card,
  IconButton,
  CardContent,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/styles/colors';
import Appbar from '../../components/Appbar';
import axios from '../../api';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import Revoked from '../../assets/img/icon_revocado.png';
import Expired from '../../assets/img/icon_expirado.png';
import VerifiedEn from '../../assets/img/icon_verified.png';
import RevokedEn from '../../assets/img/icon_revoked.png';
import ExpiredEn from '../../assets/img/icon_expired.png';
import { decrypt } from '../../utils/crypt';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';
import PdfIcon from '../../assets/img/pdf-icon.png';
import ViewPDF from '../../components/PreviewPdfCertificate';
import replaceVignettes from '../../utils/replaceVignettes';
import { useTranslation } from 'react-i18next';

function Certificate(props) {
  const classes = useStyles();
  const [certificate, setCertificate] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [skills, setSkills] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [fieldsAdditionals, setFieldsAdditionals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copiedLink, setCopiedLink] = useState([false, '']);
  const [stateCertificate, setStateCertificate] = useState('');
  const [openRequirements, setOpenRequirements] = useState(false);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [openFieldsAdditionals, setOpenFieldsAdditionals] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [Verified, setVerified] = useState('');
  let valida = '';
  const styles = JSON.parse(window.localStorage.state)?.styles;

  const LANGUAGE_EN = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);

  useEffect(() => {
    setVerified(styles.icon_verificado);
    getCertificate().catch(() => {
      props.history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCertificate = async () => {
    setLoading(true);
    const certificateId = decrypt(props.match.params.certificateId);
    const { data } = await axios.get(`/certificatesIssued/certificates/${certificateId}`);
    setCertificate(data?.issued);
    getLevel(data?.issued?.certificate?.plantillasCertificados.nivel_vp);
    getTime(data?.issued?.certificate?.plantillasCertificados.tiempo_vp);
    getCosts(data?.issued?.certificate?.plantillasCertificados.costo_vp);
    setSkills(data?.issued?.certificate?.plantillasCertificados?.plantillasHabilidades);
    setCriteria(data?.issued?.certificate?.plantillasCertificados?.criterios);
    setFieldsAdditionals(data?.issued?.certificate?.plantillasCertificados?.certificadoCamposAdicionales);

    data?.issued?.certificate?.estado === 0
      ? setStateCertificate('Revoked')
      : data?.issued?.certificate?.fecha_expiracion !== null &&
        isBefore(new Date(data?.issued?.certificate?.fecha_expiracion), new Date())
      ? setStateCertificate('Expired')
      : setStateCertificate('Verified');
    setLoading(false);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelCertificate = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelCertificate[0]);
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeCertificate = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeCertificate[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costCertificate = data.costs.filter((data) => data.id === costo_vp);
    setCost(costCertificate[0]);
  };

  if (!cost && !level && !time) {
    valida = 'No hay atributos';
  }

  if (copiedLink[0] === true) {
    Swal.fire({
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
      },
      icon: 'success',
      iconColor: colors.primary,
      buttonsStyling: false,
      title: t('certificateBadgeTemplate:details.copied'),
      text: `${copiedLink[1]} ${t('certificateBadgeTemplate:details.copied-certificate')}`,
    }).then(() => {
      setCopiedLink(false, '');
    });
  }

  return (
    <>
      <Appbar />
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={'text-center'}>
          {loading ? (
            <Skeleton variant="rect" width={500} height={500} className={classes.skeleton} />
          ) : (
            <ViewPDF
              pdf={certificate?.pdfCertificate}
              img={
                certificate?.certificate?.estado === 2 && certificate.certificate?.certificados.length > 0
                  ? certificate?.resizingPhotoCertificate
                  : certificate?.photoCertificate
              }
            />
          )}
        </Grid>
      </Grid>
      <Container className={classes.container} maxWidth={'lg'}>
        <Grid container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={7} lg={7}>
              <Typography variant="h4" gutterBottom className={classes.textName}>
                {loading ? (
                  <Skeleton variant="h3" />
                ) : (
                  <>
                    {certificate.certificate?.plantillasCertificados?.nombre}
                    {LANGUAGE_EN ? (
                      <img
                        alt="img_verificado"
                        src={
                          stateCertificate === 'Verified'
                            ? VerifiedEn
                            : stateCertificate === 'Revoked'
                            ? RevokedEn
                            : ExpiredEn
                        }
                        className={classes.img_verificado}
                      />
                    ) : (
                      <img
                        alt="img_verificado"
                        src={
                          stateCertificate === 'Verified'
                            ? Verified
                            : stateCertificate === 'Revoked'
                            ? Revoked
                            : Expired
                        }
                        className={classes.img_verificado}
                      />
                    )}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={1} className={classes.container_redes}>
              {loading ? (
                <Skeleton variant="circle" width={40} height={40} display="inline" />
              ) : (
                <>
                  <a href={certificate.pdfCertificate} download={certificate.pdfCertificate}>
                    <IconButton className={classes.PdfIcon}>
                      <img src={PdfIcon} alt="pdf" className={classes.PdfIconImage} />
                    </IconButton>
                  </a>
                </>
              )}
            </Grid>

            {certificate?.certificate?.estado === 2 && certificate.certificate?.certificados.length > 0 ? (
              <Grid item xs={12} md={12} lg={1}>
                {loading ? (
                  <Skeleton variant="rect" height={30} />
                ) : (
                  <Link
                    href={`/certificate/verify/${props.match.params.certificateId}`}
                    target="_blank"
                    underline="none"
                    variant="inherit"
                    className={classes.buttonVerificate}
                  >
                    {t('buttons:buttons.verify')}
                  </Link>
                )}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Grid container>
            <Grid container>
              <Grid item xs={12} lg={6} className={classes.container_text_chip_left}>
                <Typography className={classes.textBolder}>
                  {loading ? (
                    <Skeleton variant="rect" width={500} height={33} />
                  ) : (
                    `${t('certificateBadgeTemplate:details.certificate-link')}:`
                  )}
                </Typography>
                {!loading && (
                  <>
                    <Chip
                      variant="outlined"
                      label={`${window.location.origin}/certificate/${props.match.params.certificateId}`}
                      color="default"
                      className={classes.containerButtonToCopy}
                    />
                    <CopyToClipboard
                      text={`${window.location.origin}/certificate/${props.match.params.certificateId}`}
                      onCopy={() => setCopiedLink([true, 'Link'])}
                      className={classes.button}
                    >
                      <span>{t('certificateBadgeTemplate:details.copy')}</span>
                    </CopyToClipboard>
                  </>
                )}
              </Grid>
              <Grid item xs={12} lg={6} className={classes.container_text_chip_right}>
                <Typography className={classes.textBolder}>
                  {loading ? (
                    <Skeleton variant="rect" width={500} height={33} />
                  ) : (
                    `${t('certificateBadgeTemplate:details.certificate-code')}:`
                  )}
                </Typography>
                {!loading && (
                  <>
                    <Chip
                      variant="outlined"
                      label={certificate.certificate?.codigo_certificado}
                      color="default"
                      className={classes.containerButtonToCopy}
                    />
                    <CopyToClipboard
                      text={certificate.certificate?.codigo_certificado}
                      onCopy={() => setCopiedLink([true, 'Código'])}
                      className={classes.button}
                    >
                      <span>{t('certificateBadgeTemplate:details.copy')}</span>
                    </CopyToClipboard>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {loading ? (
                <Skeleton variant="rect" width={'100%'} height={100} className={classes.root} />
              ) : (
                <Typography className={classes.textParagraph} gutterBottom>
                  {certificate.certificate?.plantillasCertificados?.descripcion}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={5} lg={5}>
              {certificate?.certificate?.estado === 2 && certificate.certificate?.certificados.length > 0 ? (
                <Card variant="outlined" className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Grid container alignItems="center">
                      <Grid item lg={2}>
                        {loading ? (
                          <Skeleton variant="circle" className={classes.img_user} />
                        ) : (
                          certificate.certificate?.certificados[0]?.cuentas?.usuarios?.primer_nombre && (
                            <Avatar
                              aria-label="img_certificate"
                              src={certificate.photoUser}
                              className={classes.img_user}
                            />
                          )
                        )}
                      </Grid>
                      <Grid item lg={10}>
                        <Typography className={classes.textTitle}>
                          {loading ? (
                            <Skeleton variant="text" width={250} />
                          ) : (
                            certificate.certificate?.certificados[0]?.cuentas?.usuarios?.primer_nombre &&
                            `${certificate.certificate?.certificados[0]?.cuentas?.usuarios?.primer_nombre} ${
                              certificate.certificate?.certificados[0]?.cuentas?.usuarios?.segundo_nombre || ''
                            } ${certificate.certificate?.certificados[0]?.cuentas?.usuarios?.primer_apellido} ${
                              certificate.certificate?.certificados[0]?.cuentas?.usuarios?.segundo_apellido || ''
                            }`
                          )}
                        </Typography>
                        {loading ? (
                          <Skeleton variant="text" width={150} className={classes.link} />
                        ) : (
                          certificate.certificate?.certificados[0]?.cuentas?.usuarios?.url_perfil && (
                            <Link
                              href={`/dashboard/${certificate.certificate?.certificados[0]?.cuentas?.usuarios?.url_perfil}`}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.link}
                            >
                              {t('certificateBadgeTemplate:details.credencials')}
                              <FontAwesomeIcon icon={faShareSquare} size="xs" />
                            </Link>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Typography className={classes.textBolderDate}>
                {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.issued')}:`}
              </Typography>
              <Typography className={classes.text}>
                {loading ? <Skeleton /> : moment(certificate.certificate?.fecha_emision).format('DD-MM-YYYY')}
              </Typography>
            </Grid>
            {certificate?.certificate?.fecha_expiracion && (
              <Grid item xs={12} md={2} lg={2}>
                <Typography className={classes.textBolderDate}>
                  {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.expiration')}:`}
                </Typography>
                <Typography className={classes.text}>
                  {loading ? <Skeleton /> : moment(certificate.certificate?.fecha_expiracion).format('DD-MM-YYYY')}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={3} lg={3}>
              <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={1}>
                      {loading ? (
                        <Skeleton variant="circle" className={classes.img_user} />
                      ) : (
                        <Avatar
                          variant="square"
                          aria-label="img_certificate"
                          src={certificate.photoOrganization}
                          className={classes.img_org}
                        />
                      )}
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.textNameOrganization}>
                        {loading ? (
                          <Skeleton variant="text" width={250} />
                        ) : (
                          certificate.certificate?.plantillasCertificados?.organizaciones?.nombre
                        )}
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={150} className={classes.linkOrganization} />
                      ) : (
                        <Link
                          className={classes.linkOrganization}
                          href={`/organization/${certificate.certificate?.plantillasCertificados?.organizaciones?.url_perfil}`}
                          target="_blank"
                        >
                          {t('certificateBadgeTemplate:details.more-information')}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              criteria.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openRequirements ? setOpenRequirements(false) : setOpenRequirements(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.requirements')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openRequirements ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openRequirements} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {criteria.map((data, index) =>
                    data.link === null || data.link === '' ? (
                      <Typography key={`criteria${index}`} className={classes.text}>
                        {data.descripcion_criterio}
                      </Typography>
                    ) : (
                      <a
                        href={data.link}
                        target="_blank"
                        rel="noreferrer"
                        key={`criteria${index}`}
                        className={classes.link}
                        gutterBottom
                      >
                        {data.descripcion_criterio}
                      </a>
                    )
                  )}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              valida.length === 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openAttributes ? setOpenAttributes(false) : setOpenAttributes(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.attributes')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openAttributes ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Grid item xs={12} md={12} lg={12}>
                <Collapse in={openAttributes} timeout="auto">
                  <Grid item lg={12} className={classes.content_descriptions}>
                    <Grid container justify="space-evenly" spacing={2} align="center">
                      {level !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {LANGUAGE_EN ? level.valor_parametro_en : level.valor_parametro}
                          </Typography>
                          <Typography>{level ? t('certificateBadgeTemplate:details.level') : ''}</Typography>
                        </Grid>
                      )}

                      {certificate.certificate?.plantillasCertificados?.cantidad_tiempo !== null &&
                      time?.valor_parametro !== undefined ? (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {`${certificate.certificate?.plantillasCertificados?.cantidad_tiempo}  ${
                              LANGUAGE_EN ? time.valor_parametro_en : time.valor_parametro
                            }`}
                          </Typography>
                          <Typography>{t('certificateBadgeTemplate:details.time')}</Typography>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {cost !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {LANGUAGE_EN ? cost.valor_parametro_en : cost.valor_parametro}
                          </Typography>
                          <Typography>{cost ? t('certificateBadgeTemplate:details.cost') : ''}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              skills.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openSkill ? setOpenSkill(false) : setOpenSkill(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.skills')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openSkill ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openSkill} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {skills.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography className={'text-left'}>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              fieldsAdditionals.length > 0 &&
              fieldsAdditionals.map((data, index) => (
                <>
                  <Grid
                    container
                    className={classes.menuItem}
                    justify="space-between"
                    onClick={() =>
                      openFieldsAdditionals ? setOpenFieldsAdditionals(false) : setOpenFieldsAdditionals(index + 1)
                    }
                  >
                    <Grid item lg={11}>
                      <Typography key={`field-title${index}`} className={classes.textMenuItem}>
                        {data.titulo_campo}
                      </Typography>
                    </Grid>
                    <Grid item lg={1}>
                      {openFieldsAdditionals === index + 1 ? (
                        <RemoveOutlined sx={expandeMenuItemStyle} />
                      ) : (
                        <AddOutlined sx={expandeMenuItemStyle} />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={classes.descriptions_fields}>
                    <Collapse in={openFieldsAdditionals === index + 1} timeout="auto">
                      <Grid item lg={12} className={classes.content_descriptions_fields}>
                        <Typography key={`field-description${index}`} className={classes.text}>
                          {data.descripcion_campo.includes('-')
                            ? replaceVignettes(data.descripcion_campo)
                            : data.descripcion_campo}
                        </Typography>
                        {data.link && (
                          <Link href={data.link} target="_blank" rel="noreferrer" className={classes.link}>
                            {t('details.additional')} <FontAwesomeIcon icon={faShareSquare} size="xs" />
                          </Link>
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              ))
            )}

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              certificate.certificate?.plantillasCertificados?.url && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openMoreInfo ? setOpenMoreInfo(false) : setOpenMoreInfo(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.additional')}
                      <FontAwesomeIcon icon={faShareSquare} size="xs" />
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openMoreInfo ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openMoreInfo} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  <Link
                    href={certificate.certificate?.plantillasCertificados?.url}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    {t('certificateBadgeTemplate:details.additional')}
                    <FontAwesomeIcon icon={faShareSquare} size="xs" />
                  </Link>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.footer}>
        <Grid item lg={12}>
          <span className={classes.textParagraph}>{t('certificateBadgeTemplate:details.right-reserved')}</span>
          <p className={classes.textName}>{styles.organizationName}</p>
          <span className={classes.textParagraph}>{moment().format('YYYY')}</span>
        </Grid>
      </Grid>
    </>
  );
}

const expandeMenuItemStyle = {
  color: '#ffffff',
  float: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '1em',
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  img: {
    maxWidth: '-webkit-fill-available',
  },
  textBolder: {
    marginTop: '1em',
    color: '#000000',
    fontWeight: 'bold',
    display: 'inline',
  },
  textTitle: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '2em',
  },
  textName: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textNameOrganization: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '4em',
  },
  text: {
    color: '#000000',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#000000',
    margin: '1em 0 1em 0',
  },
  link: {
    color: colors.primary,
    marginLeft: '2em',
    textDecoration: 'underline',
  },
  linkOrganization: {
    color: colors.primary,
    marginLeft: '4em',
    textDecoration: 'underline',
  },
  button: {
    display: 'block',
    textAlign: 'center',
    backgroundColor: colors.primary,
    marginBottom: '1em',
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      marginTop: '1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  buttonVerificate: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '1em',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  containerButtonToCopy: {
    borderColor: colors.primary,
    marginTop: '1em',
    marginBottom: '1em',
    padding: '.4em 3em',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      borderColor: colors.primary,
      margin: '1em',
      padding: '.4em 1em',
    },
  },
  PdfIcon: {
    width: '30px',
    height: '30px',
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
  },
  PdfIconImage: {
    height: '45px',
  },
  grid: {
    backgroundColor: colors.footer,
    marginBottom: '1em',
  },
  skeleton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    borderRadius: '10px',
    borderColor: '#ffffff',
    backgroundColor: 'white',
    paddingBottom: 2,
    marginTop: '1.5em',
  },
  img_user: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  img_org: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '& img': {
      height: 'min-content',
    },
  },
  cardContent: {
    padding: '0',
  },
  textBolderDate: {
    marginTop: '2.7em',
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '.4em',
  },
  menuItem: {
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  textMenuItem: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  expandeMenuItem: {
    color: '#ffffff',
    float: 'right',
  },
  descriptions: {
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    //marginBottom: 10,
    textAlign: 'center',
  },
  content_descriptions: {
    margin: '1em',
  },
  descriptions_fields: {
    justifyContent: 'left',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  content_descriptions_fields: {
    margin: '1em',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    justifyContent: 'left',
  },
  footer: {
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  linkAdicional: {
    float: 'right',
    marginRight: '5em',
  },
  container_text_chip_right: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
    },
  },
  container_text_chip_left: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  container_redes: {
    marginBottom: '1em',
    marginLeft: '19em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1em',
      marginLeft: '-0em',
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

export default Certificate;
