import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  TextField,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Backdrop,
  Paper,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import colors from '../assets/styles/colors';
import { login, setError, setSignatorieSignUp } from '../actions';
import Swal from 'sweetalert2';
import Alert from '@material-ui/lab/Alert';
import api from '../api';
import { decrypt } from '../utils/crypt';
import axios from '../api';
import { BroadcastChannel } from 'broadcast-channel';
import { env } from '../config/environment';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function Login(props) {
  const [form, setForm] = useState({ correo: '' });
  const { error, setError, user, history, signatorieSignUp, setSignatorieSignUp } = props;
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginStyles, setLoginStyles] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const logoutChannel = new BroadcastChannel('logout');
  const [t] = useTranslation(['loginRegister', 'buttons', 'alerts']);

  const classes = useStyles({ env });

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLoginStyles({
      Logo: styles.logo435x149,
      LoginLogo: styles.icono_login,
      LoginBackground: styles.login_background,
    });
    if (user) {
      setLoading(true);
      valida();
    }

    if (props.match.params.badgeId) {
      setLoading(true);
      getUserIssued();
      const badgeId = decrypt(props.match.params.badgeId);
      const reject = props.match.params.reject;

      if (!reject) {
        api
          .get(`/badgesIssued/accept/${badgeId}`)
          .then((res) => {
            setLoading(false);
            setMessage({ message: res.data.message, severity: res.data.severity });
          })
          .catch((error) => {
            setLoading(false);
            setMessage(t('alerts:alerts.try-again'));
          });
      }
      setLoading(false);
    } else if (props.match.params.certificateId) {
      setLoading(true);
      getUserIssued();
      const certificateId = decrypt(props.match.params.certificateId);
      const reject = props.match.params.reject;

      if (!reject) {
        const aceptIssued = async () => {
          await api
            .get(`/certificatesIssued/accept/${certificateId}`)
            .then((res) => {
              setLoading(false);
              setMessage({ message: res.data.message, severity: res.data.severity });
            })
            .catch((error) => {
              setLoading(false);
              setMessage(t('alerts:alerts.try-again'));
            });
        };
        aceptIssued();
      }
      setLoading(false);
    } else if (props.match.params.carnetId) {
      setLoading(true);
      getUserIssued();
      const carnetId = decrypt(props.match.params.carnetId);
      const reject = props.match.params.reject;

      if (!reject) {
        api
          .get(`/carnetsIssued/accept/${carnetId}`)
          .then((res) => {
            setLoading(false);
            setMessage({ message: res.data.message, severity: res.data.severity });
          })
          .catch((error) => {
            setLoading(false);
            setMessage(t('alerts:alerts.try-again'));
          });
      }
      setLoading(false);
    } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
      setLoading(true);
      getUserIssued();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valida = async () => {
    await axios.post('/user/isTokensNew').catch(() => {
      setLoading(false);
      logoutChannel.postMessage('CerrarSesion');
    });
    await axios
      .get(`/user/${user?.data_user?.id}`)
      .then((res) => {
        setLoading(false);
        if (props.match.params.badgeId) {
          history.push(`/dashboard/badge/${props.match.params.badgeId}`);
        } else if (props.match.params.certificateId) {
          history.push(`/dashboard/certificate/${props.match.params.certificateId}`);
        } else if (props.match.params.carnetId) {
          history.push(`/dashboard/carnet/${props.match.params.carnetId}`);
        } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
          history.push(
            `/dashboard/document/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
          );
        } else {
          history.push('/dashboard');
        }
      })
      .catch(() => {
        setLoading(false);
        logoutChannel.postMessage('CerrarSesion');
      });
  };

  const getUserIssued = async () => {
    if (props.match.params.badgeId) {
      const badgeId = decrypt(props.match.params.badgeId);
      const { data } = await axios.get(`/badgesIssued/receiver/${badgeId}`);
      if (data.data !== null) {
        setForm({
          correo: data.data.email_receptor,
        });
      }
    } else if (props.match.params.certificateId) {
      const certificateId = decrypt(props.match.params.certificateId);
      const { data } = await axios.get(`/certificatesIssued/receiver/${certificateId}`);
      if (data.data !== null) {
        setForm({
          correo: data.data.email_receptor,
        });
      }
    } else if (props.match.params.carnetId) {
      const carnetId = decrypt(props.match.params.carnetId);
      const { data } = await axios.get(`/carnetsIssued/receiver/${carnetId}`);
      if (data.data !== null) {
        setForm({
          correo: data.data.email_receptor,
        });
      }
    } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
      const idSignature = props.match.params.idSignature;
      const { data } = await axios.get(`/signature/receiver/${idSignature}`);
      if (data.signature !== null) {
        if (data.signature.cuentas === null) return history.push('/');
        if (data.signature.cuentas.estado === false || data.signature.cuentas.estado === 0) {
          return (window.location.href = `/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`);
        }
        setForm({
          correo: data.signature.cuentas.correo,
        });
        setMessage({
          message: t('alerts:alerts.sign-in-signatorie'),
          severity: 'info',
        });
      } else {
        Swal.fire({
          icon: 'warning',
          iconColor: colors.primary,
          text: t('alerts:alerts.document-have-signature'),
        }).then(() => {
          window.location.href = '/';
        });
      }
      if (signatorieSignUp) {
        form.correo = signatorieSignUp.email;
        form.password = decrypt(signatorieSignUp.pass);
        setSignatorieSignUp(null);
        handleSubmit();
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        title: 'Error',
        text: t('alerts:alerts.user-incorrect'),
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setError(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    props.login(
      form,
      props.match.params.documentTemplateId && props.match.params.idSignature
        ? `/document/signature/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
        : '/dashboard'
    );
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage:
          window.location.origin === env.mincienciasURL ? 'none' : `url(${loginStyles?.LoginBackground})`,
      }}
    >
      <Paper elevation={0} className={classes.container_card}>
        <div className={classes.container__login}>
          <div className={classes.container__logo}>
            <img src={loginStyles?.Logo} alt="logo" />
          </div>
          {((!props.match.params.reject &&
            (props.match.params.badgeId || props.match.params.certificateId || props.match.params.carnetId)) ||
            (props.match.params.documentTemplateId && props.match.params.idSignature)) && (
            <Alert severity={message.severity}>{message.message}</Alert>
          )}
          <h3 style={{ marginBottom: '.5em' }}>{t('loginRegister:sign-in')}</h3>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className="w-100">
                  <TextField
                    disabled={props.match.params.documentTemplateId && props.match.params.idSignature}
                    required
                    label={t('loginRegister:email')}
                    name="correo"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.correo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:password')}
                    name="password"
                    autoComplete="on"
                    variant="outlined"
                    onChange={handleInput}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoFocus={(props.match.params.badgeId || props.match.params.certificateId) && true}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.classes__login_buttons}>
              <Button color="primary" variant="contained" className={classes.button} type="submit" disableElevation>
                {t('buttons:buttons.sign-in')}
              </Button>
            </div>
            <div className={classes.classes__login_buttons_paragraph}>
              <p style={{ textDecoration: 'underline' }} onClick={() => props.history.push('/account/forget')}>
                {t('loginRegister:forget-password')}
              </p>
              <p onClick={() => props.history.push('/sign-up')}>
                {t('loginRegister:register-yet')}{' '}
                <span style={{ color: colors.primary }}>{t('loginRegister:register')}</span>
              </p>
              {/* {window.location.origin === env.mincienciasURL ? (
                ''
              ) : (
                <p
                  onClick={() => {
                    props.history.push('/new-organization');
                  }}
                >
                  {`${t('loginRegister:have-organization')}, `}
                  <span style={{ color: colors.primary }}>{t('loginRegister:register-organization')}</span>
                </p>
              )} */}
            </div>
          </form>
        </div>
      </Paper>
      <div className={classes.container__image}>
        <img src={loginStyles?.LoginLogo} className={classes.img} alt="login-logo" />
      </div>
      <Backdrop className={classes.backdrop} open={loading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: window.location.origin === props.env.mincienciasURL ? '20px' : 0,
      height: '100vh',
      justifyContent: 'space-around',
    },
  }),
  container_card: {
    padding: '20px',
    borderRadius: '15px',
    margin: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
      margin: '0',
    },
  },
  container__login: {
    '& h2': {
      fontWeight: 700,
    },
    '& img': {
      width: '90%',
    },
  },
  container__logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  classes__login_buttons: {
    display: 'flex',
    marginTop: '1em',
  },
  classes__login_buttons_paragraph: {
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      marginTop: '.5em',
      marginBottom: 0,
      textAlign: 'center',
      cursor: 'pointer',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  container__image: (props) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: window.location.origin === props.env.mincienciasURL ? '83.5vw' : '50vw',
    },
  }),
  img: {
    //width: '100%',
    height: '100%',
  },
  button: {
    margin: '0.5em',
    width: '100%',
    padding: '7px 40px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    error: state.error,
    signatorieSignUp: state.signatorieSignUp,
  };
};

const mapDispatchToProps = {
  login,
  setError,
  setSignatorieSignUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
