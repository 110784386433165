import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import { FindInPageOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import EditIcon from '../../../assets/static/icons/EditIcon';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import Swal from 'sweetalert2';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  valueParameters: {
    margin: '0.25em',
  },
}));

function ValueParameters(props) {
  const { page, rowsPerPage, permission, changeBreadcrump } = props;
  const history = useHistory();
  const [parametersvalueParameters, setParametersvalueParameters] = useState([]);
  const [parametersvalueParametersAll, setParametersvalueParametersAll] = useState([]);
  const [valueParameters, setValuesParameters] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [filtro, setFiltradas] = useState([]);
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  let has = {};

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'menu']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      getParametersvalueParameters()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          history.push('/500');
          window.location.reload();
        });
      changeBreadcrump(t('breadCrumps:breadCrumps.parameter-values', { returnObjects: true }));
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const getParametersvalueParameters = async () => {
    const { data } = await axios.post(`/parameterValueParameter/getParameterValueParameter`);
    setParametersvalueParametersAll(data.parametersValueParameters);
    data.parametersValueParameters = data.parametersValueParameters.filter((o) =>
      has[o.parametros.id] ? false : (has[o.parametros.id] = true)
    );
    setParametersvalueParameters(
      data.parametersValueParameters.sort((a, b) =>
        a.parametros?.nombre.toLowerCase() < b.parametros?.nombre.toLowerCase() ? -1 : 1
      )
    );
    setFiltradas(
      data.parametersValueParameters.sort((a, b) =>
        a.parametros?.nombre.toLowerCase() < b.parametros?.nombre.toLowerCase() ? -1 : 1
      )
    );
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/valueParameters/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/valueParameters/create`);
        break;
      case 'view':
        setDialog(true);
        setValuesParameters(
          parametersvalueParametersAll.filter((info) => info.parametros?.id === id && info.valor_parametro)
        );
        break;
      default:
        break;
    }
  };

  const modalDelete = async (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-parameter'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/parameterValueParameter/${id}`);
      setParametersvalueParametersAll(parametersvalueParametersAll.filter((item) => item.parametros?.id !== id));
      setParametersvalueParameters(parametersvalueParameters.filter((item) => item.parametros?.id !== id));
      setFiltradas(parametersvalueParameters.filter((item) => item.parametros?.id !== id));
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.deleted-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const loadFillDataValuesParameters = (id) => {
    let n = 0;
    parametersvalueParametersAll.map((info) => info.parametros?.id === id && (n += 1));
    if (n !== 0) {
      return (
        <TableCell align="center">
          <span>
            {n}
            <Tooltip title={t('tables:table.tooltip.view-all')}>
              <IconButton aria-label="view" onClick={(e) => handleClick(e, id, 'view')}>
                <FindInPageOutlined>{n}</FindInPageOutlined>
              </IconButton>
            </Tooltip>
          </span>
        </TableCell>
      );
    }
  };

  const closeDialog = () => {
    setDialog(false);
  };

  const columns = () => {
    return t('tables:table.columns-parameter-value', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName={'valueParameters'}
        items={parametersvalueParameters}
        setItems={setFiltradas}
        loadData={getParametersvalueParameters}
        setLoading={setLoading}
      />
      <Table columns={columns()} rows={filtro} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{row.parametros?.nombre}</TableCell>
                    {loadFillDataValuesParameters(row.parametros?.id)}
                    <TableCell align="center">{edit_permission(row.parametros?.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.parametros?.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableCell align="center" colSpan="3">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Dialog open={dialog} onClose={closeDialog} fullWidth={false} maxWidth={'xs'}>
        <DialogContent>
          {valueParameters ? (
            <>
              <List>
                <ListItem>
                  <ListItemIcon>Id</ListItemIcon>
                  <ListItemIcon>N°</ListItemIcon>
                  <ListItemText>{t('menu:parameter-values')}</ListItemText>
                </ListItem>
                <Divider />
                {valueParameters
                  .sort((a, b) => (a.orden < b.orden ? -1 : 1))
                  .map((values) => (
                    <ListItem>
                      <ListItemAvatar>{values.id}</ListItemAvatar>
                      <ListItemAvatar>{values.orden}</ListItemAvatar>
                      <ListItemText>{values.valor_parametro}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            </>
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'valores parámetros')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValueParameters);
