import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Avatar,
  Select,
  MenuItem,
  Dialog,
  Slide,
  Typography,
  Collapse,
  Box,
  withStyles,
  LinearProgress,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import ModuleHeader from '../../components/ModuleHeader';
import Backdrop from '../../components/Backdrop';
import colors from '../../assets/styles/colors';
import axios from '../../api';
import { setFormDocuments, changeBreadcrump, setDocumentsFields } from '../../actions';
import { env } from '../../config/environment';
import { useTranslation } from 'react-i18next';
import TemplateDocumentDesing from './templates/DesignDocuments';
import { DropzoneArea } from 'material-ui-dropzone';
import capitalize from '../../utils/capitalize';
import dataURItoBlob from '../../utils/dataUriToBlob';
import { encrypt, decrypt } from '../../utils/crypt';
import moment from 'moment';
import { Layer, Rect, Stage, Text } from 'react-konva';

function Sign(props) {
  const {
    user,
    organizationId,
    formDocuments,
    setFormDocuments,
    documentsFields,
    setDocumentsFields,
    changeBreadcrump,
    returnRoute,
  } = props;
  const history = useHistory();
  const classes = useStyles({ formDocuments });
  const refImage = useRef([]);
  const refImageHD = useRef([]);
  const refSign = useRef([]);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [documentCollection, setDocumentCollection] = useState([]);
  const [openDesignDocuments, setOpenDesignDocuments] = useState(false);
  const [documentsChange, setDocumentsChange] = useState(
    formDocuments?.img_prev && formDocuments.img_prev[formDocuments?.page - 1] ? true : false
  );
  const isSuperUser =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14;
  const md5OrganizationRoute = md5(`certika-organization-${isSuperUser ? 1 : organizationId}`).toString();
  const md5UserRoute = md5(`certika-user-${isSuperUser ? 1 : user?.data_user?.id}`).toString();

  const documentSigned = props.match.params.id ? true : false;
  const documentTemplateId = props.match.params.id_template ? true : false;
  const [data, setData] = useState([]);

  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'breadCrumps',
    'tables',
    'certifyDocuments',
  ]);

  const [progressBar, setProgressBar] = useState(
    documentsChange
      ? {
          title: `${t('certifyDocuments:form-certify.uploaded-successfully')}`,
          nameFile: formDocuments?.fileName,
          load: true,
          value: 100,
          inProgress: false,
        }
      : { title: '', load: false, value: 0, inProgress: false }
  );

  useEffect(() => {
    getIp();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIp = async () => {
    try {
      const { data: ipData } = await axiosFile({
        method: 'get',
        url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
      });
      setFormDocuments({ ...formDocuments, ip_adress: ipData.ip });
    } catch (error) {
      return Swal.fire({
        icon: 'warning',
        iconColor: colors.primary,
        text: t('alerts:alerts.warning-block'),
        showConfirmButton: true,
      });
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      await getOrganizations();
      await getDocumentsCollections();
      changeBreadcrump(t('breadCrumps:breadCrumps.documents-sign', { returnObjects: true }));
      setFormDocuments({
        ...formDocuments,
        id_organizaciones: !isSuperUser ? organizationId : '',
        change_template:
          formDocuments.id_plantillas_documentos && formDocuments.id_plantillas_documentos !== 0
            ? formDocuments.id_plantillas_documentos
            : 0,
        id_plantillas_documentos: 0,
      });
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations/names`);
    setOrganizations(data.organizations);
  };

  const getDocumentsCollections = async () => {
    const { data } = await axios.post(`/documentsCollections/getDocumentsCollections`, {
      id_organizaciones: organizationId,
    });
    setDocumentCollection(data.documentsCollections);
  };

  const handleInput = async (event) => {
    setFormDocuments({
      ...formDocuments,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputArchive = async (event) => {
    setFormDocuments({
      ...formDocuments,
      id_colecciones_documentos: event.target.value,
      nameArchive:
        event.target.value !== 0
          ? documentCollection.find((item) => item.id === event.target.value).nombre
          : formDocuments?.nombre_archivo,
    });
  };

  const handleCancel = async () => {
    setLoading(true);
    const infoDelete = new FormData();
    infoDelete.append('fileName', formDocuments?.fileName);
    infoDelete.append('numPages', formDocuments?.pages);
    infoDelete.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
    infoDelete.append('certify', true);
    await axiosFile({
      method: 'post',
      url: `${env.apiURL}/file/delete-files-local`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: infoDelete,
    });
    setLoading(false);
    setDocumentsChange(false);
    setProgressBar({ title: '', load: false, value: 0, inProgress: false });
    setFormDocuments({ id_organizaciones: formDocuments.id_organizaciones, method_sign: '' });
    setDocumentsFields(null);
    refImage.current = [];
    refImageHD.current = [];
    if (documentTemplateId) {
      history.push(returnRoute);
    } else {
      history.push('/documents/sign');
    }
  };

  const validImage = async (byFormDocuments, file) => {
    setFormDocuments({ ...byFormDocuments });
    setProgressBar({
      title: `${t('certifyDocuments:form-certify.uploaded-successfully')}`,
      load: true,
      value: 100,
      inProgress: false,
      nameFile: file.name,
    });
    refImage.current = [];
    refImageHD.current = [];
    setDocumentsChange(true);
    setDocumentsChange(true);
    setLoading(false);
  };

  const handleFile = async (e) => {
    const file = e[0];
    if (file) {
      const Document = new FormData();
      Document.append('file', file);
      Document.append('fileName', file.name);
      Document.append('certify', true);
      Document.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/file/upload-documents`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: Document,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressBar({
            title: `${t('certifyDocuments:form-certify.loading')}`,
            load: false,
            value: percentage,
            inProgress: true,
          });
        },
      }).then(({ data: resInfo }) => {
        const byFormDocuments = {
          ...formDocuments,
          img_prev: resInfo.urls,
          imgHD: resInfo.urlsHD,
          pages: resInfo.numPages,
          page: 1,
          fileName: file.name,
          nombre_documento: file.name.split('.')[0],
        };
        setTimeout(() => {
          validImage(byFormDocuments, file);
        }, 3000);
      });
    }
  };

  const validaDate = (fecha) => {
    let actualDate = new Date();
    let currentDate = fecha.split('-');
    let today = new Date();
    actualDate.setFullYear(currentDate[0], currentDate[1] - 1, currentDate[2]);
    if (actualDate >= today) {
      return false;
    } else {
      return true;
    }
  };

  const goToDesignDocuments = async () => {
    if (documentTemplateId) {
      documentsFields
        ?.filter(
          (field) => field.dataSign?.correo && field.dataSign?.correo !== user.correo && field.correo !== user.correo
        )
        ?.map((item, index) => {
          let uri = refSign.current[index].toDataURL();
          item.exampleText = uri;
          return item;
        });
    }

    if (!formDocuments?.ip_adress) {
      try {
        const { data: ipData } = await axiosFile({
          method: 'get',
          url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
        });
        setFormDocuments({ ...formDocuments, ip_adress: ipData.ip });
      } catch (error) {
        return Swal.fire({
          icon: 'warning',
          iconColor: colors.primary,
          text: t('alerts:alerts.warning-block'),
          showConfirmButton: true,
        });
      }
    }

    if (!formDocuments.id_colecciones_documentos && formDocuments.id_colecciones_documentos !== 0) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-select-folder-documents'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    if (formDocuments?.id_colecciones_documentos === 0 && !formDocuments?.nombre_archivo) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.required-folder-name'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setFormDocuments({
      ...formDocuments,
      naturalWidthAndHeight: refImage.current,
      naturalWidthAndHeightHD: refImageHD.current,
    });

    const indexField = documentsFields?.findIndex((item) => item.fieldName === 'certificateCode');
    if (indexField !== undefined && indexField !== -1) {
      documentsFields[indexField].exampleText = documentsFields[indexField].exampleText.includes(':')
        ? documentsFields[indexField].exampleText.split(':')[0]
        : '';
    }
    formDocuments.img_prev && formDocuments.img_prev[formDocuments?.page - 1] && setOpenDesignDocuments(true);
    setLoading(false);
  };

  const handleSubmit = async (e, fontsOptions) => {
    e.preventDefault();

    const uriSignature = [];

    if (formDocuments.id_organizaciones === 0) {
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-organization'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const haveUploadedAnImage = formDocuments.img_prev[formDocuments?.page - 1] ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const { data: contract } = await axios.get(
      `/contract/card/${!isSuperUser ? organizationId : formDocuments.id_organizaciones}`
    );

    if (!contract.contract) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-contract-error'),
        showConfirmButton: true,
      });
      return false;
    }

    if (contract?.contract?.total_emisiones + 1 > contract?.contract?.cantidad_emisiones) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.exceeds-contract'),
        showConfirmButton: true,
      });
      return false;
    }

    if (contract?.contract?.vencimiento ? validaDate(contract?.contract?.vencimiento) : false) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.expired-contract'),
        showConfirmButton: true,
      });
      return false;
    }

    await documentsFields.map(async (item, index) => {
      uriSignature.push({ index, uri: item.exampleText });
      if (item.fontColor) {
        item.fontColor = item.fontColor.hex;
      }
      if (item.rectColor) {
        item.rectColor = item.rectColor.hex;
      }
      if (item.correo && item.exampleText.includes('data')) {
        const signatories = new FormData();
        const blobData = dataURItoBlob(item.exampleText);
        const routeSign = `${md5OrganizationRoute}/documentsTemplate/signatories/${item.correo}.png`;
        item.exampleText = routeSign;
        item.url = '';

        signatories.append('file', blobData);
        signatories.append('route', routeSign);
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/upload-certificate`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: signatories,
        });
      }
      return item;
    });

    let realWidth = formDocuments.naturalWidthAndHeight[0].width;
    let realHeight = formDocuments.naturalWidthAndHeight[0].height;
    // eslint-disable-next-line
    formDocuments.naturalWidthAndHeight.map((item) => {
      if (realWidth < item.width) {
        realWidth = item.width;
      }
      if (realHeight < item.height) {
        realHeight = item.height;
      }
    });

    let realWidthHD = formDocuments.naturalWidthAndHeightHD[0].width;
    let realHeightHD = formDocuments.naturalWidthAndHeightHD[0].height;
    const sizes = [];
    // eslint-disable-next-line
    formDocuments.naturalWidthAndHeightHD.map((item) => {
      if (realWidthHD < item.width) {
        realWidthHD = item.width;
      }
      if (realHeightHD < item.height) {
        realHeightHD = item.height;
      }
      sizes.push({ width: item.width, height: item.height });
    });
    const saltosWidth = parseInt((100 * (realWidthHD - realWidth)) / realWidth);
    const saltosHeight = parseInt((100 * (realHeightHD - realHeight)) / realHeight);

    const trailDate = moment(new Date()).format('LLLL');

    const dataToSend = {
      ...formDocuments,
      width: realWidthHD,
      height: realHeightHD,
      templateWidth: realWidth,
      templateHeight: realHeight,
      saltosWidth: saltosWidth,
      saltosHeight: saltosHeight,
      sizes: sizes,
      trailDate: trailDate,
      id_plantillas_documentos: props.match.params.id_template
        ? decrypt(props.match.params.id_template)
        : formDocuments.id_plantillas_documentos
        ? formDocuments.id_plantillas_documentos
        : 0,
    };
    delete dataToSend.img_template;
    delete dataToSend.naturalWidthAndHeight;
    delete dataToSend.naturalWidthAndHeightHD;
    setLoading(true);

    try {
      if (documentTemplateId) {
        await axios
          .put(`/documentsCertify`, {
            dataToSend,
            documentsFields,
          })
          .then(() => {
            Array.prototype.forEach.call(fontsOptions, (updatedFont) => updatedFont.remove());
            history.push(returnRoute);
          });
      } else {
        await axios
          .post(`/documentsCertify`, {
            dataToSend,
            documentsFields,
          })
          .then((data) => {
            Array.prototype.forEach.call(fontsOptions, (updatedFont) => updatedFont.remove());
            !documentTemplateId && history.push(`/documents/signed/${encrypt(data?.data?.reportes[0]?.id)}`);
            setData(data);
            setLoading(false);
          });
      }
    } catch (error) {
      documentsFields.map((item, index) => {
        if (item.fontColor) {
          item.fontColor = { hex: item.fontColor };
        }
        if (item.rectColor) {
          item.rectColor = { hex: item.rectColor };
        }
        if (item.fieldName === 'certificateCode' && item.exampleText.includes(':')) {
          item.exampleText = item.exampleText.split(':')[0];
        }
        if (item.correo) {
          item.exampleText = uriSignature.filter((item) => item.index === index)[0].uri;
        }
        return item;
      });
      setDocumentsFields(documentsFields);
      setLoading(false);
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    Swal.fire({
      customClass: { container: classes.mySwal },
      icon: 'success',
      iconColor: colors.primary,
      text: t('alerts:alerts.saved'),
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      documentsFields.map((item, index) => {
        if (item.fontColor) {
          item.fontColor = { hex: item.fontColor };
        }
        if (item.rectColor) {
          item.rectColor = { hex: item.rectColor };
        }
        if (item.fieldName === 'certificateCode' && item.exampleText.includes(':')) {
          item.exampleText = item.exampleText.split(':')[0];
        }
        if (item.correo) {
          item.exampleText = uriSignature.filter((item) => item.index === index)[0].uri;
        }
        return item;
      });
      setDocumentsFields(documentsFields);
      if (documentsFields.filter((item) => item.correo).length > 0) {
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts.document-send-signatorie'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            container: classes.mySwal,
            confirmButton: 'custom-button-confirm',
          },
        });
      }
    });
  };

  const downloadDocument = async () => {
    let enlace = document.createElement('a');
    if (data?.data?.routePdf) {
      enlace.download = data?.data?.routePdf;
      enlace.href = data?.data?.routePdf;
      enlace.click();
    } else {
      const { data: doc } = await axios.get(`documentsCertify/${decrypt(props.match.params.id)}`);
      enlace.download = doc?.documentCertify?.pdf;
      enlace.href = doc?.documentCertify?.pdf;
      enlace.click();
    }
  };

  const loadFillDataCollections = () => {
    return documentCollection.map((data) => {
      return (
        <MenuItem key={`collections-${data.id}`} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleChangeDocuments = async () => {
    setLoading(true);
    const infoDelete = new FormData();
    infoDelete.append('fileName', formDocuments?.fileName);
    infoDelete.append('numPages', formDocuments?.pages);
    infoDelete.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
    infoDelete.append('certify', true);
    await axiosFile({
      method: 'post',
      url: `${env.apiURL}/file/delete-files-local`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: infoDelete,
    });
    setLoading(false);
    setFormDocuments({
      ...formDocuments,
      img_prev: null,
      img_template: null,
      pages: 0,
      changeDocument: true,
    });
    setProgressBar({ title: '', load: false, value: 0, inProgress: false });
    setDocumentsChange(false);
  };

  const addToRef = (el) => {
    if (el && !refImage.current.includes(el)) {
      refImage.current.push(el);
    }
  };

  const addToRefHD = (el) => {
    if (el && !refImageHD.current.includes(el)) {
      refImageHD.current.push(el);
    }
  };

  const addToRefSign = (el) => {
    if (el && !refSign.current.includes(el)) {
      refSign.current.push(el);
    }
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          {!documentsChange && <Typography>{t('certifyDocuments:form-certify.upload-document')}</Typography>}
          <form className={classes.root}>
            <Grid container spacing={2} className={classes.root}>
              {!progressBar.inProgress && !progressBar.load && (
                <Grid item xs={12}>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={['.pdf']}
                    dropzoneText={t('certificateBadgeTemplate:badges-certificate-form.documents-dropzoneArea')}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass={classes.dropzoneParagraph}
                    showFileNames={false}
                    showAlerts={true}
                    onChange={handleFile}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  {formDocuments.img_prev &&
                    formDocuments.img_prev.map((item) => {
                      return <Avatar imgProps={{ ref: addToRef }} src={item} style={{ display: 'none' }}></Avatar>;
                    })}
                  {formDocuments.imgHD &&
                    formDocuments.imgHD.map((item) => {
                      return <Avatar imgProps={{ ref: addToRefHD }} src={item} style={{ display: 'none' }}></Avatar>;
                    })}
                </div>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={2}>
                  {(progressBar.inProgress || progressBar.load) && (
                    <Grid item xs={12}>
                      <Typography variant="body1">{progressBar.title}</Typography>
                      <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <BorderLinearProgress variant="determinate" value={progressBar.value} />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{`${Math.round(
                            progressBar.value
                          )}%`}</Typography>
                        </Box>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {progressBar?.nameFile}
                      </Typography>
                    </Grid>
                  )}
                  {documentsChange && (
                    <Grid item xs={6}>
                      <Button
                        disableElevation
                        color="primary"
                        variant="outlined"
                        component="span"
                        onClick={handleChangeDocuments}
                      >
                        {t('buttons:buttons.change-documents')}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography>{t('certifyDocuments:form-certify.archive-document')}*</Typography>
                    <FormControl variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={progressBar?.inProgress}
                        required
                        id="archivo"
                        name="id_colecciones_documentos"
                        onChange={handleInputArchive}
                        value={
                          formDocuments.id_colecciones_documentos !== undefined
                            ? formDocuments.id_colecciones_documentos
                            : ''
                        }
                      >
                        <MenuItem value={0}>
                          <em>{t('certifyDocuments:form-certify.new-archive-document')}</em>
                        </MenuItem>
                        {loadFillDataCollections()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={formDocuments.id_colecciones_documentos === 0}>
                      <>
                        <Typography>{t('certifyDocuments:form-certify.assign-name-archive')}*</Typography>
                        <FormControl className="w-100">
                          <TextField
                            required={formDocuments.id_colecciones_documentos === 0}
                            id="nombre_archivo"
                            name="nombre_archivo"
                            variant="outlined"
                            size="small"
                            onChange={handleInput}
                            value={formDocuments.nombre_archivo}
                          />
                        </FormControl>
                      </>
                    </Collapse>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>{t('certifyDocuments:form-certify.method-to-sign')}</Typography>
                    <FormControl variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={progressBar?.inProgress}
                        required
                        id="method"
                        name="method_sign"
                        onChange={handleInput}
                        value={formDocuments.method_sign}
                      >
                        <MenuItem value={''}>
                          <em>{t('certifyDocuments:form-certify.select-option')}</em>
                        </MenuItem>
                        <MenuItem value={1}>{t('certifyDocuments:form-certify.sign-via-email')}</MenuItem>
                        <MenuItem value={2}>{t('certifyDocuments:form-certify.sign-via-whatsapp')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    {(user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
                      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14) && (
                      <>
                        <Typography>{t('certificateBadgeTemplate:badges-certificate-form.organization')}*</Typography>
                        <FormControl required variant="outlined" className="w-100" size="small">
                          <Select
                            disabled={progressBar?.inProgress}
                            id="organizacion"
                            name="id_organizaciones"
                            onChange={handleInput}
                            value={formDocuments.id_organizaciones}
                            placeholder={t('certificateBadgeTemplate:badges-certificate-form.organization')}
                          >
                            <MenuItem value={0}>
                              <em>{t('selects:selects-autocompletes.select-option-documents')}</em>
                            </MenuItem>
                            {loadFillDataOrganization()}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                disabled={!progressBar.load}
                onClick={goToDesignDocuments}
              >
                {t('buttons:buttons.next')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      <Dialog open={openDesignDocuments} fullScreen TransitionComponent={Transition}>
        <TemplateDocumentDesing
          setOpenDesignDocuments={setOpenDesignDocuments}
          fromCertify={true}
          fromSign={true}
          isEditDocuments={documentTemplateId ? true : false}
          user={user}
          md5OrganizationRoute={md5OrganizationRoute}
          md5UserRoute={md5UserRoute}
          handleCancelGeneral={handleCancel}
          handleSubmit={handleSubmit}
          documentSigned={documentSigned}
          downloadDocument={downloadDocument}
          documentFinish={data}
        />
      </Dialog>

      <div style={{ display: 'none' }}>
        {documentTemplateId &&
          documentsFields
            ?.filter(
              (field) =>
                field.dataSign?.correo && field.dataSign?.correo !== user.correo && field.correo !== user.correo
            )
            ?.map((item) => {
              return (
                <Stage width={520} height={100} ref={addToRefSign}>
                  <Layer>
                    <Rect
                      width={520}
                      height={100}
                      fill={colors.secondary}
                      cornerRadius={8}
                      stroke="black"
                      strokeWidth={2}
                      opacity={0.5}
                    />
                    <Text
                      text={t('certifyDocuments:add-signatories.signatory')}
                      x={1}
                      y={58}
                      width={520}
                      fontSize={20}
                      align="center"
                    />
                    <Text text={item.dataSign.correo} x={1} y={78} width={520} fontSize={20} align="center" />
                  </Layer>
                </Stage>
              );
            })}
      </div>
      <Backdrop loading={loading} />
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  img: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
  buttonForm: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.primary,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  dropzoneParagraph: {
    color: 'gray',
    fontSize: '1.1em',
  },
  checkbox: { display: 'flex', justifyContent: 'flex-end' },
  containerButton: {
    display: 'grid',
    justifyContent: 'center',
  },
  button: {
    margin: '0.5em',
    padding: '10px 40px',
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    formDocuments: state.formDocuments || [],
    documentsFields: state.documentsFields,
    returnRoute: state.returnRoute,
  };
};

const mapDispatchToProps = {
  setDocumentsFields,
  changeBreadcrump,
  setFormDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sign);
