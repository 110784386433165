const hexToRGB = (hex) => {
  let pattern_color = '^#([A-Fa-f0-9]{6})$';
  let rgb_color = { r: '', g: '', b: '' };
  if (hex.match(pattern_color)) {
    let hex2 = hex.replace('#', ''),
      r = parseInt(hex2.substring(0, 2), 16),
      g = parseInt(hex2.substring(2, 4), 16),
      b = parseInt(hex2.substring(4, 6), 16);
    rgb_color = { r: r, g: g, b: b };
    return rgb_color;
  }
};

export default hexToRGB;
