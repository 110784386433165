import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Avatar, Box, Grid, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import NavBar from '../components/profile/NavBar';
import Appbar from '../components/Appbar';
import BadgeCard from '../components/BadgeCard';
import CertificateCard from '../components/CertificateCard';
import { encrypt } from '../utils/crypt';
import { setBadges, setCertificates } from '../actions';
import api from '../api';
import LinkIcon from '../assets/img/cadena.png';
import { useTranslation } from 'react-i18next';
import colors from '../assets/styles/colors';

function Dashboard(props) {
  const { badges, setBadges, certificates, setCertificates, history } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState('');
  const [organization, setOrganization] = useState({});
  const [socialLinks, setSocialLinks] = useState([]);
  const [view, setView] = useState();
  const [t] = useTranslation(['dashboard']);
  const [LogoSinInsignias, setLogoSinInsignias] = useState('');

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLogoSinInsignias(styles.no_insignias);
    mount().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    return function cleanup() {
      setBadges([]);
      setCertificates([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mount = async () => {
    const organizationUrl = props.match.params.id;
    if (props.match.params.id) {
      const { data } = await api.get(`/organization/url/${organizationUrl}`);
      setOrganization(data.organization);

      const link = data.organization?.socialNetworkUserOrganizations.filter((item) => item.id_redes_sociales === 1);
      let links = data.organization?.socialNetworkUserOrganizations.filter((item) => item.id_redes_sociales !== 1);

      links.push(link[0]);
      setSocialLinks(links.filter((item) => item !== undefined));
      getBadges(data.organization?.organizations?.id);
      getCertificates(data.organization?.organizations?.id);
    }
    setLoading(false);
  };

  const getBadges = async (id) => {
    const { data } = await api.post('/templateBadge/getTemplatesBadgesDashboard', { id_organizaciones: id });
    data.templatesBadges.length === 0 ? setView(1) : setView(0);
    setBadges(data.templatesBadges);
  };

  const getCertificates = async (id) => {
    const { data } = await api.post('/certificateTemplate/getCertificateTemplatesDashboard', { id_organizaciones: id });
    data.certificateTemplates.length === 0 ? setView(2) : setView(0);
    setCertificates(data.certificateTemplates);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const itemsPerPage = 10;

  const countOfPages = (itemsPerPage, view) => {
    const noOfPages =
      view === 0 ? Math.ceil(badges?.length / itemsPerPage) : Math.ceil(certificates?.length / itemsPerPage);

    return noOfPages;
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Appbar />
      <div className={classes.container}>
        {loading ? (
          <Skeleton variant="circle">
            <Avatar variant="rounded" className={classes.container__avatar} />
          </Skeleton>
        ) : (
          <Avatar
            variant="rounded"
            className={classes.container__avatar}
            src={organization?.organizations?.linkPhoto}
          />
        )}
        <div className={classes.container__welcome}>
          {loading ? (
            <Skeleton variant="text" width="30%" height={30} />
          ) : (
            <p className={classes.container__welcome_name}>{organization?.organizations?.nombre}</p>
          )}
          {loading ? (
            <Skeleton variant="rect" width="80vw" height={150} />
          ) : (
            <>
              <p>{organization?.organizations?.biografia}</p>
              <div className={classes.container__social}>
                {socialLinks?.map((data, index) => (
                  <a
                    href={data.link}
                    target="_blank"
                    rel="noreferrer"
                    key={`socialmedia${index}`}
                    className={classes.link}
                  >
                    <Avatar variant="rounded" className={classes.container__social_icons} src={data.linkIcons} />
                  </a>
                ))}
                {organization?.organizations?.sitio_web && organization?.organizations?.sitio_web !== '' && (
                  <a
                    href={organization?.organizations?.sitio_web}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    <Avatar variant="rounded" className={classes.container__social_icons} src={LinkIcon} />
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {loading ? (
        <Skeleton variant="rect" height={50} />
      ) : (
        <NavBar
          view={badges.length > 0 ? 0 : certificates.length > 0 ? 1 : 0}
          setView={setView}
          handleSearch={handleSearch}
          organization={true}
        />
      )}
      {loading ? (
        <div>
          {[...Array(6)].map(() => (
            <Skeleton variant="rect">
              <BadgeCard />
            </Skeleton>
          ))}
        </div>
      ) : // eslint-disable-next-line
      (view === 0 && badges.length > 0) || (view === 1 && certificates.length > 0) ? (
        <div>
          <div>
            <Container maxWidth={'xl'}>
              <Grid container justify="center" spacing={3}>
                {view === 0
                  ? badges
                      .filter(
                        (badge) =>
                          badge?.nombre?.toLowerCase().includes(search) ||
                          badge?.organizaciones?.nombre?.toLowerCase().includes(search)
                      )
                      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map((badge, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={classes.badges}>
                          <BadgeCard
                            key={`badge${index}`}
                            badgeImage={badge?.linkPhoto}
                            badgeName={badge?.nombre}
                            organizationName={badge?.organizaciones?.nombre}
                            issuedId={encrypt(badge?.id)}
                            publicBadge={true}
                            templateBadge={true}
                          />
                        </Grid>
                      ))
                  : view === 1
                  ? certificates
                      .filter(
                        (certificate) =>
                          certificate?.nombre?.toLowerCase().includes(search) ||
                          certificate?.organizaciones?.nombre?.toLowerCase().includes(search)
                      )
                      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map((certificate, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={classes.badges}>
                          <CertificateCard
                            key={`certificate${index}`}
                            format={certificate.width < certificate.height ? 0 : 1}
                            certificateImage={certificate.linkPhoto}
                            certificateName={certificate.nombre}
                            organizationName={certificate.organizaciones?.nombre}
                          />
                        </Grid>
                      ))
                  : ''}
              </Grid>
            </Container>
          </div>
          <Box component="span" display="flex" justifyContent="center">
            <Pagination
              count={countOfPages(itemsPerPage, view)}
              page={page}
              onChange={handleChange}
              defaultPage={1}
              size="large"
              className={classes.pagination}
            />
          </Box>
        </div>
      ) : (
        <div className={classes.container_noBadges}>
          <img className={classes.imagen} alt="imagen sin badges" src={LogoSinInsignias} />
          <div className={classes.container_text}>
            <p className={classes.text}>
              {' '}
              {t('dashboard:this-organization')}
              {t('dashboard:badges')}!
            </p>
          </div>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '4em 0',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
      margin: '1em 4em',
    },
  },
  container_noBadges: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  imagen: {
    width: '340px',
    height: '340px',
    margin: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  container_text: {
    maxWidth: '40rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      margin: 0,
    },
  },
  text: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: colors.primary,
    textAlign: 'center',
  },
  container__avatar: {
    width: '250px',
    height: '250px',
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
    '& img': {
      height: '100%',
      objectFit: 'contain',
    },
  },
  container__welcome: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 10px',
    '& p': {
      margin: 0,
      textAlign: 'justify',
    },
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      marginLeft: '3em',
    },
  },
  container__welcome_message: {
    fontSize: '1.3em',
  },
  container__welcome_name: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  container__social: {
    display: 'flex',
    '& > *': {
      margin: 0,
    },
    '& div': {
      margin: '6px 12px',
    },
  },
  container__social_icons: {
    display: 'flex',
    '& > *': {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: 'auto',
    },
  },
  badges: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    badges: state.badges || [],
    certificates: state.certificates || [],
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'dashboard')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  setBadges,
  setCertificates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
