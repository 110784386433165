import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Card, Grid } from '@material-ui/core';
import Breadcump from './Breadcrump';
import colors from '../assets/styles/colors';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    height: '3.5em',
    justifyContent: 'space-between',
    backgroundColor: colors.primary,
    alignItems: 'center',
    padding: 5,
  },
}));

function ModuleHeader(props) {
  const { children, crump } = props;
  const classes = useStyles();

  return (
    <Card className={classes.header} variant="outlined">
      <Grid container spacing={0}>
        <Breadcump crumps={crump} />
      </Grid>
      {children}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    crump: state.crump || [],
  };
};

export default connect(mapStateToProps, null)(ModuleHeader);
