import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

function Dropdown(props) {
  return (
    <Autocomplete
      id={props.id}
      componentname={props.name}
      multiple={props.multiple}
      getOptionLabel={props.getOptionLabel}
      getOptionSelected={props.getOptionSelected}
      filterSelectedOptions={true}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={props.placeholder} />}
    />
  );
}

Dropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default Dropdown;
