import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Link,
  Chip,
  Card,
  CardContent,
  Avatar,
  Collapse,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { WhatsApp, Telegram, AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faFacebookMessenger, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { isBefore } from 'date-fns';
import Appbar from '../components/Appbar';
import colors from '../assets/styles/colors';
import axios from '../api';
import Revoked from '../assets/img/icon_revocado.png';
import Expired from '../assets/img/icon_expirado.png';
import VerifiedEn from '../assets/img/icon_verified.png';
import RevokedEn from '../assets/img/icon_revoked.png';
import ExpiredEn from '../assets/img/icon_expired.png';
import { decrypt } from '../utils/crypt';
import { setCertificate } from '../actions';
import { env } from '../config/environment';
import Skeleton from '@material-ui/lab/Skeleton';
import PdfIcon from '../assets/img/pdf-icon.png';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import ViewPDF from '../components/PreviewPdfCertificate';
import replaceVignettes from '../utils/replaceVignettes';
import XTwitterIcon from '../assets/img/icons8-twitterx.svg';

function Certificate(props) {
  const { user, certificate, setCertificate, certificateId, setOpenDialog, styles } = props;
  const classes = useStyles();
  const history = useHistory();
  const [copiedLink, setCopiedLink] = useState([false, '']);
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [skills, setSkills] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [fieldsAdditionals, setFieldsAdditionals] = useState([]);
  const [stateCertificate, setStateCertificate] = useState('');
  const [openRequirements, setOpenRequirements] = useState(false);
  const [openFieldsAdditionals, setOpenFieldsAdditionals] = useState(false);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [organization, setOrganization] = useState({});
  const menuId = 'primary-search-account-menu';
  let valida = '';

  const LANGUAGE_EN = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);

  if (user === null) {
    history.push('/');
  }

  useEffect(() => {
    getCertificate().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && window.GS) {
      // eslint-disable-next-line no-undef
      GS.reset();
    }
  }, [loading]);

  const getCertificate = async () => {
    setLoading(true);
    const certificatesId = decrypt(certificateId);
    const {
      data: { data },
    } = await axios.get(`/certificates/getCertificate/${certificatesId}`);

    setCertificate({
      ...data.certificate,
      pdfCertificate: data.pdfCertificate,
      photoUser: data.photoUser,
      photoOrganization: data.photoOrganization,
      resizingPhotoCertificate: data.resizingPhotoCertificate,
    });
    getLevel(data.certificate?.certificadosEmitidos?.plantillasCertificados.nivel_vp);
    getTime(data.certificate?.certificadosEmitidos?.plantillasCertificados.tiempo_vp);
    getCosts(data.certificate?.certificadosEmitidos?.plantillasCertificados.costo_vp);
    setSkills(data.certificate?.certificadosEmitidos?.plantillasCertificados?.plantillasHabilidades);
    setCriteria(data.certificate?.certificadosEmitidos?.plantillasCertificados?.criterios);
    setFieldsAdditionals(data.certificate?.certificadosEmitidos?.plantillasCertificados?.certificadoCamposAdicionales);
    setOrganization(
      data.certificate?.certificadosEmitidos?.plantillasCertificados?.id_organizacion_hija !== null
        ? data.certificate?.certificadosEmitidos?.plantillasCertificados?.organizacionesHijas
        : data.certificate?.certificadosEmitidos?.plantillasCertificados?.organizaciones
    );

    data.certificate.certificadosEmitidos?.estado === 0
      ? setStateCertificate('Revoked')
      : data.certificate.certificadosEmitidos?.fecha_expiracion !== null &&
        isBefore(new Date(data.certificate.certificadosEmitidos?.fecha_expiracion), new Date())
      ? setStateCertificate('Expired')
      : setStateCertificate('Verified');
    setLoading(false);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelCertificate = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelCertificate[0]);
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeCertificate = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeCertificate[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costCertificate = data.costs.filter((data) => data.id === costo_vp);
    setCost(costCertificate[0]);
  };

  if (!cost && !level && !time) {
    valida = 'No hay atributos';
  }

  const handleShare = (idSocialNetwork) => {
    axios.post('/share/statisticSocialNetwork', {
      id_redes_sociales: idSocialNetwork,
      id_certificados_emitidos: certificate.id_certificados_emitidos,
      id_usuarios: user.id,
    });
  };

  if (copiedLink[0] === true) {
    Swal.fire({
      icon: 'success',
      iconColor: colors.primary,
      title: t('certificateBadgeTemplate:details.copied'),
      text: `${copiedLink[1]} ${t('certificateBadgeTemplate:details.copied-certificate')}`,
      buttonsStyling: false,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
      },
    }).then(() => {
      setCopiedLink(false, '');
    });
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLinkedInMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toLink = () => {
    let enlace = document.createElement('a');
    enlace.href = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
      certificate.certificadosEmitidos?.plantillasCertificados?.nombre
    }&organizationName=${
      organization?.redesSociales?.find((item) => item.id_redes_sociales === 3)?.username || organization?.nombre
    }&issueYear=${moment(certificate.certificadosEmitidos?.fecha_emision).format('YYYY')}&issueMonth=${moment(
      certificate.certificadosEmitidos?.fecha_emision
    ).format('M')}&expirationYear=${moment(certificate.certificadosEmitidos?.fecha_expiracion).format(
      'YYYY'
    )}&expirationMonth=${moment(certificate.certificadosEmitidos?.fecha_expiracion).format('M')}&certUrl=${
      window.location.origin
    }/certificate/${certificateId}&certId=${certificate.certificadosEmitidos?.codigo_certificado}`;
    enlace.target = '_blank';
    handleShare(3);
    setAnchorEl(null);
    enlace.click();
  };

  return (
    <>
      <Appbar closeButton={true} handleClose={handleClose} />
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={'text-center'}>
          {loading ? (
            <Skeleton variant="rect" width={500} height={500} className={classes.skeleton} />
          ) : (
            <ViewPDF pdf={certificate?.pdfCertificate} img={certificate?.resizingPhotoCertificate} />
          )}
        </Grid>
      </Grid>
      <Container className={classes.container} maxWidth={'lg'}>
        <Grid container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={7} lg={7}>
              <Typography variant="h4" gutterBottom className={classes.textName}>
                {loading ? (
                  <Skeleton variant="h3" />
                ) : (
                  <>
                    {certificate.certificadosEmitidos?.plantillasCertificados?.nombre}
                    {LANGUAGE_EN ? (
                      <img
                        alt="img_verificado"
                        src={
                          stateCertificate === 'Verified'
                            ? VerifiedEn
                            : stateCertificate === 'Revoked'
                            ? RevokedEn
                            : ExpiredEn
                        }
                        className={classes.img_verificado}
                      />
                    ) : (
                      <img
                        alt="img_verificado"
                        src={
                          stateCertificate === 'Verified'
                            ? styles.icon_verificado
                            : stateCertificate === 'Revoked'
                            ? Revoked
                            : Expired
                        }
                        className={classes.img_verificado}
                      />
                    )}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} lg={4} className={classes.container_redes}>
              {loading ? (
                <Skeleton variant="circle" width={40} height={40} display="inline" />
              ) : (
                <>
                  <IconButton
                    className={classes.LinkedInIcon}
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleLinkedInMenuOpen}
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
                  </IconButton>
                  <a
                    id="linkedinShare"
                    href={`https://www.linkedin.com/sharing/share-offsite`}
                    className="gs-foreign-tracking gs-provider-linkedin"
                    data-url={`${
                      styles.organizationName === 'Certika' ? env.certificatesURL : env.certificatesURLMin
                    }${certificateId}`}
                    data-title={`${t('certificateBadgeTemplate:sharing.certificate-sharing')} ${
                      certificate.certificadosEmitidos?.plantillasCertificados?.organizaciones?.redesSociales[0]
                        ?.username
                    } ${'' || organization?.nombre}${styles.organizationName === 'Certika' ? ' vía @certikaco' : '.'}`}
                    onClick={() => handleShare(3)}
                    style={{ display: 'none' }}
                  >
                    Linkedin
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet`}
                    className="gs-foreign-tracking gs-provider-twitter"
                    data-url={`${
                      styles.organizationName === 'Certika' ? env.certificatesURL : env.certificatesURLMin
                    }${certificateId}`}
                    data-title={`${t('certificateBadgeTemplate:sharing.certificate-sharing')} ${
                      organization?.redesSociales?.find((item) => item.id_redes_sociales === 5)?.username ||
                      organization?.nombre
                    }${styles.organizationName === 'Certika' ? ' vía @certikaco' : '.'}`}
                    onClick={() => handleShare(5)}
                  >
                    <IconButton className={classes.xtwitterIcon}>
                      <img src={XTwitterIcon} alt="XTwitter" height={23} />
                    </IconButton>
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php`}
                    className="gs-foreign-tracking gs-provider-facebook"
                    data-url={`${
                      styles.organizationName === 'Certika' ? env.certificatesURL : env.certificatesURLMin
                    }${certificateId}`}
                    onClick={() => handleShare(4)}
                  >
                    <IconButton className={classes.FacebookIcon}>
                      <FontAwesomeIcon icon={faFacebookF} size="sm" />
                    </IconButton>
                  </a>
                  <a
                    href={`https://www.messenger.com/sharer/sharer.php`}
                    className="gs-foreign-tracking gs-provider-fbm"
                    data-url={`${
                      styles.organizationName === 'Certika' ? env.certificatesURL : env.certificatesURLMin
                    }${certificateId}`}
                    onClick={() => handleShare(7)}
                  >
                    <IconButton className={classes.TwitterIcon}>
                      <FontAwesomeIcon icon={faFacebookMessenger} size="sm" />
                    </IconButton>
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send`}
                    className="gs-foreign-tracking gs-provider-whatsapp"
                    data-url={`${
                      styles.organizationName === 'Certika' ? env.certificatesURL : env.certificatesURLMin
                    }whatsapp/${certificateId}`}
                    data-title={`${t('certificateBadgeTemplate:sharing.certificate-sharing')} ${organization?.nombre}${
                      styles.organizationName === 'Certika' ? ' vía Certika' : '.'
                    }`}
                    onClick={() => handleShare(2)}
                  >
                    <IconButton className={classes.WhatsAppIcon}>
                      <WhatsApp />
                    </IconButton>
                  </a>
                  <a
                    href={`https://telegram.me/share`}
                    className="gs-foreign-tracking gs-provider-telegram"
                    data-url={`${
                      styles.organizationName === 'Certika' ? env.certificatesURL : env.certificatesURLMin
                    }${certificateId}`}
                    data-title={`${t('certificateBadgeTemplate:sharing.certificate-sharing')} ${organization?.nombre}${
                      styles.organizationName === 'Certika' ? ' vía Certika' : '.'
                    }`}
                    onClick={() => handleShare(6)}
                  >
                    <IconButton className={classes.TelegramIcon}>
                      <Telegram viewBox="0 0 26 24" />
                    </IconButton>
                  </a>
                  <a href={certificate.pdfCertificate} download={certificate.pdfCertificate}>
                    <IconButton className={classes.PdfIcon}>
                      <img src={PdfIcon} alt="pdf" className={classes.PdfIconImage} />
                    </IconButton>
                  </a>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={1}>
              {loading ? (
                <Skeleton variant="rect" height={30} />
              ) : (
                <Link
                  href={`/certificate/verify/${certificateId}`}
                  target="_blank"
                  underline="none"
                  variant="inherit"
                  className={classes.buttonVerificate}
                >
                  {t('buttons:buttons.verify')}
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid container>
              <Grid item xs={12} lg={6} className={classes.container_text_chip_left}>
                <Typography className={classes.textBolder}>
                  {loading ? (
                    <Skeleton variant="rect" width={500} height={33} />
                  ) : (
                    `${t('certificateBadgeTemplate:details.certificate-link')}:`
                  )}
                </Typography>
                {!loading && (
                  <>
                    <Chip
                      variant="outlined"
                      label={`${window.location.origin}/certificate/${certificateId}`}
                      color="default"
                      className={classes.containerButtonToCopy}
                    />
                    <CopyToClipboard
                      text={`${window.location.origin}/certificate/${certificateId}`}
                      onCopy={() => setCopiedLink([true, 'Link'])}
                      className={classes.button}
                    >
                      <span>{t('certificateBadgeTemplate:details.copy')}</span>
                    </CopyToClipboard>
                  </>
                )}
              </Grid>
              <Grid item xs={12} lg={6} className={classes.container_text_chip_right}>
                <Typography className={classes.textBolder}>
                  {loading ? (
                    <Skeleton variant="rect" width={500} height={33} />
                  ) : (
                    `${t('certificateBadgeTemplate:details.certificate-code')}:`
                  )}
                </Typography>
                {!loading && (
                  <>
                    <Chip
                      variant="outlined"
                      label={certificate.certificadosEmitidos?.codigo_certificado}
                      color="default"
                      className={classes.containerButtonToCopy}
                    />
                    <CopyToClipboard
                      text={certificate.certificadosEmitidos?.codigo_certificado}
                      onCopy={() => setCopiedLink([true, 'Código'])}
                      className={classes.button}
                    >
                      <span>{t('certificateBadgeTemplate:details.copy')}</span>
                    </CopyToClipboard>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              {loading ? (
                <Skeleton variant="rect" width={'100%'} height={100} className={classes.root} />
              ) : (
                <Typography className={classes.textParagraph} gutterBottom>
                  {certificate.certificadosEmitidos?.plantillasCertificados?.descripcion}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container alignItems="center">
                    <Grid item lg={2}>
                      {loading ? (
                        <Skeleton variant="circle" className={classes.img_user} />
                      ) : (
                        <Avatar aria-label="img_certificate" src={certificate.photoUser} className={classes.img_user} />
                      )}
                    </Grid>
                    <Grid item lg={10}>
                      <Typography className={classes.textTitle}>
                        {loading ? (
                          <Skeleton variant="text" width={250} />
                        ) : (
                          `${certificate.cuentas?.usuarios?.primer_nombre} ${
                            certificate.cuentas?.usuarios?.segundo_nombre || ''
                          } ${certificate.cuentas?.usuarios?.primer_apellido} ${
                            certificate.cuentas?.usuarios?.segundo_apellido || ''
                          }`
                        )}
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={150} className={classes.link} />
                      ) : (
                        <Link
                          href={`/dashboard/${certificate?.cuentas?.usuarios?.url_perfil}`}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          {t('certificateBadgeTemplate:details.credencials')}
                          <FontAwesomeIcon icon={faShareSquare} size="xs" />
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Typography className={classes.textBolderDate}>
                {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.issued')}:`}
              </Typography>
              <Typography className={classes.text}>
                {loading ? <Skeleton /> : moment(certificate.certificadosEmitidos?.fecha_emision).format('DD-MM-YYYY')}
              </Typography>
            </Grid>
            {certificate.certificadosEmitidos?.fecha_expiracion && (
              <Grid item xs={12} md={2} lg={2}>
                <Typography className={classes.textBolderDate}>
                  {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.expiration')}:`}
                </Typography>
                <Typography className={classes.text}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    moment(certificate.certificadosEmitidos?.fecha_expiracion).format('DD-MM-YYYY')
                  )}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={3} lg={3}>
              <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={1}>
                      {loading ? (
                        <Skeleton variant="circle" className={classes.img_user} />
                      ) : (
                        <Avatar
                          variant="square"
                          aria-label="img_certificate"
                          src={certificate.photoOrganization}
                          className={classes.img_org}
                        />
                      )}
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.textNameOrganization}>
                        {loading ? <Skeleton variant="text" width={250} /> : organization?.nombre}
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={150} className={classes.linkOrganization} />
                      ) : (
                        <Link
                          className={classes.linkOrganization}
                          href={`/organization/${organization?.url_perfil}`}
                          target="_blank"
                        >
                          {t('certificateBadgeTemplate:details.more-information')}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              criteria.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openRequirements ? setOpenRequirements(false) : setOpenRequirements(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.requirements')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openRequirements ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openRequirements} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {criteria.map((data, index) =>
                    data.link === null || data.link === '' ? (
                      <Typography key={`criteria${index}`} className={classes.text}>
                        {data.descripcion_criterio}
                      </Typography>
                    ) : (
                      <a
                        href={data.link}
                        target="_blank"
                        rel="noreferrer"
                        key={`criteria${index}`}
                        className={classes.link}
                        gutterBottom
                      >
                        {data.descripcion_criterio}
                      </a>
                    )
                  )}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              valida.length === 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openAttributes ? setOpenAttributes(false) : setOpenAttributes(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.attributes')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openAttributes ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Grid item xs={12} md={12} lg={12}>
                <Collapse in={openAttributes} timeout="auto">
                  <Grid item lg={12} className={classes.content_descriptions}>
                    <Grid container justify="space-evenly" spacing={2} align="center">
                      {level !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {LANGUAGE_EN ? level.valor_parametro_en : level.valor_parametro}
                          </Typography>
                          <Typography>{level ? t('certificateBadgeTemplate:details.level') : ''}</Typography>
                        </Grid>
                      )}

                      {certificate?.certificadosEmitidos?.plantillasCertificados?.cantidad_tiempo !== null &&
                      time?.valor_parametro !== undefined ? (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {`${certificate.certificadosEmitidos?.plantillasCertificados?.cantidad_tiempo} ${
                              LANGUAGE_EN ? time.valor_parametro_en : time.valor_parametro
                            }`}
                          </Typography>
                          <Typography>{t('certificateBadgeTemplate:details.time')}</Typography>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {cost !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {LANGUAGE_EN ? cost.valor_parametro_en : cost.valor_parametro}{' '}
                          </Typography>
                          <Typography>{cost ? t('certificateBadgeTemplate:details.cost') : ''}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              skills.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openSkill ? setOpenSkill(false) : setOpenSkill(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.skills')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openSkill ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openSkill} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {skills.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography className={'text-left'}>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              fieldsAdditionals.length > 0 &&
              fieldsAdditionals.map((data, index) => (
                <>
                  <Grid
                    container
                    className={classes.menuItem}
                    justify="space-between"
                    onClick={() =>
                      openFieldsAdditionals ? setOpenFieldsAdditionals(false) : setOpenFieldsAdditionals(index + 1)
                    }
                  >
                    <Grid item lg={11}>
                      <Typography key={`field-title${index}`} className={classes.textMenuItem}>
                        {data.titulo_campo}
                      </Typography>
                    </Grid>
                    <Grid item lg={1}>
                      {openFieldsAdditionals === index + 1 ? (
                        <RemoveOutlined sx={expandeMenuItemStyle} />
                      ) : (
                        <AddOutlined sx={expandeMenuItemStyle} />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={classes.descriptions_fields}>
                    <Collapse in={openFieldsAdditionals === index + 1} timeout="auto">
                      <Grid item lg={12} className={classes.content_descriptions_fields}>
                        <Typography key={`field-description${index}`} className={classes.text}>
                          {data.descripcion_campo.includes('-')
                            ? replaceVignettes(data.descripcion_campo)
                            : data.descripcion_campo}
                        </Typography>
                        {data.link && (
                          <Link href={data.link} target="_blank" rel="noreferrer" className={classes.link}>
                            {t('details.additional')} <FontAwesomeIcon icon={faShareSquare} size="xs" />
                          </Link>
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              ))
            )}

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              certificate.certificadosEmitidos?.plantillasCertificados?.url && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openMoreInfo ? setOpenMoreInfo(false) : setOpenMoreInfo(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.additional')}
                      <FontAwesomeIcon icon={faShareSquare} size="xs" />
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openMoreInfo ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openMoreInfo} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  <Link
                    href={certificate.certificadosEmitidos?.plantillasCertificados?.url}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    {t('certificateBadgeTemplate:details.additional')}
                    <FontAwesomeIcon icon={faShareSquare} size="xs" />
                  </Link>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={() => toLink()}>
          <IconButton className={classes.LinkedInIconMenu}>
            <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
          </IconButton>
          <spam className={classes.textLinkedMenu}>Agregar en el perfil</spam>
        </MenuItem>
        <MenuItem onClick={() => document.getElementById('linkedinShare').click()}>
          <IconButton className={classes.LinkedInIconMenu}>
            <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
          </IconButton>
          <spam className={classes.textLinkedMenu}>Agregar como publicación</spam>
        </MenuItem>
      </Menu>

      <Grid container className={classes.footer}>
        <Grid item lg={12}>
          <span className={classes.textParagraph}>{t('certificateBadgeTemplate:details.right-reserved')}</span>
          <p className={classes.textName}>{styles.organizationName}</p>
          <span className={classes.textParagraph}>{moment().format('YYYY')}</span>
        </Grid>
      </Grid>
    </>
  );
}

const expandeMenuItemStyle = {
  color: '#ffffff',
  float: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '1em',
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  img: {
    maxWidth: '-webkit-fill-available',
  },
  textBolder: {
    marginTop: '1em',
    color: '#000000',
    fontWeight: 'bold',
    display: 'inline',
  },
  textTitle: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '2em',
  },
  textName: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textNameOrganization: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '4em',
  },
  text: {
    color: '#000000',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#000000',
    margin: '1em 0 1em 0',
  },
  link: {
    color: colors.primary,
    marginLeft: '2em',
    textDecoration: 'underline',
  },
  linkOrganization: {
    color: colors.primary,
    marginLeft: '4em',
    textDecoration: 'underline',
  },
  button: {
    margin: '0px 5px',
    display: 'block',
    textAlign: 'center',
    backgroundColor: colors.primary,
    marginBottom: '1em',
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      marginTop: '1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  buttonVerificate: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '1em',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  container_redes: {
    marginBottom: '1em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  containerButtonToCopy: {
    borderColor: colors.primary,
    marginTop: '1em',
    marginBottom: '1em',
    padding: '.4em 3em',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      borderColor: colors.primary,
      margin: '1em',
      padding: '.4em 1em',
    },
  },
  WhatsAppIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00d954',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#009B3C',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#00d954',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#009B3C',
      },
    },
  },
  LinkedInIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#005374',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#018ec5',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#005374',
      },
    },
  },
  LinkedInIconMenu: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    '&:hover': {
      backgroundColor: '#018ec5',
    },
  },
  textLinkedMenu: {
    fontSize: '.8em',
    marginLeft: '.5em',
  },
  TwitterIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0080A0',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#00ccff',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0080A0',
      },
    },
  },
  FacebookIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#0080ff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#0080ff',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0055A9',
      },
    },
  },
  TelegramIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#00ccff',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0055A9',
      },
    },
  },
  PdfIcon: {
    width: theme.spacing(0),
    height: theme.spacing(0),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0055A9',
      },
    },
  },
  PdfIconImage: {
    height: '45px',
  },
  grid: {
    backgroundColor: colors.footer,
    marginBottom: '1em',
  },
  skeleton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    borderRadius: '10px',
    borderColor: '#ffffff',
    backgroundColor: 'white',
    paddingBottom: 2,
    marginTop: '1.5em',
  },
  img_user: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  img_org: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '& img': {
      height: 'min-content',
    },
  },
  cardContent: {
    padding: '0',
  },
  textBolderDate: {
    marginTop: '2.7em',
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '.4em',
  },
  menuItem: {
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  textMenuItem: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  expandeMenuItem: {
    color: '#ffffff',
    float: 'right',
  },
  descriptions: {
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    //marginBottom: 10,
    textAlign: 'center',
  },
  content_descriptions: {
    margin: '1em',
  },
  descriptions_fields: {
    justifyContent: 'left',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  content_descriptions_fields: {
    margin: '1em',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    justifyContent: 'left',
  },
  footer: {
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  linkAdicional: {
    float: 'right',
    marginRight: '5em',
  },
  mySwal: {
    zIndex: 3000,
  },
  container_text_chip_right: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
    },
  },
  container_text_chip_left: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  xtwitterIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#2f3136',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#000',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    certificate: state.certificate || {},
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  setCertificate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
