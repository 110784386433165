const replaceVignettes = (text) => {
  return (
    <ul>
      {text
        .split('-')
        .slice(1)
        .map((item) => {
          return <li>{item}</li>;
        })}
    </ul>
  );
};

export default replaceVignettes;
