export const getStatePol = (code) => {
  let response = '';
  switch (code) {
    case 7:
      response = 'Pendiente';
      break;
    case 4:
      response = 'Aprobada';
      break;
    case 6:
      response = 'Rechazada';
      break;
    case 5:
      response = 'Expirada';
      break;
    default:
      response = 'Error interno';
      break;
  }

  return response;
};

export const getResponseCodePol = (code) => {
  let response = '';
  switch (code) {
    case null:
      response = 'Transacción pendiente';
      break;
    case 1:
      response = 'Transacción aprobada';
      break;
    case 4:
      response = 'Transacción rechazada por entidad financiera';
      break;
    case 5:
      response = 'Transacción rechazada por el banco';
      break;
    case 6:
      response = 'Fondos insuficientes';
      break;
    case 7:
      response = 'Tarjeta inválida';
      break;
    case 8:
      response = 'Débito automático no permitido';
      break;
    case 9:
      response = 'Tarjeta vencida';
      break;
    case 10:
      response = 'Tarjeta restringida';
      break;
    case 12:
      response = 'La fecha de expiración o el código de seguridad son inválidos';
      break;
    case 13:
      response = 'Reintentar pago';
      break;
    case 14:
      response = 'Transacción inválida';
      break;
    case 17:
      response = 'El valor excede el máximo permitido por la entidad';
      break;
    case 19:
      response = 'Transacción abandonada por el pagador';
      break;
    case 22:
      response = 'Tarjeta no autorizada para comprar por internet';
      break;
    case 23:
      response = 'Transacción rechazada por sospecha de fraude';
      break;
    case 9995:
      response = 'Certificado digital no encontrado';
      break;
    case 9996:
      response = 'Error tratando de comunicarse con el banco';
      break;
    case 9997:
      response = 'Error comunicándose con la entidad financiera';
      break;
    case 9998:
      response = 'Transacción no permitida';
      break;
    case 9999:
      response = 'Error interno';
      break;
    case 20:
      response = 'Transacción expirada';
      break;
    default:
      response = 'Error interno';
      break;
  }

  return response;
};

export const getPaymentMethodType = (code) => {
  let response = '';
  switch (code) {
    case 2:
      response = 'Tarjeta de Crédito';
      break;
    case 4:
      response = 'Transferencias bancarias PSE';
      break;
    case 5:
      response = 'Débitos ACH';
      break;
    case 6:
      response = 'Tarjetas débito';
      break;
    case 7:
      response = 'Efectivo';
      break;
    case 8:
      response = 'Referencia de pago';
      break;
    case 10:
      response = 'Pago en bancos';
      break;
    case 14:
      response = 'Transferencias bancarias SPEI';
      break;
    default:
      response = '';
      break;
  }

  return response;
};
