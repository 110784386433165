import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ModuleHeader from '../../components/ModuleHeader';
import Listing from './Listing';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  Button,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Collapse,
  CircularProgress,
  LinearProgress,
  Radio,
} from '@material-ui/core';
import { FilterListOutlined, CloseOutlined, FileDownloadOutlined } from '@mui/icons-material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from '../../api';
import colors from '../../assets/styles/colors';
import Statistics from './Statistics';
import Swal from 'sweetalert2';
import exportToExcel from '../../utils/exportToExcel';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReminderComponent from '../../components/reminders/AnalyticsReminders';
import Backdrop from '../../components/Backdrop';
import { changePage } from '../../actions';
import { env } from '../../config/environment';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '.5em',
    marginBottom: '.5em',
  },
  button: {
    margin: '0.5em',
  },
  avatar: {
    marginRight: '.4em',
    width: '20px',
    height: '20px',
    borderRadius: '5%',
    '& img': {
      height: 'min-content',
    },
  },
  check: {
    color: colors.primary,
  },
  icon: {
    marginRight: '0.3em',
  },
  tab: {
    color: '#000000',
  },
  indicator: {
    backgroundColor: '#000000',
  },
  circular: {
    color: colors.primary,
  },
  buttonsRight: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
  },
}));

function IndexAnalytics(props) {
  const { permission, organizationId, user, rowsPerPage, changePage } = props;
  const history = useHistory();
  const classes = useStyles();
  const [filtro, setFiltradas] = useState([]);
  const [dataExcel, setDataexcel] = useState([]);
  const [dataToExportExcel, setDataToExportExcel] = useState([]);
  const [tipo, setTipo] = useState(0);
  const [form, setForm] = useState({
    groupsBadgesIssued: '',
    email_receptor: '',
    templates: '',
    estado: [],
    cuenta: [],
    typeAnalysis: [1],
    fecha_inicio: '',
    fecha_fin: '',
    id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
    tipo: [0],
  });
  const [stateList, setStateList] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [datosExport, setDatosExport] = useState([]);
  const [t] = useTranslation(['analytics', 'alerts', 'buttons', 'selects']);
  const estados = t('analytics:analytics-index.state-checkbox', { returnObjects: true });
  const [selectLoading, setSelectLoading] = useState(true);
  const [selectOrganizations, setSelectOrganizations] = useState([{}]);
  const [selectGroups, setSelectGroups] = useState([{}]);
  const [selectTemplates, setSelectTemplates] = useState([{}]);
  const [viewList, setViewList] = useState('');
  const [countItems, setCountItems] = useState();
  const [where, setWhere] = useState('');
  const [statistics, setStatistics] = useState('');
  const [groups, setGroups] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [selectContracts, setSelectContracts] = useState([{}]);
  const [contracts, setContracts] = useState(null);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      groupsBadgesIssued: '',
      templates: '',
      id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
    });
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, [form.tipo]);

  useEffect(() => {
    if (form.groupsBadgesIssued === '') {
      setForm({
        ...form,
        templates: '',
      });
    }
    if (form.templates !== '' && form.tipo.includes(0)) {
      setForm({
        ...form,
        templates: '',
      });
    }
    // eslint-disable-next-line
  }, [form.groupsBadgesIssued]);

  useEffect(() => {
    setForm({
      ...form,
      groupsBadgesIssued: '',
    });
    loadData();
    // eslint-disable-next-line
  }, [form.id_organizaciones]);

  const clearAllFilter = () => {
    Swal.fire({
      text: t('alerts:alerts.analytics-index-alert-delete-filter'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then((result) => {
      if (result.value) {
        setForm({
          groupsBadgesIssued: '',
          templates: '',
          estado: [],
          cuenta: [],
          fecha_inicio: '',
          fecha_fin: '',
          tipo: [form.tipo[0]],
          typeAnalysis: [1],
        });
        setLoading(false);
        setStateList(stateList);
        setFiltering(false);
        setGroups(null);
        setTemplates(null);
        setOrganizations(null);
        setContracts(null);
        setViewList('');
      }
    });
  };

  const loadData = async () => {
    setSelectLoading(true);
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1) await getOrganizations();
    await getGroupsIssued();
    await getTemplatesIssued();
    if (form?.tipo.includes(3)) await getContracts();
    setSelectLoading(false);
  };

  const getOrganizations = async () => {
    const { data } = await axios.get(`/organization/analytics/${form.tipo[0]}`);
    setSelectOrganizations(data.organizations);
  };

  const getContracts = async () => {
    const { data } = await axios.post(
      `/contract/getContracts`,
      form.id_organizaciones !== ''
        ? {
            id_organizaciones: form.id_organizaciones,
          }
        : {}
    );
    setSelectContracts(data.contracts.rows);
  };

  const getGroupsIssued = async () => {
    const { data } = await axios.post(
      `/groupsBadgesIssued/getGroups/analytics/${form.tipo[0]}`,
      form.id_organizaciones !== ''
        ? {
            id: form.id_organizaciones,
          }
        : {}
    );
    setSelectGroups(data.groups);
  };

  const getTemplatesIssued = async () => {
    if (form.tipo[0] !== 3) {
      const { data } = await axios.post(
        `/templateBadge/getTemplates/analytics/${form.tipo[0]}`,
        form.id_organizaciones !== ''
          ? {
              id: form.id_organizaciones,
            }
          : {}
      );
      setSelectTemplates(data.templatesBadges);
    }
  };

  const getIssuedFilter = async () => {
    if ((form.fecha_inicio && !form.fecha_fin) || (!form.fecha_inicio && form.fecha_fin)) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.analytics-index-alert-range-dates-invalid'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (form.fecha_inicio !== '' && form.fecha_inicio === form.fecha_fin) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.analytics-index-alert-range-dates-invalid'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (form.fecha_inicio > form.fecha_fin) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.analytics-index-alert-range-dates-invalid'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    if (form.typeAnalysis.length === 0) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: 'Debe seleccionar un tipo de analítica.',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    if (
      form.typeAnalysis[0] === 0 &&
      form.tipo[0] !== 0 &&
      form.tipo[0] !== 3 &&
      form.groupsBadgesIssued === '' &&
      form.templates === '' &&
      form.id_organizaciones !== env.idEan
    ) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: `Para la analítica de tipo 'Listado' debe seleccionar un grupo de emisión o una plantilla.`,
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }

    setLoading(false);
    setFiltering(true);

    try {
      if (form.tipo[0] === 0 && form.typeAnalysis[0] === 0) {
        const badgesIssuedFilter = await getBadgesIssuedFilter();
        if (badgesIssuedFilter.length === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
        setFiltradas(badgesIssuedFilter);
        setTipo(form.tipo[0]);
      } else if (form.tipo[0] === 0 && form.typeAnalysis[0] === 1) {
        const badgesIssuedFilter = await getBadgesIssuedStatistics();
        setStatistics(badgesIssuedFilter?.statistics);
        setTipo(form.tipo[0]);
        if (badgesIssuedFilter?.statistics?.issued === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
      } else if (form.tipo[0] === 1 && form.typeAnalysis[0] === 0) {
        const certificateIssuedFilter = await getCertificateIssuedFilter();
        if (certificateIssuedFilter.length === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
        setFiltradas(certificateIssuedFilter);
        setTipo(form.tipo[0]);
      } else if (form.tipo[0] === 1 && form.typeAnalysis[0] === 1) {
        const certificateIssuedFilter = await getCertificatesIssuedStatistics();
        setStatistics(certificateIssuedFilter?.statistics);
        setTipo(form.tipo[0]);
        if (certificateIssuedFilter?.statistics?.issued === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
      } else if (form.tipo[0] === 2 && form.typeAnalysis[0] === 0) {
        const carnetsIssuedFilter = await getCarnetsIssuedFilter();
        if (carnetsIssuedFilter.length === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
        setFiltradas(carnetsIssuedFilter);
        setTipo(form.tipo[0]);
      } else if (form.tipo[0] === 2 && form.typeAnalysis[0] === 1) {
        const carnetsIssuedFilter = await getCarnetsIssuedStatistics();
        setStatistics(carnetsIssuedFilter?.statistics);
        setTipo(form.tipo[0]);
        if (carnetsIssuedFilter?.statistics?.issued === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
      } else if (form.tipo[0] === 3 && form.typeAnalysis[0] === 0) {
        const contractsFilter = await getContractsFilter();
        if (contractsFilter.length === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
        setFiltradas(contractsFilter);
        setTipo(form.tipo[0]);
      } else if (form.tipo[0] === 3 && form.typeAnalysis[0] === 1) {
        const contractsFilter = await getContractsStatistics();
        setStatistics(contractsFilter?.statistics);
        if (contractsFilter?.statistics?.issuedTotal === 0) {
          setFiltering(false);
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.analytics-index-alert-no-datas'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }

        setTipo(form.tipo[0]);
      }

      changePage(0);
      form.typeAnalysis[0] === 1 ? setViewList('statistical') : setViewList('list');
      setFiltering(false);
      setLoading(true);
    } catch (error) {
      setFiltering(false);
      Swal.fire({
        text: t('alerts:alerts.error-ocurred'),
        icon: 'error',
        iconColor: colors.primary,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }
  };

  const getBadgesIssuedFilter = async () => {
    const where = {};

    if (form.id_organizaciones) {
      where.id_organizaciones = form.id_organizaciones;
    }
    if (form.groupsBadgesIssued) {
      where.id_grupos_insignias_emitidas = form.groupsBadgesIssued;
    }
    if (form.templates) {
      where.id_plantilla_insignias = form.templates;
    }
    if (form.fecha_inicio) {
      const dateInit = moment(form.fecha_inicio).format('YYYY-MM-DD hh:mm:ss');
      const dateEnd = moment(form.fecha_fin).format('YYYY-MM-DD hh:mm:ss');
      where.fecha_ini = dateInit;
      where.fecha_fin = dateEnd;
    }
    if (form.estado.length > 0) {
      where.estado = form.estado;
    }
    if (form.cuenta.length > 0) {
      where.cuentas = form.cuenta;
    }
    if (form.typeAnalysis[0] === 0) {
      where.limit = rowsPerPage ? rowsPerPage : 10;
      where.offset = 0;
    } else {
      if (where.limit) delete where.limit;
      if (where.offset) delete where.offset;
    }

    const { data } = await axios.post(`/badgesIssued/getBadgesIssued`, where);
    setWhere(where);
    setCountItems(data?.issued?.count);
    getBadgesIssuedForExcel(where);
    return data.issued?.rows;
  };

  const getBadgesIssuedForExcel = async (where) => {
    if (where.limit) delete where.limit;
    if (where.offset) delete where.offset;

    const { data } = await axios.post(`/badgesIssued/getBadgesIssued`, where);
    setDataToExportExcel(data?.issued?.rows);
  };

  const getBadgesIssuedStatistics = async () => {
    const where = {};

    if (form.id_organizaciones) where.id_organizaciones = form.id_organizaciones;
    if (form.groupsBadgesIssued) where.id_grupos_insignias_emitidas = form.groupsBadgesIssued;
    if (form.templates) where.id_plantilla_insignias = form.templates;

    if (form.fecha_inicio) {
      where.fecha_ini = form.fecha_inicio;
      where.fecha_fin = form.fecha_fin;
    }

    if (form.estado.length > 0) where.estado = form.estado;
    if (form.cuenta.length > 0) where.cuentas = form.cuenta;

    const { data } = await axios.post(`/badgesIssued/getStatistics`, where);

    setWhere(where);
    return data;
  };

  const getCertificateIssuedFilter = async () => {
    const where = {};

    if (form.id_organizaciones) {
      where.id_organizaciones = form.id_organizaciones;
    }
    if (form.groupsBadgesIssued) {
      where.id_grupos_certificados_emitidos = form.groupsBadgesIssued;
    }
    if (form.templates) {
      where.id_plantillas_certificados = form.templates;
    }
    if (form.email_receptor) {
      where.email_receptor = form.email_receptor;
    }
    if (form.fecha_inicio) {
      const dateInit = moment(form.fecha_inicio).format('YYYY-MM-DD hh:mm:ss');
      const dateEnd = moment(form.fecha_fin).format('YYYY-MM-DD hh:mm:ss');
      where.fecha_ini = dateInit;
      where.fecha_fin = dateEnd;
    }
    if (form.estado.length > 0) {
      where.estado = form.estado;
    }
    if (form.cuenta.length > 0) {
      where.cuentas = form.cuenta;
    }
    if (form.typeAnalysis[0] === 0) {
      where.limit = rowsPerPage ? rowsPerPage : 10;
      where.offset = 0;
    } else {
      if (where.limit) delete where.limit;
      if (where.offset) delete where.offset;
    }
    const { data } = await axios.post(`/certificatesIssued/getCertificatesIssued`, where);

    setWhere(where);
    setCountItems(data?.issued?.count);

    getCertificateIssuedForExcel(where);

    return data?.issued?.rows;
  };

  const getCertificateIssuedForExcel = async (where) => {
    if (where.limit) delete where.limit;
    if (where.offset) delete where.offset;

    const { data } = await axios.post(`/certificatesIssued/getCertificatesIssued`, where);
    setDataToExportExcel(data?.issued?.rows);
  };

  const getCertificatesIssuedStatistics = async () => {
    const where = {};

    if (form.id_organizaciones) where.id_organizaciones = form.id_organizaciones;
    if (form.groupsBadgesIssued) where.id_grupos_certificados_emitidos = form.groupsBadgesIssued;
    if (form.templates) where.id_plantillas_certificados = form.templates;

    if (form.fecha_inicio) {
      where.fecha_ini = form.fecha_inicio;
      where.fecha_fin = form.fecha_fin;
    }

    if (form.email_receptor) {
      where.email_receptor = form.email_receptor;
    }

    if (form.estado.length > 0) where.estado = form.estado;
    if (form.cuenta.length > 0) where.cuentas = form.cuenta;

    const { data: dataTotalIssued } = await axios.post(`/certificatesIssued/getStatistics/v2`, {
      where,
      type: 'totalIssued',
    });
    where.totalIssued = dataTotalIssued.statistics;
    const { data: dataStatsByState } = await axios.post(`/certificatesIssued/getStatistics/v2`, {
      where,
      type: 'statsByState',
    });
    const { data: dataAccounts } = await axios.post(`/certificatesIssued/getStatistics/v2`, {
      where,
      type: 'accounts',
    });
    const { data: dataShared } = await axios.post(`/certificatesIssued/getStatistics/v2`, { where, type: 'shared' });
    where.sharedTotal = dataShared.statistics;
    const { data: dataSharedBySocial } = await axios.post(`/certificatesIssued/getStatistics/v2`, {
      where,
      type: 'sharedBySocial',
    });
    setWhere(where);
    return {
      statistics: {
        issued: dataTotalIssued.statistics,
        statsByState: dataStatsByState.statistics,
        accounts: dataAccounts.statistics,
        shared: dataShared.statistics,
        sharedBySocial: dataSharedBySocial.statistics,
      },
    };
  };

  const getCarnetsIssuedFilter = async () => {
    const where = {};

    if (form.id_organizaciones) {
      where.id_organizaciones = form.id_organizaciones;
    }
    if (form.groupsBadgesIssued) {
      where.id_grupos_carnets_emitidos = form.groupsBadgesIssued;
    }
    if (form.templates) {
      where.id_plantillas_carnets = form.templates;
    }
    if (form.fecha_inicio) {
      const dateInit = moment(form.fecha_inicio).format('YYYY-MM-DD hh:mm:ss');
      const dateEnd = moment(form.fecha_fin).format('YYYY-MM-DD hh:mm:ss');
      where.fecha_ini = dateInit;
      where.fecha_fin = dateEnd;
    }
    if (form.estado.length > 0) {
      where.estado = form.estado;
    }
    if (form.cuenta.length > 0) {
      where.cuentas = form.cuenta;
    }
    if (form.typeAnalysis[0] === 0) {
      where.limit = rowsPerPage ? rowsPerPage : 10;
      where.offset = 0;
    } else {
      if (where.limit) delete where.limit;
      if (where.offset) delete where.offset;
    }

    const { data } = await axios.post(`/carnetsIssued/getCarnetsIssued`, where);

    setWhere(where);
    setCountItems(data?.issued?.count);

    return data.issued?.rows;
  };

  const getCarnetsIssuedStatistics = async () => {
    const where = {};

    if (form.id_organizaciones) where.id_organizaciones = form.id_organizaciones;
    if (form.groupsBadgesIssued) where.id_grupos_carnets_emitidos = form.groupsBadgesIssued;
    if (form.templates) where.id_plantillas_carnets = form.templates;

    if (form.fecha_inicio) {
      where.fecha_ini = form.fecha_inicio;
      where.fecha_fin = form.fecha_fin;
    }

    if (form.estado.length > 0) where.estado = form.estado;
    if (form.cuenta.length > 0) where.cuentas = form.cuenta;

    const { data } = await axios.post(`/carnetsIssued/getStatistics`, where);
    setWhere(where);
    return data;
  };

  const getContractsFilter = async () => {
    const where = {};

    if (form.id_organizaciones) {
      where.id_organizaciones = form.id_organizaciones;
    }

    if (form.contracts) {
      where.id = form.contracts;
    }

    if (form.typeAnalysis[0] === 0) {
      where.limit = rowsPerPage ? rowsPerPage : 10;
      where.offset = 0;
    } else {
      if (where.limit) delete where.limit;
      if (where.offset) delete where.offset;
    }

    const { data } = await axios.post(`/contract/getContracts`, where);
    setWhere(where);
    setCountItems(data?.contracts?.count);

    return data.contracts?.rows;
  };

  const getContractsStatistics = async () => {
    const where = {};

    if (form.id_organizaciones) where.id_organizaciones = form.id_organizaciones;
    if (form.contracts) where.id = form.contracts;

    const { data } = await axios.post(`/contract/getStatistics`, where);
    setWhere(where);
    return data;
  };

  const handleInput = (event) => {
    if (
      form.tipo.includes(1) &&
      (event.target.name === 'groupsBadgesIssued' || event.target.name === 'email_receptor')
    ) {
      const template = selectGroups.find((item) => (event.target.value ? item.id === event.target.value.id : true));
      setGroups(event.target.value);
      if (env.idEan === form?.id_organizaciones) {
        setForm({
          ...form,
          [event.target.name]: event.target.value,
        });
      } else {
        setForm({
          ...form,
          [event.target.name]: event.target.value.id,
          templates: `${template?.certificadosEmitidos[0]?.plantillasCertificados?.id}`,
        });
      }
    } else if (form.tipo.includes(2) && event.target.name === 'groupsBadgesIssued') {
      const template = selectGroups.find((item) => (event.target.value ? item.id === event.target.value.id : true));
      setGroups(event.target.value);
      setForm({
        ...form,
        [event.target.name]: event.target.value.id,
        templates: `${template?.carnetsEmitidos[0]?.plantillasCarnets?.id}`,
      });
    } else if (form.tipo.includes(3) && event.target.name === 'id_organizaciones') {
      setOrganizations(event.target.value);
      setForm({
        ...form,
        [event.target.name]: event.target.value.id,
      });
    } else if (
      event.target.name === 'id_organizaciones' ||
      event.target.name === 'groupsBadgesIssued' ||
      event.target.name === 'contracts' ||
      (event.target.name === 'templates' && !form.groupsBadgesIssued)
    ) {
      if (event.target.name === 'groupsBadgesIssued') {
        setGroups(event.target.value);
        setForm({
          ...form,
          [event.target.name]: event.target.value.id,
        });
      } else if (event.target.name === 'templates') {
        setTemplates(event.target.value);
      } else if (event.target.name === 'id_organizaciones') {
        setOrganizations(event.target.value);
      } else if (event.target.name === 'contracts') {
        setContracts(event.target.value);
      }
      setForm({
        ...form,
        [event.target.name]: event.target.value.id,
      });
    } else {
      if (event.target.name === 'templates') {
        setTemplates(event.target.value);
      } else if (event.target.name === 'fecha_inicio' || event.target.name === 'fecha_fin') {
        setForm({
          ...form,
          [event.target.name]: event.target.value,
        });
      } else {
        setForm({
          ...form,
          [event.target.name]: event.target.value.id,
        });
      }
    }
  };

  const loadFillDataEstado = () => {
    return estados
      .sort((a, b) => (a.orden < b.orden ? -1 : 1))
      .map((data) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                className={classes.check}
                color="default"
                checked={form.estado.indexOf(data.id) !== -1}
                onClick={handleToggle(data.id)}
              />
            }
            label={data.estado}
          />
        );
      });
  };

  const handleToggle = (value) => () => {
    const currentIndex = form.estado.indexOf(value);
    const newChecked = [...form.estado];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setForm({
      ...form,
      estado: newChecked,
    });
  };

  const handleToggleYes_No = (value) => () => {
    const currentIndex = form.cuenta.includes(value);
    const newChecked = [...form.cuenta];
    if (!currentIndex) {
      newChecked.push(value);
      setForm({
        ...form,
        cuenta: newChecked,
      });
    } else {
      setForm({
        ...form,
        cuenta: newChecked.filter((item) => item !== value),
      });
    }
  };

  const handleToggleTypeAnalysis = (value) => {
    if (value === 1) {
      setForm({
        ...form,
        typeAnalysis: [value],
      });
    } else if (value === 0) {
      setForm({
        ...form,
        typeAnalysis: [value],
      });
    }
  };

  const handleToggleType = async (value) => {
    if (!form.tipo.includes(value)) {
      if (value === 1) {
        setForm({
          ...form,
          tipo: [value],
        });
        setGroups(null);
        setTemplates(null);
        setOrganizations(null);

        setLoading(false);
      } else if (value === 0) {
        setForm({
          ...form,
          tipo: [value],
        });
        setGroups(null);
        setTemplates(null);
        setOrganizations(null);

        setLoading(false);
      } else if (value === 2) {
        setForm({
          ...form,
          tipo: [value],
        });
        setGroups(null);
        setTemplates(null);
        setOrganizations(null);

        setLoading(false);
      } else if (value === 3) {
        setForm({
          ...form,
          tipo: [value],
        });
        setGroups(null);
        setTemplates(null);
        setOrganizations(null);
        setLoading(false);
      }
      setViewList('');
    }
  };

  const exportExcel = () => {
    exportToExcel(dataExcel.rows, dataExcel.filename);
  };

  const exportStatistics = () => {
    window.open(`/analytics/exportStatistics/${datosExport}`);
  };

  const read_permission = () => {
    if (permission.includes('Leer')) {
      return (
        <>
          <Card className={classes.root} elevation={0}>
            <CardContent>
              <Typography component="h6" variant="h6" align="center">
                {t('analytics:analytics-index.title')}
              </Typography>
              <Grid container spacing={3} className={classes.root}>
                <Grid item lg={12}>
                  <Typography component="h6" variant="h6">
                    {t('analytics:analytics-index.type')}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={selectLoading}
                        className={classes.check}
                        color="default"
                        checked={form.tipo.indexOf(0) !== -1}
                        onClick={() => handleToggleType(0)}
                      />
                    }
                    label={t('analytics:analytics-index.radio-buttons-badges')}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={selectLoading}
                        className={classes.check}
                        color="default"
                        checked={form.tipo.indexOf(1) !== -1}
                        onClick={() => handleToggleType(1)}
                      />
                    }
                    label={t('analytics:analytics-index.radio-buttons-certificates')}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={selectLoading}
                        className={classes.check}
                        color="default"
                        checked={form.tipo.indexOf(2) !== -1}
                        onClick={() => handleToggleType(2)}
                      />
                    }
                    label={t('analytics:analytics-index.radio-buttons-carnets')}
                  />
                 {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                    <FormControlLabel
                      control={
                        <Radio
                          disabled={selectLoading}
                          className={classes.check}
                          color="default"
                          checked={form.tipo.indexOf(3) !== -1}
                          onClick={() => handleToggleType(3)}
                        />
                      }
                      label="Contratos"
                    />
                  )}
                </Grid>
                {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                  <>
                    <Grid item xs={12} lg={12}>
                      <FormControl variant="outlined" className="w-100">
                        <Autocomplete
                          value={organizations}
                          onChange={(event, value) => {
                            handleInput({ target: { name: 'id_organizaciones', value: value !== null ? value : '' } });
                          }}
                          id="id_organizaciones"
                          options={selectOrganizations}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                selectLoading ? 'Cargando...' : t('analytics:analytics-index.select-organizations')
                              }
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                              }}
                            />
                          )}
                        />
                        {selectLoading && <LinearProgress />}
                      </FormControl>
                    </Grid>
                    {form?.tipo.includes(3) && (
                      <Grid item xs={12} lg={12}>
                        <FormControl variant="outlined" className="w-100">
                          <Autocomplete
                            value={contracts}
                            onChange={(event, value) => {
                              handleInput({ target: { name: 'contracts', value: value !== null ? value : '' } });
                            }}
                            id="contracts"
                            options={selectContracts}
                            getOptionLabel={(option) =>
                              `${option?.planes?.nombre} (${option?.organizaciones?.nombre}) (${option?.valor_estado})`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={selectLoading ? 'Cargando...' : 'Contratos'}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: <>{params.InputProps.endAdornment}</>,
                                }}
                              />
                            )}
                          />
                          {selectLoading && <LinearProgress />}
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}
                {!form?.tipo.includes(3) && (
                  <>
                    {form.id_organizaciones !== env.idEan ? (
                      <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" className="w-100">
                          <Autocomplete
                            className={classes.autocomplete}
                            value={groups}
                            onChange={(event, value) => {
                              handleInput({ target: { name: 'groupsBadgesIssued', value: value } });
                            }}
                            options={selectGroups}
                            getOptionLabel={(option) =>
                              `${option?.nombre} ${
                                user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1
                                  ? `(${option?.organizaciones?.nombre})`
                                  : ''
                              } ${
                                option?.estado === 0
                                  ? '(Grupo revocado)'
                                  : option?.estado === 2
                                  ? '(Grupo en emisión)'
                                  : ''
                              }`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  selectLoading ? 'Cargando...' : t('analytics:analytics-index.select-groups-issued')
                                }
                                variant="outlined"
                              />
                            )}
                          />
                          {selectLoading && <LinearProgress />}
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" className="w-100">
                          <Autocomplete
                            className={classes.autocomplete}
                            value={groups}
                            onChange={(event, value) => {
                              handleInput({ target: { name: 'email_receptor', value: value } });
                            }}
                            options={selectGroups}
                            getOptionLabel={(option) => `${option}`}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={selectLoading ? 'Cargando...' : t('Usuarios Emitidos')}
                                variant="outlined"
                              />
                            )}
                          />
                          {selectLoading && <LinearProgress />}
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12} lg={6}>
                      <FormControl aling="center" variant="outlined" className="w-100">
                        <Autocomplete
                          value={templates}
                          onChange={(event, value) => {
                            handleInput({ target: { name: 'templates', value: value } });
                          }}
                          label="templates"
                          options={selectTemplates}
                          getOptionLabel={(option) =>
                            `${option.nombre} ${option.nombre_privado !== null ? `(${option.nombre_privado})` : ''} ${
                              user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1
                                ? `(${option.organizaciones?.nombre})`
                                : ''
                            }`
                          }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label={selectLoading ? 'Cargando...' : t('analytics:analytics-index.select-templates')}
                                variant="outlined"
                              />
                            </>
                          )}
                        />
                        {selectLoading && <LinearProgress />}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl className="w-100">
                        <TextField
                          label={t('analytics:analytics-index.date-start')}
                          type="date"
                          variant="outlined"
                          name="fecha_inicio"
                          value={form.fecha_inicio}
                          onChange={handleInput}
                          InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl className="w-100">
                        <TextField
                          label={t('analytics:analytics-index.date-end')}
                          type="date"
                          variant="outlined"
                          name="fecha_fin"
                          value={form.fecha_fin}
                          onChange={handleInput}
                          InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={1}>
                      <Typography component="h8" variant="h8">
                        {t('analytics:analytics-index.states-title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      {loadFillDataEstado()}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <p> {t('analytics:analytics-index.account-title')}</p>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.check}
                            color="default"
                            checked={form.cuenta.includes('Si')}
                            onClick={handleToggleYes_No('Si')}
                          />
                        }
                        label={t('analytics:analytics-index.account-checkbox-yes')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.check}
                            color="default"
                            checked={form.cuenta.includes('No')}
                            onClick={handleToggleYes_No('No')}
                          />
                        }
                        label={t('analytics:analytics-index.account-checkbox-no')}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} lg={6}>
                  <spam> {t('analytics:analytics-index.type-analytics')}: </spam>
                  <FormControlLabel
                    control={
                      <Radio
                        className={classes.check}
                        color="default"
                        checked={form.typeAnalysis.indexOf(0) !== -1}
                        onClick={() => handleToggleTypeAnalysis(0)}
                      />
                    }
                    label={t('analytics:analytics-index.list')}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        className={classes.check}
                        color="default"
                        checked={form.typeAnalysis.indexOf(1) !== -1}
                        onClick={() => handleToggleTypeAnalysis(1)}
                      />
                    }
                    label={t('analytics:analytics-index.statistics')}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} lg={7}>
                  <Button
                    disableElevation
                    color="primary"
                    disabled={selectLoading}
                    variant="contained"
                    className={`my-2 ${classes.button}`}
                    onClick={() => getIssuedFilter()}
                  >
                    <FilterListOutlined sx={{ marginRight: '0.3em' }} /> {t('buttons:buttons.filter')}
                  </Button>
                  <Button
                    disableElevation
                    color="primary"
                    disabled={selectLoading}
                    variant="contained"
                    className={`my-2 ${classes.button}`}
                    onClick={() => clearAllFilter()}
                  >
                    <CloseOutlined sx={{ marginRight: '0.3em' }} /> {t('buttons:buttons.delete')}
                  </Button>
                </Grid>
                {loading && (
                  <Grid item xs={12} lg={5}>
                    <div className={classes.buttonsRight}>
                      {form?.groupsBadgesIssued && (
                        <ReminderComponent
                          selectLoading={selectLoading}
                          issuedType={form?.tipo}
                          groupId={form?.groupsBadgesIssued}
                          setLoadingBackdrop={setLoadingBackdrop}
                        />
                      )}
                      {tipo !== 3 ? (
                        <Button
                          disableElevation
                          color="primary"
                          disabled={selectLoading}
                          variant="contained"
                          className={`my-2 ${classes.button}`}
                          onClick={() => (form.typeAnalysis[0] === 0 ? exportExcel() : exportStatistics())}
                        >
                          <FileDownloadOutlined sx={{ marginRight: '0.3em' }} /> {t('buttons:buttons.export')}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>

              <Collapse in={filtering}>
                <Grid container direction="row" alignItems=" center" justify="space-evenly">
                  <Grid item xs={1} lg={1}>
                    <CircularProgress className={classes.circular} color="default" />
                  </Grid>
                </Grid>
              </Collapse>
              <Divider />

              <Collapse in={loading}>
                {viewList === 'list' ? (
                  <Listing
                    filtro={filtro}
                    dataToExportExcel={dataToExportExcel}
                    setFiltradas={setFiltradas}
                    setDataExcel={setDataexcel}
                    tipo={tipo}
                    countItems={countItems}
                    where={where}
                  />
                ) : (
                  viewList === 'statistical' && <Statistics filtro={statistics} setData={setDatosExport} tipo={tipo} />
                )}
              </Collapse>
            </CardContent>
          </Card>
        </>
      );
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
  };

  return (
    <>
      <ModuleHeader />
      {read_permission()}
      <Backdrop loading={loadingBackdrop} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'analítica')
      .map((item) => item.acciones.nombre),
    rowsPerPage: state.rowsPerPage,
  };
};

const mapDispatchToProps = {
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexAnalytics);
