import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Button,
  Avatar,
  Link,
} from '@material-ui/core/';
import { FindInPageOutlined, DeleteForeverOutlined, LaunchOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  container__avatar: {
    '& img': {
      height: '100%',
      objectFit: 'contain',
    },
  },
}));

function Organizations(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user, organizationId, changePage } = props;
  const classes = useStyles();
  const history = useHistory();
  const [organizations, setOrganizations] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);

  const breadCrumps = () => {
    return t('breadCrumps:breadCrumps.settings-organizations', { returnObjects: true });
  };

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData();
      changeBreadcrump(breadCrumps());
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getOrganizations()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id = organizationId;
    }

    return params;
  };

  const getOrganizations = async () => {
    const params = getParams(0, rowsPerPage);

    const { data } = await axios.post(`/organization/getOrganizations`, params);

    if (isSuper) {
      setOrganizations(data.organizations?.rows);
      setFiltradas(data.organizations?.rows);
      setCountItems(data.organizations?.count);
    } else {
      const organizationsRelations = data.organizations?.rows
        .filter((item) => item.id === organizationId)[0]
        .organizacionesRelaciones.map((item) => item.organizacionesRelacionadas);

      const organizationsAll = data.organizations?.rows
        .filter((item) => item.id === organizationId)
        .concat(organizationsRelations);

      setOrganizations(organizationsAll);
      setFiltradas(organizationsAll);
      setCountItems(organizationsAll.length);
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/organization/getOrganizations`, params);

    const array = [
      ...organizations,
      ...data.organizations?.rows.filter((row) => !organizations.find((item) => item.id === row.id)),
    ];

    setOrganizations(array);
    setFiltradas(array);
    setLoading(false);
  };

  const getIssuedBadge = async (id) => {
    const { data } = await axios.post(`/others/getIssuedBadge`, { id });
    return data.issuedBadge;
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'aprove':
        modalAprove(id);
        break;
      case 'edit':
        history.push(`/organizations/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/organizations/create`);
        break;
      default:
        break;
    }
  };

  const modalAprove = (id) => {
    Swal.fire({
      text: t('alerts:alerts.aprove-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendAprove(id);
      }
    });
  };

  const modalDelete = async (id) => {
    const issuedBadge = await getIssuedBadge(id).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    if (issuedBadge !== null) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.organization-issue-error'),
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        text: t('alerts:alerts.delete-button-organization'),
        icon: 'warning',
        iconColor: colors.primary,
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    }
  };

  const sendAprove = async (id) => {
    const { data } = await axios.post(`/organization/acceptOrganization/${id}`).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.aproved'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/organization/${id}`);
      if (data.organizationId.success === false) {
        Swal.fire({
          text: 'No se puede eliminar la organizacion porque ya tiene usuarios asignados',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
          iconColor: colors.primary,
        });
      } else {
        setOrganizations(organizations.filter((item) => item.id !== id));
        setFiltradas(organizations.filter((item) => item.id !== id));
        Swal.fire({
          text: t('alerts:alerts.deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.deleted-error'),
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
        iconColor: colors.primary,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const aprove_permission = (id) => {
    if (isSuper) {
      return (
        <Tooltip title={t('tables:table.tooltip.aprove')}>
          <IconButton aria-label="publish" onClick={(e) => handleClick(e, id, 'aprove')} className={classes.iconButton}>
            <LaunchOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const organizationsColumns = () => {
    return t('tables:table.columns-organizations', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName="organizations"
        items={organizations}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={getOrganizations}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        userGroupId={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios}
        setLoading={setLoading}
      />
      <Table
        columns={organizationsColumns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={organizationsColumns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.identificacion}</TableCell>
                    <TableCell align="center">
                      <Avatar
                        alt="Remy Sharp"
                        variant="square"
                        src={row?.linkPhoto}
                        className={classes.container__avatar}
                      />
                    </TableCell>
                    <TableCell align="center">{row.nombre}</TableCell>
                    <TableCell align="center">{row.telefono}</TableCell>
                    <TableCell align="center">{row.direccion}</TableCell>
                    <TableCell align="center">{row.sitio_web}</TableCell>
                    <TableCell align="center">
                      {row.estado ? t('tables:table.state.approved') : t('tables:table.state.pending')}
                    </TableCell>
                    <TableCell align="center">{!row.estado ? aprove_permission(encrypt(row.id)) : ''}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Ver DashBoard">
                        <Link href={`/organization/${row.url_perfil}`} target="_blank" rel="noreferrer">
                          <IconButton>
                            <FindInPageOutlined />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{edit_permission(row.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableCell align="center" colSpan="9">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'organizaciones')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
