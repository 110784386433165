import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton, makeStyles, Select } from '@material-ui/core';
import { SearchOutlined } from '@mui/icons-material';
import colors from '../../assets/styles/colors';
import { setBadges, setCertificates, setCarnets, setSignedDocuments, setActive } from '../../actions';
import { useTranslation } from 'react-i18next';
import capitalize from '../../utils/capitalize';

function NavBar(props) {
  const {
    badges,
    certificates,
    carnets,
    signedDocuments,
    handleSearch,
    setBadges,
    setCertificates,
    setCarnets,
    setSignedDocuments,
    orderBy = true,
    setView,
    view,
    organization = false,
    user,
    active,
    setActive,
  } = props;
  const classes = useStyles({ user });
  //const [active, setActive] = useState(''); //pasarlo a redux
  //no era el view, era el active
  const [t] = useTranslation(['dashboard', 'search']);

  useEffect(() => {
    view === 0
      ? setActive('insignias')
      : view === 1
      ? setActive('certificados')
      : view === 2
      ? setActive('carnets')
      : setActive('documentos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleClick = (e) => {
    setActive(e.target.name);
    if (e.target.name === 'insignias') {
      setView(0);
    } else if (e.target.name === 'certificados') {
      setView(1);
    } else if (e.target.name === 'carnets') {
      setView(2);
    } else if (e.target.name === 'documentos') {
      setView(3);
    }
  };

  const handleSort = (e) => {
    if (e.target.value === 'date') {
      setBadges(
        badges.sort((a, b) => (a.insigniasEmitidas.fecha_emision > b.insigniasEmitidas.fecha_emision ? -1 : 1))
      );
      setCertificates(
        certificates.sort((a, b) =>
          a.certificadosEmitidos.fecha_emision > b.certificadosEmitidos.fecha_emision ? -1 : 1
        )
      );
      setCarnets(carnets.sort((a, b) => (a.carnetsEmitidos.fecha_emision > b.carnetsEmitidos.fecha_emision ? -1 : 1)));
      setSignedDocuments(
        signedDocuments.sort((a, b) =>
          a.documentosEmitidos.fecha_emision > b.documentosEmitidos.fecha_emision ? -1 : 1
        )
      );
    }

    if (e.target.value === 'popular') {
      setBadges(badges.sort((a, b) => (a.insigniasEmitidas.popular < b.insigniasEmitidas.popular ? -1 : 1)));
      setCertificates(
        certificates.sort((a, b) => (a.certificadosEmitidos.popular < b.certificadosEmitidos.popular ? -1 : 1))
      );
      setCarnets(carnets.sort((a, b) => (a.carnetsEmitidos.popular < b.carnetsEmitidos.popular ? -1 : 1)));
      setSignedDocuments(
        signedDocuments.sort((a, b) => (a.documentosEmitidos.popular > b.documentosEmitidos.popular ? -1 : 1))
      );
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.container__buttons}></div>
        <div className={classes.container__search}>
          <input name="search" type="text" placeholder={t('search:search.search')} onChange={handleSearch} />
          <IconButton aria-label="search" className={classes.container__search_icon}>
            <SearchOutlined />
          </IconButton>
        </div>
      </div>
      <div className={classes.container__subbar}>
        <div className={classes.container__buttons}>
          <button
            name="insignias"
            className={active === 'insignias' ? classes.container__buttons_active : classes.container__buttons_inactive}
            onClick={handleClick}
          >
            <b>{badges.length}</b> {capitalize(t('dashboard:badges'))}
          </button>
          <button
            name="certificados"
            className={
              active === 'certificados' ? classes.container__buttons_active : classes.container__buttons_inactive
            }
            onClick={handleClick}
          >
            <b>{certificates.length}</b> {capitalize(t('dashboard:certificates'))}
          </button>
          {organization !== true ? (
            <>
              <button
                name="carnets"
                className={
                  active === 'carnets' ? classes.container__buttons_active : classes.container__buttons_inactive
                }
                onClick={handleClick}
              >
                <b>{carnets.length}</b> {capitalize(t('dashboard:carnets'))}
              </button>
            </>
          ) : (
            ''
          )}
          {organization !== true && user ? (
            <>
              <button
                name="documentos"
                className={
                  active === 'documentos' ? classes.container__buttons_active : classes.container__buttons_inactive
                }
                onClick={handleClick}
              >
                <b>{signedDocuments.length}</b> {capitalize(t('dashboard:signedDocuments'))}
              </button>
            </>
          ) : (
            ''
          )}
        </div>
        {orderBy && (
          <div className={classes.container__subbar_orderby}>
            <p className={classes.container__subbar_orderby_label}>{t('dashboard:order-by')}</p>
            <Select
              native
              inputProps={{
                name: 'orderBy',
                id: 'orderBy',
              }}
              className={classes.container__subbar_orderby_select}
              onChange={handleSort}
            >
              <option value={'date'}>{t('dashboard:most-recent')}</option>
              <option value={'popular'}>{t('dashboard:most-popular')}</option>
            </Select>
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 2.5%',
    //borderBottom: '2px solid #bfbfbf',
    padding: '0 2% 8px 2%',
  },
  container__buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: ({ user }) => (user ? 600 : 400),
    '& button': {
      border: 'none',
      borderRadius: 8,
      padding: '2px 10px',
      [theme.breakpoints.up('sm')]: {
        padding: '2px 20px',
      },
    },
    '& button:focus': {
      outlineStyle: 'none',
    },
  },
  container__buttons_inactive: {
    backgroundColor: 'transparent',
  },
  container__buttons_active: {
    backgroundColor: theme.palette.tertiary.main,
  },
  container__search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100vw',
    '& input': {
      width: '100%',
      borderRadius: 8,
      border: `1px solid ${theme.palette.tertiary.main}`,
      paddingLeft: '14px',
      backgroundColor: theme.palette.tertiary.main,
    },
    '& input:focus': {
      outlineStyle: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30vw',
    },
  },
  container__search_icon: {
    position: 'absolute',
    color: colors.primary,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  container__subbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 2.5%',
    borderBottom: '2px solid #bfbfbf',
    padding: '0 2% 8px 2%',
    '& p': {
      marginBottom: 0,
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  container__subbar_orderby: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  container__subbar_orderby_label: {
    marginRight: 15,
  },
  container__subbar_orderby_select: {
    '&:focus': {
      outlineStyle: 'none',
    },
    '&:hover:before': {
      borderBottom: 'none !important',
    },
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    badges: state.badges || [],
    certificates: state.certificates || [],
    carnets: state.carnets || [],
    signedDocuments: state.signedDocuments || [],
    user: state.user,
    active: state.active,
  };
};

const mapDispatchToProps = {
  setBadges,
  setCarnets,
  setCertificates,
  setSignedDocuments,
  setActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
