import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import colors from '../assets/styles/colors';

function Button(props) {
  const { children, onClick, type = 'button', isInline = true } = props;
  const classes = useStyle({ isInline });

  return (
    <button className={classes.button} onClick={onClick} type={type}>
      {children}
    </button>
  );
}

const useStyle = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    width: '100%',
    minWidth: 'max-content',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    color: 'white',
    border: 'none',
    borderRadius: "50px",
    padding: '10px 50px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: (props) => (props.isInline ? 'max-content' : ''),
    },
  },
}));

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  onClick: () => {},
};

export default Button;
