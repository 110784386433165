import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import Appbar from '../components/Appbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../assets/styles/colors';
import axios from '../api';
import CertificateCard from '../components/CertificateCard';
import { encrypt, decrypt } from '../utils/crypt';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

function CertificateTemplate(props) {
  const { certificateId, setOpenDialog, openDialogCertificate, setRecommendation, styles } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [certificate, setTemplateCertificate] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [skills, setSkills] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [openRequirements, setOpenRequirements] = useState(false);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openRecomendation, setOpenRecomendation] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  let valida = '';
  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);

  useEffect(() => {
    loadData().catch(() => {
      props.history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getCertificate();
    setLoading(false);
  };

  const getCertificate = async () => {
    setLoading(true);
    const certificateIds = decrypt(certificateId);
    const { data } = await axios.get(`/certificateTemplate/getCertificateTemplate/${certificateIds}`);
    setTemplateCertificate(data.certificateTemplate);
    getTime(data.certificateTemplate?.certificateTemplate?.tiempo_vp);
    getLevel(data.certificateTemplate?.certificateTemplate?.nivel_vp);
    getCosts(data.certificateTemplate?.certificateTemplate?.costo_vp);
    setSkills(data.certificateTemplate?.skillsTemplate);
    setCriteria(data.certificateTemplate?.criterion);
    setRecommendations(data.certificateTemplate?.recommendations);
    setLoading(false);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelCertificate = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelCertificate[0]);
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeCertificate = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeCertificate[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costCertificate = data.costs.filter((data) => data.id === costo_vp);
    setCost(costCertificate[0]);
  };

  if (!cost && !level && !time) {
    valida = 'No hay atributos';
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Appbar closeButton={certificateId && true} handleClose={handleClose} />
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={'text-center'}>
          {loading ? (
            <Skeleton variant="rect" width={500} height={500} className={classes.skeleton} />
          ) : (
            <img src={certificate.linkPhoto} alt="certificate-img" className={classes.img} />
          )}
        </Grid>
      </Grid>
      <Container className={classes.container} maxWidth={'lg'}>
        <Grid container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={7} lg={7}>
              <Typography variant="h4" gutterBottom className={classes.textName}>
                {loading ? (
                  <Skeleton variant="h3" />
                ) : (
                  <>
                    {certificate.certificateTemplate?.nombre}
                    <img alt="img_verificado" src={styles.icon_verificado} className={classes.img_verificado} />
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              {loading ? (
                <Skeleton variant="rect" width={'100%'} height={100} className={classes.root} />
              ) : (
                <Typography className={classes.textParagraph} gutterBottom>
                  {certificate.certificateTemplate?.descripcion}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={1} lg={1}>
              <Typography className={classes.textBolderDate}>
                {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.created')}:`}
              </Typography>
            </Grid>

            <Grid item xs={12} md={9} lg={10}>
              <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={1}>
                      {loading ? (
                        <Skeleton variant="circle" className={classes.img_user} />
                      ) : (
                        <Avatar
                          variant="square"
                          aria-label="img_certificate"
                          src={certificate.linkPhotoOrganization}
                          className={classes.img_org}
                        />
                      )}
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.textNameOrganization}>
                        {loading ? (
                          <Skeleton variant="text" width={250} />
                        ) : (
                          certificate.certificateTemplate?.organizaciones?.nombre
                        )}
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={150} className={classes.linkOrganization} />
                      ) : (
                        <Link
                          className={classes.linkOrganization}
                          href={`/organization/${certificate.certificateTemplate?.organizaciones?.url_perfil}`}
                          target="_blank"
                        >
                          {t('certificateBadgeTemplate:details.more-information')}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={2} lg={1} className={'text-center'}>
              <Typography className={classes.textBolderDate}>
                {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.date-create')}:`}
              </Typography>
              <Typography className={classes.text}>
                {loading ? <Skeleton /> : moment(certificate.certificateTemplate?.fecha_creacion).format('DD-MM-YYYY')}
              </Typography>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              criteria.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openRequirements ? setOpenRequirements(false) : setOpenRequirements(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.requirements')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openRequirements ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openRequirements} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {criteria.map((data, index) =>
                    data.link === null || data.link === '' ? (
                      <Typography key={`criteria${index}`} className={classes.text}>
                        {data.descripcion_criterio}
                      </Typography>
                    ) : (
                      <a
                        href={data.link}
                        target="_blank"
                        rel="noreferrer"
                        key={`criteria${index}`}
                        className={classes.link}
                        gutterBottom
                      >
                        {data.descripcion_criterio}
                      </a>
                    )
                  )}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              valida.length === 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openAttributes ? setOpenAttributes(false) : setOpenAttributes(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.attributes')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openAttributes ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Grid item xs={12} md={12} lg={12}>
                <Collapse in={openAttributes} timeout="auto">
                  <Grid item lg={12} className={classes.content_descriptions}>
                    <Grid container justify="space-evenly" spacing={2} align="center">
                      {level !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {level?.valor_parametro}
                          </Typography>
                          <Typography>{level ? t('certificateBadgeTemplate:details.level') : ''}</Typography>
                        </Grid>
                      )}

                      {certificate?.certificateTemplate?.cantidad_tiempo !== null &&
                      time?.valor_parametro !== undefined ? (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {`${certificate?.certificateTemplate?.cantidad_tiempo} ${time.valor_parametro}`}
                          </Typography>
                          <Typography>{t('certificateBadgeTemplate:details.time')}</Typography>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {cost !== undefined && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Typography variant="h5" className={classes.textName}>
                            {cost?.valor_parametro}
                          </Typography>
                          <Typography>{cost ? t('certificateBadgeTemplate:details.cost') : ''}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              skills.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openSkill ? setOpenSkill(false) : setOpenSkill(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.skills')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openSkill ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openSkill} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {skills.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography className={'text-left'}>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              recommendations.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openRecomendation ? setOpenRecomendation(false) : setOpenRecomendation(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.recommendations')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openRecomendation ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            {recommendations.length > 0 && (
              <Grid container className={classes.descriptions}>
                <Grid item xs={12} md={12} lg={12}>
                  <Collapse in={openRecomendation} timeout="auto">
                    <Grid item xs={12} md={12} lg={12} className={classes.content_descriptions}>
                      <Grid container justify="flex-start" spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <div className={classes.certificate}>
                            {recommendations.map((recommendation) => (
                              <CertificateCard
                                publicCertificate={true}
                                templateCertificate={true}
                                certificateImage={recommendation?.linkPhotoCertificate}
                                certificateName={recommendation?.plantillasCertificadosRecomendadas?.nombre}
                                organizationName={
                                  recommendation?.plantillasCertificadosRecomendadas?.organizaciones?.nombre
                                }
                                issuedId={encrypt(recommendation?.plantillasCertificadosRecomendadas?.id)}
                                openDialogCertificate={openDialogCertificate}
                                open={setOpenDialog}
                                setRecommendation={setRecommendation}
                              />
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            )}

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              certificate.certificateTemplate?.url && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openMoreInfo ? setOpenMoreInfo(false) : setOpenMoreInfo(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certificateBadgeTemplate:details.additional')}
                      <FontAwesomeIcon icon={faShareSquare} size="xs" />
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openMoreInfo ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openMoreInfo} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  <Link
                    href={certificate.certificateTemplate?.url}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    {t('certificateBadgeTemplate:details.additional')}
                    <FontAwesomeIcon icon={faShareSquare} size="xs" />
                  </Link>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Grid container className={classes.footer}>
        <Grid item lg={12}>
          <span className={classes.textParagraph}>{t('certificateBadgeTemplate:details.right-reserved')}</span>
          <p className={classes.textName}>{styles.organizationName}</p>
          <span className={classes.textParagraph}>{moment().format('YYYY')}</span>
        </Grid>
      </Grid>
    </>
  );
}

const expandeMenuItemStyle = {
  color: '#ffffff',
  float: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '2em',
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  img: {
    maxWidth: '-webkit-fill-available',
  },
  textBolder: {
    marginTop: '1em',
    color: '#000000',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '2em',
  },
  textName: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textNameOrganization: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '4em',
    [theme.breakpoints.up('sm')]: {
      color: '#000000',
      fontWeight: 'bold',
      marginLeft: '1em',
    },
  },
  text: {
    color: '#000000',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#000000',
    margin: '1em 0 1em 0',
  },
  link: {
    color: colors.primary,
    marginLeft: '2em',
    textDecoration: 'underline',
  },
  linkOrganization: {
    color: colors.primary,
    marginLeft: '4em',
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      color: colors.primary,
      marginLeft: '1em',
      textDecoration: 'underline',
    },
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    marginTop: '1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  grid: {
    backgroundColor: colors.footer,
    marginBottom: '1em',
  },
  skeleton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    borderRadius: '10px',
    borderColor: '#ffffff',
    backgroundColor: 'white',
    paddingBottom: 2,
    marginTop: '1em',
  },
  img_user: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  img_org: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '& img': {
      height: 'min-content',
    },
  },
  cardContent: {
    padding: '0',
  },
  textBolderDate: {
    marginTop: '2em',
    color: '#000000',
    fontWeight: 'bold',
  },
  menuItem: {
    borderRadius: 10,
    padding: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  textMenuItem: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  expandeMenuItem: {
    color: '#ffffff',
    float: 'right',
  },
  descriptions: {
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    marginBottom: 10,
    textAlign: 'center',
  },
  content_descriptions: {
    margin: '1em',
  },
  footer: {
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  certificate: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(190px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(323px, 1fr))',
      margin: '30px 30px 0px 30px',
      '& div': {
        marginBottom: 20,
        justifySelf: 'center',
      },
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    styles: state.styles,
  };
};

export default connect(mapStateToProps, null)(CertificateTemplate);
