import colors from '../assets/styles/colors';

function defaultFields() {
  const dataQr = {
    fieldType: 48,
    exampleText: 'qr-certika-600x600.png',
    fieldName: 'qr',
    align: 'center',
    fontColor: { hex: '#000000' },
    fontSize: 0,
    fontFamily: {
      family: 'ABeeZee',
      files: '{italic: "http://fonts.gstatic.com/s/abeezee/v14/es…}',
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 50,
    y: 50,
    width: 150,
    height: 150,
    page: 1,
  };
  const dataImg = {
    fieldType: 48,
    exampleText: 'Certika-435x149.png',
    fieldName: 'logo',
    align: 'center',
    fontColor: { hex: '#000000' },
    fontSize: 0,
    fontFamily: {
      family: 'ABeeZee',
      files: '{italic: "http://fonts.gstatic.com/s/abeezee/v14/es…}',
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 200,
    y: 50,
    width: JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias' ? 270 : 200,
    height: JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias' ? 50 : 68,
    page: 1,
  };
  const dataCode = {
    fieldType: 41,
    fontSize: '14',
    fieldName: 'certificateCode',
    align: 'center',
    exampleText: 'Código',
    fontColor: { hex: '#000000' },
    fontFamily: {
      family: 'Source Code Pro',
      files: '{200: "http://fonts.gstatic.com/s/sourcecodepro/v14…}',
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 150,
    y: 230,
    width: 180,
    height: 18,
    page: 1,
  };

  const dataButtonVerify = {
    fieldType: 48,
    exampleText: 'button-verify.png',
    fieldName: 'buttonVerify',
    align: 'center',
    fontColor: { hex: '#ffffff' },
    rectColor: { hex: colors.primary },
    fontSize: 11,
    fontFamily: {
      family: 'ABeeZee',
      files: '{italic: "http://fonts.gstatic.com/s/abeezee/v14/es…}',
    },
    x: 60,
    y: 220,
    width: 80,
    height: 33,
    page: 1,
  };

  const dataSignature = {
    fieldType: 48,
    exampleText: '',
    fieldName: 'signature_1',
    align: 'center',
    fontColor: { hex: '#000000' },
    fontSize: 0,
    fontFamily: {
      family: 'ABeeZee',
      files: '{italic: "http://fonts.gstatic.com/s/abeezee/v14/es…}',
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 250,
    y: 300,
    width: 260,
    height: 100,
  };

  const dataModules = {
    fieldType: 48,
    exampleText: 'modules',
    fieldName: 'modules',
    align: 'left',
    fontColor: { hex: '#000000' },
    rectColor: { hex: colors.primary },
    fontSize: 11,
    fontFamily: {
      family: 'ABeeZee',
      files: '{italic: "http://fonts.gstatic.com/s/abeezee/v14/es…}',
    },
    x: 165,
    y: 160,
    width: 80,
    height: 33,
    page: 1,
  };

  return { dataQr, dataImg, dataCode, dataButtonVerify, dataSignature, dataModules };
}
export default defaultFields;
