import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  TextField,
} from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    //padding: '9px 40px',
  },
  iconMargin: {
    margin: theme.spacing(2),
  },
  añadir: {
    marginTop: '1em',
  },
  añadirButton: {
    color: '#0056b3',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  },
}));

function CreateModules(props) {
  const { changeBreadcrump } = props;
  const classes = useStyles();
  const history = useHistory();
  const [form, setForm] = useState({ nombre: '' });
  const [actions, setActions] = useState([{ index: 1, action: '' }]);
  const [last, setLast] = useState('');
  const [loading, setLoading] = useState(false);

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'forms']);

  useEffect(() => {
    loadLast().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    if (props.match.params.id) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
      changeBreadcrump(t('breadCrumps:breadCrumps.modules-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.modules-create', { returnObjects: true }));
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/module/${id}`);
    setForm(data.module);
    getActions(data.module);
  };

  const getActions = async (modulo) => {
    const { data } = await axios.post(`/action/getActions`, { id_modulos: modulo.id });
    setActions([
      ...data.actions
        .sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))
        .map((item) => ({
          index: item.id,
          action: item.nombre,
        })),
    ]);
  };

  const loadLast = async () => {
    const { data } = await axios.post(`/action/getActions`);
    setLast(data.actions[data.actions.length - 1].id);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };
  const handleCancel = () => {
    history.push('/modules');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleToggle();
    if (!props.match.params.id) {
      axios
        .post(`/module/`, { ...form, acciones: actions })
        .then((res) => {
          handleClose();
          history.push('/modules');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.created'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.created-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = decrypt(props.match.params.id);
      axios
        .put(`/module/${id}`, { ...form, acciones: actions })
        .then((res) => {
          handleClose();
          history.push('/modules');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  const addActions = async () => {
    setActions([...actions, { index: last + 1, action: '' }]);
    setLast(last + 1);
  };

  const handleInputAction = (event, index) => {
    actions.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setActions([...actions]);
  };

  const deleteAction = async (e, index) => {
    const { data } = await axios.post(`/permission/getPermissions`, { id_acciones: e }).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    if (!data.permissions?.length > 0) {
      if (actions.length !== 1) {
        const actionsDeleted = actions.filter((item) => item.index !== e);
        setActions(actionsDeleted);
      } else {
        setActions([{ index: last + 1, action: '' }]);
        setLast(last + 1);
      }
    } else {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('forms:module-error-delete'),
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <Typography align="center" component="h6" variant="h6">
            {t('forms:modules')}
          </Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl required variant="outlined" className="w-100">
                  <TextField
                    label={t('forms:module-name')}
                    required
                    variant="outlined"
                    value={form.nombre}
                    onChange={handleInput}
                    name="nombre"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} lg={12}>
              <Grid item xs={12} lg={12}>
                <Typography align="center" component="h6" variant="h6">
                  {t('forms:actions')}
                </Typography>
              </Grid>
              {actions.map((item) => (
                <>
                  <Grid item xs={10} key={item.id} lg={11}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label={t('forms:action')}
                        variant="outlined"
                        name="action"
                        value={item.action}
                        onChange={(e) => handleInputAction(e, item.index)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} key={item.id} lg={1}>
                    <Tooltip title={t('forms:action-delete')}>
                      <IconButton
                        aria-label="delete"
                        className={classes.iconMargin}
                        size="small"
                        onClick={(e) => deleteAction(item.index)}
                      >
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              ))}
            </Grid>
            <div className={`text-right ${classes.añadir}`}>
              <Button onClick={addActions} className={classes.añadirButton}>
                {t('forms:action-add')}
              </Button>
            </div>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(CreateModules);
