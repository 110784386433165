import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Typography, Divider, Select, FormControl, Grid, makeStyles } from '@material-ui/core';
import Template from './Index';
import BarChart from '../../../../components/BarChart';
import { changeBreadcrump } from '../../../../actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    marginTop: 20,
  },
}));

function Settings(props) {
  const { changeBreadcrump } = props;
  const classes = useStyles();

  useEffect(() => {
    changeBreadcrump([
      { name: 'Insignias' },
      { name: 'Plantillas', route: '/templates/published' },
      { name: 'Percepciones' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template Index={1} BadgeId={props.match.params.id}>
      <Card>
        <CardContent>
          <Typography color="textPrimary" gutterBottom>
            Mostrando Resultados por
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FormControl className={classes.formControl}>
                <Select
                  defaultValue={30}
                  inputProps={{
                    name: 'name',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={10}>Estados Unidos</option>
                  <option value={20}>Estados Unidos</option>
                  <option value={30}>Estados Unidos</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className={classes.formControl}>
                <Select
                  defaultValue={10}
                  inputProps={{
                    name: 'name',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={10}>Todos los estados</option>
                  <option value={20}>Estados Unidos</option>
                  <option value={30}>Estados Unidos</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Typography className={classes.margin} color="textPrimary" gutterBottom align="center">
            454566 Vacantes de trabajo
          </Typography>
          <Typography className={classes.margin} color="textPrimary" gutterBottom align="center">
            Titulos
          </Typography>
          <BarChart />
          <Divider />

          <Typography className={classes.margin} color="textPrimary" gutterBottom align="center">
            Localizaciones
          </Typography>
          <BarChart />
          <Divider />

          <Typography className={classes.margin} color="textPrimary" gutterBottom align="center">
            Empleadores
          </Typography>
          <BarChart />
          <Divider />
        </CardContent>
      </Card>
    </Template>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(Settings);
