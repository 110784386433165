import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Field from '../../components/Field';
import MenuCertificateItem from '../../components/MenuCertificateItem';
import textIcon from '../../assets/img/carbon_text-creation.svg';
import imgIcon from '../../assets/img/carbon_imagen.png';
import colors from '../../assets/styles/colors.js';
import { setCertificateFields } from '../../actions';
import { useTranslation } from 'react-i18next';
import defaultFields from '../../utils/defaultFields';

function Fields(props) {
  const { certificateFields, setCertificateFields, modifyField, openModal } = props;
  const classes = useStyle();
  const [expand, setExpand] = useState(true);
  const [expandImage, setExpandImage] = useState(true);
  const [expandOther, setExpandOther] = useState(true);
  const [defaultField, setDefaultField] = useState([
    {
      fieldType: 48,
      fieldName: 'qr',
      valida: certificateFields.find((item) => item.fieldName === 'qr') ? 1 : 0,
    },
    {
      fieldType: 48,
      fieldName: 'logo',
      valida: certificateFields.find((item) => item.fieldName === 'logo') ? 1 : 0,
    },
    {
      fieldType: 41,
      fieldName: 'certificateCode',
      valida: certificateFields.find((item) => item.fieldName === 'certificateCode') ? 1 : 0,
    },
    {
      fieldType: 48,
      fieldName: 'buttonVerify',
      valida: certificateFields.find((item) => item.fieldName === 'buttonVerify') ? 1 : 0,
    },
  ]);

  const [t] = useTranslation(['certificateBadgeTemplate']);

  const deleteField = (index) => {
    certificateFields.splice(index, 1);
    setCertificateFields([...certificateFields]);
  };

  const deleteFieldDefault = (fieldName, index) => {
    setCertificateFields([...certificateFields.filter((item) => item.fieldName !== fieldName)]);
    defaultField[index].valida = 0;
    setDefaultField(defaultField);
  };

  const addDefaultField = (index) => {
    const { dataQr, dataImg, dataCode, dataButtonVerify } = defaultFields();
    if (index === 0) {
      certificateFields.push(dataQr);
    } else if (index === 1) {
      certificateFields.push(dataImg);
    } else if (index === -1) {
      index = 2;
      certificateFields.push(dataCode);
    } else if (index === -3) {
      certificateFields.push(dataButtonVerify);
    }
    defaultField[index].valida = 1;
    setCertificateFields([...certificateFields]);
    setDefaultField(defaultField);
  };

  const certificateCodeExample = () => {
    const example = certificateFields.find((item) => item.fieldName === 'certificateCode')?.exampleText;

    if (example === undefined) {
      return 'Código:CK09aABbAz';
    } else {
      return `${example}${example === '' ? '' : ':'}CK09aABbAz`;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.container__header}>
          <h6>{t('certificateBadgeTemplate:certificate-design.title-design')}</h6>
          <button className={classes.container__header_button} onClick={openModal}>
            +
          </button>
        </div>
        <div className={classes.container__fields}>
          <MenuCertificateItem
            title={t('certificateBadgeTemplate:certificate-design.texts')}
            logo={textIcon}
            expand={expand}
            onClick={() => setExpand(!expand)}
          />
          {expand && (
            <>
              <Field
                key={`field${2}`}
                index={-1}
                exampleText={certificateCodeExample()}
                fieldName={defaultField[2].fieldName}
                addOrDelete={defaultField[2].valida === 0 ? 'add' : 'delete'}
                addDefaultField={addDefaultField}
                modifyField={modifyField}
                deleteField={() => deleteFieldDefault(defaultField[2].fieldName, 2)}
              />
              {
                // eslint-disable-next-line array-callback-return
                certificateFields?.map((field, index) => {
                  if (field.fieldType !== 48 && field.fieldName !== 'certificateCode') {
                    return (
                      <Field
                        key={`field${index}`}
                        index={index}
                        exampleText={field.exampleText}
                        fieldName={field.fieldName}
                        modifyField={modifyField}
                        deleteField={deleteField}
                        type={0}
                      />
                    );
                  }
                })
              }
            </>
          )}
          <MenuCertificateItem
            title={t('certificateBadgeTemplate:certificate-design.images')}
            logo={imgIcon}
            expand={expandImage}
            onClick={() => setExpandImage(!expandImage)}
          />
          {expandImage &&
            // eslint-disable-next-line array-callback-return
            defaultField?.map((field, index) => {
              if (field.fieldType === 48 && index !== 3) {
                return (
                  <Field
                    key={`field${index}`}
                    index={index}
                    exampleText={''}
                    fieldName={field.fieldName}
                    addOrDelete={field.valida === 0 ? 'add' : 'delete'}
                    addDefaultField={addDefaultField}
                    deleteField={() => deleteFieldDefault(field.fieldName, index)}
                  />
                );
              }
            })}
          {expandImage &&
            // eslint-disable-next-line array-callback-return
            certificateFields?.map((field, index) => {
              if (field.img) {
                return (
                  <Field
                    key={`field${index}`}
                    index={index}
                    fieldImage={field.img}
                    modifyField={modifyField}
                    deleteField={deleteField}
                  />
                );
              }
            })}
          <MenuCertificateItem
            title={t('certificateBadgeTemplate:certificate-design.buttons')}
            logo={textIcon}
            expand={expandOther}
            onClick={() => setExpandOther(!expandOther)}
          />
          {expandOther && (
            <Field
              key={`field${3}`}
              index={-3}
              exampleText={t('certificateBadgeTemplate:certificate-design.button-verify')}
              fieldName={defaultField[3].fieldName}
              modifyField={modifyField}
              addOrDelete={defaultField[3].valida === 0 ? 'add' : 'delete'}
              addDefaultField={addDefaultField}
              deleteField={() => deleteFieldDefault(defaultField[3].fieldName, 3)}
            />
          )}
        </div>
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    minHeight: '70vh',
  },
  container__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    '& h6': {
      marginLeft: 10,
      fontWeight: 'bold',
      color: '#888',
    },
  },
  container__header_button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    border: 'none',
    borderRadius: 20,
    color: 'white',
    backgroundColor: colors.primary,
    marginRight: 20,
    fontWeight: 'bold',
    fontSize: '1.5em',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  container__fields: {},
}));

const mapStateToProps = (state) => {
  return {
    certificateFields: state.certificateFields || [],
  };
};

const mapDispatchToProps = {
  setCertificateFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
