import React, { useState, useEffect } from 'react';
import { useHistory,/*  useLocation */ } from 'react-router-dom';
import { connect } from 'react-redux';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Dialog,
  Slide,
  Menu,
  MenuItem,
  DialogContent,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FindInPageOutlined,
  DeleteForeverOutlined,
  ImageSearchOutlined,
  DescriptionOutlined,
  SettingsBackupRestoreOutlined,
  PublishOutlined,
  DownloadOutlined,
  WhatsApp,
  HistoryOutlined,
  SendOutlined,
  AttachEmailOutlined,
  EmailOutlined,
  ShortcutOutlined,
} from '@mui/icons-material';
import axios from '../../../api';
import Table from '../../../components/Table';
import {
  sortData,
  getComparator,
  changePage,
  setFormDocuments,
  setDocumentsFields,
  setReturnRoute,
  setSearchValue,
  changeBreadcrump,
} from '../../../actions';
import { decrypt, encrypt } from '../../../utils/crypt';
import moment from 'moment';
import Swal from 'sweetalert2';
import colors from '../../../assets/styles/colors';
//import EditIcon from '../../../assets/static/icons/EditIcon';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';
import DocumentView from '../../Document';
import UploadFile from '../../../components/additionalFile/UploadFile';
import FilesList from '../../../components/additionalFile/Files';
import ViewPDF from '../../../components/PreviewPdfDocument';
import axiosFile from 'axios';
import { env } from '../../../config/environment';
import BackdropComponent from '../../../components/Backdrop';

function DocumentsCertify(props) {
  const {
    page,
    rowsPerPage,
    permission,
    user,
    organizationId,
    changePage,
    /*  setFormDocuments,
    setDocumentsFields,
    setReturnRoute, */
    setSearchValue,
    crump,
    changeBreadcrump,
  } = props;
  const [documentsCertify, setDocuemntsCertify] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [documentId, setDocumentId] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [dialogPreview, setDialogPreview] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewPreview, setViewPreview] = useState(null);
  const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const [idDocumentCertify, setIdDocumentCertify] = useState(null);
  const [valuesFields, setValuesFields] = useState([]);
  const [fieldsAdditionals, setFieldsAdditionals] = useState([]);
  //  const currentLocation = useLocation();

  const [formSend, setFormSend] = useState({
    email: '',
    whatsapp: '',
    message: '',
  });
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [typeSend, setTypeSend] = useState(0);
  const [dialogSender, setDialogSender] = useState(false);
  const [countries, setCountries] = useState([]);
  const [prefix, setPrefix] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs', 'certifyDocuments', 'tabs']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    if (crump.length > 3) {
      changeBreadcrump(crump.slice(0, 3));
    }
    const getCountries = async () => {
      const { data } = await axios.post(`/others/getCountries`);
      setCountries(data.countries);
    };
    getCountries();

    await getDocumentsCertify();
    setLoading(false);
    setSearchValue('');
  };

  const getDocumentsCertify = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/documentsCertify/getDocumentsCertifyByFiles`, {
      id_colecciones_documentos: id,
    });
    const typeView = window.location.href.includes('sign') ? 'sign' : 'normal';
    let dataFiltered = data.documentsCertify;

    if (typeView === 'sign') {
      dataFiltered = data.documentsCertify.filter(
        (doc) => doc.documentosFirmas.length > 0 || doc.thereSignField || doc.thereTrail
      );
    } else {
      dataFiltered = data.documentsCertify.filter(
        (doc) => doc.documentosFirmas.length === 0 && !doc.thereSignField && !doc.thereTrail
      );
    }
    setDocuemntsCertify(dataFiltered);
    setFiltradas(dataFiltered);
  };

  const modalDelete = (e, id, action) => {
    Swal.fire({
      text: `${t('alerts:alerts.revoke-undo-1')} ${action} ${t('alerts:alerts.revoke-undo-2-documents')}`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id, action);
      }
    });
  };

  const sendDelete = async (id, action) => {
    if (action === 'eliminar' || action === 'delete') {
      try {
        setLoading(true);
        const { data } = await axios.delete(`/documentsCertify/undo/document/${id}`);
        if (data) {
          documentsCertify.map((item) => item.id === id && getDocumentsCertify());
          setLoading(false);
          Swal.fire({
            text: t('alerts:alerts.deleted'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.deleted-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      try {
        setLoading(true);
        const { data } = await axios.delete(`/certificatesIssued/undo/certificate/${id}`);
        if (data) {
          documentsCertify.map((item) => item.id === id && getDocumentsCertify());
          setLoading(false);
          Swal.fire({
            text: t('alerts:alerts.undo-revoke'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.undo-revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const delete_permission = (id) => {
    const document = documentsCertify.find((data) => data.estado === 0 && data.id === id);
    if (permission.includes('Eliminar')) {
      if (!document) {
        return (
          <Tooltip title={t('tables:table.tooltip.delete')}>
            <IconButton
              aria-label="delete"
              onClick={(e) => modalDelete(e, id, t('tables:table.tooltip.delete').toLowerCase())}
            >
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t('tables:table.tooltip.archive')}>
            <IconButton aria-label="delete">
              <SettingsBackupRestoreOutlined
                onClick={(e) => modalDelete(e, id, t('tables:table.tooltip.archive').toLowerCase())}
              />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };

  const columnsFiles = () => {
    return t('tables:table.columns-documents-files', { returnObjects: true });
  };

  const handlePreview = (linkPdf) => {
    setViewPreview(linkPdf);
    setDialogPreview(true);
  };

  const handleShare = (type) => {
    setAnchorEl(undefined);
    setDialogSender(true);
    setTypeSend(type);
  };

  const handleClose = () => {
    setDialogPreview(false);
  };

  const downloadPdf = (link) => {
    let enlace = document.createElement('a');
    enlace.download = link;
    enlace.href = link;
    enlace.click();
  };

  const openDialogDocument = (id) => {
    setDocumentId(id);
    setOpenDialog(true);
  };

  const handleUpload = (idDocument) => {
    setIdDocumentCertify(idDocument);
    setOpenUploadFileDialog(true);
  };

  const handleFilesDialog = async (idDocument) => {
    setLoading(true);
    const { data } = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 1,
      id_documentos_emitidos: idDocument,
    });

    const additionals = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 2,
      id_documentos_emitidos: idDocument,
    });

    const trailFile = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 3,
      id_documentos_emitidos: idDocument,
    });

    if (
      data.documentFieldAdditionals?.length > 0 ||
      additionals?.data?.documentFieldAdditionals?.length > 0 ||
      trailFile?.data?.documentFieldAdditionals?.length > 0
    ) {
      setValuesFields([...data?.documentFieldAdditionals, ...trailFile.data?.documentFieldAdditionals]);
      setFieldsAdditionals(additionals?.data?.documentFieldAdditionals);
      setOpenFilesDialog(true);
    } else {
      Swal.fire({
        text: `${t('alerts:alerts.no-additional-document')}`,
        icon: 'warning',
        iconColor: colors.primary,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      });
    }
    setLoading(false);
  };

  /*   const handleClickEdit = async (e, id) => {
    setLoading(true);
    const { data } = await axios.get(`/documentTemplate/${id}`);
    if (data.documentTemplate) {
      const { documentTemplate, linkPhoto, linkPhotoHD, linkPhotoTemplate } = data.documentTemplate;
      setDocumentsFields(
        data.documentTemplate?.documentTemplate?.documentoCampos.map((field) => ({
          id: field.id,
          fieldTypeConfig: field.nombre === '' || field.nombre === 'certificateCode' ? 51 : 52,
          fieldType: field.tipo_campo_vp,
          exampleText: field.texto_ejemplo,
          idSignature: field.nombre.includes('signature') ? field.idSignature : null,
          fieldName: field.nombre,
          align: field.formato_letra ? field.formato_letra : 'center',
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width === 0 ? 'auto' : field.width,
          height: field.height === 0 ? field.tamano_letra : field.height,
          page: field.page,
          dataSign: field.nombre.includes('signature') ? field.dataSign : {},
        }))
      );
      delete data.documentTemplate?.documentTemplate?.documentoCampos;
      setFormDocuments({
        ...documentTemplate,
        img_prev: linkPhoto,
        imgHD: linkPhotoHD,
        img_template: linkPhotoTemplate,
        id_usuarios: documentTemplate.documentoEmitido[0].documentos[0].id_cuentas,
        estado: documentTemplate.estado,
        pages: linkPhoto.length,
        page: 1,
        fileName: `${documentTemplate.documentoEmitido[0].nombre}.pdf`,
        nombre_documento: documentTemplate.documentoEmitido[0].nombre,
        id_colecciones_documentos: documentTemplate.documentoEmitido[0].id_colecciones_documentos,
        nombre_archivo: documentTemplate.documentoEmitido[0].coleccionesDocumentos?.nombre,
        changeDocument: false,
        method_sign: documentTemplate.documentoEmitido[0].metodo_firma,
      });
      history.push(`/documents/sign/edit/${encrypt(id)}`);
      setReturnRoute(currentLocation.pathname);
    }
  }; */

  const handleInput = (event) => {
    setFormSend({
      ...formSend,
      [event.target.name]: event.target.value,
    });
  };

  const handleInMenuOpen = (event, document) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(document);
  };

  const loadFillDataCountries = () => {
    return countries.map((data) => {
      return (
        <MenuItem key={`countrie-${data.id}`} value={data.id}>
          {`${data.nombre}`.slice(0, data.nombre.length > 9 ? 4 : 3).toLocaleUpperCase()}
        </MenuItem>
      );
    });
  };

  const handleChangeCountry = async (event) => {
    if (event.target.value === '') {
      setPrefix('');
    } else {
      const prefixCountries = countries.find((country) => country.id === event.target.value);
      if (prefixCountries.prefijo_telefono) {
        setPrefix({
          ...prefix,
          country: event.target.value,
          prefijo_telefono: prefixCountries.prefijo_telefono,
        });
      }
    }
  };

  const sendDocument = async (e) => {
    e.preventDefault();
    setLoadingBackdrop(true);
    if (typeSend === 1) {
      const Document = new FormData();
      Document.append('id', currentRow.id);
      Document.append('email_receptor', formSend.email);
      Document.append('nombre', currentRow.nombre);
      Document.append('share', false);
      Document.append('sub', user.data_user.id);
      try {
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/share/sendDocumentsCertify`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: Document,
        }).then((response) => {
          if (response.status === 201) {
            setLoadingBackdrop(false);
            return Swal.fire({
              customClass: { container: classes.mySwal },
              text: 'El correo electrónico ha rebotado',
              icon: 'error',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              customClass: { container: classes.mySwal },
              text: t('alerts:alerts.send-successfully'),
              icon: 'success',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              onClose();
              setLoadingBackdrop(false);
            });
          }
        });
      } catch (error) {
        setLoadingBackdrop(false);
        return Swal.fire({
          customClass: { container: classes.mySwal },
          text: t('alerts:alerts.error-ocurred'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }

    if (typeSend === 2) {
      if (formSend?.whatsapp) {
        if (formSend?.whatsapp.length < 10) {
          setLoadingBackdrop(false);
          return Swal.fire({
            customClass: { container: classes.mySwal },
            text: t('alerts:alerts.whatsapp-send-invalid'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        const Document = new FormData();
        Document.append('id', currentRow.id);
        Document.append('whatsapp_receptor', `${prefix.prefijo_telefono}${formSend?.whatsapp}`);
        Document.append('nombre', currentRow.nombre);
        Document.append('share', false);
        Document.append('sub', user.data_user.id);
        try {
          await axiosFile({
            method: 'post',
            url: `${env.apiURL}/share/sendDocumentsCertifyWhatsapp`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: Document,
          }).then(() => {
            Swal.fire({
              customClass: { container: classes.mySwal },
              text: t('alerts:alerts.send-successfully'),
              icon: 'success',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              onClose();
              setLoadingBackdrop(false);
            });
          });
        } catch (error) {
          setLoadingBackdrop(false);
          return Swal.fire({
            customClass: { container: classes.mySwal },
            text: t('alerts:alerts.error-ocurred'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
  };

  const onClose = async () => {
    setDialogSender(false);
    setFormSend({
      email: '',
      whatsapp: '',
      message: '',
    });
    setPrefix({});
    await axiosFile.get(`${env.apiURL}/file/getPdfForWhatsapp/delete`);
  };

  const goToHistory = () => {
    const typeView = window.location.href.includes('sign');
    const tabs = t('tabs:tabs.tabs-details', { returnObjects: true });
    crump[2].route = `/documents${typeView ? '/sign' : ''}/files/${props.match.params.id}`;
    crump.push({ name: `${tabs[1].label}` });
    changeBreadcrump(crump);
    history.push(`history/${encrypt(currentRow.id)}`);
  };

  const sendReminder = async () => {
    Swal.fire({
      icon: 'warning',
      iconColor: colors.primary,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
        container: classes.mySwal,
      },
      buttonsStyling: false,
      text: t('alerts:alerts.send-reminder'),
      showCancelButton: true,
      confirmButtonText: t('alerts:alerts.yes-option'),
      cancelButtonText: 'No',
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          setLoadingBackdrop(true);
          const { data: infoSend } = await axios.post(`/documentsCertify/reminderSignatories/${currentRow.id}`);
          switch (infoSend) {
            case 0:
              Swal.fire({
                customClass: { container: classes.mySwal, confirmButton: 'custom-button-confirm' },
                buttonsStyling: false,
                text: t('alerts:alerts.send-successfull'),
                icon: 'success',
                iconColor: colors.primary,
                showConfirmButton: false,
                timer: 2000,
              });
              break;
            case 1:
              Swal.fire({
                customClass: { container: classes.mySwal, confirmButton: 'custom-button-confirm' },
                buttonsStyling: false,
                text: t('alerts:alerts.no-send-by-many-in-day'),
                icon: 'warning',
                iconColor: colors.primary,
                showConfirmButton: true,
              });
              break;
            case 2:
              Swal.fire({
                customClass: { container: classes.mySwal, confirmButton: 'custom-button-confirm' },
                buttonsStyling: false,
                text: t('alerts:alerts.no-send-by-many'),
                icon: 'warning',
                iconColor: colors.primary,
                showConfirmButton: true,
              });
              break;
            default:
              break;
          }
          setLoadingBackdrop(false);
        } catch (error) {
          setLoadingBackdrop(false);
          return Swal.fire({
            customClass: { container: classes.mySwal, confirmButton: 'custom-button-confirm' },
            buttonsStyling: false,
            text: t('alerts:alerts.error-ocurred'),
            iconColor: colors.primary,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="filesDocuments"
        items={documentsCertify}
        setItems={setFiltradas}
        groupId={decrypt(props.match.params.id)}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
      />
      <Table columns={columnsFiles()} rows={filtro} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columnsFiles()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.preview')}>
                        <IconButton aria-label="preview" onClick={() => handlePreview(row.linkPdf)}>
                          <ImageSearchOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      {row.nombre}
                      {row.estado === 2 ? (
                        <p className={classes.namePrivate}>{t('certifyDocuments:document.waiting-for-signatories')}</p>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.fecha_certificacion).format('DD/MM/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-additional-field')}>
                        <IconButton aria-label="view" onClick={() => handleFilesDialog(row.id)}>
                          <DescriptionOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-document')}>
                        <IconButton aria-label="detalles" onClick={(e) => openDialogDocument(encrypt(row.id))}>
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.download')}>
                        <IconButton aria-label="export" onClick={() => downloadPdf(row.linkPdf)}>
                          <DownloadOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.upload-additional-field')}>
                        <IconButton aria-label="upload" onClick={() => handleUpload(row.id)}>
                          <PublishOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.send-copy')}>
                        <IconButton aria-label="preview" onClick={(e) => handleInMenuOpen(e, row)}>
                          <SendOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {/*  <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.edit')}>
                        <IconButton
                          disabled={row.estado !== 2}
                          onClick={(e) => handleClickEdit(e, row.id_plantillas_documentos)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="30">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog fullScreen open={dialogPreview} TransitionComponent={Transition}>
        <ViewPDF pdf={viewPreview} download={false} handleClose={handleClose} fullView={true} />
      </Dialog>

      <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
        <DocumentView documentId={documentId} setOpenDialog={setOpenDialog} />
      </Dialog>

      <UploadFile
        open={openUploadFileDialog}
        setOpen={setOpenUploadFileDialog}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? 1
            : organizationId
        }
        idDocumentCertify={idDocumentCertify}
        loadData={loadData}
      />

      <FilesList
        open={openFilesDialog}
        setOpen={setOpenFilesDialog}
        rows={valuesFields}
        loadData={loadData}
        fieldsAdditionals={fieldsAdditionals}
      />

      <BackdropComponent loading={loadingBackdrop} />
      {/* MENÚ SHARE */}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem disabled={currentRow?.estado !== 1} onClick={() => handleShare(1, currentRow)}>
          <IconButton>
            <AttachEmailOutlined />
          </IconButton>
          <spam>
            <p style={{ margin: 0 }}>{t('buttons:buttons.send-by-email')}</p>
            {currentRow?.estado !== 1 && (
              <p style={{ fontSize: '9px', margin: 0 }}>{t('certifyDocuments:document.waiting-for-signatories')}</p>
            )}
          </spam>
        </MenuItem>

        <MenuItem disabled={currentRow?.estado !== 1} onClick={() => handleShare(2, currentRow)}>
          <IconButton>
            <WhatsApp />
          </IconButton>
          <spam>
            <p style={{ margin: 0 }}>{t('buttons:buttons.send-by-wp')}</p>
            {currentRow?.estado !== 1 && (
              <p style={{ fontSize: '9px', margin: 0 }}>{t('certifyDocuments:document.waiting-for-signatories')}</p>
            )}
          </spam>
        </MenuItem>

        {/* REENVIAR CORREO DE FIRMAR */}
        {currentRow?.estado !== 1 && (
          <MenuItem onClick={() => sendReminder()}>
            <IconButton>
              <ShortcutOutlined />
            </IconButton>
            <spam>{t('buttons:buttons.remember')}</spam>
          </MenuItem>
        )}

        <MenuItem onClick={() => goToHistory()}>
          <IconButton>
            <HistoryOutlined />
          </IconButton>
          <spam>{t('buttons:buttons.view-history-send')}</spam>
        </MenuItem>
      </Menu>
      {/* DIALOG SHARE */}
      <Dialog open={dialogSender} onClose={() => onClose()} fullWidth>
        <Typography align="center" variant="h6" style={{ margin: '1rem' }}>
          {t('tables:table.tooltip.send-copy')}
        </Typography>
        <form type="submit" onSubmit={(e) => sendDocument(e)}>
          <DialogContent>
            <Grid container spacing={2} className="w-100">
              {typeSend === 1 && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className="w-100">
                      <TextField
                        required
                        variant="outlined"
                        name="email"
                        type="email"
                        placeholder="example@email.co"
                        value={formSend.email}
                        onChange={handleInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {typeSend === 2 && (
                <>
                  <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" className="w-100">
                      <InputLabel>{t('buttons:buttons.country-select')} </InputLabel>
                      <Select
                        required
                        id="pais"
                        name="id_paises"
                        label="País"
                        onChange={handleChangeCountry}
                        value={prefix?.country || ''}
                      >
                        <MenuItem value="">
                          <em>{t('buttons:buttons.country-select-option-default')}</em>
                        </MenuItem>
                        {loadFillDataCountries()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <FormControl variant="outlined" className="w-100">
                      <TextField
                        required
                        variant="outlined"
                        name="whatsapp"
                        type="number"
                        placeholder="3044579029"
                        value={formSend.whatsapp}
                        onChange={handleInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <WhatsApp />
                              {`(+${prefix.prefijo_telefono ? prefix.prefijo_telefono : ''})`}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {/*  <Grid item xs={12}>
                <FormControl variant="outlined" className="w-100">
                  <TextField
                    variant="outlined"
                    name="message"
                    value={formSend.message}
                    multiline
                    rows="5"
                    placeholder="Mensaje (opcional)"
                  />
                </FormControl>
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button color="primary" variant="contained" className={classes.buttonCancel} disableElevation type="submit">
              {t('buttons:buttons.send')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  namePrivate: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
  },
  buttonCancel: {
    padding: '7px 40px',
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'insignias emitidas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    crump: state.crump || [],
  };
};

const mapDispatchToProps = {
  changePage,
  setFormDocuments,
  setDocumentsFields,
  setReturnRoute,
  setSearchValue,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsCertify);
