import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Typography, Divider, Grid } from '@material-ui/core';
import Chart from '../../components/DonutChart';
import Sharing from '../../components/Sharing';
import colors from '../../assets/styles/colors';
import { changeBreadcrump } from '../../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '.5em',
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

function Analytics(props) {
  const { changeBreadcrump, filtro, setData, tipo } = props;
  const classes = useStyles();
  const [t] = useTranslation(['breadCrumps', 'analytics']);

  useEffect(() => {
    changeBreadcrump(t('breadCrumps:breadCrumps.analytics-statistics', { returnObjects: true }));
    // eslint-disable-next-line
  }, []);

  const totalInsignias = filtro.issued;
  let pendientes = 0;
  let porcenpendientes = 0;
  let revocadas = 0;
  let porcenrevocadas = 0;
  let aceptadas = 0;
  let porcenAceptadas = 0;
  let rechazadas = 0;
  let porcenRechazadas = 0;
  let rebotados = 0;
  let porcenRebotados = 0;

  // eslint-disable-next-line array-callback-return
  filtro.statsByState?.map((data) => {
    if (data?.estado === 0) {
      revocadas = data?.total;
      porcenrevocadas = data?.porcentaje;
    }
    if (data?.estado === 1) {
      pendientes = data?.total;
      porcenpendientes = data?.porcentaje;
    }
    if (data?.estado === 2) {
      aceptadas = data?.total;
      porcenAceptadas = data?.porcentaje;
    }
    if (data?.estado === 3) {
      rechazadas = data?.total;
      porcenRechazadas = data?.porcentaje;
    }
    if (data?.estado === 4) {
      rebotados = data?.total;
      porcenRebotados = data?.porcentaje;
    }
  });

  const cuentasCreadas = filtro.accounts?.total;
  const porcenCuentas = filtro.accounts?.porcentaje;
  let totalCompartidas = filtro.shared;
  let facebook = 0;
  let linkedIn = 0;
  let twitter = 0;
  let whatsapp = 0;
  let telegram = 0;
  let porcenFacebook = 0;
  let porcenLinkedIn = 0;
  let porcenTwitter = 0;
  let porcenWhatsapp = 0;
  let porcenTelegram = 0;

  // eslint-disable-next-line array-callback-return
  filtro.sharedBySocial?.map((data) => {
    if (data?.id_redes_sociales === 2) {
      whatsapp = data?.total;
      porcenWhatsapp = data?.porcentaje;
    }
    if (data?.id_redes_sociales === 3) {
      linkedIn = data?.total;
      porcenLinkedIn = data?.porcentaje;
    }
    if (data?.id_redes_sociales === 4) {
      facebook = data?.total;
      porcenFacebook = data?.porcentaje;
    }
    if (data?.id_redes_sociales === 5) {
      twitter = data?.total;
      porcenTwitter = data?.porcentaje;
    }
    if (data?.id_redes_sociales === 6) {
      telegram = data?.total;
      porcenTelegram = data?.porcentaje;
    }
  });

  const redes = {
    facebook: { cant: facebook, porcen: porcenFacebook },
    linkedin: { cant: linkedIn, porcen: porcenLinkedIn },
    twitter: { cant: twitter, porcen: porcenTwitter },
    whatsapp: { cant: whatsapp, porcen: porcenWhatsapp },
    telegram: { cant: telegram, porcen: porcenTelegram },
  };

  const dataExport = [
    totalInsignias, //0
    pendientes, //1
    porcenpendientes, //2
    revocadas, //3
    porcenrevocadas, //4
    aceptadas, //5
    porcenAceptadas, //6
    rechazadas, //7
    porcenRechazadas, //8
    cuentasCreadas, //9
    porcenCuentas, //10
    totalCompartidas, //11
    facebook, //12
    linkedIn, //13
    twitter, //14
    whatsapp, //15
    telegram, //16
    porcenFacebook, //17
    porcenLinkedIn, //18
    porcenTwitter, //19
    porcenWhatsapp, //20
    porcenTelegram, //21
    tipo, //22
    rebotados, //23
    porcenRebotados, //24
  ];
  setData(dataExport);

  return (
    <>
      {tipo !== 3 ? (
        <Chart series={[aceptadas, pendientes, rechazadas, revocadas]} tipo={tipo} />
      ) : (
        <Chart
          series={[filtro.badges.total, filtro.certificates.total, filtro.carnets.total, filtro.documents.total]}
          tipo={tipo}
        />
      )}

      <Divider />

      <Grid container spacing={1} justify="space-evenly" className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Typography component="h4" variant="h4" align="center">
            {tipo !== 3 ? totalInsignias : filtro.issuedTotal}
          </Typography>
          <Typography align="center" gutterBottom>
            {tipo === 0
              ? totalInsignias !== 1
                ? t('analytics:analytics-statistics.badges-issued')
                : t('analytics:analytics-statistics.badge-issued')
              : tipo === 1
              ? totalInsignias !== 1
                ? t('analytics:analytics-statistics.certificates-issued')
                : t('analytics:analytics-statistics.certificate-issued')
              : tipo === 2
              ? totalInsignias !== 1
                ? t('analytics:analytics-statistics.carnets-issued')
                : t('analytics:analytics-statistics.carnet-issued')
              : 'Emisiones'}
          </Typography>
        </Grid>
        {tipo !== 3 ? (
          <>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {aceptadas}
              </Typography>
              <Typography align="center" gutterBottom>
                {tipo === 0
                  ? t('analytics:analytics-statistics.badges-accepted')
                  : t('analytics:analytics-statistics.certificates-accepted')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {porcenAceptadas}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.badge-certificate-accepted-porcen')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {pendientes}
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.pending')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {porcenpendientes}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.pending')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {rechazadas}
              </Typography>
              <Typography align="center" gutterBottom>
                {tipo === 0
                  ? t('analytics:analytics-statistics.badges-rejected')
                  : t('analytics:analytics-statistics.certificates-rejected')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {porcenRechazadas}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.badge-certificate-jected-porcen')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {revocadas}
              </Typography>
              <Typography align="center" gutterBottom>
                {tipo === 0
                  ? t('analytics:analytics-statistics.badges-revoked')
                  : t('analytics:analytics-statistics.certificates-revoked')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {porcenrevocadas}%
              </Typography>
              <Typography align="center" gutterBottom>
                {tipo === 0
                  ? t('analytics:analytics-statistics.badges-revoked')
                  : t('analytics:analytics-statistics.certificates-revoked')}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Divider />
            <Grid item xs={12} lg={12}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.restIssueds}
              </Typography>
              <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.issues-available')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.badges.total}
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.badges-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.badges.porcentaje}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.percent-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.certificates.total}
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.certificates-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.certificates.porcentaje}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.percent-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.carnets.total}
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.carnets-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.carnets.porcentaje}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.percent-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.documents.total}
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.carnets-issued')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {filtro.documents.porcentaje}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.percent-issued')}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {tipo !== 3 ? (
        <>
          <Divider />

          <Grid container spacing={1} justify="space-evenly" className={classes.root}>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {cuentasCreadas}
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.account')}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography component="h4" variant="h4" align="center">
                {porcenCuentas}%
              </Typography>
              <Typography align="center" gutterBottom>
                {t('analytics:analytics-statistics.account-porcen')}
              </Typography>
            </Grid>
          </Grid>
          <Divider />

          <Sharing socialNetworks={redes} totalCompartidas={totalCompartidas} />
        </>
      ) : (
        ''
      )}
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(Analytics);
