import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemText,
  Checkbox,
  List,
  Divider,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  check: {
    padding: '5px',
    color: colors.primary,
  },
}));

function CreatePermission(props) {
  const { changeBreadcrump } = props;
  const classes = useStyles();
  const history = useHistory();
  const [form, setForm] = useState({
    id_grupos: '',
    id_acciones: [],
    id_modulos: '',
  });

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [actions, setActions] = useState([]);
  const [modules, setModules] = useState([]);
  const [checked, setChecked] = useState([]);

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'forms']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    getGroups();
    getModules();
    if (props.match.params.id) {
      getPermise();
    }
    changeBreadcrump(t('breadCrumps:breadCrumps.permissions-manage', { returnObjects: true }));
  };

  useEffect(() => {
    getActions(form.id_modulos).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    getPermissions(form).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, [form.id_modulos]);

  useEffect(() => {
    getPermissions(form).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, [form.id_grupos]);

  const getPermissions = async (id) => {
    const { data } = await axios.post(`/permission/getPermissions`);
    let check = [];

    data.permissions.permissions
      .filter((item) => item.gruposUsuarios.id === id.id_grupos)
      // eslint-disable-next-line
      .map((datos) => {
        if (datos.acciones.id_modulos === id.id_modulos) {
          check.push(datos.id_acciones);
        }
      });
    setChecked(check);
    setForm({
      ...form,
      id_acciones: check,
    });
  };

  const getPermise = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/permission/${id}`);
    setForm({ id_grupos: data.permission.id_grupos, id_modulos: data.permission.acciones.id_modulos });
  };

  const getGroups = async () => {
    const { data } = await axios.post(`/userGroup/getGroups`);
    setGroups(data.groups);
  };

  const getModules = async () => {
    const { data } = await axios.post(`/module/getModules`);
    setModules(data.modules.rows);
  };

  const getActions = async (id_modulos) => {
    const { data } = await axios.post(`/action/getActions`, { id_modulos });
    setActions(data.actions);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };
  const handleCancel = () => {
    history.push('/permissions');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleToggle();
    if (!form.id_acciones.length > 0) {
      handleClose();
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-actions'),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios
        .post(`/permission/`, form)
        .then((res) => {
          handleClose();
          history.push('/permissions');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.successful-definition'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.no-defined'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  const loadFillDataGrupo = () => {
    return groups.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {data.nombre}
          {data.id_organizaciones !== null ? ` - ${data.organizaciones.nombre}` : ''}
        </MenuItem>
      );
    });
  };

  const loadFillDataModulos = () => {
    return modules
      .sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))
      .map((data) => {
        return (
          <MenuItem key={data.id} value={data.id}>
            {data.nombre}
          </MenuItem>
        );
      });
  };

  const loadFillDataAccion = () => {
    return actions
      .sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))
      .map((data) => {
        return (
          <ListItem key={data.id} role={undefined} dense button onClick={handleToggleChecked(data.id)}>
            <ListItemText id="nombre" primary={data.nombre} />
            <Checkbox
              className={classes.check}
              color="default"
              edge="start"
              checked={checked.indexOf(data.id) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': data.nombre }}
            />
          </ListItem>
        );
      });
  };

  const handleToggleChecked = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setForm({
      ...form,
      id_acciones: newChecked,
    });
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <Typography align="center" component="h6" variant="h6">
            {t('forms:permissions-definition')}
          </Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl required variant="outlined" className="w-100">
                  <InputLabel id="gruposLabel">{t('forms:userGroup')}</InputLabel>
                  <Select
                    labelId="gruposLabel"
                    label={t('forms:userGroup')}
                    value={form.id_grupos}
                    onChange={handleInput}
                    name="id_grupos"
                  >
                    <MenuItem value="">
                      <em>{t('forms:selected-option')}</em>
                    </MenuItem>
                    {loadFillDataGrupo()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl required variant="outlined" className="w-100">
                  <InputLabel id="modulosLabel">{t('forms:modules')}</InputLabel>
                  <Select
                    labelId="modulosLabel"
                    label={t('forms:modules')}
                    value={form.id_modulos}
                    onChange={handleInput}
                    name="id_modulos"
                  >
                    <MenuItem value="">
                      <em>{t('forms:selected-option')}</em>
                    </MenuItem>
                    {loadFillDataModulos()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} lg={12}>
              <Grid item xs={12} lg={12}>
                <Typography align="center" component="h6" variant="h6">
                  {t('forms:actions')}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <List>{loadFillDataAccion()}</List>
              </Grid>
            </Grid>

            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(CreatePermission);
