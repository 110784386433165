import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Backdrop from '../components/Backdrop';
import api from '../api';

function ComponentTemp() {
  const history = useHistory();
  const loading = true;

  useEffect(() => {
    api
      .post(`user/createUrlPerfil`)
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        history.push('/500');
      });
  });

  return (
    <>
      <Backdrop loading={loading} />
    </>
  );
}

export default ComponentTemp;
