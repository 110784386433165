import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { FindInPageOutlined } from '@mui/icons-material';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import BadgeCard from '../../../components/BadgeCard';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import { encrypt, decrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

function History(props) {
  const { page, rowsPerPage, changeBreadcrump } = props;
  const classes = useStyles();
  const [history, setHistory] = useState({});
  const [templateBadge, setTemplateBadge] = useState({});
  const [action, setAction] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [valueParameters, setValueParameters] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation(['tables', 'breadCrumps', 'dialogs']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    await getHistory();
    await getValueParameters();
    changeBreadcrump(t('breadCrumps:breadCrumps.badge-recommendation-history', { returnObjects: true }));
    setLoading(false);
  };

  const getValueParameters = async () => {
    const { data } = await axios.post(`/parameterValueParameter/getParameterValueParameter`);
    setValueParameters(data.parametersValueParameters);
  };

  const getHistory = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/recommendation/getRecommendations`, { id_plantillas: id });
    setHistory(
      data.recommendations?.history.filter(
        (item) => item.campo_actual.split(',')[0] === `id_plantillas = ${id.toString()}`
      )
    );
  };

  const openDetails = async (template, action) => {
    const idTemplate = template.split(',')[1].split('=')[1];
    const { data } = await axios.get(`/templateHability/${parseInt(idTemplate)}`);
    setTemplateBadge(data.templateHability);
    if (action === '39') {
      setAction(t('dialogs:dialog.state-added'));
    } else if (action === '38') {
      setAction(t('dialogs:dialog.state-deleted'));
    }
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const loadValueParameter = (id) => {
    // eslint-disable-next-line
    return valueParameters.map((data) => {
      if (data.id === parseInt(id)) {
        return <TableCell align="center">{data.valor_parametro}</TableCell>;
      }
    });
  };

  const columns = () => {
    return t('tables:table.columns-history', { returnObjects: true });
  };

  return (
    <>
      <ModuleHeader />
      <Table columns={columns()} rows={history} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : history.length > 0 ? (
            <>
              {sortData(history, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{moment(row.fecha_accion).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center">{moment(row.fecha_accion).format('HH:mm:ss')}</TableCell>
                    <TableCell align="center">{`${row.usuarios?.primer_nombre} ${row.usuarios?.primer_apellido}`}</TableCell>
                    {loadValueParameter(row.accion)}
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.details')}>
                        <IconButton aria-label="detalles" onClick={() => openDetails(row.campo_actual, row.accion)}>
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <SkeletonTables columns={columns()} photo={0} />
          )}
        </TableBody>
      </Table>

      <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle align="center">{t('dialogs:dialog.details')}</DialogTitle>
        <DialogContent align="center">
          <Grid container xs={12} className={classes.root}>
            <Grid item xs={12}>
              <Alert severity="info">{action}</Alert>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.badges}>
                <BadgeCard
                  badgeImage={templateBadge.linkPhoto}
                  badgeName={templateBadge.templateBadge?.nombre}
                  badgePrivateName={templateBadge.templateBadge?.nombre_privado}
                  organizationName={templateBadge.templateBadge?.organizaciones?.nombre}
                  issuedId={encrypt(templateBadge.templateBadge?.id)}
                  publicBadge={false}
                  templateBadge={true}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '2.8px solid #cacaca',
    borderRadius: '10px',
    boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.2)',
    marginTop: '1rem',
  },
  badges: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
