import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import {
  SettingsBackupRestoreOutlined,
  DeleteForeverOutlined,
  CachedOutlined,
  FileDownloadOutlined,
  ImageSearchOutlined,
  CloseOutlined,
  MailOutline,
  SystemUpdateAltOutlined,
} from '@mui/icons-material';
import axios from '../../../api';
import Table from '../../../components/Table';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { isValid } from 'date-fns';
import { decrypt, encrypt } from '../../../utils/crypt';
import moment from 'moment';
import Swal from 'sweetalert2';
import colors from '../../../assets/styles/colors';
import exportToExcel from '../../../utils/exportToExcel';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';
import axiosFile from 'axios';
import { env } from '../../../config/environment';

function CertificatesIssued(props) {
  const { page, rowsPerPage, changeBreadcrump, permission, user, organizationId, changePage, searchValue } = props;
  const [certificatesIssued, setCertificatesIssued] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [dialogPreview, setDialogPreview] = useState(false);
  const [image, setImage] = useState(null);
  const [countItems, setCountItems] = useState();
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs']);

  const startColumns = t('tables:table.columns-certificate-issued.columns-start', { returnObjects: true });
  const finalColumns = t('tables:table.columns-certificate-issued.columns-end', { returnObjects: true });

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
      changeBreadcrump(breadCrumps());
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    return () => {
      changePage(0);
    };
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    if (!searchValue || searchValue === '') {
      await getCertificatesIssued();
    }
    setLoading(false);
  };

  const breadCrumps = () => {
    return t('breadCrumps:breadCrumps.groups-certificate-issued-certificates-issued', { returnObjects: true });
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getCertificatesIssued = async () => {
    const params = getParams(0, rowsPerPage);
    const id = decrypt(props.match.params.id);
    params.id_grupos_certificados_emitidos = id;

    const { data } = await axios.post(`/certificatesIssued/getCertificatesIssued`, params);
    if (data.issued.rows.length > 0) {
      const fields = [];
      data.issued.rows[0].certificadosCamposValores
        .sort((a, b) => (a.certificadoCampos?.nombre < b.certificadoCampos?.nombre ? -1 : 1))
        // eslint-disable-next-line array-callback-return
        .map((field) => {
          if (
            field.certificadoCampos?.tipo_campo_vp !== 48 &&
            field.certificadoCampos?.nombre !== 'certificateCode' &&
            field.certificadoCampos?.nombre !== 'vencimiento'
          ) {
            fields.push({
              id: `${field.certificadoCampos?.nombre}`,
              label: `${field.certificadoCampos?.nombre}`,
              align: 'center',
              orden: `${field.certificadoCampos?.nombre}`,
            });
          }
        });
      const fieldsConcat = startColumns.concat(fields).concat(finalColumns);
      setColumns(fieldsConcat);

      setCertificatesIssued(data?.issued?.rows);
      setFiltradas(data?.issued?.rows);
      setCountItems(data?.issued?.count);
    }

    if (data.issued.rows.length === 0 || data.issued.rows[0].gruposInsigniasEmitidas.estado === 0) {
      setTimeout(reload, 15000);
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const id = decrypt(props.match.params.id);

    params.id_grupos_certificados_emitidos = id;

    const { data } = await axios.post(`/certificatesIssued/getCertificatesIssued`, params);

    const array = [
      ...certificatesIssued,
      ...data?.issued?.rows.filter((row) => !certificatesIssued.find((item) => item.id === row.id)),
    ];

    setCertificatesIssued(array);
    setFiltradas(array);
    setLoading(false);
  };

  const modalDelete = (e, id, action) => {
    Swal.fire({
      text: `${t('alerts:alerts.revoke-undo-1')} ${action} ${t('alerts:alerts.revoke-undo-2-certificate')}`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.send'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id, action);
      }
    });
  };

  const sendDelete = async (id, action) => {
    if (action === 'revocar') {
      try {
        const { value: text } = await Swal.fire({
          title: t('alerts:alerts.revoke-message'),
          input: 'textarea',
          inputPlaceholder: t('alerts:alerts.message-here'),
          inputAttributes: {
            'aria-label': t('alerts:alerts.message-here'),
          },
          showCancelButton: true,
          confirmButtonText: t('buttons:buttons.send'),
          cancelButtonText: t('buttons:buttons.cancel'),
          showLoaderOnConfirm: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
            cancelButton: 'custom-button-cancel',
          },
          inputValidator: (valor) => {
            if (!valor) {
              return t('alerts:alerts.write-message');
            }
          },
        });
        if (text) {
          setLoading(true);
          const { data } = await axios.delete(`/certificatesIssued/revoke/certificate/${id}`, {
            data: { mensaje: text },
          });
          if (data) {
            certificatesIssued.map((item) => item.id === id && getCertificatesIssued());
            setLoading(false);
            Swal.fire({
              text: t('alerts:alerts.revoke'),
              icon: 'success',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      try {
        setLoading(true);
        const { data } = await axios.delete(`/certificatesIssued/undo/certificate/${id}`);
        if (data) {
          certificatesIssued.map((item) => item.id === id && getCertificatesIssued());
          setLoading(false);
          Swal.fire({
            text: t('alerts:alerts.undo-revoke'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.undo-revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const delete_permission = (id) => {
    const certificate = certificatesIssued.find((data) => data.estado === 0 && data.id === id);
    if (permission.includes('Eliminar')) {
      if (!certificate) {
        return (
          <Tooltip title={t('tables:table.tooltip.revoke')}>
            <IconButton aria-label="delete" onClick={(e) => modalDelete(e, id, 'revocar')}>
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t('tables:table.tooltip.undo-revoke')}>
            <IconButton aria-label="delete">
              <SettingsBackupRestoreOutlined onClick={(e) => modalDelete(e, id, 'desrevocar')} />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };

  const reload = () => {
    setLoading(true);
    loadData();
  };

  const handleExportToExcel = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/certificatesIssued/getCertificatesIssued`, {
        id_grupos_certificados_emitidos: decrypt(props.match.params.id),
      });

      const rows = data?.issued?.rows?.map((data) => {
        const camposValores = data.certificadosCamposValores
          .sort((a, b) => (a.certificadoCampos?.nombre < b.certificadoCampos?.nombre ? -1 : 1))
          .filter(
            (item) =>
              item.certificadoCampos?.tipo_campo_vp !== 48 &&
              item.certificadoCampos?.nombre !== 'certificateCode' &&
              item.certificadoCampos?.nombre !== 'vencimiento'
          )
          .map((value) => {
            return { [value.certificadoCampos.nombre]: value.valor };
          });

        let camposValoresJson = {};
        camposValores.map((item) => (camposValoresJson = { ...camposValoresJson, ...item }));

        return {
          'Url Certificado': `${window.location.origin}/certificate/${encrypt(data.id)}`,
          Grupo: data.gruposInsigniasEmitidas?.nombre,
          Estado:
            data.estado === 1
              ? t('tables:table.state-issue-certificate.issued')
              : data.estado === 2
              ? t('tables:table.state-issue-certificate.accepted')
              : data.estado === 3
              ? t('tables:table.state-issue-certificate.rejected')
              : data.estado === 4
              ? t('tables:table.state-issue-certificate.bounced-mail')
              : t('tables:table.state-issue-certificate.revoked'),
          'Fecha emisión': moment(data.fecha_emision).format('DD/MM/YYYY HH:mm:ss'),
          Email: data.email_receptor,
          ...camposValoresJson,
          'Código certificado': data.codigo_certificado,
          'Nombre público certificado': data.plantillasCertificados?.nombre,
          'Nombre privado certificado': data.plantillasCertificados?.nombre_privado,
          'Fecha expiración':
            data.fecha_expiracion !== null
              ? isValid(new Date(data.fecha_expiracion))
                ? moment(data.fecha_expiracion).format('DD/MM/YYYY')
                : ''
              : '',
          Cuenta: isEsxistAccount(data.account),
          'No. correos emisión': data.correos_emision || 1,
          'No. correos aceptar certificado': data.correos_aceptar_certificado || 0,
          'No. correos crear cuenta': data.correos_crear_cuenta || 0,
          'No. correos compartir certificado': data.correos_compartir_certificado || 0,
          'No. correos revocar': data.correos_revocar || 0,
          'No. veces compartidas en Facebook': validationShare(data.estadisticasRedesSociales, 4),
          'No. veces compartidas en LinkedIn': validationShare(data.estadisticasRedesSociales, 3),
          'No. veces compartidas en Twitter': validationShare(data.estadisticasRedesSociales, 5),
          'No. veces compartidas en WhatsApp': validationShare(data.estadisticasRedesSociales, 2),
          'No. veces compartidas en Telegram': validationShare(data.estadisticasRedesSociales, 6),
        };
      });

      exportToExcel(rows, data?.issued?.rows[0]?.gruposInsigniasEmitidas?.nombre);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const isEsxistAccount = (accountBoolean) => {
    if (accountBoolean) {
      return 'Si';
    } else {
      return 'No';
    }
  };

  const validationShare = (estadisticaRedesSociales, redSocialId) => {
    // eslint-disable-next-line array-callback-return
    const certificateShare = estadisticaRedesSociales.filter((item) => item.id_redes_sociales === redSocialId);
    if (certificateShare.length > 0) {
      return certificateShare.length;
    } else {
      return 0;
    }
  };

  const handlePreview = (linkPhoto) => {
    setImage(linkPhoto);
    setDialogPreview(true);
  };

  const handleClose = () => {
    setDialogPreview(false);
  };

  const modalForwadEmail = (issueId) => {
    Swal.fire({
      title: t('alerts:alerts.certificate-no-accepted'),
      text: t('alerts:alerts.resend-issue-certificate'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmail(issueId);
      }
    });
  };

  const sendForwadEmail = async (issueId) => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/certificatesIssued/forwardEmail/issue/${issueId}`);
      if (data.issued) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.certificates-to-accept'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const downloadCertificates = async () => {
    setLoading(true);
    try {
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/certificatesIssued/generateCertificatesByGroupId`,
        data: { groupId: certificatesIssued[0].id_grupos_certificados_emitidos },
      });
      setLoading(false);
      return Swal.fire({
        icon: 'success',
        iconColor: colors.primary,
        text: 'Certificados descargados exitosamente',
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      }).then(async () => {
        setLoading(true);
        const { data } = await axiosFile({
          method: 'post',
          url: `${env.apiURL}/certificatesIssued/downloadCertificatesByGroupId`,
          data: { groupId: certificatesIssued[0].id_grupos_certificados_emitidos },
        });
        setLoading(false);
        const link = document.createElement('a');
        link.download = `${certificatesIssued[0].gruposInsigniasEmitidas.nombre}`;
        link.href = data;
        link.click();
      });
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <>
      <ModuleHeader>
        {certificatesIssued.length > 0 && (
          <>
            <Tooltip title={t('tables:table.tooltip.download-certificate')}>
              <Button className={classes.button} onClick={downloadCertificates}>
                <SystemUpdateAltOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={t('tables:table.tooltip.export')}>
              <Button className={classes.button} onClick={handleExportToExcel}>
                <FileDownloadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={t('tables:table.tooltip.upgrade')}>
              <Button className={classes.button} onClick={reload}>
                <CachedOutlined />
              </Button>
            </Tooltip>
          </>
        )}
      </ModuleHeader>

      <Search
        tableName="CertificatesIssued"
        items={certificatesIssued}
        setItems={setFiltradas}
        groupId={decrypt(props.match.params.id)}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        columns={{ startColumns, finalColumns, setColumns }}
      />

      <Table
        columns={columns}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-certificate')}>
                        <IconButton onClick={() => handlePreview(row.linkPhoto)}>
                          <ImageSearchOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.gruposInsigniasEmitidas.nombre}</TableCell>
                    <TableCell align="center">
                      <span className={row.estado === 4 && classes.row}>
                        {row.estado === 1
                          ? t('tables:table.state-issue-certificate.issued')
                          : row.estado === 2
                          ? t('tables:table.state-issue-certificate.accepted')
                          : row.estado === 3
                          ? t('tables:table.state-issue-certificate.rejected')
                          : row.estado === 4
                          ? t('tables:table.state-issue-certificate.bounced-mail')
                          : t('tables:table.state-issue-certificate.revoked')}
                      </span>
                    </TableCell>
                    <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">{row.email_receptor}</TableCell>
                    {row.certificadosCamposValores
                      ?.sort((a, b) => (a.certificadoCampos?.nombre < b.certificadoCampos?.nombre ? -1 : 1))
                      .map(
                        (value) =>
                          value.certificadoCampos?.tipo_campo_vp !== 48 &&
                          value.certificadoCampos?.nombre !== 'certificateCode' &&
                          value.certificadoCampos?.nombre !== 'vencimiento' && (
                            <TableCell align="center">{value.valor}</TableCell>
                          )
                      )}
                    <TableCell align="center">
                      {(row.estado === 2 || row.estado === 0) && isEsxistAccount(row.account) === 'Si' ? (
                        <Tooltip title={t('tables:table.tooltip.view-certificate')}>
                          <a href={`${window.location.origin}/certificate/${encrypt(row.id)}`} target="blank">
                            {row.codigo_certificado}
                          </a>
                        </Tooltip>
                      ) : (
                        row.codigo_certificado
                      )}
                    </TableCell>
                    <TableCell align="center">{row.plantillasCertificados?.nombre}</TableCell>
                    <TableCell align="center">{row.plantillasCertificados?.nombre_privado}</TableCell>
                    <TableCell align="center">
                      {row.fecha_expiracion !== null &&
                        isValid(new Date(row.fecha_expiracion)) &&
                        moment(row.fecha_expiracion).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">{isEsxistAccount(row.account)}</TableCell>
                    <TableCell align="center">{row.correos_emision || 1}</TableCell>
                    <TableCell align="center">{row.correos_aceptar_certificado || 0}</TableCell>
                    <TableCell align="center">{row.correos_crear_cuenta || 0}</TableCell>
                    <TableCell align="center">{row.correos_compartir_certificado || 0}</TableCell>
                    <TableCell align="center">{row.correos_revocar || 0}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 4)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 3)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 5)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 2)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 6)}</TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.sent-email')}>
                        <IconButton aria-label="emails" onClick={() => modalForwadEmail(row.id)}>
                          <MailOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="30">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={dialogPreview} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonDialog}`}
            onClick={handleClose}
          >
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img alt="img-prev" src={image} className={classes.img} />
        </DialogContent>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    margin: '5px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  row: {
    color: '#dc2626',
    fontWeight: 'bold',
  },
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'certificados emitidos')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesIssued);
