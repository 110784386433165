import React, { useState } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Button,
  DialogActions,
  Paper,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import { DropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { env } from '../config/environment';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import axiosFile from 'axios';
import Swal from 'sweetalert2';
import Backdrop from './Backdrop';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import colors from '../assets/styles/colors';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import axios from '../api';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UploadFile = (props) => {
  const { open = false, setOpen, organizationId, idCertificateTemplate, loadData, fieldAdditionals } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons']);

  console.log('fieldAdditionals -> ', fieldAdditionals);

  const closeDialog = () => {
    setFiles([]);
    setOpen(false);
  };

  const handleFile = async (e) => {
    const files = e;
    if (files) {
      setFiles(files);
    }
  };

  const downloadPdf = (link) => {
    let enlace = document.createElement('a');
    enlace.download = link;
    enlace.href = link;
    enlace.click();
  };

  const uploadFiles = async () => {
    if (files.length > 0) {
      setLoading(true);
      const documents = new FormData();
      files.map(async (file) => {
        documents.append('file', file);
      });
      documents.append('id_plantillas_certificados', idCertificateTemplate);
      documents.append('id_organizaciones', organizationId);
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/certificatesFieldsAdditionals/uploadSignature`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: documents,
      })
        .then(async (res) => {
          if (res.data) {
            setLoading(false);
            setOpen(false);
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.saved'),
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              loadData();
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.error-ocurred'),
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const deletePdf = (id) => {
    setOpen(false);
    Swal.fire({
      text: '¿Está seguro que desea eliminar este archivo?',
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id).catch((e) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.error-ocurred'),
            showConfirmButton: false,
            timer: 1500,
          });
        });
      } else {
        setOpen(true);
      }
    });
  };

  const sendDelete = async (id) => {
    setLoading(true);
    const { data } = await axios.delete(`/certificatesFieldsAdditionals/${id}`);
    if (data) {
      setLoading(false);
      setOpen(false);
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth={'md'}>
      <DialogTitle align="center" onClose={closeDialog} />
      <DialogContent>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} sm={12} className={classes.grid} align="center">
            <Paper>
              <Tabs
                className={classes.paper}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Subir firma" {...a11yProps(0)} />
                <Tab label="Firmas" {...a11yProps(1)} />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.grid} align="center">
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <DropzoneArea
                  filesLimit={1}
                  acceptedFiles={[
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                    'image/bmp',
                    'image/gif',
                    'image/tiff',
                    'image/heif',
                    'image/raw',
                    'image/psd',
                    'image/webp',
                    'image/svg+xml',
                    'image/x-icon',
                    'image/jp2',
                  ]}
                  dropzoneText={t('certificateBadgeTemplate:badges-certificate-form.documents-dropzoneArea')}
                  dropzoneClass={classes.dropzone}
                  dropzoneParagraphClass={classes.dropzoneParagraph}
                  showAlerts={false}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText={t('certificateBadgeTemplate:badges-certificate-form.documents-selected-files')}
                  onChange={handleFile}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <List aria-label="contacts">
                  {fieldAdditionals.length > 0 ? (
                    fieldAdditionals.map((item) => (
                      <ListItem>
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{item.titulo_campo}</ListItemText>
                        <ListItemIcon>
                          <Tooltip title="Descargar">
                            <IconButton aria-label="delete" onClick={() => downloadPdf(item.signatureFile)}>
                              <GetAppOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemIcon>
                          <Tooltip title="Eliminar">
                            <IconButton aria-label="delete" onClick={() => deletePdf(item.id)}>
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText>No hay firmas cargadas</ListItemText>
                    </ListItem>
                  )}
                </List>
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {files.length > 0 && (
          <Button disableElevation variant="contained" onClick={uploadFiles} color="primary">
            {t('buttons:buttons.save')}
          </Button>
        )}
      </DialogActions>
      <Backdrop loading={loading} />
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: '2.8px solid #cacaca',
    borderRadius: '10px',
    boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.2)',
    marginBottom: '1rem',
  },
  text: {
    marginTop: '1em',
  },
  container: {
    marginTop: '.5em',
    marginBottom: '.5em',
  },
  alert: {
    backgroundColor: theme.palette.primary.light,
  },
  divider: {
    marginTop: '-.5rem',
  },
  grid: {
    paddingRight: '.8em',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5em',
    },
  },
  button: {
    margin: '0.5em',
    padding: '.5em 1em',
    borderRadius: '10px',
  },
  container__input_root: {
    borderRadius: '10px',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.palette.primary.main,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  dropzoneParagraph: {
    color: 'gray',
    fontSize: '1.1em',
  },
  paper: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F5F5F5',
  },
}));

export default UploadFile;
