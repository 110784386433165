import React, { useState } from 'react';
import { makeStyles, Paper, Tabs, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import colors from '../assets/styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: '#ffffff',
  },
  tab: {
    color: '#000000',
  },
  indicator: {
    backgroundColor: colors.primary,
    color: colors.primary,
  },
  link: {
    padding: '0',
    textAlign: 'center',
  },
}));

function TabPanel(props) {
  const { Index, TabsHeader } = props;
  const classes = useStyles();
  const [value, setValue] = useState(Index);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="on"
          onChange={handleChange}
          classes={{
            indicator: classes.indicator,
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {TabsHeader.map((data) => {
            return (
              <Link id="linkMenu" className={`list-group-item list-group-item-action ${classes.link}`} to={data.route}>
                <Tab className={classes.tab} label={data.label} selected={true} />
              </Link>
            );
          })}
        </Tabs>
      </Paper>
    </div>
  );
}

export default TabPanel;
