import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Toolbar,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  TextField,
  InputLabel,
  Button,
} from '@material-ui/core';
import { Divider } from '@mui/material';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ButtonTextFieldIcon from '../../../assets/img/textField.png';
import ButtonPhotnoIcon from '@mui/icons-material/InsertPhotoOutlined';
import ButtonButtonsIcon from '@mui/icons-material/SmartButtonOutlined';
import ButtonSignatureIcon from '../../../assets/img/signature.png';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import webFont from 'webfontloader';
import Swal from 'sweetalert2';
import Appbar from '../../../components/Appbar';
import SketchPickerButton from '../../../components/SketchPickerButton';
import Documents from './Documents';
import Fields from './Fields';
import Dropdown from '../../../components/Dropdown';
import ButtonComponent from '../../../components/Button';
import onlyNumber from '../../../utils/validations/onlyNumber';
import onlyText from '../../../utils/validations/onlyText';
import hexToRGB from '../../../utils/hexToRGB';
import dataURItoBlob from '../../../utils/dataUriToBlob';
import colors from '../../../assets/styles/colors';
import { setDocumentsFields, setFormDocuments, setSaveDesign } from '../../../actions';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import SignatureDrawing from '../../../components/SignatureDrawing';
import SignatureText from '../../../components/SignatureText';
import SignatureFile from '../../../components/SignatureFile';
import Signatories from '../../../components/Signatories';
import { env } from '../../../config/environment';
import { decrypt } from '../../../utils/crypt';
import Backdrop from '../../../components/Backdrop';

function DesignDocuments(props) {
  const {
    documentsFields,
    setDocumentsFields,
    formDocuments,
    setFormDocuments,
    setSaveDesign,
    setOpenDesignDocuments,
    fromCertify,
    user,
    md5OrganizationRoute,
    md5UserRoute,
    handleCancelGeneral,
    handleSubmit,
    fromSign,
    documentSigned,
    downloadDocument,
    isEditDocuments,
    documentFinish,
  } = props;
  const classes = useStyle();
  const stageRef = useRef();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openSign, setOpenSign] = useState(false);
  const [openSignatories, setOpenSignatories] = useState(false);
  const [selectedId, selectShape] = useState(null);
  const [sendToEmail, setSendToEmail] = useState(false);
  const [sendToWhatsapp, setSendToWhatsapp] = useState(false);
  const [dataSend, setDataSend] = useState({ correo: '', whatsapp: '' });
  const [typeViewFields, setTypeViewFields] = useState(fromSign ? 3 : 0);
  const [countries, setCountries] = useState([]);
  const [prefix, setPrefix] = useState({});
  const [field, setField] = useState({
    fieldType: 41,
    fieldTypeConfig: 51,
    exampleText: '',
    fieldName: '',
    align: 'center',
    fontColor: { hex: '#000000' },
    fontSize: '18',
    fontFamily: {
      family: 'ABeeZee',
      files: {
        italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
        regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
      },
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 50,
    y: 50,
    width: 0,
    height: 0,
  });
  const indexFieldCode = documentsFields.findIndex(
    (item) => item.fieldName === 'certificateCode' && item.page === formDocuments?.page
  );
  const indexFieldButtonVerify = documentsFields.findIndex((item) => item.fieldName === 'buttonVerify');
  const isFieldCode = field.fieldName === 'certificateCode' ? true : false;
  const isFieldButton = field.fieldName === 'buttonVerify' ? true : false;
  const fontsFamilysTypeHand = [
    'Caveat',
    'Caveat Brush',
    'Cookie',
    'Architects Daughter',
    'Cedarville Cursive',
    'Dawning of a New Day',
    'Crafty Girls',
    'League Script',
  ];
  const [fontFamilies, setFontFamilies] = useState(
    [...documentsFields.map((field) => field.fontFamily?.family), 'ABeeZee', ...fontsFamilysTypeHand] || ['ABeeZee']
  );
  const [fontList, setFontList] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [fieldTypesConfig, setFieldTypesConfig] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [refField, setRefField] = useState(null);
  const [modifiedFieldIndex, setModifiedFieldIndex] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [progressBar, setProgressBar] = useState({});

  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons', 'dialogs', 'selects', 'tables', 'menu']);
  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  const thereSignatories = documentsFields.filter((item) => item.correo).length > 0;

  useEffect(() => {
    setLoading(true);

    const getCountries = async () => {
      const { data } = await api.post(`/others/getCountries`);
      setCountries(data.countries);
    };
    getCountries();

    if (isEditDocuments) {
      setSignatories(
        documentsFields
          ?.filter(
            (field) => field.dataSign?.correo && field.dataSign?.correo !== user.correo && field.correo !== user.correo
          )
          // eslint-disable-next-line array-callback-return
          ?.map((item) => {
            item.primer_nombre = item.dataSign ? item.dataSign.primer_nombre : item.primer_nombre;
            item.segundo_nombre = item.dataSign ? item.dataSign.segundo_nombre : item.segundo_nombre;
            item.primer_apellido = item.dataSign ? item.dataSign.primer_apellido : item.primer_apellido;
            item.segundo_apellido = item.dataSign ? item.dataSign.segundo_apellido : item.segundo_apellido;
            item.correo = item.dataSign ? item.dataSign.correo : item.correo;
            item.num_whatsapp = item.dataSign ? item.dataSign.num_whatsapp : item.num_whatsapp;
            item.id_paises = item.dataSign ? item.dataSign.id_paises : item.id_paises;
            item.ind_whatsapp = item.dataSign ? item.dataSign.ind_whatsapp : item.ind_whatsapp;
            item.url = item.exampleText;
            return item;
          })
      );
    }

    api.get(`/others/getFieldTypes`).then(({ data }) => {
      setFieldTypesConfig(
        data.fieldTypes
          .filter((item) => item.id === 51 || item.id === 52)
          .map((fieldType) => ({
            id: fieldType.id,
            value: languajeEn ? fieldType.valor_parametro_en : fieldType.valor_parametro,
          }))
      );
      setFieldTypes(
        data.fieldTypes
          .filter((item) => item.id !== 51 && item.id !== 52)
          .map((fieldType) => ({
            id: fieldType.id,
            value: languajeEn ? fieldType.valor_parametro_en : fieldType.valor_parametro,
          }))
      );
    });

    if (fontFamilies.length > 0) {
      webFont.load({
        google: {
          families: fontFamilies,
        },
        active: () => {
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }

    axios
      .get(
        'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAiqPGRASVurt5YfOdCCDOljv0nqATgCSc&fields=items.family,items.files&sort=alpha'
      )
      .then(({ data }) => {
        setFontList(data.items);
      });

    if (user) {
      api.post(`/signature/getSignatures`).then(({ data }) => {
        setSignatures([...data?.signatures]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (e) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setOpen(true);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      setOpen(true);
    }
  };

  const openModalSign = (e) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenSign(true);
          setValueTab(0);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      setValueTab(0);
      setOpenSign(true);
    }
  };

  const openModalSignatories = (e) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenSignatories(true);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else if (formDocuments.id_plantillas_documentos === 0 && formDocuments.checkSaveTemplate) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.signatories-no-template'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenSignatories(true);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
            checkSaveTemplate: false,
          });
        }
      });
    } else {
      setOpenSignatories(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setField({
      fieldType: 41,
      fieldTypeConfig: 51,
      exampleText: '',
      fieldName: '',
      align: 'center',
      fontColor: { hex: '#000000' },
      fontSize: '18',
      fontFamily: {
        family: 'ABeeZee',
        files: {
          italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
          regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
        },
      },
      fontStyle: {
        bold: 0,
        italic: 0,
        underline: 0,
        strikethrough: 0,
      },
      x: 50,
      y: 50,
      width: 0,
      height: 0,
    });
  };

  const closeModalSign = () => {
    setOpenSign(false);
  };

  const closeModalSignatories = () => {
    setOpenSignatories(false);
  };

  const changeFontStyle = (fontStyle) => {
    setField({ ...field, fontStyle });
  };

  const changeColor = (color) => {
    setField({ ...field, fontColor: fromCertify ? { hex: color.hex } : color });
  };

  const changeColorButton = (color) => {
    setField({ ...field, rectColor: fromCertify ? { hex: color.hex } : color });
  };

  const handleChange = (e) => {
    if (e.target.name === 'fontSize' && e.target.value > 100) return;
    if (e.target.name === 'fieldTypeConfig') {
      return setField({ ...field, [e.target.name]: e.target.value, fieldName: '', align: 'center', width: 'auto' });
    }
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const handleChangeCode = (e) => {
    if (onlyText(e)) {
      setField({ ...field, [e.target.name]: e.target.value });
    }
  };

  const onChangeFontFamily = (fontFamily) => {
    if (fontFamily?.family && !fontFamilies.includes(fontFamily.family)) {
      const fonts = [...fontFamilies, fontFamily.family];
      setFontFamilies(fonts);

      webFont.load({
        google: {
          families: [fontFamily.family],
        },
        active: () => {
          setField({ ...field, fontFamily: fontFamily });
        },
      });
    } else {
      setField({ ...field, fontFamily: fontFamily });
    }
  };

  const validateRequiredProps = () => {
    if (isFieldCode || isFieldButton) {
      return false;
    }
    if (!field.exampleText?.length > 0 || !field.fontSize > 0 || !field.fontFamily) {
      return true;
    }
    if (
      field.fieldTypeConfig === 52 &&
      (!field.fieldName?.length > 0 ||
        !field.exampleText?.length > 0 ||
        !field.fontSize > 0 ||
        !field.fontFamily ||
        !field.fieldType)
    ) {
      return true;
    }

    return false;
  };

  const saveField = () => {
    if (validateRequiredProps()) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.certificate-all-fields'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    if (isFieldCode) field.exampleText = field.exampleText.trim();

    const isNew = !(field.index >= 0);
    if (isNew) {
      documentsFields.push({
        ...field,
        page: formDocuments?.page,
        width: 'auto',
        height: field?.fontSize,
      });
      setRefField(null);
    } else {
      const index = field.index;
      delete field.index;
      documentsFields[index] = field;
      !isFieldButton && setModifiedFieldIndex(index);
    }

    selectShape(field.exampleText);
    !isFieldButton && setIsSelected(field.fieldTypeConfig === 51 ? false : true);

    setDocumentsFields([...documentsFields]);
    setField({
      fieldType: 41,
      fieldTypeConfig: 51,
      exampleText: '',
      fieldName: '',
      align: 'center',
      fontColor: { hex: '#000000' },
      fontSize: '18',
      fontFamily: {
        family: 'ABeeZee',
        files: {
          italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
          regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
        },
      },
      fontStyle: {
        bold: 0,
        italic: 0,
        underline: 0,
        strikethrough: 0,
      },
      x: 50,
      y: 50,
      width: 0,
      height: 0,
    });
    setOpen(false);
  };

  const saveSign = async (stageRef) => {
    const uri = stageRef.current.toDataURL();
    const blobData = dataURItoBlob(uri);
    const signature = new FormData();

    const routeSign = `${md5OrganizationRoute}/documentsTemplate/signatures/${md5UserRoute}`;
    signature.append('file', blobData);
    signature.append('route', routeSign);
    signature.append('userId', user?.data_user?.id);
    try {
      const { data: signId } = await axios({
        method: 'post',
        url: `${env.apiURL}/file/upload-certificate`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: signature,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressBar({ value: percentage });
        },
      });
      setSignatures([...signatures, { url: signId?.url, id: signId?.id }]);
      setOpenSign(false);
      setProgressBar({});
    } catch (error) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.try-again'),
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      });
      setOpenSign(false);
      setProgressBar({});
    }
  };

  const modifyField = (index) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (index === -1) {
            setField({
              ...documentsFields[indexFieldCode],
              width:
                documentsFields[indexFieldCode].fieldTypeConfig === 51 ? 'auto' : documentsFields[indexFieldCode].width,
              index: indexFieldCode,
            });
          } else if (index === -3) {
            setField({ ...documentsFields[indexFieldButtonVerify], index: indexFieldButtonVerify });
          } else {
            setField({
              ...documentsFields[index],
              width: documentsFields[index].fieldTypeConfig === 51 ? 'auto' : documentsFields[index].width,
              index,
            });
          }
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
          setOpen(true);
        }
      });
    } else {
      if (index === -1) {
        setField({
          ...documentsFields[indexFieldCode],
          width:
            documentsFields[indexFieldCode].fieldTypeConfig === 51 ? 'auto' : documentsFields[indexFieldCode].width,
          index: indexFieldCode,
        });
      } else if (index === -3) {
        setField({ ...documentsFields[indexFieldButtonVerify], index: indexFieldButtonVerify });
      } else {
        setField({
          ...documentsFields[index],
          width: documentsFields[index].fieldTypeConfig === 51 ? 'auto' : documentsFields[index].width,
          index,
        });
      }
      setOpen(true);
    }
  };

  const saveFields = async (e) => {
    if (fromSign && !documentsFields.find((item) => item.fieldName.includes('signature'))) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-fields'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');

    if (indexFieldCode !== undefined && indexFieldCode !== -1) {
      documentsFields[indexFieldCode].exampleText = `${documentsFields[indexFieldCode].exampleText}${
        documentsFields[indexFieldCode].exampleText === '' ? '' : ':'
      }CK09aABbAz`;
    }

    if (fromCertify) {
      handleSubmit(e, updatedFonts);
    } else {
      const uri = stageRef?.current?.toDataURL();
      setFormDocuments({ ...formDocuments, img_template: uri });
      setSaveDesign(true);
      setOpenDesignDocuments(false);
    }
  };

  const handleCancel = async () => {
    if (documentSigned) {
      const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
      Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

      if (indexFieldCode !== undefined && indexFieldCode !== -1) {
        documentsFields[indexFieldCode].exampleText = `${documentsFields[indexFieldCode].exampleText}${
          documentsFields[indexFieldCode].exampleText === '' ? '' : ':'
        }CK09aABbAz`;
      }
      handleCancelGeneral();
      setOpenDesignDocuments(false);
    } else if (fromCertify) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.cancel-progress'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
          Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

          if (indexFieldCode !== undefined && indexFieldCode !== -1) {
            documentsFields[indexFieldCode].exampleText = `${documentsFields[indexFieldCode].exampleText}${
              documentsFields[indexFieldCode].exampleText === '' ? '' : ':'
            }CK09aABbAz`;
          }
          handleCancelGeneral();
          setOpenDesignDocuments(false);
        }
      });
    } else {
      const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
      Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

      if (indexFieldCode !== undefined && indexFieldCode !== -1) {
        documentsFields[indexFieldCode].exampleText = `${documentsFields[indexFieldCode].exampleText}${
          documentsFields[indexFieldCode].exampleText === '' ? '' : ':'
        }CK09aABbAz`;
      }
      setOpenDesignDocuments(false);
    }
    await axios.get(`${env.apiURL}/file/getPdfForWhatsapp/delete`);
  };

  const closeDialogDesig = () => {
    if (indexFieldCode !== undefined && indexFieldCode !== -1) {
      documentsFields[indexFieldCode].exampleText = `${documentsFields[indexFieldCode].exampleText}${
        documentsFields[indexFieldCode].exampleText === '' ? '' : ':'
      }CK09aABbAz`;
    }
    setOpenDesignDocuments(false);
  };

  const validateBGColor = (hex) => {
    const rgb = hexToRGB(hex);
    return rgb.r > 230 && rgb.g > 230 && rgb.b > 230 ? classes.dialog__title : '';
  };

  const onSelectShape = (shapeId) => {
    if (shapeId === 'X') {
      return setDocumentsFields([
        ...documentsFields.filter(
          (item) => item.fieldName !== selectedId && item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'
        ),
      ]);
    }
    if (shapeId === '+') {
      const indexDelete = documentsFields.findIndex((item) => item.fieldName === 'deleteSign');
      const indexAdd = documentsFields.findIndex((item) => item.fieldName === 'addSign');
      const usedSignature = documentsFields.filter((item) => item.fieldName.includes('signature'));
      const indexCurrentField = documentsFields.findIndex(
        (item) => item.fieldName === selectedId && item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'
      );
      if (
        documentsFields[indexCurrentField].y + documentsFields[indexCurrentField].height + 20 >
        formDocuments?.naturalWidthAndHeight[0].height
      ) {
        documentsFields.push({
          ...documentsFields[indexCurrentField],
          y: documentsFields[indexCurrentField].y - documentsFields[indexCurrentField].height - 20,
          fieldName: `${documentsFields[indexCurrentField].fieldName.split('_')[0]}_${usedSignature.length + 1}`,
        });
        documentsFields[indexDelete].y =
          documentsFields[indexDelete].y - documentsFields[indexCurrentField].height - 20;
        documentsFields[indexAdd].y = documentsFields[indexAdd].y - documentsFields[indexCurrentField].height - 20;
      } else {
        documentsFields.push({
          ...documentsFields[indexCurrentField],
          y: documentsFields[indexCurrentField].y + documentsFields[indexCurrentField].height + 20,
          fieldName: `${documentsFields[indexCurrentField].fieldName.split('_')[0]}_${usedSignature.length + 1}`,
        });
        documentsFields[indexDelete].y =
          documentsFields[indexDelete].y + documentsFields[indexCurrentField].height + 20;
        documentsFields[indexAdd].y = documentsFields[indexAdd].y + documentsFields[indexCurrentField].height + 20;
      }
      selectShape(`${documentsFields[indexCurrentField].fieldName.split('_')[0]}_${usedSignature.length + 1}`);
      return setDocumentsFields([...documentsFields]);
    }
    const signField = documentsFields.find(
      (item) => item.fieldName === shapeId && item.fieldName.includes('signature')
    );
    const iconDelete = documentsFields.find((item) => item.fieldName === 'deleteSign');
    if (signField && !iconDelete) {
      documentsFields.push({
        ...signField,
        fieldType: 41,
        fieldTypeConfig: 52,
        fontSize: '18',
        fieldName: 'deleteSign',
        exampleText: 'X',
        x: signField.x + 20,
        y: signField.y - 20,
        width: 23,
        height: 32,
        fontStyle: { bold: 1 },
      });
      documentsFields.push({
        ...signField,
        fieldType: 41,
        fieldTypeConfig: 52,
        fontSize: '18',
        fieldName: 'addSign',
        exampleText: '+',
        x: signField.x + 5,
        y: signField.y - 20,
        width: 23,
        height: 32,
        fontStyle: { bold: 1 },
      });
      setDocumentsFields([...documentsFields]);
    }
    if (iconDelete) {
      setDocumentsFields([
        ...documentsFields.filter((item) => item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'),
      ]);
    }
    if (shapeId && formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          selectShape(shapeId);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      selectShape(shapeId);
    }
  };

  const handleChangePage = (event, value) => {
    setFormDocuments({
      ...formDocuments,
      page: value,
    });
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  const openSendToEmail = () => {
    setSendToWhatsapp(false);
    setSendToEmail(!sendToEmail);
  };

  const openSendToWhatsapp = () => {
    setSendToEmail(false);
    setSendToWhatsapp(!sendToWhatsapp);
  };

  const handleInput = (event) => {
    setDataSend({ ...dataSend, [event.target.name]: event.target.value });
  };

  const sendDocumentToEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    if (dataSend?.correo) {
      const Document = new FormData();
      Document.append(
        'id',
        documentFinish?.data?.reportes
          ? documentFinish?.data?.reportes[0].id
          : decrypt(window.location.href.split('/')[window.location.href.split('/').length - 1])
      );
      Document.append('email_receptor', dataSend?.correo);
      Document.append('nombre', formDocuments?.nombre_documento);
      Document.append('share', false);
      Document.append('sub', user.data_user.id);
      axios({
        method: 'post',
        url: `${env.apiURL}/share/sendDocumentsCertify`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: Document,
      })
        .then((response) => {
          if (response.status === 201) {
            Swal.fire({
              customClass: { container: classes.mySwal },
              title: 'El correo electrónico ha rebotado',
              icon: 'error',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          setDataSend({ ...dataSend, correo: '' });
          setLoading(false);
          setSendToEmail(false);
          return Swal.fire({
            customClass: { container: classes.mySwal },
            title: t('alerts:alerts.send-successfully'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        })
        .catch(() => {
          Swal.fire({
            customClass: { container: classes.mySwal },
            title: t('alerts:alerts.error-ocurred'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        });
    }
  };

  const sendDocumentToWhatsapp = (e) => {
    e.preventDefault();
    setLoading(true);
    if (dataSend?.whatsapp) {
      if (dataSend?.whatsapp.length < 10) {
        setLoading(false);
        return Swal.fire({
          customClass: { container: classes.mySwal },
          title: t('alerts:alerts.whatsapp-send-invalid'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      const Document = new FormData();
      Document.append(
        'id',
        documentFinish?.data?.reportes
          ? documentFinish?.data?.reportes[0].id
          : decrypt(window.location.href.split('/')[window.location.href.split('/').length - 1])
      );
      Document.append('whatsapp_receptor', `${prefix.prefijo_telefono}${dataSend?.whatsapp}`);
      Document.append('nombre', formDocuments?.nombre_documento);
      Document.append('share', false);
      Document.append('sub', user.data_user.id);
      axios({
        method: 'post',
        url: `${env.apiURL}/share/sendDocumentsCertifyWhatsapp`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: Document,
      })
        .then((res) => {
          setDataSend({ ...dataSend, whatsapp: '' });
          setLoading(false);
          setSendToWhatsapp(false);
          return Swal.fire({
            customClass: { container: classes.mySwal },
            title: t('alerts:alerts.send-successfully'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        })
        .catch(() => {
          Swal.fire({
            customClass: { container: classes.mySwal },
            title: t('alerts:alerts.error-ocurred'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        });
    }
  };

  const prehandleCancel = () => {
    Swal.fire({
      text: t('alerts:alerts.exit'),
      icon: 'question',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.exit'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        handleCancel();
      }
    });
  };

  const changeViewFields = async (type, isSign) => {
    if (isSign && !formDocuments?.ip_adress) {
      await getIp();
    }
    setTypeViewFields(type);
  };

  const getIp = async () => {
    try {
      const { data: ipData } = await axios({
        method: 'get',
        url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
      });
      setFormDocuments({ ...formDocuments, ip_adress: ipData.ip });
    } catch (error) {
      return Swal.fire({
        icon: 'warning',
        iconColor: colors.primary,
        text: t('alerts:alerts.warning-block'),
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      });
    }
  };

  const loadFillDataCountries = () => {
    return countries.map((data) => {
      return (
        <MenuItem key={`countrie-${data.id}`} value={data.id}>
          {`${data.nombre}`.slice(0, data.nombre.length > 9 ? 4 : 3).toLocaleUpperCase()}
        </MenuItem>
      );
    });
  };

  const handleChangeCountry = async (event) => {
    if (event.target.value === '') {
      setDataSend({ ...dataSend, [event.target.name]: event.target.value });
      setPrefix('');
    } else {
      setDataSend({ ...dataSend, [event.target.name]: event.target.value });

      const prefixCountries = countries.find((country) => country.id === event.target.value);
      if (prefixCountries.prefijo_telefono) {
        setPrefix({
          ...prefix,
          country: event.target.value,
          prefijo_telefono: prefixCountries.prefijo_telefono,
        });
      }
    }
  };

  return (
    <>
      <Appbar />
      <div style={{ backgroundColor: '#E5E5E5' }}>
        <Toolbar className={classes.container_info}>
          <Grid item xs={12} style={{ display: 'inline-flex', alignItems: 'center', height: '50px' }}>
            <Typography align="left">{`${formDocuments?.nombre_documento || formDocuments?.nombre}`}</Typography>
            {(formDocuments?.nameTemplate || formDocuments?.checkSaveTemplate) && (
              <>
                <Typography style={{ fontWeight: 'bold', margin: '0 3px 0 3px' }}>/</Typography>
                <Typography align="left" style={{ fontWeight: 'bold' }}>
                  {`${formDocuments?.nameTemplate || formDocuments?.nombre_plantilla}`}
                </Typography>
              </>
            )}
            <Typography style={{ fontWeight: 'bold', margin: '0 3px 0 3px' }}>{fromCertify && '/'}</Typography>
            <Typography align="left" style={{ fontWeight: 'bold' }}>
              {`${formDocuments?.nameArchive || formDocuments?.nombre_archivo || ''}`}
            </Typography>

            <Divider orientation="vertical" sx={{ height: '25px', margin: '0 1rem 0 1rem', backgroundColor: '#000' }} />
            <Tooltip title={t('buttons:tooltips.text')}>
              <img
                src={ButtonTextFieldIcon}
                style={typeViewFields === 0 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
                alt="textField"
                onClick={() => changeViewFields(0, true)}
              />
            </Tooltip>
            <Tooltip title={t('buttons:tooltips.image')}>
              <ButtonPhotnoIcon
                sx={typeViewFields === 1 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
                onClick={() => changeViewFields(1, true)}
              />
            </Tooltip>
            <Tooltip title={t('buttons:tooltips.buttons')}>
              <ButtonButtonsIcon
                sx={typeViewFields === 2 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
                onClick={() => changeViewFields(2, true)}
              />
            </Tooltip>
            {fromSign && (
              <Tooltip title={t('buttons:tooltips.sign')}>
                <img
                  src={ButtonSignatureIcon}
                  style={typeViewFields === 3 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
                  alt="Signatue"
                  onClick={() => changeViewFields(3, true)}
                />
              </Tooltip>
            )}
          </Grid>

          {fromSign && (
            <Tooltip title={t('buttons:buttons.add-signatorie')}>
              <Button
                color="primary"
                variant="outlined"
                disabled={documentSigned}
                onClick={openModalSignatories}
                disableElevation
              >
                <GroupAddOutlinedIcon fontSize="small" sx={{ marginRight: '.5em' }} /> Firmantes
              </Button>
            </Tooltip>
          )}
          <Tooltip
            title={
              fromSign
                ? isEditDocuments
                  ? t('buttons:buttons.edit')
                  : t('menu:sign')
                : fromCertify
                ? t('buttons:buttons.finish')
                : t('buttons:buttons.accept')
            }
          >
            <IconButton className={classes.buttonClose} disabled={documentSigned} onClick={saveFields}>
              <CheckOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('buttons:buttons.cancel')}>
            <IconButton className={classes.buttonClose} disabled={documentSigned} onClick={closeDialogDesig}>
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </div>
      <div className={classes.container}>
        <div className={classes.container__certificate}>
          <Documents
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            selectedId={selectedId}
            selectShape={onSelectShape}
            stageRef={stageRef}
            modifyField={modifyField}
            handleChangePage={handleChangePage}
            refField={refField}
            setRefField={setRefField}
            modifiedFieldIndex={modifiedFieldIndex}
            setModifiedFieldIndex={setModifiedFieldIndex}
            documentSigned={documentSigned}
            changeViewFields={changeViewFields}
          />
        </div>
        <div className={classes.container__fields}>
          {documentSigned ? (
            <>
              <div className={classes.container__button_signed}>
                <Grid container style={{ justifyContent: 'center', marginTop: '2rem' }}>
                  {!thereSignatories && (
                    <>
                      <Grid item xs={12} onClick={() => openSendToEmail()}>
                        <Card color="" style={{ margin: '10px' }}>
                          <Tooltip title={t('buttons:buttons.send-document-email')}>
                            <CardActionArea>
                              <CardContent style={{ textAlign: 'center' }}>
                                <EmailIcon disabled color="primary" />
                              </CardContent>
                            </CardActionArea>
                          </Tooltip>
                        </Card>
                      </Grid>
                      <form onSubmit={(e) => sendDocumentToEmail(e)}>
                        <Collapse in={sendToEmail}>
                          <Grid item xs={12} lg={12}>
                            <TextField
                              required
                              variant="outlined"
                              name="correo"
                              type="email"
                              placeholder="example@email.com"
                              value={dataSend.correo}
                              onChange={handleInput}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip title={t('buttons:buttons.send')} type="submit">
                                      <IconButton size="small">
                                        <SendIcon color="primary" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('buttons:buttons.close')}>
                                      <IconButton size="small" onClick={() => setSendToEmail(false)}>
                                        <CloseOutlinedIcon color="primary" />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Collapse>
                      </form>
                      <Grid item xs={12} onClick={() => openSendToWhatsapp()}>
                        <Card style={{ margin: '10px' }}>
                          <Tooltip title={t('buttons:buttons.send-document-whatsapp')}>
                            <CardActionArea>
                              <CardContent style={{ textAlign: 'center' }}>
                                <WhatsAppIcon color="primary" />
                              </CardContent>
                            </CardActionArea>
                          </Tooltip>
                        </Card>
                      </Grid>
                      <form onSubmit={(e) => sendDocumentToWhatsapp(e)}>
                        <Collapse in={sendToWhatsapp}>
                          <Grid container>
                            <Grid item xs={12} lg={3}>
                              <FormControl variant="outlined" className="w-100">
                                <InputLabel>País </InputLabel>
                                <Select
                                  required
                                  id="pais"
                                  name="id_paises"
                                  label="País"
                                  onChange={handleChangeCountry}
                                  value={prefix?.country || ''}
                                >
                                  <MenuItem value="">
                                    <em>Seleccione una opción</em>
                                  </MenuItem>
                                  {loadFillDataCountries()}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                              <TextField
                                required
                                variant="outlined"
                                name="whatsapp"
                                type="number"
                                placeholder="3044579029"
                                value={dataSend.whatsapp}
                                onChange={handleInput}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <WhatsAppIcon />
                                      {`(+${prefix.prefijo_telefono ? prefix.prefijo_telefono : ''})`}
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip title={t('buttons:buttons.send')}>
                                        <IconButton size="small" type="submit">
                                          <SendIcon color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={t('buttons:buttons.close')}>
                                        <IconButton size="small" onClick={() => setSendToWhatsapp(false)}>
                                          <CloseOutlinedIcon color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </form>
                      <Grid item xs={12} lg={12} onClick={() => downloadDocument()}>
                        <Card style={{ margin: '10px' }}>
                          <Tooltip title={t('buttons:buttons.download-documents')}>
                            <CardActionArea>
                              <CardContent style={{ textAlign: 'center' }}>
                                <GetAppIcon color="primary" />
                              </CardContent>
                            </CardActionArea>
                          </Tooltip>
                        </Card>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} lg={12} onClick={() => prehandleCancel()}>
                    <Card style={{ margin: '10px' }}>
                      <Tooltip title={t('buttons:buttons.exit')}>
                        <CardActionArea>
                          <CardContent style={{ textAlign: 'center' }}>
                            <Typography color="primary">
                              <ExitToAppIcon color="primary" />
                              {t('buttons:buttons.exit')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Tooltip>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            <>
              <Fields
                openModal={openModal}
                openModalSign={openModalSign}
                openModalSignatories={openModalSignatories}
                modifyField={modifyField}
                signatures={signatures}
                setSignatures={setSignatures}
                fromCertify={fromCertify}
                fromSign={fromSign}
                typeViewFields={typeViewFields}
              />
            </>
          )}
        </div>
      </div>
      <Dialog open={open} onClose={closeModal} fullWidth={true} maxWidth="sm">
        <DialogTitle
          align="center"
          className={!isFieldButton && validateBGColor(field.fontColor?.hex)}
          style={isFieldButton ? { backgroundColor: field?.rectColor?.hex } : {}}
        >
          <Typography
            style={{
              fontSize: `${field.fontSize || 18}px`,
              color: field.fontColor?.hex,
              fontFamily: field.fontFamily?.family,
              fontWeight: field.fontStyle?.bold ? 'bold' : '',
              fontStyle: field.fontStyle?.italic ? 'italic' : '',
              textDecoration: `${field.fontStyle?.underline ? 'underline' : ''} ${
                field.fontStyle?.strikethrough ? 'line-through' : ''
              }`,
              textAlign: field?.align,
            }}
          >
            {isFieldCode
              ? `${field.exampleText}${field.exampleText === '' ? '' : ':'}CK09aABbAz`
              : isFieldButton
              ? 'Verificar'
              : field.exampleText || t('certificateBadgeTemplate:certificate-design.example')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers className={isFieldButton && classes.fieldButtonDisabled}>
          <div className={classes.content_modal}>
            <p>{t('certificateBadgeTemplate:certificate-design.type-config-field')}*</p>
            <FormControl required variant="outlined" className="w-100" size="small">
              <Select
                name="fieldTypeConfig"
                value={field.fieldTypeConfig}
                onChange={handleChange}
                disabled={field.fieldName === 'certificateCode' || fromCertify ? true : false}
              >
                {fieldTypesConfig.map((fieldType, index) => (
                  <MenuItem key={`field-type-option${index}`} value={fieldType.id}>
                    <em>{fieldType.value}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {field.fieldTypeConfig === 52 && (
              <>
                <p>{t('certificateBadgeTemplate:certificate-design.name-field')}*</p>
                <input
                  type="text"
                  name="fieldName"
                  value={field.fieldName || ''}
                  onChange={handleChange}
                  disabled={field.fieldName === 'certificateCode' ? true : false}
                />
                <p>{t('certificateBadgeTemplate:certificate-design.type-field')}*</p>
                <FormControl required variant="outlined" className="w-100" size="small">
                  <Select
                    name="fieldType"
                    value={field.fieldType}
                    onChange={handleChange}
                    disabled={field.fieldName === 'certificateCode' ? true : false}
                  >
                    {fieldTypes.map((fieldType, index) => (
                      <MenuItem key={`field-type-option${index}`} value={fieldType.id}>
                        <em>{fieldType.value}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <p>{t('certificateBadgeTemplate:documents-design.field')}*</p>
            <OutlinedInput
              type="text"
              name="exampleText"
              value={field.exampleText || ''}
              style={{ borderRadius: '16px' }}
              onChange={isFieldCode ? handleChangeCode : handleChange}
              endAdornment={
                <InputAdornment position="end">
                  {isFieldCode ? `${field.exampleText === '' ? '' : ':'}CK09aABbAz` : ''}
                </InputAdornment>
              }
            />
            {field.fieldTypeConfig === 52 && (
              <>
                <p>{t('certificateBadgeTemplate:certificate-design.align-letter')}*</p>
                <div>
                  <Checkbox
                    icon={<FormatAlignCenterIcon />}
                    checkedIcon={<FormatAlignCenterIcon />}
                    name="align"
                    value="center"
                    checked={field.align === 'center'}
                    onChange={handleChange}
                  />
                  <Checkbox
                    icon={<FormatAlignLeftIcon />}
                    checkedIcon={<FormatAlignLeftIcon />}
                    name="align"
                    value="left"
                    checked={field?.align === 'left'}
                    onChange={handleChange}
                  />
                  <Checkbox
                    icon={<FormatAlignRightIcon />}
                    checkedIcon={<FormatAlignRightIcon />}
                    name="align"
                    value="right"
                    checked={field?.align === 'right'}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <p>{t('certificateBadgeTemplate:certificate-design.font')}*</p>
            <Dropdown
              name="fontFamily"
              value={field.fontFamily || null}
              placeholder="Selecciona un tipo de letra"
              options={fontList}
              getOptionLabel={(option) => option.family}
              getOptionSelected={(option) => option.family}
              onChange={(e, value) => onChangeFontFamily(value)}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.size-letter')}*</p>
            <input
              type="number"
              name="fontSize"
              value={field.fontSize || ''}
              onChange={handleChange}
              onKeyUp={onlyNumber}
              style={{ borderRadius: '16px', border: '1px solid #C4C4C4', paddingLeft: '1em' }}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.style-letter')}*</p>
            <div>
              <Checkbox
                icon={<FormatBoldIcon />}
                checkedIcon={<FormatBoldIcon />}
                name="bold"
                checked={field?.fontStyle?.bold}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, bold: e.target.checked })}
              />
              <Checkbox
                icon={<FormatItalicIcon />}
                checkedIcon={<FormatItalicIcon />}
                name="italic"
                checked={field?.fontStyle?.italic}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, italic: e.target.checked })}
              />
              <Checkbox
                icon={<FormatUnderlinedIcon />}
                checkedIcon={<FormatUnderlinedIcon />}
                name="underline"
                checked={field?.fontStyle?.underline}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, underline: e.target.checked })}
              />
              <Checkbox
                icon={<StrikethroughSIcon />}
                checkedIcon={<StrikethroughSIcon />}
                name="strikethrough"
                checked={field?.fontStyle?.strikethrough}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, strikethrough: e.target.checked })}
              />
            </div>
            <p>{t('certificateBadgeTemplate:certificate-design.colour')}*</p>
            <SketchPickerButton color={field.fontColor?.hex || '#000000'} changeColor={changeColor} />
          </div>
        </DialogContent>
        <DialogContent dividers className={!isFieldButton && classes.fieldButtonDisabled}>
          <div className={classes.content_modal}>
            <p>{t('certificateBadgeTemplate:certificate-design.colour-text')}*</p>
            <SketchPickerButton color={field.fontColor?.hex || '#000000'} changeColor={changeColor} />
            <p>{t('certificateBadgeTemplate:certificate-design.colour-background')}*</p>
            <SketchPickerButton color={field.rectColor?.hex || '#000000'} changeColor={changeColorButton} />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color="primary" variant="contained" onClick={saveField}>
            {t('buttons:buttons.save')}
          </ButtonComponent>
          <ButtonComponent color="primary" variant="contained" onClick={closeModal}>
            {t('buttons:buttons.close')}
          </ButtonComponent>
        </DialogActions>
      </Dialog>

      <Dialog open={openSign} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <Tabs
            value={valueTab}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.draw')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.write')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.upload')} />
          </Tabs>
          <Grid container>
            {valueTab === 0 && (
              <SignatureDrawing
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                emailUser={user.correo}
              />
            )}
            {valueTab === 1 && (
              <SignatureText
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                nameUser={`${user.data_user.primer_nombre} ${user.data_user.primer_apellido}`}
                emailUser={user.correo}
                fontsFamilys={fontsFamilysTypeHand}
              />
            )}
            {valueTab === 2 && (
              <SignatureFile
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                emailUser={user.correo}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={openSignatories} fullWidth={true} maxWidth="xl">
        <DialogContent>
          <Signatories
            closeModalSignatories={closeModalSignatories}
            emailCurrent={user?.correo}
            documentsFields={documentsFields}
            setDocumentsFields={setDocumentsFields}
            signatories={signatories}
            setSignatories={setSignatories}
            formDocuments={formDocuments}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loading} />
    </>
  );
}
const useStyle = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    backgroundColor: '#E5E5E5',
  },
  container__certificate: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
  container__fields: {
    backgroundColor: 'white',
  },
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  content_modal: {
    display: 'grid',
    gridTemplateColumns: '35% 65%',
    gridTemplateRows: '',
  },
  dialog__title: {
    backgroundColor: theme.palette.primary.main,
  },
  sweetModal: {
    zIndex: 1300,
  },
  mySwal: {
    zIndex: 3000,
  },
  fieldButtonDisabled: {
    display: 'none',
  },
  root_info: {
    height: 180,
  },
  container_info: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: '1em',
  },
  buttonMinimize: {
    display: 'flex',
    justifyContent: 'right',
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: '1em',
  },
  buttonClose: {
    margin: '0 .1em 0 .1em',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  container__button_signed: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button_signed: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '10px 40px',
    fontSize: '11px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

const useStyleMUI = {
  sizeZoom: {
    width: '50px',
    height: '24px',
    marginLeft: '5px',
    fontWeight: 'bold',
    input: {
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  iconsButtons: {
    width: '45px',
    height: '45px',
    padding: '10px',
    margin: '0 3px 0 3px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#E6E6E6' },
  },
  iconsButtonsActive: {
    width: '45px',
    height: '45px',
    padding: '10px',
    margin: '0 3px 0 3px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#E6E6E6',
  },
};

const mapStateToProps = (state) => {
  return {
    documentsFields: state.documentsFields || [],
    formDocuments: state.formDocuments || [],
  };
};

const mapDispatchToProps = {
  setDocumentsFields,
  setFormDocuments,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignDocuments);
