import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TablePagination,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { changePage, changeRowsPerPage } from '../actions';
import Skeleton from '@material-ui/lab/Skeleton';
/* Translations */
import { useTranslation } from 'react-i18next';

const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 'bold',
    fontSize: 16,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const BasicTable = (props) => {
  const {
    rows,
    columns,
    children,
    page,
    rowsPerPage,
    setDirection,
    setOrderBy,
    loading,
    tableSmall,
    changePagination,
  } = props;
  const classes = useStyles();

  /* translation */
  const [t] = useTranslation('tables');

  const handleChangePage = (event, newPage) => {
    props.changePage(newPage);

    if (changePagination !== undefined) {
      changePagination(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    props.changeRowsPerPage(rowsPerPage);
    props.changePage(0);
    if (changePagination !== undefined) {
      changePagination(0, rowsPerPage);
    }
  };

  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('name');

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && ordenDirection === 'asc';
    setValueToOrderBy(property);
    setOrdenDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
    setDirection(isAscending ? 'desc' : 'asc');
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table className={tableSmall ? '' : classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                loading ? (
                  <>
                    <StyledTableCell
                      align={column.align}
                      style={{ minWidth: column.minWidth, width: column.width }}
                      colSpan={column.colSpan}
                    >
                      <Skeleton />
                    </StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, width: column.width }}
                      colSpan={column.colSpan}
                    >
                      {column.orden ? (
                        <TableSortLabel
                          active={`${valueToOrderBy}` === `${column.orden}`}
                          direction={`${valueToOrderBy}` === `${column.orden}` ? ordenDirection : 'asc'}
                          onClick={createSortHandler(`${column.orden}`)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </StyledTableCell>
                  </>
                )
              )}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={typeof rows === 'undefined' || typeof rows === 'number' ? rows || 0 : rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('table.rows-per-page')}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeRowsPerPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
