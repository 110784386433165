import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles, Container, Grid, Divider, Typography, Box, IconButton } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import Appbar from '../components/Appbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../assets/styles/colors';
import axios from '../api';
import BadgeCard from '../components/BadgeCard';
import Backdrop from '../components/Backdrop';
import { encrypt, decrypt } from '../utils/crypt';
import { useTranslation } from 'react-i18next';

function Certificate(props) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [badge, setTemplateBadge] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [skills, setSkills] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [badgesReccomendations, setBadgesRecommendations] = useState([]);
  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getBadge();
    await getBadgesReccomendations();
    setLoading(false);
  };

  const getBadge = async () => {
    setLoading(true);
    const badgeId = decrypt(props.match.params.badgeId);
    const { data } = await axios.get(`/templateHability/getTemplateHability/${badgeId}`);

    setTemplateBadge(data.templateHability);
    getTime(data.templateHability?.templateBadge?.tiempo_vp);
    getLevel(data.templateHability?.templateBadge?.nivel_vp);
    getCosts(data.templateHability?.templateBadge?.costo_vp);
    setSkills(data.templateHability?.templateHability);
    setCriteria(data.templateHability?.criterion);
    setRecommendations(data.templateHability?.recommendation);
    setLoading(false);
  };

  const getBadgesReccomendations = async () => {
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublishedPublic`);
    setBadgesRecommendations(data.templatesBadges);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelBadge = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelBadge[0]);
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeBadge = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeBadge[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costBadge = data.costs.filter((data) => data.id === costo_vp);
    setCost(costBadge[0]);
  };

  const linkphoto = (idPlantilla) => {
    let photo = null;
    // eslint-disable-next-line
    badgesReccomendations.map((datos) => {
      if (datos.id === idPlantilla) {
        photo = datos.linkPhoto;
      }
    });
    return photo;
  };

  const toLink = (e, url_perfil) => {
    history.push(`/organization/${url_perfil}`);
  };

  return (
    <>
      <Appbar />
      <IconButton
        className={classes.closeButton}
        onClick={(e) => toLink(e, badge.templateBadge?.organizaciones?.url_perfil)}
      >
        <CloseOutlined />
      </IconButton>
      <Container className={classes.container} maxWidth={'xl'}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} className={'text-center'}>
            <img alt="img-badge" src={badge.linkPhoto} className={classes.img} />
            <div>
              <Typography className={classes.textTitle}>{t('certificateBadgeTemplate:details.issued-by')}:</Typography>
              <Link
                className={classes.link}
                onClick={(e) => toLink(e, encrypt(badge.templateBadge?.organizaciones?.url_perfil))}
              >
                {badge.templateBadge?.organizaciones?.nombre}
              </Link>
            </div>
            <div>
              <Typography className={classes.textBolder}>{t('certificateBadgeTemplate:details.created')}:</Typography>
              <Typography className={classes.text}>
                {moment(badge.templateBadge?.fecha_creacion).format('DD-MM-YYYY')}
              </Typography>
            </div>
          </Grid>

          <Divider orientation="vertical" flexItem className={classes.divider} />

          <Grid item xs={12} md={7}>
            {!loading && (
              <div className={classes.header}>
                <Grid container spacing={0}>
                  <Typography variant="h4" gutterBottom className={classes.textTitle}>
                    {badge.templateBadge?.nombre}
                  </Typography>
                  <img alt="img_verificado" src={props.styles.icon_verificado} className={classes.img_verificado} />
                </Grid>
              </div>
            )}

            <Typography className={classes.textParagraph} gutterBottom>
              {badge.templateBadge?.descripcion}
            </Typography>
            <Typography className={classes.textBolder} gutterBottom>
              {t('certificateBadgeTemplate:details.criteria')}
            </Typography>
            {criteria.map((data, index) =>
              data.link === null || data.link === '' ? (
                <Typography key={`criteria${index}`} className={classes.text}>
                  {data.descripcion_criterio}
                </Typography>
              ) : (
                <a
                  href={data.link}
                  target="_blank"
                  rel="noreferrer"
                  key={`criteria${index}`}
                  className={classes.link}
                  gutterBottom
                >
                  {data.descripcion_criterio}
                </a>
              )
            )}

            <Grid container spacing={2} align="center" className={classes.root}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {level?.valor_parametro}
                </Typography>
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.level')}</Typography>
              </Grid>
              {badge?.templateBadge?.cantidad_tiempo !== null && time?.valor_parametro !== undefined ? (
                <Grid item xs={12} sm={4}>
                  <Typography variant="h5" className={classes.textTitle}>
                    {`${badge.templateBadge?.cantidad_tiempo} ${time.valor_parametro}`}
                  </Typography>
                  <Typography className={classes.text}>{t('certificateBadgeTemplate:details.time')}</Typography>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {cost.valor_parametro}
                </Typography>
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.cost')}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.root}>
              <Grid item lg={12} sm={12} xs={12}>
                <Typography className={classes.textBolder}>{t('certificateBadgeTemplate:details.skills')}</Typography>
                <Grid container>
                  {skills.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                {recommendations.length > 0 ? (
                  <>
                    <Typography className={classes.textBolder}>
                      {t('certificateBadgeTemplate:details.recommendations')}
                    </Typography>

                    <div className={classes.badges}>
                      {recommendations.map((recommendation, index) => (
                        <BadgeCard
                          key={`badge${index}`}
                          badgeImage={linkphoto(recommendation?.plantillasInsigniasRecomendadas?.id)}
                          badgeName={recommendation?.plantillasInsigniasRecomendadas?.nombre}
                          organizationName={recommendation?.plantillasInsigniasRecomendadas?.organizaciones?.nombre}
                          issuedId={encrypt(recommendation?.plantillasInsigniasRecomendadas?.id)}
                          publicBadge={true}
                          templateBadge={true}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </Grid>
              {badge.templateBadge?.url && (
                <a href={badge.templateBadge?.url} target="_blank" rel="noreferrer" className={classes.link}>
                  {t('certificateBadgeTemplate:details.additional')}
                  <FontAwesomeIcon icon={faShareSquare} size="xs" />
                </a>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Backdrop loading={loading} />
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '2em',
  },
  img: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  img_verificado: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      height: theme.spacing(5),
    },
  },
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
    margin: '1em 0 1em 0',
  },
  textCode: {
    color: '#808080',
    textAlign: 'left',
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.5em 3em',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  header: {
    marginTop: '3em',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0',
    },
  },
  divider: {
    marginRight: '2em',
  },
  closeButton: {
    margin: '.5em .5em 0 0',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  link: {
    color: colors.primary,
    textDecoration: 'underline',
  },
  badges: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    styles: state.styles,
  };
};

export default connect(mapStateToProps, null)(Certificate);
