import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const exportToExcel = (jsonData, filename) => {
  if (jsonData.length > 0) {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = { Sheets: { Hoja1: ws }, SheetNames: ['Hoja1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + '.xlsx');
  }
};

export default exportToExcel;
