import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItem,
  ListItemText,
  Slide,
} from '@material-ui/core';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import NavBar from '../components/profile/NavBar';
import Appbar from '../components/Appbar';
import BadgeCard from '../components/BadgeCard';
import CertificateCard from '../components/CertificateCard';
import { setBadges, setCertificates, setCarnets, setSignedDocuments, setView } from '../actions';
import api from '../api';
import { encrypt, decrypt } from '../utils/crypt';
import colors from '../assets/styles/colors';
import Swal from 'sweetalert2';
import { BroadcastChannel } from 'broadcast-channel';
import Skeleton from '@material-ui/lab/Skeleton';
import CertificateView from './Certificate';
import CarnetView from './Carnet';
import DocumentView from './Document';
import { useTranslation } from 'react-i18next';
import { env } from '../config/environment';

function Dashboard(props) {
  const {
    user,
    permission,
    badges,
    setBadges,
    certificates,
    setCertificates,
    carnets,
    setCarnets,
    signedDocuments,
    setSignedDocuments,
    history,
    view,
    setView,
  } = props;
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [publicUser, setPublicUser] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataUser, setDataUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDialogCertificate, setOpenDialogCertificate] = useState(false);
  const [openDialogCarnet, setOpenDialogCarnet] = useState(false);
  const [openDialogDocument, setOpenDialogDocument] = useState(false);
  const [certificateId, setCertificateId] = useState('');
  const [carnetId, setCarnetId] = useState('');
  const [documentId, setDocumentId] = useState('');
  const logoutChannel = new BroadcastChannel('logout');
  const [t] = useTranslation(['dashboard', 'dialogs', 'alerts', 'certifyDocuments']);
  //const [LogoSinInsignias, setLogoSinInsignias] = useState('');
  //const [LogoSinInsigniasSinOrg, setLogoSinInsigniasSinOrg] = useState('');
  const idOrganizationBackoffice = window.location.origin === env.mincienciasURL ? env.idMinciencias : 1;

  useEffect(() => {
    //const styles = JSON.parse(window.localStorage.state)?.styles;
    //setLogoSinInsignias(styles.no_insignias2);
    //setLogoSinInsigniasSinOrg(styles.no_insignias3);
    if (
      user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 1 ||
      user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 14
    ) {
      history.push('/templates/published');
      window.location.reload();
    }

    mount()
      .then(() => {
        if (!props.match.params.userUrl && !props.match.params.documentTemplateId && user) {
          history.push(`/dashboard/${user?.data_user?.url_perfil}`);
        }
        setLoading(false);
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });

    return function cleanup() {
      setBadges([]);
      setCertificates([]);
      setCarnets([]);
      setSignedDocuments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mount = async () => {
    const profileURL = props.match.params.userUrl;
    if (profileURL && permission.length === 0) {
      const badgeLength = await getBadgesPublic(profileURL);
      const carnetLength = await getCarnetsPublic(profileURL);
      const certificateLength = await getCertificatesPublic(profileURL);
      const { data } = await api.get(`/user/public/url/${profileURL}`);
      setPublicUser(data.user);
      badgeLength > 0 ? setView(0) : certificateLength > 0 ? setView(1) : carnetLength > 0 ? setView(2) : setView(0);
      if (badges === 1 || certificates === 1 || carnets === 1) {
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.user-no-found'),
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/');
          window.location.reload();
        });
      }
    } else {
      if (permission.includes('Leer') && profileURL && profileURL !== user?.data_user?.url_perfil) {
        const badgeLength = await getBadgesPublic(profileURL);
        const carnetLength = await getCarnetsPublic(profileURL);
        const certificateLength = await getCertificatesPublic(profileURL);
        const { data } = await api.get(`/user/public/url/${profileURL}`);
        setPublicUser(data.user);
        badgeLength > 0 ? setView(0) : certificateLength > 0 ? setView(1) : carnetLength > 0 ? setView(2) : setView(0);
        if (badges === 1 || certificates === 1 || carnets === 1) {
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.user-no-found'),
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push('/');
            window.location.reload();
          });
        }
      } else {
        if (permission.includes('Leer')) {
          const { data } = await api.post(`/user/profile`);
          setDataUser(data?.user);
          setUserPhoto(data?.user?.linkPhoto);
          const certificateLength = await getCertificates();
          const badgeLength = await getBadges();
          const carnetLength = await getCarnets();
          const signedDocumentLength = await getSignedDocuments();
          badgeLength > 0
            ? setView(0)
            : certificateLength > 0
            ? setView(1)
            : carnetLength > 0
            ? setView(2)
            : signedDocumentLength > 0
            ? setView(3)
            : setView(0);
          if (props.match.params.badgeId) {
            const badge = await loadData();
            const account = await getEmail(badge?.issued?.email_receptor);
            if (badge?.issued?.alertAcept === 1) {
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.badge-accepted'),
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                title: t('alerts:alerts.badge-accepted'),
                text: t('alerts:alerts.close-session'),
                icon: 'success',
                iconColor: colors.primary,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: colors.primary,
                confirmButtonText: t('buttons:buttons.logout'),
                cancelButtonText: t('buttons:buttons.cancel'),
              }).then(async (result) => {
                if (result.value) {
                  if (account.length > 0) {
                    history.push(`/sign-in/${props.match.params.badgeId}`);
                    logoutChannel.postMessage('CerrarSesion');
                  } else {
                    history.push(`/sign-up/${props.match.params.badgeId}`);
                    logoutChannel.postMessage('CerrarSesion');
                  }
                }
              });
            }
          } else if (props.match.params.certificateId) {
            const certificate = await loadData();
            const account = await getEmail(certificate?.issued?.email_receptor);
            if (certificate?.issued?.alertAcept === 1) {
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.certificate-accepted'),
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                title: t('alerts:alerts.certificate-accepted'),
                text: t('alerts:alerts.close-session'),
                icon: 'success',
                iconColor: colors.primary,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: colors.primary,
                confirmButtonText: t('buttons:buttons.logout'),
                cancelButtonText: t('buttons:buttons.cancel'),
              }).then(async (result) => {
                if (result.value) {
                  if (account.length > 0) {
                    history.push(`/sign-in/certificate/${props.match.params.certificateId}`);
                    logoutChannel.postMessage('CerrarSesion');
                  } else {
                    history.push(`/sign-up/certificate/${props.match.params.certificateId}`);
                    logoutChannel.postMessage('CerrarSesion');
                  }
                } else {
                  window.location.reload();
                }
              });
            }
          } else if (props.match.params.carnetId) {
            const carnet = await loadData();
            const account = await getEmail(carnet?.issued?.email_receptor);
            if (carnet?.issued?.alertAcept === 1) {
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.carnet-accepted'),
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                title: t('alerts:alerts.carnet-accepted'),
                text: t('alerts:alerts.close-session'),
                icon: 'success',
                iconColor: colors.primary,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: colors.primary,
                confirmButtonText: t('buttons:buttons.logout'),
                cancelButtonText: t('buttons:buttons.cancel'),
              }).then(async (result) => {
                if (result.value) {
                  if (account.length > 0) {
                    history.push(`/sign-in/carnet/${props.match.params.carnetId}`);
                    logoutChannel.postMessage('CerrarSesion');
                  } else {
                    history.push(`/sign-up/carnet/${props.match.params.carnetId}`);
                    logoutChannel.postMessage('CerrarSesion');
                  }
                }
              });
            }
          } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
            const idSignature = props.match.params.idSignature;
            const { data } = await api.get(`/signature/receiver/${idSignature}`);
            if (data?.signature !== null) {
              if (user.correo === data.signature.cuentas.correo) {
                history.push(
                  `/document/signature/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
                );
              } else {
                Swal.fire({
                  text: t('alerts:alerts.user-no-is-signatorie'),
                  icon: 'warning',
                  iconColor: colors.primary,
                  showCancelButton: true,
                  confirmButtonText: t('buttons:buttons.logout'),
                  cancelButtonText: t('buttons:buttons.cancel'),
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'custom-button-confirm',
                    cancelButton: 'custom-button-cancel',
                  },
                }).then(async (result) => {
                  if (result.value) {
                    history.push(
                      `/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
                    );
                    logoutChannel.postMessage('CerrarSesion');
                  } else {
                    history.push('/');
                  }
                });
              }
            } else {
              Swal.fire({
                icon: 'warning',
                iconColor: colors.primary,
                text: t('alerts:alerts.document-already'),
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'custom-button-confirm',
                },
              });
              history.push('/');
            }
          }
          //Cerrar sesion
        } else if (user) {
          Swal.fire({
            text: t('alerts:alerts.access-denied'),
            icon: 'error',
            iconColor: colors.primary,
            confirmButtonColor: colors.primary,
            confirmButtonText: t('buttons:buttons.logout'),
            confirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          }).then((res) => {
            if (res.isConfirmed) {
              logoutChannel.postMessage('CerrarSesion');
              history.push('/');
            } else {
              history.push('/');
            }
          });
        } else {
          history.push('/');
        }
      }
    }
  };

  const loadData = async () => {
    if (props.match.params.certificateId) {
      return await getCertificateIssued();
    }
    if (props.match.params.badgeId) {
      return await getBadgeIssued();
    }
    if (props.match.params.carnetId) {
      return await getCarnetIssued();
    }
    if (props.match.params.documentId) {
      return await getSignedDocumentsIssued();
    }
  };

  const getBadges = async () => {
    let badgeLength = 0;
    const { data } = await api.post('badgesIssued/getBadgesIssuedByUser', {
      idOrganizationBackoffice,
    });
    badgeLength = data.issued.length;
    setBadges(data.issued);
    return badgeLength;
  };

  const getCertificates = async () => {
    let certificateLength = 0;
    const { data } = await api.post('certificatesIssued/getCertificatesIssuedByUser', {
      idOrganizationBackoffice,
    });
    certificateLength = data.issued.length;
    setCertificates(data.issued);

    return certificateLength;
  };

  const getCarnets = async () => {
    let carnetLength = 0;
    const { data } = await api.post('carnetsIssued/getCarnetsIssuedByUser', {
      idOrganizationBackoffice,
    });
    carnetLength = data.issued.length;
    setCarnets(data.issued);

    return carnetLength;
  };

  const getSignedDocuments = async () => {
    let documentLength = 0;
    const { data } = await api.post('documentsCertify/getSignedDocumentsByUser', {
      idOrganizationBackoffice,
    });
    documentLength = data.signedDocuments.length;
    setSignedDocuments(data.signedDocuments);

    return documentLength;
  };

  const getBadgesPublic = async (url) => {
    let badgeLength = 0;
    const { data } = await api.post('badgesIssued/public/getBadgesIssuedByUser', {
      url: url,
      idOrganizationBackoffice,
    });
    badgeLength = data.issued.length;
    setBadges(data.issued);
    return badgeLength;
  };

  const getCertificatesPublic = async (url) => {
    let certificateLength = 0;
    const { data } = await api.post('certificatesIssued/public/getCertificatesIssuedByUser', {
      url: url,
      idOrganizationBackoffice,
    });
    certificateLength = data.issued.length;
    setCertificates(data.issued);
    return certificateLength;
  };

  const getCarnetsPublic = async (url) => {
    let carnetLength = 0;
    const { data } = await api.post('carnetsIssued/public/getCarnetsIssuedByUser', {
      url: url,
      idOrganizationBackoffice,
    });
    carnetLength = data.issued.length;
    setCarnets(data.issued);
    return carnetLength;
  };

  const getSignedDocumentsIssued = async () => {
    const signedDocumentsIssuedId = decrypt(props.match.params.documentId);
    const { data } = await api.get(`documentsCertify/${signedDocumentsIssuedId}`);
    setView(3);
    return data.documentCertify;
  };

  const getCarnetIssued = async () => {
    const carnetIssuedId = decrypt(props.match.params.carnetId);
    const { data } = await api.get(`carnetsIssued/${carnetIssuedId}`);
    setView(2);
    return data.issued;
  };

  const getCertificateIssued = async () => {
    const certificateIssuedId = decrypt(props.match.params.certificateId);
    const { data } = await api.get(`certificatesIssued/${certificateIssuedId}`);
    setView(1);
    return data.issued;
  };

  const getBadgeIssued = async () => {
    const badgeIssuedId = decrypt(props.match.params.badgeId);
    const { data } = await api.get(`badgesIssued/${badgeIssuedId}`);
    setView(0);
    return data.issued;
  };

  const getEmail = async (email) => {
    const { data } = await api.post('user/getAccounts', { correo: email });
    return data.accounts;
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const toLink = (id) => {
    history.push(`/organization/${id}`);
    window.location.reload();
  };

  const openDialogOrganizacion = () => {
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Appbar />
      <div className={classes.container}>
        {publicUser && (
          <>
            {loading ? (
              <Skeleton variant="circle">
                <Avatar variant="rounded" className={classes.container__avatar} />
              </Skeleton>
            ) : (
              <Avatar
                src={publicUser?.linkPhoto || userPhoto}
                variant="rounded"
                className={classes.container__avatar}
              />
            )}
            <div className={classes.container__welcome}>
              {!publicUser && loading ? <Skeleton variant="text" width={250} height={30} /> : ''}
              <p className={classes.container__welcome_name}>
                {publicUser ? (
                  loading ? (
                    <Skeleton variant="text" width={250} height={30} />
                  ) : (
                    `${publicUser.primer_nombre} ${publicUser.primer_apellido}`
                  )
                ) : loading ? (
                  <Skeleton variant="text" width={250} height={30} />
                ) : (
                  `${dataUser?.primer_nombre} ${dataUser?.primer_apellido}`
                )}
              </p>
              <div align="left">
                {publicUser?.pagina_web ? (
                  <a href={publicUser.pagina_web} target="_blank" rel="noreferrer">
                    <LanguageOutlinedIcon />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {loading ? (
        <Skeleton variant="rect" height={50} />
      ) : (
        <NavBar
          view={
            badges.length > 0
              ? 0
              : certificates.length > 0
              ? 1
              : carnets.length > 0
              ? 2
              : signedDocuments.length > 0
              ? 3
              : 0
          }
          setView={setView}
          handleSearch={handleSearch}
        />
      )}
      {loading ? (
        <div className={classes.badges}>
          {[...Array(6)].map(() => (
            <Skeleton variant="rect">
              <BadgeCard />
            </Skeleton>
          ))}
        </div>
      ) : // eslint-disable-next-line
      (view === 0 && badges.length > 0) ||
        (view === 1 && certificates.length > 0) ||
        (view === 2 && carnets.length > 0) ||
        (view === 3 && signedDocuments.length > 0) ? (
        view === 0 ? (
          <div className={classes.badges}>
            {badges
              .filter(
                (badge) =>
                  badge.insigniasEmitidas?.plantillasInsignias?.nombre.toLowerCase().includes(search.toLowerCase()) ||
                  badge.insigniasEmitidas?.plantillasInsignias?.organizaciones?.nombre
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((badge, index) => (
                <>
                  <BadgeCard
                    key={`badge${index}`}
                    badgeImage={badge.insigniasEmitidas?.plantillasInsignias?.linkPhoto}
                    badgeName={badge.insigniasEmitidas?.plantillasInsignias?.nombre}
                    organizationName={
                      badge.insigniasEmitidas?.plantillasInsignias?.id_organizacion_hija !== null
                        ? badge.insigniasEmitidas?.plantillasInsignias?.organizacionesHijas.nombre
                        : badge.insigniasEmitidas?.plantillasInsignias?.organizaciones.nombre
                    }
                    issuedId={encrypt(badge.id_insignias_emitidas)}
                    publicBadge={publicUser !== null}
                  />
                </>
              ))}
          </div>
        ) : view === 1 ? (
          <div className={classes.certificate}>
            {certificates
              .filter(
                (certificate) =>
                  certificate.certificadosEmitidos?.plantillasCertificados?.nombre
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  certificate.certificadosEmitidos?.plantillasCertificados?.organizaciones?.nombre
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((certificate, index) => (
                <CertificateCard
                  key={`certificate${index}`}
                  format={
                    certificate.certificadosEmitidos?.plantillasCertificados?.width <
                    certificate.certificadosEmitidos?.plantillasCertificados?.height
                      ? 0
                      : 1
                  }
                  certificateImage={certificate.certificadosEmitidos?.plantillasCertificados?.linkPhoto}
                  certificateName={certificate.certificadosEmitidos?.plantillasCertificados?.nombre}
                  organizationName={
                    certificate.certificadosEmitidos?.plantillasCertificados?.id_organizacion_hija !== null
                      ? certificate.certificadosEmitidos?.plantillasCertificados?.organizacionesHijas?.nombre
                      : certificate.certificadosEmitidos?.plantillasCertificados?.organizaciones?.nombre
                  }
                  issuedId={encrypt(certificate.id_certificados_emitidos)}
                  publicCertificate={publicUser !== null}
                  open={setOpenDialogCertificate}
                  certificateId={setCertificateId}
                />
              ))}
          </div>
        ) : view === 2 ? (
          <div className={classes.certificate}>
            {carnets
              .filter(
                (carnet) =>
                  carnet.carnetsEmitidos?.plantillasCarnets?.nombre.toLowerCase().includes(search.toLowerCase()) ||
                  carnet.carnetsEmitidos?.plantillasCarnets?.organizaciones?.nombre
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((carnet, index) => (
                <CertificateCard
                  key={`carnet${index}`}
                  format={
                    carnet.carnetsEmitidos?.plantillasCarnets?.width < carnet.carnetsEmitidos?.plantillasCarnets?.height
                      ? 0
                      : 1
                  }
                  certificateImage={carnet.carnetsEmitidos?.plantillasCarnets?.linkPhoto}
                  certificateName={carnet.carnetsEmitidos?.plantillasCarnets?.nombre}
                  organizationName={carnet.carnetsEmitidos?.plantillasCarnets?.organizaciones?.nombre}
                  issuedId={encrypt(carnet.id_carnets_emitidos)}
                  publicCertificate={publicUser !== null}
                  open={setOpenDialogCarnet}
                  certificateId={setCarnetId}
                  isCarnets={true}
                />
              ))}
          </div>
        ) : (
          <div className={classes.certificate}>
            {signedDocuments
              .filter(
                (document) =>
                  document.documentosEmitidos?.nombre.toLowerCase().includes(search.toLowerCase()) ||
                  document.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((document, index) => (
                <CertificateCard
                  key={`document${index}`}
                  format={0}
                  certificateImage={document.documentosEmitidos?.photoDocument}
                  certificateName={document.documentosEmitidos?.nombre}
                  organizationName={document.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre}
                  issuedId={encrypt(document.id_documentos_emitidos)}
                  publicCertificate={publicUser !== null}
                  open={setOpenDialogDocument}
                  certificateId={setDocumentId}
                  isDocument={true}
                  waitDocument={
                    document.documentosEmitidos.estado === 2
                      ? t('certifyDocuments:document.waiting-for-signatories')
                      : ''
                  }
                />
              ))}
          </div>
        )
      ) : user?.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones ? (
        <div className={classes.container_noBadges}>
          {/* <img className={classes.imagen} alt="imagen sin badges" src={LogoSinInsignias} /> */}
          <div className={classes.container_text}>
            <p className={classes.text}>
              {`${t('dashboard:have-badges-certificates')}
              ${
                view === 0
                  ? t('dashboard:badges')
                  : view === 1
                  ? t('dashboard:certificates')
                  : view === 2
                  ? t('dashboard:carnets')
                  : t('dashboard:signedDocuments')
              }!`}
            </p>
            <p className={classes.text_secondary}>
              {`${view !== 3 ? t('dashboard:can-know') : ''}
              ${
                view === 0
                  ? t('dashboard:badges-2')
                  : view === 1
                  ? t('dashboard:certificates-2')
                  : view === 2
                  ? t('dashboard:carnets-2')
                  : ''
              }
              ${view !== 3 ? t('dashboard:organization-offered') : ''}`}
            </p>
            {user?.data_user.gruposUsuariosOrganizaciones.length > 1 && view !== 3 ? (
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
                onClick={(e) => openDialogOrganizacion()}
              >
                {t('buttons:buttons.click-here')}
              </Button>
            ) : view !== 3 ? (
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
                onClick={(e) => toLink(user.data_user.gruposUsuariosOrganizaciones[0].organizaciones?.url_perfil)}
              >
                {t('buttons:buttons.click-here')}
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : publicUser ? (
        <div className={classes.container_noBadges}>
          {/*  <img className={classes.imagenPublicUser} alt="imagen sin badges" src={LogoSinInsigniasSinOrg} /> */}
          <div className={classes.container_text}>
            <p className={classes.text}>
              {t('dashboard:this-user')}{' '}
              {view === 0 ? t('dashboard:badges') : view === 1 ? t('dashboard:certificates') : t('dashboard:carnets')}!
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.container_noBadges}>
          {/* <img className={classes.imagen} alt="imagen sin badges" src={LogoSinInsigniasSinOrg} /> */}
          <div className={classes.container_text}>
            <p className={classes.text}>
              {t('dashboard:have-badges-certificates')}{' '}
              {view === 0 ? t('dashboard:badges') : view === 1 ? t('dashboard:certificates') : t('dashboard:carnets')}!
            </p>
            <p className={classes.text_secondary}>{t('dashboard:wait')} </p>
            <Button className={classes.editPerfilButton} onClick={(e) => history.push('/profileSettings')}>
              {t('dashboard:edit-profile')}
            </Button>
          </div>
        </div>
      )}

      <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle align="center">{t('dialogs:dialog.select-organization')}</DialogTitle>
        <DialogContent align="center">
          {user?.data_user.gruposUsuariosOrganizaciones.map((org) => (
            <ListItem button onClick={() => toLink(org.id_organizaciones)}>
              <ListItemText primary={org.organizaciones?.nombre} />
            </ListItem>
          ))}
        </DialogContent>
      </Dialog>

      <Dialog fullScreen open={openDialogCertificate} TransitionComponent={Transition}>
        <CertificateView certificateId={certificateId} setOpenDialog={setOpenDialogCertificate} />
      </Dialog>

      <Dialog fullScreen open={openDialogCarnet} TransitionComponent={Transition}>
        <CarnetView carnetId={carnetId} setOpenDialog={setOpenDialogCarnet} />
      </Dialog>

      <Dialog fullScreen open={openDialogDocument} TransitionComponent={Transition}>
        <DocumentView documentId={documentId} setOpenDialog={setOpenDialogDocument} />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1em 2em',
    marginBottom: '0',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
      margin: '1em 4em',
      marginBottom: '0',
    },
  },
  container_button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '3em',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
      margin: '4em',
      marginBottom: '4em',
      marginTop: '1em',
    },
  },
  container_noBadges: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginTop: '10%',
    },
  },
  container__avatar: {
    width: '120px',
    height: '120px',
    borderRadius: '100%',
    marginBottom: 10,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  imagen: {
    maxWidth: '330px',
    [theme.breakpoints.up('1850')]: {
      maxWidth: '600px',
    },
  },
  imagenPublicUser: {
    maxWidth: '290px',
    [theme.breakpoints.up('1850')]: {
      maxWidth: '540px',
    },
  },
  container__welcome: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '3em',
    },
  },
  container__buttonEdit: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttons: {
    border: 'none',
    borderRadius: 8,
    padding: '2px 20px',
  },
  container__welcome_message: {
    fontSize: '1.3em',
    margin: 0,
  },
  container__welcome_name: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '10px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '1px',
    },
  },
  badges: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
  },
  certificate: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(335px, 1fr))',
      margin: '30px 30px 0px 30px',
      '& div': {
        marginBottom: 20,
        justifySelf: 'center',
      },
    },
  },
  container_text: {
    maxWidth: '40rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: 0,
    },
  },
  text: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: colors.primary,
    textAlign: 'center',
  },
  text_secondary: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  button: {
    margin: '1em 5em 1em 5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 10,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  editPerfilButton: {
    fontSize: '1em',
    textAlign: 'center',
    color: colors.primary,
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    user: state.user,
    badges: state.badges || [],
    certificates: state.certificates || [],
    carnets: state.carnets || [],
    signedDocuments: state.signedDocuments || [],
    view: state.view || 0,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'dashboard')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  setBadges,
  setCertificates,
  setCarnets,
  setSignedDocuments,
  setView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
