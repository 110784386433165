import { React, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Stage, Layer, Text, Image, Transformer } from 'react-konva';
import { setCertificateFields } from '../../actions';
import useImage from 'use-image';
import FieldImage from '../../components/FieldImage';
import FieldVerify from '../../components/FieldVerify';

function Certificate(props) {
  const {
    imageRoute,
    width,
    height,
    modifyField,
    certificateFields,
    setCertificateFields,
    stageRef,
    selectedId,
    selectShape,
    isSelected,
    setIsSelected,
  } = props;
  const classes = useStyles({ imageRoute });
  const trRef = useRef();
  const refShape = useRef();
  const [shapeRef, setShapeRef] = useState([]);

  const handleOnDragEnd = (e, index) => {
    certificateFields[index] = {
      ...certificateFields[index],
      x: e.target.x(),
      y: e.target.y(),
    };
    setIsSelected(false);
    setShapeRef(e.target);
    setIsSelected(true);
    setCertificateFields(certificateFields);
  };

  useEffect(() => {
    if (isSelected && shapeRef?.index) {
      trRef.current.setNode(shapeRef);
      trRef.current.getLayer().batchDraw();
    } else if (isSelected && refShape) {
      trRef.current.setNode(refShape.current);
      trRef.current.getLayer().batchDraw();
    } // eslint-disable-next-line
  }, [isSelected]);

  const BackgroundImage = () => {
    const [image] = useImage(imageRoute, 'Anonymous');
    return <Image image={image} />;
  };

  const onChange = (newAttrs, index) => {
    const rects = certificateFields.slice();
    rects[index] = newAttrs;
    setCertificateFields(rects);
  };

  return (
    <div className={classes.container}>
      <Stage
        width={width}
        height={height}
        ref={stageRef}
        onMouseDown={(e) => {
          // deselect when clicked on empty area
          if (e.target.attrs.image) {
            selectShape(null);
            setIsSelected(false);
            setShapeRef(null);
          }
        }}
        onMouseLeave={() => {
          selectShape(null);
          setIsSelected(false);
          setShapeRef(null);
        }}
      >
        <Layer>
          <BackgroundImage />
          {certificateFields?.map((field, index) => {
            if (field.fieldType !== 48) {
              // is the id imagen
              return (
                <>
                  <Text
                    key={`field${index + 1}`}
                    ref={refShape}
                    text={
                      field.fieldName === 'certificateCode'
                        ? `${field.exampleText}${field.exampleText === '' ? '' : ':'}CK09aABbAz`
                        : field.exampleText
                    }
                    x={field.x}
                    y={field.y}
                    width={field.width}
                    height={parseInt(field.fontSize)}
                    align={field.align}
                    ellipsis={true}
                    onClick={(e) => {
                      setIsSelected(false);
                      setShapeRef(e.target);
                      selectShape(field.exampleText);
                      setIsSelected(true);
                    }}
                    draggable
                    dragBoundFunc={(pos, e) => {
                      let newX = pos.x;
                      let newY = pos.y;
                      if (newX < 0) newX = 0;
                      if (newY < 0) newY = 0;
                      if (newX > width - field.width) newX = width - field.width;
                      if (newY > height - field.height) newY = height - field.height;

                      return {
                        x: newX,
                        y: newY,
                      };
                    }}
                    fill={field.fontColor?.hex}
                    fontStyle={`${field.fontStyle?.bold ? 'bold' : ''} ${field.fontStyle?.italic ? 'italic' : ''}`}
                    textDecoration={`${field.fontStyle?.underline ? 'underline' : ''} ${
                      field.fontStyle?.strikethrough ? 'line-through' : ''
                    }`}
                    fontSize={parseInt(field.fontSize)}
                    fontFamily={field.fontFamily.family}
                    onDragEnd={(e) => handleOnDragEnd(e, index)}
                    onDblClick={(e) => {
                      setShapeRef(e.target);
                      modifyField(index);
                    }}
                    onTransformEnd={(e) => {
                      // transformer is changing scale of the node
                      // and NOT its width or height
                      // but in the store we have only width and height
                      // to match the data better we will reset scale on transform end
                      const node = shapeRef;
                      const scaleX = node.scaleX();

                      // we will reset it back
                      node.scaleX(1);
                      node.scaleY(1);
                      node.width(Math.max(5, node.width() * scaleX));

                      onChange(
                        {
                          ...field,
                          width: Math.round(node.width()),
                        },
                        index
                      );
                    }}
                  />
                  {isSelected && (
                    <Transformer
                      ref={trRef}
                      rotateEnabled={false}
                      enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                      boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        const minWidthHeight = 150;
                        if (newBox.width <= minWidthHeight) {
                          return oldBox;
                        }
                        const maxWidthHeight = width;
                        if (newBox.width >= maxWidthHeight) {
                          return oldBox;
                        }
                        return newBox;
                      }}
                    />
                  )}
                </>
              );
            } else if (field.fieldName === 'buttonVerify') {
              return (
                <FieldVerify
                  key={index}
                  index={index}
                  shapeProps={field}
                  isSelected={field.exampleText === selectedId}
                  isDraggable={true}
                  onSelect={() => {
                    setIsSelected(false);
                    setShapeRef(null);
                    selectShape(field.exampleText);
                  }}
                  onChange={(newAttrs) => {
                    const rects = certificateFields.slice();
                    rects[index] = newAttrs;
                    setCertificateFields(rects);
                  }}
                  layoutWidth={width}
                  layoutHeight={height}
                  modifyField={modifyField}
                />
              );
            } else {
              return (
                <FieldImage
                  key={index}
                  shapeProps={field}
                  index={index}
                  isSelected={field.exampleText === selectedId}
                  isDraggable={true}
                  onSelect={() => {
                    selectShape(field.exampleText);
                  }}
                  onChange={(newAttrs) => {
                    const rects = certificateFields.slice();
                    rects[index] = newAttrs;
                    setCertificateFields(rects);
                  }}
                  modifyField={modifyField}
                  layoutWidth={width}
                  layoutHeight={height}
                />
              );
            }
          })}
        </Layer>
      </Stage>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    marginTop: 32,
    backgroundImage: `url(${props.imageRoute})`,
    backgroundRepeat: 'no-repeat',
    width: 'fit-content',
    maxWidth: '68vw',
  }),
}));

const mapStateToProps = (state) => {
  return {
    certificateFields: state.certificateFields || [],
    imageRoute: state.form.img_prev,
    width: state.form.width,
    height: state.form.height,
  };
};

const mapDispatchToProps = {
  setCertificateFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
