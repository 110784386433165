export function SumOneYear(currentDate) {
  /* Esta función le suma un año a la fecha ingresada con formato YYYY-MM-DD hh:mm:ss */
  const date = currentDate.replace(currentDate.split('-')[0], parseInt(currentDate.split('-')[0]) + 1);
  return date;
}

export function SumOneDay(currentDate) {
  /* Esta función le suma un dia a la fecha ingresada con formato YYYY-MM-DD hh:mm:ss */
  const dayMoreOne = parseInt(currentDate.slice(8, 10)) + 1;
  let date = currentDate.split('-');
  date[2] = dayMoreOne < 10 ? `0${dayMoreOne}` : dayMoreOne;
  date = `${date[0]}-${date[1]}-${date[2]}`;
  return date;
}

export function ResOneYear(currentDate) {
  /* Esta función le resta un año a la fecha ingresada con formato YYYY-MM-DD hh:mm:ss */
  const date = currentDate.replace(currentDate.split('-')[0], parseInt(currentDate.split('-')[0]) - 1);
  return date;
}
