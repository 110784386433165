import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow, IconButton, Button, Tooltip, Avatar } from '@material-ui/core/';
import { FindInPageOutlined, HistoryOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: "50px",
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  lista: {
    backgroundColor: '#F8F9F9',
  },
}));

function Recommendations(props) {
  const { page, rowsPerPage, permission, changePage, changeBreadcrump, user, organizationId, searchValue } = props;
  const classes = useStyles();
  const history = useHistory();
  const [recommendation, setRecommendation] = useState([]);
  const [recommendationAll, setRecommendationAll] = useState([]);
  const [templatesBadges, setTemplateBadges] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [historyFilter, setHistoryFilter] = useState([]);
  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;
  const [t] = useTranslation(['tables', 'buttons', 'alerts', 'breadCrumps']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    if (!searchValue || searchValue === '') {
      await getRecommendation();
    }
    await getTemplatesBadges();
    changeBreadcrump(t('breadCrumps:breadCrumps.badge-recommendation', { returnObjects: true }));
    setLoading(false);
  };

  const getRecommendation = async () => {
    const { data } = await axios.post(`/recommendation/getRecommendations`);
    if (
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
    ) {
      setRecommendation(data.recommendations?.recommendations);
      setFiltradas(data.recommendations?.recommendations);
    } else {
      const idHistoryfilter = data.recommendations.history
        .filter((item) => item.id_usuarios === user.data_user.id && parseInt(item.accion) === 39)
        // eslint-disable-next-line
        .map((item) => {
          if (item.id_campo) return item.id_campo;
        });
      setHistoryFilter(idHistoryfilter);
      setRecommendation(
        data.recommendations?.recommendations.filter(
          (item) => item.plantillasInsignias?.id_organizaciones === organizationId
        )
      );
      setFiltradas(
        data.recommendations?.recommendations.filter(
          (item) => item.plantillasInsignias?.id_organizaciones === organizationId
        )
      );
    }
    setRecommendationAll(data.recommendations?.recommendations);
  };

  const getTemplatesBadges = async () => {
    const { data } = await axios.post(`/templateBadge/getTemplatesBadges`);
    setTemplateBadges(data.templatesBadges);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/recommendations/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/recommendations/create`);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-register'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/recommendation/${id}`, { data: { id_usuarios: user.data_user?.id } });
      getRecommendation();
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.deleted-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (idTemplate, idRecomendation) => {
    if (permission.includes('Editar')) {
      let permissionDepartament = false;
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        permissionDepartament = !historyFilter.includes(idRecomendation);
      }
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton
            disabled={permissionDepartament}
            aria-label="edit"
            onClick={(e) => handleClick(e, idTemplate, 'edit')}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (idTemplate, idRecomendation) => {
    if (permission.includes('Eliminar')) {
      let permissionDepartament = false;
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        permissionDepartament = !historyFilter.includes(idRecomendation);
      }
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton
            disabled={permissionDepartament}
            aria-label="delete"
            onClick={(e) => handleClick(e, idTemplate, 'delete')}
          >
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const loadFillDataTemplatesRecomendadas = (id) => {
    let n = 0;
    recommendationAll.map((info) => info.id_plantillas === id && (n += 1));
    if (n !== 0) {
      return (
        <TableCell align="center">
          {n}
          <Tooltip title={t('tables:table.tooltip.recommended-templates')}>
            <IconButton aria-label="detalles" onClick={(e) => toLink(e, id)}>
              <FindInPageOutlined />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    }
  };

  const toLink = (e, id) => {
    changePage(0);
    props.history.push(`/recommendations/templatesRecommendations/${encrypt(id)}`);
  };

  const toHistory = (e, id) => {
    changePage(0);
    history.push(`/recommendations/history/${encrypt(id)}`);
  };

  const recommendationsColumns = () => {
    return t('tables:table.columns-recommendations', { returnObjects: true });
  };

  const load = (datos) => {
    let has = {};
    // eslint-disable-next-line
    datos = datos.filter((o) => (has[o.id_plantillas] ? false : (has[o.id_plantillas] = true)));
    return sortData(datos, getComparator(ordenDirection, valueToOrderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        return <Row key={row.id} row={row} />;
      });
  };

  function Row(props) {
    const { row } = props;
    let linkPhoto = null;
    // eslint-disable-next-line
    templatesBadges.map((datos) => {
      if (datos.id === row.id_plantillas) {
        linkPhoto = datos.linkPhoto;
      }
    });
    return (
      <>
        <TableRow key={row.id} align="center">
          <TableCell align="center">
            <Avatar alt="Remy Sharp" src={linkPhoto} className={classes.large} />
          </TableCell>
          <TableCell align="center">{row.plantillasInsignias?.nombre}</TableCell>
          <TableCell align="center">{row.plantillasInsignias?.nombre_privado}</TableCell>
          {loadFillDataTemplatesRecomendadas(row.plantillasInsignias?.id)}
          <TableCell align="center">
            <Tooltip title={t('tables:table.tooltip.history')}>
              <IconButton aria-label="history">
                <HistoryOutlined onClick={(e) => toHistory(e, row.plantillasInsignias?.id)} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="center">{edit_permission(row.plantillasInsignias?.id, row.id)}</TableCell>
          <TableCell align="center">{delete_permission(row.plantillasInsignias?.id, row.id)}</TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <>
      {create_permission()}
      <Search
        tableName="recomendations"
        type="badges"
        items={recommendation}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        userGroupId={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios}
        organizationId={organizationId}
        setLoading={setLoading}
      />
      <Table
        columns={recommendationsColumns()}
        rows={filtro}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={recommendationsColumns()} photo={1} />
          ) : filtro.length > 0 ? (
            <>{load(filtro)}</>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="9">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'recomendaciones')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
