import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ScrollDialog(props) {
  const { data, onClose, handleAccept, checked } = props;

  return (
    <div>
      <Dialog open={data.open} onClose={onClose} scroll={'paper'} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>{data.title}</DialogTitle>
        <embed src={`${data.url}#toolbar=0`} type="application/pdf" width="100%" height={600} />
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() =>
              data.index === 1
                ? data.viewTerms && checked.includes(1)
                  ? onClose()
                  : handleAccept(data.index)
                : data.viewPPT && checked.includes(2)
                ? onClose()
                : handleAccept(data.index)
            }
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
