import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Button, FormControl, TextField, Paper } from '@material-ui/core';
import api from '../../api';
import Backdrop from '../../components/Backdrop';
import Swal from 'sweetalert2';
import { env } from '../../config/environment';
import { useTranslation } from 'react-i18next';

function ForgetPassword(props) {
  const classes = useStyles({ env });
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [styles, setStyles] = useState('');

  const [t] = useTranslation(['loginRegister', 'buttons']);

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setStyles({
      Logo: styles.logo435x149,
      LoginLogo: styles.icono_login,
      LoginBackground: styles.login_background,
      ForgetPassword: styles.forget_password,
    });

    if (window.location.origin === env.certikaURL) {
      setForm({
        ...form,
        backoficceId: 1,
      });
    } else if (window.location.origin === env.mincienciasURL) {
      setForm({
        ...form,
        backoficceId: 2,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post('/user/forgetPassword', form)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Swal.fire({
            icon: 'success',
            title: 'Mensaje enviado',
            text: 'Revise su bandeja de entrada.',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            props.history.push('/sign-in');
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: 'El correo electrónico no se encuentra registrado.',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          text: 'Ha ocurrido un error. Intenta nuevamente.',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: window.location.origin === env.mincienciasURL ? 'none' : `url(${styles?.LoginBackground})`,
      }}
    >
      <Paper elevation={0} className={classes.container_card}>
        <div className={classes.container__login}>
          <div className={classes.container__logo}>
            <img src={styles?.Logo} alt="logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.container__text}>
                <p>{t('loginRegister:message-recovery1')}</p>
                <p> {t('loginRegister:message-recovery2')}</p>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:email')}
                    name="correo"
                    variant="outlined"
                    type="email"
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.classes__login_buttons}>
              <Button
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
                disableElevation
              >
                {t('buttons:buttons.send')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={() => props.history.push('/sign-in')}
                disableElevation
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
      <div className={classes.container__image}>
        <img
          src={window.location.origin === env.certikaURL ? styles?.ForgetPassword : styles?.LoginLogo}
          className={classes.img}
          alt="login-logo"
        />
      </div>
      <Backdrop loading={loading} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: window.location.origin === props.env.mincienciasURL ? '20px' : 0,
      height: '100vh',
      justifyContent: 'space-around',
    },
  }),
  container_card: {
    padding: '20px',
    borderRadius: '15px',
    margin: '5px 10px',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
      margin: '0 10px',
      width: '600px',
    },
  },
  container__text: (props) => ({
    width: '100%',
    margin: '10px 0px',
    '& p': {
      fontWeight: 500,
      marginBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width: window.location.origin === props.env.mincienciasURL ? '24em' : '30em',
    },
  }),
  button: {
    margin: '0.5em',
    padding: '9px 40px',
    width: '50%',
  },
  container__image: (props) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: window.location.origin === props.env.mincienciasURL ? '83.5vw' : '50vw',
    },
  }),
  img: {
    width: '100%',
    height: '100%',
  },
  container__login: {
    '& h2': {
      fontWeight: 700,
    },
    '& img': {
      width: '90%',
    },
  },
  container__logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  classes__login_buttons: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: '1em',
  },
}));

export default ForgetPassword;
