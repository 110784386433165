import React from 'react';
import ModuleHeader from '../../../../components/ModuleHeader';
import TabPanel from '../../../../components/TabPanel';
import { encrypt } from '../../../../utils/crypt';
import { useTranslation } from 'react-i18next';

function IndexCollections({ children, Index, Buttons, documentsId }) {
  /* translation */
  const [t] = useTranslation('tabs');

  const tabs = t('tabs.tabs-details', { returnObjects: true });

  const Tabs = [
    { label: 'Detalle', route: `/templates/documents/published/details/${encrypt(documentsId)}` },
    { label: 'Historial', route: `/templates/documents/published/history/${encrypt(documentsId)}` },
  ];

  Tabs.map((item, index) => tabs.filter((data, index2) => (index === index2 ? (item.label = data.label) : '')));

  return (
    <>
      <ModuleHeader>{Buttons}</ModuleHeader>
      <TabPanel Index={Index} TabsHeader={Tabs} />
      {children}
    </>
  );
}

export default IndexCollections;
