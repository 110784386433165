import axios from 'axios';
import { env } from '../../config/environment';

const validated = (value) => {
  let colors = '';
  let color = '';
  if (window.location.origin === env.certikaURL) {
    axios({
      url: `${env.apiURL}/organization/backoffice/${1}`,
      method: 'get',
    }).then((response) => {
      colors = response.data.organization.styles.colors;
    });
  } else if (window.location.origin === env.mincienciasURL) {
    axios({
      url: `${env.apiURL}/organization/backoffice/${env.idMinciencias}`,
      method: 'get',
    }).then((response) => {
      colors = response.data.organization.styles;
    });
  }

  switch (value) {
    case 'primary':
      color = colors.primary;
      break;
    case 'secondary':
      color = colors.secondary;
      break;
    case 'tertiary':
      color = colors.tertiary;
      break;
    case 'background':
      color = colors.background;
      break;
    case 'footer':
      color = colors.footer;
      break;
    case 'error':
      color = colors.error;
      break;
    default:
      break;
  }
  return color;
};

const colors = {
  primary: `${
    window.localStorage.state ? JSON.parse(window.localStorage.state)?.styles?.colors?.primary : validated('primary')
  }`,
  secondary: `${
    window.localStorage.state
      ? JSON.parse(window.localStorage.state)?.styles?.colors?.secondary
      : validated('secondary')
  }`,
  tertiary: `${
    window.localStorage.state ? JSON.parse(window.localStorage.state)?.styles?.colors?.tertiary : validated('tertiary')
  }`,
  background: `${
    window.localStorage.state
      ? JSON.parse(window.localStorage.state)?.styles?.colors?.background
      : validated('background')
  }`,
  footer: `${
    window.localStorage.state ? JSON.parse(window.localStorage.state)?.styles?.colors?.footer : validated('footer')
  }`,
  error: `${
    window.localStorage.state ? JSON.parse(window.localStorage.state)?.styles?.colors?.error : validated('error')
  }`,
};

export default colors;
