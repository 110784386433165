import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Field from '../../components/Field';
import MenuCertificateItem from '../../components/MenuCertificateItem';
import textIcon from '../../assets/img/carbon_text-creation.svg';
import imgIcon from '../../assets/img/carbon_imagen.png';
import colors from '../../assets/styles/colors.js';
import { setCertificateFields } from '../../actions';
import { useTranslation } from 'react-i18next';
import defaultFields from '../../utils/defaultFields';

function Fields(props) {
  const { certificateFields, setCertificateFields, modifyField, openModal, form } = props;
  const classes = useStyle();
  const [expand, setExpand] = useState(true);
  const [expandImage, setExpandImage] = useState(true);
  const [expandOther, setExpandOther] = useState(true);
  const defaultField = [
    {
      fieldType: 48,
      fieldName: 'qr',
    },
    {
      fieldType: 48,
      fieldName: 'logo',
    },
    {
      fieldType: 41,
      fieldName: 'certificateCode',
    },
    {
      fieldType: 48,
      fieldName: 'buttonVerify',
    },
  ];

  const [t] = useTranslation(['certificateBadgeTemplate']);

  const deleteField = (index) => {
    certificateFields.splice(index, 1);
    setCertificateFields([...certificateFields]);
  };

  const deleteFieldDefault = (fieldName, all) => {
    if (all) {
      setCertificateFields([...certificateFields.filter((item) => item.fieldName !== fieldName)]);
    } else {
      const indexField = certificateFields.findIndex(
        (item) => item.fieldName === fieldName && (item.page === form?.page || item.page === 0)
      );
      setCertificateFields([...certificateFields.filter((_, index) => index !== indexField)]);
    }
  };

  const addDefaultField = (index) => {
    const { dataQr, dataImg, dataCode, dataButtonVerify, dataModules } = defaultFields();
    if (index === 0) {
      certificateFields.push({ ...dataQr, page: form?.page });
    } else if (index === 1) {
      certificateFields.push({ ...dataImg, page: form?.page });
    } else if (index === -1) {
      index = 2;
      certificateFields.push({ ...dataCode, page: form?.page });
    } else if (index === -2) {
      certificateFields.push({
        ...dataModules,
        page: form?.page,
        width: form.width - 300,
        modulos: form.modules,
      });
    } else if (index === -3) {
      certificateFields.push({ ...dataButtonVerify, page: form?.page });
    }
    setCertificateFields([...certificateFields]);
  };

  const multyFieldDefault = (fieldName) => {
    const indexField = certificateFields.findIndex(
      (item) => item.fieldName === fieldName && (item.page === form?.page || item.page === 0)
    );
    if (certificateFields[indexField].page === 0) {
      certificateFields[indexField].page = form?.page;
    } else {
      certificateFields[indexField].page = 0;
    }
    setCertificateFields([...certificateFields]);
  };

  const multyField = (index) => {
    if (certificateFields[index].page === 0) {
      certificateFields[index].page = form?.page;
    } else {
      certificateFields[index].page = 0;
    }
    setCertificateFields([...certificateFields]);
  };

  const addField = (indexField) => {
    const newField = { ...certificateFields[indexField] };
    if (newField.id) {
      delete newField.id;
    }
    certificateFields.push({
      ...newField,
      page: form?.page,
    });
    setCertificateFields([...certificateFields]);
  };

  const certificateCodeExample = () => {
    const example = certificateFields.find((item) => item.fieldName === 'certificateCode')?.exampleText;

    if (example === undefined) {
      return 'Código:CK09aABbAz';
    } else {
      return `${example}${example === '' ? '' : ':'}CK09aABbAz`;
    }
  };

  const validField = (fieldName) => {
    if (
      certificateFields?.find((item) => item.fieldName === fieldName && (item.page === form?.page || item.page === 0))
    ) {
      return 'delete';
    } else {
      return 'add';
    }
  };

  const numPage = (fieldName) => {
    return certificateFields.filter((item) => item.fieldName === fieldName);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.container__header}>
          <h6>{t('certificateBadgeTemplate:certificate-design.title-design')}</h6>
          <button className={classes.container__header_button} onClick={openModal}>
            +
          </button>
        </div>
        <div className={classes.container__fields}>
          <MenuCertificateItem
            title={t('certificateBadgeTemplate:certificate-design.texts')}
            logo={textIcon}
            expand={expand}
            onClick={() => setExpand(!expand)}
          />
          {expand && (
            <>
              <Field
                key={`field${2}`}
                index={-1}
                exampleText={certificateCodeExample()}
                fieldName={defaultField[2].fieldName}
                addOrDelete={validField(defaultField[2].fieldName)}
                addDefaultField={addDefaultField}
                modifyField={modifyField}
                deleteField={() => deleteFieldDefault(defaultField[2].fieldName)}
                deleteForAllPages={() => deleteFieldDefault(defaultField[2].fieldName, 'all')}
                page={numPage(defaultField[2].fieldName)}
                multyField={() => multyFieldDefault(defaultField[2].fieldName)}
                multyPages={form?.pages > 1}
              />
              {
                // eslint-disable-next-line array-callback-return
                certificateFields?.map((field, index) => {
                  if (field.fieldType !== 48 && field.fieldName !== 'certificateCode') {
                    return (
                      <Field
                        key={`field${index}`}
                        index={index}
                        exampleText={field.exampleText}
                        fieldName={field.fieldName}
                        addField={addField}
                        modifyField={modifyField}
                        deleteField={deleteField}
                        type={0}
                        addOrDelete={field.page === form?.page || field.page === 0 ? 'delete' : 'add'}
                        page={field?.page}
                        multyField={multyField}
                        multyPages={form?.pages > 1}
                      />
                    );
                  }
                })
              }
            </>
          )}
          <MenuCertificateItem
            title={t('certificateBadgeTemplate:certificate-design.images')}
            logo={imgIcon}
            expand={expandImage}
            onClick={() => setExpandImage(!expandImage)}
          />
          {expandImage &&
            // eslint-disable-next-line array-callback-return
            defaultField?.map((field, index) => {
              if (field.fieldType === 48 && index !== 3) {
                return (
                  <Field
                    key={`field${index}`}
                    index={index}
                    exampleText={''}
                    fieldName={field.fieldName}
                    addOrDelete={validField(field.fieldName)}
                    addDefaultField={addDefaultField}
                    deleteField={() => deleteFieldDefault(field.fieldName)}
                    deleteForAllPages={() => deleteFieldDefault(field.fieldName, 'all')}
                    page={numPage(field.fieldName)}
                    multyField={() => multyFieldDefault(field.fieldName)}
                    multyPages={form?.pages > 1}
                  />
                );
              }
            })}
          {expandImage &&
            // eslint-disable-next-line array-callback-return
            certificateFields?.map((field, index) => {
              if (field.img) {
                return (
                  <Field
                    key={`field${index}`}
                    index={index}
                    fieldImage={field.img}
                    multyField={() => multyFieldDefault(field.fieldName)}
                    multyPages={form?.pages > 1}
                    modifyField={modifyField}
                    deleteField={deleteField}
                    noEdit={true}
                  />
                );
              }
            })}
          <MenuCertificateItem
            title={t('certificateBadgeTemplate:certificate-design.buttons')}
            logo={textIcon}
            expand={expandOther}
            onClick={() => setExpandOther(!expandOther)}
          />
          {expandOther && (
            <Field
              key={`field${3}`}
              index={-3}
              exampleText={t('certificateBadgeTemplate:certificate-design.button-verify')}
              fieldName={defaultField[3].fieldName}
              modifyField={modifyField}
              addOrDelete={validField(defaultField[3].fieldName)}
              addDefaultField={addDefaultField}
              deleteField={() => deleteFieldDefault(defaultField[3].fieldName)}
              deleteForAllPages={() => deleteFieldDefault(defaultField[3].fieldName, 'all')}
              page={numPage(defaultField[3].fieldName)}
              multyField={() => multyFieldDefault(defaultField[3].fieldName)}
              multyPages={form?.pages > 1}
            />
          )}
        </div>
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    minHeight: '70vh',
  },
  container__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    '& h6': {
      marginLeft: 10,
      fontWeight: 'bold',
      color: '#888',
    },
  },
  container__header_button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    border: 'none',
    borderRadius: 20,
    color: 'white',
    backgroundColor: colors.primary,
    marginRight: 20,
    fontWeight: 'bold',
    fontSize: '1.5em',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  container__fields: {},
}));

const mapStateToProps = (state) => {
  return {
    certificateFields: state.certificateFields || [],
  };
};

const mapDispatchToProps = {
  setCertificateFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
