import React from 'react';
import { LinearProgress, Dialog, DialogTitle, DialogContent, withStyles, Box, Typography } from '@material-ui/core';

function ProgressBar({ title, value = 0, open }) {
  return (
    <Dialog open={open} fullWidth="xs">
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={value} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

export default ProgressBar;
