import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow, Avatar, Button, Tooltip, IconButton } from '@material-ui/core';
import { FindInPageOutlined } from '@mui/icons-material';
import moment from 'moment';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import colors from '../../../assets/styles/colors';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { encrypt, decrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: "50px",
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function HabilitiesTemplates(props) {
  const { page, rowsPerPage, changeBreadcrump, permission, user, organizationId, changePage } = props;
  const history = useHistory();
  const [templatesHabilities, setTemplatesHabilities] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [templates, setTemplates] = useState([]);
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await getTemplates();
    await getTemplatesHabilities();
    changeBreadcrump([
      { name: 'Insignias' },
      { name: 'Habilidades', route: '/skills' },
      { name: 'Plantillas Asignadas' },
    ]);
    setLoading(false);
  };

  const getTemplatesHabilities = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/templateHability/getTemplateHabilities/`, { id_habilidades: id });
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1) {
      setTemplatesHabilities(data.templateHabilities);
      setFiltradas(data.templateHabilities);
    } else {
      setTemplatesHabilities(
        data.templateHabilities.filter((item) => item.plantillasInsignias?.id_organizaciones === organizationId)
      );
      setFiltradas(
        data.templateHabilities.filter((item) => item.plantillasInsignias?.id_organizaciones === organizationId)
      );
    }
  };

  const getTemplates = async () => {
    const { data } = await axios.post(`/templateBadge/getTemplatesBadges`);
    setTemplates(data.templatesBadges);
  };

  const linkphoto = (idPlantilla) => {
    let photo = null;
    // eslint-disable-next-line
    templates.map((datos) => {
      if (datos.id === idPlantilla) {
        photo = datos.linkPhoto;
      }
    });
    return (
      <TableCell align="center">
        <Avatar alt="Remy Sharp" src={photo} className={classes.large} />
      </TableCell>
    );
  };

  const organizations = (idPlantilla) => {
    let organization = null;
    // eslint-disable-next-line
    templates.map((datos) => {
      if (datos.id === idPlantilla) {
        organization = datos.organizaciones.nombre;
      }
    });
    return <TableCell align="center">{organization}</TableCell>;
  };

  const toLinkPlantilla = (e, id) => {
    props.history.push(`/templates/published/details/${encrypt(id)}`);
  };

  return (
    <>
      <ModuleHeader>
        <Button className={classes.button} onClick={(e) => history.push('/skills')}>
          <span>Regresar</span>
        </Button>
      </ModuleHeader>
      <Search
        items={templatesHabilities}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
      />
      <Table columns={columns} rows={filtro} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns} photo={1} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.plantillasInsignias?.id}>
                    {linkphoto(row.plantillasInsignias?.id)}
                    <TableCell align="center">{row.plantillasInsignias?.nombre}</TableCell>
                    <TableCell align="center">{row.plantillasInsignias?.nombre_privado}</TableCell>
                    {organizations(row.plantillasInsignias?.id)}
                    <TableCell align="center">
                      {moment(row.plantillasInsignias.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {row.plantillasInsignias.estado === 1
                        ? 'Publicada'
                        : row.plantillasInsignias.estado === 2
                        ? 'Archivada'
                        : row.plantillasInsignias.estado === 0
                        ? 'En Edición'
                        : row.plantillasInsignias.estado === -1
                        ? 'Eliminada'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Ver Plantilla">
                        <IconButton
                          aria-label="detalles"
                          onClick={(e) => toLinkPlantilla(e, row.plantillasInsignias?.id)}
                        >
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableCell align="center" colSpan="6">
              No hay datos registrados
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const columns = [
  { id: 'insignia', label: 'Insignia', align: 'center' },
  { id: 'name', label: 'Nombre Público', align: 'center', orden: 'plantillasInsignias.nombre' },
  { id: 'private_name', label: 'Nombre Privado', align: 'center', orden: 'plantillasInsignias.nombre_privado' },
  { id: 'tipo', label: 'Organización', align: 'center' },
  {
    id: 'date',
    label: 'Última Actualización',
    align: 'center',
    orden: 'plantillasInsignias.fecha_ultima_modificacion',
  },
  { id: 'state', label: 'Estado', align: 'center' },
  { id: '', label: '', align: 'center' },
];

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'habilidades')
      .map((item) => item.acciones.nombre),
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(HabilitiesTemplates);
