import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { FindInPageOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import Swal from 'sweetalert2';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import EditIcon from '../../../assets/static/icons/EditIcon';
import { encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function Permissions(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, changePage } = props;
  const classes = useStyles();
  const history = useHistory();
  const [permissionsData, setPermissions] = useState([]);
  const [permissionsDataAll, setPermissionsAll] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [actionsDialog, setActionsDialog] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  let has = {};

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'forms']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      getPermissions()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          history.push('/500');
          window.location.reload();
        });
      changeBreadcrump(t('breadCrumps:breadCrumps.permissions', { returnObjects: true }));
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const getPermissions = async () => {
    const { data } = await axios.post(`/permission/getPermissions`);
    setPermissionsAll(data.permissions.permissions);
    data.permissions.permissions = data.permissions.permissions.filter((o) =>
      has[`${o.acciones?.modulos?.id}${o.id_grupos}`] ? false : (has[`${o.acciones?.modulos?.id}${o.id_grupos}`] = true)
    );
    setPermissions(
      data.permissions.permissions.sort((a, b) =>
        `${a.gruposUsuarios?.nombre.toLowerCase()}${a.acciones?.modulos?.nombre.toLowerCase()}` <
        `${b.gruposUsuarios?.nombre.toLowerCase()}${b.acciones?.modulos?.nombre.toLowerCase()}`
          ? -1
          : 1
      )
    );
    setFiltradas(
      data.permissions.permissions.sort((a, b) =>
        `${a.gruposUsuarios?.nombre.toLowerCase()}${a.acciones?.modulos?.nombre.toLowerCase()}` <
        `${b.gruposUsuarios?.nombre.toLowerCase()}${b.acciones?.modulos?.nombre.toLowerCase()}`
          ? -1
          : 1
      )
    );
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'create':
        history.push(`/permissions/create`);
        break;
      case 'edit':
        history.push(`/permissions/edit/${encrypt(id)}`);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-permission'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id).catch(() => {
          history.push('/500');
          window.location.reload();
        });
      }
    });
  };

  const sendDelete = async (id) => {
    const { data } = await axios.delete(`/permission/${id}`);
    setPermissions(permissionsData.filter((item) => item.id !== id));
    setFiltradas(permissionsData.filter((item) => item.id !== id));
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.to-manage')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const loadActions = (id_modulo, id_group) => {
    let n = 0;
    n = permissionsDataAll.filter(
      (item) => item.id_grupos === id_group && item.acciones?.modulos?.id === id_modulo
    ).length;
    return (
      <TableCell align="center">
        {n}
        <Tooltip title={t('buttons:tooltips.view-actions')}>
          <IconButton aria-label="detalles" onClick={(e) => viewActions(e, id_modulo, id_group)}>
            <FindInPageOutlined />
          </IconButton>
        </Tooltip>
      </TableCell>
    );
  };

  const viewActions = (e, modulo, grupo) => {
    setDialog(true);
    setActionsDialog(
      permissionsDataAll
        .filter((item) => item.id_grupos === grupo && item.acciones?.modulos?.id === modulo)
        .sort((a, b) => (a.acciones?.nombre.toLowerCase() < b.acciones?.nombre.toLowerCase() ? -1 : 1))
    );
  };

  const closeDialog = () => {
    setDialog(false);
  };

  const columns = () => {
    return t('tables:table.columns-permissions', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName="Permissions"
        items={permissionsData}
        setItems={setFiltradas}
        loadData={getPermissions}
        setLoading={setLoading}
        changePage={changePage}
      />
      <Table columns={columns()} rows={filtro} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      {row.gruposUsuarios?.nombre}
                      {row.gruposUsuarios?.id_organizaciones !== null
                        ? ` - ${row.gruposUsuarios?.organizaciones?.nombre}`
                        : ''}
                    </TableCell>
                    <TableCell align="center">{row.acciones?.modulos?.nombre}</TableCell>
                    {loadActions(row.acciones?.modulos?.id, row.id_grupos)}
                    <TableCell align="center">{edit_permission(row?.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableCell align="center" colSpan="5">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Dialog open={dialog} onClose={closeDialog} fullWidth={false} maxWidth={'xs'}>
        <DialogContent>
          {actionsDialog ? (
            <>
              <List>
                <ListItem>
                  <ListItemText>{t('forms:actions')}</ListItemText>
                </ListItem>
                <Divider />
                {actionsDialog.map((values) => (
                  <ListItem>
                    <ListItemText>{values.acciones?.nombre}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'permisos')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
