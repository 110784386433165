import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Link,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CircularProgress,
  Fade,
  IconButton,
  Dialog,
  Tooltip,
  Badge,
  Slide,
} from '@material-ui/core';
import {
  CheckCircleOutlineOutlined,
  HighlightOffOutlined,
  WarningAmberOutlined,
  VerticalAlignBottomOutlined,
  OpenInNewOutlined,
  CheckOutlined,
} from '@mui/icons-material';
import { ImageSearchOutlined, CloseOutlined } from '@mui/icons-material';
import moment from 'moment';
import { isBefore } from 'date-fns';
import colors from '../../assets/styles/colors';
import pdfProofLogo from '../../assets/img/logo_pdf.svg';
import chainpointLogo from '../../assets/img/chainpoint.png';
import opentimestampLogo from '../../assets/img/opentimestamps.png';
import { decrypt } from '../../utils/crypt';
import Appbar from '../../components/Appbar';
import Backdrop from '../../components/Backdrop';
import axios from '../../api';
import ViewPDF from '../../components/PreviewPdfDocument';
import { useTranslation } from 'react-i18next';

function DocumentVerify(props) {
  const classes = useStyles();
  const history = useHistory();
  const [myDocument, SetMyDocument] = useState({});
  const [verificationType] = useState(props.match.params?.verificationType);
  const [withNavBar, setWithNavBar] = useState(false);
  const [stateDocument, setStateDocument] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadIcon, setLoadIcon] = React.useState(true);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [signatureInfoDocument, setSignatureInfoDocument] = useState([]);

  const styles = JSON.parse(window.localStorage.state)?.styles;

  const [t] = useTranslation(['verify']);

  useEffect(() => {
    const isVerifying = verificationType && verificationType.length > 0;
    setWithNavBar(!isVerifying);
    getDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocument = async () => {
    setLoading(true);
    const documentId = decrypt(props.match.params.documentId);
    const { data } = await axios.get(`/documents/verifyDocuments/${documentId}`);
    SetMyDocument(data.document);
    setLoading(false);
    setTimeout(() => {
      setLoadIcon(false);
    }, 2000);
    data.document.document.documentosEmitidos?.estado === -1
      ? setStateDocument('Revoked')
      : data.document.document.documentosEmitidos?.fecha_expiracion !== null &&
        isBefore(new Date(data.document.document.documentosEmitidos?.fecha_expiracion), new Date())
      ? setStateDocument('Expired')
      : setStateDocument('Verified');

    await getAdditionalDocuments(documentId);
    await getSignatureInfoDocument(documentId);
  };

  const getAdditionalDocuments = async (idDocument) => {
    const { data } = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 1,
      id_documentos_emitidos: idDocument,
    });
    setAdditionalDocuments(data?.documentFieldAdditionals);
  };

  const getSignatureInfoDocument = async (idDocument) => {
    const { data } = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 3,
      id_documentos_emitidos: idDocument,
    });
    setSignatureInfoDocument(data?.documentFieldAdditionals);
  };

  const downloadOtsFile = (e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = myDocument.openTimestampFile
      ? myDocument.openTimestampFile
      : `https://app.woleet.io/api/receipt/${myDocument.document?.documentosEmitidos?.blockchain?.id_anchor}/ots`;
    link.download = myDocument.document?.documentosEmitidos?.blockchain?.hash_certificado;

    document.getElementById('root').appendChild(link);

    link.click();

    document.getElementById('root').removeChild(link);
  };

  const viewCarnet = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const iconVerified = () => {
    return <CheckCircleOutlineOutlined sx={{ color: colors.primary }} />;
  };

  const iconExpired = () => {
    return <WarningAmberOutlined sx={{ color: '#fced47' }} />;
  };

  const iconRevoked = () => {
    return <HighlightOffOutlined sx={{ color: '#d33' }} />;
  };

  return (
    <>
      {withNavBar ? (
        <Appbar />
      ) : (
        <IconButton className={classes.closeButton} onClick={() => history.push(`/verify/${verificationType}`)}>
          <CloseOutlined />
        </IconButton>
      )}
      <Container maxWidth={'lg'} className={classes.container}>
        <Grid container spacing={0} className={classes.root}>
          <Grid item xs={12} className={`text-center ${classes.grid}`}>
            {loadIcon === false ? (
              <>
                {stateDocument === 'Verified'
                  ? iconVerified()
                  : stateDocument === 'Revoked'
                  ? iconRevoked()
                  : iconExpired()}
                <Typography variant="h6" className={classes.textVerify}>
                  {stateDocument === 'Verified'
                    ? t('verify:verify-success')
                    : stateDocument === 'Revoked'
                    ? t('verify:message-document.document-revoked')
                    : t('verify:message-document.document-expired')}
                </Typography>
                <Typography className={classes.text}>
                  {stateDocument === 'Verified'
                    ? `${t('verify:message-document.document-issued')} ${styles.organizationName} ${t(
                        'verify:information'
                      )}`
                    : stateDocument === 'Revoked'
                    ? `${t('verify:message-document.document-issued')} ${styles.organizationName} ${t(
                        'verify:message-document.document-information-revoked'
                      )}`
                    : `${t('verify:message-document.document-issued')} ${styles.organizationName} ${t(
                        'verify:message-document.document-information-expired'
                      )}`}
                </Typography>
              </>
            ) : (
              <Fade
                in={loadIcon}
                style={{
                  transitionDelay: loadIcon ? '800ms' : '0ms',
                }}
                unmountOnExit
              >
                <CircularProgress size={50} className={classes.circularProgress} />
              </Fade>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={
                        loadIcon === false ? (
                          iconVerified()
                        ) : (
                          <Fade
                            in={loadIcon}
                            style={{
                              transitionDelay: loadIcon ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                          >
                            <CircularProgress size={30} className={classes.circularProgress} />
                          </Fade>
                        )
                      }
                      title={
                        <Typography className={classes.text}>{t('verify:message-document.document-name')}</Typography>
                      }
                    />
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={4} sm={2}>
                          <Tooltip title="Ver documento">
                            <Badge
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              badgeContent={<ImageSearchOutlined onClick={viewCarnet} />}
                            >
                              <img
                                aria-label="img_document"
                                className={classes.img_document}
                                src={myDocument.photoDocument}
                                onClick={viewCarnet}
                              />
                            </Badge>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={8} sm={10}>
                          <Grid container alignItems="center">
                            <Typography className={classes.textTitle}>
                              {myDocument.document?.documentosEmitidos?.nombre}
                            </Typography>
                            {loadIcon === false ? (
                              iconVerified()
                            ) : (
                              <Fade
                                in={loadIcon}
                                style={{
                                  transitionDelay: loadIcon ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                              >
                                <CircularProgress size={30} className={classes.circularProgress} />
                              </Fade>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {signatureInfoDocument.length > 0 && (
                  <Grid item xs={12}>
                    <Card variant="outlined" className={classes.cardBig}>
                      <CardHeader
                        className={classes.cardHeader}
                        avatar={
                          loadIcon === false ? (
                            iconVerified()
                          ) : (
                            <Fade
                              in={loadIcon}
                              style={{
                                transitionDelay: loadIcon ? '800ms' : '0ms',
                              }}
                              unmountOnExit
                            >
                              <CircularProgress size={30} className={classes.circularProgress} />
                            </Fade>
                          )
                        }
                        title={
                          <Typography className={classes.text}>
                            {t('verify:message-document.document-signature')}
                          </Typography>
                        }
                      />
                      {signatureInfoDocument.map((document) => (
                        <CardContent>
                          <Grid container alignItems="center">
                            <Grid item xs={12} className={classes.container_file_blockchain}>
                              <div className={classes.logo_fontawesome_blockchain}>
                                <img src={pdfProofLogo} alt="PDF Proof" width={35} />
                              </div>
                              <div>
                                <Typography className={classes.textTitle}>{document?.descripcion}</Typography>
                                <Link
                                  href={document?.linkPdf}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classes.link}
                                >
                                  {t('verify:blockchain.download')}{' '}
                                  <VerticalAlignBottomOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                                </Link>
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      ))}
                    </Card>
                  </Grid>
                )}

                {myDocument.document?.documentosEmitidos?.email_receptor && (
                  <Grid item xs={12}>
                    <Card variant="outlined" className={classes.card}>
                      <CardHeader
                        className={classes.cardHeader}
                        avatar={
                          loadIcon === false ? (
                            iconVerified()
                          ) : (
                            <Fade
                              in={loadIcon}
                              style={{
                                transitionDelay: loadIcon ? '800ms' : '0ms',
                              }}
                              unmountOnExit
                            >
                              <CircularProgress size={30} className={classes.circularProgress} />
                            </Fade>
                          )
                        }
                        title={
                          <Typography className={classes.text}>
                            {t('verify:message-document.document-headline')}
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid item xs={4} sm={2}>
                            <Avatar
                              aria-label="img_document"
                              className={classes.img_document}
                              src={myDocument.photoUser}
                            />
                          </Grid>
                          <Grid item xs={8} sm={10}>
                            <Typography className={classes.textTitle}>
                              {`${myDocument.document?.cuentas?.usuarios?.primer_nombre} ${
                                myDocument.document?.cuentas?.usuarios?.segundo_nombre || ''
                              } ${myDocument.document?.cuentas?.usuarios?.primer_apellido} ${
                                myDocument.document?.cuentas?.usuarios?.segundo_apellido || ''
                              }`}
                            </Typography>
                            <Link
                              href={`/dashboard/${myDocument.document?.cuentas?.usuarios?.url_perfil}`}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.link}
                            >
                              {t('verify:credentials')}{' '}
                              <OpenInNewOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                            </Link>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card variant="outlined" className={classes.cardBig}>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                    loadIcon === false ? (
                      iconVerified()
                    ) : (
                      <Fade
                        in={loadIcon}
                        style={{
                          transitionDelay: loadIcon ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                      >
                        <CircularProgress size={30} className={classes.circularProgress} />
                      </Fade>
                    )
                  }
                  title={
                    <Typography className={classes.text}>
                      {t('verify:issued-verified')} {styles.organizationName}.
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item xs={4} sm={2}>
                      <Avatar
                        variant="square"
                        aria-label="img_organization"
                        className={classes.img_org}
                        src={myDocument.photoOrganization}
                      />
                    </Grid>
                    <Grid item xs={8} sm={10}>
                      <Typography className={classes.textTitle}>
                        {myDocument.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre}
                      </Typography>
                      <Link
                        href={`/organization/${myDocument.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.url_perfil}`}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}
                      >
                        {t('verify:more-information')}{' '}
                        <OpenInNewOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container alignItems="center" className={classes.grid_dates}>
                    <Typography className={classes.textTitle}>{t('verify:certificate')}</Typography>
                    <Typography className={classes.text_dates}>
                      {moment(myDocument.document?.documentosEmitidos?.fecha_certificacion).format('DD-MM-YYYY')}
                    </Typography>
                    {loadIcon === false ? (
                      iconVerified()
                    ) : (
                      <Fade
                        in={loadIcon}
                        style={{
                          transitionDelay: loadIcon ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                      >
                        <CircularProgress size={30} className={classes.circularProgress} />
                      </Fade>
                    )}
                  </Grid>
                  {myDocument.document?.documentosEmitidos?.fecha_expiracion !== null &&
                    myDocument.document?.documentosEmitidos?.fecha_expiracion !== '0000-00-00' && (
                      <Grid container alignItems="center" className={classes.grid_dates}>
                        <Typography className={classes.textTitle}>{t('verify:expired')}</Typography>
                        <Typography className={classes.text_dates}>
                          {moment(myDocument.document?.documentosEmitidos?.fecha_expiracion).format('DD-MM-YYYY')}
                        </Typography>{' '}
                        {loadIcon === false ? (
                          <>{stateDocument === 'Expired' ? iconExpired() : iconVerified()}</>
                        ) : (
                          <Fade
                            in={loadIcon}
                            style={{
                              transitionDelay: loadIcon ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                          >
                            <CircularProgress size={30} className={classes.circularProgress} />
                          </Fade>
                        )}
                      </Grid>
                    )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Blockchain section */}
          {myDocument.document?.documentosEmitidos?.blockchain && (
            <Grid container spacing={2} className={classes.container__bitcoin}>
              <Grid
                item
                xs={12}
                md={myDocument.document?.documentosEmitidos?.blockchain?.estado_anchor !== 'CONFIRMED' ? 12 : 6}
              >
                <Card variant="outlined" className={classes.cardBig}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      loadIcon === false ? (
                        iconVerified()
                      ) : (
                        <Fade
                          in={loadIcon}
                          style={{
                            transitionDelay: loadIcon ? '800ms' : '0ms',
                          }}
                          unmountOnExit
                        >
                          <CircularProgress size={30} className={classes.circularProgress} />
                        </Fade>
                      )
                    }
                    title={
                      <Typography className={classes.text}>{t('verify:blockchain.information-blockchain')}</Typography>
                    }
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        {myDocument.document?.documentosEmitidos?.blockchain?.estado_anchor !== 'CONFIRMED' && (
                          <div className={classes.transaction}>
                            <p className={classes.textTitle}>{t('verify:blockchain.state-register')}:</p>
                            <p className={classes.textCodeTransaction}>{t('verify:blockchain.state-wait')}</p>
                          </div>
                        )}
                        {myDocument.document?.documentosEmitidos?.blockchain?.hash_certificado && (
                          <div className={classes.transaction}>
                            <p className={classes.textTitle}>{t('verify:blockchain.hash')}:</p>
                            <p className={classes.textCodeTransaction}>
                              {myDocument.document?.documentosEmitidos?.blockchain?.hash_certificado}
                            </p>
                          </div>
                        )}
                        {myDocument.document?.documentosEmitidos?.blockchain?.estado_anchor === 'CONFIRMED' && (
                          <>
                            {myDocument.document?.documentosEmitidos?.blockchain?.codigo_transaccion && (
                              <div className={classes.transaction}>
                                <p className={classes.textTitle}>txid:</p>
                                <p className={classes.textCodeTransaction}>
                                  {myDocument.document?.documentosEmitidos?.blockchain?.codigo_transaccion}
                                </p>
                              </div>
                            )}
                            {myDocument?.document?.documentosEmitidos?.blockchain?.altura_block && (
                              <div className={classes.transaction}>
                                <p className={classes.textTitle}>{t('verify:blockchain.block')}:</p>
                                <p className={classes.textCodeTransaction}>
                                  {myDocument.document?.documentosEmitidos?.blockchain?.altura_block}
                                </p>
                              </div>
                            )}
                            {myDocument.document?.documentosEmitidos?.blockchain?.merkle_root && (
                              <div className={classes.transaction}>
                                <p className={classes.textTitle}>Merkle root:</p>
                                <p className={classes.textCodeTransaction}>
                                  {myDocument.document?.documentosEmitidos?.blockchain?.merkle_root}
                                </p>
                              </div>
                            )}
                            <div className={classes.transaction}>
                              <p className={classes.textTitle}>{t('verify:blockchain.type-register')}:</p>
                              <p className={classes.textCodeTransaction}>
                                <span>BTCOpReturn</span>
                              </p>
                            </div>
                            <Grid container alignItems="center" className={classes.grid_dates}>
                              <Typography className={classes.textTitle}>
                                {t('verify:blockchain.date-signature')}:
                              </Typography>
                              <Typography className={classes.text_dates}>
                                {moment(
                                  myDocument.document?.documentosEmitidos?.blockchain?.fecha_firma_blockchain
                                ).format('DD-MM-YYYY hh:mm:ss a')}
                              </Typography>
                            </Grid>
                            {myDocument.document?.documentosEmitidos?.blockchain?.codigo_transaccion && (
                              <Link
                                href={`https://blockstream.info/tx/${myDocument.document?.documentosEmitidos?.blockchain?.codigo_transaccion}`}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.link}
                              >
                                {t('verify:blockchain.link')}{' '}
                                <OpenInNewOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                              </Link>
                            )}
                            {myDocument.document?.documentosEmitidos?.blockchain?.altura_block && (
                              <Link
                                href={`https://blockstream.info/block-height/${myDocument.document?.documentosEmitidos?.blockchain?.altura_block}`}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.link}
                              >
                                {t('verify:blockchain.link')}{' '}
                                <OpenInNewOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                              </Link>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {myDocument.document?.documentosEmitidos?.blockchain?.estado_anchor === 'CONFIRMED' && (
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" className={classes.cardBig}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={
                        loadIcon === false ? (
                          iconVerified()
                        ) : (
                          <Fade
                            in={loadIcon}
                            style={{
                              transitionDelay: loadIcon ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                          >
                            <CircularProgress size={30} className={classes.circularProgress} />
                          </Fade>
                        )
                      }
                      title={<Typography className={classes.text}>{t('verify:blockchain.files')}</Typography>}
                    />
                    {/* <CardContent>
                    <Grid container alignItems="center">
                      <Grid item xs={12} className={classes.container_file_blockchain}>
                        <div className={classes.logo_fontawesome_blockchain}>
                          <img src={pdfProofLogo} alt="PDF Proof" width={35} />
                        </div>
                        <div>
                          <Typography className={classes.textTitle}>Documento</Typography>
                          <Link href={document.pdfDocument} target="_blank" rel="noreferrer" className={classes.link}>
                            Descargar <VerticalAlignBottomOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent> */}

                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={12} className={classes.container_file_blockchain}>
                          <div className={classes.logo_fontawesome_blockchain}>
                            <img src={pdfProofLogo} alt="PDF Proof" width={35} />
                          </div>
                          <div>
                            <Typography className={classes.textTitle}>Proof</Typography>
                            <Link
                              href={myDocument.certikaProof}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.link}
                            >
                              {t('verify:blockchain.download')}{' '}
                              <VerticalAlignBottomOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {myDocument?.chainpoint && (
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid item xs={12} className={classes.container_file_blockchain}>
                            <Avatar
                              aria-label="img_organization"
                              className={classes.img_file_blockchain}
                              src={chainpointLogo}
                            />
                            <div>
                              <Typography className={classes.textTitle}>Chainpoint</Typography>
                              <Link
                                href={myDocument.chainpoint}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.link}
                              >
                                {t('verify:blockchain.download')}{' '}
                                <VerticalAlignBottomOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    )}
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={12} className={classes.container_file_blockchain}>
                          <Avatar
                            aria-label="img_organization"
                            className={classes.img_file_blockchain}
                            src={opentimestampLogo}
                          />
                          <div>
                            <Typography className={classes.textTitle}>Opentimestamp</Typography>
                            <Link href="" onClick={downloadOtsFile} className={classes.link}>
                              {t('verify:blockchain.download')}{' '}
                              <VerticalAlignBottomOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                            </Link>
                            <Link
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                window.open('https://opentimestamps.org/');
                              }}
                              className={classes.linkVerify}
                            >
                              {t('verify:blockchain.verify')}{' '}
                              <CheckOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}

          {additionalDocuments.length > 0 && (
            <Grid item xs={12} md={12} style={{ marginTop: '1em' }}>
              <Card variant="outlined" className={classes.cardBig}>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                    loadIcon === false ? (
                      iconVerified()
                    ) : (
                      <Fade
                        in={loadIcon}
                        style={{
                          transitionDelay: loadIcon ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                      >
                        <CircularProgress size={30} className={classes.circularProgress} />
                      </Fade>
                    )
                  }
                  title={<Typography className={classes.text}>{t('verify:additional-documents')}</Typography>}
                />
                {additionalDocuments.map((document) => (
                  <CardContent>
                    <Grid container alignItems="center">
                      <Grid item xs={12} className={classes.container_file_blockchain}>
                        <div className={classes.logo_fontawesome_blockchain}>
                          <img src={pdfProofLogo} alt="PDF Proof" width={35} />
                        </div>
                        <div>
                          <Typography className={classes.textTitle}>{document?.descripcion}</Typography>
                          <Link href={document?.linkPdf} target="_blank" rel="noreferrer" className={classes.link}>
                            {t('verify:blockchain.download')}{' '}
                            <VerticalAlignBottomOutlined sx={{ color: colors.primary, fontSize: 15 }} />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                ))}
              </Card>
            </Grid>
          )}

          <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <ViewPDF pdf={myDocument?.pdfDocument} download={false} handleClose={handleClose} fullView={true} />
          </Dialog>
        </Grid>
      </Container>
      <Backdrop loading={loading} />
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '1em',
  },
  img_document: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
    },
  },
  img_document_view: {
    maxWidth: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '-webkit-fill-available',
    },
  },
  img_org: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '& img': {
      height: 'min-content',
    },
  },
  img_verified: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  img_status_small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  img_security: {
    marginRight: '1em',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  textVerify: {
    fontWeight: 'bold',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
    margin: '1em 0 1em 0',
  },
  textCode: {
    color: '#808080',
    textAlign: 'left',
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.5em 3em',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: colors.primary,
  },
  linkVerify: {
    color: colors.primary,
    marginLeft: 20,
    '& svg': {
      width: 18,
    },
  },
  grid: {
    backgroundColor: colors.tertiary,
    borderRadius: '10px',
    padding: '1em',
    marginBottom: '1em',
  },
  card: {
    borderRadius: '10px',
    //borderColor: '#5c5c5c',
    padding: '.5em',
    backgroundColor: 'white',
    paddingBottom: 0,
  },
  cardBig: {
    borderRadius: '10px',
    //borderColor: '#5c5c5c',
    padding: '.5em',
    backgroundColor: 'white',
    height: '100%',
  },
  cardHeader: {
    padding: '.5em',
  },
  grid_dates: {
    padding: '.3em 0 .3em 0',
  },
  text_dates: {
    color: '#5c5c5c',
    marginLeft: '.5em',
  },
  circularProgress: {
    color: colors.primary,
  },
  container__bitcoin: {
    marginTop: 15,
  },
  container_file_blockchain: {
    display: 'flex',
    alignItems: 'center',
  },
  img_file_blockchain: {
    marginRight: 10,
  },
  logo_fontawesome_blockchain: {
    display: 'flex',
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  transaction: {
    display: 'flex',
    marginBottom: 5,
    '& p:first-child': {
      marginBottom: 0,
      marginRight: 5,
      minWidth: 90,
      width: 90,
    },
    '& span': {
      marginRight: 5,
      fontSize: '0.9em',
    },
  },
  textCodeTransaction: {
    wordBreak: 'break-word',
    margin: 0,
  },
  closeButton: {
    margin: '.5em .5em 0 0',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  btnClose: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default DocumentVerify;
