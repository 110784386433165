import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isBefore } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles, Container, Grid, Typography, Divider, Box, Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faClone } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/styles/colors';
import Appbar from '../../components/Appbar';
import axios from '../../api';
import Backdrop from '../../components/Backdrop';
import Revoked from '../../assets/img/icon_revocado.png';
import Expired from '../../assets/img/icon_expirado.png';
import VerifiedEn from '../../assets/img/icon_verified.png';
import RevokedEn from '../../assets/img/icon_revoked.png';
import ExpiredEn from '../../assets/img/icon_expired.png';
import { decrypt } from '../../utils/crypt';
import { useTranslation } from 'react-i18next';

function Certificate(props) {
  const classes = useStyles();
  const [badge, setBadge] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [skills, setSkills] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [stateBadge, setStateBadge] = useState('');
  const [organization, setOrganization] = useState({});
  const [Verified, setVerified] = useState('');
  const [photoOrganization, setPhotoOrganization] = useState(null);

  const LANGUAGE_EN = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setVerified(styles.icon_verificado);

    getBadge().catch(() => {
      props.history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBadge = async () => {
    setLoading(true);
    const issuedBadgeId = decrypt(props.match.params.issuedBadgeId);
    const { data } = await axios.get(`/badgesIssued/badges/${issuedBadgeId}`);
    setBadge(data?.issued);
    getLevel(data?.issued?.badge?.plantillasInsignias.nivel_vp);
    getTime(data?.issued?.badge?.plantillasInsignias.tiempo_vp);
    getCosts(data?.issued?.badge?.plantillasInsignias.costo_vp);
    setSkills(data?.issued?.badge?.plantillasInsignias?.plantillasHabilidades);
    setCriteria(data?.issued?.badge?.plantillasInsignias?.criterios);
    setOrganization(
      data?.issued?.badge.plantillasInsignias?.id_organizacion_hija !== null
        ? data?.issued?.badge.plantillasInsignias?.organizacionesHijas
        : data?.issued?.badge.plantillasInsignias?.organizaciones
    );
    setPhotoOrganization(data?.issued?.photoOrganization);
    data?.issued?.badge?.estado === 0
      ? setStateBadge('Revoked')
      : data?.issued?.badge?.fecha_expiracion !== null &&
        isBefore(new Date(data?.issued?.badge?.fecha_expiracion), new Date())
      ? setStateBadge('Expired')
      : setStateBadge('Verified');
    setLoading(false);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelBadge = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelBadge[0]);
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeBadge = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeBadge[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costBadge = data.costs.filter((data) => data.id === costo_vp);
    setCost(costBadge[0]);
  };

  if (loading) {
    return <Backdrop loading={loading} />;
  }

  return (
    <>
      <Appbar />
      <Container className={classes.container} maxWidth={'xl'}>
        <Grid container spacing={0} className={classes.root}>
          <Grid item xs={12} md={4} className={'text-center'}>
            <img alt="img-badge" src={badge?.photoBadge} className={classes.img} />
            <div>
              <Typography className={classes.textTitle}>{t('certificateBadgeTemplate:details.issued-by')}:</Typography>
              <div className="container_org">
                <a href={`/organization/${organization?.url_perfil}`} target="_blank" rel="noreferrer">
                  <img aria-label="img_organization" className={classes.img_org} src={photoOrganization} />
                </a>
              </div>
            </div>
            <div>
              <Typography className={classes.textBolder}>{t('certificateBadgeTemplate:details.issued')}:</Typography>
              <Typography className={classes.text}>
                {moment(badge?.badge?.fecha_emision).format('DD-MM-YYYY')}
              </Typography>
            </div>
            {badge?.badge?.fecha_expiracion !== null && badge?.badge?.fecha_expiracion !== '0000-00-00' && (
              <div>
                <Typography className={classes.textBolder}>
                  {t('certificateBadgeTemplate:details.expiration')}:
                </Typography>
                <Typography className={classes.text}>
                  {moment(badge?.badge?.fecha_expiracion).format('DD-MM-YYYY')}
                </Typography>
              </div>
            )}
            <div>
              <Typography className={classes.textBolder}>
                {t('certificateBadgeTemplate:details.badge-link')}:
              </Typography>
              <Box display="flex" justifyContent="center">
                <Box borderRadius={10} {...defaultProps} className={classes.containerButtonToCopy}>
                  <Typography className={classes.textCode}>
                    {`${window.location.origin}/badges/${props.match.params.issuedBadgeId}`}
                  </Typography>
                  <CopyToClipboard
                    text={`${window.location.origin}/badges/${props.match.params.issuedBadgeId}`}
                    onCopy={() => setCopiedLink(true)}
                  >
                    <FontAwesomeIcon icon={faClone} size="lg" />
                  </CopyToClipboard>
                </Box>
              </Box>
              {copiedLink && (
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.copied')}</Typography>
              )}
            </div>
            <div>
              <Typography className={classes.textBolder}>
                {t('certificateBadgeTemplate:details.badge-code')}:
              </Typography>
              <Box display="flex" justifyContent="center">
                <Box borderRadius={10} {...defaultProps} className={classes.containerButtonToCopy}>
                  <Typography className={classes.textCode}>{`${badge?.badge?.codigo_insignia}`}</Typography>
                  <CopyToClipboard text={`${badge?.badge?.codigo_insignia}`} onCopy={() => setCopiedCode(true)}>
                    <FontAwesomeIcon icon={faClone} size="lg" />
                  </CopyToClipboard>
                </Box>
              </Box>
              {copiedCode && (
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.copied')}</Typography>
              )}
            </div>
          </Grid>

          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={12} md={7}>
            <div className={classes.header}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Typography variant="h4" gutterBottom className={classes.textTitle}>
                      {badge?.badge?.plantillasInsignias?.nombre}
                    </Typography>
                    {LANGUAGE_EN ? (
                      <img
                        alt="img_verificado"
                        src={stateBadge === 'Verified' ? VerifiedEn : stateBadge === 'Revoked' ? RevokedEn : ExpiredEn}
                        className={classes.img_verificado}
                      />
                    ) : (
                      <img
                        alt="img_verificado"
                        src={stateBadge === 'Verified' ? Verified : stateBadge === 'Revoked' ? Revoked : Expired}
                        className={classes.img_verificado}
                      />
                    )}
                  </Grid>
                </Grid>
                {badge?.badge?.estado === 2 && badge?.badge?.insignias.length > 0 ? (
                  <Grid item xs={12} sm={3}>
                    <Link
                      href={`/certificates/verify/${props.match.params.issuedBadgeId}`}
                      target="_blank"
                      underline="none"
                      variant="inherit"
                      className={`my-2 ${classes.button}`}
                    >
                      {t('buttons:buttons.verify')}
                    </Link>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </div>

            <Typography className={classes.textParagraph} gutterBottom>
              {badge?.badge?.plantillasInsignias?.descripcion}
            </Typography>
            <Typography className={classes.text} gutterBottom>
              {t('certificateBadgeTemplate:details.coffered')}:
            </Typography>
            <Box display="flex">
              <Box borderRadius={10} {...defaultPropsUser}>
                {badge?.badge?.estado === 2 && badge?.badge?.insignias.length > 0 ? (
                  <Link
                    href={`/dashboard/${badge.badge?.insignias[0]?.cuentas?.usuarios?.url_perfil}`}
                    target="_blank"
                    underline="none"
                    className={classes.linkUser}
                  >
                    <Typography>
                      {`${badge?.badge?.primer_nombre_receptor?.toUpperCase()} ${
                        badge?.badge?.segundo_nombre_receptor?.toUpperCase() || ''
                      } ${badge?.badge?.primer_apellido_receptor?.toUpperCase()} ${
                        badge?.badge?.segundo_apellido_receptor?.toUpperCase() || ''
                      }`}
                    </Typography>
                  </Link>
                ) : (
                  <Typography>
                    {`${badge?.badge?.primer_nombre_receptor?.toUpperCase()} ${
                      badge?.badge?.segundo_nombre_receptor?.toUpperCase() || ''
                    } ${badge?.badge?.primer_apellido_receptor?.toUpperCase()} ${
                      badge?.badge?.segundo_apellido_receptor?.toUpperCase() || ''
                    }`}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography className={classes.textBolder} gutterBottom>
              {t('certificateBadgeTemplate:details.met-requirements')}:
            </Typography>
            {criteria.map((data, index) =>
              data.link === null || data.link === '' ? (
                <Typography key={`criteria${index}`} className={classes.text}>
                  {data.descripcion_criterio}
                </Typography>
              ) : (
                <a
                  href={data.link}
                  target="_blank"
                  rel="noreferrer"
                  key={`criteria${index}`}
                  className={classes.link}
                  gutterBottom
                >
                  {data.descripcion_criterio}
                </a>
              )
            )}

            {badge?.badge?.merito && (
              <>
                <Typography className={classes.textBolder} gutterBottom>
                  {t('certificateBadgeTemplate:details.merit')}:
                </Typography>
                <Box display="flex">
                  <Box borderRadius={10} {...defaultPropsUser}>
                    <Typography>{badge?.badge?.merito}</Typography>
                  </Box>
                </Box>
                {badge?.badge?.link_merito && (
                  <Link href={badge?.badge?.link_merito} target="_blank" rel="noreferrer" className={classes.link}>
                    {t('certificateBadgeTemplate:details.merit-link')}:
                    <FontAwesomeIcon icon={faShareSquare} size="xs" />
                  </Link>
                )}
              </>
            )}

            <Grid container spacing={2} align="center" className={classes.root}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {LANGUAGE_EN ? level.valor_parametro_en : level.valor_parametro}
                </Typography>
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.level')}</Typography>
              </Grid>
              {badge?.badge?.plantillasInsignias?.cantidad_tiempo !== null && time?.valor_parametro !== undefined ? (
                <Grid item xs={12} sm={4}>
                  <Typography variant="h5" className={classes.textTitle}>
                    {`${badge?.badge?.plantillasInsignias?.cantidad_tiempo} ${
                      LANGUAGE_EN ? time.valor_parametro_en : time.valor_parametro
                    }`}
                  </Typography>
                  <Typography className={classes.text}> {t('certificateBadgeTemplate:details.time')}</Typography>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {LANGUAGE_EN ? cost.valor_parametro_en : cost.valor_parametro}
                </Typography>
                <Typography className={classes.text}> {t('certificateBadgeTemplate:details.cost')}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.root}>
              <Grid item xs={12} sm={2}>
                <Typography className={classes.textBolder}>{t('certificateBadgeTemplate:details.skills')}:</Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid container>
                  {skills.map((data) => (
                    <Box key={data.id} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            {badge?.badge?.plantillasInsignias?.url && (
              <Link href={badge?.badge?.plantillasInsignias?.url} target="_blank" className={classes.link}>
                {t('certificateBadgeTemplate:details.additional')}:<FontAwesomeIcon icon={faShareSquare} size="xs" />
              </Link>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '1em',
  },
  img: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  img_verificado: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      height: theme.spacing(5),
    },
  },
  img_org: {
    width: 'auto',
    height: theme.spacing(6),
  },
  container_org: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
    margin: '1em 0 1em 0',
  },
  textCode: {
    color: '#808080',
    textAlign: 'left',
  },
  link: {
    color: colors.primary,
    textDecoration: 'underline',
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.5em 3em',
    '&:hover': {
      backgroundColor: colors.secondary,
      color: '#ffffff',
    },
  },
  containerButtonToCopy: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: colors.primary,
      marginLeft: 15,
      cursor: 'pointer',
    },
  },
  header: {
    marginTop: '3em',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0',
    },
  },
  divider: {
    marginRight: '2em',
  },
  linkUser: {
    color: '#ffffff',
    '&:hover': {
      color: '#ffffff',
    },
  },
}));

const defaultProps = {
  bgcolor: 'background.paper',
  borderColor: '#808080',
  m: 1,
  border: 1,
  padding: 1,
};

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

export default Certificate;
