import React, { useEffect, useRef } from 'react';
import { Group, Text, Transformer } from 'react-konva';

const FieldModules = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  layoutWidth,
  layoutHeight,
  isDraggable,
  handleOnDragStart,
  modifyField,
  index,
  form,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Group
        ref={shapeRef}
        onClick={onSelect}
        {...shapeProps}
        onDragStart={handleOnDragStart}
        draggable={isDraggable}
        dragBoundFunc={(pos, e) => {
          let newX = pos.x;
          let newY = pos.y;
          if (newX < 0) newX = 0;
          if (newY < 0) newY = 0;
          if (newX > layoutWidth - shapeProps.width) newX = layoutWidth - shapeProps.width;
          if (newY > layoutHeight - shapeProps.height) newY = layoutHeight - shapeProps.height;

          return {
            x: newX,
            y: newY,
          };
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          node.width(Math.max(5, node.width() * scaleX));
          node.height(Math.max(node.height() * scaleY));

          onChange({
            ...shapeProps,

            x: Math.round(node.x()),
            y: Math.round(node.y()),
            // set minimal value
            width: Math.round(node.width()),
            height: Math.round(node.height()),
          });
        }}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: Math.round(e.target.x()),
            y: Math.round(e.target.y()),
          });
        }}
        onDblClick={(e) => {
          modifyField(index);
        }}
      >
        <Text
          width={shapeProps.width}
          fill={shapeProps.fontColor?.hex}
          align={shapeProps.align}
          fontSize={shapeProps.fontSize}
          padding={shapeProps.fontSize}
          fontFamily={shapeProps.fontFamily.family}
          text={
            shapeProps.modulos
              ? shapeProps.modulos[0]?.titulo_modulo &&
                shapeProps.modulos
                  ?.map((modulo) => ` - ${modulo.titulo_modulo}`)
                  .toString()
                  .replaceAll(',', '\n\n')
              : shapeProps.exampleText
          }
        />
      </Group>
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            const minWidthHeight = 300;
            if (newBox.width < minWidthHeight) {
              return oldBox;
            }
            const maxWidthHeight = form.width - 200;
            if (newBox.width >= maxWidthHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default FieldModules;
