import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ModuleHeader from '../../components/ModuleHeader';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Button,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from '@material-ui/core';
import { FileDownloadOutlined, CloseOutlined } from '@mui/icons-material';
import colors from '../../assets/styles/colors';
import { Link } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';
import Table from '../../components/Table';
import { changePage } from '../../actions';
import axios from '../../api';
import Swal from 'sweetalert2';
import Alert from '@material-ui/lab/Alert';
import { isAfter, isExists } from 'date-fns';
import { changeBreadcrump, sortData, getComparator } from '../../actions';
import { encrypt } from '../../utils/crypt';
import { useTranslation } from 'react-i18next';
import capitalize from '../../utils/capitalize';
import ReceiverForm from '../../components/ReceiverForm';

const buttonRef = React.createRef();

function Issue(props) {
  const { page, rowsPerPage, permission, changePage, user, organizationId, changeBreadcrump, styles } = props;
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [dataIssued, setDataIssued] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [invalid, setInvalid] = useState([]);
  const [templateBadge, setTemplateBadge] = useState([]);
  //const [badgesIssued, setBadgesIssued] = useState([]);
  const [openBadges, setOpenBadges] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewEvery, setViewEvery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [form, setForm] = useState({
    nombre: '',
    id_organizaciones:
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ||
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 14
        ? organizationId
        : '0',
    file: null,
  });
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [encodingPlatform, setEncodingPlatform] = useState('');
  const [openForm, setOpenForm] = useState(false);

  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'certificateBadgeIssued', 'breadCrumps', 'dialogs']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await getTemplatesBadges();
    //await getBadgesIssued();
    await getOrganizaciones();
    changeBreadcrump(t('breadCrumps:breadCrumps.badge-issue', { returnObjects: true }));
    const platform = window.navigator?.userAgentData?.platform?.toLowerCase();
    const platformAlt = window.navigator?.platform?.toLowerCase();
    if (platform) {
      platform.includes('windows') ? setEncodingPlatform('ISO-8859-3') : setEncodingPlatform('UTF-8');
    } else {
      platformAlt.includes('win') ? setEncodingPlatform('ISO-8859-3') : setEncodingPlatform('UTF-8');
    }
  };

  const getTemplatesBadges = async () => {
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublished/names`);
    setTemplateBadge(data.templatesBadges);
  };

  /*   const getBadgesIssued = async () => {
    const { data } = await axios.post(`/badgesIssued/getBadgesIssued`);
    setBadgesIssued(data.issued.rows);
  }; */

  const getOrganizaciones = async () => {
    const { data } = await axios.post(`/organization/getOrganizations`);
    setOrganizations(data.organizations.rows);
  };

  const handleInput = (event) => {
    if (event.target.name === 'mensaje' && !event.target.value.includes('Hola (nombre usuario), ')) {
      return setForm({ ...form, [event.target.name]: 'Hola (nombre usuario), ' });
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const downloadTemplate = async () => {
    const { data } = await axios.post(`/file/getFile`, {
      keyFile: 'files/issue_template.csv',
    });

    window.open(data.url);
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data, file) => {
    const warningsA = [];
    const issued = [];
    const preIssued = [];
    const invalidA = [];
    // eslint-disable-next-line
    const email = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const link =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const id = /^([0-9]{6,12})+$/;
    const id_passport = /^([a-zA-Z0-9]{6,10})+$/;
    //const id_dni = /^[0-9]{8}[A-Z]$/i;
    const tel = /^([0-9]{8,15})+$/;
    const ind = /^([0-9]{0,5})+$/;
    const tipo_id = ['CC', 'CE', 'TI', 'PA', 'NIT', 'DNI', 'CI', 'DUI', 'DPI', 'CIE', 'CIC', 'CIP', 'ID', 'CURP'];
    const letters = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]+$/;
    const fields = [
      'id_plantilla',
      'email_receptor',
      'indicativo_pais',
      'whatsapp_receptor',
      'tipo_identificacion',
      'identificacion',
      'primer_nombre_receptor',
      'segundo_nombre_receptor',
      'primer_apellido_receptor',
      'segundo_apellido_receptor',
      'numero_celular',
      'numero_telegram',
      'merito',
      'link_merito',
      'fecha_expiracion',
    ];

    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      if (item.errors.length === 0 && item.meta.fields.length === 15) {
        if (JSON.stringify(item.meta.fields.sort()) === JSON.stringify(fields.sort())) {
          issued.push(item.data);
        }
      }
    });

    // eslint-disable-next-line array-callback-return
    issued.map((item, index) => {
      const repeatEmail = [];
      const repeatWhatsapp = [];
      // eslint-disable-next-line
      issued.map((data) => {
        data.id_plantilla === item.id_plantilla &&
          data.email_receptor === item.email_receptor &&
          repeatEmail.push(true);
        `${item.indicativo_pais}${item.whatsapp_receptor}` !== '' &&
          `${data.indicativo_pais}${data.whatsapp_receptor}` === `${item.indicativo_pais}${item.whatsapp_receptor}` &&
          repeatWhatsapp.push(true);
      });

      if (
        (item.id_plantilla.trim() !== '' &&
          item.email_receptor.trim() !== '' &&
          item.primer_nombre_receptor.trim() !== '' &&
          item.primer_apellido_receptor.trim() !== '') ||
        (item.tipo_identificacion === 'NIT' &&
          item.id_plantilla.trim() !== '' &&
          item.email_receptor.trim() !== '' &&
          item.identificacion.trim() !== '' &&
          item.primer_nombre_receptor.trim() !== '')
      ) {
        preIssued.push({
          index,
          ...item,
        });

        if (
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
        ) {
          if (!templateBadge.find((e) => e.codigo === item.id_plantilla.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.does-exit-template')} ${item.id_plantilla}`,
            });
          }
        } else {
          if (
            !templateBadge.find((e) => e.codigo === item.id_plantilla.trim() && e.id_organizaciones === organizationId)
          ) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.does-exit-template')} ${item.id_plantilla}`,
            });
          }
        }

        if (!letters.test(item.primer_nombre_receptor)) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.first-name-invalid')}`,
          });
        }

        if (item.segundo_nombre_receptor !== '') {
          if (!letters.test(item.segundo_nombre_receptor)) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.midle-name-invalid')}`,
            });
          }
        }

        if (!letters.test(item.primer_apellido_receptor) && item.tipo_identificacion !== 'NIT') {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.last-name-invalid')}`,
          });
        }

        if (item.segundo_apellido_receptor !== '') {
          if (!letters.test(item.segundo_apellido_receptor)) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.second-last-name-invalid')}`,
            });
          }
        }

        if (repeatEmail.length > 1) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.template-assigned')} ${item.id_plantilla} ${t('alerts:alerts.for-user')} ${
              item.email_receptor
            }`,
          });
        }

        if (repeatWhatsapp.length > 1) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.template-assigned')} ${item.id_plantilla} ${t('alerts:alerts.for-user')} ${
              item.indicativo_pais
            }${item.whatsapp_receptor}`,
          });
        }
        
        /* if (
          badgesIssued.find(
            (e) =>
              e.plantillasInsignias.codigo === item.id_plantilla.trim() &&
              e.email_receptor === item.email_receptor.trim()
          )
        ) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.template-issued')} ${item.id_plantilla} ${t('alerts:alerts.for-user')} ${
              item.email_receptor
            }`,
          });
        } */

        if (!email.test(item.email_receptor.trim())) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.email-invalid')}`,
          });
        }

        if (item.tipo_identificacion.trim() !== '') {
          if (!tipo_id.includes(item.tipo_identificacion.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.identification-type-invalid')}`,
            });
          }
        }

        if (item.tipo_identificacion.trim() !== '') {
          if (item.tipo_identificacion.trim() !== 'PA') {
            if (!id.test(item.identificacion.trim())) {
              warningsA.push({
                index,
                msg: `${t('alerts:alerts.identification-invalid')}`,
              });
            }
          } else {
            if (!id_passport.test(item.identificacion.trim())) {
              warningsA.push({
                index,
                msg: `${t('alerts:alerts.identification-invalid')}`,
              });
            }
          }
        }

        if (item.numero_celular.trim() !== '') {
          if (!tel.test(item.numero_celular.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.phone-number-invalid')}`,
            });
          }
        }

        if (item.indicativo_pais.trim() !== '') {
          if (!ind.test(item.indicativo_pais.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.indicative-invalid')}`,
            });
          }
        }

        if (item.whatsapp_receptor.trim() !== '') {
          if (!tel.test(item.whatsapp_receptor.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.whatsapp-invalid')}`,
            });
          }
          if (item.indicativo_pais.trim() === '') {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.indicative-invalid')}`,
            });
          }
        }

        if (item.link_merito.trim() !== '') {
          if (!link.test(item.link_merito.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.link-merit')}`,
            });
          } else {
            if (!item.link_merito.includes('https://') && !item.link_merito.includes('http://')) {
              item.link_merito = `https://${item.link_merito}`;
            }
          }
          if (item.merito === '') {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.empty-merit')}`,
            });
          }
        }

        if (item.fecha_expiracion.trim() !== '') {
          const dateArray = item.fecha_expiracion.trim().split('/');
          const formattedDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
          if (
            !isExists(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt(dateArray[0])) ||
            !isAfter(formattedDate, new Date())
          ) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.expiration-date-invalid')}`,
            });
          }
        }
      } else {
        invalidA.push(index + 2);
      }
    });

    const { data: contracts } = await axios.post(`/contract/validations/`, {
      id_organizaciones: form?.id_organizaciones,
      issued: preIssued,
    });

    const contract = contracts.contracts;
    if (contract.thereContrat) {
      const { id: idGroupUserOrg, id_grupos_usuarios } = user.data_user.gruposUsuariosOrganizaciones.find(
        (item) => item.id_organizaciones === organizationId
      );

      const isAnIssuer = id_grupos_usuarios === 4;
      if (isAnIssuer) {
        const { data: dataGroup } = await axios.get(`/userGroup/organizations/${idGroupUserOrg}`);
        const { limit_issued: limit, user_total_issueds: total_issued } = dataGroup.group;
        if (limit && preIssued.length > limit - total_issued) {
          return Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.exceeds-contract'),
            showConfirmButton: true,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          }).then(() => {
            handleRemoveFile();
          });
        }
      }
      if (contract.exceeds) {
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.exceeds-contract'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => {
          handleRemoveFile();
        });
      }
      if (contract.vencido) {
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.expired-contract'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => {
          handleRemoveFile();
        });
      }
    } else {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-contract-error'),
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      }).then(() => {
        handleRemoveFile();
      });
    }

    changePage(0);
    setOpenBadges(true);
    setRows(preIssued);
    setDataIssued(issued);
    setWarnings(warningsA);
    setInvalid(invalidA);
    setForm({ ...form, file: file });
  };

  const handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    setOpenBadges(false);
    setOpenDialog(false);
    setViewEvery(false);
    setRows([]);
    setWarnings([]);
    setForm({
      nombre: '',
      id_organizaciones:
        user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ||
        user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 14
          ? organizationId
          : '',
      file: null,
    });
  };

  const handlePrevSubmit = (e) => {
    e.preventDefault();
    setOpenBadges(false);
    setOpenDialog(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form?.mensaje && (form.mensaje.length < 60 || form.mensaje.length > 300)) {
      Swal.fire({
        title: 'Error',
        iconColor: colors.primary,
        text: t('alerts:alerts.message-size'),
        icon: 'error',
        timer: 4000,
        confirmButtonColor: colors.primary,
      });

      return false;
    }
    setOpenDialog(false);

    Swal.fire({
      text: `${t('alerts:alerts.button-emit-1')} ${dataIssued.length} ${t('alerts:alerts.button-emit-2')}`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: t('buttons:buttons.issue'),
      cancelButtonText: t('buttons:buttons.cancel'),
      denyButtonText: t('buttons:buttons.back'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
        denyButton: 'custom-button-back',
      },
    }).then(async (result) => {
      if (result.isDenied) {
        setOpenDialog(true);
      }
      if (result.isDismissed) {
        setForm({
          nombre: '',
          id_organizaciones:
            user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ||
            user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 14
              ? organizationId
              : '',
          file: null,
        });
        handleRemoveFile();
      }
      if (result.value) {
        setLoading(true);

        const datapost = {
          insignias: rows,
          nombre: form.nombre,
          mensaje: form.mensaje || `${t('alerts:alerts.message-issue-badge')}`,
          id_organizaciones: form.id_organizaciones,
          id_usuarios_emisores: user.data_user.id,
          archivo: form.file.name,
        };

        axios
          .post(`/badgesIssued/`, datapost)
          .then(({ data }) => {
            setLoading(false);
            handleRemoveFile();
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.badges-issued'),
              showConfirmButton: true,
              confirmButtonText: t('buttons:buttons.accept'),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'custom-button-confirm',
              },
            }).then(async () => {
              history.push(`/badgesIssued/badges/${encrypt(data.issuedId.groupId)}`);
              setForm({
                nombre: '',
                id_organizaciones:
                  user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ||
                  user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 14
                    ? organizationId
                    : '',
              });
            });
          })
          .catch((error) => {
            setLoading(false);
            handleRemoveFile();
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.template-issued-error'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const loadFillDataOrganizaciones = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const verTodos = () => {
    setViewEvery(true);
  };

  const columns = () => {
    return t('tables:table.columns-template-badge-issue', { returnObjects: true });
  };

  /* const handleDialogForm = () => {
    setOpenForm(true);
  }; */

  return (
    <>
      <ModuleHeader />
      <Card elevation={0} style={{ backgroundColor: 'transparent' }}>
        <CardContent>
          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.complete-badge')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.text-issued-1')} <b>{t('certificateBadgeIssued:issue.rows')} </b>
            {styles.organizationName === 'Certika'
              ? t('certificateBadgeIssued:issue.text-issued-2')
              : t('certificateBadgeIssued:issue.text-issued-2-min')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.access')}
            <Link to="/developers/template-ids/badges" className={classes.link}>
              {' '}
              {t('certificateBadgeIssued:issue.view-id')}
            </Link>
          </Typography>

          <CSVReader
            ref={buttonRef}
            onFileLoad={handleOnFileLoad}
            noClick
            noDrag
            progressBarColor={colors.primary}
            config={{ header: true, encoding: encodingPlatform, skipEmptyLines: true }}
          >
            {({ file }) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={handleOpenDialog}
                  disableElevation
                >
                  {t('buttons:buttons.choose-csv')}
                </Button>
                <div
                  style={{
                    borderWidth: 1,
                    height: 45,
                    lineHeight: 2.5,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingLeft: 13,
                    paddingTop: 3,
                    width: '60%',
                  }}
                >
                  {file && file.name}
                </div>
              </div>
            )}
          </CSVReader>

          {/* <Button onClick={handleDialogForm} color="primary" variant="contained" disableElevation>
            Agregar receptor
          </Button> */}

          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.uses-csv')}
          </Typography>
          <Link to="#" onClick={downloadTemplate} className={classes.link}>
            <Typography variant="body1" className={classes.text}>
              <FileDownloadOutlined />
              {t('certificateBadgeIssued:issue.dowload-template')}
            </Typography>
          </Link>
          {/* <Alert severity="info">"{t('alerts:alerts.info-template')}"</Alert> */}
        </CardContent>
      </Card>

      <Dialog open={openBadges} fullWidth={true} maxWidth={'xl'} scroll={'body'}>
        <Grid container justify="flex-end">
          <IconButton className={classes.closeButton} onClick={handleRemoveFile}>
            <CloseOutlined />
          </IconButton>
        </Grid>
        <DialogTitle>
          <div className={classes.alert}>
            <Alert severity="info">
              {t('alerts:alerts.preloaded')} {rows.length} {t('alerts:alerts.of')} {dataIssued.length}{' '}
              {t('alerts:alerts.rows')}
            </Alert>
            {rows.length === 0 && <Alert severity="error">{t('alerts:alerts.no-badge-issue')}</Alert>}
            {rows.length > 500 && <Alert severity="error">{t('alerts:alerts.exceeded-limit')}</Alert>}
            {invalid.length > 0 && (
              <Alert severity="error">
                {t('alerts:alerts.rows-2')} <b>{invalid.toString()}</b> {t('alerts:alerts.no-requirements')}
              </Alert>
            )}
            {warnings.length > 5 && !viewEvery ? (
              <>
                {warnings.map(
                  (data, index) =>
                    index < 5 && (
                      <div key={`row${index}`}>
                        <Alert severity="error">
                          <b>
                            {t('alerts:alerts.row')} {data.index + 2}:
                          </b>
                          {data.msg}
                        </Alert>
                      </div>
                    )
                )}
                <div>
                  <Alert severity="error">
                    <b>
                      5 {t('alerts:alerts.of')} {warnings.length} {t('alerts:alerts.errors')}.
                    </b>
                    <Button onClick={verTodos} className={classes.añadirButton}>
                      {t('buttons:buttons.see-all')}
                    </Button>
                  </Alert>
                </div>
              </>
            ) : (
              <>
                {warnings.map((data, index) => (
                  <div key={`row${index}`}>
                    <Alert severity="error">
                      <b>
                        {t('alerts:alerts.row')} {data.index + 2}:
                      </b>
                      {data.msg}.
                    </Alert>
                  </div>
                ))}
              </>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handlePrevSubmit}
            disabled={
              (rows.length !== dataIssued.length || warnings.length > 0 || rows.length === 0 || rows.length > 500) &&
              true
            }
            disableElevation
          >
            {t('buttons:buttons.issue')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handleRemoveFile}
            disableElevation
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </DialogTitle>

        <DialogContent dividers>
          {rows.length > 0 && (
            <Table columns={columns()} rows={rows} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
              <TableBody>
                {sortData(rows, getComparator(ordenDirection, valueToOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={`row${index}`}>
                      <TableCell align="center">
                        <b>{row.index + 2}</b>
                      </TableCell>
                      <TableCell align="center">{row.id_plantilla}</TableCell>
                      <TableCell align="center">{row.email_receptor}</TableCell>
                      <TableCell align="center">{row.indicativo_pais}</TableCell>
                      <TableCell align="center">{row.whatsapp_receptor}</TableCell>
                      <TableCell align="center">{row.tipo_identificacion}</TableCell>
                      <TableCell align="center">{row.identificacion}</TableCell>
                      <TableCell align="center">{row.primer_nombre_receptor}</TableCell>
                      <TableCell align="center">{row.segundo_nombre_receptor}</TableCell>
                      <TableCell align="center">{row.primer_apellido_receptor}</TableCell>
                      <TableCell align="center">{row.segundo_apellido_receptor}</TableCell>
                      <TableCell align="center">{row.numero_celular}</TableCell>
                      <TableCell align="center">{row.numero_telegram}</TableCell>
                      <TableCell align="center">{row.merito}</TableCell>
                      <TableCell align="center">{row.link_merito}</TableCell>
                      <TableCell align="center">{row.fecha_expiracion}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={openDialog} fullWidth={true} maxWidth={'sm'} style={{ zIndex: 100 }}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Typography gutterBottom>{t('dialogs:dialog.groups-issue-badges-name')}</Typography>
            <FormControl className="w-100">
              <TextField
                autoFocus
                required
                id="nombre"
                name="nombre"
                variant="outlined"
                onChange={handleInput}
                value={form.nombre}
              />
            </FormControl>
            <Typography gutterBottom>{t('dialogs:dialog.notification-message')}</Typography>
            <FormControl className="w-100">
              <TextField
                id="message"
                name="mensaje"
                variant="outlined"
                onChange={handleInput}
                value={form.mensaje || `${t('alerts:alerts.message-issue-badge')}.`}
                required
                multiline
              />
            </FormControl>
            {(user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
              user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14) && (
              <>
                <Typography gutterBottom style={{ marginTop: '1.5em' }}>
                  {t('dialogs:dialog.select-organization')} *
                </Typography>
                <FormControl required variant="outlined" className="w-100">
                  <Select
                    id="id_organizaciones"
                    name="id_organizaciones"
                    onChange={handleInput}
                    value={form.id_organizaciones}
                  >
                    <MenuItem>
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataOrganizaciones()}
                  </Select>
                </FormControl>
              </>
            )}
            <div className={`text-center ${classes.root}`} style={{ marginTop: '1.5em' }}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.issue')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleRemoveFile}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <ReceiverForm open={openForm} setOpen={setOpenForm} />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  button: {
    padding: '9px 40px',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    color: colors.primary,
    '&:hover': {
      color: colors.secondary,
    },
  },
  closeButton: {
    margin: '.5em .5em 0 0',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  añadirButton: {
    color: '#0056b3',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'none',
      textDecoration: 'underline',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'emitir')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Issue);
