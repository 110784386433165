import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { TableBody, TableCell, TableRow, Tooltip, IconButton, makeStyles, Avatar, Button } from '@material-ui/core';
import { FindInPageOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Table from '../../components/Table';
import ModuleHeader from '../../components/ModuleHeader';
import Search from '../../components/SearchBar';
import SkeletonTables from '../../components/SkeletonTable';
import axios from '../../api';
import { changeBreadcrump, changePage, sortData, getComparator } from '../../actions';
import moment from 'moment';
import { encrypt } from '../../utils/crypt';
import Swal from 'sweetalert2';
import exportToExcel from '../../utils/exportToExcel';
import colors from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  cellCopy: {
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      color: '#0056b3',
      backgroundColor: 'white',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  namePrivate: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function TemplateIDs(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user, organizationId, changePage } = props;
  const history = useHistory();
  const [templatesBadges, setTemplateBadges] = useState([]);
  const [filtro, setFiltradas] = useState(templatesBadges);
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(false);
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'breadCrumps']);

  useEffect(() => {
    changePage(0);
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    changeBreadcrump(t('breadCrumps:breadCrumps.ids-templates-badges', { returnObjects: true }));
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getTemplatesBadges();
    setLoading(false);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;
  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getTemplatesBadges = async () => {
    const params = getParams(0, rowsPerPage);
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublished`, params);
    if (isDeparmentCreater || isDeparmentCreaterRoll) {
      const deparmentCreaterData = data.templatesBadges?.rows.filter(
        (templateCertificate) =>
          templateCertificate.plantillasCertificadosHistorial[0].id_usuarios === user.data_user.id
      );
      setTemplateBadges(deparmentCreaterData);
      setFiltradas(deparmentCreaterData);
      setCountItems(deparmentCreaterData.length);
    } else {
      setTemplateBadges(data.templatesBadges?.rows);
      setFiltradas(data.templatesBadges?.rows);
      setCountItems(data.templatesBadges?.count);
    }
  };

  const toLink = (e, id) => {
    changePage(0);
    history.push(`/templates/published/details/${encrypt(id)}`);
  };

  const copiado = () => {
    Swal.fire({
      icon: 'success',
      iconColor: colors.primary,
      text: t('alerts:alerts.template-copy'),
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleExportToExcel = async () => {
    setLoading(true);

    const rows = filtro?.map((data) => {
      return {
        'Id Plantilla': data.codigo,
        'Nombre Público Plantilla': data.nombre,
        'Nombre Privado Plantilla': data.nombre_privado,
        Estado:
          data.estado === 1
            ? 'Publicada'
            : data.estado === 0
            ? 'En Edición'
            : data.estado === 2
            ? 'Archivada'
            : 'Eliminada',
        'Fecha de Creación': moment(data.fecha_creacion).format('DD/MM/YYYY HH:mm:ss'),
        'Última Actualización': moment(data.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm:ss'),
      };
    });

    exportToExcel(rows, 'ID Plantillas Insignias');
    setLoading(false);
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublished`, params);

    const array = [
      ...templatesBadges,
      ...data.templatesBadges?.rows.filter((row) => !templatesBadges.find((item) => item.id === row.id)),
    ];

    setTemplateBadges(array);
    setFiltradas(array);
    setLoading(false);
  };

  const columns = () => {
    return t('tables:table.columns-ids-templates-badges', { returnObjects: true });
  };
  
  const read_permission = () => {
    if (permission.includes('Leer')) {
      return (
        <>
          <Search
            tableName="templateBadge"
            type="published"
            items={templatesBadges}
            setItems={setFiltradas}
            setCountItems={setCountItems}
            changePage={changePage}
            loadData={loadData}
            organizationId={
              user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
              user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
                ? null
                : organizationId
            }
            deparmentCreater={{ isDeparmentCreater, isDeparmentCreaterRoll, userId: user.data_user.id }}
            setLoading={setLoading}
          />
          <Table
            columns={columns()}
            rows={countItems}
            setDirection={setOrdenDirection}
            setOrderBy={setValueToOrderBy}
            loading={loading}
            changePagination={changePagination}
          >
            <TableBody>
              {filtro.length > 0 ? (
                loading ? (
                  <>
                    <SkeletonTables columns={columns()} photo={1} />
                  </>
                ) : (
                  <>
                    {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <TableRow key={`row${row.id}`}>
                          <CopyToClipboard text={row.codigo} onCopy={copiado}>
                            <TableCell align="center" className={classes.cellCopy}>
                              {row.codigo}
                            </TableCell>
                          </CopyToClipboard>
                          <TableCell align="center">
                            <Avatar alt="Remy Sharp" src={row?.linkPhoto} className={classes.large} />
                            {row.nombre}
                            <p className={classes.namePrivate}>{row.nombre_privado}</p>
                          </TableCell>
                          <TableCell align="center">
                            {row.estado === 1 ? t('tables:table.state.published') : ' '}
                          </TableCell>
                          <TableCell align="center">{moment(row.fecha_creacion).format('DD/MM/YYYY HH:mm')}</TableCell>
                          <TableCell align="center">
                            {moment(row.fecha_ultima_modificacion).format('DD/MM/YYY HH:mm')}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title={t('tables:table.tooltip.view-template')}>
                              <IconButton aria-label="detalles" onClick={(e) => toLink(e, row.id)}>
                                <FindInPageOutlined />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )
              ) : loading ? (
                <SkeletonTables columns={columns()} photo={1} />
              ) : (
                <TableCell align="center" colSpan="6">
                  {t('tables:table.empty-table')}
                </TableCell>
              )}
            </TableBody>
          </Table>
        </>
      );
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
  };

  return (
    <>
      <ModuleHeader>
        {templatesBadges.length > 0 && (
          <Button className={classes.button} onClick={handleExportToExcel}>
            <Tooltip title={t('tables:table.tooltip.export')}>
              <FileDownloadOutlined />
            </Tooltip>
          </Button>
        )}
      </ModuleHeader>
      {read_permission()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'ids plantillas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateIDs);
