import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow, IconButton, Tooltip, Button } from '@material-ui/core';
import { DeleteForeverOutlined } from '@mui/icons-material';
import EditIcon from '../../../assets/static/icons/EditIcon';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import { decrypt, encrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import Swal from 'sweetalert2';
import moment from 'moment';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  container__avatar: {
    '& img': {
      height: 'min-content',
    },
  },
}));

function ContractOrganization(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user } = props;
  const history = useHistory();
  const [contracts, setContracts] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'menu']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });

    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await getContracts();
    setLoading(false);
  };

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    return params;
  };

  const getContracts = async () => {
    const params = getParams(0, rowsPerPage);
    const organizationsId = decrypt(props.match.params.organizations);
    const { data } = await axios.post(`/contract/getContracts`, { id_organizaciones: organizationsId, ...params });
    setContracts(data.contracts?.rows);
    setFiltradas(data.contracts?.rows);
    setCountItems(data.contracts?.count);

    const breadcrump = t('breadCrumps:breadCrumps.contracts-organization', { returnObjects: true });
    breadcrump[1].route =
      user?.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
      user?.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
        ? '/contracts'
        : window.reload;
    breadcrump.push({ name: data.contracts.count > 0 ? `${data.contracts.rows[0]?.organizaciones?.nombre}` : '' });
    changeBreadcrump(breadcrump);
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const organizationsId = decrypt(props.match.params.organizations);
    const { data } = await axios.post(`/contract/getContracts`, { id_organizaciones: organizationsId, ...params });

    const array = [
      ...contracts,
      ...data.contracts?.rows.filter((row) => !contracts.find((item) => item.id === row.id)),
    ];

    setContracts(array);
    setFiltradas(array);
    setLoading(false);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/contracts/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/contract/create`);
        break;
      default:
        break;
    }
  };

  const modalDelete = async (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-contract'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/contract/${id}`);
      setLoading(true);
      await loadData();
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.deleted'),
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.deleted-error'),
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const columns = () => {
    return t('tables:table.columns-contracts', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search items={contracts} setItems={setFiltradas} loadData={loadData} setLoading={setLoading} />
      <Table
        columns={columns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{row.planes?.nombre}</TableCell>
                    <TableCell align="center">{row.valor_estado}</TableCell>
                    <TableCell align="center">
                      {row.fecha_inicio && moment(row.fecha_inicio).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {row.vencimiento && moment(row.vencimiento).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {new Intl.NumberFormat('de-DE').format(row.cantidad_emisiones)}
                    </TableCell>
                    <TableCell align="center">
                      {new Intl.NumberFormat('de-DE').format(row.cantidad_insignias_emitidas)}
                    </TableCell>
                    <TableCell align="center">
                      {new Intl.NumberFormat('de-DE').format(row.cantidad_certificados_emitidos)}
                    </TableCell>
                    <TableCell align="center">
                      {new Intl.NumberFormat('de-DE').format(row.cantidad_carnets_emitidos)}
                    </TableCell>
                    <TableCell align="center">
                      {new Intl.NumberFormat('de-DE').format(row.cantidad_documentos_emitidos)}
                    </TableCell>
                    <TableCell align="center">{new Intl.NumberFormat('de-DE').format(row.total_emisiones)}</TableCell>
                    <TableCell align="center">
                      {new Intl.NumberFormat('de-DE').format(
                        parseInt(row.cantidad_emisiones) - parseInt(row.total_emisiones)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.estado === 1 || row.estado === 0 ? edit_permission(row.id) : ''}
                    </TableCell>
                    <TableCell align="center">
                      {row.estado === 1 || row.estado === 0 ? delete_permission(row.id) : ''}
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : (
            <TableCell align="center" colSpan="8">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    user: state.user,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'contratos')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractOrganization);
