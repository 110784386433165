import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemAvatar,
} from '@material-ui/core/';
import { FindInPageOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

function Users(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user, organizationId, superAdmin, changePage } = props;
  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [accountDialog, setAccountDialog] = useState([]);
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);

  const breadCrumps = () => {
    return t('breadCrumps:breadCrumps.settings-users', { returnObjects: true });
  };

  useEffect(() => {
    if (permission.includes('Leer')) {
      getUsers()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          history.push('/500');
          window.location.reload();
        });
      changeBreadcrump(breadCrumps());
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getUsers = async () => {
    const params = getParams(0, rowsPerPage);
    const { data } = await axios.post(`/user/getUsers`, params);
    setUsers(data.users?.rows);
    setFiltradas(data.users?.rows);
    setCountItems(data.users?.count);
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/user/getUsers`, params);

    const array = [...users, ...data.users?.rows.filter((row) => !users.find((item) => item.id === row.id))];

    setUsers(array);
    setFiltradas(array);
    setLoading(false);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/users/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/users/create`);
        break;
      case 'view':
        setDialog(true);
        setAccountDialog(filtro.filter((info) => info.id === id));
        break;
      case 'uncombine':
        modalUncombine(id);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-user'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/user/${id}`);
      setUsers(users.filter((item) => item.id !== id));
      setFiltradas(users.filter((item) => item.id !== id));
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.deleted-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const modalUncombine = (correo) => {
    Swal.fire({
      customClass: {
        container: classes.mySwal,
      },
      text: '¿Está seguro que desea descombinar este usuario?',
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        sendUncombine(correo);
      }
    });
  };

  const sendUncombine = async (correo) => {
    try {
      await axios.post(`/user/uncombine`, { correo: correo }).then(() => {
        Swal.fire({
          customClass: {
            container: classes.mySwal,
          },
          text: 'Cuentas descombinadas exitosamente.',
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      });
    } catch (error) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        text: 'No se pudo descombinar.',
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const closeDialog = () => {
    setDialog(false);
  };

  const userColumns = () => {
    if (superAdmin) {
      return t('tables:table.columns-users-super', { returnObjects: true });
    } else {
      return t('tables:table.columns-users', { returnObjects: true });
    }
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName="users"
        items={users}
        changePage={changePage}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        loadData={getUsers}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        userGroupId={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios}
      />
      <Table
        columns={userColumns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={userColumns()} photo={0} />
          ) : filtro.sort((a, b) => (a.id < b.id ? 1 : -1)).length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{`${row.primer_nombre} ${row.segundo_nombre || ''}`}</TableCell>
                    <TableCell align="center">{`${row.primer_apellido} ${row.segundo_apellido || ''}`}</TableCell>
                    <TableCell align="center">{row.cuentas[0]?.correo || ''}</TableCell>
                    <TableCell align="center">{row.gruposUsuariosOrganizaciones[0]?.gruposUsuarios?.nombre}</TableCell>
                    {superAdmin && (
                      <TableCell align="center">
                        {row.gruposUsuariosOrganizaciones[0]?.organizaciones?.nombre ||
                          t('tables:table.without-organization')}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {row.cuentas.length}
                      <Tooltip title={t('tables:table.tooltip.view-accounts')}>
                        <IconButton aria-label="view" onClick={(e) => handleClick(e, row.id, 'view')}>
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{edit_permission(row.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="6">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={dialog} onClose={closeDialog} fullWidth={false} maxWidth={'xs'}>
        <DialogContent>
          {accountDialog ? (
            <>
              <List>
                <ListItem>
                  <ListItemIcon>N°</ListItemIcon>
                  <ListItemText>{t('dialogs:dialog.accounts')}.</ListItemText>
                  <ListItemIcon />
                </ListItem>
                <Divider />
                {accountDialog.map((values) =>
                  values.cuentas.map((item, index) => (
                    <ListItem>
                      <ListItemAvatar>{index + 1}</ListItemAvatar>
                      <ListItemText>{item.correo}</ListItemText>
                      {superAdmin && (
                        <ListItemAvatar>
                          {index !== 0 && (
                            <Tooltip title="Descombinar">
                              <IconButton aria-label="delete" onClick={(e) => handleClick(e, item.correo, 'uncombine')}>
                                <DeleteForeverOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ListItemAvatar>
                      )}
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'usuarios')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    superAdmin: state.user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios.nombre === 'Super administrador',
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
