import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow, IconButton, Tooltip, Button } from '@material-ui/core/';
import { DeleteForeverOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function UserGroupsOrganization(props) {
  const { page, rowsPerPage, permission, changeBreadcrump, user, organizationId, changePage } = props;
  const classes = useStyles();
  const history = useHistory();
  const [userGroupsOrganization, setUserGroupsOrganization] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      getUserGroupsOrganization()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          history.push('/500');
          window.location.reload();
        });
      changeBreadcrump(t('breadCrumps:breadCrumps.user-groups-organizations', { returnObjects: true }));
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const isSuper = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ? true : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getUserGroupsOrganization = async () => {
    const params = getParams(0, rowsPerPage);

    const { data } = await axios.post(`/userGroup/organizations/getUserGroupsOrganization`, params);

    setUserGroupsOrganization(data.groups.rows);
    setFiltradas(data.groups.rows);
    setCountItems(data.groups?.count);
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/userGroup/organizations/getUserGroupsOrganization`, params);

    const array = [
      ...userGroupsOrganization,
      ...data.groups?.rows.filter((row) => !userGroupsOrganization.find((item) => item.id === row.id)),
    ];

    setUserGroupsOrganization(array);
    setFiltradas(array);
    setLoading(false);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/userGroupsOrganization/edit/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/userGroupsOrganization/create`);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-register'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { data } = await axios.delete(`/userGroup/organizations/${id}`);
      setUserGroupsOrganization(userGroupsOrganization.filter((item) => item.id !== id));
      setFiltradas(userGroupsOrganization.filter((item) => item.id !== id));
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.deleted-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const columns = () => {
    return t('tables:table.columns-user-groups-organizations', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName="userGroupsOrganization"
        items={userGroupsOrganization}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={getUserGroupsOrganization}
        organizationId={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ? null : organizationId}
        userGroupId={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios}
        setLoading={setLoading}
      />
      <Table
        columns={columns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{`${row.usuarios?.primer_nombre} ${row.usuarios?.primer_apellido}`}</TableCell>
                    <TableCell align="center">{row.organizaciones?.nombre}</TableCell>
                    <TableCell align="center">
                      {row.gruposUsuarios?.nombre}
                      {row.gruposUsuarios?.id_organizaciones !== null
                        ? ` - ${row.gruposUsuarios?.organizaciones?.nombre}`
                        : ''}
                    </TableCell>
                    <TableCell align="center">{edit_permission(row.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="9">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'grupos usuarios organizaciones')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupsOrganization);
