import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Avatar,
  Select,
  MenuItem,
  Dialog,
  Slide,
  Typography,
  Switch,
  FormControlLabel,
  Collapse,
  Box,
  withStyles,
  LinearProgress,
  DialogTitle,
  DialogContent,
  InputAdornment,
  Tooltip,
  IconButton,
  InputLabel,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import ModuleHeader from '../../components/ModuleHeader';
import Backdrop from '../../components/Backdrop';
import colors from '../../assets/styles/colors';
import axios from '../../api';
import { setFormDocuments, changeBreadcrump, setDocumentsFields } from '../../actions';
import defaultFields from '../../utils/defaultFields';
import { env } from '../../config/environment';
import { useTranslation } from 'react-i18next';
import TemplateDocumentDesing from './templates/DesignDocuments';
import { DropzoneArea } from 'material-ui-dropzone';
import capitalize from '../../utils/capitalize';
import dataURItoBlob from '../../utils/dataUriToBlob';
import moment from 'moment';
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

function Certify(props) {
  const {
    user,
    organizationId,
    formDocuments,
    setFormDocuments,
    documentsFields,
    setDocumentsFields,
    changeBreadcrump,
  } = props;
  const history = useHistory();
  const classes = useStyles({ formDocuments });
  const refImage = useRef([]);
  const refImageHD = useRef([]);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [templatesName, setTemplateNames] = useState([]);
  const [documentCollection, setDocumentCollection] = useState([]);
  const [openDesignDocuments, setOpenDesignDocuments] = useState(false);
  const [documentsChange, setDocumentsChange] = useState(
    formDocuments?.img_prev && formDocuments.img_prev[formDocuments?.page - 1] ? true : false
  );
  const isSuperUser =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14;
  const md5OrganizationRoute = md5(`certika-organization-${isSuperUser ? 1 : organizationId}`).toString();
  const md5UserRoute = md5(`certika-user-${isSuperUser ? 1 : user?.data_user?.id}`).toString();

  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);

  const [dataSend, setDataSend] = useState({ correo: '', whatsapp: '' });
  const [openSendToEmail, setOpenSendToEmail] = useState(false);
  const [openSendToWp, setOpenSendToWp] = useState(false);
  const [prefix, setPrefix] = useState({});
  const [countries, setCountries] = useState([]);

  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'breadCrumps',
    'tables',
    'certifyDocuments',
  ]);

  const [progressBar, setProgressBar] = useState(
    documentsChange
      ? {
          title: `${t('certifyDocuments:form-certify.uploaded-successfully')}`,
          nameFile: formDocuments?.fileName,
          load: true,
          value: 100,
          inProgress: false,
        }
      : { title: '', load: false, value: 0, inProgress: false }
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getCountries();
      await getOrganizations();
      await getDocumentsTemplateNames();
      await getDocumentsCollections();
      changeBreadcrump(t('breadCrumps:breadCrumps.documents-certify', { returnObjects: true }));
      setFormDocuments({
        ...formDocuments,
        id_organizaciones: !isSuperUser ? organizationId : '',
        change_template:
          formDocuments.id_plantillas_documentos && formDocuments.id_plantillas_documentos !== 0
            ? formDocuments.id_plantillas_documentos
            : 0,
      });
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  const getCountries = async () => {
    const { data } = await axios.post(`/others/getCountries`);
    setCountries(data.countries);
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations/names`);
    setOrganizations(data.organizations);
  };

  const getDocumentsTemplateNames = async () => {
    if (isSuperUser) {
      const { data } = await axios.post(`/documentTemplate/getDocumentTemplatesPublished/names`);
      setTemplateNames(data.documentTemplates);
    } else {
      const { data } = await axios.post(`/documentTemplate/getDocumentTemplatesPublished/names`, {
        id_organizaciones: organizationId,
      });
      setTemplateNames(data.documentTemplates);
    }
  };

  const getDocumentsCollections = async () => {
    const { data } = await axios.post(`/documentsCollections/getDocumentsCollections`, {
      id_organizaciones: organizationId,
    });
    setDocumentCollection(data.documentsCollections);
  };

  const getDocumentsTemplate = async (id) => {
    try {
      const { data } = await axios.get(`/documentTemplate/getDocumentTemplateFields/${id}`);
      setDocumentsFields(
        data.documentTemplate?.documentoCampos.map((field) => ({
          id: field.id,
          fieldType: field.tipo_campo_vp,
          exampleText: field.nombre.includes('certificateCode')
            ? field.texto_ejemplo.split(':')[0]
            : field.texto_ejemplo,
          idSignature: field.nombre.includes('signature') ? field.idSignature : null,
          fieldName: field.nombre,
          fieldTypeConfig: 51,
          align: field.formato_letra,
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width,
          height: field.height,
          page: field.page,
        }))
      );
    } catch (error) {
      history.push('/500');
    }
  };

  const handleInput = async (event) => {
    setFormDocuments({
      ...formDocuments,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputTemplate = async (event) => {
    setFormDocuments({
      ...formDocuments,
      id_plantillas_documentos: event.target.value,
      change_template: event.target.value,
      nameTemplate: event.target.value !== 0 ? templatesName.find((item) => item.id === event.target.value).nombre : '',
    });
  };

  const handleInputArchive = async (event) => {
    setFormDocuments({
      ...formDocuments,
      id_colecciones_documentos: event.target.value,
      nameArchive:
        event.target.value !== 0
          ? documentCollection.find((item) => item.id === event.target.value).nombre
          : formDocuments?.nombre_archivo,
    });
  };

  const handleCancel = async () => {
    setLoading(true);
    const infoDelete = new FormData();
    infoDelete.append('fileName', formDocuments?.fileName);
    infoDelete.append('numPages', formDocuments?.pages);
    infoDelete.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
    infoDelete.append('certify', true);
    await axiosFile({
      method: 'post',
      url: `${env.apiURL}/file/delete-files-local`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: infoDelete,
    });
    await axiosFile.get(`${env.apiURL}/file/getPdfForWhatsapp/delete`);
    setLoading(false);
    setDocumentsChange(false);
    setProgressBar({ title: '', load: false, value: 0, inProgress: false });
    setFormDocuments({ id_organizaciones: formDocuments.id_organizaciones });
    setDocumentsFields(null);
    refImage.current = [];
    refImageHD.current = [];
  };

  const validImage = async (byFormDocuments, file) => {
    setFormDocuments({ ...byFormDocuments });
    setProgressBar({
      title: `${t('certifyDocuments:form-certify.uploaded-successfully')}`,
      load: true,
      value: 100,
      inProgress: false,
      nameFile: file.name,
    });
    refImage.current = [];
    refImageHD.current = [];
    setDocumentsChange(true);
    setLoading(false);
  };

  const handleFile = async (e) => {
    const file = e[0];
    if (file) {
      const Document = new FormData();
      Document.append('file', file);
      Document.append('fileName', file.name);
      Document.append('certify', true);
      Document.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/file/upload-documents`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: Document,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressBar({
            title: `${t('certifyDocuments:form-certify.loading')}`,
            load: false,
            value: percentage,
            inProgress: true,
          });
        },
      }).then(({ data: resInfo }) => {
        const byFormDocuments = {
          ...formDocuments,
          img_prev: resInfo.urls,
          imgHD: resInfo.urlsHD,
          pages: resInfo.numPages,
          page: 1,
          fileName: file.name,
          nombre_documento: file.name.split('.')[0],
        };
        setTimeout(() => {
          validImage(byFormDocuments, file);
        }, 3000);
      });
    }
  };

  const validaDate = (fecha) => {
    let actualDate = new Date();
    let currentDate = fecha.split('-');
    let today = new Date();
    actualDate.setFullYear(currentDate[0], currentDate[1] - 1, currentDate[2]);
    if (actualDate >= today) {
      return false;
    } else {
      return true;
    }
  };

  const goToDesignDocuments = async () => {
    if (!formDocuments?.nombre_documento) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.required-documents-name'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!formDocuments.id_plantillas_documentos && formDocuments.id_plantillas_documentos !== 0) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-select-template-documents'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!formDocuments.id_colecciones_documentos && formDocuments.id_colecciones_documentos !== 0) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-select-folder-documents'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (formDocuments.id_plantillas_documentos !== 0) {
      formDocuments.checkSaveTemplate = false;
      await getDocumentsTemplate(formDocuments.id_plantillas_documentos);
    } else {
      if (formDocuments?.checkSaveTemplate && !formDocuments?.nombre_plantilla) {
        return Swal.fire({
          icon: 'info',
          iconColor: colors.primary,
          text: t('alerts:alerts.required-template-name'),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      const { dataQr, dataCode, dataButtonVerify } = defaultFields();
      !documentsFields &&
        setDocumentsFields([
          { ...dataQr, page: 1 },
          { ...dataCode, page: 1, fieldTypeConfig: 51 },
          { ...dataButtonVerify, page: 1 },
        ]);
    }
    if (formDocuments?.id_colecciones_documentos === 0 && !formDocuments?.nombre_archivo) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.required-folder-name'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setFormDocuments({
      ...formDocuments,
      naturalWidthAndHeight: refImage.current,
      naturalWidthAndHeightHD: refImageHD.current,
    });

    const indexField = documentsFields?.findIndex((item) => item.fieldName === 'certificateCode');
    if (indexField !== undefined && indexField !== -1) {
      documentsFields[indexField].exampleText = documentsFields[indexField].exampleText.includes(':')
        ? documentsFields[indexField].exampleText.split(':')[0]
        : '';
    }
    formDocuments.img_prev && formDocuments.img_prev[formDocuments?.page - 1] && setOpenDesignDocuments(true);
    setLoading(false);
  };

  const handleSubmit = async (e, fontsOptions) => {
    e.preventDefault();

    const uriSignature = [];

    if (formDocuments.id_organizaciones === 0) {
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-organization'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const haveUploadedAnImage = formDocuments.img_prev[formDocuments?.page - 1] ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const { data: contract } = await axios.get(
      `/contract/card/${isSuperUser ? organizationId : formDocuments.id_organizaciones}`
    );

    if (!contract.contract) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-contract-error'),
        showConfirmButton: true,
      });
      return false;
    }

    if (contract?.contract?.total_emisiones + 1 > contract?.contract?.cantidad_emisiones) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.exceeds-contract'),
        showConfirmButton: true,
      });
      return false;
    }

    if (contract?.contract?.vencimiento ? validaDate(contract?.contract?.vencimiento) : false) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.expired-contract'),
        showConfirmButton: true,
      });
      return false;
    }

    await documentsFields.map(async (item, index) => {
      if (item.fontColor) {
        item.fontColor = item.fontColor.hex;
      }
      if (item.rectColor) {
        item.rectColor = item.rectColor.hex;
      }
      if (item.correo) {
        const signatories = new FormData();
        const blobData = dataURItoBlob(item.exampleText);
        const routeSign = `${md5OrganizationRoute}/documentsTemplate/signatories/${item.correo}.png`;
        uriSignature.push({ index, uri: item.exampleText });
        item.exampleText = routeSign;

        signatories.append('file', blobData);
        signatories.append('route', routeSign);
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/upload-certificate`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: signatories,
        });
      }
      return item;
    });

    let realWidth = formDocuments.naturalWidthAndHeight[0].width;
    let realHeight = formDocuments.naturalWidthAndHeight[0].height;
    // eslint-disable-next-line
    formDocuments.naturalWidthAndHeight.map((item) => {
      if (realWidth < item.width) {
        realWidth = item.width;
      }
      if (realHeight < item.height) {
        realHeight = item.height;
      }
    });

    let realWidthHD = formDocuments.naturalWidthAndHeightHD[0].width;
    let realHeightHD = formDocuments.naturalWidthAndHeightHD[0].height;
    const sizes = [];
    // eslint-disable-next-line
    formDocuments.naturalWidthAndHeightHD.map((item) => {
      if (realWidthHD < item.width) {
        realWidthHD = item.width;
      }
      if (realHeightHD < item.height) {
        realHeightHD = item.height;
      }
      sizes.push({ width: item.width, height: item.height });
    });
    const saltosWidth = parseInt((100 * (realWidthHD - realWidth)) / realWidth);
    const saltosHeight = parseInt((100 * (realHeightHD - realHeight)) / realHeight);

    const trailDate = moment(new Date()).format('LLLL');

    const dataToSend = {
      ...formDocuments,
      width: realWidthHD,
      height: realHeightHD,
      templateWidth: realWidth,
      templateHeight: realHeight,
      saltosWidth: saltosWidth,
      saltosHeight: saltosHeight,
      sizes: sizes,
      trailDate: trailDate,
    };
    delete dataToSend.img_template;
    delete dataToSend.naturalWidthAndHeight;
    delete dataToSend.naturalWidthAndHeightHD;
    setLoading(true);

    try {
      await axios
        .post(`/documentsCertify`, {
          dataToSend,
          documentsFields,
        })
        .then((data) => {
          Array.prototype.forEach.call(fontsOptions, (updatedFont) => updatedFont.remove());
          setData(data);
          setOpenDesignDocuments(false);
          setLoading(false);
        });
    } catch (error) {
      documentsFields.map((item, index) => {
        if (item.fontColor) {
          item.fontColor = { hex: item.fontColor };
        }
        if (item.rectColor) {
          item.rectColor = { hex: item.rectColor };
        }
        if (item.fieldName === 'certificateCode' && item.exampleText.includes(':')) {
          item.exampleText = item.exampleText.split(':')[0];
        }
        if (item.correo) {
          item.exampleText = uriSignature.filter((item) => item.index === index)[0].uri;
        }
        return item;
      });
      setDocumentsFields(documentsFields);
      setLoading(false);
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    Swal.fire({
      customClass: { container: classes.mySwal },
      icon: 'success',
      iconColor: colors.primary,
      text: t('alerts:alerts.saved'),
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      if (documentsFields.filter((item) => item.correo).length > 0) {
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts.document-send-signatorie'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            container: classes.mySwal,
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => {
          handleCancel();
          history.push(`/documents/files`);
        });
      } else {
        setOpenDialog(true);
      }
    });
  };

  const downloadDocument = (link) => {
    let enlace = document.createElement('a');
    enlace.download = link;
    enlace.href = link;
    enlace.click();
  };

  const loadFillDataCollections = () => {
    return documentCollection.map((data) => {
      return (
        <MenuItem key={`collections-${data.id}`} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataTemplates = () => {
    return templatesName
      .filter((item) => !item.thereDynamicField)
      .map((data) => {
        return (
          <MenuItem key={`template-${data.id}`} value={data.id}>
            {data.nombre}
          </MenuItem>
        );
      });
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleChangeDocuments = async () => {
    setLoading(true);
    const infoDelete = new FormData();
    infoDelete.append('fileName', formDocuments?.fileName);
    infoDelete.append('numPages', formDocuments?.pages);
    infoDelete.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
    infoDelete.append('certify', true);
    await axiosFile({
      method: 'post',
      url: `${env.apiURL}/file/delete-files-local`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: infoDelete,
    });
    setLoading(false);
    setFormDocuments({
      ...formDocuments,
      img_prev: null,
      img_template: null,
      pages: 0,
      nombre_documento: '',
    });
    setProgressBar({ title: '', load: false, value: 0, inProgress: false });
    setDocumentsChange(false);
  };

  const handleCheckSaveTemplate = () => {
    setFormDocuments({ ...formDocuments, checkSaveTemplate: !formDocuments.checkSaveTemplate });
  };

  const addToRef = (el) => {
    if (el && !refImage.current.includes(el)) {
      refImage.current.push(el);
    }
  };

  const addToRefHD = (el) => {
    if (el && !refImageHD.current.includes(el)) {
      refImageHD.current.push(el);
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
    handleCancel();
    history.push(`/documents/files`);
  };

  const sendDocumentToEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const Document = new FormData();
    Document.append('id', data?.data?.reportes[0].id);
    Document.append('email_receptor', dataSend.correo);
    Document.append('nombre', formDocuments?.nombre_documento);
    Document.append('share', false);
    Document.append('sub', user.data_user.id);

    try {
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/share/sendDocumentsCertify`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: Document,
      }).then((response) => {
        if (response.status === 201) {
          setLoading(false);
          return Swal.fire({
            customClass: { container: classes.mySwal },
            text: t('alerts:alerts.error-ocurred'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        customClass: { container: classes.mySwal },
        text: t('alerts:alerts.error-ocurred'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 2000,
      });
    }

    setLoading(false);
    Swal.fire({
      customClass: { container: classes.mySwal },
      text: t('alerts:alerts.send-successfully'),
      icon: 'success',
      iconColor: colors.primary,
      showConfirmButton: false,
      timer: 2000,
    });
    setOpenSendToEmail(false);
    setDataSend({ ...dataSend, correo: '' });
  };

  const sendDocumentToWhatsapp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (dataSend?.whatsapp) {
      if (dataSend?.whatsapp.length < 10) {
        setLoading(false);
        return Swal.fire({
          customClass: { container: classes.mySwal },
          text: t('alerts:alerts.whatsapp-send-invalid'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      const Document = new FormData();
      Document.append('id', data?.data?.reportes[0].id);
      Document.append('whatsapp_receptor', `${prefix.prefijo_telefono}${dataSend?.whatsapp}`);
      Document.append('nombre', formDocuments?.nombre_documento);
      Document.append('share', false);
      Document.append('sub', user.data_user.id);
      try {
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/share/sendDocumentsCertifyWhatsapp`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: Document,
        });
      } catch (error) {
        setLoading(false);
        return Swal.fire({
          customClass: { container: classes.mySwal },
          text: t('alerts:alerts.error-ocurred'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      setDataSend({ ...dataSend, whatsapp: '' });
      setPrefix({});
      setLoading(false);
      setOpenSendToWp(false);
      return Swal.fire({
        customClass: { container: classes.mySwal },
        text: t('alerts:alerts.send-successfully'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleInputDataSend = (event) => {
    setDataSend({ ...dataSend, [event.target.name]: event.target.value });
  };

  const handleChangeCountry = async (event) => {
    if (event.target.value === '') {
      setDataSend({ ...dataSend, [event.target.name]: event.target.value });
      setPrefix('');
    } else {
      setDataSend({ ...dataSend, [event.target.name]: event.target.value });

      const prefixCountries = countries.find((country) => country.id === event.target.value);
      if (prefixCountries.prefijo_telefono) {
        setPrefix({
          ...prefix,
          country: event.target.value,
          prefijo_telefono: prefixCountries.prefijo_telefono,
        });
      }
    }
  };

  const loadFillDataCountries = () => {
    return countries.map((data) => {
      return (
        <MenuItem key={`countrie-${data.id}`} value={data.id}>
          {`${data.nombre}`.slice(0, data.nombre.length > 9 ? 4 : 3).toLocaleUpperCase()}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root}>
            <Grid container spacing={2} className={classes.root}>
              {!progressBar.inProgress && !progressBar.load && (
                <Grid item xs={12}>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={['.pdf']}
                    dropzoneText={t('certificateBadgeTemplate:badges-certificate-form.documents-dropzoneArea')}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass={classes.dropzoneParagraph}
                    showFileNames={false}
                    showAlerts={true}
                    onChange={handleFile}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  {formDocuments.img_prev &&
                    formDocuments.img_prev.map((item) => {
                      return <Avatar imgProps={{ ref: addToRef }} src={item} style={{ display: 'none' }}></Avatar>;
                    })}
                  {formDocuments.imgHD &&
                    formDocuments.imgHD.map((item) => {
                      return <Avatar imgProps={{ ref: addToRefHD }} src={item} style={{ display: 'none' }}></Avatar>;
                    })}
                </div>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={2}>
                  {(progressBar.inProgress || progressBar.load) && (
                    <Grid item xs={12}>
                      <Typography variant="body1">{progressBar.title}</Typography>
                      <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <BorderLinearProgress variant="determinate" value={progressBar.value} />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{`${Math.round(
                            progressBar.value
                          )}%`}</Typography>
                        </Box>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {progressBar?.nameFile}
                      </Typography>
                    </Grid>
                  )}
                  {documentsChange && (
                    <Grid item xs={6}>
                      <Button
                        disableElevation
                        color="primary"
                        variant="outlined"
                        component="span"
                        onClick={handleChangeDocuments}
                      >
                        {t('buttons:buttons.change-documents')}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography>{t('certifyDocuments:form-certify.name-document')}*</Typography>
                    <FormControl className="w-100">
                      <TextField
                        disabled={progressBar?.inProgress}
                        required
                        name="nombre_documento"
                        variant="outlined"
                        size="small"
                        onChange={handleInput}
                        value={formDocuments?.nombre_documento ? formDocuments?.nombre_documento : ''}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{t('certifyDocuments:form-certify.template-document')}</Typography>
                    <FormControl variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={progressBar?.inProgress}
                        required
                        id="plantilla"
                        name="id_plantillas_documentos"
                        onChange={handleInputTemplate}
                        value={
                          formDocuments.id_plantillas_documentos !== undefined
                            ? formDocuments.id_plantillas_documentos
                            : ''
                        }
                      >
                        <MenuItem key={`template-${0}`} value={0}>
                          <em>{t('certifyDocuments:form-certify.new-template-document')}</em>
                        </MenuItem>
                        {loadFillDataTemplates()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {formDocuments.id_plantillas_documentos === 0 && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {t('certifyDocuments:form-certify.message-new-template')}
                        </Typography>
                        <Collapse in={formDocuments.checkSaveTemplate}>
                          <>
                            <Typography>{t('certifyDocuments:form-certify.assign-name-template')}*</Typography>
                            <Grid item xs={12}>
                              <FormControl className="w-100">
                                <TextField
                                  disabled={progressBar?.inProgress}
                                  required={formDocuments.checkSaveTemplate}
                                  id="nombre_plantilla"
                                  name="nombre_plantilla"
                                  variant="outlined"
                                  size="small"
                                  onChange={handleInput}
                                  value={formDocuments.nombre_plantilla}
                                />
                              </FormControl>
                            </Grid>
                            <Typography>{t('certifyDocuments:form-certify.assign-name-private-template')}</Typography>
                            <Grid item xs={12}>
                              <FormControl className="w-100">
                                <TextField
                                  disabled={progressBar?.inProgress}
                                  id="nombre_privado_plantilla"
                                  name="nombre_privado_plantilla"
                                  variant="outlined"
                                  size="small"
                                  onChange={handleInput}
                                  value={formDocuments.nombre_privado_plantilla}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        </Collapse>
                        <Grid item xs={12} className={classes.checkbox}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={formDocuments.checkSaveTemplate}
                                onChange={() => handleCheckSaveTemplate()}
                              />
                            }
                            label={t('certifyDocuments:form-certify.switch-save-template')}
                            labelPlacement="start"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{t('certifyDocuments:form-certify.archive-document')}*</Typography>
                    <FormControl variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={progressBar?.inProgress}
                        required
                        id="archivo"
                        name="id_colecciones_documentos"
                        onChange={handleInputArchive}
                        value={
                          formDocuments.id_colecciones_documentos !== undefined
                            ? formDocuments.id_colecciones_documentos
                            : ''
                        }
                      >
                        <MenuItem value={0}>
                          <em>{t('certifyDocuments:form-certify.new-archive-document')}</em>
                        </MenuItem>
                        {loadFillDataCollections()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={formDocuments.id_colecciones_documentos === 0}>
                      <>
                        <Typography>{t('certifyDocuments:form-certify.assign-name-archive')}*</Typography>
                        <FormControl className="w-100">
                          <TextField
                            disabled={progressBar?.inProgress}
                            required={formDocuments.id_colecciones_documentos === 0}
                            id="nombre_archivo"
                            name="nombre_archivo"
                            variant="outlined"
                            size="small"
                            onChange={handleInput}
                            value={formDocuments.nombre_archivo}
                          />
                        </FormControl>
                      </>
                    </Collapse>
                  </Grid>

                  <Grid item xs={12}>
                    {(user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
                      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14) && (
                      <>
                        <Typography>{t('certificateBadgeTemplate:badges-certificate-form.organization')}*</Typography>
                        <FormControl required variant="outlined" className="w-100" size="small">
                          <Select
                            disabled={progressBar?.inProgress}
                            id="organizacion"
                            name="id_organizaciones"
                            onChange={handleInput}
                            value={formDocuments.id_organizaciones}
                            placeholder={t('certificateBadgeTemplate:badges-certificate-form.organization')}
                          >
                            <MenuItem value={0}>
                              <em>{t('selects:selects-autocompletes.select-option-documents')}</em>
                            </MenuItem>
                            {loadFillDataOrganization()}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                disabled={!progressBar.load}
                onClick={goToDesignDocuments}
              >
                {t('buttons:buttons.next')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      <Dialog open={openDesignDocuments} fullScreen TransitionComponent={Transition}>
        <TemplateDocumentDesing
          setOpenDesignDocuments={setOpenDesignDocuments}
          fromCertify={true}
          user={user}
          md5OrganizationRoute={md5OrganizationRoute}
          md5UserRoute={md5UserRoute}
          handleCancelGeneral={handleCancel}
          handleSubmit={handleSubmit}
        />
      </Dialog>

      <Dialog open={openDialog} fullWidth={true} maxWidth={'sm'} style={{ zIndex: 1000 }}>
        <DialogTitle className={`text-center`}>{t('dialogs:dialog.choose-option')}</DialogTitle>
        <DialogContent dividers>
          <div className={`text-center ${classes.containerButton}`}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={() => setOpenSendToEmail(!openSendToEmail)}
            >
              {t('buttons:buttons.send-document-email')}
            </Button>
            <form onSubmit={(e) => sendDocumentToEmail(e)}>
              <Collapse in={openSendToEmail}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    variant="outlined"
                    name="correo"
                    type="email"
                    placeholder="example@email.com"
                    value={dataSend.correo}
                    onChange={handleInputDataSend}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={t('buttons:buttons.send')} type="submit">
                            <IconButton size="small">
                              <SendIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('buttons:buttons.close')}>
                            <IconButton size="small" onClick={() => setOpenSendToEmail(false)}>
                              <CloseOutlinedIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Collapse>
            </form>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={() => setOpenSendToWp(!openSendToWp)}
            >
              {t('buttons:buttons.send-document-whatsapp')}
            </Button>
            <form onSubmit={(e) => sendDocumentToWhatsapp(e)}>
              <Collapse in={openSendToWp}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" className="w-100">
                      <InputLabel>País </InputLabel>
                      <Select
                        required
                        id="pais"
                        name="id_paises"
                        label="País"
                        onChange={handleChangeCountry}
                        value={prefix?.country || ''}
                      >
                        <MenuItem value="">
                          <em>Seleccione una opción</em>
                        </MenuItem>
                        {loadFillDataCountries()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <TextField
                      required
                      variant="outlined"
                      name="whatsapp"
                      type="number"
                      placeholder="3044579029"
                      value={dataSend.whatsapp}
                      onChange={handleInputDataSend}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WhatsAppIcon />
                            {`(+${prefix.prefijo_telefono ? prefix.prefijo_telefono : ''})`}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={t('buttons:buttons.send')}>
                              <IconButton size="small" type="submit">
                                <SendIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('buttons:buttons.close')}>
                              <IconButton size="small" onClick={() => setOpenSendToWp(false)}>
                                <CloseOutlinedIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </form>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={(e) => downloadDocument(data?.data?.routePdf)}
            >
              {t('buttons:buttons.download-documents')}
            </Button>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={closeDialog}
            >
              {t('buttons:buttons.exit')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Backdrop loading={loading} />
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  img: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
  buttonForm: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.primary,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  dropzoneParagraph: {
    color: 'gray',
    fontSize: '1.1em',
  },
  checkbox: { display: 'flex', justifyContent: 'flex-end' },
  containerButton: {
    display: 'grid',
    justifyContent: 'center',
  },
  button: {
    margin: '0.5em',
    padding: '10px 40px',
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    formDocuments: state.formDocuments || [],
    documentsFields: state.documentsFields,
    returnRoute: state.returnRoute,
  };
};

const mapDispatchToProps = {
  setDocumentsFields,
  changeBreadcrump,
  setFormDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Certify);
