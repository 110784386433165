import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Toolbar,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Divider } from '@mui/material';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import EditIcon from '@material-ui/icons/Edit';
import ButtonTextFieldIcon from '../../../assets/img/textField.png';
import ButtonPhotnoIcon from '@mui/icons-material/InsertPhotoOutlined';
import ButtonButtonsIcon from '@mui/icons-material/SmartButtonOutlined';
import ButtonSignatureIcon from '../../../assets/img/signature.png';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import webFont from 'webfontloader';
import Swal from 'sweetalert2';
import Appbar from '../../../components/Appbar';
import SketchPickerButton from '../../../components/SketchPickerButton';
import Diplomas from './Diplomas';
import Fields from './Fields';
import Dropdown from '../../../components/Dropdown';
import ButtonComponent from '../../../components/Button';
import onlyNumber from '../../../utils/validations/onlyNumber';
import onlyText from '../../../utils/validations/onlyText';
import hexToRGB from '../../../utils/hexToRGB';
import dataURItoBlob from '../../../utils/dataUriToBlob';
import colors from '../../../assets/styles/colors';
import { setDiplomaFields, setFormDiplomas, setSaveDesign } from '../../../actions';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import SignatureDrawing from '../../../components/SignatureDrawing';
import SignatureText from '../../../components/SignatureText';
import SignatureFile from '../../../components/SignatureFile';
import Signatories from '../../../components/Signatories';
import { env } from '../../../config/environment';
import Backdrop from '../../../components/Backdrop';

function DesignDocuments(props) {
  const {
    diplomaFields,
    setDiplomaFields,
    formDiplomas,
    setFormDiplomas,
    setSaveDesign,
    setOpenDesignDiploma,
    fromCertify,
    user,
    md5OrganizationRoute,
    md5UserRoute,
    handleCancelGeneral,
    fromSign,
    documentSigned,
    isEditDocuments,
  } = props;
  const classes = useStyle();
  const stageRef = useRef();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openSign, setOpenSign] = useState(false);
  const [openSignatories, setOpenSignatories] = useState(false);
  const [selectedId, selectShape] = useState(null);
  const [typeViewFields, setTypeViewFields] = useState(0);
  const [field, setField] = useState({
    fieldType: 41,
    fieldTypeConfig: 51,
    exampleText: '',
    fieldName: '',
    align: 'center',
    fontColor: { hex: '#000000' },
    fontSize: '18',
    fontFamily: {
      family: 'ABeeZee',
      files: {
        italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
        regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
      },
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 50,
    y: 50,
    width: 0,
    height: 0,
  });
  const indexFieldCode = diplomaFields.findIndex(
    (item) => item.fieldName === 'certificateCode' && item.page === formDiplomas?.page
  );
  const indexFieldButtonVerify = diplomaFields.findIndex((item) => item.fieldName === 'buttonVerify');
  const isFieldCode = field.fieldName === 'certificateCode' ? true : false;
  const isFieldButton = field.fieldName === 'buttonVerify' ? true : false;
  const fontsFamilysTypeHand = [
    'Caveat',
    'Caveat Brush',
    'Cookie',
    'Architects Daughter',
    'Cedarville Cursive',
    'Dawning of a New Day',
    'Crafty Girls',
    'League Script',
  ];
  const [fontFamilies, setFontFamilies] = useState(
    [...diplomaFields.map((field) => field.fontFamily?.family), 'ABeeZee', ...fontsFamilysTypeHand] || ['ABeeZee']
  );
  const [fontList, setFontList] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [fieldTypesConfig, setFieldTypesConfig] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [refField, setRefField] = useState(null);
  const [modifiedFieldIndex, setModifiedFieldIndex] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [progressBar, setProgressBar] = useState({});

  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons', 'dialogs', 'selects', 'tables', 'menu']);
  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  useEffect(() => {
    setLoading(true);

    if (isEditDocuments) {
      setSignatories(
        diplomaFields
          ?.filter(
            (field) => field.dataSign?.correo && field.dataSign?.correo !== user.correo && field.correo !== user.correo
          )
          ?.map((item) => {
            item.primer_nombre = item.dataSign ? item.dataSign.primer_nombre : item.primer_nombre;
            item.segundo_nombre = item.dataSign ? item.dataSign.segundo_nombre : item.segundo_nombre;
            item.primer_apellido = item.dataSign ? item.dataSign.primer_apellido : item.primer_apellido;
            item.segundo_apellido = item.dataSign ? item.dataSign.segundo_apellido : item.segundo_apellido;
            item.correo = item.dataSign ? item.dataSign.correo : item.correo;
            item.url = item.exampleText;
            return item;
          })
      );
    }

    api.get(`/others/getFieldTypes`).then(({ data }) => {
      setFieldTypesConfig(
        data.fieldTypes
          .filter((item) => item.id === 51 || item.id === 52)
          .map((fieldType) => ({
            id: fieldType.id,
            value: languajeEn ? fieldType.valor_parametro_en : fieldType.valor_parametro,
          }))
      );
      setFieldTypes(
        data.fieldTypes
          .filter((item) => item.id !== 51 && item.id !== 52)
          .map((fieldType) => ({
            id: fieldType.id,
            value: languajeEn ? fieldType.valor_parametro_en : fieldType.valor_parametro,
          }))
      );
    });

    if (fontFamilies.length > 0) {
      webFont.load({
        google: {
          families: fontFamilies,
        },
        active: () => {
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }

    axios
      .get(
        'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAiqPGRASVurt5YfOdCCDOljv0nqATgCSc&fields=items.family,items.files&sort=alpha'
      )
      .then(({ data }) => {
        setFontList(data.items);
      });

    if (user) {
      api.post(`/signature/getSignatures`).then(({ data }) => {
        setSignatures([...data?.signatures]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (e) => {
    if (formDiplomas.change_template && formDiplomas.change_template !== 0) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setOpen(true);
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      setOpen(true);
    }
  };

  const openModalSign = (e) => {
    if (formDiplomas.change_template && formDiplomas.change_template !== 0) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenSign(true);
          setValueTab(0);
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      setValueTab(0);
      setOpenSign(true);
    }
  };

  const openModalSignatories = (e) => {
    if (formDiplomas.change_template && formDiplomas.change_template !== 0) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenSignatories(true);
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else if (formDiplomas.id_plantillas_documentos === 0 && formDiplomas.checkSaveTemplate) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.signatories-no-template'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenSignatories(true);
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
            checkSaveTemplate: false,
          });
        }
      });
    } else {
      setOpenSignatories(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setField({
      fieldType: 41,
      fieldTypeConfig: 51,
      exampleText: '',
      fieldName: '',
      align: 'center',
      fontColor: { hex: '#000000' },
      fontSize: '18',
      fontFamily: {
        family: 'ABeeZee',
        files: {
          italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
          regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
        },
      },
      fontStyle: {
        bold: 0,
        italic: 0,
        underline: 0,
        strikethrough: 0,
      },
      x: 50,
      y: 50,
      width: 0,
      height: 0,
    });
  };

  const closeModalSign = () => {
    setOpenSign(false);
  };

  const closeModalSignatories = () => {
    setOpenSignatories(false);
  };

  const changeFontStyle = (fontStyle) => {
    setField({ ...field, fontStyle });
  };

  const changeColor = (color) => {
    setField({ ...field, fontColor: fromCertify ? { hex: color.hex } : color });
  };

  const changeColorButton = (color) => {
    setField({ ...field, rectColor: fromCertify ? { hex: color.hex } : color });
  };

  const handleChange = (e) => {
    if (e.target.name === 'fontSize' && e.target.value > 100) return;
    if (e.target.name === 'fieldTypeConfig') {
      return setField({ ...field, [e.target.name]: e.target.value, fieldName: '', align: 'center', width: 'auto' });
    }
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const handleChangeCode = (e) => {
    if (onlyText(e)) {
      setField({ ...field, [e.target.name]: e.target.value });
    }
  };

  const onChangeFontFamily = (fontFamily) => {
    if (fontFamily?.family && !fontFamilies.includes(fontFamily.family)) {
      const fonts = [...fontFamilies, fontFamily.family];
      setFontFamilies(fonts);

      webFont.load({
        google: {
          families: [fontFamily.family],
        },
        active: () => {
          setField({ ...field, fontFamily: fontFamily });
        },
      });
    } else {
      setField({ ...field, fontFamily: fontFamily });
    }
  };

  const validateRequiredProps = () => {
    if (isFieldCode || isFieldButton) {
      return false;
    }
    if (!field.exampleText?.length > 0 || !field.fontSize > 0 || !field.fontFamily) {
      return true;
    }
    if (
      field.fieldTypeConfig === 52 &&
      (!field.fieldName?.length > 0 ||
        !field.exampleText?.length > 0 ||
        !field.fontSize > 0 ||
        !field.fontFamily ||
        !field.fieldType)
    ) {
      return true;
    }

    return false;
  };

  const saveField = () => {
    if (validateRequiredProps()) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.certificate-all-fields'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    if (isFieldCode) field.exampleText = field.exampleText.trim();

    const isNew = !(field.index >= 0);
    if (isNew) {
      diplomaFields.push({
        ...field,
        page: formDiplomas?.page,
        width: 'auto',
        height: field?.fontSize,
      });
      setRefField(null);
    } else {
      const index = field.index;
      delete field.index;
      diplomaFields[index] = field;
      !isFieldButton && setModifiedFieldIndex(index);
    }

    selectShape(field.exampleText);
    !isFieldButton && setIsSelected(field.fieldTypeConfig === 51 ? false : true);

    setDiplomaFields([...diplomaFields]);
    setField({
      fieldType: 41,
      fieldTypeConfig: 51,
      exampleText: '',
      fieldName: '',
      align: 'center',
      fontColor: { hex: '#000000' },
      fontSize: '18',
      fontFamily: {
        family: 'ABeeZee',
        files: {
          italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
          regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
        },
      },
      fontStyle: {
        bold: 0,
        italic: 0,
        underline: 0,
        strikethrough: 0,
      },
      x: 50,
      y: 50,
      width: 0,
      height: 0,
    });
    setOpen(false);
  };

  const saveSign = async (stageRef) => {
    const uri = stageRef.current.toDataURL();
    const blobData = dataURItoBlob(uri);
    const signature = new FormData();

    const routeSign = `${md5OrganizationRoute}/documentsTemplate/signatures/${md5UserRoute}`;
    signature.append('file', blobData);
    signature.append('route', routeSign);
    signature.append('userId', user?.data_user?.id);
    try {
      const { data: signId } = await axios({
        method: 'post',
        url: `${env.apiURL}/file/upload-certificate`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: signature,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressBar({ value: percentage });
        },
      });
      setSignatures([...signatures, { url: signId?.url, id: signId?.id }]);
      setOpenSign(false);
      setProgressBar({});
    } catch (error) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        text: t('alerts:alerts.try-again'),
      });
      setOpenSign(false);
      setProgressBar({});
    }
  };

  const modifyField = (index) => {
    if (formDiplomas.change_template && formDiplomas.change_template !== 0) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          if (index === -1) {
            setField({
              ...diplomaFields[indexFieldCode],
              width:
                diplomaFields[indexFieldCode].fieldTypeConfig === 51 ? 'auto' : diplomaFields[indexFieldCode].width,
              index: indexFieldCode,
            });
          } else if (index === -3) {
            setField({ ...diplomaFields[indexFieldButtonVerify], index: indexFieldButtonVerify });
          } else {
            setField({
              ...diplomaFields[index],
              width: diplomaFields[index].fieldTypeConfig === 51 ? 'auto' : diplomaFields[index].width,
              index,
            });
          }
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
          setOpen(true);
        }
      });
    } else {
      if (index === -1) {
        setField({
          ...diplomaFields[indexFieldCode],
          width: diplomaFields[indexFieldCode].fieldTypeConfig === 51 ? 'auto' : diplomaFields[indexFieldCode].width,
          index: indexFieldCode,
        });
      } else if (index === -3) {
        setField({ ...diplomaFields[indexFieldButtonVerify], index: indexFieldButtonVerify });
      } else {
        setField({
          ...diplomaFields[index],
          width: diplomaFields[index].fieldTypeConfig === 51 ? 'auto' : diplomaFields[index].width,
          index,
        });
      }
      setOpen(true);
    }
  };

  const saveFields = async (e) => {
    if (fromSign && !diplomaFields.find((item) => item.fieldName.includes('signature'))) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.no-fields'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
    Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

    const fieldsTextNumber = diplomaFields.filter(
      (field) => (field.fieldType === 41 && field.fieldName !== 'certificateCode') || field.fieldType === 42
    );
    if (fieldsTextNumber.length <= 0) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.certificate-field'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    if (indexFieldCode !== undefined && indexFieldCode !== -1) {
      diplomaFields[indexFieldCode].exampleText = `${diplomaFields[indexFieldCode].exampleText}${
        diplomaFields[indexFieldCode].exampleText === '' ? '' : ':'
      }CK09aABbAz`;
    }

    const uri = stageRef?.current?.toDataURL();
    let uris = formDiplomas?.img_template || [];
    if (uris.length === formDiplomas?.pages) {
      uris[formDiplomas?.page - 1] = uri;
    } else {
      uris.push(uri);
    }
    if (formDiplomas?.pages > 1) {
      for (let i = 0; i < formDiplomas?.pages; i++) {
        if (!uris[i]) {
          uris = null;
          return Swal.fire({
            icon: 'info',
            text: t('alerts:alerts.certificate-no-design-page'),
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: classes.sweetModal,
            },
          });
        }
      }
    }

    setFormDiplomas({ ...formDiplomas, img_template: uris });
    setSaveDesign(true);
    setOpenDesignDiploma(false);
  };

  const handleCancel = () => {
    if (documentSigned) {
      const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
      Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

      if (indexFieldCode !== undefined && indexFieldCode !== -1) {
        diplomaFields[indexFieldCode].exampleText = `${diplomaFields[indexFieldCode].exampleText}${
          diplomaFields[indexFieldCode].exampleText === '' ? '' : ':'
        }CK09aABbAz`;
      }
      handleCancelGeneral();
      setOpenDesignDiploma(false);
    } else if (fromCertify) {
      Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'info',
        text: t('alerts:alerts.cancel-progress'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
          Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

          if (indexFieldCode !== undefined && indexFieldCode !== -1) {
            diplomaFields[indexFieldCode].exampleText = `${diplomaFields[indexFieldCode].exampleText}${
              diplomaFields[indexFieldCode].exampleText === '' ? '' : ':'
            }CK09aABbAz`;
          }
          handleCancelGeneral();
          setOpenDesignDiploma(false);
        }
      });
    } else {
      const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
      Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

      if (indexFieldCode !== undefined && indexFieldCode !== -1) {
        diplomaFields[indexFieldCode].exampleText = `${diplomaFields[indexFieldCode].exampleText}${
          diplomaFields[indexFieldCode].exampleText === '' ? '' : ':'
        }CK09aABbAz`;
      }
      setOpenDesignDiploma(false);
    }
  };

  const handleEdit = () => {
    if (indexFieldCode !== undefined && indexFieldCode !== -1) {
      diplomaFields[indexFieldCode].exampleText = `${diplomaFields[indexFieldCode].exampleText}${
        diplomaFields[indexFieldCode].exampleText === '' ? '' : ':'
      }CK09aABbAz`;
    }
    setOpenDesignDiploma(false);
  };

  const validateBGColor = (hex) => {
    const rgb = hexToRGB(hex);
    return rgb.r > 230 && rgb.g > 230 && rgb.b > 230 ? classes.dialog__title : '';
  };

  const onSelectShape = (shapeId) => {
    if (shapeId === 'X') {
      return setDiplomaFields([
        ...diplomaFields.filter(
          (item) => item.fieldName !== selectedId && item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'
        ),
      ]);
    }
    if (shapeId === '+') {
      const indexDelete = diplomaFields.findIndex((item) => item.fieldName === 'deleteSign');
      const indexAdd = diplomaFields.findIndex((item) => item.fieldName === 'addSign');
      const usedSignature = diplomaFields.filter((item) => item.fieldName.includes('signature'));
      const indexCurrentField = diplomaFields.findIndex(
        (item) => item.fieldName === selectedId && item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'
      );
      if (
        diplomaFields[indexCurrentField].y + diplomaFields[indexCurrentField].height + 20 >
        formDiplomas?.naturalWidthAndHeight[0].height
      ) {
        diplomaFields.push({
          ...diplomaFields[indexCurrentField],
          y: diplomaFields[indexCurrentField].y - diplomaFields[indexCurrentField].height - 20,
          fieldName: `${diplomaFields[indexCurrentField].fieldName.split('_')[0]}_${usedSignature.length + 1}`,
        });
        diplomaFields[indexDelete].y = diplomaFields[indexDelete].y - diplomaFields[indexCurrentField].height - 20;
        diplomaFields[indexAdd].y = diplomaFields[indexAdd].y - diplomaFields[indexCurrentField].height - 20;
      } else {
        diplomaFields.push({
          ...diplomaFields[indexCurrentField],
          y: diplomaFields[indexCurrentField].y + diplomaFields[indexCurrentField].height + 20,
          fieldName: `${diplomaFields[indexCurrentField].fieldName.split('_')[0]}_${usedSignature.length + 1}`,
        });
        diplomaFields[indexDelete].y = diplomaFields[indexDelete].y + diplomaFields[indexCurrentField].height + 20;
        diplomaFields[indexAdd].y = diplomaFields[indexAdd].y + diplomaFields[indexCurrentField].height + 20;
      }
      selectShape(`${diplomaFields[indexCurrentField].fieldName.split('_')[0]}_${usedSignature.length + 1}`);
      return setDiplomaFields([...diplomaFields]);
    }
    const signField = diplomaFields.find((item) => item.fieldName === shapeId && item.fieldName.includes('signature'));
    const iconDelete = diplomaFields.find((item) => item.fieldName === 'deleteSign');
    if (signField && !iconDelete) {
      diplomaFields.push({
        ...signField,
        fieldType: 41,
        fieldTypeConfig: 52,
        fontSize: '18',
        fieldName: 'deleteSign',
        exampleText: 'X',
        x: signField.x + 20,
        y: signField.y - 20,
        width: 23,
        height: 32,
        fontStyle: { bold: 1 },
      });
      diplomaFields.push({
        ...signField,
        fieldType: 41,
        fieldTypeConfig: 52,
        fontSize: '18',
        fieldName: 'addSign',
        exampleText: '+',
        x: signField.x + 5,
        y: signField.y - 20,
        width: 23,
        height: 32,
        fontStyle: { bold: 1 },
      });
      setDiplomaFields([...diplomaFields]);
    }
    if (iconDelete) {
      setDiplomaFields([
        ...diplomaFields.filter((item) => item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'),
      ]);
    }
    if (shapeId && formDiplomas.change_template && formDiplomas.change_template !== 0) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          selectShape(shapeId);
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      selectShape(shapeId);
    }
  };

  const handleChangePage = (event, value) => {
    setFormDiplomas({
      ...formDiplomas,
      page: value,
    });
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  // eslint-disable-next-line no-unused-vars
  const prehandleCancel = () => {
    Swal.fire({
      customClass: {
        container: classes.mySwal,
      },
      text: t('alerts:alerts.exit'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: t('buttons:buttons.exit'),
      cancelButtonText: t('buttons:buttons.cancel'),
    }).then(async (result) => {
      if (result.value) {
        handleCancel();
      }
    });
  };

  const changeViewFields = async (type, isSign) => {
    if (isSign && !formDiplomas?.ip_adress) {
      await getIp();
    }
    setTypeViewFields(type);
  };

  const getIp = async () => {
    try {
      const { data: ipData } = await axios({
        method: 'get',
        url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
      });
      setFormDiplomas({ ...formDiplomas, ip_adress: ipData.ip });
    } catch (error) {
      return Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'warning',
        text: t('alerts:alerts.warning-block'),
        showConfirmButton: true,
      });
    }
  };

  return (
    <>
      <Appbar />
      <div style={{ backgroundColor: '#E5E5E5' }}>
        <Toolbar className={classes.container_info}>
          <Grid item xs={12} style={{ display: 'inline-flex', alignItems: 'center', height: '50px' }}>
            <Typography align="left">{t('certificateBadgeTemplate:diplomas-design.title-design')}</Typography>
            {(formDiplomas?.nameTemplate || formDiplomas?.checkSaveTemplate) && (
              <>
                <Typography style={{ fontWeight: 'bold', margin: '0 3px 0 3px' }}>/</Typography>
                <Typography align="left" style={{ fontWeight: 'bold' }}>
                  {`${formDiplomas?.nameTemplate || formDiplomas?.nombre_plantilla}`}
                </Typography>
              </>
            )}
            <Typography style={{ fontWeight: 'bold', margin: '0 3px 0 3px' }}>{fromCertify && '/'}</Typography>
            <Typography align="left" style={{ fontWeight: 'bold' }}>
              {`${formDiplomas?.nameArchive || formDiplomas?.nombre_archivo || ''}`}
            </Typography>

            <Divider orientation="vertical" sx={{ height: '25px', margin: '0 1rem 0 1rem', backgroundColor: '#000' }} />

            <img
              src={ButtonTextFieldIcon}
              style={typeViewFields === 0 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
              alt="textField"
              onClick={() => changeViewFields(0, true)}
            />
            <ButtonPhotnoIcon
              sx={typeViewFields === 1 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
              onClick={() => changeViewFields(1, true)}
            />
            <ButtonButtonsIcon
              sx={typeViewFields === 2 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
              onClick={() => changeViewFields(2, true)}
            />
            {fromSign && (
              <img
                src={ButtonSignatureIcon}
                style={typeViewFields === 3 ? useStyleMUI.iconsButtonsActive : useStyleMUI.iconsButtons}
                alt="Signatue"
                onClick={() => changeViewFields(3, true)}
              />
            )}
          </Grid>

          <Tooltip
            title={
              fromSign
                ? isEditDocuments
                  ? t('buttons:buttons.edit')
                  : t('menu:sign')
                : fromCertify
                ? t('buttons:buttons.finish')
                : t('buttons:buttons.accept')
            }
          >
            <IconButton className={classes.buttonClose} disabled={documentSigned} onClick={saveFields}>
              <CheckOutlinedIcon />
            </IconButton>
          </Tooltip>
          {fromCertify && (
            <Tooltip title={t('tables:table.tooltip.edit-config')}>
              <IconButton className={classes.buttonClose} disabled={documentSigned} onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('buttons:buttons.cancel')}>
            <IconButton className={classes.buttonClose} disabled={documentSigned} onClick={handleCancel}>
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </div>
      <div className={classes.container}>
        <div className={classes.container__certificate}>
          <Diplomas
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            selectedId={selectedId}
            selectShape={onSelectShape}
            stageRef={stageRef}
            modifyField={modifyField}
            handleChangePage={handleChangePage}
            refField={refField}
            setRefField={setRefField}
            modifiedFieldIndex={modifiedFieldIndex}
            setModifiedFieldIndex={setModifiedFieldIndex}
            documentSigned={documentSigned}
          />
        </div>
        <div className={classes.container__fields}>
          {documentSigned ? (
            <></>
          ) : (
            <>
              <Fields
                openModal={openModal}
                openModalSign={openModalSign}
                openModalSignatories={openModalSignatories}
                modifyField={modifyField}
                signatures={signatures}
                setSignatures={setSignatures}
                fromCertify={fromCertify}
                fromSign={fromSign}
                typeViewFields={typeViewFields}
              />
            </>
          )}
        </div>
      </div>
      <Dialog open={open} onClose={closeModal} fullWidth={true} maxWidth="sm">
        <DialogTitle
          align="center"
          className={!isFieldButton && validateBGColor(field.fontColor?.hex)}
          style={isFieldButton ? { backgroundColor: field?.rectColor?.hex } : {}}
        >
          <Typography
            style={{
              fontSize: `${field.fontSize || 18}px`,
              color: field.fontColor?.hex,
              fontFamily: field.fontFamily?.family,
              fontWeight: field.fontStyle?.bold ? 'bold' : '',
              fontStyle: field.fontStyle?.italic ? 'italic' : '',
              textDecoration: `${field.fontStyle?.underline ? 'underline' : ''} ${
                field.fontStyle?.strikethrough ? 'line-through' : ''
              }`,
              textAlign: field?.align,
            }}
          >
            {isFieldCode
              ? `${field.exampleText}${field.exampleText === '' ? '' : ':'}CK09aABbAz`
              : isFieldButton
              ? 'Verificar'
              : field.exampleText || t('certificateBadgeTemplate:certificate-design.example')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers className={isFieldButton && classes.fieldButtonDisabled}>
          <div className={classes.content_modal}>
            <p>{t('certificateBadgeTemplate:certificate-design.type-config-field')}*</p>
            <FormControl required variant="outlined" className="w-100" size="small">
              <Select
                name="fieldTypeConfig"
                value={field.fieldTypeConfig}
                onChange={handleChange}
                disabled={field.fieldName === 'certificateCode' || fromCertify ? true : false}
              >
                {fieldTypesConfig.map((fieldType, index) => (
                  <MenuItem key={`field-type-option${index}`} value={fieldType.id}>
                    <em>{fieldType.value}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {field.fieldTypeConfig === 52 && (
              <>
                <p>{t('certificateBadgeTemplate:certificate-design.name-field')}*</p>
                <input
                  type="text"
                  name="fieldName"
                  value={field.fieldName || ''}
                  onChange={handleChange}
                  disabled={field.fieldName === 'certificateCode' ? true : false}
                />
                <p>{t('certificateBadgeTemplate:certificate-design.type-field')}*</p>
                <FormControl required variant="outlined" className="w-100" size="small">
                  <Select
                    name="fieldType"
                    value={field.fieldType}
                    onChange={handleChange}
                    disabled={field.fieldName === 'certificateCode' ? true : false}
                  >
                    {fieldTypes.map((fieldType, index) => (
                      <MenuItem key={`field-type-option${index}`} value={fieldType.id}>
                        <em>{fieldType.value}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <p>{t('certificateBadgeTemplate:documents-design.field')}*</p>
            <OutlinedInput
              type="text"
              name="exampleText"
              value={field.exampleText || ''}
              onChange={isFieldCode ? handleChangeCode : handleChange}
              endAdornment={
                <InputAdornment position="end">
                  {isFieldCode ? `${field.exampleText === '' ? '' : ':'}CK09aABbAz` : ''}
                </InputAdornment>
              }
            />
            {field.fieldTypeConfig === 52 && (
              <>
                <p>{t('certificateBadgeTemplate:certificate-design.align-letter')}*</p>
                <div>
                  <Checkbox
                    icon={<FormatAlignCenterIcon />}
                    checkedIcon={<FormatAlignCenterIcon />}
                    name="align"
                    value="center"
                    checked={field.align === 'center'}
                    onChange={handleChange}
                  />
                  <Checkbox
                    icon={<FormatAlignLeftIcon />}
                    checkedIcon={<FormatAlignLeftIcon />}
                    name="align"
                    value="left"
                    checked={field?.align === 'left'}
                    onChange={handleChange}
                  />
                  <Checkbox
                    icon={<FormatAlignRightIcon />}
                    checkedIcon={<FormatAlignRightIcon />}
                    name="align"
                    value="right"
                    checked={field?.align === 'right'}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <p>{t('certificateBadgeTemplate:certificate-design.font')}*</p>
            <Dropdown
              name="fontFamily"
              value={field.fontFamily || null}
              placeholder="Selecciona un tipo de letra"
              options={fontList}
              getOptionLabel={(option) => option.family}
              getOptionSelected={(option) => option.family}
              onChange={(e, value) => onChangeFontFamily(value)}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.size-letter')}*</p>
            <input
              type="number"
              name="fontSize"
              value={field.fontSize || ''}
              onChange={handleChange}
              onKeyUp={onlyNumber}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.style-letter')}*</p>
            <div>
              <Checkbox
                icon={<FormatBoldIcon />}
                checkedIcon={<FormatBoldIcon />}
                name="bold"
                checked={field?.fontStyle?.bold}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, bold: e.target.checked })}
              />
              <Checkbox
                icon={<FormatItalicIcon />}
                checkedIcon={<FormatItalicIcon />}
                name="italic"
                checked={field?.fontStyle?.italic}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, italic: e.target.checked })}
              />
              <Checkbox
                icon={<FormatUnderlinedIcon />}
                checkedIcon={<FormatUnderlinedIcon />}
                name="underline"
                checked={field?.fontStyle?.underline}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, underline: e.target.checked })}
              />
              <Checkbox
                icon={<StrikethroughSIcon />}
                checkedIcon={<StrikethroughSIcon />}
                name="strikethrough"
                checked={field?.fontStyle?.strikethrough}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, strikethrough: e.target.checked })}
              />
            </div>
            <p>{t('certificateBadgeTemplate:certificate-design.colour')}*</p>
            <SketchPickerButton color={field.fontColor?.hex || '#000000'} changeColor={changeColor} />
          </div>
        </DialogContent>
        <DialogContent dividers className={!isFieldButton && classes.fieldButtonDisabled}>
          <div className={classes.content_modal}>
            <p>{t('certificateBadgeTemplate:certificate-design.colour-text')}*</p>
            <SketchPickerButton color={field.fontColor?.hex || '#000000'} changeColor={changeColor} />
            <p>{t('certificateBadgeTemplate:certificate-design.colour-background')}*</p>
            <SketchPickerButton color={field.rectColor?.hex || '#000000'} changeColor={changeColorButton} />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonComponent color="primary" variant="contained" onClick={saveField}>
            {t('buttons:buttons.save')}
          </ButtonComponent>
          <ButtonComponent color="primary" variant="contained" onClick={closeModal}>
            {t('buttons:buttons.close')}
          </ButtonComponent>
        </DialogActions>
      </Dialog>

      <Dialog open={openSign} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <Tabs
            value={valueTab}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.draw')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.write')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.upload')} />
          </Tabs>
          <Grid container>
            {valueTab === 0 && (
              <SignatureDrawing
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                emailUser={user.correo}
              />
            )}
            {valueTab === 1 && (
              <SignatureText
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                nameUser={`${user.data_user.primer_nombre} ${user.data_user.primer_apellido}`}
                emailUser={user.correo}
                fontsFamilys={fontsFamilysTypeHand}
              />
            )}
            {valueTab === 2 && (
              <SignatureFile
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                emailUser={user.correo}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={openSignatories} fullWidth={true} maxWidth="lg">
        <DialogContent>
          <Signatories
            closeModalSignatories={closeModalSignatories}
            emailCurrent={user?.correo}
            diplomaFields={diplomaFields}
            setDiplomaFields={setDiplomaFields}
            signatories={signatories}
            setSignatories={setSignatories}
            formDiplomas={formDiplomas}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loading} />
    </>
  );
}
const useStyle = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    backgroundColor: '#E5E5E5',
  },
  container__certificate: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
  container__fields: {
    backgroundColor: 'white',
  },
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  content_modal: {
    display: 'grid',
    gridTemplateColumns: '35% 65%',
    gridTemplateRows: '',
  },
  dialog__title: {
    backgroundColor: theme.palette.primary.main,
  },
  sweetModal: {
    zIndex: 1300,
  },
  mySwal: {
    zIndex: 3000,
  },
  fieldButtonDisabled: {
    display: 'none',
  },
  root_info: {
    height: 180,
  },
  container_info: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: '1em',
  },
  buttonMinimize: {
    display: 'flex',
    justifyContent: 'right',
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: '1em',
  },
  buttonClose: {
    margin: '0 .1em 0 .1em',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  container__button_signed: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button_signed: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '10px 40px',
    fontSize: '11px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

const useStyleMUI = {
  sizeZoom: {
    width: '50px',
    height: '24px',
    marginLeft: '5px',
    fontWeight: 'bold',
    input: {
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  iconsButtons: {
    width: '45px',
    height: '45px',
    padding: '10px',
    margin: '0 3px 0 3px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#E6E6E6' },
  },
  iconsButtonsActive: {
    width: '45px',
    height: '45px',
    padding: '10px',
    margin: '0 3px 0 3px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#E6E6E6',
  },
};

const mapStateToProps = (state) => {
  return {
    diplomaFields: state.diplomaFields || [],
    formDiplomas: state.formDiplomas || [],
  };
};

const mapDispatchToProps = {
  setDiplomaFields,
  setFormDiplomas,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignDocuments);
