import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, makeStyles, Tooltip, Typography, Menu, MenuItem } from '@material-ui/core';
import { HighlightOffOutlined, AddCircleOutlineOutlined, AllInclusiveOutlined } from '@mui/icons-material';
import EditIcon from '../assets/static/icons/EditIcon';
import { useTranslation } from 'react-i18next';
import qrCK from '../assets/img/qr-certika-600x600.png'; // cargando imagen qr
import qrMinC from '../assets/img/qr-minciencias-600x600.png'; // cargando imagen qr
import buttonVerifyCK from '../assets/img/button-verify.png'; // cargando imagen buttonVerify
import buttonVerifyMinC from '../assets/img/button-verify-MinC.png'; // cargando imagen buttonVerify

function Field(props) {
  const {
    index,
    exampleText,
    fieldName,
    modifyField,
    deleteField,
    type,
    addDefaultField,
    fieldImage,
    addField,
    addOrDelete,
    page,
    deleteForAllPages,
    multyField,
    multyPages,
    noEdit = false,
  } = props;
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [t] = useTranslation('tables');
  const logo = JSON.parse(window.localStorage.state)?.styles?.logo435x149;
  const buttonVerify =
    JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias'
      ? buttonVerifyMinC
      : buttonVerifyCK;
  const qr = JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias' ? qrMinC : qrCK;

  const handleLinkedInMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const optionsDelete = (option) => {
    if (option === 'one') {
      deleteField(index);
    } else {
      deleteForAllPages(index);
    }
    setAnchorEl(null);
  };

  return type === 0 ? (
    <div className={classes.container}>
      <Box display="flex" flexDirection="column">
        <p>{exampleText}</p>
        <Typography variant="caption">{fieldName !== 'modules' && fieldName}</Typography>
        <Typography variant="caption">
          {page || page === 0
            ? page === 0
              ? `${t('tables:pagination.all-pages')}`
              : `${t('tables:pagination.one-page')}: ${page}`
            : ''}
        </Typography>
      </Box>
      <div className={classes.buttons}>
        {addOrDelete === 'add' ? (
          <>
            <Tooltip title={t('tables:table.tooltip.add')}>
              <IconButton aria-label="edit" onClick={() => addField(index)}>
                <AddCircleOutlineOutlined />
              </IconButton>
            </Tooltip>
            {fieldName !== 'modules' && (
              <Tooltip title={t('tables:table.tooltip.delete')}>
                <IconButton aria-label="delete" onClick={() => deleteField(index)}>
                  <HighlightOffOutlined />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : fieldName !== 'modules' ? (
          <>
            <Tooltip title={t('tables:table.tooltip.edit')}>
              <IconButton aria-label="edit" onClick={() => modifyField(index)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {multyPages && (
              <Tooltip title={t('tables:table.tooltip.multy')}>
                <IconButton aria-label="edit" onClick={() => multyField(index)}>
                  <AllInclusiveOutlined color={page === 0 ? 'primary' : 'default'} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t('tables:table.tooltip.delete')}>
              <IconButton aria-label="delete" onClick={() => deleteField(index)}>
                <HighlightOffOutlined />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={t('tables:table.tooltip.delete')}>
              <IconButton aria-label="delete" onClick={() => deleteField(index)}>
                <HighlightOffOutlined />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <Box display="flex" flexDirection="column">
        {fieldName === 'certificateCode' ? (
          <>
            <p>{exampleText}</p>
            <Typography variant="caption">Código</Typography>
            {page?.length > 0 && (
              <Typography variant="caption">
                Página{page.length > 1 || page[0]?.page === 0 ? 's' : ''}:
                {page
                  .sort((a, b) => (a.page < b.page ? -1 : 1))
                  .map((item, index) =>
                    index + 1 === page.length ? (item.page === 0 ? 'Todas' : item.page) : `${item.page},`
                  )}
              </Typography>
            )}
          </>
        ) : (
          <>
            <img
              src={fieldImage ? fieldImage : fieldName === 'qr' ? qr : fieldName === 'logo' ? logo : buttonVerify}
              alt="exampleImage"
              width={fieldName === 'qr' ? '50' : fieldName === 'logo' ? '80' : '100'}
            />
            <Typography variant="caption">{exampleText}</Typography>
            {page?.length > 0 && (
              <Typography variant="caption">
                Página{page.length > 1 || page[0]?.page === 0 ? 's' : ''}:
                {page
                  .sort((a, b) => (a.page < b.page ? -1 : 1))
                  .map((item, index) =>
                    index + 1 === page.length ? (item.page === 0 ? 'Todas' : item.page) : `${item.page},`
                  )}
              </Typography>
            )}
          </>
        )}
      </Box>
      <div>
        {(fieldName === 'certificateCode' || fieldName === 'buttonVerify') && addOrDelete === 'delete' && (
          <Tooltip title={t('tables:table.tooltip.edit')}>
            <IconButton aria-label="edit" onClick={() => modifyField(index)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {addOrDelete === 'add' ? (
          <Tooltip title={t('tables:table.tooltip.add')}>
            <IconButton aria-label="edit" onClick={() => addDefaultField(index)}>
              <AddCircleOutlineOutlined />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            {page?.length === 1 && multyPages && (
              <Tooltip title={t('tables:table.tooltip.multy')}>
                <IconButton aria-label="edit" onClick={() => multyField(index)}>
                  <AllInclusiveOutlined color={page[0]?.page === 0 ? 'primary' : 'default'} />
                </IconButton>
              </Tooltip>
            )}
            {fieldImage && !noEdit && (
              <Tooltip title={t('tables:table.tooltip.edit')}>
                <IconButton aria-label="edit" onClick={() => modifyField(index)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {page?.length > 1 ? (
              <>
                <Tooltip title={t('tables:table.tooltip.delete')}>
                  <IconButton
                    aria-label="delete"
                    aria-controls={'primary-search-account-menu'}
                    aria-haspopup="true"
                    onClick={handleLinkedInMenuOpen}
                  >
                    <HighlightOffOutlined />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Tooltip title={t('tables:table.tooltip.delete')}>
                <IconButton aria-label="delete" onClick={() => deleteField(index)}>
                  <HighlightOffOutlined />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={'primary-search-account-menu'}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={() => optionsDelete('one')}>
          <spam>Eliminar de esta página</spam>
        </MenuItem>
        <MenuItem onClick={() => optionsDelete('all')}>
          <spam>Eliminar todas</spam>
        </MenuItem>
      </Menu>
    </div>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 15,
    '& p': {
      margin: 0,
    },
  },
  buttons: {
    display: 'flex',
  },
}));

Field.propTypes = {
  value: PropTypes.string,
};

export default Field;
