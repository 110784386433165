/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  TextField,
  LinearProgress,
  Button,
  Avatar,
  InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from '../api';

const Appbar = (props) => {
  const { user, open, setOpen, organizationId } = props;
  const classes = useStyles();
  const [templatesBadges, setTemplatesBages] = useState([]);
  const [selectLoading, setSelectLoading] = useState(true);
  const [error, setError] = useState({});
  const [form, setForm] = useState({
    id_plantilla: '',
    email_receptor: '',
    indicativo_pais: '',
    whatsapp_receptor: '',
    tipo_identificacion: '',
    identificacion: '',
    primer_nombre_receptor: '',
    segundo_nombre_receptor: '',
    primer_apellido_receptor: '',
    segundo_apellido_receptor: '',
    numero_celular: '',
    numero_telegram: '',
    merito: '',
    link_merito: '',
    fecha_expiracion: '',
  });
  const tipo_id = ['CC', 'CE', 'CI', 'CIC', 'CIE', 'CIP', 'CURP', 'DNI', 'DPI', 'DUI', 'ID', 'NIT', 'PA', 'TI'];

  const [t] = useTranslation([
    'alerts',
    'tables',
    'buttons',
    'certificateBadgeIssued',
    'breadCrumps',
    'dialogs',
    'selects',
  ]);

  const isSuperUser =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;
  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;

  useEffect(() => {
    getTemplatesBadges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplatesBadges = async () => {
    if (isSuperUser) {
      const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublished/names`);
      setTemplatesBages(data.templatesBadges);
      if (data) setSelectLoading(false);
    } else {
      const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublished/names`, {
        id_organizaciones: organizationId,
      });
      if (isDeparmentCreater) {
        setTemplatesBages(
          data.templatesBadges.filter(
            (templateBadge) => templateBadge.plantillasInsigniasHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setTemplatesBages(data.templatesBadges);
      }
      if (data) setSelectLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInput = (event) => {
    // eslint-disable-next-line no-unused-vars
    const link =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const email =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const letters = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/;
    // eslint-disable-next-line no-unused-vars
    const id = /^([0-9]{6,12})+$/;
    const tel = /^([0-9]{7,15})+$/;
    const id_passport = /^([a-zA-Z0-9]{6,10})+$/;

    if (event.target.name === 'email_receptor') {
      if (email.test(event.target.value) || event.target.value === '') {
        setError({ ...error, [event.target.name]: false });
      } else {
        setError({ ...error, [event.target.name]: true });
      }
    }

    if (
      event.target.name === 'primer_nombre_receptor' ||
      event.target.name === 'segundo_nombre_receptor' ||
      event.target.name === 'primer_apellido_receptor' ||
      event.target.name === 'segundo_apellido_receptor'
    ) {
      if (letters.test(event.target.value) || event.target.value === '') {
        setError({ ...error, [event.target.name]: false });
      } else {
        setError({ ...error, [event.target.name]: true });
      }
    }

    if (event.target.name === 'identificacion') {
      if (document.test(event.target.value) || event.target.value === '') {
        setError({ ...error, [event.target.name]: false });
      } else {
        setError({ ...error, [event.target.name]: true });
      }
    }

    if (
      event.target.name === 'whatsapp_receptor' ||
      event.target.name === 'numero_celular' ||
      event.target.name === 'numero_telegram'
    ) {
      if (tel.test(event.target.value) || event.target.value === '') {
        setError({ ...error, [event.target.name]: false });
      } else {
        setError({ ...error, [event.target.name]: true });
      }
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar receptor</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <FormControl required variant="outlined" className="w-100">
                <Autocomplete
                  options={templatesBadges.sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))}
                  getOptionLabel={(option) => `${option.nombre} (${option.nombre_privado})`}
                  //onChange={handleTemplateCertificate}
                  renderOption={(option) => (
                    <Grid container alignItems="center" className={classes.option}>
                      <Grid item>
                        <Avatar src={option.linkPhoto} className={classes.avatar} variant="square" />
                      </Grid>
                      <Grid item>{`${option.nombre} (${option.nombre_privado})`}</Grid>
                    </Grid>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={selectLoading ? 'Cargando...' : 'Seleccione una plantilla'}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
                {selectLoading && <LinearProgress />}
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormControl className="w-100">
                <TextField
                  required
                  label="Email receptor"
                  variant="outlined"
                  name="email_receptor"
                  type="email"
                  value={form.email_receptor}
                  onChange={handleInput}
                  error={error.email_receptor}
                  helperText={error.email_receptor && 'El correo electrónico es inválido.'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Indicativo país"
                  variant="outlined"
                  name="indicativo_pais"
                  value={form.indicativo_pais}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="WhatsApp receptor"
                  variant="outlined"
                  type="number"
                  name="whatsapp_receptor"
                  value={form.whatsapp_receptor}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" className="w-100">
                <Autocomplete
                  options={tipo_id}
                  getOptionLabel={(option) => `${option}`}
                  //onChange={handleTemplateCertificate}
                  renderOption={(option) => (
                    <Grid container alignItems="center" className={classes.option}>
                      <Grid item>{`${option}`}</Grid>
                    </Grid>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={selectLoading ? 'Cargando...' : 'Seleccione un tipo de identificación'}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
                {selectLoading && <LinearProgress />}
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Identificación"
                  variant="outlined"
                  name="identificacion"
                  value={form.identificacion}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  required
                  label="Primer nombre"
                  variant="outlined"
                  name="primer_nombre_receptor"
                  value={form.primer_nombre_receptor}
                  onChange={handleInput}
                  error={error.primer_nombre_receptor}
                  helperText={error.primer_nombre_receptor && 'Solo se permiten letras.'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Segundo nombre"
                  variant="outlined"
                  name="segundo_nombre_receptor"
                  value={form.segundo_nombre_receptor}
                  onChange={handleInput}
                  error={error.segundo_nombre_receptor}
                  helperText={error.segundo_nombre_receptor && 'Solo se permiten letras.'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  required
                  label="Primer apellido"
                  variant="outlined"
                  name="primer_apellido_receptor"
                  value={form.primer_apellido_receptor}
                  onChange={handleInput}
                  error={error.primer_apellido_receptor}
                  helperText={error.primer_apellido_receptor && 'Solo se permiten letras.'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Segundo apellido"
                  variant="outlined"
                  name="segundo_apellido_receptor"
                  value={form.segundo_apellido_receptor}
                  onChange={handleInput}
                  error={error.segundo_apellido_receptor}
                  helperText={error.segundo_apellido_receptor && 'Solo se permiten letras.'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Número de Celular"
                  variant="outlined"
                  name="numero_celular"
                  value={form.numero_celular}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Número de Telegram"
                  variant="outlined"
                  name="numero_telegram"
                  value={form.numero_telegram}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField label="Merito" variant="outlined" name="merito" value={form.merito} onChange={handleInput} />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl className="w-100">
                <TextField
                  label="Link Merito"
                  variant="outlined"
                  name="link_merito"
                  value={form.link_merito}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormControl className="w-100">
                <TextField
                  label="Fecha de expiración"
                  type="date"
                  variant="outlined"
                  name="fecha_expiracion"
                  value={form.fecha_expiracion}
                  onChange={handleInput}
                  InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className={`text-center ${classes.container_center}`}>
            <Button color="primary" variant="contained" type="submit" disableElevation>
              {t('buttons:buttons.save')}
            </Button>

            <Button
              color="primary"
              variant="contained"
              //onClick={handleCancel}
              disableElevation
            >
              {t('buttons:buttons.cancel')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  container_center: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

export default connect(mapStateToProps, null)(Appbar);
