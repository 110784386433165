import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Avatar,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@material-ui/core';
import {
  FindInPageOutlined,
  ContentCopyOutlined,
  DeleteForeverOutlined,
  VisibilityOutlined,
  LaunchOutlined,
  CloseOutlined,
  PublishOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import Template from '../Templates';
import SkeletonTables from '../../../components/SkeletonTable';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator, changePage, setReturnRoute } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import Swal from 'sweetalert2';
import EditIcon from '../../../assets/static/icons/EditIcon';
import PreviewCertificate from '../../../components/PreviewDialogCertificate';
import { useTranslation } from 'react-i18next';
import UploadFile from '../../../components/UploadFileSignature';

function Published(props) {
  const {
    page,
    rowsPerPage,
    changeBreadcrump,
    user,
    organizationId,
    permission,
    changePage,
    setReturnRoute,
    searchValue,
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [certificateTemplates, setCertificateTemplates] = useState([]);
  const [filtro, setFiltradas] = useState(certificateTemplates);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [imgTemplate, setImgTemplate] = useState('');
  const [vista, setVista] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [countItems, setCountItems] = useState();
  const [idTemplateDrafts, setIdTemplateDrafts] = useState(null);
  const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
  const [fieldAdditionals, setFieldAdditionals] = useState([]);
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    changeBreadcrump(t('breadCrumps:breadCrumps.certificates-edition', { returnObjects: true }));
    return () => {
      changePage(0);
    };
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    if (!searchValue || searchValue === '') {
      await getCertificateTemplates();
    }
    await getLevel();
    await getCosts();
    await getTime();
    setLoading(false);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    if (isDeparmentCreater || isDeparmentCreaterRoll) params.id_usuarios = user.data_user.id;

    return params;
  };

  const getCertificateTemplates = async () => {
    try {
      const params = getParams(0, rowsPerPage);
      const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesEdited`, params);

      setCertificateTemplates(data.certificateTemplates?.rows);
      setFiltradas(data.certificateTemplates?.rows);
      setCountItems(data?.certificateTemplates?.count);
    } catch (error) {
      history.push('/500');
      window.location.reload();
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesEdited`, params);

    const array = [
      ...certificateTemplates,
      ...data.certificateTemplates?.rows.filter((row) => !certificateTemplates.find((item) => item.id === row.id)),
    ];

    setCertificateTemplates(array);
    setFiltradas(array);
    setLoading(false);
  };

  const getLevel = async () => {
    const { data } = await axios.post(`/others/getLevels`);
    setLevel(data.levels);
  };

  const getCosts = async () => {
    const { data } = await axios.post(`/others/getCosts`);
    setCost(data.costs);
  };

  const getTime = async () => {
    const { data } = await axios.post(`/others/getTime`);
    setTime(data.time);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'copy':
        modalCopy(e, id);
        break;
      case 'edit':
        setReturnRoute('/certificates/edited');
        history.push(`/certificates/edit/${encrypt(id)}`);
        break;
      case 'publish':
        modalPublish(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalCopy = (e, id) => {
    Swal.fire({
      text: t('alerts:alerts.copy-button-certificate'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendCopy(id, e);
      }
    });
  };

  const modalPublish = (id) => {
    Swal.fire({
      text: t('alerts:alerts.publish-button-certificate'),
      icon: 'warning',
      showCancelButton: true,
      iconColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendPublish(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button-certificate'),
      icon: 'warning',
      showCancelButton: true,
      iconColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendCopy = async (id, e) => {
    e.preventDefault();
    setLoading(true);
    const template = certificateTemplates?.filter((template) => template.id === id);
    const templateCopy = {
      ...template[0],
      copy: true,
      nombre: `${template[0]?.nombre} copia`,
      nombre_privado: `${template[0]?.nombre_privado} copia`,
      id_plantillas_recomendadas: template[0]?.recomendaciones.map((item) => ({
        id_plantillas_certificados_recomendadas: item.id_plantillas_certificados_recomendadas,
      })),
      id_habilidades: template[0]?.plantillasHabilidades.map((item) => ({
        id_habilidades: item.id_habilidades,
      })),
      id_usuarios: user.data_user?.id,
      id_organizaciones: organizationId,
      camposAdicionales: template[0]?.certificadoCamposAdicionales.map((item) => ({
        titulo_campo: item.titulo_campo,
        descripcion_campo: item.descripcion_campo,
        url: item.url,
      })),
      certificateFields: template[0]?.certificadoCampos.map((item) => ({
        fieldName: item.nombre,
        fieldType: item.tipo_campo_vp,
        exampleText: item.texto_ejemplo,
        fontFamily: { family: item.tipo_letra },
        fontSize: item.tamano_letra,
        fontColor: { hex: item.color },
        rectColor: { hex: item.background_color },
        fontStyle: {
          bold: item.bold,
          italic: item.italic,
          underline: item.underline,
          strikethrough: item.strikethrough,
        },
        x: parseInt(item.x),
        y: parseInt(item.y),
        width: item.width,
        height: item.height,
        page: item.page,
        align: item.formato_letra || 'center',
      })),
    };

    try {
      const { data } = await axios.post(`/certificateTemplate`, templateCopy);
      if (data) {
        setLoading(false);
        loadData();
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts.copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.copied-error'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const sendPublish = async (id) => {
    const { data } = await axios
      .put(`/certificateTemplate/updateState/${id}`, {
        estado: 1,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.published'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/certificates/published');
      });
    }
  };

  const sendDelete = async (id) => {
    const { data } = await axios
      .delete(`/certificateTemplate/${id}`, {
        data: { id_usuarios: user.data_user?.id },
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')} className={classes.iconButton}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const upload_signature_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title="Subir firma">
          <IconButton aria-label="upload" onClick={(e) => handleUpload(id)} className={classes.iconButton}>
            <PublishOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const handleUpload = (idTemplate) => {
    setIdTemplateDrafts(idTemplate);
    setOpenUploadFileDialog(true);
    const filtered = certificateTemplates.find((item) => item.id === idTemplate);
    setFieldAdditionals(filtered.certificadoCamposAdicionales.filter((item) => item.estado === 0));
  };

  const publish_permission = (id) => {
    if (permission.includes('Publicar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.publish')}>
          <IconButton
            aria-label="publish"
            onClick={(e) => handleClick(e, id, 'publish')}
            className={classes.iconButton}
          >
            <LaunchOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')} className={classes.iconButton}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const organizationColumn = () => {
    if (isSuper) {
      return t('tables:table.columns-certificate-edited-super', { returnObjects: true });
    } else {
      return t('tables:table.columns-certificate-edited', { returnObjects: true });
    }
  };

  const organizationName = (row) => {
    if (isSuper) {
      return <TableCell align="center">{row.organizaciones.nombre}</TableCell>;
    }
  };

  const copy_permission = (id) => {
    if (permission.includes('Copiar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.copy')}>
          <IconButton aria-label="copy" onClick={(e) => handleClick(e, id, 'copy')} className={classes.iconButton}>
            <ContentCopyOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const handleOpen = (linkPhotoTemplate, width, height) => {
    setImgTemplate({ photo: linkPhotoTemplate, width: width, height: height });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImgTemplate('');
  };

  const handleOpenDialog = (id) => {
    const template = certificateTemplates?.find((template) => template.id === id);
    const niveles = level.find((item) => item.id === template.nivel_vp);
    const tiempos = time.find((item) => item.id === template.tiempo_vp);
    const costos = cost.find((item) => item.id === template.costo_vp);
    setVista({
      ...template,
      camposAdicionales: template?.certificadoCamposAdicionales,
      niveles: niveles?.valor_parametro,
      tiempos: tiempos?.valor_parametro,
      costos: costos?.valor_parametro,
    });
    setOpenPreview(true);
  };

  const handleCloseDialog = () => {
    setOpenPreview(false);
  };

  return (
    <Template Index={1}>
      <Search
        tableName="templateCertificate"
        type="edited"
        items={certificateTemplates}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        deparmentCreater={{ isDeparmentCreater, isDeparmentCreaterRoll, userId: user.data_user.id }}
        setLoading={setLoading}
      />
      <Table
        columns={organizationColumn()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {filtro.length > 0 ? (
            loading ? (
              <>
                <SkeletonTables columns={organizationColumn()} photo={1} />
              </>
            ) : (
              <>
                {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <Tooltip title={t('tables:table.tooltip.view-template')}>
                          <Avatar
                            alt="Remy Sharp"
                            variant="square"
                            src={row.linkPhotoTemplate}
                            className={classes.large}
                            onClick={() => handleOpen(row.linkPhotoTemplate, row.width, row.height)}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{row.nombre}</TableCell>
                      <TableCell align="center">{row.nombre_privado}</TableCell>
                      {organizationName(row)}
                      <TableCell align="center">
                        {moment(row.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell align="center">{row.estado === 0 ? t('tables:table.state.edited') : ''}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.details')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={() => history.push(`/certificates/details/${encrypt(row.id)}`)}
                            className={classes.iconButton}
                          >
                            <FindInPageOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{edit_permission(row.id)}</TableCell>
                      {/* Subir firma */}
                      <TableCell align="center">{upload_signature_permission(row.id)}</TableCell>
                      <TableCell align="center">{publish_permission(row.id)}</TableCell>
                      <TableCell align="center">{copy_permission(row.id)}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.preview')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={(e) => handleOpenDialog(row.id)}
                            className={classes.iconButton}
                          >
                            <VisibilityOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{delete_permission(row.id)}</TableCell>
                    </TableRow>
                  ))}
              </>
            )
          ) : loading ? (
            <SkeletonTables columns={organizationColumn()} photo={1} />
          ) : (
            <TableCell align="center" colSpan="9">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.btnClose}`}
            onClick={handleClose}
          >
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={imgTemplate.photo}
            className={imgTemplate.width > imgTemplate.height ? classes.img_horizontal : classes.img_vertical}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openPreview} onClose={handleCloseDialog} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle align="center" onClose={handleCloseDialog}>
          {t('dialogs:dialog.preview-title-template-certificate')}
        </DialogTitle>
        <DialogContent dividers>
          <PreviewCertificate vista={vista} />
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={handleCloseDialog}
          >
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Subir firma */}
      <UploadFile
        open={openUploadFileDialog}
        setOpen={setOpenUploadFileDialog}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? 1
            : organizationId
        }
        idCertificateTemplate={idTemplateDrafts}
        loadData={loadData}
        fieldAdditionals={fieldAdditionals}
      />
    </Template>
  );
}

const useStyles = makeStyles((theme) => ({
  large: {
    width: 'auto',
    height: theme.spacing(10),
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnClose: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: '50px',
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  buttonForm: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: '50px',
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  img_vertical: {
    maxWidth: '30vw',
    height: 'auto',
  },
  img_horizontal: {
    maxWidth: '70vw',
    height: 'auto',
  },
  iconButton: {
    margin: '0em -.4em',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'certificados')
      .map((item) => item.acciones.nombre),
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
  setReturnRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
