import React from 'react';
import { connect } from 'react-redux';
import api from '../../../api';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Field from '../../../components/Field';
import { setDocumentsFields, setFormDocuments } from '../../../actions';
import { useTranslation } from 'react-i18next';
import defaultFields from '../../../utils/defaultFields';
import Swal from 'sweetalert2';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import ButtonAddIcon from '@mui/icons-material/ControlPointOutlined';
import colors from '../../../assets/styles/colors';

function Fields(props) {
  const {
    documentsFields,
    setDocumentsFields,
    modifyField,
    openModal,
    openModalSign,
    setFormDocuments,
    formDocuments,
    signatures,
    setSignatures,
    typeViewFields,
  } = props;

  const classes = useStyle();

  const defaultField = [
    {
      fieldType: 48,
      fieldName: 'qr',
    },
    {
      fieldType: 48,
      fieldName: 'logo',
    },
    {
      fieldType: 41,
      fieldName: 'certificateCode',
    },
    {
      fieldType: 48,
      fieldName: 'buttonVerify',
    },
    {
      fieldType: 48,
      fieldName: 'signature',
    },
  ];

  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons']);

  const deleteField = (index) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          documentsFields.splice(index, 1);
          setDocumentsFields([...documentsFields]);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      documentsFields.splice(index, 1);
      setDocumentsFields([...documentsFields]);
    }
  };

  const deleteFieldDefault = (fieldName, all) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (all) {
            setDocumentsFields([...documentsFields.filter((item) => item.fieldName !== fieldName)]);
          } else {
            const indexField = documentsFields.findIndex(
              (item) => item.fieldName === fieldName && (item.page === formDocuments?.page || item.page === 0)
            );
            setDocumentsFields([...documentsFields.filter((item, index) => index !== indexField)]);
          }
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      if (all) {
        setDocumentsFields([...documentsFields.filter((item) => item.fieldName !== fieldName)]);
      } else {
        const indexField = documentsFields.findIndex(
          (item) => item.fieldName === fieldName && (item.page === formDocuments?.page || item.page === 0)
        );
        setDocumentsFields([...documentsFields.filter((item, index) => index !== indexField)]);
      }
    }
  };

  const deleteSing = (id, signUrl) => {
    Swal.fire({
      icon: 'info',
      iconColor: colors.primary,
      text: t('alerts:alerts.delete-signature'),
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
      preConfirm: () => {
        if (!id) {
          setDocumentsFields([...documentsFields.filter((item) => item.exampleText !== signUrl)]);
          return setSignatures([...signatures.filter((item) => item.url !== signUrl)]);
        }
        return api
          .delete(`/signature/${id}`)
          .then(() => {
            setDocumentsFields([...documentsFields.filter((item) => item.exampleText !== signUrl)]);
            setSignatures([...signatures.filter((item) => item.url !== signUrl)]);
          })
          .catch(() => {
            Swal.showValidationMessage(t('alerts:alerts.try-again'));
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const addDefaultField = (index) => {
    const { dataQr, dataImg, dataCode, dataButtonVerify } = defaultFields();
    if (index === 0) {
      documentsFields.push({ ...dataQr, page: formDocuments?.page });
    } else if (index === 1) {
      documentsFields.push({ ...dataImg, page: formDocuments?.page });
    } else if (index === -1) {
      index = 2;
      documentsFields.push({ ...dataCode, page: formDocuments?.page, fieldTypeConfig: 51 });
    } else if (index === -3) {
      documentsFields.push({ ...dataButtonVerify, page: formDocuments?.page });
    }
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setDocumentsFields([...documentsFields]);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      setDocumentsFields([...documentsFields]);
    }
  };

  const addField = (indexField) => {
    documentsFields.push({
      ...documentsFields[indexField],
      page: formDocuments?.page,
    });

    setDocumentsFields([...documentsFields]);
  };

  const validUserInfo = async () => {
    try {
      const infoUser = await api.post('user/validCanSign');

      if (infoUser.data.message) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.try-again'),
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      });
    }
  };

  const addSign = async (signature) => {
    const { dataSignature } = defaultFields();
    const usedSignature = documentsFields.filter((item) => item.fieldName.includes('signature'));

    if (await validUserInfo()) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: 'Detectamos que tu información se encuentra desactualizada, por favor, actualiza tus datos',
        confirmButtonText: 'Actualizar datos',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('/profileSettings', '_blank');
        }
      });
    }
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          documentsFields.push({
            ...dataSignature,
            ...signature,
            fieldName: usedSignature
              ? `${dataSignature.fieldName.split('_')[0]}_${usedSignature.length + 1}`
              : dataSignature.fieldName,
            page: formDocuments?.page,
            exampleText: signature.url,
            idSignature: signature.id,
          });
          setDocumentsFields([...documentsFields]);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      documentsFields.push({
        ...dataSignature,
        ...signature,
        correo: signature.id && signature.correo ? '' : signature.correo,
        fieldName: usedSignature
          ? `${dataSignature.fieldName.split('_')[0]}_${usedSignature.length + 1}`
          : dataSignature.fieldName,
        page: formDocuments?.page,
        exampleText: signature.url,
        idSignature: signature.id,
      });
      setDocumentsFields([...documentsFields]);
    }
  };

  const certificateCodeExample = () => {
    const example = documentsFields.find(
      (item) => item.fieldName === 'certificateCode' && item.page === formDocuments?.page
    )?.exampleText;

    if (example === undefined) {
      return 'Código:CK09aABbAz';
    } else {
      return `${example}${example === '' ? '' : ':'}CK09aABbAz`;
    }
  };

  const validField = (fieldName) => {
    if (
      documentsFields?.find(
        (item) => item.fieldName === fieldName && (item.page === formDocuments?.page || item.page === 0)
      )
    ) {
      return 'delete';
    } else {
      return 'add';
    }
  };

  const numPage = (fieldName) => {
    return documentsFields.filter((item) => item.fieldName === fieldName);
  };

  const multyFieldDefault = (fieldName) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const indexField = documentsFields.findIndex(
            (item) => item.fieldName === fieldName && (item.page === formDocuments?.page || item.page === 0)
          );
          if (documentsFields[indexField].page === 0) {
            documentsFields[indexField].page = formDocuments?.page;
          } else {
            documentsFields[indexField].page = 0;
          }
          setDocumentsFields([...documentsFields]);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      const indexField = documentsFields.findIndex(
        (item) => item.fieldName === fieldName && (item.page === formDocuments?.page || item.page === 0)
      );
      if (documentsFields[indexField].page === 0) {
        documentsFields[indexField].page = formDocuments?.page;
      } else {
        documentsFields[indexField].page = 0;
      }
      setDocumentsFields([...documentsFields]);
    }
  };

  const multyField = (index) => {
    if (formDocuments.change_template && formDocuments.change_template !== 0) {
      Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        cancelButtonText: t('buttons:buttons.cancel'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (documentsFields[index].page === 0) {
            documentsFields[index].page = formDocuments?.page;
          } else {
            documentsFields[index].page = 0;
          }
          setDocumentsFields([...documentsFields]);
          setFormDocuments({
            ...formDocuments,
            change_template: 0,
            id_plantillas_documentos: 0,
            nameTemplate: '',
          });
        }
      });
    } else {
      if (documentsFields[index].page === 0) {
        documentsFields[index].page = formDocuments?.page;
      } else {
        documentsFields[index].page = 0;
      }
      setDocumentsFields([...documentsFields]);
    }
  };

  const typeView = (type) => {
    switch (type) {
      case 0:
        return (
          <>
            <div className={classes.container__header}>
              <h6>{t('certificateBadgeTemplate:certificate-design.texts')}</h6>
              <Tooltip title={t('tables:table.tooltip.add')}>
                <IconButton onClick={openModal}>
                  <ButtonAddIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Field
              key={`field${2}`}
              index={-1}
              exampleText={certificateCodeExample()}
              fieldName={defaultField[2].fieldName}
              addOrDelete={validField(defaultField[2].fieldName)}
              addDefaultField={addDefaultField}
              modifyField={modifyField}
              deleteField={() => deleteFieldDefault(defaultField[2].fieldName)}
              deleteForAllPages={() => deleteFieldDefault(defaultField[2].fieldName, 'all')}
              page={numPage(defaultField[2].fieldName)}
              multyField={() => multyFieldDefault(defaultField[2].fieldName)}
              multyPages={formDocuments?.pages > 1}
            />
            {
              // eslint-disable-next-line array-callback-return
              documentsFields?.map((field, index) => {
                if (
                  field.fieldType !== 48 &&
                  field.fieldName !== 'certificateCode' &&
                  field.fieldName !== 'deleteSign' &&
                  field.fieldName !== 'addSign'
                ) {
                  return (
                    <Field
                      key={`field${index}`}
                      index={index}
                      exampleText={field.exampleText}
                      fieldName={field.fieldName}
                      addField={addField}
                      modifyField={modifyField}
                      deleteField={deleteField}
                      type={0}
                      addOrDelete={field.page === formDocuments?.page || field.page === 0 ? 'delete' : 'add'}
                      page={field?.page}
                      multyField={multyField}
                      multyPages={formDocuments?.pages > 1}
                    />
                  );
                }
              })
            }
          </>
        );
      case 1:
        return (
          <>
            <div className={classes.container__header}>
              <h6>{t('certificateBadgeTemplate:certificate-design.images')}</h6>
            </div>
            {
              // eslint-disable-next-line array-callback-return
              defaultField?.map((field, index) => {
                if (field.fieldType === 48 && index !== 3 && index !== 4) {
                  return (
                    <Field
                      key={`field${index}`}
                      index={index}
                      exampleText={''}
                      fieldName={field.fieldName}
                      addOrDelete={validField(field.fieldName)}
                      addDefaultField={addDefaultField}
                      deleteField={() => deleteFieldDefault(field.fieldName)}
                      deleteForAllPages={() => deleteFieldDefault(field.fieldName, 'all')}
                      page={numPage(field.fieldName)}
                      multyField={() => multyFieldDefault(field.fieldName)}
                      multyPages={formDocuments?.pages > 1}
                    />
                  );
                }
              })
            }
          </>
        );
      case 2:
        return (
          <>
            <div className={classes.container__header}>
              <h6>{t('certificateBadgeTemplate:certificate-design.buttons')}</h6>
            </div>
            <Field
              key={`field${3}`}
              index={-3}
              exampleText={t('certificateBadgeTemplate:certificate-design.button-verify')}
              fieldName={defaultField[3].fieldName}
              modifyField={modifyField}
              addOrDelete={validField(defaultField[3].fieldName)}
              addDefaultField={addDefaultField}
              deleteField={() => deleteFieldDefault(defaultField[3].fieldName)}
              deleteForAllPages={() => deleteFieldDefault(defaultField[3].fieldName, 'all')}
              page={numPage(defaultField[3].fieldName)}
              multyField={() => multyFieldDefault(defaultField[3].fieldName)}
              multyPages={formDocuments?.pages > 1}
            />
          </>
        );
      case 3:
        return (
          <>
            <div className={classes.container__header}>
              <h6>{t('certificateBadgeTemplate:certificate-design.signatures')}</h6>
              <Tooltip title={t('buttons:buttons.add-signature')}>
                <IconButton onClick={openModalSign}>
                  <ButtonAddIcon />
                </IconButton>
              </Tooltip>
            </div>
            <List>
              {signatures.length > 0 && (
                <>
                  {signatures.map((sign) => {
                    return (
                      <List>
                        <ListItem button onClick={() => addSign(sign)}>
                          <ListItemIcon>
                            <img src={sign.url} alt="sign" width={200} />
                          </ListItemIcon>
                          <ListItemSecondaryAction>
                            <Tooltip title={t('tables:table.tooltip.delete')}>
                              <IconButton aria-label="delete" onClick={() => deleteSing(sign.id, sign.url)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    );
                  })}
                </>
              )}
            </List>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.container__fields}>{typeView(typeViewFields)}</div>
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    minHeight: '70vh',
  },
  container__header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    '& h6': {
      marginLeft: 10,
      fontWeight: 'bold',
      color: '#888',
    },
  },
  my_signature: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'gainsboro',
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    documentsFields: state.documentsFields || [],
    formDocuments: state.formDocuments,
  };
};

const mapDispatchToProps = {
  setDocumentsFields,
  setFormDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
