import React, { useState } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Button,
  DialogActions,
  Paper,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  CardActions,
  FormControl,
  TextField,
} from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import { DropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { env } from '../../config/environment';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import axiosFile from 'axios';
import Swal from 'sweetalert2';
import Backdrop from '../Backdrop';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import axios from '../../api';
import colors from '../../assets/styles/colors';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UploadFile = (props) => {
  const { open = false, setOpen, organizationId, idDocumentCertify, loadData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [fieldsAdditionals, setFieldsAdditionals] = useState([{ index: 1 }]);
  const [error, setError] = useState({});
  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons']);

  const closeDialog = () => {
    setFiles([]);
    setFieldsAdditionals([{ index: 1 }]);
    setOpen(false);
  };

  const handleFile = async (e) => {
    const files = e;
    if (files) {
      setFiles(files);
    }
  };

  const uploadFiles = async () => {
    if (files.length > 0) {
      setLoading(true);
      const documents = new FormData();
      files.map(async (file) => {
        documents.append('file', file);
      });
      documents.append('id_documentos_emitidos', idDocumentCertify);
      documents.append('id_organizaciones', organizationId);
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/documentsFieldAdditionals/upload`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: documents,
      })
        .then(async (res) => {
          if (res.data) {
            setLoading(false);
            setOpen(false);
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.saved'),
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              loadData();
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.error-ocurred'),
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const addFieldAdditional = () => {
    const last = fieldsAdditionals[fieldsAdditionals?.length - 1];
    setFieldsAdditionals([...fieldsAdditionals, { index: last.index + 1 }]);
  };

  const deleteFieldAdditional = (e, index) => {
    let fieldAdditionalDeleted;
    if (fieldsAdditionals.length === 0) {
      fieldAdditionalDeleted = [{ index: 1 }];
    } else {
      fieldAdditionalDeleted = fieldsAdditionals?.filter((item) => item.index !== index);
    }
    setFieldsAdditionals(fieldAdditionalDeleted);
  };

  const handleInputFieldAdditional = (event, index) => {
    // eslint-disable-next-line no-useless-escape
    const urlRegEx = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;

    if (event.target.name === 'link') {
      if (urlRegEx.test(event.target.value) || event.target.value === '') {
        setError({ ...error, [event.target.name]: false, index });
      } else {
        setError({ ...error, [event.target.name]: true, index });
      }
    }

    fieldsAdditionals?.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setFieldsAdditionals(fieldsAdditionals);
  };

  const uploadFieldAdditionals = async () => {
    let create = true;
    fieldsAdditionals.map(async (item) => {
      if (!item.link) {
        create = false;
        setError({ ...error, link: true, index: item.index });
      }
    });

    if (create) {
      setLoading(true);
      axios
        .post(`/documentsFieldAdditionals/`, { id_documentos_emitidos: idDocumentCertify, fields: fieldsAdditionals })
        .then(async (res) => {
          if (res.data) {
            setLoading(false);
            setOpen(false);
            closeDialog();
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.saved'),
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              loadData();
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.error-ocurred'),
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth={'md'}>
      <DialogTitle align="center" onClose={closeDialog} />
      <DialogContent>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} sm={12} className={classes.grid} align="center">
            <Paper>
              <Tabs
                className={classes.paper}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Documentos" {...a11yProps(0)} />
                <Tab label="Links" {...a11yProps(1)} />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.grid} align="center">
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <DropzoneArea
                  filesLimit={10}
                  acceptedFiles={['.pdf']}
                  dropzoneText={t('certificateBadgeTemplate:badges-certificate-form.documents-dropzoneArea')}
                  dropzoneClass={classes.dropzone}
                  dropzoneParagraphClass={classes.dropzoneParagraph}
                  showAlerts={false}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText={t('certificateBadgeTemplate:badges-certificate-form.documents-selected-files')}
                  onChange={handleFile}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {fieldsAdditionals.map((item, index) => (
                  <Grid container key={`grid-activity-${index}`} spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <Card variant="outlined" className={classes.card}>
                        <CardContent>
                          <Typography variant="body1" align="left" className={classes.text}>
                            {`${t('certificateBadgeTemplate:badges-certificate-form.field-description')}`}
                          </Typography>
                          <FormControl className="w-100">
                            <TextField
                              multiline
                              name="descripcion"
                              variant="outlined"
                              size="small"
                              value={item.descripcion}
                              onChange={(e) => handleInputFieldAdditional(e, item.index)}
                              className={classes.fieldCard}
                            />
                          </FormControl>
                          <Typography variant="body1" align="left" className={classes.text}>
                            {`${t('certificateBadgeTemplate:badges-certificate-form.field-url')}${'*'}`}
                          </Typography>
                          <FormControl className="w-100" required>
                            <TextField
                              label=""
                              name="link"
                              variant="outlined"
                              size="small"
                              placeholder="http://"
                              onChange={(e) => handleInputFieldAdditional(e, item.index)}
                              value={item.link}
                              className={classes.fieldCard}
                              type="url"
                              required
                              error={error.link && error.index === item.index}
                              helperText={error.link && error.index === item.index && 'Url no válido.'}
                            />
                          </FormControl>
                        </CardContent>

                        <CardActions>
                          {fieldsAdditionals?.length > 0 && (
                            <Button
                              disableElevation
                              color="primary"
                              size="small"
                              onClick={(e) => deleteFieldAdditional(e, item.index)}
                            >
                              <Typography color="textSecondary" variant="body2">
                                <CloseOutlined />
                                {t('buttons:buttons.delete')}
                              </Typography>
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                ))}
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Button disableElevation variant="outlined" color="primary" onClick={addFieldAdditional}>
                      {t('buttons:buttons.add-field')}
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {files.length > 0 && (
          <Button disableElevation variant="contained" onClick={uploadFiles} color="primary">
            {t('buttons:buttons.save')}
          </Button>
        )}
        {fieldsAdditionals.length > 0 && (
          <Button
            disableElevation
            variant="contained"
            onClick={uploadFieldAdditionals}
            color="primary"
            disabled={error.link}
          >
            {t('buttons:buttons.save')}
          </Button>
        )}
      </DialogActions>
      <Backdrop loading={loading} />
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: '2.8px solid #cacaca',
    borderRadius: '10px',
    boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.2)',
    marginBottom: '1rem',
  },
  text: {
    marginTop: '1em',
  },
  container: {
    marginTop: '.5em',
    marginBottom: '.5em',
  },
  alert: {
    backgroundColor: theme.palette.primary.light,
  },
  divider: {
    marginTop: '-.5rem',
  },
  grid: {
    paddingRight: '.8em',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5em',
    },
  },
  button: {
    margin: '0.5em',
    padding: '.5em 1em',
    borderRadius: '10px',
  },
  container__input_root: {
    borderRadius: '10px',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.palette.primary.main,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  dropzoneParagraph: {
    color: 'gray',
    fontSize: '1.1em',
  },
  paper: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F5F5F5',
  },
}));

export default UploadFile;
