import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  text: {
    color: 'white',
  },
  link: {
    color: 'white',
    cursor: 'pointer',
  },
}));

function Breadcrump(props) {
  const { crumps } = props;
  const classes = useStyles();
  const history = useHistory();

  if (!crumps) return;

  return (
    <div className={classes.root}>
      <Breadcrumbs className={classes.text} separator="›" aria-label="breadcrumb">
        {crumps?.map((crump, index) => {
          if (index === (crumps.length - 1) || index === 0) {
            return (<Typography key={crump.name} color="inherit">{crump.name}</Typography>);
          } else {
            return (
              <Link key={crump.name} className={classes.link} onClick={() => history.push(crump.route)}>
                {crump.name}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
}

export default Breadcrump;
