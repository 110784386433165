import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stage, Layer, Text, Rect } from 'react-konva';
import {
  IconButton,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { CheckOutlined, CloseOutlined, DeleteForeverOutlined, SearchOutlined } from '@mui/icons-material';
import colors from '../assets/styles/colors';
import EditIcon from '../assets/static/icons/EditIcon';
import defaultFields from '../utils/defaultFields';
import axios from '../api';

const Signatories = (props) => {
  const {
    closeModalSignatories,
    emailCurrent,
    signatories,
    setSignatories,
    documentsFields,
    setDocumentsFields,
    formDocuments,
  } = props;
  const [from, setFrom] = React.useState({
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    correo: '',
    ind_whatsapp: '',
    num_whatsapp: '',
    id_paises: '',
  });
  const [isUser, setIsUser] = React.useState(true);
  const [existing, setExisting] = React.useState(false);
  const [sign, setSign] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searched, setSearched] = React.useState(true);
  const stageRef = React.useRef(null);
  const classes = useStyle();
  const [t] = useTranslation([
    'buttons',
    'certificateBadgeTemplate',
    'certifyDocuments',
    'alerts',
    'loginRegister',
    'tables',
    'selects',
  ]);
  const columns = [
    { id: 'name', label: t('certifyDocuments:add-signatories.column.names'), minWidth: 100 },
    { id: 'email', label: t('certifyDocuments:add-signatories.column.email'), minWidth: 100 },
    { id: 'whatsapp', label: t('certifyDocuments:form-certify.num-phone'), minWidth: 100 },
    { id: 'options', label: ' ', maxWidth: 50 },
  ];
  const inActtion =
    from.primer_nombre !== '' ||
    from.segundo_nombre !== '' ||
    from.primer_apellido !== '' ||
    from.segundo_apellido !== '' ||
    from.id_paises !== '' ||
    from.ind_whatsapp !== '' ||
    from.num_whatsapp !== '' ||
    from.correo !== ''
      ? true
      : false;

  React.useEffect(() => {
    setSign(signatories);
    getCountries();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getIndWhatsapp(from.id_paises);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from.id_paises]);

  const getCountries = async () => {
    const { data } = await axios.post(`/others/getCountries`);
    setCountries(data.countries);
  };

  const goToSave = () => {
    let currentDocumentsFields = documentsFields;
    const { dataSignature } = defaultFields();
    const usedSignature = documentsFields.filter((item) => item.fieldName.includes('signature'));

    currentDocumentsFields = currentDocumentsFields.filter((field) => !field.correo);
    // eslint-disable-next-line
    sign.map((item, index) => {
      currentDocumentsFields.push({
        ...dataSignature,
        y:
          dataSignature.y + index * 105 >= formDocuments?.naturalWidthAndHeight[0].height - 155
            ? formDocuments?.naturalWidthAndHeight[0].height - 155
            : dataSignature.y + index * 105,
        exampleText: item.url,
        primer_nombre: item.primer_nombre,
        segundo_nombre: item.segundo_nombre,
        primer_apellido: item.primer_apellido,
        segundo_apellido: item.segundo_apellido,
        correo: item.correo,
        num_whatsapp: item.num_whatsapp,
        id_paises: item.id_paises,
        ind_whatsapp: item.ind_whatsapp,
        fieldName: usedSignature
          ? `${dataSignature.fieldName.split('_')[0]}_${usedSignature.length + (index + 1)}`
          : dataSignature.fieldName,
        url: item.url,
        page: formDocuments?.page,
      });
    });
    setSignatories(sign);
    setDocumentsFields(currentDocumentsFields);
    closeModalSignatories();
  };

  const searchUser = async (name, value) => {
    const email =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (email.test(value)) {
      setLoading(true);
      await axios.post('/user/getAccountSearch', { correo: value }).then(({ data: res }) => {
        setLoading(false);
        if (res.account) {
          setIsUser(true);
          setSearched(false);
          setFrom({
            primer_nombre: res.account?.usuarios?.primer_nombre,
            segundo_nombre: res.account?.usuarios?.segundo_nombre,
            primer_apellido: res.account?.usuarios?.primer_apellido,
            segundo_apellido: res.account?.usuarios?.segundo_apellido,
            num_whatsapp: res.account?.usuarios?.celular || '',
            id_paises: res.account?.usuarios?.id_paises || '',
            ind_whatsapp: res.account?.usuarios?.paises?.prefijo_telefono || '',
            [name]: value,
          });
        } else {
          setIsUser(false);
          setSearched(false);
          setFrom({
            primer_nombre: '',
            segundo_nombre: '',
            primer_apellido: '',
            segundo_apellido: '',
            num_whatsapp: '',
            id_paises: '',
            ind_whatsapp: '',
            [name]: value,
          });
        }
        return setExisting({ boolean: false, message: '' });
      });
    } else {
      setExisting({ boolean: true, message: t('alerts:alerts.email-not-valid') });
    }
  };

  const handleInput = (event) => {
    const email =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (event.target.name === 'correo') {
      if (email.test(event.target.value) || event.target.value === '') {
        if (sign.find((item) => item.correo.toLowerCase() === event.target.value.toLowerCase())) {
          if (
            (from?.index || from?.index === 0) &&
            sign[from?.index]?.correo.toLowerCase() === event.target.value.toLowerCase()
          ) {
            setFrom({ ...from, [event.target.name]: event.target.value });
          } else {
            setFrom({ ...from, [event.target.name]: event.target.value });
            return setExisting({ boolean: true, message: t('alerts:alerts.signer-already') });
          }
        } else if (event.target.value.toLowerCase() === emailCurrent.toLowerCase()) {
          setFrom({ ...from, [event.target.name]: event.target.value });
          return setExisting({
            boolean: true,
            message: t('alerts:alerts.message-use-your-sign'),
          });
        } else if (email.test(event.target.value)) {
          //searchUser(event.target.name, event.target.value);
        } else {
          setFrom({ ...from, [event.target.name]: event.target.value });
          setIsUser(true);
          return setExisting({ boolean: false, message: '' });
        }
      }
    }
    setFrom({
      ...from,
      [event.target.name]: event.target.value,
    });
  };

  const getIndWhatsapp = (id_paises) => {
    const country = countries.find((item) => item.id === id_paises);
    setFrom({
      ...from,
      ind_whatsapp: id_paises === '' ? '' : country?.prefijo_telefono,
    });
  };

  const addSignatorie = (e, stageRef) => {
    e.preventDefault();
    const uri = stageRef.current.toDataURL();

    const email =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (email.test(from.correo)) {
      if (from?.item || from?.item === 0) {
        const updateData = sign;
        updateData[from?.item] = { ...from, url: uri };
        setSignatories(signatories);
        setSign(updateData);
        handleClear();
      } else {
        setSign([...sign, { ...from, color: colorHEX(), url: uri }]);
        handleClear();
      }
    } else {
      setIsUser(true);
      setExisting({ boolean: true, message: t('alerts:alerts.email-not-valid') });
    }
  };

  const editSignatorie = async (signatorie) => {
    setLoading(true);
    const { data: res } = await axios.post('/user/getAccountSearch', { correo: signatorie.correo });
    setLoading(false);
    setIsUser(res.account);
    setFrom({ ...signatorie });
  };

  const deleteSignatorie = (item) => {
    setSign(sign.filter((_, index) => index !== item));
  };

  const handleClear = () => {
    setFrom({
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      correo: '',
      id_paises: '',
      ind_whatsapp: '',
      num_whatsapp: '',
      line: from?.line,
    });
    setIsUser(true);
    setExisting({ boolean: false, message: '' });
  };

  function generarLetra() {
    var letras = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var numero = (Math.random() * 15).toFixed(0);
    return letras[numero];
  }

  function colorHEX() {
    var coolor = '';
    for (var i = 0; i < 6; i++) {
      coolor = coolor + generarLetra();
    }
    return '#' + coolor;
  }

  function handleSearch() {
    const email =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (sign.find((item) => item.correo.toLowerCase() === from.correo.toLowerCase())) {
      if ((from?.index || from?.index === 0) && sign[from?.index]?.correo.toLowerCase() === from.correo.toLowerCase()) {
        setFrom({ ...from, correo: from.correo });
      } else {
        setFrom({ ...from, correo: from.correo });
        return setExisting({ boolean: true, message: t('alerts:alerts.signer-already') });
      }
    } else if (from.correo.toLowerCase() === emailCurrent.toLowerCase()) {
      setFrom({ ...from, correo: from.correo });
      return setExisting({
        boolean: true,
        message: t('alerts:alerts.message-use-your-sign'),
      });
    } else if (email.test(from.correo)) {
      searchUser('correo', from.correo);
    } else {
      setFrom({ ...from, correo: from.correo });

      return setExisting({ boolean: true, message: t('alerts:alerts.email-not-valid') });
    }
  }

  return (
    <Grid container>
      <Typography variant="h6">{t('certifyDocuments:add-signatories.title')}</Typography>
      <Grid item xs={12}>
        <Divider orientation="horizontal" style={{ backgroundColor: '#5c5c5c' }} />
      </Grid>
      <form onSubmit={(e) => addSignatorie(e, stageRef)} style={{ width: 'inherit' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12} lg={2}>
            <FormControl className="w-100" style={{ marginTop: existing.boolean ? '1.4em' : 0 }}>
              <TextField
                error={existing.boolean}
                helperText={existing.boolean ? existing.message : ''}
                required
                label={t('loginRegister:email')}
                name="correo"
                type="email"
                variant="outlined"
                onChange={handleInput}
                value={from?.correo}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Buscar">
                        <IconButton color="primary" onClick={handleSearch}>
                          <SearchOutlined />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {loading && <LinearProgress />}
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl className="w-100">
              <TextField
                required
                disabled={isUser}
                label={t('loginRegister:first-name')}
                name="primer_nombre"
                value={from.primer_nombre}
                onChange={handleInput}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl className="w-100">
              <TextField
                disabled={isUser}
                required
                label={t('loginRegister:last-name')}
                name="primer_apellido"
                value={from.primer_apellido}
                onChange={handleInput}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl className="w-100">
              <InputLabel id="label_id_paises" style={{ marginLeft: '.8em', marginTop: '-.5em' }}>
                {t('certifyDocuments:form-certify.phone-code')} *
              </InputLabel>
              <Select
                required
                labelId="label_id_paises"
                label={`${t('certifyDocuments:form-certify.phone-code')} *`}
                id="id_paises"
                name="id_paises"
                onChange={handleInput}
                value={from.id_paises}
                disabled={searched}
                variant="outlined"
              >
                <MenuItem value="">
                  <em>{t('selects:selects-autocompletes.select-option')}</em>
                </MenuItem>
                {countries.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {`+${data.prefijo_telefono} (${data.nombre})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl className="w-100">
              <TextField
                required
                label={t('certifyDocuments:form-certify.num-phone')}
                name="num_whatsapp"
                value={from.num_whatsapp}
                onChange={handleInput}
                disabled={searched}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Tooltip title={t('tables:table.tooltip.add')}>
              <IconButton
                color="primary"
                className={classes.button}
                type="submit"
                disabled={from.primer_nombre === '' && from.primer_apellido === '' ? true : existing.boolean}
              >
                <CheckOutlined size="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('tables:table.tooltip.clean')}>
              <IconButton color="primary" className={classes.button} onClick={handleClear}>
                <CloseOutlined size="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </form>

      <TableContainer className={classes.containerTable}>
        {sign?.length > 0 && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align="center" minWidth={column?.minWidth} style={{ fontWeight: 'bold' }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sign.map((signatorie, index) => {
                return (
                  <TableRow hover>
                    <TableCell align="center">
                      <Grid container>
                        <Grid item xs={2}>
                          <Avatar style={{ backgroundColor: signatorie?.color }}>
                            {signatorie?.primer_nombre.slice(0, 1)}
                          </Avatar>
                        </Grid>
                        <Grid item xs={10}>
                          <p style={{ margin: '0.8em 0 0 0.3em', textAlign: 'left' }}>
                            {`${signatorie?.primer_nombre} ${
                              signatorie?.segundo_nombre ? signatorie?.segundo_nombre : ''
                            } ${signatorie?.primer_apellido} ${
                              signatorie?.segundo_apellido ? signatorie?.segundo_apellido : ''
                            }`}
                          </p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">{signatorie?.correo}</TableCell>
                    <TableCell align="center">{`${signatorie?.num_whatsapp !== '' ? '+' : ''}${
                      signatorie?.ind_whatsapp
                    } ${signatorie?.num_whatsapp}`}</TableCell>
                    <TableCell>
                      <Tooltip title={t('tables:table.tooltip.edit')}>
                        <IconButton
                          color="primary"
                          variant
                          onClick={() => editSignatorie({ ...signatorie, item: index })}
                        >
                          <EditIcon size="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('tables:table.tooltip.delete')}>
                        <IconButton color="primary" onClick={() => deleteSignatorie(index, signatorie)}>
                          <DeleteForeverOutlined size="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} className={classes.container__button_fields}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => goToSave()}
            disabled={inActtion}
          >
            {t('buttons:buttons.save')}
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => closeModalSignatories()}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <Stage width={520} height={155} ref={stageRef}>
          <Layer>
            <Rect
              width={520}
              height={155}
              fill={colors.secondary}
              cornerRadius={8}
              stroke="black"
              strokeWidth={2}
              opacity={0.5}
            />
            <Text
              text={t('certifyDocuments:add-signatories.signatory')}
              x={1}
              y={58}
              width={520}
              fontSize={20}
              align="center"
            />
            <Text text={from.correo} x={1} y={78} width={520} fontSize={20} align="center" />
          </Layer>
        </Stage>
      </div>
    </Grid>
  );
};

const useStyle = makeStyles(() => ({
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: '50px',
    //padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  containerTable: {
    marginLeft: '10%',
    width: '80%',
    height: 350,
  },
}));

export default Signatories;
