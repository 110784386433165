import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../assets/styles/colors';

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: colors.primary,
    color: 'white',
    fontSize: '1em',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const ChipVersion = () => {
  const classes = useStyles();
  const [t] = useTranslation(['dialogs']);

  return <Chip label={t('dialogs:dialog.version')} className={classes.chip} />;
};

export default ChipVersion;
