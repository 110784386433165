import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import Collapse from '@material-ui/core/Collapse';
import { decrypt } from '../../../utils/crypt';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    padding: '7px 40px',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    padding: 5,
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '4em',
  },
  checkTextOrden: {
    marginLeft: '0',
    marginRight: '0',
    color: colors.primary,
  },
  buttonOrden: {
    color: '#0056b3',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  },
  selectOrden: {
    margin: theme.spacing(1.5),
  },
}));

function CreateRecommendations(props) {
  const { changeBreadcrump, user, organizationId } = props;
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({
    id_plantillas_certificados: '',
    id_plantillas_certificados_recomendadas: [],
    id_usuarios: user.data_user?.id,
  });

  const [loading, setLoading] = useState(false);
  const [templates, setTemplate] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSpacing, setOpenSpacing] = useState(false);
  const [orden, setOrden] = useState([]);
  const [t] = useTranslation(['certificateBadgeRecommendation', 'buttons', 'alerts', 'breadCrumps', 'selects']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    if (props.match.params.id) {
      getTempateRecommendations();
      getRecommendation();
      setOpen(true);
      changeBreadcrump(t('breadCrumps:breadCrumps.certificate-recommendation-edit', { returnObjects: true }));
    } else {
      getTemplate();
      getRecommendations();
      changeBreadcrump(t('breadCrumps:breadCrumps.certificate-recommendation-create', { returnObjects: true }));
    }
  };

  const getRecommendation = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/recommendation/getRecommendations`, { id_plantillas_certificados: id });
    let checkBack = [];
    let ordenCheckBack = [];
    // eslint-disable-next-line
    data.recommendations.recommendationsCertificate.map((datos, index) => {
      checkBack.push(datos.id_plantillas_certificados_recomendadas);
      ordenCheckBack.push({ no: index + 1, id_recomendada: datos.id_plantillas_certificados_recomendadas });
    });
    setOrden(ordenCheckBack);
    setChecked(checkBack);
    setForm({
      id_plantillas_certificados: id,
      id_plantillas_certificados_recomendadas: checkBack,
      id_usuarios: user.data_user?.id,
    });
  };

  const getRecommendations = async () => {
    const { data } = await axios.post(`/recommendation/getRecommendations`);

    setRecommendations(data.recommendations?.recommendationsCertificate);
  };

  const getTemplate = async () => {
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1) {
      const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesPublished`);
      setTemplate(data.certificateTemplates.rows);
    } else if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6) {
      const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesPublished`);
      setTemplate(
        data.certificateTemplates.rows.filter(
          (templateBadge) => templateBadge?.plantillasCertificadosHistorial[0]?.id_usuarios === user.data_user?.id
        )
      );
    } else {
      const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesPublished`, {
        id_organizaciones: organizationId,
      });
      setTemplate(data.certificateTemplates.rows);
    }
  };

  const getTempateRecommendations = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesRecommendations`, {
      id_plantillas_certificados: id,
    });
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6) {
      setTemplate(
        data.certificateTemplates.filter(
          (templateCertificate) =>
            templateCertificate?.plantillasCertificadosHistorial[0]?.id_usuarios === user.data_user?.id
        )
      );
    } else {
      setTemplate(data.certificateTemplates);
    }
  };

  const handleInputRecomendation = (event, values) => {
    setOpen(true);
    setOrden([{ no: '', id_recomendada: '' }]);
    setChecked('');
    if (event.target.value === '') {
      setOpen(false);
    }
    setForm({
      ...form,
      id_plantillas_certificados: values.id,
    });
  };

  const handleOrden = (event, id_recomendada) => {
    orden.map((item) => {
      if (item.id_recomendada === id_recomendada) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleCancel = () => {
    history.push('/recommendations/certificate');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(!loading);
    if (openSpacing) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.save-order'),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (!props.match.params.id) {
        if (checked.length > 0) {
          axios
            .post(`/recommendation/`, { ...form })
            .then((res) => {
              setLoading(false);
              history.push('/recommendations/certificate');
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.created'),
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((error) => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                iconColor: colors.primary,
                text: t('alerts:alerts.created-error'),
                showConfirmButton: false,
                timer: 1500,
              });
              history.push('/500');
              window.location.reload();
            });
        } else {
          Swal.fire({
            icon: 'warning',
            iconColor: colors.primary,
            text: t('alerts:alerts.recommended-template'),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        if (checked.length > 0) {
          const id = decrypt(props.match.params.id);
          axios
            .put(`/recommendation/${id}`, { ...form })
            .then((res) => {
              handleClose();
              history.push('/recommendations/certificate');
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.edited'),
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((error) => {
              handleClose();
              Swal.fire({
                icon: 'error',
                iconColor: colors.primary,
                text: t('alerts:alerts.recommended-template'),
                showConfirmButton: false,
                timer: 1500,
              });
              history.push('/500');
              window.location.reload();
            });
        } else {
          Swal.fire({
            icon: 'warning',
            iconColor: colors.primary,
            text: t('alerts:alerts.recommended-template'),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    }
  };

  const privateName = (option) => {
    let idOption = parseInt(option, 10);
    const template = templates.filter((template) => template.id === idOption);

    if (option === '') {
      return '';
    }

    if (template[0] === undefined) {
      return option.nombre_privado !== '' ? `${option.nombre} (${option.nombre_privado})` : option.nombre;
    } else {
      return template[0].nombre_privado !== ''
        ? `${template[0].nombre} (${template[0].nombre_privado})`
        : template[0].nombre;
    }
  };

  const loadFillDataTemplates = (option) => {
    let validador = 0;
    recommendations.map((datos) => {
      if (datos.id_plantillas_certificados === option) {
        validador = 1;
      }
      return validador;
    });
    if (props.match.params.id) {
      return true;
    } else {
      if (validador === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const loadOrdenValue = () => {
    return orden.map((item) => {
      return (
        <MenuItem key={item.no} value={item.no}>
          {item.no}
        </MenuItem>
      );
    });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
      const maximoNo = orden.sort((a, b) => (a.no > b.no ? 1 : -1));
      const last = maximoNo[orden.length - 1];
      if (last.no === '') {
        // eslint-disable-next-line
        setOrden([{ no: eval(last.no + 1), id_recomendada: value }]);
      } else {
        setOrden([...orden, { no: last.no + 1, id_recomendada: value }]);
      }
    } else {
      newChecked.splice(currentIndex, 1);
      const ordenDeleted = orden.filter((item) => item.id_recomendada !== value);
      const newOrden = ordenDeleted.map((item, index) => ({ no: index + 1, id_recomendada: item.id_recomendada }));
      if (newOrden.length > 0) {
        setOrden(newOrden);
      } else {
        setOrden([{ no: '', id_recomendada: '' }]);
      }
    }
    setChecked(newChecked);
    setForm({
      ...form,
      id_plantillas_certificados_recomendadas: newChecked,
    });
  };

  const openOrden = () => {
    setOpenSpacing(true);
  };

  const saveOrden = () => {
    let newCheck = [];
    let has = {};
    const repetidos = orden.filter((o) => (has[o.no] ? false : (has[o.no] = true)));
    if (repetidos.length < orden.length) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.order-error'),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      // eslint-disable-next-line
      orden.map((index) => {
        newCheck[index.no - 1] = index.id_recomendada;
      });
      setChecked(newCheck);
      setForm({
        ...form,
        id_plantillas_certificados_recomendadas: newCheck,
      });
      setOrden([...orden]);
      setOpenSpacing(false);
    }
  };

  const ordenView = (id_plantillas_certificados_recomendadas) => {
    let indice = '';
    // eslint-disable-next-line
    orden.map((item) => (item.id_recomendada === id_plantillas_certificados_recomendadas ? (indice = item.no) : ''));
    return indice;
  };

  const loadFillDataTemplates_recommended = (id) => {
    const idTemplateForm = parseInt(id);
    // eslint-disable-next-line
    return templates.map((data) => {
      if (data.id !== idTemplateForm) {
        return (
          <ListItem key={data.id} role={undefined} dense button onClick={!openSpacing ? handleToggle(data.id) : ''}>
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" variant="square" src={data.linkPhoto} />
            </ListItemAvatar>
            <ListItemText id="nombre" primary={data.nombre} secondary={data.nombre_privado} />
            <Grid item xs={1} lg={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    edge="end"
                    checked={checked.indexOf(data.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': data.nombre }}
                    className={classes.checkTextOrden}
                  />
                }
                label={ordenView(data.id)}
                labelPlacement="end"
              />
            </Grid>
          </ListItem>
        );
      }
    });
  };

  const loadFillDataOrden = (id) => {
    const idTemplateForm = parseInt(id);
    // eslint-disable-next-line
    return templates.map((data) => {
      if (data.id !== idTemplateForm) {
        return (
          <FormControl className={classes.selectOrden}>
            <Select displayEmpty name="no" defaultValue={ordenView(data.id)} onChange={(e) => handleOrden(e, data.id)}>
              {checked.indexOf(data.id) !== -1 ? loadOrdenValue() : <MenuItem disabled />}
            </Select>
          </FormControl>
        );
      }
    });
  };

  function Row() {
    return (
      <>
        <Grid container spacing={4} className={classes.root}>
          <Grid item xs={12}>
            <FormControl required variant="outlined" className="w-100">
              <Autocomplete
                id="id_plantillas_certificados"
                name="id_plantillas_certificados"
                options={templates.sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))}
                getOptionLabel={(option) => privateName(option)}
                onChange={handleInputRecomendation}
                value={form.id_plantillas_certificados}
                getOptionDisabled={(option) => loadFillDataTemplates(option.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('selects:selects-autocompletes.recommendation-select')}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container>
                <Grid item xs={10} lg={11}>
                  <Typography variant="h6">
                    {t(
                      'certificateBadgeRecommendation:badge-certificate-recommendation-form.select-template-recommendate'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={1}>
                  <Typography variant="h6">
                    {t('certificateBadgeRecommendation:badge-certificate-recommendation-form.order')}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={1} lg={12}>
                <Grid item xs={openSpacing ? 10 : 12} lg={openSpacing ? 11 : 12}>
                  <List>{loadFillDataTemplates_recommended(form.id_plantillas_certificados)}</List>
                </Grid>
                <Grid item xs={2} lg={1}>
                  <Collapse in={openSpacing} timeout="auto" unmountOnExit>
                    <List>{loadFillDataOrden(form.id_plantillas_certificados)}</List>
                  </Collapse>
                </Grid>
              </Grid>
              <Grid container spacing={1} lg={12}>
                <Grid item xs={9} lg={10} />
                <Grid item xs={3} lg={2}>
                  {openSpacing ? (
                    <Button onClick={saveOrden} className={classes.buttonOrden}>
                      {t('buttons:buttons.save-order')}
                    </Button>
                  ) : (
                    <Button onClick={openOrden} className={classes.buttonOrden}>
                      {t('buttons:buttons.change-order')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography component="h6" variant="h6">
              {t('certificateBadgeRecommendation:badge-certificate-recommendation-form.template-recommendation')}
            </Typography>
            <Row />
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecommendations);
