import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { BroadcastChannel } from 'broadcast-channel';

const useStyles = makeStyles((theme) => ({
  textSelect: {
    '& .MuiSelect-select': {
      borderBottom: 'none', // Elimina la línea inferior del Select
    },
    color: '#000',
    //fontWeight: 'bold',
    border: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
  },
  textMenu: {
    color: '#000',
  },
  formControl: {
    textAlign: 'center',
    minWidth: 50,
    border: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    [theme.breakpoints.up('sm')]: {
      minWidth: 50,
      marginLeft: '.8em',
      boder: 0,
      '.MuiOutlinedInput-notchedOutline': { border: 0 },
    },
  },
}));

const Language = () => {
  const { i18n, t } = useTranslation();
  const [dropdownLang, setDropdownLang] = useState(i18n.language || 'es');

  const lenguageChannel = new BroadcastChannel('lenguage');
  const classes = useStyles();

  useEffect(() => {
    setDropdownLang(i18n.language);
    // eslint-disable-next-line
  }, [t]);

  const languageHandler = (event) => {
    const newLanguage = event.target.value;
    if (dropdownLang !== newLanguage) {
      setDropdownLang(newLanguage);
      lenguageChannel.postMessage(`${newLanguage}`);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={dropdownLang}
        onChange={languageHandler}
        className={classes.textSelect}
        inputProps={{
          disableUnderline: true, // Elimina la línea inferior del input
        }}
      >
        <MenuItem className={classes.textMenu} value="es">
          ES
        </MenuItem>
        <MenuItem className={classes.textMenu} value="en">
          EN
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Language;
