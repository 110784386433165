import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import md5 from 'crypto-js/md5';
import axiosFile from 'axios';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Avatar,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { FileDownloadOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, changePage, getComparator, sortData } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import { env } from '../../../config/environment';
import capitalize from '../../../utils/capitalize';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { CSVReader } from 'react-papaparse';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import Backdrop from '../../../components/Backdrop';

function CreateUser(props) {
  const { changeBreadcrump, user, organizationId, changePage, page, rowsPerPage } = props;
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    documento: '',
    telefono: '',
    celular: '',
    correo: '',
    password: '',
    fecha_nacimiento: null,
    biografia: '',
    pagina_web: '',
    url_perfil: '',
    id_paises: null,
    id_ciudades: null,
    img_prev: '',
    imagen: null,
    id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
    id_grupos_usuarios: '',
    limit_issued: 0,
    user_total_issueds: 0,
  });
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [prefix, setPrefix] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [organizations, setOrganization] = useState([]);
  const [dateValid, setDateValid] = useState(false);
  const patron = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  );
  const [openBatch, setOpenBatch] = useState(false);
  const [openBadges, setOpenBadges] = useState(false);
  const [rows, setRows] = useState([]);
  const [dataIssued, setDataIssued] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [invalid, setInvalid] = useState([]);
  const [viewEvery, setViewEvery] = useState(false);
  const [isAnIssuer, setIsAnIssuer] = useState(false);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const buttonRef = React.createRef();
  const [encodingPlatform, setEncodingPlatform] = useState('');
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs', 'users', 'selects']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    getCountries();
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 && getUserGroups(organizationId);
    getOrganizations();
    if (props.match.params.id) {
      getUser();
      changeBreadcrump(t('breadCrumps:breadCrumps.settings-users-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.settings-users-create', { returnObjects: true }));
    }
    const platform = window.navigator?.userAgentData?.platform?.toLowerCase();
    const platformAlt = window.navigator?.platform?.toLowerCase();
    if (platform) {
      platform.includes('windows') ? setEncodingPlatform('ISO-8859-3') : setEncodingPlatform('UTF-8');
    } else {
      platformAlt.includes('win') ? setEncodingPlatform('ISO-8859-3') : setEncodingPlatform('UTF-8');
    }
  };

  useEffect(() => {
    form.id_paises !== ''
      ? getCities(form.id_paises).catch(() => {
          history.push('/500');
          window.location.reload();
        })
      : setCities(['']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.id_paises]);

  const getUser = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/user/${id}`);
    setForm({
      ...data.user,
      correo: data.user.cuentas[0].correo,
      id_organizaciones: data.user.gruposUsuariosOrganizaciones[0].id_organizaciones,
      id_grupos_usuarios: data.user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios,
      imagen: data.user.linkPhoto,
      img_prev: data.user.linkPhoto,
      url_static: data.user.url_perfil,
      limit_issued: data.user.gruposUsuariosOrganizaciones[0].limit_issued,
      user_total_issueds: data.user.gruposUsuariosOrganizaciones[0].user_total_issueds || 0,
    });
    if (data.user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 4) {
      setIsAnIssuer(true);
    }

    if (data.user.id_paises) {
      setPrefix({
        ...prefix,
        country: data.user?.paises?.prefijo_telefono,
        city: data.user?.ciudades?.prefijo_telefono,
      });
    }

    getUserGroups(data.user.gruposUsuariosOrganizaciones[0].id_organizaciones || null);
  };

  const getCountries = async () => {
    const { data } = await axios.post(`/others/getCountries`);
    setCountries(data.countries);
  };

  const getCities = async (idPais) => {
    const { data } = await axios.post(`/others/getCities`, { idPais });
    setCities(data.cities);
  };

  const getUserGroups = async (id_organizaciones) => {
    const { data } = await axios.post(`/userGroup/getGroups`);
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1) {
      setUserGroup(
        data.groups.filter((item) => item.id_organizaciones === null || item.id_organizaciones === id_organizaciones)
      );
    } else {
      // eslint-disable-next-line
      setUserGroup(
        data.groups.filter(
          (item) =>
            (item.id !== 1 && item.id !== 3 && item.id !== 14 && item.id_organizaciones === null) ||
            item.id_organizaciones === organizationId
        )
      );
    }
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations`);
    setOrganization(data.organizations.rows);
  };

  const handleInput = (event) => {
    if (event.target.name === 'id_grupos_usuarios') {
      event.target.value === 4 ? setIsAnIssuer(true) : setIsAnIssuer(false);
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputOrg = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
    getUserGroups(event.target.value);
  };

  const handleFile = (e) => {
    const imagen = e.target.files[0];
    setForm({
      ...form,
      imagen: imagen,
      img_prev: URL.createObjectURL(imagen),
    });
  };

  const handleChangeCountry = async (event) => {
    if (event.target.value === '') {
      setForm({
        ...form,
        [event.target.name]: '',
        id_ciudades: '',
      });
      setPrefix('');
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
        id_ciudades: '',
      });
      const prefixCountries = countries.find((country) => country.id === event.target.value);
      if (prefixCountries.prefijo_telefono) {
        setPrefix({
          ...prefix,
          country: prefixCountries.prefijo_telefono,
        });
      }
    }
  };

  const handleChangeCity = async (event) => {
    if (event.target.value === '') {
      setForm({
        ...form,
        [event.target.name]: '',
      });
      setPrefix('');
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
      const prefixCities = cities.find((item) => item.id === event.target.value);
      if (prefixCities.prefijo_telefono) {
        setPrefix({
          ...prefix,
          city: prefixCities.prefijo_telefono,
        });
      }
    }
  };

  const handleInputDateBirth = (event) => {
    let actualDate = new Date();
    let currentDate = event.target.value.split('-');
    let today = new Date();
    actualDate.setFullYear(currentDate[0], currentDate[1] - 1, currentDate[2]);
    if (actualDate >= today) {
      setDateValid(true);
    } else if (currentDate[0] > today.getFullYear() - 18) {
      setDateValid(true);
    } else if (parseInt(currentDate[0]) === today.getFullYear() - 18) {
      if (currentDate[1] - 1 > today.getMonth()) {
        setDateValid(true);
      } else if (currentDate[1] - 1 === today.getMonth()) {
        setDateValid(currentDate[2] > today.getDate());
      } else {
        setDateValid(false);
      }
    } else {
      setDateValid(false);
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history.push('/users');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data } = await axios.post(`/user/getUserUrl`, { url_perfil: form.url_perfil });
    if (form.password !== form.password2) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.password-match'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (dateValid) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.birthdate-invalid'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (form.url_static !== form.url_perfil && data) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.url-profile-error'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!patron.test(form.pagina_web) && form.pagina_web) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.url-website-error'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    if (form.limit_issued) {
      const { data: contracts } = await axios.post(`/contract/validations/`, {
        id_organizaciones: form?.id_organizaciones,
        issued: form.limit_issued,
      });
      if (form.user_total_issueds && parseInt(form.user_total_issueds) > parseInt(form.limit_issued)) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: `${t('alerts:alerts.issued-limit-error-1')} ${form.user_total_issueds} ${t(
            'alerts:alerts.issued-limit-error-2'
          )}`,
          showConfirmButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }
      if (contracts.contracts.cantidad_emisiones - contracts.contracts.total_emisiones < form.limit_issued) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.issued-limit-error-3'),
          showConfirmButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }
    }

    if (!form.limit_issued && form.user_total_issueds !== 0) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: `${t('alerts:alerts.issued-limit-error-1')} ${form.user_total_issueds} ${t(
          'alerts:alerts.issued-limit-error-4'
        )}`,
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }

    if (!props.match.params.id) {
      const formPhoto = new FormData();
      formPhoto.append('file', form.imagen);

      axios
        .post(`/user/`, { ...form, imagen: form.imagen?.name })
        .then((res) => {
          if (!res.data.userId) {
            setLoading(false);
            return Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.email-error'),
              showConfirmButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'custom-button-confirm',
              },
            });
          }
          if (form.imagen?.name) {
            const md5Route = md5(`certika-user-${res.data.userId}`).toString();
            axiosFile({
              method: 'post',
              url: `${env.apiURL}/file/upload-photo/${md5Route}`,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formPhoto,
            }).then(() => {
              setLoading(false);
              history.push('/users');
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.created'),
                showConfirmButton: false,
                timer: 1500,
              });
            });
          } else {
            setLoading(false);
            history.push('/users');
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.created'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.created-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = props.match.params.id;
      const formPhoto = new FormData();
      formPhoto.append('file', form.imagen);

      axios
        .put(`/user/${id}`, { ...form, imagen: form.imagen?.name, whatsapp: `+${prefix.country}${form.celular}` })
        .then((res) => {
          if (form.imagen.name) {
            const md5Route = md5(`certika-user-${res.data.userId}`).toString();
            axiosFile({
              method: 'post',
              url: `${env.apiURL}/file/upload-photo/${md5Route}`,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formPhoto,
            }).then(() => {
              setLoading(false);
              history.push('/users');
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: t('alerts:alerts.edited'),
                showConfirmButton: false,
                timer: 1500,
              });
            });
          } else {
            setLoading(false);
            history.push('/users');
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.edited'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  const loadFillDataCountries = () => {
    return countries.map((data) => {
      return (
        <MenuItem key={`countrie-${data.id}`} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataCities = () => {
    return cities.map((data) => {
      return (
        <MenuItem key={`city-${data.id}`} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const loadFillDataUserGroup = () => {
    return userGroup.map((data) => {
      return (
        <MenuItem key={`group-${data.id}`} value={data.id}>
          {data.nombre}
          {data.id_organizaciones !== null ? ` - ${data.organizaciones.nombre}` : ''}
        </MenuItem>
      );
    });
  };

  const handleOnFileLoad = async (data, file) => {
    const warningsA = [];
    const issued = [];
    const preIssued = [];
    const invalidA = [];
    // eslint-disable-next-line
    const email = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const letters = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]+$/;
    const fields = [
      'documento',
      'email',
      'celular',
      'primer_nombre',
      'segundo_nombre',
      'primer_apellido',
      'segundo_apellido',
    ];

    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      if (item.errors.length === 0 && item.meta.fields.length === 7) {
        if (JSON.stringify(item.meta.fields.sort()) === JSON.stringify(fields.sort())) {
          issued.push(item.data);
        }
      }
    });

    // eslint-disable-next-line array-callback-return
    issued.map((item, index) => {
      const repeatEmail = [];
      // eslint-disable-next-line
      issued.map((data) => {
        data.email === item.email && repeatEmail.push(true);
      });

      if (
        item.celular.trim() !== '' &&
        item.email.trim() !== '' &&
        item.primer_nombre.trim() !== '' &&
        item.primer_apellido.trim() !== ''
      ) {
        preIssued.push({
          index,
          ...item,
        });

        if (!letters.test(item.primer_nombre)) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.first-name-invalid')}`,
          });
        }

        if (item.segundo_nombre !== '') {
          if (!letters.test(item.segundo_nombre)) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.midle-name-invalid')}`,
            });
          }
        }

        if (!letters.test(item.primer_apellido)) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.last-name-invalid')}`,
          });
        }

        if (item.segundo_apellido !== '') {
          if (!letters.test(item.segundo_apellido)) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.second-last-name-invalid')}`,
            });
          }
        }

        if (repeatEmail.length > 1) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.user-assigned')} ${item.email} ${t('alerts:alerts.for-user')} ${
              item.primer_nombre
            }`,
          });
        }

        if (!email.test(item.email.trim())) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.email-invalid')}`,
          });
        }
      } else {
        invalidA.push(index + 2);
      }
    });

    setOpenBatch(false);
    changePage(0);
    setOpenBadges(true);
    setRows(preIssued);
    setDataIssued(issued);
    setWarnings(warningsA);
    setInvalid(invalidA);
  };

  const DialogBatch = () => {
    return (
      <>
        <Dialog open={openBatch} onClose={() => setOpenBatch(false)}>
          <DialogContent>
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              noClick
              noDrag
              progressBarColor={colors.primary}
              config={{ header: true, encoding: encodingPlatform, skipEmptyLines: true }}
            >
              {({ file }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={handleOpenDialog}
                  >
                    {t('buttons:buttons.choose-csv')}
                  </Button>
                  <div
                    style={{
                      borderWidth: 1,
                      height: 45,
                      lineHeight: 2.5,
                      marginTop: 5,
                      marginBottom: 5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: '60%',
                    }}
                  >
                    {file && file.name}
                  </div>
                </div>
              )}
            </CSVReader>

            <Typography variant="body1" className={classes.text}>
              {t('users:users.choose-csv')}
            </Typography>
            <Link to="#" onClick={downloadTemplate} sx={classes.link}>
              <Typography variant="body1" style={{ cursor: 'pointer' }} className={classes.text}>
                <FileDownloadOutlined />
                {t('buttons:buttons.download')}
              </Typography>
            </Link>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const DialogTableBatch = () => {
    return (
      <>
        <Dialog open={openBadges} fullWidth={true} maxWidth={'xl'} scroll={'body'}>
          <DialogTitle>
            <div className={classes.alert}>
              <Alert severity="info">
                {t('alerts:alerts.preloaded')} {rows.length} {t('alerts:alerts.of')} {` ${dataIssued.length}`}
                {t('alerts:alerts.rows')}
              </Alert>
              {rows.length === 0 && (
                <Alert severity="error">
                  No hay usuarios por crear, revise que el archvio tenga el formato correcto.
                </Alert>
              )}
              {invalid.length > 0 && (
                <Alert severity="error">
                  {t('alerts:alerts.rows-2')} <b>{invalid.toString()}</b> {t('alerts:alerts.no-requirements')}
                </Alert>
              )}
              {warnings.length > 5 && !viewEvery ? (
                <>
                  {warnings.map(
                    (data, index) =>
                      index < 5 && (
                        <div key={`row${index}`}>
                          <Alert severity="error">
                            <b>
                              {t('alerts:alerts.row')} {data.index + 2}:
                            </b>
                            {data.msg}
                          </Alert>
                        </div>
                      )
                  )}
                  <div>
                    <Alert severity="error">
                      <b>
                        5 {t('alerts:alerts.of')} {warnings.length} {t('alerts:alerts.errors')}.
                      </b>
                      <Button onClick={verTodos} className={classes.añadirButton}>
                        {t('buttons:buttons.see-all')}
                      </Button>
                    </Alert>
                  </div>
                </>
              ) : (
                <>
                  {warnings.map((data, index) => (
                    <div key={`row${index}`}>
                      <Alert severity="error">
                        <b>
                          {t('alerts:alerts.row')} {data.index + 2}:
                        </b>
                        {data.msg}.
                      </Alert>
                    </div>
                  ))}
                </>
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={handleSubmitBatch}
              disabled={(rows.length !== dataIssued.length || warnings.length > 0 || rows.length === 0) && true}
            >
              {t('buttons:buttons.create')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={handleRemoveFile}
            >
              {t('buttons:buttons.cancel')}
            </Button>
          </DialogTitle>

          <DialogContent dividers>
            {rows.length > 0 && (
              <Table columns={columns()} rows={rows} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
                <TableBody>
                  {sortData(rows, getComparator(ordenDirection, valueToOrderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={`row${index}`}>
                        <TableCell align="center">
                          <b>{row.index + 2}</b>
                        </TableCell>
                        <TableCell align="center">{row.documento}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.indicativo_pais}</TableCell>
                        <TableCell align="center">{row.celular}</TableCell>
                        <TableCell align="center">{row.primer_nombre}</TableCell>
                        <TableCell align="center">{row.segundo_nombre}</TableCell>
                        <TableCell align="center">{row.primer_apellido}</TableCell>
                        <TableCell align="center">{row.segundo_apellido}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const downloadTemplate = async () => {
    const { data } = await axios.post(`/file/getFile`, {
      keyFile: 'files/template_users_batch.csv',
    });

    window.open(data.url);
  };

  const verTodos = () => {
    setViewEvery(true);
  };

  const columns = () => {
    return t('tables:table.columns-template-user-batch', { returnObjects: true });
  };

  const handleSubmitBatch = (e) => {
    e.preventDefault();

    setOpenBadges(false);

    Swal.fire({
      text: `${t('alerts:alerts.user-create-batch')} ${dataIssued.length} ${t('alerts:alerts.user-create-batch-2')} ?`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: colors.primary,
      cancelButtonColor: '#d33',
      denyButtonColor: colors.secondary,
      confirmButtonText: t('buttons:buttons.create'),
      cancelButtonText: t('buttons:buttons.cancel'),
      denyButtonText: 'Atrás',
    }).then(async (result) => {
      if (result.isDenied) {
        setOpenBadges(true);
      }
      if (result.isDismissed) {
        handleRemoveFile();
      }
      if (result.value) {
        setLoading(true);

        const datapost = {
          usuarios: rows,
          id_organizaciones: form.id_organizaciones,
          id_usuarios_emisores: user.data_user.id,
        };

        axios
          .post(`/user/batch`, datapost)
          .then(({ data }) => {
            setLoading(false);
            if (data.response.length > 0) {
              setWarnings(
                data.response.map((indexUser) => {
                  return {
                    index: indexUser,
                    msg: `${t('alerts:alerts.user-register')}`,
                  };
                })
              );
              setOpenBadges(true);
            } else {
              handleRemoveFile();
              Swal.fire({
                icon: 'success',
                iconColor: colors.primary,
                text: 'Usuarios creados correctamente',
                showConfirmButton: true,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            handleRemoveFile();
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.try-again'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    setOpenBadges(false);
    setViewEvery(false);
    setRows([]);
    setWarnings([]);
  };

  const onlyNumber = (event) => {
    const value = String(event.target.value);
    // eslint-disable-next-line no-useless-escape
    const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;

    if (!patron.test(value)) {
      event.target.value = value.substr(value.length);
    }
    // Only 11 digit
    if (value.length > 11) {
      event.target.value = value.substr(value.length);
    }
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Tooltip title="Cargar csv">
              <IconButton onClick={() => setOpenBatch(true)}>
                <FileUploadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
          {DialogBatch()}
          {DialogTableBatch()}
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography component="h6" variant="h6">
              {t('users:users.photo-profile')}
            </Typography>
            <Grid container spacing={4} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Avatar alt="img-prev" src={form.img_prev} variant="rounded" className={classes.avatar}>
                    <PersonOutlineOutlined sx={{ fontSize: '4em' }} />
                  </Avatar>
                </div>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Typography variant="body2" className={classes.text}>
                  {t('users:users.message-photo')}
                </Typography>

                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  name="imagen"
                  type="file"
                  onChange={(e) => handleFile(e)}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    disableElevation
                    color="primary"
                    variant="outlined"
                    component="span"
                    className={classes.button}
                  >
                    {t('buttons:buttons.upload-image')}
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:users.first-name')}
                    variant="outlined"
                    name="primer_nombre"
                    value={form.primer_nombre}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.second-name')}
                    variant="outlined"
                    name="segundo_nombre"
                    value={form.segundo_nombre}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:users.last-name')}
                    variant="outlined"
                    name="primer_apellido"
                    value={form.primer_apellido}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.second-last-name')}
                    variant="outlined"
                    name="segundo_apellido"
                    value={form.segundo_apellido}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    type="number"
                    label={t('users:users.document')}
                    variant="outlined"
                    name="documento"
                    value={form.documento}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    error={dateValid}
                    label={t('users:users.birthdate')}
                    type="date"
                    variant="outlined"
                    name="fecha_nacimiento"
                    value={form.fecha_nacimiento}
                    onChange={handleInputDateBirth}
                    InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('users:users.email')}
                    variant="outlined"
                    name="correo"
                    type="email"
                    value={form.correo}
                    onChange={handleInput}
                    disabled={props.match.params.id && decrypt(props.match.params.id) > 0}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.url-profile')}
                    variant="outlined"
                    name="url_perfil"
                    onChange={handleInput}
                    value={form.url_perfil}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel>
                    {t('users:users.country')} {' *'}
                  </InputLabel>
                  <Select
                    required
                    id="pais"
                    name="id_paises"
                    label={t('users:users.country')}
                    onChange={handleChangeCountry}
                    value={form.id_paises ? form.id_paises : ''}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCountries()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel id="ciudadesLabel">
                    {t('users:users.city')} {' *'}
                  </InputLabel>
                  <Select
                    required
                    id="ciudades"
                    name="id_ciudades"
                    labelId="ciudadesLabel"
                    value={form.id_ciudades ? form.id_ciudades : ''}
                    onChange={handleChangeCity}
                    label={t('users:users.city')}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCities()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.phone')}
                    variant="outlined"
                    name="telefono"
                    type="number"
                    value={form.telefono}
                    onChange={handleFile}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{`(${prefix.country ? prefix.country : ''})`}</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.cellphone')}
                    variant="outlined"
                    name="celular"
                    type="number"
                    value={form.celular}
                    onChange={handleInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{`(${prefix.city ? prefix.city : ''})`}</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.web-site')}
                    variant="outlined"
                    name="pagina_web"
                    onChange={handleInput}
                    value={form.pagina_web}
                    placeholder="https://"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl className="w-100">
                  <TextField
                    label={t('users:users.biography')}
                    name="biografia"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    onChange={handleInput}
                    value={form.biografia}
                  />
                </FormControl>
              </Grid>
              {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                <Grid item xs={12} lg={6}>
                  <FormControl required={form?.id_organizaciones} variant="outlined" className="w-100">
                    <InputLabel>{t('users:users.organization')}</InputLabel>
                    <Select
                      id="organizacion"
                      name="id_organizaciones"
                      label="organizacion"
                      onChange={handleInputOrg}
                      value={form.id_organizaciones}
                    >
                      <MenuItem value="">
                        <em>{t('selects:selects-autocompletes.select-option')}</em>
                      </MenuItem>
                      {loadFillDataOrganization()}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                lg={
                  user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1
                    ? isAnIssuer
                      ? 3
                      : 6
                    : isAnIssuer
                    ? 6
                    : 12
                }
              >
                <FormControl required variant="outlined" className="w-100">
                  <InputLabel>{t('users:users.user-group')}</InputLabel>
                  <Select
                    id="grupo_usuario"
                    name="id_grupos_usuarios"
                    label="grupo_usuario"
                    onChange={handleInput}
                    value={form.id_grupos_usuarios}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataUserGroup()}
                  </Select>
                </FormControl>
              </Grid>
              {isAnIssuer && (
                <Grid item xs={12} lg={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ? 3 : 6}>
                  <FormControl className="w-100">
                    <TextField
                      label={t('users:users.issue-limit')}
                      variant="outlined"
                      type="number"
                      name="limit_issued"
                      value={form.limit_issued}
                      onKeyUp={onlyNumber}
                      onChange={handleInput}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required={props.match.params.id ? false : true}
                    label={t('users:users.password')}
                    variant="outlined"
                    type="password"
                    name="password"
                    autoComplete="on"
                    value={form.password}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl className="w-100">
                  <TextField
                    required={props.match.params.id ? false : true}
                    label={t('users:users.confirm-password')}
                    variant="outlined"
                    type="password"
                    name="password2"
                    autoComplete="on"
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    padding: 5,
    width: 'auto',
    height: 'auto',
  },
  input: {
    display: 'none',
  },
  icon: {
    fontSize: '4em',
  },
  text: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  link: {
    color: colors.primary,
    '&:hover': {
      color: colors.secondary,
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
