import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { EmailOutlined, WhatsApp, ShortcutOutlined } from '@mui/icons-material';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import SkeletonTables from '../../../components/SkeletonTable';
import axios from '../../../api';
import { changePage, sortData, getComparator, setSearchValue } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import { useTranslation } from 'react-i18next';

function History(props) {
  const { page, rowsPerPage, permission, changePage, user, organizationId, setSearchValue } = props;
  const history = useHistory();
  const [documentsHistory, setDocumentsHistory] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ordenDirection, setOrdenDirection] = useState('desc');
  const [valueToOrderBy, setValueToOrderBy] = useState('fecha');
  const [t] = useTranslation(['tables', 'buttons']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData();
    } else {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getDocumentsHistory();
      setLoading(false);
      setSearchValue('');
    } catch (error) {
      history.push('/500');
    }
  };

  const getDocumentsHistory = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/documentHistory/getDocumentHistorys`, {
      id_documentos_emitidos: id,
    });
    setDocumentsHistory(data.documentHistorys.filter((history) => history.tipo !== 4));
    setFiltradas(data.documentHistorys.filter((history) => history.tipo !== 4));
  };

  const columns = () => {
    return t('tables:table.columns-documents-files-history', { returnObjects: true });
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="historyDocuments"
        items={documentsHistory}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        setLoading={setLoading}
      />
      <Table
        columns={columns()}
        rows={filtro}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={1} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      {row?.tipo === 1 && (
                        <Tooltip title={t('buttons:tooltips.share-by-email')}>
                          <EmailOutlined sx={iconStyle} />
                        </Tooltip>
                      )}
                      {row?.tipo === 2 && (
                        <Tooltip title={t('buttons:tooltips.share-by-whatsapp')}>
                          <WhatsApp sx={iconStyle} />
                        </Tooltip>
                      )}
                      {row?.tipo === 3 && (
                        <Tooltip title={t('buttons:tooltips.reminder')}>
                          <ShortcutOutlined sx={iconStyle} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">{`${row?.usuarios?.primer_nombre} ${row?.usuarios?.primer_apellido}`}</TableCell>
                    <TableCell align="center">{`${row?.receptor}`}</TableCell>
                    <TableCell align="center">{moment(row?.fecha).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="4">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const iconStyle = {
  color: '#888888',
  fontSize: '3rem',
};

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'insignias emitidas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  setSearchValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
