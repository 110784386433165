import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  Typography,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import capitalize from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    //padding: '9px 40px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  check: {
    color: colors.primary,
  },
}));

function CreateGroup(props) {
  const { changeBreadcrump } = props;
  const classes = useStyles();
  const history = useHistory();
  const [form, setForm] = useState({
    nombre: '',
    id_organizaciones: null,
    rol: null,
  });
  const [loading, setLoading] = useState(false);
  const [tipoDefinicion, setTipoDefinicion] = useState([0]);
  const [organizations, setOrganizations] = useState([]);
  const [userGroup, setUserGroup] = useState([]);

  const [t] = useTranslation(['alerts', 'buttons', 'breadCrumps', 'forms']);

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    if (props.match.params.id) {
      getGroup().catch(() => {
        history.push('/500');
        window.location.reload();
      });
      changeBreadcrump(t('breadCrumps:breadCrumps.userGroup-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.userGroup-create', { returnObjects: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    await getOrganizaciones();
    await getUserGroups();
  };

  const getGroup = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/userGroup/${id}`);
    setForm(data.group);
    data.group.id_organizaciones !== null && setTipoDefinicion([1]);
  };

  const getOrganizaciones = async () => {
    const { data } = await axios.post(`/organization/getOrganizations`);
    setOrganizations(data.organizations.rows);
  };

  const getUserGroups = async () => {
    const { data } = await axios.post(`/userGroup/getGroups`);
    setUserGroup(data.groups);
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleCancel = () => {
    history.push('/userGroups');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleToggle();

    if (!props.match.params.id) {
      axios
        .post(`/userGroup/`, form)
        .then((res) => {
          handleClose();
          history.push('/userGroups');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.created'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.created-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = decrypt(props.match.params.id);
      axios
        .put(`/userGroup/${id}`, form)
        .then((res) => {
          handleClose();
          history.push('/userGroups');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  const handleToggleType = (value) => () => {
    if (value === 0) {
      setForm({
        ...form,
        id_organizaciones: null,
        rol: null,
      });
    }
    const currentIndex = tipoDefinicion.indexOf(value);
    const newChecked = [...tipoDefinicion];
    if (currentIndex === -1) {
      newChecked.splice(currentIndex, 1);
      newChecked.push(value);
      setTipoDefinicion(newChecked);
    }
  };

  const loadFillDataOrganizaciones = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const loadFillDataUserGroup = () => {
    return userGroup.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {data.nombre}
          {data.id_organizaciones !== null ? ` - ${data.organizaciones.nombre}` : ''}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography align="center" component="h6" variant="h6">
              {t('forms:userGroup-define')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <FormControlLabel
                  control={
                    <Radio
                      className={classes.check}
                      color="default"
                      checked={tipoDefinicion.includes(0)}
                      onClick={handleToggleType(0)}
                      disabled={props.match.params.id && form.id_organizaciones !== null}
                    />
                  }
                  label="Normal"
                />
                <FormControlLabel
                  control={
                    <Radio
                      className={classes.check}
                      color="default"
                      checked={tipoDefinicion.includes(1)}
                      onClick={handleToggleType(1)}
                      disabled={props.match.params.id && form.id_organizaciones === null}
                    />
                  }
                  label={t('forms:advanced')}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('forms:name')}
                    name="nombre"
                    value={form.nombre}
                    variant="outlined"
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              {tipoDefinicion.includes(1) && (
                <>
                  <Grid item xs={12} lg={6}>
                    <FormControl required variant="outlined" className="w-100">
                      <InputLabel>{t('forms:organizations')}</InputLabel>
                      <Select
                        id="id_organizaciones"
                        name="id_organizaciones"
                        label="id_organizaciones"
                        onChange={handleInput}
                        value={form.id_organizaciones}
                      >
                        <MenuItem>
                          <em>{t('forms:selected-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganizaciones()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl required variant="outlined" className="w-100">
                      <InputLabel>Rol</InputLabel>
                      <Select id="rol" name="rol" label="rol" onChange={handleInput} value={form.rol}>
                        <MenuItem>
                          <em>{t('forms:selected-option')}</em>
                        </MenuItem>
                        {loadFillDataUserGroup()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(CreateGroup);
