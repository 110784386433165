import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow, Avatar, Tooltip, IconButton } from '@material-ui/core';
import { FindInPageOutlined } from '@mui/icons-material';
import moment from 'moment';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import colors from '../../../assets/styles/colors';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { decrypt, encrypt } from '../../../utils/crypt';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function TemplatesRecommendations(props) {
  const { page, rowsPerPage, changeBreadcrump, permission, user, organizationId, changePage, searchValue } = props;
  const history = useHistory();
  const classes = useStyles();
  const [recommendation, setRecommendation] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation(['tables', 'buttons', 'alerts', 'breadCrumps']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    if (!searchValue || searchValue === '') {
      await getRecommendation();
    }
    await getTemplates();
    changeBreadcrump(t('breadCrumps:breadCrumps.badge-recommendation-assigned', { returnObjects: true }));
    setLoading(false);
  };

  const getRecommendation = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/recommendation/getRecommendations`, { id_plantillas: id });
    if (
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
    ) {
      setRecommendation(data.recommendations?.recommendations);
      setFiltradas(data.recommendations?.recommendations);
    } else {
      setRecommendation(
        data.recommendations?.recommendations.filter(
          (item) => item.plantillasInsignias?.id_organizaciones === organizationId
        )
      );
      setFiltradas(
        data.recommendations?.recommendations.filter(
          (item) => item.plantillasInsignias?.id_organizaciones === organizationId
        )
      );
    }
  };

  const getTemplates = async () => {
    const { data } = await axios.post(`/templateBadge/getTemplatesBadges`);
    setTemplates(data.templatesBadges);
  };

  const linkphoto = (idPlantilla) => {
    let photo = null;
    // eslint-disable-next-line
    templates.map((datos) => {
      if (datos.id === idPlantilla) {
        photo = datos.linkPhoto;
      }
    });
    return (
      <TableCell align="center">
        <Avatar alt="Remy Sharp" src={photo} className={classes.large} />
      </TableCell>
    );
  };

  const organizations = (idPlantilla) => {
    let organization = null;
    // eslint-disable-next-line
    templates.map((datos) => {
      if (datos.id === idPlantilla) {
        organization = datos.organizaciones.nombre;
      }
    });
    return <TableCell align="center">{organization}</TableCell>;
  };

  const toLinkPlantilla = (e, id) => {
    props.history.push(`/templates/published/details/${encrypt(id)}`);
  };

  const columns = () => {
    return t('tables:table.columns-recommended-assigned', { returnObjects: true });
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="badgeTemplateRecomendations"
        items={recommendation}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        userGroupId={user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios}
        organizationId={organizationId}
        groupId={decrypt(props.match.params.id)}
        setLoading={setLoading}
      />
      <Table
        columns={columns()}
        rows={filtro}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={1} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.plantillasInsigniasRecomendadas?.id}>
                    {linkphoto(row.plantillasInsigniasRecomendadas?.id)}
                    <TableCell align="center">{row.plantillasInsigniasRecomendadas?.nombre}</TableCell>
                    <TableCell align="center">{row.plantillasInsigniasRecomendadas?.nombre_privado}</TableCell>
                    {organizations(row.plantillasInsigniasRecomendadas?.id)}
                    <TableCell align="center">
                      {moment(row.plantillasInsigniasRecomendadas.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {row.plantillasInsigniasRecomendadas.estado === 1
                        ? t('tables:table.state.published')
                        : row.plantillasInsigniasRecomendadas.estado === 2
                        ? t('tables:table.state.archived')
                        : row.plantillasInsigniasRecomendadas.estado === 0
                        ? t('tables:table.state.edited')
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-template')}>
                        <IconButton
                          aria-label="detalles"
                          onClick={(e) => toLinkPlantilla(e, row.plantillasInsigniasRecomendadas?.id)}
                        >
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableCell align="center" colSpan="6">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'recomendaciones')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesRecommendations);
