import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import capitalize from '../../../utils/capitalize';
import colors from '../../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.1em',
    //padding: '8px 40px',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    padding: 5,
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '4em',
  },
}));

function CreateUserGroupsOrganizations(props) {
  const { changeBreadcrump, user, organizationId } = props;
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({
    id_usuarios: '',
    id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
    id_grupos_usuarios: '',
  });

  const [loading, setLoading] = useState(false);
  const [userGroup, setUserGroup] = useState([]);
  const [organizations, setOrganization] = useState([]);
  const [users, setUsers] = useState([]);

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps']);

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    getUserGroups();
    getOrganizations();
    getUsers();
    if (props.match.params.id) {
      getUserGroupOrganization();
      changeBreadcrump(t('breadCrumps:breadCrumps.user-groups-organizations-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.user-groups-organizations-create', { returnObjects: true }));
    }
  };

  const getUserGroupOrganization = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/userGroup/organizations/${id}`);
    setForm(data.group);
  };

  const getUserGroups = async () => {
    const { data } = await axios.post(`/userGroup/getGroups`);
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1) {
      setUserGroup(data.groups);
    } else {
      // eslint-disable-next-line
      setUserGroup(
        data.groups.filter(
          (item) =>
            (item.id !== 1 && item.id !== 3 && item.id_organizaciones === null) ||
            item.id_organizaciones === organizationId
        )
      );
    }
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations`);
    setOrganization(data.organizations.rows);
  };

  const getUsers = async () => {
    const params = {};
    if (user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1)
      params.id_organizaciones = organizationId;
    const { data } = await axios.post(`/user/getUsers`, params);
    setUsers(data.users?.rows);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleCancel = () => {
    history.push('/userGroupsOrganization');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(!loading);

    if (!props.match.params.id) {
      axios
        .post(`/userGroup/organizations/`, form)
        .then((res) => {
          setLoading(false);
          history.push('/userGroupsOrganization');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.created'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.created-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = decrypt(props.match.params.id);
      axios
        .put(`/userGroup/organizations/${id}`, form)
        .then((res) => {
          handleClose();
          history.push('/userGroupsOrganization');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  const loadFillDataUser = () => {
    return users
      .sort((a, b) => (a.primer_nombre.toLowerCase() < b.primer_nombre.toLowerCase() ? -1 : 1))
      .map((data) => {
        return (
          <MenuItem key={data.id} value={data.id}>
            {`${data.primer_nombre} ${data.primer_apellido}`}
          </MenuItem>
        );
      });
  };

  const loadFillDataOrganization = () => {
    return organizations
      .sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))
      .map((data) => {
        return (
          <MenuItem key={data.id} value={data.id}>
            {capitalize(data.nombre)}
          </MenuItem>
        );
      });
  };

  const loadFillDataUserGroup = () => {
    return userGroup.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {data.nombre}
          {data.id_organizaciones !== null ? ` - ${data.organizaciones.nombre}` : ''}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4} className={classes.root}>
              <Grid item xs={12}>
                <FormControl required variant="outlined" className="w-100">
                  <InputLabel>{t('forms:user')}</InputLabel>
                  <Select
                    id="usuario"
                    name="id_usuarios"
                    label={t('forms:user')}
                    onChange={handleInput}
                    value={form.id_usuarios}
                  >
                    <MenuItem value="">
                      <em>{t('forms:selected-option')}</em>
                    </MenuItem>
                    {loadFillDataUser()}
                  </Select>
                </FormControl>
              </Grid>
              {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                <Grid item xs={12}>
                  <FormControl required={form.id_grupos_usuarios !== 3} variant="outlined" className="w-100">
                    <InputLabel>{t('forms:organization')}</InputLabel>
                    <Select
                      id="organizacion"
                      name="id_organizaciones"
                      label={t('forms:organization')}
                      onChange={handleInput}
                      value={form.id_organizaciones}
                    >
                      <MenuItem value={0}>
                        <em>{t('forms:selected-option')}</em>
                      </MenuItem>
                      {loadFillDataOrganization()}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl required variant="outlined" className="w-100">
                  <InputLabel>{t('forms:userGroup')}</InputLabel>
                  <Select
                    id="grupo_usuario"
                    name="id_grupos_usuarios"
                    label="grupo_usuario"
                    onChange={handleInput}
                    value={form.id_grupos_usuarios}
                  >
                    <MenuItem value={0}>
                      <em>{t('forms:selected-option')}</em>
                    </MenuItem>
                    {loadFillDataUserGroup()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserGroupsOrganizations);
