import React from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Divider, Box, Container } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../assets/styles/colors';
import VerifiedEn from '../assets/img/icon_verified.png';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function PreviewDialog(props) {
  const { vista, children, styles } = props;
  const classes = useStyles();
  /* translation */
  const [t] = useTranslation(['certificateBadgeTemplate']);
  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} className={'text-center'}>
            <img alt="img-badge" src={vista?.img_prev} className={classes.img} />
            <div>
              <Typography className={classes.textTitle}>{t('details.issued-by')}</Typography>
              {vista.id_organizacion_hija !== null ? (
                <Typography className={classes.text}>{vista?.organizacionesHijas?.nombre}</Typography>
              ) : (
                <Typography className={classes.text}>{vista?.organizaciones?.nombre}</Typography>
              )}
            </div>
            <div>
              <Typography className={classes.textBolder}>{t('details.created')}:</Typography>
              <Typography className={classes.text}>{moment(vista.fecha_creacion).format('DD-MM-YYYY')}</Typography>
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={12} md={7}>
            <div className={classes.header}>
              <Grid container spacing={0}>
                <Typography variant="h4" gutterBottom className={classes.textTitle}>
                  {vista.nombre}
                </Typography>

                <img
                  alt="img_verificado"
                  src={languajeEn ? VerifiedEn : styles.icon_verificado}
                  className={classes.img_verificado}
                />
              </Grid>
            </div>
            <Typography className={classes.textParagraph} gutterBottom>
              {vista.descripcion}
            </Typography>
            <Typography className={classes.textBolder} gutterBottom>
              {t('details.criteria')}
            </Typography>
            {vista?.criterios?.map((data, index) => (
              <Typography key={`criteria${index}`} className={classes.text} gutterBottom>
                {data.descripcion_criterio}
              </Typography>
            ))}
            <Grid container spacing={2} align="center" className={classes.root}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {vista.niveles}
                </Typography>
                <Typography className={classes.text}> {t('details.level')}</Typography>
              </Grid>
              {vista?.cantidad_tiempo !== null && vista?.tiempos !== undefined ? (
                <Grid item xs={12} sm={4}>
                  <Typography variant="h5" className={classes.textTitle}>
                    {`${vista?.cantidad_tiempo} ${vista.tiempos}`}
                  </Typography>
                  <Typography className={classes.text}> {t('details.time')}</Typography>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {vista.costos}
                </Typography>
                <Typography className={classes.text}> {t('details.cost')}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.root}>
              <Grid item lg={12} sm={12} xs={12}>
                <Typography className={classes.textBolder}> {t('details.skills')}</Typography>
                <Grid container>
                  {vista?.id_habilidades.map((data) => (
                    <Box display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography>{data.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                {vista.id_plantillas_recomendadas?.length > 0 ? (
                  <>
                    <Typography className={classes.textBolder}> {t('details.recommendations')}:</Typography>
                    <div className={classes.badges}>{children}</div>
                  </>
                ) : (
                  ''
                )}
              </Grid>
              {vista?.url && (
                <Typography className={classes.link}>
                  {t('details.additional')} <FontAwesomeIcon icon={faShareSquare} size="xs" />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '2em',
  },
  img: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  img_verificado: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      height: theme.spacing(5),
    },
  },
  divider: {
    marginRight: '2em',
  },
  badges: {
    pointerEvents: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
  },
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  link: {
    color: colors.primary,
    textDecoration: 'underline',
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    styles: state.styles,
  };
};

export default connect(mapStateToProps, null)(PreviewDialog);
