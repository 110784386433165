import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Slide,
  CardActions,
} from '@material-ui/core';
import { BadgeOutlined, CloseOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import Backdrop from '../../../components/Backdrop';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { setForm, changeBreadcrump, setCertificateFields, setSaveDesign } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import defaultFields from '../../../utils/defaultFields';
import { env } from '../../../config/environment';
import { useTranslation } from 'react-i18next';
import DesignCarnet from '../DesignCarnet';
import capitalize from '../../../utils/capitalize';

function CreateTemplate(props) {
  const {
    user,
    organizationId,
    form,
    returnRoute,
    setForm,
    certificateFields,
    setCertificateFields,
    changeBreadcrump,
    saveDesign,
    setSaveDesign,
  } = props;
  const history = useHistory();
  const classes = useStyles({ form });
  const imageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDesignCarnet, setOpenDesignCarnet] = useState(false);
  const [carnetsIssued, setCarnetsIssued] = useState([]);
  const [privacy, setPrivacy] = useState([]);

  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'breadCrumps',
    'tables',
  ]);
  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getOrganizations();
      await getPrivacy();

      const isModifying = props.match.params.id ? true : false;
      if (isModifying) {
        await getCarnetTemplate();
        changeBreadcrump(t('breadCrumps:breadCrumps.carnet-template-edit', { returnObjects: true }));
        setSaveDesign(true);
      } else {
        setForm({
          ...form,
          img_prev: form?.img_prev?.split(':', 1)[0] === 'blob' ? null : form.img_prev,
          id_organizaciones:
            user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
          id_usuarios: user.data_user?.id,
        });
        changeBreadcrump(t('breadCrumps:breadCrumps.carnet-template-create', { returnObjects: true }));
      }
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations/names`);
    setOrganizations(data.organizations);
  };

  const getCarnetTemplate = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const { data } = await axios.get(`/carnetTemplate/${id}`);
      const { carnetTemplate, linkPhoto, linkPhotoTemplate, fieldsAdditionals } = data.carnetTemplate;
      setCarnetsIssued(data.carnetTemplate?.carnetTemplate?.carnetsEmitidos);
      setCertificateFields(
        data.carnetTemplate?.carnetTemplate?.carnetCampos.map((field) => ({
          id: field.id,
          fieldType: field.tipo_campo_vp,
          exampleText: field.texto_ejemplo,
          img: field.img ? field.img : null,
          fieldName: field.nombre,
          align: field.formato_letra ? field.formato_letra : 'center',
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width === 0 ? 430 : field.width,
          height: field.height === 0 ? field.tamano_letra : field.height,
        }))
      );
      delete data.carnetTemplate?.carnetTemplate?.carnetsCampos;
      setForm({
        ...carnetTemplate,
        img_prev: linkPhoto,
        img_template: linkPhotoTemplate,
        id_usuarios: user.data_user?.id,
        estado: carnetTemplate.estado,
        fieldsAdditionals: fieldsAdditionals.map((item, index) => ({
          index,
          id: item.id,
          privacidad_vp: item.privacidad_vp,
          nombre_campo: item.nombre_campo,
        })),
      });
    } catch (error) {
      history.push('/500');
    }
  };

  const getPrivacy = async () => {
    const { data } = await axios.post(`/others/getPrivacy`);
    setPrivacy(data.privacy);
  };

  const nombrePrivado = () => {
    if (carnetsIssued !== 0 && props.match.params.id) {
      return true;
    } else {
      return false;
    }
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFile = (e) => {
    const imagen = e.target.files[0];
    if (imagen) {
      setImage(imagen);
      setForm({
        ...form,
        img_prev: URL.createObjectURL(imagen),
        img_template: null,
        imagen: 'newImage',
      });
      certificateFields && setCertificateFields(certificateFields.map((field) => ({ ...field, x: 50, y: 50 })));
      setSaveDesign(false);
    }
  };

  const addFieldAdditional = () => {
    const last = form.fieldsAdditionals[form.fieldsAdditionals?.length - 1];
    setForm({ ...form, fieldsAdditionals: [...form.fieldsAdditionals, { index: last.index + 1 }] });
  };

  const deleteFieldAdditional = (e, index) => {
    let fieldAdditionalDeleted;
    if (form.fieldsAdditionals.length === 0) {
      fieldAdditionalDeleted = [{ index: 1 }];
    } else {
      fieldAdditionalDeleted = form.fieldsAdditionals?.filter((item) => item.index !== index);
    }
    setForm({ ...form, fieldsAdditionals: fieldAdditionalDeleted });
  };

  const handleInputFieldAdditional = (event, index) => {
    form.fieldsAdditionals?.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setForm({ ...form, fieldsAdditionals: form.fieldsAdditionals });
  };

  const goToDesignCarnet = async () => {
    const haveUploadedAnImage = form.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const md5OrganizationRoute = md5(`certika-organization-${organizationId}`).toString();
    const isANewImage = image?.name ? true : false;
    /* const isBlod = form?.img_prev?.split(':', 1)[0] === 'blob' ? true : false;
    const isBlodHorizontal = imageRef.current?.naturalWidth > imageRef.current?.naturalHeight ? true : false;
    const isHorizontal = form.width > form.height ? true : false; */
    if (isANewImage) {
      /* if (
        isBlod &&
        isBlodHorizontal &&
        (imageRef.current?.naturalWidth > 950 ||
          imageRef.current?.naturalHeight > 700 ||
          imageRef.current?.naturalWidth < 750 ||
          imageRef.current?.naturalHeight < 550)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.horizontal-certificates'),
          showConfirmButton: true,
        });
      }
      if (
        isBlod &&
        !isBlodHorizontal &&
        (imageRef.current?.naturalWidth > 668 ||
          imageRef.current?.naturalHeight > 900 ||
          imageRef.current?.naturalWidth < 420 ||
          imageRef.current?.naturalHeight < 690)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.vertical-certificates'),
          showConfirmButton: true,
        });
      }
      if (!isBlod && isHorizontal && (form.width > 950 || form.height > 700 || form.width < 750 || form.height < 550)) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.horizontal-certificates'),
          showConfirmButton: true,
        });
      }
      if (
        !isBlod &&
        !isHorizontal &&
        (form.width > 668 || form.height > 900 || form.width < 420 || form.height < 690)
      ) {
        return Swal.fire({
          icon: 'info',
          text: t('alerts:alerts.vertical-certificates'),
          showConfirmButton: true,
        });
      } */
      setLoading(true);

      const carnetImageForm = new FormData();
      carnetImageForm.append('file', image);
      carnetImageForm.append('route', `${md5OrganizationRoute}/carnetTemplate/carnet-temp/temp`);

      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/file/upload-certificate`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: carnetImageForm,
      });

      if (!certificateFields?.length > 0) {
        const { dataQr, dataImg, dataCode, dataButtonVerify } = defaultFields();
        setCertificateFields([dataQr, dataImg, dataCode, dataButtonVerify]);
      }

      const { data } = await axios.post('/file/getFile', {
        keyFile: `${md5OrganizationRoute}/carnetTemplate/carnet-temp/temp`,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setForm({
        ...form,
        img_prev: data?.url,
        width: imageRef.current?.naturalWidth,
        height: imageRef.current?.naturalHeight,
      });
    }

    const indexField = certificateFields?.findIndex((item) => item.fieldName === 'certificateCode');
    if (indexField !== undefined && indexField !== -1) {
      certificateFields[indexField].exampleText = certificateFields[indexField].exampleText.includes(':')
        ? certificateFields[indexField].exampleText.split(':')[0]
        : '';
    }
    setOpenDesignCarnet(true);
    setLoading(false);
  };

  const saveTemplate = async (e) => {
    e.preventDefault();

    const haveUploadedAnImage = form.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const certificateDesigned = certificateFields?.length > 0;
    if (!certificateDesigned) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-design-certificate'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!saveDesign) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-accepted-design-certificate'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const isANewTemplate = !form.id ? true : false;
    setLoading(true);
    try {
      if (isANewTemplate) {
        delete form.img_template;
        await axios.post(`/carnetTemplate`, {
          ...form,
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
          carnetFields: certificateFields,
          camposAdicionales: form.fieldsAdditionals,
        });
      } else {
        await axios.put(`/carnetTemplate/${form.id}`, {
          ...form,
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
          carnetFields: certificateFields,
          camposAdicionales: form.fieldsAdditionals,
        });
      }
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setLoading(false);
    Swal.fire({
      icon: 'success',
      iconColor: colors.primary,
      text: t('alerts:alerts.saved'),
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      if (isANewTemplate) {
        history.push('/carnets/edited');
      } else {
        history.push(returnRoute);
      }
      setForm(null);
      setCertificateFields(null);
    });
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setForm(null);
    setCertificateFields(null);

    history.push(returnRoute);
  };

  const loadFillDataPrivacy = () => {
    return privacy.map((data) => (
      <MenuItem key={`privacy-${data.id}`} value={data.id}>
        {languajeEn ? data.valor_parametro_en : data.valor_parametro}
      </MenuItem>
    ));
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={saveTemplate}>
            <Typography component="h6" variant="h6">
              {t('certificateBadgeTemplate:badges-certificate-form.details-carnet')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Tooltip title={t('tables:table.tooltip.view-template')}>
                    <Avatar
                      imgProps={{ ref: imageRef }}
                      alt="img-prev"
                      src={form?.img_template ? form?.img_template : form?.img_prev}
                      variant="rounded"
                      className={classes.avatar}
                      onClick={() => setOpen(form?.img_prev ? true : false)}
                    >
                      <BadgeOutlined sx={{ margin: 4, fontSize: '4em' }} />
                    </Avatar>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="body2">
                  {/* {t('certificateBadgeTemplate:badges-certificate-form.carnet-img')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.recommended-size')}
                  <b>{t('certificateBadgeTemplate:badges-certificate-form.horizontal')}</b>
                  {t('certificateBadgeTemplate:badges-certificate-form.least')}
                  <b>950 x 660 </b>
                  {t('certificateBadgeTemplate:badges-certificate-form.pixels')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.carnets')}
                  <b>{t('certificateBadgeTemplate:badges-certificate-form.vertical')} 668 x 890 </b>
                  {t('certificateBadgeTemplate:badges-certificate-form.pixels')} */}
                </Typography>
                <div className={classes.contentInput}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    name="imagen"
                    type="file"
                    disabled={nombrePrivado()}
                    onChange={(e) => handleFile(e)}
                  />
                  <Grid container spacing={2}>
                    <Grid item>
                      <label htmlFor="contained-button-file">
                        <Button variant="outlined" color="primary" component="span" disabled={nombrePrivado()}>
                          {t('buttons:buttons.upload-image')}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        onClick={goToDesignCarnet}
                        disabled={nombrePrivado()}
                      >
                        {t('buttons:buttons.design-carnet')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.carnet-public-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label=""
                    name="nombre"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={form?.nombre}
                    disabled={nombrePrivado()}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.carnet-private-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label=""
                    name="nombre_privado"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={form?.nombre_privado}
                  />
                </FormControl>
              </Grid>

              {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        id="organizacion"
                        name="id_organizaciones"
                        value={form.id_organizaciones || ''}
                        onChange={handleInput}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganization()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.additional-fields')}
            </Typography>
            {form.fieldsAdditionals.map((item, index) => (
              <Grid container key={`grid-activity-${index}`} spacing={2} className={classes.root}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1" className={classes.text} align="right">
                    {`${t('certificateBadgeTemplate:badges-certificate-form.field')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {`${t('certificateBadgeTemplate:badges-certificate-form.field-type')}${
                          item.nombre_campo ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl required variant="outlined" className="w-100" size="small">
                        <Select
                          disabled={nombrePrivado()}
                          id="privacidad"
                          name="privacidad_vp"
                          value={item.privacidad_vp || ''}
                          required={item.nombre_campo ? true : false}
                          onChange={(e) => handleInputFieldAdditional(e, item.index)}
                        >
                          <MenuItem value="">
                            <em>{t('selects:selects-autocompletes.select-option')}</em>
                          </MenuItem>
                          {loadFillDataPrivacy()}
                        </Select>
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {`${t('certificateBadgeTemplate:badges-certificate-form.field-name')}${
                          item.privacidad_vp ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          required={item.privacidad_vp ? true : false}
                          disabled={nombrePrivado()}
                          name="nombre_campo"
                          variant="outlined"
                          size="small"
                          value={item.nombre_campo || ''}
                          rows={4}
                          onChange={(e) => handleInputFieldAdditional(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                    </CardContent>

                    <CardActions>
                      {form.fieldsAdditionals?.length > 0 && (
                        <Button
                          disabled={nombrePrivado()}
                          size="small"
                          onClick={(e) => deleteFieldAdditional(e, item.index)}
                        >
                          <Typography color="textSecondary" variant="body2">
                            <CloseOutlined />
                            {t('buttons:buttons.delete')}
                          </Typography>
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <Button variant="outlined" color="primary" onClick={addFieldAdditional} disabled={nombrePrivado()}>
                  {t('buttons:buttons.add-field')}
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} sm="auto">
                <Button disableElevation type="submit" color="primary" variant="contained" fullWidth>
                  {t('buttons:buttons.save')}
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button disableElevation color="primary" variant="contained" onClick={handleCancel} fullWidth>
                  {t('buttons:buttons.cancel')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.buttonForm}`} onClick={handleClose}>
            <BadgeOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img alt="img-prev" src={form?.img_template ? form?.img_template : form?.img_prev} className={classes.img} />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loading} />
      <Dialog open={openDesignCarnet} fullScreen TransitionComponent={Transition}>
        <DesignCarnet setOpenDesignCarnet={setOpenDesignCarnet} />
      </Dialog>
    </>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  contentInput: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      alignItems: 'left',
    },
  },
  text: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  img: (props) => ({
    maxWidth: '70vw',
    height:
      props.form?.img_prev?.split(':', 1)[0] === 'blob' ? props.imageRef?.current?.naturalHeight : props.form?.height,
  }),
  buttonForm: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    form: state.form
      ? {
          ...state.form,
          fieldsAdditionals: state.form?.fieldsAdditionals?.length > 0 ? state.form?.fieldsAdditionals : [{ index: 1 }],
        }
      : { fieldsAdditionals: [{ index: 1 }], skills: [] },
    certificateFields: state.certificateFields,
    returnRoute: state.returnRoute,
    saveDesign: state.saveDesign,
  };
};

const mapDispatchToProps = {
  setCertificateFields,
  changeBreadcrump,
  setForm,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
