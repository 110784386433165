import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core';
import axios from '../api';
import { encrypt } from '../utils/crypt';
import Appbar from '../components/Appbar';
import colors from '../assets/styles/colors';
import { env } from '../config/environment';

function LinkedinShare(props) {
  const { user, badge, location, history, typeBrand } = props;
  const classes = useStyles();
  const [access, setAccess] = useState(null);
  const [description, setDescription] = useState(
    `He obtenido la siguiente insignia emitida por ${badge.plantillasInsignias?.organizaciones.nombre}${
      typeBrand === 'Certika' ? ' vía #certikaco' : '.'
    }`
  );

  useEffect(() => {
    const code = location.search.substr(props.location.search.indexOf('?code=') + 6);

    const loginLinkedin = async () => {
      try {
        const {
          data: { token, userId },
        } = await axios.get(`/share/login/linkedin/${code}`);

        setAccess({ token, userId });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ha ocurrido un error',
        });
      }
    };

    loginLinkedin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = async () => {
    try {
      await axios.post('/share/linkedin', {
        ...access,
        content: {
          title: `${badge.plantillasInsignias?.nombre} fue emitida por ${
            badge.plantillasInsignias?.organizaciones.nombre
          } a ${badge.primer_nombre_receptor} ${badge.segundo_nombre_receptor || ''} ${
            badge.primer_apellido_receptor
          } ${badge.segundo_apellido_receptor || ''}`,
          description: description,
          link: `${env.certificateURL}${encrypt(badge.id)}`,
        },
      });

      await axios.post('/share/statisticSocialNetwork', {
        id_redes_sociales: 3,
        id_insignias_emitidas: badge.id,
        id_usuarios: user.id,
      });

      Swal.fire({
        icon: 'success',
        title: 'Publicado',
        text: 'Se ha publicado el artículo',
      }).then(() => {
        window.close();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error',
      });
    }
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  if (user === null) {
    history.push('/');
  }

  return (
    <>
      <Appbar />
      <div className={classes.container}>
        <div className={classes.container__share}>
          <p>Descripción:</p>
          <textarea
            name="description"
            value={description}
            className={classes.container__share_input}
            onChange={handleChange}
          />
        </div>
        <button className={classes.container__button} onClick={handleShare}>
          Compartir
        </button>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80vh',
    width: '100vw',
  },
  container__share: {
    '& p': {
      marginBottom: 5,
      fontWeight: 'bold',
    },
  },
  container__share_input: {
    border: '1px solid',
    borderRadius: 30,
    borderColor: colors.primary,
    padding: 10,
    width: '80vw',
    marginBottom: 20,
  },
  container__button: {
    borderRadius: 20,
    padding: '10px 30px',
    backgroundColor: colors.primary,
    color: 'white',
    fontWeight: 'bold',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    badge: state.badge?.insigniasEmitidas,
    typeBrand: state.styles?.organizationName,
  };
};

export default connect(mapStateToProps, null)(LinkedinShare);
