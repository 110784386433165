const arrayObjToCsv = (contenido, templateSelected) => {
  if (window.Blob && (window.URL || window.webkitURL)) {
    var blob, reader, save, clicEvent;
    blob = new Blob(['\ufeff', contenido], { type: 'text/csv' });
    reader = new FileReader();
    reader.onload = function (event) {
      save = document.createElement('a');
      save.href = event.target.result;
      save.target = '_blank';
      save.download = `${templateSelected?.nombre || 'issue_certificates'}.csv`;
      try {
        clicEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
      } catch (e) {
        clicEvent = document.createEvent('MouseEvent');
        clicEvent.initEvent('click', true, true);
      }
      save.dispatchEvent(clicEvent);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    };

    reader.readAsDataURL(blob);
  } else {
    alert('Su navegador no permite esta acción');
  }
};

export default arrayObjToCsv;
