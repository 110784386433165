import React, { useState, useEffect } from 'react';
import { Card, Grid, Typography, makeStyles, Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import api from '../api';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '3.5em',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
  },
  text: {
    marginTop: '1em',
    marginBottom: '1em',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  avatar: {
    width: '54px',
    height: '54px',
    '& img': {
      height: 'min-content',
    },
    marginLeft: '.5em',
  },
  nombre: {
    textAlign: 'left',
  },
}));

function Organizations(props) {
  const classes = useStyles();
  const { organizationId } = props;
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState({
    nombre: '',
    logo: null,
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const { data } = await api.get(`/organization/${organizationId}`);
    const { organizations } = data.organization;
    setOrganization({
      ...organizations,
      logo: organizations.linkPhoto,
    });
    setLoading(false);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <Grid container>
        <Grid item xs={4} lg={3}>
          {loading ? (
            <Skeleton variant="circle" className={classes.avatar} />
          ) : (
            <Avatar aria-label="profile" src={organization.logo} className={classes.avatar} />
          )}
        </Grid>
        <Grid item xs={8} lg={9} className={!loading && classes.nombre}>
          {loading ? (
            <Skeleton variant="rect" height="3.5em" />
          ) : (
            <Typography className={classes.text}>{organization.nombre}</Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

export default connect(mapStateToProps, null)(Organizations);
