import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { env } from '../../config/environment';
import {
  Avatar,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slide,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material';
import axios from '../../api';
import Appbar from '../../components/Appbar';
import { useTranslation } from 'react-i18next';
import { decrypt } from '../../utils/crypt';
import dataURItoBlob from '../../utils/dataUriToBlob';
import signatureIconDefault from '../../assets/img/signature-icon.png';
import signatureIcon from '../../assets/img/pen_5502.png';
import MenuCertificateItem from '../../components/MenuCertificateItem';
import SignatureDrawing from '../../components/SignatureDrawing';
import SignatureText from '../../components/SignatureText';
import SignatureFile from '../../components/SignatureFile';
import Backdrop from '../../components/Backdrop';
import colors from '../../assets/styles/colors';
import webFont from 'webfontloader';
import Swal from 'sweetalert2';
import md5 from 'crypto-js/md5';
import axiosFile from 'axios';
import moment from 'moment';
import ViewPDF from '../../components/PreviewPdfDocument';
import { login } from '../../actions/index';

function Signatories(props) {
  const classes = useStyle();
  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'tables',
    'dialogOtp',
  ]);
  const [loadingSignatures, setLoadingSignatures] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(true);
  const [documentsFields, setDocumentsFields] = useState([]);
  const [formDocuments, setFormDocuments] = useState([]);
  const [expandSignature, setExpandSignature] = useState(true);
  const [valueTab, setValueTab] = useState(0);
  const [openSign, setOpenSign] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [expandSignatures, setExpandSignatures] = useState(true);
  const [responsiveWidth, setResponsiveWidth] = useState(
    window.innerWidth < 660 ? window.innerWidth - window.innerWidth * 0.3 : false
  );
  const [contador, setContador] = useState(30);
  const [dialogOtp, setDialogOtp] = useState({
    title: '',
    view: false,
    message: '',
    thisNoNumberMessage: '',
    reSendCodeMessage: '',
    invalidCode: false,
    codeOtp: '',
    signatorieEmail: '',
    correctNumber: false,
    type: 0,
    labelButtom: '',
    label_no_number: '',
  });
  const fontsFamilysTypeHand = [
    'Caveat',
    'Caveat Brush',
    'Cookie',
    'Architects Daughter',
    'Cedarville Cursive',
    'Dawning of a New Day',
    'Crafty Girls',
    'League Script',
  ];
  const idSignature = props.match.params.idSignature;
  const indexSignatorie = documentsFields.findIndex((item) => `${item.idSignature}` === decrypt(idSignature));
  const md5OrganizationRoute = md5(`certika-organization-${formDocuments?.id_organizaciones}`).toString();
  const md5UserRoute = md5(`cer
    tika-user-${props?.user?.data_user?.id}`).toString();
  const refImage = useRef([]);
  const refImageHD = useRef([]);

  useEffect(() => {
    setLoading(true);
    if (props?.user && !window.location.href.includes('/document')) {
      // Si hay usuario logeado y no viene del dashboard
      window.location.href = `/sign-in/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`;
    } else if (!window.location.href.includes('/document')) {
      // Si no hay usuario logueado y no viene del dashboard
      validateAccount();
    } else {
      // hay usuario logeado y viene del dashboard
      setDialogOtp({ ...dialogOtp, view: false });
      validSignature();
      loadTemplate();
      getIp();
      axios.post(`/signature/getSignatures`).then(({ data }) => {
        setSignatures(data?.signatures);
      });

      webFont.load({ google: { families: fontsFamilysTypeHand } });
      // cuando viene del dashboard es porque está confirmado que es el firmante
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        setResponsiveWidth(window.innerWidth < 660 ? window.innerWidth - window.innerWidth * 0.3 : false);
      },
      true
    );
  }, []);

  useEffect(() => {
    if (contador === 0) return;
    if (contador === 30) return;
    const timer = setTimeout(() => {
      setContador(contador - 1);
    }, 1000); // Incrementa el contador cada 1000 milisegundos (1 segundo)

    return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
  }, [contador]);

  const validateAccount = async () => {
    if (props.match.params.documentTemplateId && props.match.params.idSignature) {
      try {
        const { data } = await axios.get(`/signature/receiver/${idSignature}`);
        if (data.signature === null) {
          return Swal.fire({
            customClass: {
              container: classes.mySwal,
              confirmButton: 'custom-button-confirm',
            },
            icon: 'warning',
            iconColor: colors.primary,
            text: t('alerts:alerts.document-have-signature'),
          }).then(() => {
            window.location.href = '/';
          });
        }

        if (data.signature.usuarios.whatsapp) {
          setDialogOtp({
            ...dialogOtp,
            title: t('dialogOtp:otp-dialog.title'),
            type: 0,
            view: true,
            correctNumber: false,
            message: `${t('dialogOtp:otp-dialog.message-informated')} +${data.signature.usuarios.whatsapp} ${t(
              'dialogOtp:otp-dialog.message-informated2'
            )} ${data.signature.cuentas.correo}`,
            thisNoNumberMessage: t('dialogOtp:otp-dialog.info-number'),
            signatorieEmail: data.signature.cuentas.correo,
            codeOtp: '',
            invalidCode: false,
            labelButtom: t('buttons:buttons.send'),
            label_no_number: t('dialogOtp:otp-dialog.is-not-my-number'),
          });
          setContador(30);
          setLoadingDialog(false);
        } else {
          if (data.signature.cuentas?.estado === true || data.signature.cuentas?.estado === 1) {
            /* si tiene cuenta */
            window.location.href = `/sign-in/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`;
          } else {
            /* si no tiene cuenta */
            window.location.href = `/sign-up/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`;
          }
        }
      } catch (error) {
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.error-ocurred'),
          showConfirmButton: true,
          buttonsStyling: false,
          customClass: {
            container: classes.mySwal,
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => {
          window.location.href = '/';
        });
      }
    }
  };

  const onlyNumber = (value) => {
    // eslint-disable-next-line no-useless-escape
    const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;

    return !patron.test(value);
  };

  const validCodeOtp = async () => {
    try {
      if (dialogOtp.type === 0) {
        setLoadingButton(true);
        await axios.post(`/signature/otp_code/${idSignature}`);
        setDialogOtp({
          ...dialogOtp,
          type: 1,
          message: t('dialogOtp:otp-dialog.input-code'),
          thisNoNumberMessage: '',
          reSendCodeMessage: t('dialogOtp:otp-dialog.no-received-sms'),
          correctNumber: true,
        });
        setLoadingButton(false);
        contador !== 0 && setContador(contador - 1);
      }
      if (dialogOtp.type === 1) {
        const email = dialogOtp.signatorieEmail;
        const code = dialogOtp.codeOtp;

        setLoadingButton(true);
        if (code === '' || code.length !== 6) {
          setLoadingButton(false);
          setDialogOtp({
            ...dialogOtp,
            invalidCode: true,
          });
          return;
        }
        setDialogOtp({ ...dialogOtp, invalidCode: false });
        const isValidCode = await axios.post('/signature/valid/otp_code', { code, email });
        if (isValidCode.data.message) {
          await props.login(
            { correo: email, password: code },
            `/sign-in/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
          );
        } else {
          setDialogOtp({ ...dialogOtp, invalidCode: true });
          setLoadingButton(false);
        }
      }
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      }).then(() => {
        window.location.href = '/';
      });
    }
  };

  const getIp = async () => {
    try {
      const { data: ipData } = await axiosFile({
        method: 'get',
        url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
      });
      setFormDocuments({ ...formDocuments, ip_adress: ipData.ip });
    } catch (error) {
      return Swal.fire({
        icon: 'warning',
        iconColor: colors.primary,
        text: t('alerts:alerts.warning-block'),
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      });
    }
  };

  const validSignature = async () => {
    const { data } = await axios.get(`/signature/receiver/${idSignature}`);
    if (data?.signature === null) {
      return Swal.fire({
        icon: 'warning',
        iconColor: colors.primary,
        text: t('alerts:alerts.document-already'),
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      }).then(() => {
        window.location.href = '/';
      });
    }
  };

  const loadTemplate = async () => {
    const id = decrypt(props.match.params.documentTemplateId);
    const { data } = await axios.get(`/documentTemplate/${id}`);
    if (data.documentTemplate && data.documentTemplate.documentTemplate.documentoEmitido[0].estado === -1) {
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.document-no-available'),
        showConfirmButton: true,
      }).then(() => {
        window.location.href = '/';
      });
    }
    if (data.documentTemplate) {
      const { documentTemplate, linkPhoto, linkPhotoHD, linkPhotoTemplate } = data.documentTemplate;
      setDocumentsFields(
        data.documentTemplate?.documentTemplate?.documentoCampos.map((field) => ({
          id: field.id,
          fieldTypeConfig: field.nombre === '' || field.nombre === 'certificateCode' ? 51 : 52,
          fieldType: field.tipo_campo_vp,
          exampleText: field.texto_ejemplo,
          idSignature: field.nombre.includes('signature') ? field.idSignature : null,
          fieldName: field.nombre,
          align: field.formato_letra ? field.formato_letra : 'center',
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width === 0 ? 'auto' : field.width,
          height: field.height === 0 ? field.tamano_letra : field.height,
          page: field.page,
        }))
      );
      delete data.documentTemplate?.documentTemplate?.documentoCampos;
      setFormDocuments({
        ...documentTemplate,
        img_prev: linkPhoto,
        img_prevHD: linkPhotoHD,
        img_template: linkPhotoTemplate,
        id_usuarios: documentTemplate.documentoEmitido[0].documentos[0].id_cuentas,
        estado: documentTemplate.estado,
        pages: linkPhoto.length,
        page: 1,
        fileName: 'documento',
      });
      setLoading(false);
      refImage.current = [];
      refImageHD.current = [];
    }
  };

  const openModalSign = (e) => {
    setValueTab(0);
    setOpenSign(true);
  };

  const addSign = async (signature) => {
    Swal.fire({
      icon: 'warning',
      iconColor: colors.primary,
      text: t('alerts:alerts.sure-selected-signature'),
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
      },
    }).then((res) => {
      if (res.isConfirmed) {
        const indexSignatorie = documentsFields.findIndex((item) => `${item.idSignature}` === decrypt(idSignature));
        documentsFields[indexSignatorie] = {
          ...documentsFields[indexSignatorie],
          exampleText: signature.url,
          originalIdSign: signature.id,
        };
        setDocumentsFields([...documentsFields]);
        setLoading(true);
        setTimeout(() => {
          signDocument();
          setLoading(false);
        }, 2000);
      }
    });
    const indexSignatorie = documentsFields.findIndex((item) => `${item.idSignature}` === decrypt(idSignature));
    documentsFields[indexSignatorie] = {
      ...documentsFields[indexSignatorie],
      exampleText: signature.url,
      originalIdSign: signature.id,
    };
    setDocumentsFields([...documentsFields]);
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  const closeModalSign = () => {
    setOpenSign(false);
  };

  const saveSign = async (stageRef) => {
    Swal.fire({
      icon: 'warning',
      iconColor: colors.primary,
      text: t('alerts:alerts.sure-sign'),
      showConfirmButton: true,
      confirmButtonText: t('alerts:alerts.yes-option'),
      cancelButtonText: 'No',
      showCancelButton: true,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
      buttonsStyling: false,
    }).then((res) => {
      if (res.isConfirmed) {
        const uri = stageRef.current.toDataURL();
        documentsFields[indexSignatorie] = {
          ...documentsFields[indexSignatorie],
          exampleText: uri,
        };
        setDocumentsFields([...documentsFields]);
        setOpenSign(false);
        setLoading(true);
        setTimeout(() => {
          signDocument();
          setLoading(false);
        }, 2000);
      }
    });
  };

  const signDocument = async (e) => {
    e && e.preventDefault();
    const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
    let uriSignature;
    let ip_adress = formDocuments?.ip_adress;

    if (!formDocuments?.ip_adress) {
      try {
        const { data: ipData } = await axiosFile({
          method: 'get',
          url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
        });
        ip_adress = ipData.ip;
      } catch (error) {
        return Swal.fire({
          icon: 'warning',
          iconColor: colors.primary,
          text: t('alerts:alerts.warning-block'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            container: classes.mySwal,
            confirmButton: 'custom-button-confirm',
          },
        });
      }
    }

    if (documentsFields[indexSignatorie]?.exampleText?.includes('signatorie')) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-sign-the-document'),
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      });
    }
    setLoadingSignatures(true);
    await Promise.all(
      documentsFields.map(async (item) => {
        if (item.fontColor) {
          item.fontColor = item.fontColor.hex;
        }
        if (item.rectColor) {
          item.rectColor = item.rectColor.hex;
        }
        if (`${item.idSignature}` === decrypt(idSignature)) {
          item.signCurrent = true;
          if (item.exampleText.includes('data')) {
            const signature = new FormData();
            const blobData = dataURItoBlob(item.exampleText);
            const routeSign = `${md5OrganizationRoute}/documentsTemplate/signatures/${md5UserRoute}/${idSignature}.png`;

            signature.append('file', blobData);
            signature.append('route', routeSign);
            signature.append('signatorie', true);
            const { data } = await axiosFile({
              method: 'post',
              url: `${env.apiURL}/file/upload-certificate`,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: signature,
            });
            uriSignature = item.exampleText;
            item.exampleText = data.url;
            formDocuments.routeSignature = routeSign;
          } else {
            item.idSignature = item.originalIdSign;
          }
        }
        return item;
      })
    );

    let realWidth = refImage.current[0].width;
    let realHeight = refImage.current[0].height;
    // eslint-disable-next-line
    refImage.current.map((item) => {
      if (realWidth < item.width) {
        realWidth = item.width;
      }
      if (realHeight < item.height) {
        realHeight = item.height;
      }
    });

    let realWidthHD = refImageHD.current[0].width;
    let realHeightHD = refImageHD.current[0].height;
    const sizes = [];
    // eslint-disable-next-line
    refImageHD.current.map((item) => {
      if (realWidthHD < item.width) {
        realWidthHD = item.width;
      }
      if (realHeightHD < item.height) {
        realHeightHD = item.height;
      }
      sizes.push({ width: item.width, height: item.height });
    });
    const saltosWidth = parseInt((100 * (realWidthHD - realWidth)) / realWidth);
    const saltosHeight = parseInt((100 * (realHeightHD - realHeight)) / realHeight);

    const trailDate = moment(new Date()).format('LLLL');

    const dataToSend = {
      ...formDocuments,
      ip_adress,
      idSignature,
      sizes,
      saltosWidth,
      saltosHeight,
      trailDate: trailDate,
    };

    try {
      await axios
        .post(`/documentsCertify/signatureDocument`, {
          dataToSend,
          documentsFields,
        })
        .then((data) => {
          Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());
          if (data.data) {
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.successfully-signed'),
              showConfirmButton: true,
              confirmButtonText: t('buttons:buttons.accept'),
              buttonsStyling: false,
              customClass: {
                container: classes.mySwal,
                confirmButton: 'custom-button-confirm',
              },
            })
              .then(() => {
                setFormDocuments({ ...formDocuments, pdf_documentoEmitido: data.data });
                const link = document.createElement('a');
                link.download = `${formDocuments.nombre}`;
                link.href = data.data;
                link.click();
              })
              .then(() => {
                setTimeout(() => {
                  window.location.href = '/';
                }, 3000);
              });
          } else {
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              title: t('alerts:alerts.successfully-signed'),
              text: t('alerts:alerts.successfully-signed-text-still-parts'),
              showConfirmButton: true,
              confirmButtonText: t('buttons:buttons.accept'),
              buttonsStyling: false,
              customClass: {
                container: classes.mySwal,
                confirmButton: 'custom-button-confirm',
              },
            }).then(() => {
              window.location.href = '/';
            });
          }
          setLoadingSignatures(false);
        });
    } catch (error) {
      documentsFields.map(async (item) => {
        if (item.fontColor) {
          item.fontColor = { hex: item.fontColor };
        }
        if (item.rectColor) {
          item.rectColor = { hex: item.rectColor };
        }
        if (`${item.idSignature}` === decrypt(idSignature)) {
          item.exampleText = uriSignature;
        }
        return item;
      });
      setLoadingSignatures(false);
      return Swal.fire({
        customClass: { container: classes.mySwal },
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const addToRef = (el) => {
    if (el && !refImage.current.includes(el)) {
      refImage.current.push(el);
    }
  };

  const addToRefHD = (el) => {
    if (el && !refImageHD.current.includes(el)) {
      refImageHD.current.push(el);
    }
  };

  const handleInput = (e) => {
    if (e.target.value.length > 6) return;

    setDialogOtp({ ...dialogOtp, codeOtp: e.target.value, invalidCode: false });
  };

  const unknownNumber = async () => {
    try {
      const id = props.match.params.documentTemplateId;
      setLoadingButton(true);
      await axios.post(`/signature/otp_code/not_number/${idSignature}_${id}`);
      setDialogOtp({
        ...dialogOtp,
        title: t('dialogOtp:otp-dialog.title_no_number'),
        labelButtom: 'OK',
        thisNoNumberMessage: '',
        message: t('dialogOtp:otp-dialog.message_error_number'),
        label_no_number: '',
      });
      setLoadingButton(false);
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      }).then(() => {
        window.location.href = '/';
      });
    }
  };

  const exitNoNumber = async () => {
    props.history.push('/');
  };

  const resendCodeOtp = async () => {
    try {
      setLoadingButton(true);
      setContador(30);
      await axios.post(`/signature/otp_code/resend/${idSignature}`);
      setContador(29);
      setLoadingButton(false);
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        buttonsStyling: false,
        customClass: {
          container: classes.mySwal,
          confirmButton: 'custom-button-confirm',
        },
      }).then(() => {
        window.location.href = '/';
      });
    }
  };

  return (
    <>
      {loading ? (
        <Backdrop loading={loadingDialog} />
      ) : (
        <>
          <Dialog open={!loading} fullScreen TransitionComponent={Transition}>
            <Backdrop loading={loadingSignatures} />
            <Appbar signature={true} />
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={8} lg={8} className={classes.container__certificate}>
                <ViewPDF
                  pdf={formDocuments.pdf_documentoEmitido}
                  download={false}
                  handleClose={() => {}}
                  fullView={false}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4} className={classes.container__fields}>
                <MenuCertificateItem
                  title={t('certificateBadgeTemplate:certificate-design.signatures')}
                  logo={signatureIcon}
                  expand={expandSignature}
                  onClick={() => {
                    setExpandSignature(!expandSignature);
                    setExpandSignatures(true);
                  }}
                />
                <Collapse in={expandSignature}>
                  <List>
                    <ListItem button onClick={openModalSign}>
                      <ListItemIcon>
                        <img src={signatureIconDefault} alt="sign" />
                      </ListItemIcon>
                      <ListItemText style={{ textAlign: 'center' }} primary={t('buttons:buttons.add-signature')} />
                    </ListItem>
                    {signatures.length > 0 && (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Divider style={{ width: '85%' }} />
                          <IconButton onClick={() => setExpandSignatures(!expandSignatures)}>
                            {expandSignatures ? (
                              <ExpandLessOutlined size="small" />
                            ) : (
                              <ExpandMoreOutlined size="small" />
                            )}
                          </IconButton>
                        </div>
                        {signatures.map((signature) => {
                          return (
                            <Collapse in={expandSignatures}>
                              <List>
                                <ListItem button onClick={() => addSign(signature)}>
                                  <ListItemIcon>
                                    <img src={signature.url} alt="sign" width={200} />
                                  </ListItemIcon>
                                </ListItem>
                              </List>
                            </Collapse>
                          );
                        })}
                      </>
                    )}
                  </List>
                </Collapse>
              </Grid>
            </Grid>
          </Dialog>
          <Dialog open={openSign} fullWidth={true} maxWidth="sm">
            <DialogContent>
              <Tabs
                value={valueTab}
                onChange={handleChangeTabs}
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
              >
                {!responsiveWidth && <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.draw')} />}
                <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.write')} />
                <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.upload')} />
              </Tabs>
              <Grid container>
                {valueTab === 0 && !responsiveWidth && (
                  <SignatureDrawing
                    closeModalSign={closeModalSign}
                    saveSign={saveSign}
                    progressBar={{}}
                    emailUser={props.user?.correo}
                    responsiveWidth={responsiveWidth}
                  />
                )}
                {((valueTab === 1 && !responsiveWidth) || (responsiveWidth && valueTab === 0)) && (
                  <SignatureText
                    closeModalSign={closeModalSign}
                    saveSign={saveSign}
                    nameUser={`${props.user.data_user.primer_nombre} ${props.user.data_user.primer_apellido}`}
                    fontsFamilys={fontsFamilysTypeHand}
                    progressBar={{}}
                    emailUser={props.user.correo}
                    responsiveWidth={responsiveWidth}
                  />
                )}
                {((valueTab === 2 && !responsiveWidth) || (responsiveWidth && valueTab === 1)) && (
                  <SignatureFile
                    closeModalSign={closeModalSign}
                    saveSign={saveSign}
                    progressBar={{}}
                    emailUser={props.user.correo}
                  />
                )}
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      )}

      <Dialog open={dialogOtp.view} fullWidth>
        <Typography align="center" variant="h6" style={{ margin: '1rem' }}>
          {dialogOtp.title}
        </Typography>

        <DialogContent>
          <Grid container spacing={2} className="w-100">
            <>
              <Grid item xs={12}>
                <Typography
                  align={dialogOtp.correctNumber ? 'center' : 'start'}
                  variant="h6"
                  style={{ textAlign: 'justify' }}
                >
                  {dialogOtp.message}
                </Typography>
              </Grid>

              {dialogOtp.correctNumber ? (
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="w-100">
                    <TextField
                      error={dialogOtp.invalidCode}
                      required
                      variant="outlined"
                      name="codeOtp"
                      type="number"
                      value={dialogOtp.codeOtp}
                      onChange={handleInput}
                      onKeyDown={onlyNumber}
                      helperText={dialogOtp.invalidCode ? t('dialogOtp:otp-dialog.invalid_code') : ''}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography align="start" style={{ fontSize: '14px' }}>
                    {dialogOtp.thisNoNumberMessage}
                  </Typography>
                  <p className={classes.style_a} onClick={() => unknownNumber()}>
                    {dialogOtp.label_no_number}
                  </p>
                </Grid>
              )}
              {dialogOtp.correctNumber && dialogOtp.reSendCodeMessage !== '' && (
                <Grid item xs={12}>
                  <p
                    className={contador === 0 ? classes.style_a : classes.style_a_disabled}
                    onClick={() => contador === 0 && resendCodeOtp()}
                  >
                    {`${dialogOtp.reSendCodeMessage} ${contador === 0 ? '' : `(${contador}s)`}`}
                  </p>
                </Grid>
              )}
            </>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonCancel}
            disableElevation
            onClick={() => !loadingButton && (dialogOtp.labelButtom === 'OK' ? exitNoNumber() : validCodeOtp())}
          >
            {loadingButton ? <CircularProgress size={24} color="inherit" /> : dialogOtp.labelButtom}
          </Button>
          {dialogOtp.correctNumber && (
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonCancel}
              disableElevation
              onClick={() => validateAccount()}
            >
              {t('buttons:buttons.back')}
            </Button>
          )}
          {!dialogOtp.correctNumber && (
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonCancel}
              disableElevation
              onClick={() => (window.location.href = '/sign-in')}
            >
              {t('buttons:buttons.cancel')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {formDocuments.img_prev &&
        formDocuments.img_prev.map((item) => {
          return <Avatar imgProps={{ ref: addToRef }} src={item} style={{ display: 'none' }}></Avatar>;
        })}
      {formDocuments.img_prevHD &&
        formDocuments.img_prevHD.map((item) => {
          return <Avatar imgProps={{ ref: addToRefHD }} src={item} style={{ display: 'none' }}></Avatar>;
        })}
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E5E5E5',
  },
  container_stage: (props) => ({
    marginTop: 32,
    backgroundImage: `url(${props.imageRoute})`,
    backgroundRepeat: 'no-repeat',
    width: 'fit-content',
    maxWidth: '68vw',
  }),
  container__certificate: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
  container__fields: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  container__button_fields: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    margin: 10,
    padding: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
    '& p': {
      marginBottom: 0,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  content_modal: {
    display: 'grid',
    gridTemplateColumns: '35% 65%',
    gridTemplateRows: '',
  },
  dialog__title: {
    backgroundColor: theme.palette.primary.main,
  },
  sweetModal: {
    zIndex: 1300,
  },
  mySwal: {
    zIndex: 3000,
  },
  fieldButtonDisabled: {
    display: 'none',
  },
  root_info: {
    height: 180,
  },
  container_info: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: '1em',
  },
  buttonMinimize: {
    display: 'flex',
    justifyContent: 'right',
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: '1em',
  },
  pagination: {
    marginTop: '3px',
    position: 'absolute',
  },
  style_a: {
    fontSize: '12px',
    color: '#0056b3',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  style_a_disabled: {
    fontSize: '12px',
    color: '#6c757d',
    cursor: 'default',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  login,
};
export default connect(mapStateToProps, mapDispatchToProps)(Signatories);
