const onlyNumber = (event) => {
  const value = String(event.target.value);
  // eslint-disable-next-line no-useless-escape
  const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;

  if (!patron.test(value)) {
    event.target.value = value.substr(value.length);
  }
};

export default onlyNumber;
