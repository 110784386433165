import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { MergeTypeOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import colors from '../../assets/styles/colors';
import { makeStyles } from '@material-ui/core/styles';
import Appbar from '../../components/Appbar';
import api from '../../api';
import Swal from 'sweetalert2';
import Backdrop from '../../components/Backdrop';
import Table from '../../components/Table';
import SkeletonTables from '../../components/SkeletonTable';
import { loginRequest } from '../../actions';

function CombineAccount(props) {
  const { user, page, rowsPerPage, loginRequest } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [view, setView] = useState(false);
  const [form, setForm] = useState({
    accountCurrent: user?.data_user.cuentas[0].correo,
    accountToCombine: '',
    passwordAccountToCombine: '',
  });
  const [Logo, setLogo] = useState('');

  useEffect(() => {
    if (!user) {
      history.push('/');
      window.location.reload();
    } else {
      const styles = JSON.parse(window.localStorage.state)?.styles;
      if (styles) {
        setLogo(styles.logo435x149);
      }
      setLoadingData(true);
      getAccount();
    }
    // eslint-disable-next-line
  }, []);

  const getAccount = async () => {
    const { data } = await api.post(`/user/getAccounts`);
    data.accounts.map(async (user) => {
      let { data: photo } = await api.post(`/user/photo`);
      user.linkPhoto = photo.photo;
      return user;
    });
    setAccounts(data.accounts);
    setLoadingData(false);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    setForm({
      ...form,
      accountToCombine: '',
      passwordAccountToCombine: '',
    });
    setView(false);
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'uncombine':
        modalUncombine(id);
        break;
      default:
        break;
    }
  };

  const modalUncombine = (correo) => {
    Swal.fire({
      customClass: {
        container: classes.mySwal,
      },
      text: '¿Está seguro que desea descombinar este usuario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: colors.primary,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.value) {
        sendUncombine(correo);
      }
    });
  };

  const sendUncombine = async (correo) => {
    try {
      await api.post(`/user/uncombine`, { correo: correo }).then(() => {
        setAccounts(accounts.filter((item) => item.correo !== correo));
        user.data_user.cuentas = user.data_user.cuentas.filter((item) => item.correo !== correo);
        loginRequest(user);
        Swal.fire({
          text: 'Cuentas descombinadas exitosamente.',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      });
    } catch (error) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        text: 'No se pudo descombinar.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleToggle();

    // eslint-disable-next-line
    const combinated = accounts.map((correos) => {
      if (correos.correo) return correos.correo;
    });

    if (form.accountCurrent === form.accountToCombine) {
      Swal.fire({
        icon: 'error',
        text: 'Las cuentas no pueden ser iguales.',
        showConfirmButton: false,
        timer: 1500,
      });
      handleClose();
    } else if (combinated.includes(form.accountToCombine)) {
      Swal.fire({
        icon: 'error',
        text: 'Esta cuenta ya fue combinada.',
        showConfirmButton: false,
        timer: 1500,
      });
      handleClose();
    } else {
      api({
        url: `/user/sign-in`,
        method: 'post',
        auth: {
          username: form.accountToCombine,
          password: form.passwordAccountToCombine,
        },
      })
        .then(({ data }) => {
          api
            .post(`/user/combine`, { accountCurrent: user?.data_user?.cuentas[0]?.id, accountCombinate: data?.id })
            .then((res) => {
              if (res) {
                handleClose();
                Swal.fire({
                  icon: 'success',
                  text: `Se ha enviado una solicitud al correo '${data.correo}', acéptala para continuar la combinación de cuenta.`,
                  showConfirmButton: true,
                }).then(() => {
                  history.push('/dashboard');
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                text: 'Ha ocurrido un error.',
                showConfirmButton: false,
                timer: 1500,
              });
              handleClose();
              history.push('/500');
              window.location.reload();
            });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            text: 'Usuario o contaseña incorrecta.',
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  return (
    <div className="App">
      {user && <Appbar />}
      <div className={classes.AppHeader}>
        <Card className={classes.card} elevation={0}>
          <CardContent>
            <img src={Logo} alt="logo" className={classes.img} />
            {!view ? (
              <>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Table tableSmall={true} columns={columns} rows={accounts} loading={loadingData}>
                      <TableBody>
                        {loadingData ? (
                          <SkeletonTables columns={columns} photo={1} />
                        ) : (
                          <>
                            {accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                              <TableRow key={row.id} align="center">
                                <TableCell align="center">
                                  <Avatar alt={row.correo} src={row?.linkPhoto} className={classes.large} />
                                </TableCell>
                                <TableCell align="center">{row.correo}</TableCell>
                                <TableCell align="center">
                                  {index !== 0 && (
                                    <Tooltip title="Descombinar">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={(e) => handleClick(e, row.correo, 'uncombine')}
                                      >
                                        <DeleteForeverOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
                <div className={`text-right ${classes.añadir}`}>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={`my-2 ${classes.button}`}
                    onClick={() => setView(true)}
                  >
                    Combinar una cuenta
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography component="h6" variant="h6">
                  Combinar cuentas
                </Typography>
                <form className={classes.root} onSubmit={handleSubmit}>
                  <Grid container spacing={1} justify="center">
                    {user && (
                      <Grid item xs={12}>
                        <FormControl className="w-50">
                          <TextField
                            required
                            disabled={true}
                            name="accountCurrent"
                            variant="outlined"
                            value={form.accountCurrent}
                            InputProps={{
                              classes: {
                                root: classes.container__input_root,
                                notchedOutline: classes.container__input_notchedOutline,
                                focused: classes.container__input_focused,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item className="text-center">
                      <MergeTypeOutlined sx={{ color: colors.primary }} />
                    </Grid>
                    {user && (
                      <Grid item xs={12}>
                        <FormControl className="w-50">
                          <TextField
                            required
                            label="Correo electrónico"
                            name="accountToCombine"
                            variant="outlined"
                            onChange={handleInput}
                            value={form.accountToCombine}
                            InputProps={{
                              classes: {
                                root: classes.container__input_root,
                                notchedOutline: classes.container__input_notchedOutline,
                                focused: classes.container__input_focused,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl className="w-50">
                        <TextField
                          required
                          label="Contraseña"
                          name="passwordAccountToCombine"
                          variant="outlined"
                          type="password"
                          onChange={handleInput}
                          InputProps={{
                            classes: {
                              root: classes.container__input_root,
                              notchedOutline: classes.container__input_notchedOutline,
                              focused: classes.container__input_focused,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div className={`text-center ${classes.root}`}>
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      className={`my-2 ${classes.button}`}
                      type="submit"
                    >
                      Combinar
                    </Button>
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      className={`my-2 ${classes.button}`}
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                  </div>
                </form>
              </>
            )}
          </CardContent>
        </Card>
        <Backdrop loading={loading} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  AppHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    marginTop: '1em',
    [theme.breakpoints.up('sm')]: {
      margin: '1em 6em 0 6em',
    },
  },
  card: {
    minWidth: 275,
    marginTop: '1em',
  },
  img: {
    width: theme.spacing(40),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  container__input_root: {
    borderRadius: '20px',
  },
  container__input_notchedOutline: {
    borderWidth: 2,
    borderColor: colors.primary,
  },
  container__input_focused: {},
  button: {
    margin: '0.5em',
    padding: '8px 40px',
  },
  text_password_error: {
    marginBottom: '5px',
    color: '#f4443b',
    fontSize: '0.9em',
    maxWidth: 'fit-content',
    textAlign: 'justify',
  },
  classes__login_buttons_paragraph: {
    '& p': {
      marginBottom: 0,
      color: colors.primary,
      textAlign: 'initial',
      cursor: 'pointer',
    },
  },
  icon: {
    color: colors.primary,
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
}));

const columns = [
  { id: 'accountId', label: 'Mis cuentas', align: 'center' },
  { id: 'email', label: 'Correo', minWidth: 10, align: 'center' },
  { id: 'uncombine', label: '', minWidth: 10, align: 'center' },
];

const mapStateToProps = (state) => {
  return {
    user: state.user,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
  };
};

const mapDispatchToProps = {
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CombineAccount);
