import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, IconButton, TableBody, TableCell, TableRow, Tooltip, Button, Avatar } from '@material-ui/core';
import { DeleteForeverOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
}));

function SocialNetworks(props) {
  const { page, rowsPerPage, permission, changeBreadcrump } = props;
  const classes = useStyles();
  const history = useHistory();
  const [socialNetwoks, setSocialNetwoks] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      getSocialNetwoks()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          history.push('/500');
          window.location.reload();
        });
      changeBreadcrump(t('breadCrumps:breadCrumps.socialMedia', { returnObjects: true }));
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const getSocialNetwoks = async () => {
    const { data } = await axios.post(`/socialNetworks/getsocialNetworks`);
    setSocialNetwoks(data.socialNetworks);
    setFiltradas(data.socialNetworks);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      case 'edit':
        history.push(`/socialNetworks/edit/${id}`);
        break;
      case 'create':
        history.push(`/socialNetworks/create`);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-register'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id).catch(() => {
          history.push('/500');
          window.location.reload();
        });
      }
    });
  };

  const sendDelete = async (id) => {
    const { data } = await axios.delete(`/socialNetworks/${id}`);
    setSocialNetwoks(socialNetwoks.filter((item) => item.id !== id));
    setFiltradas(socialNetwoks.filter((item) => item.id !== id));
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const columns = () => {
    return t('tables:table.columns-socialMedia', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search items={socialNetwoks} setItems={setFiltradas} loadData={getSocialNetwoks} setLoading={setLoading} />
      <Table columns={columns()} rows={filtro} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={1} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={row.id} align="center">
                    <TableCell align="center">
                      <Avatar alt="Remy Sharp" src={row?.linkIcons} className={classes.large} />
                    </TableCell>
                    <TableCell align="center">{row.nombre}</TableCell>
                    <TableCell align="center">{edit_permission(row.id)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableCell align="center" colSpan="3">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'redes sociales')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialNetworks);
