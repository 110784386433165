import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Card, CardContent, Grid, Button, FormControl, TextField, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import Appbar from '../../components/Appbar';
import Backdrop from '../../components/Backdrop';
import colors from '../../assets/styles/colors';
import checkPassword from '../../utils/checkPassword';
import { decrypt } from '../../utils/crypt';
import api from '../../api';

function ChangePassword(props) {
  const classes = useStyles();
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState({ password: { error: false } });
  const singupButton = useRef();
  const [Logo, setLogo] = useState('');

  useEffect(() => {
    if (!props?.user && !props.match.params?.accountId) props.history.push('/');
    const styles = JSON.parse(window.localStorage.state)?.styles;
    if (styles) {
      setLogo(styles.logo435x149);
    }
    // eslint-disable-next-line
  }, []);

  const handleInput = (event) => {
    if (event.target.name === 'password') {
      if (checkPassword(event.target.value)) {
        singupButton.current.disabled = false;
        setValidate({ ...validate, password: { error: false } });
      } else {
        singupButton.current.disabled = true;
        setValidate({
          ...validate,
          password: {
            error: true,
            message:
              'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.',
          },
        });
      }
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const accountId = props.user ? props.user.data_user.cuentas[0].id : decrypt(props.match.params.accountId);
    setLoading(true);
    if (form.password === form.password2) {
      api
        .put(`/user/changePassword/${accountId}`, form)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            Swal.fire({
              icon: 'success',
              text: 'Contraseña actualizada exitosamente.',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              props.user ? props.history.push('/dashboard') : props.history.push('/sign-in');
            });
          } else {
            Swal.fire({
              icon: 'error',
              text: 'Ha ocurrido un error. Intenta nuevamente.',
              showConfirmButton: false,
              timer: 2000,
            });
            props.history.push('/500');
            window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            text: props.user ? 'Contraseña actual incorrecta' : 'Se ha vencido el tiempo de espera.',
            showConfirmButton: false,
            timer: 2000,
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Las contraseñas ingresadas no coinciden.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <div className="App">
      {props.user && <Appbar />}
      <div className={classes.AppHeader}>
        <Card className={classes.card} elevation={0}>
          <CardContent>
            <img src={Logo} alt="logo" className={classes.img} />
            <Typography component="h6" variant="h6">
              Cambiar contraseña
            </Typography>
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {props.user && (
                  <Grid item xs={12}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label="Contraseña actual"
                        name="currentPassword"
                        variant="outlined"
                        type="password"
                        onChange={handleInput}
                        InputProps={{
                          classes: {
                            root: classes.container__input_root,
                            notchedOutline: classes.container__input_notchedOutline,
                            focused: classes.container__input_focused,
                          },
                        }}
                        className={classes.signup_input}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl className="w-100">
                    <TextField
                      required
                      label="Nueva contraseña"
                      name="password"
                      variant="outlined"
                      type="password"
                      onChange={handleInput}
                      InputProps={{
                        classes: {
                          root: classes.container__input_root,
                          notchedOutline: classes.container__input_notchedOutline,
                          focused: classes.container__input_focused,
                        },
                      }}
                      className={classes.signup_input}
                      error={validate.password.error}
                    />
                    {validate.password.error && (
                      <span className={classes.text_password_error}>{validate.password.message}</span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className="w-100">
                    <TextField
                      required
                      label="Confirmar contraseña"
                      name="password2"
                      variant="outlined"
                      type="password"
                      onChange={handleInput}
                      InputProps={{
                        classes: {
                          root: classes.container__input_root,
                          notchedOutline: classes.container__input_notchedOutline,
                          focused: classes.container__input_focused,
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={`text-center ${classes.root}`}>
                <Button
                  disableElevation
                  color="primary"
                  ref={singupButton}
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  type="submit"
                >
                  Aceptar
                </Button>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  onClick={() => {
                    props.user ? props.history.push('/dashboard') : props.history.push('/sign-in');
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
        <Backdrop loading={loading} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  AppHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    marginTop: '1em',
    [theme.breakpoints.up('sm')]: {
      margin: '1em 6em 0 6em',
    },
  },
  card: {
    minWidth: 275,
    marginTop: '1em',
  },
  img: {
    width: theme.spacing(40),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  container__input_root: {
    borderRadius: '20px',
  },
  container__input_notchedOutline: {
    borderWidth: 2,
    borderColor: colors.primary,
  },
  container__input_focused: {},
  button: {
    margin: '0.5em',
    padding: '8px 40px',
  },
  text_password_error: {
    marginBottom: '5px',
    color: '#f4443b',
    fontSize: '0.9em',
    maxWidth: 'fit-content',
    textAlign: 'justify',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(ChangePassword);
