import React, { useState } from 'react';
import { makeStyles, Container, Box } from '@material-ui/core';
import { Document, pdfjs, Page } from 'react-pdf';
import Pagination from '@material-ui/lab/Pagination';
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
//import { Page, Image, View, Document, PDFViewer, Text } from '@react-pdf/renderer';

function ViewPDF(props) {
  const { pdf, img } = props;
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPage(1);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Container className={classes.container}>
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
          className={numPages > 1 ? classes.document : classes.documentNoPagination}
        >
          <Page key={`page_${page}`} pageNumber={page} />
        </Document>

        {numPages > 1 && (
          <div className={classes.pagination}>
            <Box component="span" display="flex" justifyContent="center">
              <Pagination
                orientation="vertical"
                color="secondary"
                count={numPages}
                page={page}
                onChange={handleChange}
                defaultPage={1}
                size="small"
              />
            </Box>
          </div>
        )}
      </Container>
      <div className={classes.mobile}>
        <img src={img} alt="certificate-img" className={classes.img} />
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  pagination: {
    marginTop: '3px',
    position: 'absolute',
  },
  mobile: {
    display: 'none',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  img: {
    maxWidth: '50vh',
  },
  document: {
    '& div .react-pdf__Page__annotations': {
      display: 'none',
    },
    '& canvas': {
      marginTop: '2em',
    },
  },
  documentNoPagination: {
    '& div .react-pdf__Page__annotations': {
      display: 'none',
    },
  },
}));

export default ViewPDF;
