import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  makeStyles,
  Container,
  Grid,
  Divider,
  Typography,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { WhatsApp, CloseOutlined, Telegram } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faClone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faFacebookMessenger, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { isBefore } from 'date-fns';
import Appbar from '../components/Appbar';
import colors from '../assets/styles/colors';
import axios from '../api';
import Revoked from '../assets/img/icon_revocado.png';
import Expired from '../assets/img/icon_expirado.png';
import VerifiedEn from '../assets/img/icon_verified.png';
import RevokedEn from '../assets/img/icon_revoked.png';
import ExpiredEn from '../assets/img/icon_expired.png';
import Backdrop from '../components/Backdrop';
import { decrypt, encrypt } from '../utils/crypt';
import { setBadge } from '../actions';
import { env } from '../config/environment';
import { useTranslation } from 'react-i18next';
import XTwitterIcon from '../assets/img/icons8-twitterx.svg';

function Certificate(props) {
  const { user, badge, setBadge, styles } = props;
  const classes = useStyles();
  const history = useHistory();
  const [copiedCode, setCopiedCode] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [skills, setSkills] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [organization, setOrganization] = useState({});
  const [stateBadge, setStateBadge] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [photoOrganization, setPhotoOrganization] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const LANGUAGE_EN = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons']);

  if (user == null) {
    history.push('/');
  }

  useEffect(() => {
    getBadge().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && window.GS) {
      // eslint-disable-next-line no-undef
      GS.reset();
    }
  }, [loading]);

  const getBadge = async () => {
    setLoading(true);
    const badgeId = decrypt(props.match.params.badgeId);
    const {
      data: { data },
    } = await axios.get(`/badges/getBadge/${badgeId}`);

    if (user.data_user.id !== data.badge.cuentas.usuarios.id) {
      history.push(`/badges/${encrypt(data?.badge?.id_insignias_emitidas)}`);
    }
    setBadge({ ...data.badge, photoBadge: data.photoBadge });
    getLevel(data.badge?.insigniasEmitidas?.plantillasInsignias.nivel_vp);
    getTime(data.badge?.insigniasEmitidas?.plantillasInsignias.tiempo_vp);
    getCosts(data.badge?.insigniasEmitidas?.plantillasInsignias.costo_vp);
    setSkills(data.badge?.insigniasEmitidas?.plantillasInsignias?.plantillasHabilidades);
    setCriteria(data.badge?.insigniasEmitidas?.plantillasInsignias?.criterios);
    setOrganization(
      data.badge.insigniasEmitidas?.plantillasInsignias?.id_organizacion_hija !== null
        ? data.badge.insigniasEmitidas?.plantillasInsignias?.organizacionesHijas
        : data.badge.insigniasEmitidas?.plantillasInsignias?.organizaciones
    );
    setPhotoOrganization(data.photoOrganization);
    data.badge.insigniasEmitidas?.estado === 0
      ? setStateBadge('Revoked')
      : data.badge.insigniasEmitidas?.fecha_expiracion !== null &&
        isBefore(new Date(data.badge.insigniasEmitidas?.fecha_expiracion), new Date())
      ? setStateBadge('Expired')
      : setStateBadge('Verified');
    setLoading(false);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelBadge = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelBadge[0]);
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeBadge = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeBadge[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costBadge = data.costs.filter((data) => data.id === costo_vp);
    setCost(costBadge[0]);
  };

  const handleShare = (idSocialNetwork) => {
    axios.post('/share/statisticSocialNetwork', {
      id_redes_sociales: idSocialNetwork,
      id_insignias_emitidas: badge.id_insignias_emitidas,
      id_usuarios: user.id,
    });
  };

  const handleLinkedInMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toLink = () => {
    let enlace = document.createElement('a');
    enlace.href = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
      badge.insigniasEmitidas?.plantillasInsignias?.nombre
    }&organizationName=${
      organization?.redesSociales?.find((item) => item.id_redes_sociales === 3)?.username || organization?.nombre
    }&issueYear=${moment(badge.insigniasEmitidas?.fecha_emision).format('YYYY')}&issueMonth=${moment(
      badge.insigniasEmitidas?.fecha_emision
    ).format('M')}&expirationYear=${moment(badge.insigniasEmitidas?.fecha_expiracion).format(
      'YYYY'
    )}&expirationMonth=${moment(badge.insigniasEmitidas?.fecha_expiracion).format('M')}&certUrl=${
      window.location.origin
    }/badges/${props.match.params.badgeId}&certId=${badge?.insigniasEmitidas?.codigo_insignia}`;
    enlace.target = '_blank';
    handleShare(3);
    setAnchorEl(null);
    enlace.click();
  };

  return (
    <>
      <Appbar />
      <IconButton className={classes.closeButton} onClick={() => history.push('/dashboard')}>
        <CloseOutlined />
      </IconButton>
      <Container className={classes.container} maxWidth={'xl'}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} className={'text-center'}>
            <img alt="img-badge" src={badge.photoBadge} className={classes.img} />
            <div>
              <Typography className={classes.textTitle}>{t('certificateBadgeTemplate:details.issued-by')}:</Typography>
              <div className="container_org">
                <a href={`/organization/${organization?.url_perfil}`} target="_blank" rel="noreferrer">
                  <img aria-label="img_organization" className={classes.img_org} src={photoOrganization} />
                </a>
              </div>
            </div>
            <div>
              <Typography className={classes.textBolder}>{t('certificateBadgeTemplate:details.issued')}:</Typography>
              <Typography className={classes.text}>
                {moment(badge.insigniasEmitidas?.fecha_emision).format('DD-MM-YYYY')}
              </Typography>
            </div>
            {badge.insigniasEmitidas?.fecha_expiracion !== null &&
              badge?.insigniasEmitidas?.fecha_expiracion !== '0000-00-00' && (
                <div>
                  <Typography className={classes.textBolder}>
                    {t('certificateBadgeTemplate:details.expiration')}:
                  </Typography>
                  <Typography className={classes.text}>
                    {moment(badge.insigniasEmitidas?.fecha_expiracion).format('DD-MM-YYYY')}
                  </Typography>
                </div>
              )}
            <div>
              <Typography className={classes.textBolder}>
                {t('certificateBadgeTemplate:details.badge-link')}:
              </Typography>
              <Box display="flex" justifyContent="center">
                <Box borderRadius={10} {...defaultProps} className={classes.containerButtonToCopy}>
                  <Typography className={classes.textCode}>
                    {`${window.location.origin}/badges/${props.match.params.badgeId}`}
                  </Typography>
                  <CopyToClipboard
                    text={`${window.location.origin}/badges/${props.match.params.badgeId}`}
                    onCopy={() => setCopiedLink(true)}
                  >
                    <FontAwesomeIcon icon={faClone} size="lg" />
                  </CopyToClipboard>
                </Box>
              </Box>
              {copiedLink && (
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.copied')}</Typography>
              )}
            </div>
            <div>
              <Typography className={classes.textBolder}>
                {t('certificateBadgeTemplate:details.badge-code')}:
              </Typography>
              <Box display="flex" justifyContent="center">
                <Box borderRadius={10} {...defaultProps} className={classes.containerButtonToCopy}>
                  <Typography className={classes.textCode}>{`${badge?.insigniasEmitidas?.codigo_insignia}`}</Typography>
                  <CopyToClipboard
                    text={`${badge?.insigniasEmitidas?.codigo_insignia}`}
                    onCopy={() => setCopiedCode(true)}
                  >
                    <FontAwesomeIcon icon={faClone} size="lg" />
                  </CopyToClipboard>
                </Box>
              </Box>
              {copiedCode && (
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.copied')}</Typography>
              )}
            </div>
          </Grid>

          <Divider orientation="vertical" flexItem className={classes.divider} />

          <Grid item xs={12} md={7}>
            {!loading && (
              <div className={classes.header}>
                <Grid container spacing={2}>
                  <Grid item xs={12} xl={12}>
                    <Grid container>
                      <Typography variant="h4" gutterBottom className={classes.textTitle}>
                        {badge.insigniasEmitidas?.plantillasInsignias?.nombre}
                      </Typography>

                      {LANGUAGE_EN ? (
                        <img
                          alt="img_verificado"
                          src={
                            stateBadge === 'Verified' ? VerifiedEn : stateBadge === 'Revoked' ? RevokedEn : ExpiredEn
                          }
                          className={classes.img_verificado}
                        />
                      ) : (
                        <img
                          alt="img_verificado"
                          src={
                            stateBadge === 'Verified'
                              ? styles.icon_verificado
                              : stateBadge === 'Revoked'
                              ? Revoked
                              : Expired
                          }
                          className={classes.img_verificado}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8} lg={6} xl={6}>
                    <a
                      id="linkedinShare"
                      href={`https://www.linkedin.com/sharing/share-offsite`}
                      className="gs-foreign-tracking gs-provider-linkedin"
                      data-url={`${env.certificateURL}${props.match.params.badgeId}`}
                      data-title={`${t('certificateBadgeTemplate:sharing.badge-sharing')} ${organization?.nombre}${
                        styles.organizationName === 'Certika' ? ' vía @certikaco' : '.'
                      }`}
                      onClick={() => handleShare(3)}
                      style={{ display: 'none' }}
                    >
                      Linkedin
                    </a>
                    <IconButton
                      className={classes.LinkedInIcon}
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleLinkedInMenuOpen}
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
                    </IconButton>
                    <a
                      href={`https://twitter.com/intent/tweet`}
                      className="gs-foreign-tracking gs-provider-twitter"
                      data-url={`${env.certificateURL}${props.match.params.badgeId}`}
                      data-title={`${t('certificateBadgeTemplate:sharing.badge-sharing')} ${
                        organization?.redesSociales?.find((item) => item.id_redes_sociales === 5)?.username ||
                        organization?.nombre
                      }${styles.organizationName === 'Certika' ? ' vía @certikaco' : '.'}`}
                      onClick={() => handleShare(5)}
                    >
                      <IconButton className={classes.xtwitterIcon}>
                        <img src={XTwitterIcon} alt="XTwitter" height={23} />
                      </IconButton>
                    </a>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php`}
                      className="gs-foreign-tracking gs-provider-facebook"
                      data-url={`${env.certificateURL}${props.match.params.badgeId}`}
                      onClick={() => handleShare(4)}
                    >
                      <IconButton className={classes.FacebookIcon}>
                        <FontAwesomeIcon icon={faFacebookF} size="sm" />
                      </IconButton>
                    </a>
                    <a
                      href={`https://www.messenger.com/sharer/sharer.php`}
                      className="gs-foreign-tracking gs-provider-fbm"
                      data-url={`${env.certificateURL}${props.match.params.badgeId}`}
                      onClick={() => handleShare(7)}
                    >
                      <IconButton className={classes.TwitterIcon}>
                        <FontAwesomeIcon icon={faFacebookMessenger} size="sm" />
                      </IconButton>
                    </a>
                    <a
                      href={`https://api.whatsapp.com/send`}
                      className="gs-foreign-tracking gs-provider-whatsapp"
                      data-url={`${env.certificateURL}whatsapp/${props.match.params.badgeId}`}
                      data-title={`${t('certificateBadgeTemplate:sharing.badge-sharing')} ${organization?.nombre}${
                        styles.organizationName === 'Certika' ? ' vía certika' : '.'
                      }`}
                      onClick={() => handleShare(2)}
                    >
                      <IconButton className={classes.WhatsAppIcon}>
                        <WhatsApp />
                      </IconButton>
                    </a>
                    <a
                      href={`https://telegram.me/share`}
                      className="gs-foreign-tracking gs-provider-telegram"
                      data-url={`${env.certificateURL}${props.match.params.badgeId}`}
                      data-title={`${t('certificateBadgeTemplate:sharing.badge-sharing')} ${organization?.nombre}${
                        styles.organizationName === 'Certika' ? ' vía certika' : '.'
                      }`}
                      onClick={() => handleShare(6)}
                    >
                      <IconButton className={classes.TelegramIcon}>
                        <Telegram viewBox="0 0 26 24" />
                      </IconButton>
                    </a>
                  </Grid>
                  <Grid item xs={12} md={4} lg={6} xl={6}>
                    <Link
                      href={`/certificates/verify/${props.match.params.badgeId}`}
                      target="_blank"
                      underline="none"
                      variant="inherit"
                      className={classes.button}
                    >
                      {t('buttons:buttons.verify')}
                    </Link>
                  </Grid>
                </Grid>
              </div>
            )}

            <Typography className={classes.textParagraph} gutterBottom>
              {badge.insigniasEmitidas?.plantillasInsignias?.descripcion}
            </Typography>
            <Typography className={classes.text} gutterBottom>
              {t('certificateBadgeTemplate:details.coffered')}:
            </Typography>
            <Box display="flex">
              <Box borderRadius={10} {...defaultPropsUser}>
                <Typography>
                  {`${badge.insigniasEmitidas?.primer_nombre_receptor.toUpperCase()} ${
                    badge.insigniasEmitidas?.segundo_nombre_receptor.toUpperCase() || ''
                  } ${badge.insigniasEmitidas?.primer_apellido_receptor.toUpperCase()} ${
                    badge.insigniasEmitidas?.segundo_apellido_receptor.toUpperCase() || ''
                  }`}
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.textBolder} gutterBottom>
              {t('certificateBadgeTemplate:details.met-requirements')}:
            </Typography>
            {criteria.map((data, index) =>
              data.link === null || data.link === '' ? (
                <Typography key={`criteria${index}`} className={classes.text}>
                  {data.descripcion_criterio}
                </Typography>
              ) : (
                <a
                  href={data.link}
                  target="_blank"
                  rel="noreferrer"
                  key={`criteria${index}`}
                  className={classes.link}
                  gutterBottom
                >
                  {data.descripcion_criterio}
                </a>
              )
            )}

            {badge?.insigniasEmitidas?.merito && (
              <>
                <Typography className={classes.textBolder} gutterBottom>
                  {t('certificateBadgeTemplate:details.merit')}:
                </Typography>
                <Box display="flex">
                  <Box borderRadius={10} {...defaultPropsUser}>
                    <Typography>{badge?.insigniasEmitidas?.merito}</Typography>
                  </Box>
                </Box>
                {badge?.insigniasEmitidas?.link_merito && (
                  <Link
                    href={badge.insigniasEmitidas?.link_merito}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    {t('certificateBadgeTemplate:details.merit-link')}:
                    <FontAwesomeIcon icon={faShareSquare} size="xs" />
                  </Link>
                )}
              </>
            )}

            <Grid container spacing={2} align="center" className={classes.root}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {LANGUAGE_EN ? level.valor_parametro_en : level.valor_parametro}
                </Typography>
                <Typography className={classes.text}>{t('certificateBadgeTemplate:details.level')}</Typography>
              </Grid>
              {badge?.insigniasEmitidas?.plantillasInsignias?.cantidad_tiempo !== null &&
              time?.valor_parametro !== undefined ? (
                <Grid item xs={12} sm={4}>
                  <Typography variant="h5" className={classes.textTitle}>
                    {`${badge.insigniasEmitidas?.plantillasInsignias?.cantidad_tiempo} ${
                      LANGUAGE_EN ? time.valor_parametro_en : time.valor_parametro
                    }`}
                  </Typography>
                  <Typography className={classes.text}> {t('certificateBadgeTemplate:details.time')}</Typography>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" className={classes.textTitle}>
                  {LANGUAGE_EN ? cost.valor_parametro_en : cost.valor_parametro}
                </Typography>
                <Typography className={classes.text}> {t('certificateBadgeTemplate:details.cost')}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.root}>
              <Grid item xs={12} sm={2}>
                <Typography className={classes.textBolder}>{t('certificateBadgeTemplate:details.skills')}:</Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid container>
                  {skills.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography>{data.habilidades.descripcion}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            {badge.insigniasEmitidas?.plantillasInsignias?.url && (
              <Link
                href={badge.insigniasEmitidas?.plantillasInsignias?.url}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                {t('certificateBadgeTemplate:details.additional')}:
                <FontAwesomeIcon icon={faShareSquare} size="xs" />
              </Link>
            )}
          </Grid>
        </Grid>
        <Backdrop loading={loading} />
      </Container>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={() => toLink()}>
          <IconButton className={classes.LinkedInIconMenu}>
            <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
          </IconButton>{' '}
          <spam className={classes.textLinkedMenu}>Agregar en el perfil</spam>
        </MenuItem>
        <MenuItem onClick={() => document.getElementById('linkedinShare').click()}>
          <IconButton className={classes.LinkedInIconMenu}>
            <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
          </IconButton>
          <spam className={classes.textLinkedMenu}> Agregar como publicación</spam>
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '1em',
  },
  img: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  img_org: {
    width: 'auto',
    height: theme.spacing(6),
  },
  container_org: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
    margin: '1em 0 1em 0',
  },
  textCode: {
    color: '#808080',
    textAlign: 'left',
  },
  link: {
    color: colors.primary,
    textDecoration: 'underline',
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.5em 3em',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  containerButtonToCopy: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: colors.primary,
      marginLeft: 15,
      cursor: 'pointer',
    },
  },
  header: {
    marginTop: '3em',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0',
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '1em',
    },
  },
  WhatsAppIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00d954',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#009B3C',
    },
  },
  LinkedInIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#005374',
    },
  },
  LinkedInIconMenu: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    '&:hover': {
      backgroundColor: '#018ec5',
    },
  },
  textLinkedMenu: {
    fontSize: '.8em',
    marginLeft: '.5em',
  },
  TwitterIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0080A0',
    },
  },
  FacebookIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#0080ff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
  },
  TelegramIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
  },
  ShareIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#888888',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#545454',
    },
  },
  divider: {
    marginRight: '2em',
  },
  closeButton: {
    margin: '.5em .5em 0 0',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  xtwitterIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#2f3136',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
}));

const defaultProps = {
  bgcolor: 'background.paper',
  borderColor: '#808080',
  m: 1,
  border: 1,
  padding: 1,
};

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    badge: state.badge || {},
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  setBadge,
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
