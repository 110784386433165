import React, { Fragment } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const RadarChart = (props) => {
  const { series } = props;
  const tipo = props.tipo;
  const [t] = useTranslation(['analytics']);
  const estados =
    tipo !== 3
      ? t('analytics:analytics-index.state-checkbox', { returnObjects: true })
      : t('analytics:analytics-index.count-checkbox', { returnObjects: true });

  const data = {
    labels: [
      `${estados[2].estado}: ${series[0]}`,
      `${estados[1].estado}: ${series[1]}`,
      `${estados[3].estado}: ${series[2]}`,
      `${estados[0].estado}: ${series[3]}`,
    ],
    datasets: [
      {
        data: series,
        backgroundColor: ['#21C700', '#D3D300', '#C70000', '#ff9900'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'dataset',
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#000',
          font: {
            size: 18,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let total = 0;
            // eslint-disable-next-line
            context.dataset.data.map((item) => {
              total = total + item;
            });
            let value = ((context.parsed * 100) / total).toFixed();
            let label = `${context.label.split(':')[0]}: ${value}%`;
            return label;
          },
        },
      },
    },
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        <Doughnut data={data} height={400} width={580} options={options} />
      </div>
    </Fragment>
  );
};
export default RadarChart;
