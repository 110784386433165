import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  TextField,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Checkbox,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { BroadcastChannel } from 'broadcast-channel';
import Swal from 'sweetalert2';
import checkPassword from '../utils/checkPassword';
import axios from '../api';
import { decrypt, encrypt } from '../utils/crypt';
import { login, setSignatorieSignUp } from '../actions';
import { env } from '../config/environment';
import TermsAndConditions from '../components/TermsAndConditions';
import { useTranslation } from 'react-i18next';
import colors from '../assets/styles/colors';

function Login(props) {
  const classes = useStyles({ env });
  const [form, setForm] = useState({
    firstName: '',
    secondName: '',
    lastName: '',
    secondSurname: '',
    email: '',
    password: '',
    issued: false,
    backoficceId: '',
  });
  const [loading, setLoading] = useState(false);
  const singupButton = useRef();
  const [validate, setValidate] = useState({ password: { error: false } });
  const [message, setMessage] = useState({});
  const [checked, setChecked] = useState([]);
  const [dataDialog, setDataDialog] = useState({ open: false, title: '', url: '', viewTerms: false, viewPPT: false });
  const [loginStyles, setLoginStyles] = useState({});
  const { user, history } = props;
  const logoutChannel = new BroadcastChannel('logout');
  const [t] = useTranslation(['loginRegister', 'buttons', 'alerts']);

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLoginStyles({
      Logo: styles.logo435x149,
      LoginLogo: styles.icono_login,
      LoginBackground: styles.login_background,
      Register: styles.register,
    });

    if (window.location.origin === env.certikaURL) {
      setForm({
        ...form,
        backoficceId: 1,
      });
    } else if (window.location.origin === env.mincienciasURL) {
      setForm({
        ...form,
        backoficceId: 2,
      });
    }

    if (user) {
      setLoading(true);
      valida();
    }

    if (props.match.params.badgeId) {
      setLoading(true);
      getUserIssued().catch(() => {
        props.history.push('/500');
        window.location.reload();
      });
      const badgeId = decrypt(props.match.params.badgeId);
      const reject = props.match.params.reject;

      if (!reject) {
        axios
          .get(`/badgesIssued/accept/${badgeId}`)
          .then((res) => {
            setLoading(false);
            setMessage({ message: res.data.message, severity: res.data.severity });
          })
          .catch((error) => {
            setLoading(false);
            setMessage(t('alerts:alerts.try-again'));
            props.history.push('/500');
            window.location.reload();
          });
      }
    } else if (props.match.params.certificateId) {
      setLoading(true);
      getUserIssued().catch(() => {
        props.history.push('/500');
        window.location.reload();
      });
      const certificateId = decrypt(props.match.params.certificateId);
      const reject = props.match.params.reject;
      if (!reject) {
        axios
          .get(`/certificatesIssued/accept/${certificateId}`)
          .then((res) => {
            setLoading(false);
            setMessage({ message: res.data.message, severity: res.data.severity });
          })
          .catch((error) => {
            setLoading(false);
            setMessage(t('alerts:alerts.try-again'));
            props.history.push('/500');
            window.location.reload();
          });
      }
    } else if (props.match.params.carnetId) {
      setLoading(true);
      getUserIssued().catch(() => {
        props.history.push('/500');
        window.location.reload();
      });
      const carnetId = decrypt(props.match.params.carnetId);
      const reject = props.match.params.reject;
      if (!reject) {
        axios
          .get(`/carnetsIssued/accept/${carnetId}`)
          .then((res) => {
            setLoading(false);
            setMessage({ message: res.data.message, severity: res.data.severity });
          })
          .catch((error) => {
            setLoading(false);
            setMessage(t('alerts:alerts.try-again'));
            props.history.push('/500');
            window.location.reload();
          });
      }
    } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
      setLoading(true);
      getUserIssued();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserIssued = async () => {
    if (props.match.params.badgeId) {
      const badgeId = decrypt(props.match.params.badgeId);
      const { data } = await axios.get(`/badgesIssued/receiver/${badgeId}`);
      if (data.data !== null) {
        setForm({
          firstName: data.data.primer_nombre_receptor,
          secondName: data.data.segundo_nombre_receptor,
          lastName: data.data.primer_apellido_receptor,
          secondSurname: data.data.segundo_apellido_receptor,
          email: data.data.email_receptor,
          issued: true,
          backoficceId: window.location.origin === env.mincienciasURL ? 2 : 1,
        });
      }
    } else if (props.match.params.certificateId) {
      const certificateId = decrypt(props.match.params.certificateId);
      const { data } = await axios.get(`/certificatesIssued/receiver/${certificateId}`);
      if (data.data !== null) {
        setForm({
          email: data.data.email_receptor,
          issued: true,
          backoficceId: window.location.origin === env.mincienciasURL ? 2 : 1,
        });
      }
    } else if (props.match.params.carnetId) {
      const carnetId = decrypt(props.match.params.carnetId);
      const { data } = await axios.get(`/carnetsIssued/receiver/${carnetId}`);
      if (data.data !== null) {
        setForm({
          email: data.data.email_receptor,
          issued: true,
          backoficceId: window.location.origin === env.mincienciasURL ? 2 : 1,
        });
      }
    } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
      const idSignature = props.match.params.idSignature;
      const { data } = await axios.get(`/signature/receiver/${idSignature}`);
      if (data.signature !== null) {
        if (data.signature.cuentas === null) return history.push('/');
        if (data.signature.cuentas.estado === true || data.signature.cuentas.estado === 1) {
          return (window.location.href = `/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`);
        }
        setForm({
          firstName: data.signature.usuarios.primer_nombre,
          secondName: data.signature.usuarios.segundo_nombre,
          lastName: data.signature.usuarios.primer_apellido,
          secondSurname: data.signature.usuarios.segundo_apellido,
          email: data.signature.cuentas.correo,
          signatorie: true,
          issued: true,
          backoficceId: window.location.origin === env.mincienciasURL ? 2 : 1,
        });
        setMessage({ message: t('alerts:alerts.sing-up-signatorie'), severity: 'info' });
        setLoading(false);
      } else {
        history.push('/');
        Swal.fire({
          icon: 'warning',
          iconColor: colors.primary,
          text: t('alerts:alerts.document-have-signature'),
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }
    }
  };

  const valida = async () => {
    await axios
      .get(`/user/${user?.data_user?.id}`)
      .then((res) => {
        setLoading(false);
        if (props.match.params.badgeId) {
          history.push(`/dashboard/badge/${props.match.params.badgeId}`);
        } else if (props.match.params.certificateId) {
          history.push(`/dashboard/certificate/${props.match.params.certificateId}`);
        } else if (props.match.params.carnetId) {
          history.push(`/dashboard/carnet/${props.match.params.carnetId}`);
        } else if (props.match.params.documentTemplateId && props.match.params.idSignature) {
          history.push(
            `/dashboard/document/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
          );
        } else {
          history.push('/dashboard');
        }
      })
      .catch(() => {
        setLoading(false);
        logoutChannel.postMessage('CerrarSesion');
      });
  };

  const handleInput = (event) => {
    if (event.target.name === 'password') {
      if (event.target.value === '' || checkPassword(event.target.value)) {
        singupButton.current.disabled = false;
        setValidate({ ...validate, password: { error: false } });
        Swal.close();
      } else {
        singupButton.current.disabled = true;
        setValidate({
          ...validate,
          password: {
            error: true,
          },
        });
        !validate.password.error &&
          Swal.mixin({
            toast: true,
            position: 'bottom-start',
          }).fire({
            icon: 'error',
            iconColor: colors.primary,
            title: t('alerts:alerts.password-lenght'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
      }
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.password !== form.passwordConfirmation) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.password-match'),
        showConfirmButton: false,
        timer: 3000,
      });
    }
    setLoading(true);
    axios({
      url: `/user/sign-up`,
      method: 'post',
      data: {
        primer_nombre: form.firstName,
        segundo_nombre: form.secondName,
        primer_apellido: form.lastName,
        segundo_apellido: form.secondSurname,
        correo: form.email,
        password: form.password,
        issued: form.issued,
        backoficceId: form.backoficceId,
      },
    })
      .then((res) => {
        setLoading(false);
        const { data } = res;
        if (data) {
          if (form?.signatorie) {
            return Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.successful-sign-up'),
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              props.setSignatorieSignUp({ email: form?.email, pass: encrypt(form.password) });
              props.history.push(
                `/sign-in/signatorie/${props.match.params.documentTemplateId}/${props.match.params.idSignature}`
              );
            });
          }
          if (form.issued) {
            return Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: t('alerts:alerts.thanks-register'),
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              props.history.push('/sign-in');
            });
          }
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.email-confirm-account'),
            showConfirmButton: false,
            timer: 7000,
          }).then(() => {
            props.history.push('/sign-in');
          });
        }
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.account-exist'),
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const openTerms = (index, title, url) => {
    setDataDialog({ ...dataDialog, open: true, title, url, index });
  };

  const handleClose = () => {
    setDataDialog({ ...dataDialog, open: false, title: '', url: '' });
  };

  const handleAccept = (index) => {
    const currentIndex = checked.includes(index);
    const newChecked = [...checked];
    if (!currentIndex) {
      newChecked.push(index);
      setChecked(newChecked);
    } else {
      setChecked(newChecked.filter((item) => item !== index));
    }
    setDataDialog({
      open: false,
      title: '',
      url: '',
      viewTerms: index === 1 ? true : dataDialog.viewTerms,
      viewPPT: index === 2 ? true : dataDialog.viewPPT,
    });
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage:
          window.location.origin === env.mincienciasURL ? 'none' : `url(${loginStyles?.LoginBackground})`,
      }}
    >
      <Paper elevation={0} className={classes.container_card}>
        <div className={classes.container__login}>
          <div className={classes.container__logo}>
            <img src={loginStyles?.Logo} alt="logo" style={{ width: '70%' }} />
          </div>
          {!props.match.params.reject &&
            (props.match.params.badgeId ||
              props.match.params.certificateId ||
              props.match.params.carnetId ||
              (props.match.params.documentTemplateId && props.match.params.idSignature)) && (
              <Alert style={{ maxWidth: '32em' }} severity={message.severity}>
                {message.message}
              </Alert>
            )}
          <h3 style={{ marginBottom: '.5em' }}>{t('buttons:buttons.register')}</h3>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    disabled={form?.signatorie}
                    required
                    label={t('loginRegister:first-name')}
                    name="firstName"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    disabled={form?.signatorie}
                    label={t('loginRegister:second-name')}
                    name="secondName"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.secondName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    disabled={form?.signatorie}
                    required
                    label={t('loginRegister:last-name')}
                    name="lastName"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.lastName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    disabled={form?.signatorie}
                    label={t('loginRegister:second-last-name')}
                    name="secondSurname"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.secondSurname}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:email')}
                    name="email"
                    type="email"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.email}
                    disabled={
                      props.match.params.badgeId ? true : props.match.params.certificateId ? true : form?.signatorie
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:password')}
                    name="password"
                    variant="outlined"
                    onChange={handleInput}
                    error={validate.password.error}
                    type={'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  {validate.password.error && (
                    <span className={classes.text_password_error}>{validate.password.message}</span>
                  )}
                  <TextField
                    required
                    label={t('loginRegister:confirm-password')}
                    name="passwordConfirmation"
                    variant="outlined"
                    onChange={handleInput}
                    type={'password'}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {window.location.origin !== env.mincienciasURL && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.checked}
                    control={<Checkbox checked={checked.includes(1)} name="checkedE" onClick={() => handleAccept(1)} />}
                  />
                  <span
                    className={classes.checkedSpam}
                    onClick={() =>
                      openTerms(
                        1,
                        'TÉRMINOS Y CONDICIONES DE USO DE SOFTWARE CERTIKA S.A.S.',
                        'https://certika.co/wp-content/uploads/2022/08/Terminos-y-Condiciones-de-Uso-de-Softwre-y-ANS-1.pdf'
                      )
                    }
                  >
                    {t('loginRegister:accept-terms')}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.checked}
                    control={<Checkbox checked={checked.includes(2)} name="checkedE" onClick={() => handleAccept(2)} />}
                  />
                  <span
                    className={classes.checkedSpam}
                    onClick={() =>
                      openTerms(
                        2,
                        'POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES CERTIKA S.A.S',
                        'https://certika.co/wp-content/uploads/2022/07/Politica-de-Tratamiento-de-Datos-Personales-Responsable_Certika-1.pdf'
                      )
                    }
                  >
                    {t('loginRegister:accept-privacy')}
                  </span>
                </Grid>
              </>
            )}
            <div className={`text-center`}>
              <Button
                disabled={
                  window.location.origin !== env.mincienciasURL && !(checked.includes(1) && checked.includes(2))
                }
                ref={singupButton}
                variant="contained"
                size="small"
                className={classes.button}
                type="submit"
                color="primary"
                disableElevation
              >
                {t('buttons:buttons.register')}
              </Button>
              <br />
            </div>
            <div className={classes.classes__login_buttons_paragraph}>
              {!form?.signatorie && (
                <>
                  <p
                    onClick={() => {
                      props.history.push('/sign-in');
                    }}
                  >
                    {`${t('loginRegister:have-account')}, `}
                    <span className={classes.signin_span}>{t('loginRegister:sign-in')}</span>
                  </p>
                </>
              )}
            </div>
          </form>
        </div>
      </Paper>
      <div className={classes.container__image}>
        <img
          src={window.location.origin === env.certikaURL ? loginStyles?.Register : loginStyles?.LoginLogo}
          className={classes.img}
          alt="login-logo"
        />
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TermsAndConditions data={dataDialog} onClose={handleClose} handleAccept={handleAccept} checked={checked} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: window.location.origin === props.env.mincienciasURL ? '20px' : 0,
      height: '100vh',
      justifyContent: 'space-around',
    },
  }),
  container_card: {
    padding: '20px',
    borderRadius: '15px',
    margin: '5px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '5px 40px',
      margin: '0 10px',
      width: '600px',
    },
  },
  container__login: {
    '& h2': {
      fontWeight: 700,
    },
    '& img': {
      width: '85%',
    },
  },
  container__logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: (props) => ({
    marginTop: window.location.origin === props.env.mincienciasURL ? '1em' : 0,
    margin: '0.5em',
    width: '100%',
    padding: '9px 40px',
  }),
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  signin_span: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  text_password_error: {
    marginBottom: '5px',
    color: '#f4443b',
    fontSize: '0.9em',
    maxWidth: 'fit-content',
    textAlign: 'justify',
  },
  container__image: (props) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: window.location.origin === props.env.mincienciasURL ? '80vw' : '50vw',
    },
  }),
  img: {
    //width: '100%',
    height: '100%',
  },
  checked: {
    margin: 0,
  },
  checkedSpam: {
    cursor: 'pointer',
    fontSize: 13,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  classes__login_buttons_paragraph: {
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      marginTop: '.5em',
      marginBottom: '.5em',
      textAlign: 'center',
      cursor: 'pointer',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  login,
  setSignatorieSignUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
