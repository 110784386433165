import React from 'react';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  withStyles,
  LinearProgress,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Radio,
} from '@material-ui/core';
import colors from '../assets/styles/colors';
import { useTranslation } from 'react-i18next';
import axios from '../api';
import Swal from 'sweetalert2';

const MySignatures = (props) => {
  const { closeModalSign, saveSign, progressBar } = props;
  const [signatures, setSignatures] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currentSignature, setCurrentSignature] = React.useState(null);
  const classes = useStyle();
  const [t] = useTranslation(['buttons', 'certifyDocuments']);

  React.useEffect(() => {
    setLoading(true);
    const getSign = async () => {
      try {
        await axios.post(`/signature/getSignatures`).then(({ data }) => {
          setSignatures([...data?.signatures]);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.try-again'),
          buttonsStyling: false,
          customClass: {
            container: classes.mySwal,
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => closeModalSign());
      }
    };
    getSign();
    // eslint-disable-next-line
  }, []);

  const goToSave = () => {
    saveSign({ idSign: currentSignature });
  };

  const selectSign = (sign) => {
    setCurrentSignature(sign.id);
  };

  return (
    <>
      {!progressBar.value && (
        <List>
          {loading ? (
            <ListItem>Cargando...</ListItem>
          ) : signatures.length > 0 ? (
            <>
              {signatures.map((sign) => {
                return (
                  <ListItem button onClick={() => selectSign(sign)}>
                    <ListItemIcon>
                      <Radio edge="start" checked={currentSignature === sign.id} />
                    </ListItemIcon>
                    <ListItemIcon>
                      <img src={sign.url} alt="sign" width={200} />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </>
          ) : (
            <ListItem>No tienes firmas registradas</ListItem>
          )}
        </List>
      )}
      {progressBar.value && (
        <div style={{ margin: '1em', width: 520 }}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progressBar.value} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressBar.value)}%`}</Typography>
            </Box>
          </Box>
        </div>
      )}
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} className={classes.container__button_fields}>
          <Button className={classes.button} onClick={() => goToSave()} disabled={currentSignature === null}>
            {t('buttons:buttons.save')}
          </Button>{' '}
          <Button className={classes.button} onClick={() => closeModalSign()}>
            {t('buttons:buttons.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyle = makeStyles(() => ({
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

export default MySignatures;
