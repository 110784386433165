import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Divider,
  Button,
  Card,
  makeStyles,
  CardContent,
  Avatar,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  ContentCopyOutlined,
  ArchiveOutlined,
  DeleteForeverOutlined,
  VisibilityOutlined,
  LaunchOutlined,
  Brightness5Outlined,
} from '@mui/icons-material';
import { changeBreadcrump } from '../../../../actions';
import { encrypt, decrypt } from '../../../../utils/crypt';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Template from './Index';
import colors from '../../../../assets/styles/colors';
import axios from '../../../../api';
import EditIcon from '../../../../assets/static/icons/EditIcon';
import Tooltip from '@material-ui/core/Tooltip';
import BadgeCard from '../../../../components/BadgeCard';
import PreviewDialog from '../../../../components/PreviewDialog';
import Skeleton from '@material-ui/lab/Skeleton';

function Templates(props) {
  const { user, changeBreadcrump, permission } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateHability, setTemplateBadge] = useState({});
  const [badgesIssued, setBadgesIssued] = useState([]);
  const [skills, setSkills] = useState([]);
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [type, setType] = useState({});
  const [criteria, setCriteria] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [templateCopy, setTemplateCopy] = useState({});
  const [vista, setVista] = useState({});
  const [permissionDepartament, setPermissionDepartament] = useState(false);

  /* translation */
  const [t] = useTranslation(['certificateBadgeTemplate', 'tables', 'alerts', 'buttons', 'breadCrumps']);

  const classes = useStyles();

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getTemplateBadge();
    setLoading(false);
  };

  const getTemplateBadge = async () => {
    const id = decrypt(props.match.params.id);
    const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
    const isDeparmentCreaterRoll =
      user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

    const { data } = await axios.get(`/templateHability/${id}`);
    setTemplateBadge(data.templateHability);
    setBadgesIssued(data.templateHability?.templateBadge?.insigniasEmitidas);
    getTime(data.templateHability?.templateBadge?.tiempo_vp);
    getLevel(data.templateHability?.templateBadge?.nivel_vp);
    getCosts(data.templateHability?.templateBadge?.costo_vp);
    getTypes(data.templateHability?.templateBadge?.tipo_vp);
    setSkills(data.templateHability?.templateHability);
    setCriteria(data.templateHability?.criterion);
    setRecommendations(data.templateHability?.recommendation);
    getTemplateBadgeState(data.templateHability?.templateBadge?.estado);
    setTemplateCopy({
      ...data.templateHability?.templateBadge,
      nombre: `${data.templateHability?.templateBadge?.nombre} copia`,
      nombre_privado: `${data.templateHability?.templateBadge?.nombre_privado} copia`,
      linkPhoto: data.templateHability?.linkPhoto,
      id_plantillas_recomendadas: data.templateHability?.recommendation?.map((item) => ({
        id_plantillas_recomendadas: item.id_plantillas_recomendadas,
      })),
      id_habilidades: data.templateHability?.templateHability?.map((item) => ({
        id_habilidades: item.id_habilidades,
      })),
      criterios: data.templateHability?.criterion?.map((item) => ({
        tipo_criterio_vp: item.tipo_criterio_vp,
        descripcion_criterio: item?.descripcion_criterio,
        link: item.link,
      })),
      id_usuarios: user.data_user?.id,
    });
    if (isDeparmentCreater || isDeparmentCreaterRoll) {
      data.templateHability?.templateBadgeHistory?.filter((item) => parseInt(item.accion) === 34)[0].id_usuarios ===
      user.data_user?.id
        ? setPermissionDepartament(false)
        : setPermissionDepartament(true);
    }
  };

  const getTime = async (tiempo_vp) => {
    const { data } = await axios.post(`/others/getTime`);
    const timeBadge = data.time.filter((data) => data.id === tiempo_vp);
    setTime(timeBadge[0]);
  };

  const getLevel = async (level_vp) => {
    const { data } = await axios.post(`/others/getLevels`);
    const levelBadge = data.levels.filter((data) => data.id === level_vp);
    setLevel(levelBadge[0]);
  };

  const getCosts = async (costo_vp) => {
    const { data } = await axios.post(`/others/getCosts`);
    const costBadge = data.costs.filter((data) => data.id === costo_vp);
    setCost(costBadge[0]);
  };

  const getTypes = async (tipo_vp) => {
    const { data } = await axios.post(`/others/getTypes`);
    const TypeBadge = data.types.filter((data) => data.id === tipo_vp);
    setType(TypeBadge[0]);
  };

  const getTemplateBadgeState = (estado) => {
    if (estado === 1) {
      changeBreadcrump(t('breadCrumps:breadCrumps.badge-details-published', { returnObjects: true }));
    } else {
      if (estado === 0) {
        changeBreadcrump(t('breadCrumps:breadCrumps.badge-details-edited', { returnObjects: true }));
      } else {
        changeBreadcrump(t('breadCrumps:breadCrumps.badge-details-archived', { returnObjects: true }));
      }
    }
  };

  const issuedBadges = () => {
    return (
      <>
        <Grid item lg={3} md={3} xs={5}>
          <Tooltip title={t('tables:table.tooltip.preview')}>
            <Button className={classes.button} onClick={(e) => handleOpenDialog()}>
              <VisibilityOutlined />
            </Button>
          </Tooltip>
        </Grid>
        {permission.includes('Editar') && (
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.edit')}>
              <Button disabled={permissionDepartament} className={classes.button} onClick={goEdit}>
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {permission.includes('Copiar') && (
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.copy')}>
              <Button
                disabled={permissionDepartament}
                className={classes.button}
                onClick={(e) => goCopy(e, templateCopy)}
              >
                <ContentCopyOutlined />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {badgesIssued !== 0
          ? permission.includes('Archivar') && (
              <Grid item lg={3} md={3} xs={5}>
                <Tooltip title={t('tables:table.tooltip.archive')}>
                  <Button
                    disabled={permissionDepartament}
                    className={classes.button}
                    onClick={(e) => handleClick(e, templateHability.id, 'archive')}
                  >
                    <ArchiveOutlined />
                  </Button>
                </Tooltip>
              </Grid>
            )
          : permission.includes('Eliminar') && (
              <Grid item lg={3} md={3} xs={5}>
                <Tooltip title={t('tables:table.tooltip.delete')}>
                  <Button
                    disabled={permissionDepartament}
                    className={classes.button}
                    onClick={(e) => handleClick(e, templateHability.id, 'delete')}
                  >
                    <DeleteForeverOutlined />
                  </Button>
                </Tooltip>
              </Grid>
            )}
      </>
    );
  };

  const loadButtons = () => {
    if (templateHability.templateBadge?.estado === 0) {
      return (
        <>
          <Grid item lg={3} md={3} xs={5}>
            <Tooltip title={t('tables:table.tooltip.preview')}>
              <Button className={classes.button} onClick={(e) => handleOpenDialog()}>
                <VisibilityOutlined />
              </Button>
            </Tooltip>
          </Grid>
          {permission.includes('Publicar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.publish')}>
                <Button className={classes.button} onClick={(e) => handleClick(e, templateHability.id, 'publish')}>
                  <LaunchOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Editar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.edit')}>
                <Button className={classes.button} onClick={goEdit}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Copiar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.copy')}>
                <Button className={classes.button} onClick={(e) => goCopy(e, templateCopy)}>
                  <ContentCopyOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permission.includes('Eliminar') && (
            <Grid item lg={3} md={3} xs={5}>
              <Tooltip title={t('tables:table.tooltip.delete')}>
                <Button className={classes.button} onClick={(e) => handleClick(e, templateHability.id, 'delete')}>
                  <DeleteForeverOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      );
    }
    if (templateHability.templateBadge?.estado === 1) {
      return <>{issuedBadges()}</>;
    }
    if (templateHability.templateBadge?.estado === 2) {
      return (
        <>
          {permission.includes('Publicar') && (
            <Grid item lg={2} md={2} xs={5}>
              <Tooltip title={t('tables:table.tooltip.publish')}>
                <Button className={classes.button} onClick={(e) => handleClick(e, templateHability.id, 'publish')}>
                  <LaunchOutlined />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      );
    }
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'archive':
        modalArchive(id);
        break;
      case 'publish':
        modalPublish(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalArchive = (id) => {
    Swal.fire({
      text: t('alerts:alerts:archive-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons:cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendArchive(id);
      }
    });
  };

  const modalPublish = (id) => {
    Swal.fire({
      text: t('alerts:alerts:publish-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons:cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendPublish(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts:delete-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons:cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const goCopy = (e, templateCopy) => {
    Swal.fire({
      text: t('alerts:alerts:copy-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons:cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendCopy(templateCopy, e);
      }
    });
  };

  const sendCopy = (templateCopy, e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/templateHability/`, templateCopy)
      .then((res) => {
        setLoading(false);
        history.push('/templates/edited');
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts:copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts:copied-error'),
          showConfirmButton: false,
          timer: 1500,
        });
        history.push('/500');
        window.location.reload();
      });
  };

  const sendPublish = async (id) => {
    const { data } = await axios
      .put(`/templateBadge/${templateHability.templateBadge.id}`, {
        estado: 1,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts:published'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/templates/published');
      });
    }
  };

  const sendArchive = async (id) => {
    const { data } = await axios
      .put(`/templateBadge/${templateHability.templateBadge.id}`, {
        estado: 2,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts:archived'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/templates/archived');
      });
    }
  };

  const sendDelete = async (id) => {
    const { data } = await axios
      .delete(`/templateBadge/${templateHability.templateBadge.id}`, {
        data: { id_usuarios: user.data_user?.id },
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      if (templateHability.templateBadge.estado === 0) {
        Swal.fire({
          text: t('alerts:alerts:deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/templates/edited');
        });
      } else {
        Swal.fire({
          text: t('alerts:alerts:deleted'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push('/templates/published');
        });
      }
    }
  };

  const goEdit = () => {
    props.history.push(`/templates/edit/${encrypt(templateHability.templateBadge.id)}`);
  };

  const handleOpenDialog = () => {
    setVista({
      ...templateHability?.templateBadge,
      tipos: type?.valor_parametro,
      costos: cost?.valor_parametro,
      tiempos: time?.valor_parametro,
      niveles: level?.valor_parametro,
      img_prev: templateHability?.linkPhoto,
      id_plantillas_recomendadas: recommendations?.map((item) => ({
        linkPhoto: item.linkPhoto,
        id_plantillas_recomendadas: item.id_plantillas_recomendadas,
        nombre: item.plantillasInsigniasRecomendadas?.nombre,
      })),
      id_habilidades: skills?.map((item) => ({
        id_habilidades: item.id_habilidades,
        descripcion: item.habilidades.descripcion,
      })),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Template Index={0} Buttons={loadButtons()} BadgeId={decrypt(props.match.params.id)}>
      <Card elevation={0}>
        <CardContent className={classes.root} maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} xs={12}>
              <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                {loading ? (
                  <Skeleton variant="circle">
                    <Avatar className={classes.avatar} />
                  </Skeleton>
                ) : (
                  <Avatar alt="img-prev" src={templateHability.linkPhoto} variant="rounded" className={classes.avatar}>
                    <Brightness5Outlined sx={{ margin: 3, fontSize: '4em' }} />
                  </Avatar>
                )}
              </div>
            </Grid>
            <Grid item lg={9} md={9} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={6} xs={6}>
                  <Typography variant="h4" gutterBottom className={classes.textTitle}>
                    {loading ? <Skeleton /> : `${templateHability.templateBadge?.nombre}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={6}>
                  <Typography variant="h6" gutterBottom className={classes.textTitle}>
                    {loading ? <Skeleton /> : `${templateHability.templateBadge?.nombre_privado || ''}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={6}>
                  <Typography className={classes.textParagraph}>
                    {loading ? <Skeleton /> : `${templateHability.templateBadge?.descripcion}`}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={6} xs={6}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.issued-by')}: </span>
                      <span className={classes.text}>{templateHability.templateBadge?.organizaciones?.nombre}</span>
                    </>
                  )}
                </Grid>
                <Grid item lg={12} md={6} xs={6}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.created')}: </span>
                      <span className={classes.text}>
                        {moment(templateHability.templateBadge?.fecha_creacion).format('DD-MM-YYYY')}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.root} />
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12} justify="center">
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.skills')}:</span>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {loading ? (
                    <Skeleton variant="rect" width="100%" height="5em" />
                  ) : (
                    <>
                      {skills.map((data) => (
                        <Box display="flex">
                          <Box borderRadius={10} {...defaultPropsUser}>
                            <Typography>{data.habilidades.descripcion}</Typography>
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <Grid item lg={5} md={5} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={6} xs={6}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.type')}: </span>
                      <span className={classes.text}>{type.valor_parametro}</span>
                    </>
                  )}
                </Grid>
                <Grid item lg={12} md={6} xs={6}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.level')}: </span>
                      <span className={classes.text}>{level.valor_parametro}</span>
                    </>
                  )}
                </Grid>
                {loading ? (
                  <>
                    <Grid item lg={12} md={6} xs={6}>
                      <Skeleton variant="text" />
                    </Grid>
                  </>
                ) : (
                  <>
                    {templateHability?.templateBadge?.cantidad_tiempo !== null &&
                    time?.valor_parametro !== undefined ? (
                      <Grid item lg={12} md={6} xs={6}>
                        <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.time')}: </span>
                        <span
                          className={classes.text}
                        >{`${templateHability?.templateBadge?.cantidad_tiempo} ${time?.valor_parametro}`}</span>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </>
                )}
                <Grid item lg={12} md={6} xs={6}>
                  {loading ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <>
                      <span className={classes.textBolder}>{t('certificateBadgeTemplate:details.cost')}: </span>
                      <span className={classes.text}>{cost.valor_parametro}</span>
                    </>
                  )}
                </Grid>
                <Grid item lg={12} md={6} xs={6}>
                  <Typography className={classes.textBolder}>
                    {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.criteria')}:`}
                  </Typography>
                </Grid>
                <Grid>
                  {loading ? (
                    <>
                      <Skeleton variant="text" width={200} />
                    </>
                  ) : (
                    <>
                      {criteria.map((data, index) => (
                        <Typography key={`criteria${index}`} className={classes.text} gutterBottom>
                          {data.descripcion_criterio}
                        </Typography>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.root} />
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12}>
              {recommendations?.length > 0 ? (
                <>
                  <Typography className={classes.textBolder}>
                    {t('certificateBadgeTemplate:details.recommendations')}:
                  </Typography>

                  <div className={classes.badges}>
                    {recommendations.map((recommendation, index) => (
                      <BadgeCard
                        key={`badge${index}`}
                        badgeImage={recommendation?.linkPhoto}
                        badgeName={recommendation?.plantillasInsigniasRecomendadas?.nombre}
                        organizationName={recommendation?.plantillasInsigniasRecomendadas?.organizaciones?.nombre}
                        issuedId={encrypt(recommendation?.plantillasInsigniasRecomendadas?.id)}
                        publicBadge={false}
                        templateBadge={true}
                      />
                    ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle align="center" onClose={handleClose}>
          {t('dialogs:dialog.preview-title')}
        </DialogTitle>
        <DialogContent dividers>
          <PreviewDialog vista={vista}>
            {vista?.id_plantillas_recomendadas?.map((recommendation, index) => (
              <BadgeCard
                key={`badge${index}`}
                badgeImage={recommendation.linkPhoto}
                badgeName={recommendation.nombre}
                organizationName={vista.organizaciones?.nombre}
                publicBadge={false}
                templateBadge={true}
              />
            ))}
          </PreviewDialog>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={handleClose}
          >
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Template>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
  },
  img: {
    marginLeft: '4em',
  },
  textBolder: {
    marginTop: '1em',
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#5c5c5c',
    margin: '1em 0 1em 0',
  },
  textTitle: {
    color: '#5d5d5d',
    fontWeight: 'bold',
  },
  text: {
    color: '#5c5c5c',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 30px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  badges: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    margin: '30px 30px 0px 30px',
    '& div': {
      marginBottom: 20,
      justifySelf: 'center',
    },
  },
  buttonForm: {
    margin: '0.5em',
    padding: '9px 40px',
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'plantillas')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
