import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FolderOpenOutlined } from '@mui/icons-material';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import SkeletonTables from '../../../components/SkeletonTable';
import axios from '../../../api';
import { changePage, changeBreadcrump, sortData, getComparator, setSearchValue } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '3rem',
  },
  table: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function DocumentsCollections(props) {
  const { page, rowsPerPage, permission, changePage, changeBreadcrump, user, organizationId, setSearchValue } = props;
  const history = useHistory();
  const classes = useStyles();
  const [documentsCollections, setDocumentsCollections] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData();
    } else {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [window.location.href]);

  const loadData = async () => {
    try {
      setLoading(true);
      await getDocumentsCollections();
      const typeView = window.location.href.includes('sign');
      changeBreadcrump(
        t(`breadCrumps:breadCrumps.documents-files-folders-files${typeView ? '-sign' : ''}`, {
          returnObjects: true,
        })
      );
      setLoading(false);
      setSearchValue('');
    } catch (error) {
      history.push('/500');
    }
  };

  const getDocumentsCollections = async () => {
    const typeView = window.location.href.includes('sign') ? 'sign' : 'normal';

    const { data } = await axios.post(`/documentsCollections/getDocumentsCollections_v2`, { estado: 1 });
    let dataFiltered = [];

    if (typeView === 'sign') {
      dataFiltered = data.documentsCollections.filter((files) => !files.normalDoc || files.mixDoc);
    } else {
      dataFiltered = data.documentsCollections.filter((files) => files.normalDoc || files.mixDoc);
    }

    if (
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
    ) {
      setDocumentsCollections(dataFiltered);
      setFiltradas(dataFiltered);
    } else {
      setDocumentsCollections(dataFiltered.filter((item) => item.id_organizaciones === organizationId));
      setFiltradas(dataFiltered.filter((item) => item.id_organizaciones === organizationId));
    }
  };

  const toLink = (e, id, nameCollections) => {
    const typeView = window.location.href.includes('sign');
    changePage(0);
    const breadcrump = t(`breadCrumps:breadCrumps.documents-files-folders-files${typeView ? '-sign' : ''}`, {
      returnObjects: true,
    });
    breadcrump.push({ name: nameCollections });
    changeBreadcrump(breadcrump);
    history.push(`/documents${typeView ? '/sign' : ''}/files/${id}`);
  };

  const columns = () => {
    return t('tables:table.columns-documents-folders', { returnObjects: true });
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="groupDocuments"
        items={documentsCollections}
        setItems={setFiltradas}
        changePage={changePage}
        loadData={loadData}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        setLoading={setLoading}
      />
      <Table
        columns={columns()}
        rows={filtro}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    hover
                    className={classes.table}
                    key={`row${index}`}
                    onClick={(e) => toLink(e, encrypt(row.id), row.nombre)}
                  >
                    <TableCell align="center">
                      <FolderOpenOutlined sx={iconStyle} />
                    </TableCell>
                    <TableCell align="center">{`${row.nombre}`}</TableCell>
                    <TableCell align="center">{`${row.organizaciones?.nombre}`}</TableCell>
                    <TableCell align="center">{moment(row.fecha_creacion).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="4">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const iconStyle = {
  color: '#888888',
  fontSize: '3rem',
};

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'insignias emitidas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
  setSearchValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsCollections);
