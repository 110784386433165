import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModuleHeader from '../../../components/ModuleHeader';
import { changeBreadcrump } from '../../../actions';
import {
  makeStyles,
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Tooltip,
  Typography,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { ArrowUpwardOutlined, ArrowDownwardOutlined, CloseOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import axios from '../../../api';
import { decrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    //padding: '7px 40px',
  },
  iconMargin: {
    margin: theme.spacing(2),
  },
  añadir: {
    marginTop: '1em',
  },
  añadirButton: {
    //color: '#0056b3',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  },
}));

function CreateValueParameter(props) {
  const classes = useStyles();
  const history = useHistory();
  const [form, setForm] = useState({ parametro: '' });
  const [valueParametes, setValueParametes] = useState([{ index: 1, valor_parametro: '', orden: '1' }]);
  const [valueParametesAll, setValueParametesAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const { changeBreadcrump } = props;
  const [last, setLast] = useState('');

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'menu', 'parameterValues']);

  useEffect(() => {
    loadLast().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    if (props.match.params.id) {
      getParametersValueParameters().catch(() => {
        history.push('/500');
        window.location.reload();
      });

      changeBreadcrump(t('breadCrumps:breadCrumps.parameter-values-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.parameter-values-create', { returnObjects: true }));
    }
    // eslint-disable-next-line
  }, []);

  const loadLast = async () => {
    const { data } = await axios.post(`/parameterValueParameter/getParameterValueParameter`);
    setLast(data.parametersValueParameters[0].id);
  };

  const getParametersValueParameters = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/parameterValueParameter/${id}`);
    setForm({ parametro: data.parameterValueParameter[0]?.parametros?.nombre });
    setValueParametes([
      ...data.parameterValueParameter
        .sort((a, b) => (a.valor_parametro.toLowerCase() < b.valor_parametro.toLowerCase() ? -1 : 1))
        .map((item, index) => ({
          index: item.id,
          valor_parametro: item.valor_parametro,
          orden: item.orden === 0 ? index + 1 : item.orden,
        })),
    ]);
    setValueParametesAll([...data.parameterValueParameter.map((item) => item.id)]);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputValueParameters = (event, index) => {
    valueParametes.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setValueParametes([...valueParametes]);
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleCancel = () => {
    history.push('/valueParameters');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleToggle();

    if (!props.match.params.id) {
      axios
        .post(`/parameterValueParameter/`, { ...form, valor_parametro: valueParametes })
        .then((res) => {
          handleClose();
          history.push('/valueParameters');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.created'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.created-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    } else {
      const id = decrypt(props.match.params.id);
      axios
        .put(`/parameterValueParameter/${id}`, { ...form, valor_parametro: valueParametes })
        .then((res) => {
          handleClose();
          history.push('/valueParameters');
          Swal.fire({
            icon: 'success',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited'),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.edited-error'),
            showConfirmButton: false,
            timer: 1500,
          });
          history.push('/500');
          window.location.reload();
        });
    }
  };

  const addValueParameters = async () => {
    setValueParametes([...valueParametes, { index: last + 1, valor_parametro: '', orden: valueParametes.length + 1 }]);
    setLast(last + 1);
  };

  const deleteValueParameters = async (e) => {
    if (props.match.params.id) {
      const valorParametroNombre = valueParametes.filter((item) => item.index === e);
      if (valueParametesAll.includes(valorParametroNombre[0]?.index)) {
        Swal.fire({
          text: t('alerts:alerts.delete-parameter-value'),
          icon: 'warning',
          iconColor: colors.primary,
          showCancelButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          cancelButtonText: t('buttons:buttons.cancel'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
            cancelButton: 'custom-button-cancel',
          },
        }).then(async (result) => {
          if (result.value) {
            if (valueParametes.length !== 1) {
              const valueParametesDeleted = valueParametes
                .sort((a, b) => (a.orden < b.orden ? -1 : 1))
                .filter((item) => item.index !== e)
                .map((iten, index) => {
                  if (iten.valor_parametro === '') {
                    iten['valor_parametro'] = null;
                  }
                  iten['orden'] = index + 1;
                  return iten;
                });
              setValueParametes(
                valueParametesDeleted.sort((a, b) =>
                  a.valor_parametro
                    ? a.valor_parametro.toLowerCase() < b.valor_parametro.toLowerCase()
                      ? -1
                      : 1
                    : a.valor_parametro < b.valor_parametro
                    ? -1
                    : 1
                )
              );
            } else {
              setValueParametes([{ index: last + 1, valor_parametro: '', orden: '1' }]);
              setLast(last + 1);
            }
            Swal.fire({
              icon: 'info',
              iconColor: colors.primary,
              text: `${t('alerts:alerts.alert-delete-parameter-value')} "${valorParametroNombre[0]?.valor_parametro}"`,
              showConfirmButton: true,
              confirmButtonText: t('buttons:buttons.accept'),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'custom-button-confirm',
              },
            });
          }
        });
      } else {
        if (valueParametes.length !== 1) {
          const valueParametesDeleted = valueParametes
            .sort((a, b) => (a.orden < b.orden ? -1 : 1))
            .filter((item) => item.index !== e)
            .map((iten, index) => {
              if (iten.valor_parametro === '') {
                iten['valor_parametro'] = null;
              }
              iten['orden'] = index + 1;
              return iten;
            });
          setValueParametes(
            valueParametesDeleted.sort((a, b) =>
              a.valor_parametro
                ? a.valor_parametro.toLowerCase() < b.valor_parametro.toLowerCase()
                  ? -1
                  : 1
                : a.valor_parametro < b.valor_parametro
                ? -1
                : 1
            )
          );
        } else {
          setValueParametes([{ index: last + 1, valor_parametro: '', orden: '1' }]);
          setLast(last + 1);
        }
      }
    } else {
      if (valueParametes.length !== 1) {
        const valueParametesDeleted = valueParametes
          .filter((item) => item.index !== e)
          .map((iten, index) => {
            iten['orden'] = index + 1;
            return iten;
          });
        setValueParametes(valueParametesDeleted);
      } else {
        setValueParametes([{ index: last + 1, valor_parametro: '', orden: '1' }]);
        setLast(last + 1);
      }
    }
  };

  const ordenUp = (orden) => {
    if (orden + 1 <= valueParametes.length) {
      valueParametes.map((item) => {
        if (item.orden === orden) {
          item['orden'] = item.orden + 1;
        } else if (item.orden === orden + 1) {
          item['orden'] = item.orden - 1;
        }
        return item;
      });
      setValueParametes([...valueParametes]);
    }
  };

  const ordenDown = (orden) => {
    if (orden - 1 >= 1) {
      valueParametes.map((item) => {
        if (item.orden === orden) {
          item['orden'] = item.orden - 1;
        } else if (item.orden === orden - 1) {
          item['orden'] = item.orden + 1;
        }
        return item;
      });
      setValueParametes([...valueParametes]);
    }
  };

  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography align="center" component="h6" variant="h6">
              {t('parameterValues:title')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('parameterValues:parameter')}
                    name="parametro"
                    value={form.parametro}
                    variant="outlined"
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {valueParametes.map((item) => (
              <Grid container key={item.id} spacing={1} className={classes.root}>
                <Grid item xs={2} key={item.id} lg={1}>
                  <FormControl className="w-100">
                    <TextField type="number" label="Orden" variant="outlined" name="orden" value={item.orden} />
                  </FormControl>
                </Grid>
                <Grid item xs={8} key={item.id} lg={10}>
                  <FormControl className="w-100">
                    <TextField
                      required
                      label={t('parameterValues:parameter-value')}
                      variant="outlined"
                      name="valor_parametro"
                      value={item.valor_parametro ? item.valor_parametro : ''}
                      onChange={(e) => handleInputValueParameters(e, item.index)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={(e) => ordenUp(item.orden)}>
                              <ArrowUpwardOutlined />
                            </IconButton>
                            <IconButton onClick={() => ordenDown(item.orden)}>
                              <ArrowDownwardOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                        /*endAdornment:
                          <InputAdornment position="start">
                          <IconButton onClick={(e) => ordenUp(item.orden)}><ArrowUpwardOutlined /></IconButton>
                            <IconButton onClick={() => ordenDown(item.orden)}><ArrowDownwardOutlined /></IconButton>
                          </InputAdornment>*/
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} key={item.id} lg={1}>
                  <Tooltip title={t('parameterValues:delete')}>
                    <IconButton
                      aria-label="delete"
                      className={classes.iconMargin}
                      size="small"
                      onClick={(e) => deleteValueParameters(item.index)}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
            <div className={`text-right ${classes.añadir}`}>
              <Button color="primary" onClick={addValueParameters} className={classes.añadirButton}>
                {t('parameterValues:add')}
              </Button>
            </div>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(null, mapDispatchToProps)(CreateValueParameter);
