import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  MenuItem,
  TextField,
  Button,
  Slider,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { generateString } from '../utils/crypt';
import { env } from '../config/environment';
import axios from '../api';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import FormPayu from './FormPayu';
import Swal from 'sweetalert2';
import md5 from 'crypto-js/md5';
import colors from '../assets/styles/colors';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const RechargeIssue = (props) => {
  const { user, organizationId } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    cantidad: 500,
    amount: 5000000,
    unit_amountText: '10,000',
    amountText: '5,000,000',
    currency: 'COP',
  });
  const [error, setError] = useState({});
  const [dataPayu, setDataPayu] = useState(null);
  const [plans, setPlans] = useState([]);
  const [typePlan, setTypePlan] = useState('1');

  /* translation */
  const [t] = useTranslation(['alerts', 'buttons', 'contracts']);

  const currencies = [
    {
      value: 'COP',
      label: '$',
    },
    {
      value: 'USD',
      label: '$',
    },
  ];

  useEffect(() => {
    const code = `Certika${generateString(10)}`;
    setForm({
      ...form,
      referenceCode: code,
      signature: md5(`${env.API_KEY_PAYU}~${env.merchantId}~${code}~5000000~COP`).toString(),
    });
    getPlans().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const plan = plans?.filter((plan) => plan.id === parseInt(typePlan));
    if (plan.length > 0) {
      setForm({
        ...form,
        cantidad: typePlan === '4' ? 10 : plan[0].cantidad_emisiones,
      });
      setError({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typePlan]);

  useEffect(() => {
    handleAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.cantidad, form.currency]);

  const getPlans = async () => {
    const { data } = await axios.get(`/others/getPlans`);
    setPlans(data.plans);
  };

  const handleAmount = () => {
    let amount = 0;
    let unit_amountText = '';
    const plan = plans?.filter((plan) => plan.id === parseInt(typePlan));
    if (plan.length > 0) {
      if (form.currency === 'COP') {
        amount = form.cantidad * plan[0].precio_unitario_cop;
        unit_amountText = plan[0].precio_unitario_cop.toLocaleString('es-MX');
      } else if (form.currency === 'USD') {
        amount = form.cantidad * plan[0].precio_unitario;
        unit_amountText = plan[0].precio_unitario.toLocaleString('es-MX');
      }

      const referenceCode = `Certika${generateString(10)}`;
      //Apikey ~ merchantId ~ referenceCode ~ amount ~ currency
      const md5Signature = md5(
        `${env.API_KEY_PAYU}~${env.merchantId}~${referenceCode}~${amount}~${form.currency}`
      ).toString();

      setForm({
        ...form,
        amount: amount,
        amountText: amount.toLocaleString('es-MX'),
        unit_amountText: unit_amountText,
        referenceCode: referenceCode,
        signature: md5Signature,
      });
    }
  };

  const closeDialog = () => {
    setDataPayu(null);
    setOpen(false);
    setTypePlan('1');
  };

  const handleInput = (event) => {
    const numbers = /^[0-9]*$/;

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'cantidad') {
      setTypePlan('4');
      if (
        event.target.value === 0 ||
        event.target.value < 10 ||
        event.target.value > 2000 ||
        !numbers.test(event.target.value)
      ) {
        setError({ ...error, [event.target.name]: true });
      } else {
        setError({ ...error, [event.target.name]: false });
      }
    }
  };

  const handleConfirmation = () => {
    setOpen(false);
    Swal.fire({
      text: `¿${t('alerts:alerts.buy-issues')} ${form.cantidad} ${t('alerts:alerts.issues-for')} $${form.amountText} ${
        form.currency
      }?`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        handleSubmit();
      } else {
        setOpen(true);
      }
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    axios
      .post(`/payment/`, {
        ...form,
        id_usuarios: user.data_user.id,
        id_organizaciones: organizationId,
        id_planes: parseInt(typePlan),
      })
      .then((res) => {
        if (res.data?.paymentId) {
          setLoading(false);
          setOpen(false);
          setDataPayu({
            ...form,
            amount: parseFloat(form.amount),
            buyerFullName: `${user.data_user.primer_nombre} ${user.data_user.primer_apellido}`,
            buyerEmail: user.correo,
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.error-ocurred'),
          showConfirmButton: false,
          timer: 3000,
        });
        setLoading(false);
        setOpen(false);
      });
  };

  const toWhatsapp = async () => {
    let numero = '573012479849';
    let mensaje = 'Hola, quiero comprar más emisiones';
    let url = `https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <Tooltip title="Recargar emisiones">
        <IconButton aria-label="recharge" color="secondary" size="small" onClick={() => toWhatsapp()}>
          <AddOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle align="center" onClose={closeDialog} />
        <DialogContent>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Grid container className={classes.container} spacing={1}>
                <Grid item xs={12} sm={12} align="center">
                  <Typography variant="h6" component="h6" className={classes.title}>
                    {t('contracts:select-issue')}
                  </Typography>
                  <div style={{ width: '10em' }} className={classes.grid}>
                    <TextField
                      required
                      id="cantidad"
                      label=""
                      name="cantidad"
                      type="number"
                      size="large"
                      value={form.cantidad}
                      onChange={handleInput}
                      error={error.cantidad}
                      helperText={error.cantidad && 'Digite una cantidad válida.'}
                      InputProps={{
                        classes: {
                          root: classes.container__input_root,
                        },
                        inputProps: {
                          style: { textAlign: 'center' },
                        },
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} align="center">
                  <div className={classes.div}>
                    <PrettoSlider
                      name="cantidad"
                      value={form.cantidad}
                      onChange={(e, value) => {
                        setTypePlan(value === 500 ? '1' : value === 1000 ? '2' : value === 2000 ? '3' : '4');
                      }}
                      valueLabelDisplay="off"
                      aria-label="pretto slider"
                      defaultValue={500}
                      marks={[
                        {
                          value: 10,
                          label: '10',
                        },
                        {
                          value: 500,
                          label: '',
                        },
                        {
                          value: 1000,
                          label: '',
                        },
                        {
                          value: 2000,
                          label: '2,000',
                        },
                      ]}
                      step={null}
                      min={10}
                      max={2000}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} align="center">
                  <div className={classes.div_m}>
                    <Typography align="left" className={classes.title}>
                      {typePlan === '1'
                        ? t('contracts:lite')
                        : typePlan === '2'
                        ? t('contracts:plus')
                        : typePlan === '3'
                        ? t('contracts:premium')
                        : t('contracts:on-demand')}
                    </Typography>
                    <span>
                      <Typography align="left" className={classes.title}>
                        {`V/${t('contracts:unit')} \n $${form.unit_amountText}`}{' '}
                        <TextField
                          select
                          size="small"
                          label=""
                          name="currency"
                          value={form.currency}
                          onChange={handleInput}
                        >
                          {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </span>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} align="center">
                  <div className={classes.div}>
                    <Divider />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} align="center">
                  <div className={classes.div_m}>
                    <Typography align="left" className={classes.title}>
                      {t('contracts:total-pay')}
                    </Typography>
                    <Typography align="left" className={classes.title}>
                      <b>{`$${form.amountText} ${form.currency} / ${t('contracts:year')}`}</b>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            onClick={handleConfirmation}
            disabled={form.cantidad === 0 || form.amount === 0 || error.cantidad}
            color="primary"
            variant="contained"
            style={{ margin: '.5em 1em' }}
          >
            {t('contracts:pay')}
          </Button>
        </DialogActions>
      </Dialog>
      <FormPayu
        location={`${env.certikaURL}/responsePage`}
        formData={dataPayu}
        action={(r) => {
          if (r && dataPayu) r.submit();
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  div: {
    width: '60vh',
  },
  div_m: {
    justifyContent: 'space-between',
    width: '60vh',
    display: 'flex',
    whiteSpace: 'pre-line',
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.5em',
    textAlign: 'center',
  },
  container__input_root: {
    borderRadius: '50px',
    color: theme.palette.primary.main,
    fontSize: '1.5em',
    textAlign: 'center',
  },
}));

const PrettoSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId,
  };
};

export default connect(mapStateToProps, null)(RechargeIssue);
