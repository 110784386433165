import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  Grid,
} from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';
import ModuleHeader from '../../components/ModuleHeader';
import Search from '../../components/SearchBar';
import Table from '../../components/Table';
import SkeletonTables from '../../components/SkeletonTable';
import Backdrop from '../../components/Backdrop';
import api from '../../api';
import colors from '../../assets/styles/colors';
import { changePage, changeBreadcrump, sortData, getComparator } from '../../actions';
import { useTranslation } from 'react-i18next';
import ButtonSignatureIcon from '../../assets/img/signature.png';
import ButtonSignatureIconWhite from '../../assets/img/signatureWhite.png';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import SignatureDrawing from '../../components/SignatureDrawing';
import SignatureText from '../../components/SignatureText';
import SignatureFile from '../../components/SignatureFile';
import dataURItoBlob from '../../utils/dataUriToBlob';
import { env } from '../../config/environment';
import MySignatures from '../../components/MySignatures';
import webFont from 'webfontloader';
import axios from 'axios';
import md5 from 'crypto-js/md5';

function SignDiplomas(props) {
  const { page, rowsPerPage, permission, changePage, changeBreadcrump, user, organizationId } = props;
  const history = useHistory();
  const classes = useStyles();
  const [groupsDiplomasIssued, setGroupsDiplomasIssued] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSign, setOpenSign] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [form, setForm] = useState({});
  const [currentDiplomaSign, setCurrentDiplomaSign] = useState(1);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [countItems, setCountItems] = useState();
  const [progressBar, setProgressBar] = useState({});
  const [thereCurrentSign, setThereCurrentSign] = useState(false);
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs']);

  const fontsFamilysTypeHand = [
    'Caveat',
    'Caveat Brush',
    'Cookie',
    'Architects Daughter',
    'Cedarville Cursive',
    'Dawning of a New Day',
    'Crafty Girls',
    'League Script',
  ];
  const isSuperUser = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1;
  const md5OrganizationRoute = md5(`certika-organization-${isSuperUser ? 1 : organizationId}`).toString();
  const md5UserRoute = md5(`certika-user-${isSuperUser ? 1 : user?.data_user?.id}`).toString();

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData();
    } else {
      history.push('/dashboard');
    }
    return () => {
      changePage(0);
    };
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    try {
      webFont.load({
        google: {
          families: fontsFamilysTypeHand,
        },
        active: () => {
          setLoading(false);
        },
      });
      setLoading(true);
      await getGroupsDiplomasIssued();
      changeBreadcrump(t('breadCrumps:breadCrumps.groups-diplomas-issued', { returnObjects: true }));
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getGroupsDiplomasIssued = async () => {
    try {
      const params = getParams(0, rowsPerPage);
      const { data } = await api.post(`/groupsBadgesIssued/getGroups/diplomasForSign`, params);
      setGroupsDiplomasIssued(data.groups?.rows);
      setFiltradas(data.groups?.rows);
      setCountItems(data.groups?.count);
    } catch (error) {
      history.push('/500');
      window.location.reload();
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await api.post(`/groupsBadgesIssued/getGroups/diplomasForSign`, params);

    const array = [
      ...groupsDiplomasIssued,
      ...data.groups?.rows.filter((row) => !groupsDiplomasIssued.find((item) => item.id === row.id)),
    ];

    setGroupsDiplomasIssued(array);
    setFiltradas(array);
    setLoading(false);
  };

  const Sign = async (id_grupos_diplomas_emitidos) => {
    try {
      setLoadingBackdrop(true);
      const { data: diplomas } = await api.post(`/diplomasIssued/getDiplomasForSign`, {
        id_grupos_diplomas_emitidos,
      });
      setForm({ ...diplomas.issued });
      setLoadingBackdrop(false);
      setCurrentDiplomaSign(1);
      setOpenDialog(true);
    } catch (error) {
      setLoadingBackdrop(false);
      return Swal.fire({
        icon: 'error',
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const columns = () => {
    return t('tables:table.columns-group-diplomas-issued', { returnObjects: true });
  };

  const handleChangeDiploma = (event, value) => {
    setCurrentDiplomaSign(value);
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  const closeModalSign = () => {
    setOpenSign(false);
  };

  const saveSign = async (stageRef) => {
    setLoadingBackdrop(true);
    const { data: ipData } = await axios({
      method: 'get',
      url: `https://api.ipdata.co?api-key=${env.keyIpData}`,
    });

    const trailDate = moment(new Date());

    if (stageRef.idSign) {
      try {
        await api.post(`/diplomasIssued/sign`, {
          id_firmas: stageRef.idSign,
          id_diplomas_emitidos: form?.rows[currentDiplomaSign - 1]?.id,
          ip_adress: ipData.ip,
          trailDate,
        });

        setLoadingBackdrop(false);
        setOpenSign(false);
        setThereCurrentSign(true);
      } catch (error) {
        setThereCurrentSign(false);
        setLoadingBackdrop(false);
        setOpenSign(false);
        Swal.fire({
          customClass: { container: classes.mySwal },
          icon: 'error',
          text: t('alerts:alerts.try-again'),
        });
      }
    } else {
      const uri = stageRef.current.toDataURL();
      const blobData = dataURItoBlob(uri);
      const signature = new FormData();

      const routeSign = `${md5OrganizationRoute}/diplomasTemplate/signatures/${md5UserRoute}`;
      signature.append('file', blobData);
      signature.append('route', routeSign);
      signature.append('userId', user?.data_user?.id);
      try {
        const { data: signId } = await axios({
          method: 'post',
          url: `${env.apiURL}/file/upload-certificate`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: signature,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded * 100) / total);
            setProgressBar({ value: percentage });
          },
        });

        await api.post(`/diplomasIssued/sign`, {
          id_firmas: signId.id,
          id_diplomas_emitidos: form?.rows[currentDiplomaSign - 1]?.id,
          ip_adress: ipData.ip,
        });

        setLoadingBackdrop(false);
        setOpenSign(false);
        setProgressBar({});
        setThereCurrentSign(true);
      } catch (error) {
        Swal.fire({
          customClass: { container: classes.mySwal },
          icon: 'error',
          text: t('alerts:alerts.try-again'),
        });
        setThereCurrentSign(false);
        setLoadingBackdrop(false);
        setOpenSign(false);
        setProgressBar({});
      }
    }
  };

  const SignDiploma = () => {
    if (!thereCurrentSign) {
      setOpenSign(true);
    }
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="groupIssued"
        type="certificates"
        items={groupsDiplomasIssued}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
      />
      <Table
        columns={columns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">{`${row.nombre}`}</TableCell>
                    <TableCell align="center">{`${row.organizaciones?.nombre || row.nombre_organizacion}`}</TableCell>
                    <TableCell align="center">{`${row.usuarios?.primer_nombre} ${row.usuarios?.primer_apellido}`}</TableCell>
                    <TableCell align="center">
                      {row.diplomasIssued}
                      <Tooltip title="Firmar Diplomas">
                        <IconButton aria-label="detalles" onClick={(e) => Sign(row.id)}>
                          <img
                            src={ButtonSignatureIcon}
                            alt="Signatue"
                            style={{
                              width: '48px',
                              height: '48px',
                              padding: '12px',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: 'blue',
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="6">
                {t('tables:table.empty-table-diploma')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={openSign} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <Tabs
            value={valueTab}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.my-sign')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.draw')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.write')} />
            <Tab style={{ fontWeight: 'bold' }} label={t('buttons:buttons.upload')} />
          </Tabs>
          <Grid container justifyContent="center">
            {valueTab === 0 && (
              <MySignatures closeModalSign={closeModalSign} saveSign={saveSign} progressBar={progressBar} />
            )}
            {valueTab === 1 && (
              <SignatureDrawing
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                emailUser={user.correo}
              />
            )}
            {valueTab === 2 && (
              <SignatureText
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                nameUser={`${user.data_user.primer_nombre} ${user.data_user.primer_apellido}`}
                emailUser={user.correo}
                fontsFamilys={fontsFamilysTypeHand}
              />
            )}
            {valueTab === 3 && (
              <SignatureFile
                closeModalSign={closeModalSign}
                saveSign={saveSign}
                progressBar={progressBar}
                emailUser={user.correo}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={'sm'}>
        <DialogTitle>Email receptor: {form?.rows && form?.rows[currentDiplomaSign - 1]?.email_receptor}</DialogTitle>

        <img
          alt="img-prev"
          src={form?.rows && form?.rows[currentDiplomaSign - 1]?.linkPhoto}
          style={{ border: 'groove', margin: '3px' }}
        />

        <Pagination
          style={
            form?.count <= 4
              ? { display: 'grid', placeItems: 'center', margin: '3px 0 3px 0' }
              : { margin: '3px 0 3px 0' }
          }
          color="secondary"
          size="large"
          count={form?.count}
          page={currentDiplomaSign}
          onChange={handleChangeDiploma}
        />
        <DialogActions style={{ justifyContent: 'center' }}>
          <Tooltip title="Firmar">
            <Button variant="contained" className={`${classes.buttonDialogSign}`} onClick={(e) => SignDiploma(e)}>
              <img
                src={ButtonSignatureIconWhite}
                style={{ width: '48px', height: '48px', padding: '12px' }}
                alt="Sign"
              />
            </Button>
          </Tooltip>
          <Tooltip title="Cancelar">
            <Button
              variant="contained"
              className={`my-2 ${classes.buttonDialogClose}`}
              onClick={() => setOpenDialog(false)}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>

      <Backdrop loading={loadingBackdrop} />
    </>
  );
}

const useStyles = makeStyles(() => ({
  buttonDialogClose: {
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  buttonDialogSign: {
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    width: '64px',
    height: '42px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'firmar diplomas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignDiplomas);
