import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Stage, Layer, Text, Image, Transformer } from 'react-konva';
import { setDiplomaFields, setFormDiplomas } from '../../../actions';
import useImage from 'use-image';
import FieldImage from '../../../components/FieldImage';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import FieldVerify from '../../../components/FieldVerify';

function Documents(props) {
  const {
    imageRoute,
    width,
    height,
    modifyField,
    diplomaFields,
    setDiplomaFields,
    setFormDiplomas,
    formDiplomas,
    stageRef,
    selectedId,
    selectShape,
    handleChangePage,
    isSelected,
    setIsSelected,
    refField,
    setRefField,
    modifiedFieldIndex,
    setModifiedFieldIndex,
    documentSigned,
  } = props;
  const classes = useStyles({ imageRoute });
  const trRef = useRef();
  const refShape = useRef([]);
  refShape.current = [];
  const [t] = useTranslation(['alerts']);
  const [isDraggable, setIsDraggable] = useState(true);
  const [shapeRef, setShapeRef] = useState([]);

  const handleOnDragStart = (e) => {
    if (formDiplomas.change_template && formDiplomas.change_template !== 0) {
      Swal.fire({
        customClass: {
          container: classes.mySwal,
        },
        icon: 'info',
        text: t('alerts:alerts.new-templates-of-edited'),
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: t('alerts:alerts.yes-option'),
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setFormDiplomas({
            ...formDiplomas,
            change_template: 0,
            id_plantillas_diplomas: 0,
            nameTemplate: '',
          });
          setIsDraggable(true);
        } else {
          setIsDraggable(true);
        }
      });
      setIsDraggable(false);
    }
  };

  const handleOnDragEnd = (e, index) => {
    diplomaFields[index] = {
      ...diplomaFields[index],
      x: e.target.x(),
      y: e.target.y(),
    };

    if (diplomaFields[index].fieldTypeConfig === 52) {
      setIsSelected(false);
      setShapeRef(e.target);
      setIsSelected(true);
      setDiplomaFields(diplomaFields);
    } else {
      setIsSelected(false);
      setDiplomaFields(diplomaFields);
    }
  };

  useEffect(() => {
    if (isSelected && (shapeRef?.index || refField?.ref?.index)) {
      trRef.current.setNode(shapeRef || refField?.ref);
      trRef.current.getLayer().batchDraw();
      if (refField?.ref) {
        if (diplomaFields[refField.index].width === 'auto') {
          diplomaFields[refField.index] = {
            ...diplomaFields[refField.index],
            width: parseInt(refField.ref.getWidth()) + 5,
          };
        }
        setShapeRef(refField?.ref);
        setDiplomaFields(diplomaFields);
        setRefField(null);
      }
    } else if (isSelected && refShape) {
      trRef.current.setNode(refShape.current[refShape.current.length - 1].el);
      trRef.current.getLayer().batchDraw();
    }
    // eslint-disable-next-line
  }, [isSelected]);

  useEffect(() => {
    if (refField?.index) {
      if (diplomaFields[refField.index] && diplomaFields[refField.index].width === 'auto') {
        diplomaFields[refField.index] = {
          ...diplomaFields[refField.index],
          width: parseInt(refField.ref.getWidth()) + 5,
        };
      }
      setRefField(null);
      setDiplomaFields(diplomaFields);
    } else if (modifiedFieldIndex !== null) {
      const index = refShape.current.findIndex((item) => item.index === modifiedFieldIndex);
      if (diplomaFields[modifiedFieldIndex] && diplomaFields[modifiedFieldIndex].width === 'auto') {
        const width = parseInt(refShape.current[index].el.getWidth() + 5);
        diplomaFields[modifiedFieldIndex] = {
          ...diplomaFields[modifiedFieldIndex],
          width: width,
        };
      }
      if (diplomaFields[modifiedFieldIndex].fieldTypeConfig === 52) {
        trRef.current.setNode(refShape.current[index].el);
        trRef.current.getLayer().batchDraw();
      }
      setModifiedFieldIndex(null);
      setDiplomaFields(diplomaFields);
    }
    // eslint-disable-next-line
  }, [diplomaFields]);

  const BackgroundImage = () => {
    const [image] = useImage(imageRoute, 'Anonymous');
    return <Image image={image} />;
  };

  const onChange = (newAttrs, index) => {
    const rects = diplomaFields.slice();
    rects[index] = newAttrs;
    setDiplomaFields(rects);
  };

  const addToRef = (el, index) => {
    if (el && !refShape.current.includes({ el, index })) {
      refShape.current.push({ el, index });
    }
  };

  return (
    <>
      <div className={classes.pagination}>
        <Box component="span" display="flex" justifyContent="center">
          <Pagination
            color="secondary"
            count={formDiplomas?.pages || 0}
            onChange={handleChangePage}
            defaultPage={1}
            size="small"
          />
        </Box>
      </div>
      <div className={classes.container}>
        <Stage
          width={width}
          height={height}
          ref={stageRef}
          onMouseDown={(e) => {
            // deselect when clicked on empty area
            if (e.target.attrs.image) {
              selectShape(null);
              setIsSelected(false);
              setShapeRef(null);
            }
          }}
          onMouseLeave={() => {
            if (diplomaFields[diplomaFields.length - 1] && diplomaFields[diplomaFields.length - 1].width === 'auto') {
              diplomaFields[diplomaFields.length - 1] = {
                ...diplomaFields[diplomaFields.length - 1],
                width: parseInt(refShape.current[refShape.current.length - 1].el.getWidth()) + 5,
                align: 'left',
              };
              setDiplomaFields(diplomaFields);
            }
            selectShape(null);
            setIsSelected(false);
            setShapeRef(null);
          }}
        >
          <Layer>
            <BackgroundImage />
            {diplomaFields?.map((field, index) => {
              if (field.fieldType !== 48) {
                // is the id imagen
                return (
                  (field.page === formDiplomas?.page || field.page === 0) && (
                    <>
                      <Text
                        key={`field${index + 1}`}
                        ref={(e) => addToRef(e, index)}
                        index={index}
                        text={
                          field.fieldName === 'certificateCode'
                            ? `${field.exampleText}${field.exampleText === '' ? '' : ':'}CK09aABbAz`
                            : field.exampleText
                        }
                        x={field.x}
                        y={field.y}
                        width={field.width}
                        height={parseInt(field.fontSize)}
                        align={field.align}
                        ellipsis={true}
                        onClick={(e) => {
                          if (!documentSigned) {
                            if (field.fieldTypeConfig === 52) {
                              setIsSelected(false);
                              setShapeRef(e.target);
                              selectShape(field.exampleText);
                              setIsSelected(field.exampleText !== 'X' && field.exampleText !== '+' && true);
                            } else {
                              setIsSelected(false);
                            }
                          }
                        }}
                        draggable={
                          !documentSigned
                            ? !(field.exampleText === 'X' || field.exampleText === '+') && isDraggable
                            : false
                        }
                        dragBoundFunc={(pos, e) => {
                          let newX = pos.x;
                          let newY = pos.y;
                          if (newX < 0) newX = 0;
                          if (newY < 0) newY = 0;
                          if (newX > width - field.width) newX = width - field.width;
                          if (newY > height - field.height) newY = height - field.height;

                          return {
                            x: newX,
                            y: newY,
                          };
                        }}
                        fill={field.fontColor?.hex}
                        fontStyle={`${field.fontStyle?.bold ? 'bold' : ''} ${field.fontStyle?.italic ? 'italic' : ''}`}
                        textDecoration={`${field.fontStyle?.underline ? 'underline' : ''} ${
                          field.fontStyle?.strikethrough ? 'line-through' : ''
                        }`}
                        fontSize={parseInt(field.fontSize)}
                        fontFamily={field.fontFamily.family}
                        onDragStart={(e) => handleOnDragStart(e)}
                        onDragEnd={(e) => handleOnDragEnd(e, index)}
                        onDblClick={(e) => {
                          if (!documentSigned && !field.exampleText === '+') {
                            modifyField(index);
                            setRefField({ ref: e.target, index: index });
                          }
                        }}
                        onTransformEnd={(e) => {
                          // transformer is changing scale of the node
                          // and NOT its width or height
                          // but in the store we have only width and height
                          // to match the data better we will reset scale on transform end
                          const node = shapeRef;
                          const scaleX = node.scaleX();

                          // we will reset it back
                          node.scaleX(1);
                          node.scaleY(1);
                          node.width(Math.max(5, node.width() * scaleX));

                          onChange(
                            {
                              ...field,
                              width: Math.round(node.width()),
                            },
                            index
                          );
                        }}
                      />
                      {isSelected && (
                        <Transformer
                          ref={trRef}
                          rotateEnabled={false}
                          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                          boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            const minWidthHeight = 10;
                            if (newBox.width <= minWidthHeight) {
                              return oldBox;
                            }
                            const maxWidthHeight = width;
                            if (newBox.width >= maxWidthHeight) {
                              return oldBox;
                            }
                            return newBox;
                          }}
                        />
                      )}
                    </>
                  )
                );
              } else if (field.fieldName === 'buttonVerify') {
                return (
                  (field.page === formDiplomas?.page || field.page === 0) && (
                    <FieldVerify
                      key={index}
                      index={index}
                      shapeProps={field}
                      isSelected={field.exampleText === selectedId}
                      isDraggable={!documentSigned && isDraggable}
                      handleOnDragStart={handleOnDragStart}
                      onSelect={() => {
                        if (!documentSigned) {
                          setIsSelected(false);
                          setShapeRef(null);
                          selectShape(field.exampleText);
                        }
                      }}
                      onChange={(newAttrs) => {
                        const rects = diplomaFields.slice();
                        rects[index] = newAttrs;
                        setDiplomaFields(rects);
                      }}
                      layoutWidth={width}
                      layoutHeight={height}
                      modifyField={!documentSigned ? modifyField : () => {}}
                    />
                  )
                );
              } else if (field.fieldName.includes('signature')) {
                return (
                  (field.page === formDiplomas?.page || field.page === 0) && (
                    <FieldImage
                      key={index}
                      shapeProps={field}
                      isSelected={field.fieldName === selectedId}
                      onSelect={() => {
                        !documentSigned && selectShape(field.fieldName);
                      }}
                      isDraggable={!documentSigned && isDraggable}
                      handleOnDragStart={handleOnDragStart}
                      onChange={(newAttrs) => {
                        const rects = diplomaFields.slice();
                        rects[index] = newAttrs;
                        rects[index].transform &&
                          rects.push({
                            ...rects[index],
                            fieldType: 41,
                            fieldTypeConfig: 52,
                            fontSize: '18',
                            fieldName: 'deleteSign',
                            exampleText: 'X',
                            x: rects[index].x + 20,
                            y: rects[index].y - 20,
                            width: 23,
                            height: 32,
                            fontStyle: { bold: 1 },
                          });
                        rects[index].transform &&
                          rects.push({
                            ...rects[index],
                            fieldType: 41,
                            fieldTypeConfig: 52,
                            fontSize: '18',
                            fieldName: 'addSign',
                            exampleText: '+',
                            x: rects[index].x + 5,
                            y: rects[index].y - 20,
                            width: 23,
                            height: 32,
                            fontStyle: { bold: 1 },
                          });
                        setDiplomaFields(rects);
                      }}
                      layoutWidth={width}
                      layoutHeight={height}
                      diplomaFields={diplomaFields}
                      setDiplomaFields={setDiplomaFields}
                    />
                  )
                );
              } else {
                return (
                  (field.page === formDiplomas?.page || field.page === 0) && (
                    <FieldImage
                      key={index}
                      shapeProps={field}
                      isSelected={field.exampleText === selectedId}
                      onSelect={() => {
                        !documentSigned && selectShape(field.exampleText);
                      }}
                      isDraggable={!documentSigned && isDraggable}
                      handleOnDragStart={handleOnDragStart}
                      onChange={(newAttrs) => {
                        const rects = diplomaFields.slice();
                        rects[index] = newAttrs;
                        setDiplomaFields(rects);
                      }}
                      layoutWidth={width}
                      layoutHeight={height}
                    />
                  )
                );
              }
            })}
          </Layer>
        </Stage>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    marginTop: 32,
    backgroundImage: `url(${props.imageRoute})`,
    backgroundRepeat: 'no-repeat',
    width: 'fit-content',
    maxWidth: '68vw',
  }),
  mySwal: {
    zIndex: 3000,
  },
  pagination: {
    marginTop: '3px',
    position: 'absolute',
  },
}));

const mapStateToProps = (state) => {
  return {
    diplomaFields: state.diplomaFields || [],
    imageRoute: state.formDiplomas.img_prev && state.formDiplomas.img_prev[state.formDiplomas.page - 1],
    width: state.formDiplomas.width,
    height: state.formDiplomas.height,
    formDiplomas: state.formDiplomas,
  };
};

const mapDispatchToProps = {
  setDiplomaFields,
  setFormDiplomas,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
