import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


function NotFound() {

  const history = useHistory();

  useEffect(() => {
    history.push('/404')
    window.location.reload();
    // eslint-disable-next-line
  }, []);
  return (
    <></>
  );
}

export default (NotFound);