import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axiosFile from 'axios';
import moment from 'moment';
import { isValid } from 'date-fns';
import Swal from 'sweetalert2';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import {
  SettingsBackupRestoreOutlined,
  DeleteForeverOutlined,
  CachedOutlined,
  FileDownloadOutlined,
  ImageSearchOutlined,
  SystemUpdateAltOutlined,
  CloseOutlined,
} from '@mui/icons-material';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import axios from '../../../api';
import Table from '../../../components/Table';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { decrypt, encrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import exportToExcel from '../../../utils/exportToExcel';
import SkeletonTables from '../../../components/SkeletonTable';
import { env } from '../../../config/environment';
import { useTranslation } from 'react-i18next';

function CarnetsIssued(props) {
  const { page, rowsPerPage, changeBreadcrump, permission, user, organizationId, changePage, searchValue } = props;
  const [carnetsIssued, setCarnetsIssued] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [dialogPreview, setDialogPreview] = useState(false);
  const [image, setImage] = useState(null);
  const [countItems, setCountItems] = useState();

  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps']);

  const startColumns = t('tables:table.columns-carnet-issued.columns-start', { returnObjects: true });
  const finalColumns = t('tables:table.columns-carnet-issued.columns-end', { returnObjects: true });

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    if (!searchValue || searchValue === '') {
      await getCarnetsIssued();
    }
    changeBreadcrump(t('breadCrumps:breadCrumps.groups-carnet-issued-carnets-issued', { returnObjects: true }));
    setLoading(false);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getCarnetsIssued = async () => {
    const params = getParams(0, rowsPerPage);
    const id = decrypt(props.match.params.id);
    params.id_grupos_carnets_emitidos = id;

    const { data } = await axios.post(`/carnetsIssued/getCarnetsIssued`, params);

    if (data.issued.rows.length > 0) {
      const fields = [];
      // eslint-disable-next-line array-callback-return
      data.issued.rows[0].carnetsCamposValores
        .sort((a, b) => (a.carnetCampos?.nombre < b.carnetCampos?.nombre ? -1 : 1))
        // eslint-disable-next-line array-callback-return
        .map((field) => {
          if (
            field.carnetCampos?.tipo_campo_vp !== 48 &&
            field.carnetCampos?.nombre !== 'certificateCode' &&
            field.carnetCampos !== null
          ) {
            fields.push({
              id: `${field.carnetCampos?.nombre}`,
              label: `${field.carnetCampos?.nombre}`,
              align: 'center',
              orden: `${field.carnetCampos?.nombre}`,
            });
          }
          if (field.carnetCampos === null) {
            fields.push({
              id: `${field.carnetCamposAdicionales?.nombre_campo}`,
              label: `${field.carnetCamposAdicionales?.nombre_campo}`,
              align: 'center',
              orden: `${field.carnetCamposAdicionales?.nombre_campo}`,
            });
          }
        });

      const fieldsConcat = startColumns.concat(fields).concat(finalColumns);
      setColumns(fieldsConcat);

      setCarnetsIssued(data.issued?.rows);
      setFiltradas(data.issued?.rows);
      setCountItems(data?.issued?.count);
    }

    if (data.issued.rows.length === 0 || data.issued.rows[0].gruposInsigniasEmitidas.estado === 0) {
      setTimeout(reload, 15000);
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const id = decrypt(props.match.params.id);

    params.id_grupos_carnets_emitidos = id;

    const { data } = await axios.post(`/carnetsIssued/getCarnetsIssued`, params);

    const array = [
      ...carnetsIssued,
      ...data?.issued?.rows.filter((row) => !carnetsIssued.find((item) => item.id === row.id)),
    ];

    setCarnetsIssued(array);
    setFiltradas(array);
    setLoading(true);
  };

  const modalDelete = (e, id, action) => {
    Swal.fire({
      text: `${t('alerts:alerts.revoke-undo-1')} ${action} ${t('alerts:alerts.revoke-undo-2-carnet')}`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.send'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id, action);
      }
    });
  };

  const sendDelete = async (id, action) => {
    if (action === 'revocar') {
      try {
        const { value: text } = await Swal.fire({
          text: t('alerts:alerts.revoke-message'),
          input: 'textarea',
          inputPlaceholder: t('alerts:alerts.message-here'),
          inputAttributes: {
            'aria-label': t('alerts:alerts.message-here'),
          },
          showCancelButton: true,
          confirmButtonText: t('buttons:buttons.send'),
          cancelButtonText: t('buttons:buttons.cancel'),
          showLoaderOnConfirm: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
            cancelButton: 'custom-button-cancel',
          },
          inputValidator: (valor) => {
            if (!valor) {
              return t('alerts:alerts.write-message');
            }
          },
        });
        if (text) {
          setLoading(true);
          const { data } = await axios.delete(`/carnetsIssued/revoke/carnet/${id}`, {
            data: { mensaje: text },
          });
          if (data) {
            carnetsIssued.map((item) => item.id === id && getCarnetsIssued());
            setLoading(false);
            Swal.fire({
              text: t('alerts:alerts.revoke'),
              icon: 'success',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      try {
        setLoading(true);
        const { data } = await axios.delete(`/carnetsIssued/undo/carnet/${id}`);
        if (data) {
          carnetsIssued.map((item) => item.id === id && getCarnetsIssued());
          setLoading(false);
          Swal.fire({
            text: t('alerts:alerts.undo-revoke'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.undo-revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const delete_permission = (id) => {
    const carnet = carnetsIssued.find((data) => data.estado === 0 && data.id === id);
    if (permission.includes('Eliminar')) {
      if (!carnet) {
        return (
          <Tooltip title={t('tables:table.tooltip.revoke')}>
            <IconButton aria-label="delete" onClick={(e) => modalDelete(e, id, 'revocar')}>
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t('tables:table.tooltip.undo-revoke')}>
            <IconButton aria-label="delete">
              <SettingsBackupRestoreOutlined onClick={(e) => modalDelete(e, id, 'desrevocar')} />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };

  const reload = () => {
    setLoading(true);
    loadData();
  };

  const downloadCarnets = async () => {
    try {
      setLoading(true);
      await axiosFile({
        method: 'post',
        url: `${env.apiURL}/carnetsIssued/generateCarnetsByGroupId`,
        data: { groupId: carnetsIssued[0].id_grupos_carnets_emitidos },
      });
      setLoading(false);
      return Swal.fire({
        icon: 'success',
        iconColor: colors.primary,
        text: 'Carnets Generados Exitosamente',
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      }).then(async () => {
        setLoading(true);
        const { data } = await axiosFile({
          method: 'post',
          url: `${env.apiURL}/carnetsIssued/downloadCarnetsByGroupId`,
          data: { groupId: carnetsIssued[0].id_grupos_carnets_emitidos },
        });
        setLoading(false);
        const link = document.createElement('a');
        link.download = `${carnetsIssued[0].gruposInsigniasEmitidas.nombre}`;
        link.href = data;
        link.click();
      });
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }
  };

  const handleExportToExcel = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/carnetsIssued/getCarnetsIssued`, {
        id_grupos_carnets_emitidos: decrypt(props.match.params.id),
      });

      const rows = data?.issued?.rows?.map((data) => {
        const camposValores = data?.carnetsCamposValores
          .sort((a, b) => (a.carnetCampos?.nombre < b.carnetCampos?.nombre ? -1 : 1))
          .filter((item) => item.carnetCampos?.tipo_campo_vp !== 48 && item.carnetCampos?.nombre !== 'certificateCode')
          .map((value) => {
            if (value?.carnetCampos?.nombre) {
              return { [value?.carnetCampos?.nombre]: value?.valor };
            } else {
              return { [value?.carnetCamposAdicionales?.nombre_campo]: value?.valor };
            }
          });

        let camposValoresJson = {};
        camposValores.map((item) => (camposValoresJson = { ...camposValoresJson, ...item }));

        return {
          Grupo: data.gruposInsigniasEmitidas?.nombre,
          Estado:
            data.estado === 1
              ? t('tables:table.state-reports-carnets.issued')
              : data.estado === 2
              ? t('tables:table.state-reports-carnets.viewed')
              : data.estado === 3
              ? t('tables:table.state-reports-carnets.report')
              : data.estado === 4
              ? t('tables:table.state-reports-carnets.bounced-mail')
              : t('tables:table.state-reports-carnets.revoked'),
          'Fecha emisión': moment(data.fecha_emision).format('DD/MM/YYYY HH:mm:ss'),
          Email: data.email_receptor,
          ...camposValoresJson,
          'Código carnet': data.codigo_carnet,
          'Nombre público carnet': data.plantillasCarnets?.nombre,
          'Nombre privado carnet': data.plantillasCarnets?.nombre_privado,
          'Fecha expiración':
            data.fecha_expiracion !== null
              ? isValid(new Date(data.fecha_expiracion))
                ? moment(data.fecha_expiracion).format('DD/MM/YYYY')
                : ''
              : '',
          Cuenta: isEsxistAccount(data.account),
          'No. correos emisión': data.correos_emision || 1,
          'No. correos aceptar carnet': data.correos_aceptar_carnet || 0,
          'No. correos crear cuenta': data.correos_crear_cuenta || 0,
          'No. correos compartir carnet': data.correos_compartir_carnet || 0,
          'No. correos revocar': data.correos_revocar || 0,
          'No. veces compartidas en Facebook': validationShare(data.estadisticasRedesSociales, 4),
          'No. veces compartidas en LinkedIn': validationShare(data.estadisticasRedesSociales, 3),
          'No. veces compartidas en Twitter': validationShare(data.estadisticasRedesSociales, 5),
          'No. veces compartidas en WhatsApp': validationShare(data.estadisticasRedesSociales, 2),
          'No. veces compartidas en Telegram': validationShare(data.estadisticasRedesSociales, 6),
        };
      });

      exportToExcel(rows, data?.issued?.rows[0]?.gruposInsigniasEmitidas?.nombre);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const isEsxistAccount = (accountBoolean) => {
    // eslint-disable-next-line array-callback-return
    if (accountBoolean) {
      return 'Si';
    } else {
      return 'No';
    }
  };

  const validationShare = (estadisticaRedesSociales, redSocialId) => {
    // eslint-disable-next-line array-callback-return
    const carnetShare = estadisticaRedesSociales.filter((item) => item.id_redes_sociales === redSocialId);
    if (carnetShare.length > 0) {
      return carnetShare.length;
    } else {
      return 0;
    }
  };

  const handlePreview = (linkPhoto) => {
    setImage(linkPhoto);
    setDialogPreview(true);
  };

  const handleClose = () => {
    setDialogPreview(false);
  };

  return (
    <>
      <ModuleHeader>
        {carnetsIssued.length > 0 && (
          <>
            <Tooltip title="Descargar carnets">
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={downloadCarnets}
              >
                <SystemUpdateAltOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={t('tables:table.tooltip.export')}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleExportToExcel}
              >
                <FileDownloadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={t('tables:table.tooltip.upgrade')}>
              <Button disableElevation color="primary" variant="contained" className={classes.button} onClick={reload}>
                <CachedOutlined />
              </Button>
            </Tooltip>
          </>
        )}
      </ModuleHeader>
      <Search
        tableName="CarnetIssued"
        items={carnetsIssued}
        setItems={setFiltradas}
        groupId={decrypt(props.match.params.id)}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        columns={{ startColumns, finalColumns, setColumns }}
      />
      <Table
        columns={columns}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-carnet')}>
                        <IconButton onClick={() => handlePreview(row.linkPhoto)}>
                          <ImageSearchOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.gruposInsigniasEmitidas.nombre}</TableCell>
                    <TableCell align="center">
                      <span className={row.estado === 4 && classes.row}>
                        {row.estado === 1
                          ? t('tables:table.state-reports-carnets.issued')
                          : row.estado === 2
                          ? t('tables:table.state-reports-carnets.viewed')
                          : row.estado === 3
                          ? t('tables:table.state-reports-carnets.report')
                          : row.estado === 4
                          ? t('tables:table.state-reports-carnets.bounced-mail')
                          : t('tables:table.state-reports-carnets.revoked')}
                      </span>
                    </TableCell>
                    <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">{row.email_receptor}</TableCell>
                    {row.carnetsCamposValores
                      ?.sort((a, b) => (a.carnetCampos?.nombre < b.carnetCampos?.nombre ? -1 : 1))
                      .map(
                        (value) =>
                          value.carnetCampos?.tipo_campo_vp !== 48 &&
                          value.carnetCampos?.nombre !== 'certificateCode' && (
                            <TableCell align="center">{value.valor}</TableCell>
                          )
                      )}
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-carnet')}>
                        {(row.estado === 2 || row.estado === 0) && isEsxistAccount(row.account) === 'Si' ? (
                          <a href={`${window.location.origin}/carnet/${encrypt(row.id)}`} target="blank">
                            {row.codigo_carnet}
                          </a>
                        ) : (
                          <a href={`${window.location.origin}/carnet/verify/${encrypt(row.id)}`} target="blank">
                            {row.codigo_carnet}
                          </a>
                        )}
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.plantillasCarnets?.nombre}</TableCell>
                    <TableCell align="center">{row.plantillasCarnets?.nombre_privado}</TableCell>
                    <TableCell align="center">
                      {row.fecha_expiracion !== null &&
                        isValid(new Date(row.fecha_expiracion)) &&
                        moment(row.fecha_expiracion).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">{isEsxistAccount(row.account)}</TableCell>
                    <TableCell align="center">{row.correos_emision || 1}</TableCell>
                    <TableCell align="center">{row.correos_aceptar_carnet || 0}</TableCell>
                    <TableCell align="center">{row.correos_crear_cuenta || 0}</TableCell>
                    <TableCell align="center">{row.correos_compartir_carnet || 0}</TableCell>
                    <TableCell align="center">{row.correos_revocar || 0}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 4)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 3)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 5)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 2)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 6)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="30">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={dialogPreview} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonDialog}`}
            onClick={handleClose}
          >
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img alt="img-prev" src={image} className={classes.img} />
        </DialogContent>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    margin: '5px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  row: {
    color: '#dc2626',
    fontWeight: 'bold',
    padding: '1rem',
  },
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'carnets emitidos')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarnetsIssued);
