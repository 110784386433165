import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, TableBody, TableCell, TableRow, Tooltip, IconButton, Avatar, Button } from '@material-ui/core';
import {
  SettingsBackupRestoreOutlined,
  DeleteForeverOutlined,
  CachedOutlined,
  SystemUpdateAltOutlined,
  FileDownloadOutlined,
} from '@mui/icons-material';
import { changeBreadcrump, sortData, getComparator, changePage } from '../../../actions';
import { isValid } from 'date-fns';
import { decrypt, encrypt } from '../../../utils/crypt';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import axios from '../../../api';
import Table from '../../../components/Table';
import moment from 'moment';
import Swal from 'sweetalert2';
import colors from '../../../assets/styles/colors';
import exportToExcel from '../../../utils/exportToExcel';
import SkeletonTables from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';
import { env } from '../../../config/environment';
import axiosFile from 'axios';

const useStyles = makeStyles((theme) => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    margin: '5px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  row: {
    color: '#dc2626',
    fontWeight: 'bold',
  },
}));
function BadgesIssued(props) {
  const { page, rowsPerPage, changeBreadcrump, permission, user, organizationId, changePage, searchValue } = props;
  const [badgesIssued, setBadgesIssued] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [countItems, setCountItems] = useState();
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    if (!searchValue || searchValue === '') {
      await getBadgesIssued();
    }
    changeBreadcrump(t('breadCrumps:breadCrumps.groups-badge-issued-badges-issued', { returnObjects: true }));
    setLoading(false);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getBadgesIssued = async () => {
    try {
      const params = getParams(0, rowsPerPage);
      const id = decrypt(props.match.params.id);
      params.id_grupos_insignias_emitidas = id;
      const { data } = await axios.post(`/badgesIssued/getBadgesIssued`, params);
      setBadgesIssued(data.issued?.rows);
      setFiltradas(data.issued?.rows);
      setCountItems(data.issued?.count);
    } catch (error) {
      history.push('/500');
      window.location.reload();
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const id = decrypt(props.match.params.id);

    params.id_grupos_insignias_emitidas = id;

    const { data } = await axios.post(`/badgesIssued/getBadgesIssued`, params);

    const array = [
      ...badgesIssued,
      ...data.issued?.rows.filter((row) => !badgesIssued.find((item) => item.id === row.id)),
    ];

    setBadgesIssued(array);
    setFiltradas(array);
    setLoading(false);
  };

  const modalDelete = (e, id, action) => {
    Swal.fire({
      text: `${t('alerts:alerts.revoke-undo-1')} ${action} ${t('alerts:alerts.revoke-undo-2')}`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id, action);
      }
    });
  };

  const sendDelete = async (id, action) => {
    if (action === 'revocar') {
      try {
        const { value: text } = await Swal.fire({
          text: t('alerts:alerts.revoke-message'),
          input: 'textarea',
          inputPlaceholder: t('alerts:alerts.message-here'),
          inputAttributes: {
            'aria-label': t('alerts:alerts.message-here'),
          },
          showCancelButton: true,
          confirmButtonText: t('buttons:buttons.send'),
          cancelButtonText: t('buttons:buttons.cancel'),
          showLoaderOnConfirm: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
            cancelButton: 'custom-button-cancel',
          },
          inputValidator: (valor) => {
            if (!valor) {
              return t('alerts:alerts.write-message');
            }
          },
        });
        if (text) {
          setLoading(true);
          const { data } = await axios.delete(`/badgesIssued/revoke/badge/${id}`, { data: { mensaje: text } });
          if (data) {
            badgesIssued.map((item) => item.id === id && getBadgesIssued());
            setLoading(false);
            Swal.fire({
              text: t('alerts:alerts.revoke'),
              icon: 'success',
              iconColor: colors.primary,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      try {
        setLoading(true);
        const { data } = await axios.delete(`/badgesIssued/undo/badge/${id}`);
        if (data) {
          badgesIssued.map((item) => item.id === id && getBadgesIssued());
          setLoading(false);
          Swal.fire({
            text: t('alerts:alerts.undo-revoke'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          text: t('alerts:alerts.undo-revoke-error'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const delete_permission = (id) => {
    const badge = badgesIssued.find((data) => data.estado === 0 && data.id === id);
    if (permission.includes('Eliminar')) {
      if (!badge) {
        return (
          <Tooltip title={t('tables:table.tooltip.revoke')}>
            <IconButton aria-label="delete" onClick={(e) => modalDelete(e, id, 'revocar')}>
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t('tables:table.tooltip.undo-revoke')}>
            <IconButton aria-label="delete">
              <SettingsBackupRestoreOutlined onClick={(e) => modalDelete(e, id, 'desrevocar')} />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };

  const reload = () => {
    setLoading(true);
    loadData();
  };

  const handleExportToExcel = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/badgesIssued/getBadgesIssued`, {
        id_grupos_insignias_emitidas: decrypt(props.match.params.id),
      });

      const rows = data?.issued?.rows?.map((data) => {
        return {
          Grupo: data.gruposInsigniasEmitidas?.nombre,
          Estado:
            data.estado === 1
              ? t('tables:table.state-issue.issued')
              : data.estado === 2
              ? t('tables:table.state-issue.accepted')
              : data.estado === 3
              ? t('tables:table.state-issue.rejected')
              : data.estado === 4
              ? t('tables:table.state-issue.bounced-mail')
              : t('tables:table.state-issue.revoked'),
          'Fecha emisión': moment(data.fecha_emision).format('DD/MM/YYYY HH:mm:ss'),
          Nombres: `${data.primer_nombre_receptor} ${data.segundo_nombre_receptor || ''}`,
          Apellidos: `${data.primer_apellido_receptor} ${data.segundo_apellido_receptor || ''}`,
          Email: data.email_receptor,
          'Tipo de identificación': data.tipo_identificacion,
          Identificación: data.identificacion,
          'Código insignia': data.codigo_insignia,
          'Nombre público insignia': data.plantillasInsignias?.nombre,
          'Nombre privado insignia': data.plantillasInsignias?.nombre_privado,
          'Fecha expiración':
            data.fecha_expiracion !== null
              ? isValid(new Date(data.fecha_expiracion))
                ? moment(data.fecha_expiracion).format('DD/MM/YYYY')
                : ''
              : '',
          Celular: data.numero_celular,
          WhatsApp: data.numero_whatsapp,
          Telegram: data.numero_telegram,
          Merito: data.merito,
          'Link merito': data.link_merito,
          Cuenta: isEsxistAccount(data.account),
          'No. correos emisión': data.correos_emision || 1,
          'No. correos aceptar insignia': data.correos_aceptar_insignia || 0,
          'No. correos crear cuenta': data.correos_crear_cuenta || 0,
          'No. correos compartir insignia': data.correos_compartir_insignia || 0,
          'No. correos revocar': data.correos_revocar || 0,
          'No. veces compartidas en Facebook': validationShare(data.estadisticasRedesSociales, 4),
          'No. veces compartidas en LinkedIn': validationShare(data.estadisticasRedesSociales, 3),
          'No. veces compartidas en Twitter': validationShare(data.estadisticasRedesSociales, 5),
          'No. veces compartidas en WhatsApp': validationShare(data.estadisticasRedesSociales, 2),
          'No. veces compartidas en Telegram': validationShare(data.estadisticasRedesSociales, 6),
        };
      });

      exportToExcel(rows, data?.issued?.rows[0]?.gruposInsigniasEmitidas?.nombre);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const isEsxistAccount = (accountBoolean) => {
    if (accountBoolean) {
      return 'Si';
    } else {
      return 'No';
    }
  };

  const validationShare = (estadisticaRedesSociales, redSocialId) => {
    // eslint-disable-next-line array-callback-return
    const badgeShare = estadisticaRedesSociales.filter((item) => item.id_redes_sociales === redSocialId);
    if (badgeShare.length > 0) {
      return badgeShare.length;
    } else {
      return 0;
    }
  };

  const columns = () => {
    return t('tables:table.columns-badges-issued', { returnObjects: true });
  };

  const downloadCodes = async () => {
    setLoading(true);

    const { data } = await axiosFile({
      method: 'post',
      url: `${env.apiURL}/badgesIssued/downloadCodesByGroupId`,
      data: { groupId: badgesIssued[0].id_grupos_insignias_emitidas },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${badgesIssued[0].gruposInsigniasEmitidas.nombre}.zip`);
    document.body.appendChild(link);
    link.click();

    setLoading(false);
  };

  return (
    <>
      <ModuleHeader>
        {badgesIssued.length > 0 && (
          <>
            <Button disableElevation variant="contained" className={classes.button} onClick={downloadCodes}>
              <Tooltip title={t('tables:table.tooltip.download-code')}>
                <SystemUpdateAltOutlined />
              </Tooltip>
            </Button>
            <Button disableElevation variant="contained" className={classes.button} onClick={handleExportToExcel}>
              <Tooltip title={t('tables:table.tooltip.export')}>
                <FileDownloadOutlined />
              </Tooltip>
            </Button>
            <Tooltip title={t('tables:table.tooltip.upgrade')}>
              <Button disableElevation variant="contained" className={classes.button} onClick={reload}>
                <CachedOutlined />
              </Button>
            </Tooltip>
          </>
        )}
      </ModuleHeader>
      <Search
        tableName="BadgesIssued"
        items={badgesIssued}
        setItems={setFiltradas}
        groupId={decrypt(props.match.params.id)}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
      />
      <Table
        columns={columns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{row.gruposInsigniasEmitidas.nombre}</TableCell>
                    <TableCell align="center">
                      <span className={row.estado === 4 && classes.row}>
                        {row.estado === 1
                          ? t('tables:table.state-issue.pending')
                          : row.estado === 2
                          ? t('tables:table.state-issue.accepted')
                          : row.estado === 3
                          ? t('tables:table.state-issue.rejected')
                          : row.estado === 4
                          ? t('tables:table.state-issue.bounced-mail')
                          : t('tables:table.state-issue.revoked')}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <Avatar
                        aria-label="img_badge"
                        src={row.plantillasInsignias?.linkPhoto}
                        className={classes.large}
                      />
                    </TableCell>
                    <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">{`${row.primer_nombre_receptor} ${
                      row.segundo_nombre_receptor || ''
                    }`}</TableCell>
                    <TableCell align="center">{`${row.primer_apellido_receptor} ${
                      row.segundo_apellido_receptor || ''
                    }`}</TableCell>
                    <TableCell align="center">{row.email_receptor}</TableCell>
                    <TableCell align="center">{row.tipo_identificacion}</TableCell>
                    <TableCell align="center">{row.identificacion}</TableCell>
                    <TableCell align="center">
                      {(row.estado === 2 || row.estado === 0) && isEsxistAccount(row.account) === 'Si' ? (
                        <Tooltip title={t('tables:table.tooltip.view-badge')}>
                          <a href={`${window.location.origin}/badges/${encrypt(row.id)}`} target="blank">
                            {row.codigo_insignia}
                          </a>
                        </Tooltip>
                      ) : (
                        row.codigo_insignia
                      )}
                    </TableCell>
                    <TableCell align="center">{row.plantillasInsignias?.nombre}</TableCell>
                    <TableCell align="center">{row.plantillasInsignias?.nombre_privado}</TableCell>
                    <TableCell align="center">
                      {row.fecha_expiracion !== null &&
                        isValid(new Date(row.fecha_expiracion)) &&
                        moment(row.fecha_expiracion).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">{row.numero_celular}</TableCell>
                    <TableCell align="center">{row.numero_whatsapp}</TableCell>
                    <TableCell align="center">{row.numero_telegram}</TableCell>
                    <TableCell align="justify">{row.merito}</TableCell>
                    <TableCell align="center">
                      <a href={row.link_merito} target="blank">
                        {row.link_merito}
                      </a>
                    </TableCell>
                    <TableCell align="center">{isEsxistAccount(row.account)}</TableCell>
                    <TableCell align="center">{row.correos_emision || 1}</TableCell>
                    <TableCell align="center">{row.correos_aceptar_insignia || 0}</TableCell>
                    <TableCell align="center">{row.correos_crear_cuenta || 0}</TableCell>
                    <TableCell align="center">{row.correos_compartir_insignia || 0}</TableCell>
                    <TableCell align="center">{row.correos_revocar || 0}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 4)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 3)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 5)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 2)}</TableCell>
                    <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 6)}</TableCell>
                    <TableCell align="center">{delete_permission(row.id)}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="22">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'insignias emitidas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgesIssued);
