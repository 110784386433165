import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  TextField,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Checkbox,
  Paper,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import checkPassword from '../utils/checkPassword';
import axios from '../api';
import { login } from '../actions';
import { env } from '../config/environment';
import TermsAndConditions from '../components/TermsAndConditions';
import { useTranslation } from 'react-i18next';

function NewOrganization(props) {
  const classes = useStyles({ env });
  const [form, setForm] = useState({
    firstName: '',
    secondName: '',
    lastName: '',
    secondSurname: '',
    email: '',
    password: '',
    organizationName: '',
    organizationPhone: '',
    countIssues: '',
    userRole: '',
    productName: '',
  });
  const [loading, setLoading] = useState(false);
  const singupButton = useRef();
  const [validate, setValidate] = useState({ password: { error: false } });
  const [checked, setChecked] = useState([]);
  const [dataDialog, setDataDialog] = useState({ open: false, title: '', url: '', viewTerms: false, viewPPT: false });
  const [loginStyles, setLoginStyles] = useState({});
  const { user } = props;
  const [t] = useTranslation(['loginRegister', 'buttons', 'alerts']);

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLoginStyles({
      Logo: styles.logo435x149,
      LoginLogo: styles.icono_login,
      LoginBackground: styles.login_background,
      RegisterOrganization: styles.organizationRegister,
    });

    if (window.location.origin === env.certikaURL) {
      setForm({
        ...form,
        backoficceId: 1,
      });
    } else if (window.location.origin === env.mincienciasURL) {
      setForm({
        ...form,
        backoficceId: 2,
      });
    }

    if (user) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (event) => {
    if (event.target.name === 'password') {
      if (event.target.value === '' || checkPassword(event.target.value)) {
        singupButton.current.disabled = false;
        setValidate({ ...validate, password: { error: false } });
        Swal.close();
      } else {
        singupButton.current.disabled = true;
        setValidate({
          ...validate,
          password: {
            error: true,
          },
        });
        !validate.password.error &&
          Swal.mixin({
            toast: true,
            position: 'bottom-start',
          }).fire({
            icon: 'error',
            title: t('alerts:alerts.password-lenght'),
          });
      }
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.password !== form.passwordConfirmation) {
      return Swal.fire({
        icon: 'info',
        text: t('alerts:alerts.password-match'),
        showConfirmButton: false,
        timer: 3000,
      });
    }

    setLoading(true);
    axios({
      url: `/user/sign-up`,
      method: 'post',
      data: {
        primer_nombre: form.firstName,
        primer_apellido: form.lastName,
        correo: form.email,
        password: form.password,
        backoficceId: form.backoficceId,
        organizationData: {
          nombre: form.organizationName,
          telefono: form.organizationPhone,
          correo: form.email,
          newOrganization: true,
          nameClient: `${form.firstName} ${form.lastName}`,
          countIssues: form.countIssues,
          userRole: form.userRole,
          isNewOrganizaton: true,
          productName: form.productName,
        },
      },
    })
      .then((res) => {
        setLoading(false);
        const { data } = res;
        if (data) {
          Swal.fire({
            icon: 'success',
            text: t('alerts:alerts.email-confirm-account'),
            showConfirmButton: false,
            timer: 7000,
          }).then(() => {
            props.history.push('/sign-in');
          });
        }
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          text: t('alerts:alerts.account-exist'),
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const openTerms = (index, title, url) => {
    setDataDialog({ ...dataDialog, open: true, title, url, index });
  };

  const handleClose = () => {
    setDataDialog({ ...dataDialog, open: false, title: '', url: '' });
  };

  const handleAccept = (index) => {
    const currentIndex = checked.includes(index);
    const newChecked = [...checked];
    if (!currentIndex) {
      newChecked.push(index);
      setChecked(newChecked);
    } else {
      setChecked(newChecked.filter((item) => item !== index));
    }
    setDataDialog({
      open: false,
      title: '',
      url: '',
      viewTerms: index === 1 ? true : dataDialog.viewTerms,
      viewPPT: index === 2 ? true : dataDialog.viewPPT,
    });
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage:
          window.location.origin === env.mincienciasURL ? 'none' : `url(${loginStyles?.LoginBackground})`,
      }}
    >
      <Paper elevation={0} className={classes.container_card}>
        <div className={classes.container__login}>
          <div className={classes.container__logo}>
            <img src={loginStyles?.Logo} alt="logo" style={{ width: '70%' }} />
          </div>

          <h3 style={{ marginBottom: '.5em' }}>{t('buttons:buttons.register')}</h3>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:first-name')}
                    name="firstName"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:last-name')}
                    name="lastName"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.lastName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:email')}
                    name="email"
                    type="email"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label={t('loginRegister:password')}
                    name="password"
                    variant="outlined"
                    onChange={handleInput}
                    error={validate.password.error}
                    type={'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  {validate.password.error && (
                    <span className={classes.text_password_error}>{validate.password.message}</span>
                  )}
                  <TextField
                    required
                    label={t('loginRegister:confirm-password')}
                    name="passwordConfirmation"
                    variant="outlined"
                    onChange={handleInput}
                    type={'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Organización"
                    name="organizationName"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.organizationName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    type="number"
                    label="Teléfono"
                    name="organizationPhone"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.organizationPhone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Cargo"
                    name="userRole"
                    variant="outlined"
                    onChange={handleInput}
                    value={form.userRole}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel id="count-issues">Producto de interés*</InputLabel>
                  <Select
                    required
                    labelId="count-issues"
                    label="Producto de interes"
                    name="productName"
                    onChange={handleInput}
                    value={form.productName}
                  >
                    <MenuItem value="">
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value="Insignias Digitales">Insignias Digitales</MenuItem>
                    <MenuItem value="Certificados Digitales">Certificados Digitales</MenuItem>
                    <MenuItem value="Documentos Digitales">Documentos Digitales</MenuItem>
                    <MenuItem value="Carnets Digitales">Carnets Digitales</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel id="count-issues">Cantidad emisiones*</InputLabel>
                  <Select
                    required
                    labelId="count-issues"
                    label="Cantidad emisiones"
                    name="countIssues"
                    onChange={handleInput}
                    value={form.countIssues}
                  >
                    <MenuItem value="">
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value="10 - 499">10 - 499</MenuItem>
                    <MenuItem value="500 - 999">500 - 999</MenuItem>
                    <MenuItem value="1000 - 1999">1000 - 1999</MenuItem>
                    <MenuItem value="+2000">+2000</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {window.location.origin !== env.mincienciasURL && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.checked}
                    control={<Checkbox checked={checked.includes(1)} name="checkedE" onClick={() => handleAccept(1)} />}
                  />
                  <span
                    className={classes.checkedSpam}
                    onClick={() =>
                      openTerms(
                        1,
                        'TÉRMINOS Y CONDICIONES DE USO DE SOFTWARE CERTIKA S.A.S.',
                        'https://certika.co/wp-content/uploads/2022/08/Terminos-y-Condiciones-de-Uso-de-Softwre-y-ANS-1.pdf',
                      )
                    }
                  >
                    {t('loginRegister:accept-terms')}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.checked}
                    control={<Checkbox checked={checked.includes(2)} name="checkedE" onClick={() => handleAccept(2)} />}
                  />
                  <span
                    className={classes.checkedSpam}
                    onClick={() =>
                      openTerms(
                        2,
                        'POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES CERTIKA S.A.S',
                        'https://certika.co/wp-content/uploads/2022/07/Politica-de-Tratamiento-de-Datos-Personales-Responsable_Certika-1.pdf',
                      )
                    }
                  >
                    {t('loginRegister:accept-privacy')}
                  </span>
                </Grid>
              </>
            )}
            <div className={`text-center`}>
              <Button
                disabled={
                  window.location.origin !== env.mincienciasURL && !(checked.includes(1) && checked.includes(2))
                }
                ref={singupButton}
                variant="contained"
                size="small"
                className={classes.button}
                type="submit"
                color="primary"
                disableElevation
              >
                {t('buttons:buttons.register')}
              </Button>
              <br />
            </div>
            <div className={classes.classes__login_buttons_paragraph}>
              <p
                onClick={() => {
                  props.history.push('/sign-in');
                }}
              >
                {`${t('loginRegister:have-account')}, `}
                <span className={classes.signin_span}>{t('loginRegister:sign-in')}</span>
              </p>
            </div>
          </form>
        </div>
      </Paper>
      <div className={classes.container__image}>
        <img
          src={window.location.origin === env.certikaURL ? loginStyles?.RegisterOrganization : loginStyles?.LoginLogo}
          className={classes.img}
          alt="login-logo"
        />
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TermsAndConditions data={dataDialog} onClose={handleClose} handleAccept={handleAccept} checked={checked} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      marginLeft: window.location.origin === props.env.mincienciasURL ? '20px' : 0,
      height: '100vh',
      justifyContent: 'space-around',
    },
  }),
  container_card: {
    padding: '20px',
    borderRadius: '15px',
    margin: '5px 10px',
    [theme.breakpoints.up('lg')]: {
      padding: '5px 40px',
      margin: '0 10px',
      width: '600px',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '100px 0 10px',
    },
  },
  container__login: {
    '& h2': {
      fontWeight: 700,
    },
    '& img': {
      width: '85%',
    },
  },
  container__logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: (props) => ({
    marginTop: window.location.origin === props.env.mincienciasURL ? '1em' : 0,
    margin: '0.5em',
    width: '100%',
    padding: '9px 40px',
  }),
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  signin_span: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  text_password_error: {
    marginBottom: '5px',
    color: '#f4443b',
    fontSize: '0.9em',
    maxWidth: 'fit-content',
    textAlign: 'justify',
  },
  container__image: (props) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: window.location.origin === props.env.mincienciasURL ? '80vw' : '50vw',
    },
  }),
  img: {
    //width: '100%',
    height: '100%',
  },
  checked: {
    margin: 0,
  },
  checkedSpam: {
    cursor: 'pointer',
    fontSize: 13,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  classes__login_buttons_paragraph: {
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      marginTop: '.5em',
      marginBottom: '.5em',
      textAlign: 'center',
      cursor: 'pointer',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrganization);
