import i18next from 'i18next';
import alerts_es from './translations/es/alerts.json';
import alerts_en from './translations/en/alerts.json';
import tables_es from './translations/es/tables.json';
import tables_en from './translations/en/tables.json';
import buttons_es from './translations/es/buttons.json';
import buttons_en from './translations/en/buttons.json';
import appBar_es from './translations/es/appBar.json';
import appBar_en from './translations/en/appBar.json';
import certificateBadgeTemplate_es from './translations/es/certificateBadgeTemplate.json';
import certificateBadgeTemplate_en from './translations/en/certificateBadgeTemplate.json';
import dialogs_es from './translations/es/dialogs.json';
import dialogs_en from './translations/en/dialogs.json';
import selects_es from './translations/es/selects.json';
import selects_en from './translations/en/selects.json';
import tabs_es from './translations/es/tabs.json';
import tabs_en from './translations/en/tabs.json';
import breadCrumps_en from './translations/en/breadCrump.json';
import breadCrumps_es from './translations/es/breadCrump.json';
import certificateBadgeCollection_es from './translations/es/certificateBadgeCollection.json';
import certificateBadgeCollection_en from './translations/en/certificateBadgeCollection.json';
import certificateBadgeRecommendation_es from './translations/es/certificateRecommendation.json';
import certificateBadgeRecommendation_en from './translations/en/certificateBadgeRecommendation.json';
import menu_es from './translations/es/menu.json';
import menu_en from './translations/en/menu.json';
import certificateBadgeIssued_es from './translations/es/certificateBadgeIssued.json';
import certificateBadgeIssued_en from './translations/en/certificateBadgeIssued.json';
import search_es from './translations/es/search.json';
import search_en from './translations/en/search.json';
import contracts_es from './translations/es/contracts.json';
import contracts_en from './translations/en/contracts.json';
import analytics_es from './translations/es/analytics.json';
import analytics_en from './translations/en/analytics.json';
import dashboard_es from './translations/es/dashboard.json';
import dashboard_en from './translations/en/dashboard.json';
import error404_es from './translations/es/error404.json';
import error404_en from './translations/en/error404.json';
import certifyDocuments_es from './translations/es/certifyDocuments.json';
import certifyDocuments_en from './translations/en/certifyDocuments.json';
import verify_en from './translations/en/verify.json';
import verify_es from './translations/es/verify.json';
import loginRegister_en from './translations/en/loginRegister.json';
import loginRegister_es from './translations/es/loginRegister.json';
import payments_en from './translations/en/payments.json';
import payments_es from './translations/es/payments.json';
import users_en from './translations/en/users.json';
import users_es from './translations/es/users.json';
import parameterValues_en from './translations/en/parameterValues.json';
import parameterValues_es from './translations/es/parameterValues.json';
import forms_en from './translations/en/forms.json';
import forms_es from './translations/es/forms.json';
import dialogOtp_es from './translations/es/dialogOtp.json';
import dialogOtp_en from './translations/en/dialogOtp.json';

const resources = {
  es: {
    alerts: alerts_es,
    tables: tables_es,
    buttons: buttons_es,
    appBar: appBar_es,
    certificateBadgeTemplate: certificateBadgeTemplate_es,
    dialogs: dialogs_es,
    selects: selects_es,
    tabs: tabs_es,
    breadCrumps: breadCrumps_es,
    certificateBadgeCollection: certificateBadgeCollection_es,
    certificateBadgeRecommendation: certificateBadgeRecommendation_es,
    menu: menu_es,
    certificateBadgeIssued: certificateBadgeIssued_es,
    search: search_es,
    contracts: contracts_es,
    analytics: analytics_es,
    dashboard: dashboard_es,
    error404: error404_es,
    certifyDocuments: certifyDocuments_es,
    verify: verify_es,
    loginRegister: loginRegister_es,
    payments: payments_es,
    users: users_es,
    parameterValues: parameterValues_es,
    forms: forms_es,
    dialogOtp: dialogOtp_es,
  },
  en: {
    alerts: alerts_en,
    tables: tables_en,
    buttons: buttons_en,
    appBar: appBar_en,
    certificateBadgeTemplate: certificateBadgeTemplate_en,
    dialogs: dialogs_en,
    selects: selects_en,
    tabs: tabs_en,
    breadCrumps: breadCrumps_en,
    certificateBadgeCollection: certificateBadgeCollection_en,
    certificateBadgeRecommendation: certificateBadgeRecommendation_en,
    menu: menu_en,
    certificateBadgeIssued: certificateBadgeIssued_en,
    search: search_en,
    contracts: contracts_en,
    analytics: analytics_en,
    dashboard: dashboard_en,
    error404: error404_en,
    certifyDocuments: certifyDocuments_en,
    verify: verify_en,
    loginRegister: loginRegister_en,
    payments: payments_en,
    users: users_en,
    parameterValues: parameterValues_en,
    forms: forms_en,
    dialogOtp: dialogOtp_en,
  },
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) || 'es',
  resources,
});

export default i18next;
