import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from '@material-ui/core';
import { CloseOutlined, ImageSearchOutlined } from '@mui/icons-material';
import colors from '../../assets/styles/colors';
import { changeBreadcrump, sortData, getComparator, setLoading } from '../../actions';
import Table from '../../components/Table';
import SkeletonTables from '../../components/SkeletonTable';
import axios from '../../api';
import moment from 'moment';
import { isValid } from 'date-fns';
import { encrypt } from '../../utils/crypt';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '.5em',
    marginBottom: '.5em',
  },
  export: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: '#28a745',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#28a745',
    },
  },
  cards: {
    borderColor: colors.secondary,
    borderRadius: '5%',
  },
  check1: {
    color: '#ffc107',
  },
  check2: {
    color: '#28a745',
  },
  check3: {
    color: '#dc3545',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  namePrivate: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
  },
  row: {
    color: '#dc2626',
    fontWeight: 'bold',
  },
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

function Listing(props) {
  const { page, rowsPerPage, changeBreadcrump, filtro, dataToExportExcel, setFiltradas, setDataExcel, tipo, where, countItems } = props;
  const [t] = useTranslation(['tables', 'breadCrumps']);
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [dialogPreview, setDialogPreview] = useState(false);
  const [image, setImage] = useState(null);
  const startColumnsCertificate = t('tables:table.columns-certificate-issued.columns-start', { returnObjects: true });
  const finalColumnsCertificate = t('tables:table.columns-certificate-issued.columns-end', { returnObjects: true });
  const startColumnsCarnet = t('tables:table.columns-carnet-issued.columns-start', { returnObjects: true });
  const finalColumnsCarnet = t('tables:table.columns-carnet-issued.columns-end', { returnObjects: true });
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    changeBreadcrump(t('breadCrumps:breadCrumps.analytics-list', { returnObjects: true }));
    // eslint-disable-next-line
  }, []);

  const loadFields = () => {
    const fields = [];
    if (tipo === 1) {
      // eslint-disable-next-line array-callback-return
      filtro[0]?.certificadosCamposValores?.map((field) => {
        if (field.certificadoCampos?.tipo_campo_vp !== 48 && field.certificadoCampos?.nombre !== 'certificateCode') {
          fields.push({
            id: `${field?.certificadoCampos?.nombre}`,
            label: `${field?.certificadoCampos?.nombre}`,
            align: 'center',
            orden: `${field?.certificadoCampos?.nombre}`,
          });
        }
      });
      const fieldsConcat = startColumnsCertificate.concat(fields).concat(finalColumnsCertificate);
      return fieldsConcat;
    } else {
      // eslint-disable-next-line array-callback-return
      filtro[0]?.carnetsCamposValores?.map((field) => {
        if (field.carnetCampos?.tipo_campo_vp !== 48 && field.carnetCampos?.nombre !== 'certificateCode') {
          fields.push({
            id: `${field?.carnetCampos?.nombre}`,
            label: `${field?.carnetCampos?.nombre}`,
            align: 'center',
            orden: `${field?.carnetCampos?.nombre}`,
          });
        }
      });
      const fieldsConcat = startColumnsCarnet.concat(fields).concat(finalColumnsCarnet);
      return fieldsConcat;
    }
  };

  const exportExcel = () => {
    setLoading(true);
    try {
      const rows = dataToExportExcel.map((data) => {
        return {
          Grupo: data.gruposInsigniasEmitidas?.nombre,
          Estado:
            data.estado === 1
              ? t('tables:table.state-issue.issued')
              : data.estado === 2
              ? t('tables:table.state-issue.accepted')
              : data.estado === 3
              ? t('tables:table.state-issue.rejected')
              : data.estado === 4
              ? t('tables:table.state-issue.bounced-mail')
              : t('tables:table.state-issue.revoked'),
          'Fecha emisión': moment(data.fecha_emision).format('DD/MM/YYYY HH:mm:ss'),
          Nombres: `${data.primer_nombre_receptor} ${data.segundo_nombre_receptor || ''}`,
          Apellidos: `${data.primer_apellido_receptor} ${data.segundo_apellido_receptor || ''}`,
          Email: data.email_receptor,
          'Tipo de identificación': data.tipo_identificacion,
          Identificación: data.identificacion,
          'Código insignia': data.codigo_insignia,
          'Nombre público insignia': data.plantillasInsignias?.nombre,
          'Nombre privado insignia': data.plantillasInsignias?.nombre_privado,
          'Fecha expiración':
            data.fecha_expiracion !== null
              ? isValid(new Date(data.fecha_expiracion))
                ? moment(data.fecha_expiracion).format('DD/MM/YYYY')
                : ''
              : '',
          Celular: data.numero_celular,
          WhatsApp: data.numero_whatsapp,
          Telegram: data.numero_telegram,
          Merito: data.merito,
          'Link merito': data.link_merito,
          Cuenta: isEsxistAccount(data.account),
          'No. correos emisión': data.correos_emision || 1,
          'No. correos aceptar insignia': data.correos_aceptar_insignia || 0,
          'No. correos crear cuenta': data.correos_crear_cuenta || 0,
          'No. correos compartir insignia': data.correos_compartir_insignia || 0,
          'No. correos revocar': data.correos_revocar || 0,
          'No. veces compartidas en Facebook': validationShare(data.estadisticasRedesSociales, 4),
          'No. veces compartidas en LinkedIn': validationShare(data.estadisticasRedesSociales, 3),
          'No. veces compartidas en Twitter': validationShare(data.estadisticasRedesSociales, 5),
          'No. veces compartidas en WhatsApp': validationShare(data.estadisticasRedesSociales, 2),
          'No. veces compartidas en Telegram': validationShare(data.estadisticasRedesSociales, 6),
        };
      });

      const filename = dataToExportExcel[0]?.gruposInsigniasEmitidas?.nombre;
      setDataExcel({ rows, filename });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const exportExcelCertificate = () => {
    setLoading(true);

    try {
      const rows = dataToExportExcel.map((data) => {
        const camposValores = data.certificadosCamposValores
          .sort((a, b) => (a.certificadoCampos?.nombre < b.certificadoCampos?.nombre ? -1 : 1))
          .filter(
            (item) =>
              item.certificadoCampos?.tipo_campo_vp !== 48 && item.certificadoCampos?.nombre !== 'certificateCode'
          )
          .map((value) => {
            return { [value.certificadoCampos.nombre]: value.valor };
          });

        let camposValoresJson = {};
        camposValores.map((item) => (camposValoresJson = { ...camposValoresJson, ...item }));

        return {
          Grupo: data.gruposInsigniasEmitidas?.nombre,
          Estado:
            data.estado === 1
              ? t('tables:table.state-issue-certificate.issued')
              : data.estado === 2
              ? t('tables:table.state-issue-certificate.accepted')
              : data.estado === 3
              ? t('tables:table.state-issue-certificate.rejected')
              : data.estado === 4
              ? t('tables:table.state-issue-certificate.bounced-mail')
              : t('tables:table.state-issue-certificate.revoked'),
          'Fecha emisión': moment(data.fecha_emision).format('DD/MM/YYYY HH:mm:ss'),
          Email: data.email_receptor,
          ...camposValoresJson,
          'Código certificado': data.codigo_certificado,
          'Nombre público certificado': data.plantillasCertificados?.nombre,
          'Nombre privado certificado': data.plantillasCertificados?.nombre_privado,
          'Fecha expiración':
            data.fecha_expiracion !== null
              ? isValid(new Date(data.fecha_expiracion))
                ? moment(data.fecha_expiracion).format('DD/MM/YYYY')
                : ''
              : '',
          Cuenta: isEsxistAccount(data.account),
          'No. correos emisión': data.correos_emision || 1,
          'No. correos aceptar certificado': data.correos_aceptar_certificado || 0,
          'No. correos crear cuenta': data.correos_crear_cuenta || 0,
          'No. correos compartir certificado': data.correos_compartir_certificado || 0,
          'No. correos revocar': data.correos_revocar || 0,
          'No. veces compartidas en Facebook': validationShare(data.estadisticasRedesSociales, 4),
          'No. veces compartidas en LinkedIn': validationShare(data.estadisticasRedesSociales, 3),
          'No. veces compartidas en Twitter': validationShare(data.estadisticasRedesSociales, 5),
          'No. veces compartidas en WhatsApp': validationShare(data.estadisticasRedesSociales, 2),
          'No. veces compartidas en Telegram': validationShare(data.estadisticasRedesSociales, 6),
        };
      });

      const filename = dataToExportExcel[0]?.gruposInsigniasEmitidas?.nombre;
      setDataExcel({ rows, filename });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const exportExcelCarnet = () => {
    setLoading(true);
    try {
      const rows = filtro.map((data) => {
        const camposValores = data.carnetsCamposValores
          .sort((a, b) => (a.carnetCampos?.nombre < b.carnetCampos?.nombre ? -1 : 1))
          .filter((item) => item.carnetCampos?.tipo_campo_vp !== 48 && item.carnetCampos?.nombre !== 'certificateCode')
          .map((value) => {
            if (value?.carnetCampos?.nombre) {
              return { [value?.carnetCampos?.nombre]: value?.valor };
            } else {
              return { [value?.carnetCamposAdicionales?.nombre_campo]: value?.valor };
            }
          });

        let camposValoresJson = {};
        camposValores.map((item) => (camposValoresJson = { ...camposValoresJson, ...item }));

        return {
          Grupo: data.gruposInsigniasEmitidas?.nombre,
          Estado:
            data.estado === 1
              ? t('tables:table.state-reports-carnets.issued')
              : data.estado === 2
              ? t('tables:table.state-reports-carnets.viewed')
              : data.estado === 3
              ? t('tables:table.state-reports-carnets.report')
              : data.estado === 4
              ? t('tables:table.state-reports-carnets.bounced-mail')
              : t('tables:table.state-reports-carnets.revoked'),
          'Fecha emisión': moment(data.fecha_emision).format('DD/MM/YYYY HH:mm:ss'),
          Email: data.email_receptor,
          ...camposValoresJson,
          'Código carnet': data.codigo_carnet,
          'Nombre público carnet': data.plantillasCarnets?.nombre,
          'Nombre privado carnet': data.plantillasCarnets?.nombre_privado,
          'Fecha expiración':
            data.fecha_expiracion !== null
              ? isValid(new Date(data.fecha_expiracion))
                ? moment(data.fecha_expiracion).format('DD/MM/YYYY')
                : ''
              : '',
          Cuenta: isEsxistAccount(data.account),
          'No. correos emisión': data.correos_emision || 1,
          'No. correos aceptar carnet': data.correos_aceptar_carnet || 0,
          'No. correos crear cuenta': data.correos_crear_cuenta || 0,
          'No. correos compartir carnet': data.correos_compartir_carnet || 0,
          'No. correos revocar': data.correos_revocar || 0,
          'No. veces compartidas en Facebook': validationShare(data.estadisticasRedesSociales, 4),
          'No. veces compartidas en LinkedIn': validationShare(data.estadisticasRedesSociales, 3),
          'No. veces compartidas en Twitter': validationShare(data.estadisticasRedesSociales, 5),
          'No. veces compartidas en WhatsApp': validationShare(data.estadisticasRedesSociales, 2),
          'No. veces compartidas en Telegram': validationShare(data.estadisticasRedesSociales, 6),
        };
      });

      const filename = filtro[0]?.gruposInsigniasEmitidas?.nombre;
      setDataExcel({ rows, filename });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const isEsxistAccount = (email_receptor) => {
    if (email_receptor) {
      return 'Si';
    } else {
      return 'No';
    }
  };

  const validationShare = (estadisticasRedesSociales, redSocialId) => {
    // eslint-disable-next-line array-callback-return
    const badgeShare = estadisticasRedesSociales.filter((item) => item.id_redes_sociales === redSocialId);
    if (badgeShare.length > 0) {
      return badgeShare.length;
    } else {
      return 0;
    }
  };

  const columns = () => {
    return t('tables:table.columns-badges-issued', { returnObjects: true }).filter(
      (item) => item.id !== 'badgename' && item.id !== 'badgenamePrivate' && item.id !== 'delete'
    );
  };

  const columnsContracts = () => {
    return t('tables:table.columns-contracts', { returnObjects: true });
  };

  const handlePreview = (linkPhoto) => {
    setImage(linkPhoto);
    setDialogPreview(true);
  };

  const handleClose = () => {
    setDialogPreview(false);
  };

  const changePagination = async (newPage, perPage) => {
    setLoadingTable(true);
    where.limit = perPage ? perPage : 10;
    where.offset = newPage ? newPage * perPage : 0;

    if (tipo === 0) {
      const { data } = await axios.post(`/badgesIssued/getBadgesIssued`, where);
      const array = [...filtro, ...data?.issued?.rows.filter((row) => !filtro.find((item) => item.id === row.id))];
      setFiltradas(array);
    } else if (tipo === 1) {
      const { data } = await axios.post(`/certificatesIssued/getCertificatesIssued`, where);
      const array = [...filtro, ...data?.issued?.rows.filter((row) => !filtro.find((item) => item.id === row.id))];
      setFiltradas(array);
    } else if (tipo === 2) {
      const { data } = await axios.post(`/carnetsIssued/getCarnetsIssued`, where);
      const array = [...filtro, ...data?.issued?.rows.filter((row) => !filtro.find((item) => item.id === row.id))];
      setFiltradas(array);
    } else if (tipo === 3) {
      const { data } = await axios.post(`/contract/getContracts`, where);
      const array = [...filtro, ...data?.contracts?.rows.filter((row) => !filtro.find((item) => item.id === row.id))];
      setFiltradas(array);
    }
    setLoadingTable(false);
  };

  return tipo === 0 ? (
    <Table
      columns={columns()}
      rows={countItems}
      setDirection={setOrdenDirection}
      setOrderBy={setValueToOrderBy}
      changePagination={changePagination}
    >
      <TableBody>
        {loadingTable ? (
          <SkeletonTables columns={columns()} photo={1} />
        ) : filtro.length > 0 ? (
          <>
            {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">{row.gruposInsigniasEmitidas.nombre}</TableCell>
                  <TableCell align="center">
                    <span className={row.estado === 4 && classes.row}>
                      {row.estado === 1
                        ? t('tables:table.state-issue.pending')
                        : row.estado === 2
                        ? t('tables:table.state-issue.accepted')
                        : row.estado === 3
                        ? t('tables:table.state-issue.rejected')
                        : row.estado === 4
                        ? t('tables:table.state-issue.bounced-mail')
                        : t('tables:table.state-issue.revoked')}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <Avatar
                      aria-label="img_badge"
                      src={row.plantillasInsignias?.linkPhoto}
                      className={classes.avatar}
                    />
                    {row.plantillasInsignias?.nombre}
                    <p className={classes.namePrivate}>{row.plantillasInsignias?.nombre_privado}</p>
                  </TableCell>
                  <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                  <TableCell align="center">{`${row.primer_nombre_receptor} ${
                    row.segundo_nombre_receptor || ''
                  }`}</TableCell>
                  <TableCell align="center">{`${row.primer_apellido_receptor} ${
                    row.segundo_apellido_receptor || ''
                  }`}</TableCell>
                  <TableCell align="center">{row.email_receptor}</TableCell>
                  <TableCell align="center">{row.tipo_identificacion}</TableCell>
                  <TableCell align="center">{row.identificacion}</TableCell>
                  <TableCell align="center">
                    {(row.estado === 2 || row.estado === 0) && isEsxistAccount(row.account) === 'Si' ? (
                      <Tooltip title={t('tables:table.tooltip.view-badge')}>
                        <a href={`${window.location.origin}/badges/${encrypt(row.id)}`} target="blank">
                          {row.codigo_insignia}
                        </a>
                      </Tooltip>
                    ) : (
                      row.codigo_insignia
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.fecha_expiracion !== null &&
                      isValid(new Date(row.fecha_expiracion)) &&
                      moment(row.fecha_expiracion).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="center">{row.numero_celular}</TableCell>
                  <TableCell align="center">{row.numero_whatsapp}</TableCell>
                  <TableCell align="center">{row.numero_telegram}</TableCell>
                  <TableCell align="center">{row.merito}</TableCell>
                  <TableCell align="center">
                    <a href={row.link_merito} target="blank">
                      {row.link_merito}
                    </a>
                  </TableCell>
                  <TableCell align="center">{isEsxistAccount(row.account)}</TableCell>
                  <TableCell align="center">{row.correos_emision || 1}</TableCell>
                  <TableCell align="center">{row.correos_aceptar_insignia || 0}</TableCell>
                  <TableCell align="center">{row.correos_crear_cuenta || 0}</TableCell>
                  <TableCell align="center">{row.correos_compartir_insignia || 0}</TableCell>
                  <TableCell align="center">{row.correos_revocar || 0}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 4)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 3)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 5)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 2)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 6)}</TableCell>
                </TableRow>
              ))}
            {exportExcel()}
          </>
        ) : (
          <TableRow>
            <TableCell align="center" colSpan="22">
              {t('tables:table.empty-table')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ) : tipo === 1 ? (
    <Table
      columns={loadFields()}
      rows={countItems}
      setDirection={setOrdenDirection}
      setOrderBy={setValueToOrderBy}
      changePagination={changePagination}
    >
      <TableBody>
        {loadingTable ? (
          <SkeletonTables columns={loadFields()} photo={0} />
        ) : filtro.length > 0 ? (
          <>
            {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={`row${index}`}>
                  {' '}
                  <TableCell align="center">
                    <Tooltip title={t('tables:table.tooltip.view-certificate')}>
                      <IconButton onClick={() => handlePreview(row.linkPhoto)}>
                        <ImageSearchOutlined />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">{row.gruposInsigniasEmitidas.nombre}</TableCell>
                  <TableCell align="center">
                    <span className={row.estado === 4 && classes.row}>
                      {row.estado === 1
                        ? t('tables:table.state-issue-certificate.issued')
                        : row.estado === 2
                        ? t('tables:table.state-issue-certificate.accepted')
                        : row.estado === 3
                        ? t('tables:table.state-issue-certificate.rejected')
                        : row.estado === 4
                        ? t('tables:table.state-issue-certificate.bounced-mail')
                        : t('tables:table.state-issue-certificate.revoked')}
                    </span>
                  </TableCell>
                  <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                  <TableCell align="center">{row.email_receptor}</TableCell>
                  {row.certificadosCamposValores?.map(
                    (value) =>
                      value.certificadoCampos?.tipo_campo_vp !== 48 &&
                      value.certificadoCampos?.nombre !== 'certificateCode' && (
                        <TableCell align="center">{value.valor}</TableCell>
                      )
                  )}
                  <TableCell align="center">
                    {(row.estado === 2 || row.estado === 0) && isEsxistAccount(row.account) === 'Si' ? (
                      <Tooltip title={t('tables:table.tooltip.view-certificate')}>
                        <a href={`${window.location.origin}/certificate/${encrypt(row.id)}`} target="blank">
                          {row.codigo_certificado}
                        </a>
                      </Tooltip>
                    ) : (
                      row.codigo_certificado
                    )}
                  </TableCell>
                  <TableCell align="center">{row.plantillasCertificados?.nombre}</TableCell>
                  <TableCell align="center">{row.plantillasCertificados?.nombre_privado}</TableCell>
                  <TableCell align="center">
                    {row.fecha_expiracion !== null &&
                      isValid(new Date(row.fecha_expiracion)) &&
                      moment(row.fecha_expiracion).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="center">{isEsxistAccount(row.account)}</TableCell>
                  <TableCell align="center">{row.correos_emision || 1}</TableCell>
                  <TableCell align="center">{row.correos_aceptar_certificado || 0}</TableCell>
                  <TableCell align="center">{row.correos_crear_cuenta || 0}</TableCell>
                  <TableCell align="center">{row.correos_compartir_certificado || 0}</TableCell>
                  <TableCell align="center">{row.correos_revocar || 0}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 4)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 3)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 5)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 2)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 6)}</TableCell>
                </TableRow>
              ))}
            {exportExcelCertificate()}

            <Dialog open={dialogPreview} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
              <DialogActions>
                <Button variant="contained" className={`my-2 ${classes.buttonDialog}`} onClick={handleClose}>
                  <CloseOutlined />
                </Button>
              </DialogActions>
              <DialogContent align="center">
                <img alt="img-prev" src={image} className={classes.img} />
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <TableRow>
            <TableCell align="center" colSpan="22">
              {t('tables:table.empty-table')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ) : tipo === 2 ? (
    <Table
      columns={loadFields()}
      rows={countItems}
      setDirection={setOrdenDirection}
      setOrderBy={setValueToOrderBy}
      changePagination={changePagination}
    >
      <TableBody>
        {loadingTable ? (
          <SkeletonTables columns={loadFields()} photo={0} />
        ) : filtro.length > 0 ? (
          <>
            {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">{row.gruposInsigniasEmitidas.nombre}</TableCell>
                  <TableCell align="center">
                    <span className={row.estado === 4 && classes.row}>
                      {row.estado === 1
                        ? t('tables:table.state-reports-carnets.issued')
                        : row.estado === 2
                        ? t('tables:table.state-reports-carnets.viewed')
                        : row.estado === 3
                        ? t('tables:table.state-reports-carnets.report')
                        : row.estado === 4
                        ? t('tables:table.state-reports-carnets.bounced-mail')
                        : t('tables:table.state-reports-carnets.revoked')}
                    </span>
                  </TableCell>
                  <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                  <TableCell align="center">{row.email_receptor}</TableCell>
                  {row.carnetsCamposValores?.map(
                    (value) =>
                      value.carnetCampos?.tipo_campo_vp !== 48 &&
                      value.carnetCampos?.nombre !== 'certificateCode' && (
                        <TableCell align="center">{value.valor}</TableCell>
                      )
                  )}
                  <TableCell align="center">
                    <Tooltip title={t('tables:table.tooltip.view-carnet')}>
                      <a href={`${window.location.origin}/carnet/verify/${encrypt(row.id)}`} target="blank">
                        {row.codigo_carnet}
                      </a>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">{row.plantillasCarnets?.nombre}</TableCell>
                  <TableCell align="center">{row.plantillasCarnets?.nombre_privado}</TableCell>
                  <TableCell align="center">
                    {row.fecha_expiracion !== null &&
                      isValid(new Date(row.fecha_expiracion)) &&
                      moment(row.fecha_expiracion).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="center">{isEsxistAccount(row.account)}</TableCell>
                  <TableCell align="center">{row.correos_emision || 1}</TableCell>
                  <TableCell align="center">{row.correos_aceptar_certificado || 0}</TableCell>
                  <TableCell align="center">{row.correos_crear_cuenta || 0}</TableCell>
                  <TableCell align="center">{row.correos_compartir_certificado || 0}</TableCell>
                  <TableCell align="center">{row.correos_revocar || 0}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 4)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 3)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 5)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 2)}</TableCell>
                  <TableCell align="center">{validationShare(row.estadisticasRedesSociales, 6)}</TableCell>
                </TableRow>
              ))}
            {exportExcelCarnet()}
          </>
        ) : (
          <TableRow>
            <TableCell align="center" colSpan="22">
              {t('tables:table.empty-table')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ) : (
    <Table
      columns={columnsContracts()}
      rows={countItems}
      setDirection={setOrdenDirection}
      setOrderBy={setValueToOrderBy}
      changePagination={changePagination}
    >
      <TableBody>
        {loadingTable ? (
          <SkeletonTables columns={columnsContracts()} photo={0} />
        ) : filtro.length > 0 ? (
          <>
            {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">{row.organizaciones.nombre}</TableCell>
                  <TableCell align="center">{row.planes.nombre}</TableCell>
                  <TableCell align="center">{row.valor_estado}</TableCell>
                  <TableCell align="center">{moment(row.fecha_inicio).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="center">{moment(row.vencimiento).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="center">{row.cantidad_emisiones}</TableCell>
                  <TableCell align="center">{row.cantidad_insignias_emitidas}</TableCell>
                  <TableCell align="center">{row.cantidad_certificados_emitidos}</TableCell>
                  <TableCell align="center">{row.cantidad_carnets_emitidos}</TableCell>
                  <TableCell align="center">{row.cantidad_documentos_emitidos}</TableCell>
                  <TableCell align="center">{row.total_emisiones}</TableCell>
                </TableRow>
              ))}
            {/* {exportExcelCarnet()} */}
          </>
        ) : (
          <TableRow>
            <TableCell align="center" colSpan="22">
              {t('tables:table.empty-table')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'insignias emitidas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
