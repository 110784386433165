import React from 'react';
import { Stage, Layer, Text } from 'react-konva';
import { Html } from 'react-konva-utils';
import SketchPickerButton from './SketchPickerButton';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  withStyles,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SignatureText = (props) => {
  const { closeModalSign, saveSign, progressBar, nameUser, emailUser, fontsFamilys, responsiveWidth = false } = props;
  const [sizesBySave, setSizesBySave] = React.useState(false);
  const [textKonva, setTextKonva] = React.useState(false);
  const [signature, setSignature] = React.useState({
    text: nameUser,
    color: '#000',
    font: 'Caveat',
    fontSize: '50',
  });
  const stageRef = React.useRef(null);
  const classes = useStyle();
  const [t] = useTranslation(['buttons', 'certificateBadgeTemplate', 'certifyDocuments']);

  const changeFonts = () => {
    return fontsFamilys.map((font) => {
      return (
        <MenuItem value={font}>
          <Typography
            style={{
              fontSize: '18px',
              color: signature?.color,
              fontFamily: font,
              textAlign: 'center',
            }}
          >
            {signature?.text}
          </Typography>
        </MenuItem>
      );
    });
  };

  const onChange = (e) => {
    setSignature({ ...signature, text: e.target.value });
  };

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const handleStyle = (e) => {
    setSignature({ ...signature, font: e.target.value });
  };

  const changeColor = (color) => {
    setSignature({ ...signature, color: color.hex });
  };

  const goToSave = async (stageRef) => {
    await setTextKonva(!textKonva);
    responsiveWidth && (await setSizesBySave(true));

    saveSign(stageRef);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <SketchPickerButton color={signature?.color} changeColor={changeColor} />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right', marginTop: '1em' }}>
          <Select id="style" name="signatureTextStyle" onChange={handleStyle} value="0">
            <MenuItem key={0} value={0} style={{ display: 'none' }}>
              {t('certificateBadgeTemplate:certificate-design.font')}
            </MenuItem>
            {changeFonts()}
          </Select>
        </Grid>
      </Grid>
      {!progressBar.value && (
        <div
          style={{
            backgroundColor: '#f0f0f0',
            margin: responsiveWidth && !sizesBySave ? '1em 0 ' : '1em',
            borderRadius: '1em',
          }}
        >
          <Stage width={responsiveWidth && !sizesBySave ? responsiveWidth : 520} height={150} ref={stageRef}>
            <Layer>
              <Text
                text={t('certifyDocuments:document.stamp')}
                fontSize={14}
                fontStyle="italic"
                width={responsiveWidth && !sizesBySave ? responsiveWidth - 10 : 510}
                x={1}
                y={2}
                align="right"
              />

              <Text
                text={t('certifyDocuments:add-signatories.signatory')}
                x={1}
                y={10}
                width={responsiveWidth && !sizesBySave ? responsiveWidth : 520}
                fontSize={20}
                align="center"
              />

              <Text
                text={emailUser}
                x={1}
                y={30}
                width={responsiveWidth && !sizesBySave ? responsiveWidth : 520}
                fontSize={20}
                align="center"
              />
              {textKonva && (
                <Text
                  width={responsiveWidth && !sizesBySave ? responsiveWidth : 518}
                  height={81}
                  x={1}
                  y={68}
                  fontSize={signature?.fontSize}
                  fontFamily={signature?.font}
                  fill={signature?.color}
                  align="center"
                  text={signature?.text}
                />
              )}
              {!textKonva && (
                <Html>
                  <textarea
                    value={signature?.text}
                    onChange={onChange}
                    className={classes.textArea}
                    style={{
                      fontFamily: signature?.font,
                      color: signature?.color,
                      width: responsiveWidth && !sizesBySave ? `${responsiveWidth - 10}px` : '500px',
                    }}
                    onKeyDown={keyDown}
                  />
                </Html>
              )}
            </Layer>
          </Stage>
        </div>
      )}
      {progressBar.value && (
        <div style={{ margin: '1em', width: responsiveWidth && !sizesBySave ? responsiveWidth : 520 }}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progressBar.value} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressBar.value)}%`}</Typography>
            </Box>
          </Box>
        </div>
      )}
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} className={classes.container__button_fields}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => goToSave(stageRef)}
            disabled={signature?.text.length === 0}
          >
            {t('buttons:buttons.save')}
          </Button>{' '}
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => closeModalSign()}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyle = makeStyles(() => ({
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  button: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  textArea: {
    backgroundColor: '#f0f0f0',
    border: 'none',
    height: '80px',
    marginTop: '1em',
    marginLeft: '0.2em',
    resize: 'none',
    textAlign: 'center',
    fontSize: '50px',
    overflow: 'hidden',
    '&:focus': {
      outline: '0px',
    },
  },
}));

export default SignatureText;
