import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow, IconButton, Tooltip, Button, Avatar } from '@material-ui/core';
import { FindInPageOutlined } from '@mui/icons-material';
import Table from '../../../components/Table';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import axios from '../../../api';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import colors from '../../../assets/styles/colors';
import SkeletonTables from '../../../components/SkeletonTable';
import { changePage } from '../../../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  container__avatar: {
    '& img': {
      height: 'min-content',
    },
  },
}));

function Contract(props) {
  const { page, rowsPerPage, permission, changePage, changeBreadcrump } = props;
  const history = useHistory();
  const [contracts, setContracts] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const classes = useStyles();
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [loading, setLoading] = useState(true);
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await getContracts();
    changeBreadcrump(t('breadCrumps:breadCrumps.contracts', { returnObjects: true }));
    setLoading(false);
  };

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    return params;
  };

  const getContracts = async () => {
    const params = getParams(0, rowsPerPage);
    const { data } = await axios.post(`/contract/getContractsCountForOrganizations`, params);
    setContracts(data.contracts.rows);
    setFiltradas(data.contracts.rows);
    setCountItems(data.contracts?.count);
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/contract/getContractsCountForOrganizations`, params);

    const array = [
      ...contracts,
      ...data.contracts?.rows.filter((row) => !contracts.find((item) => item.id === row.id)),
    ];

    setContracts(array);
    setFiltradas(array);
    setLoading(false);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'view':
        changePage(0);
        history.push(`/contracts/${encrypt(id)}`);
        break;
      case 'create':
        history.push(`/contract/create`);
        break;
      default:
        break;
    }
  };

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button className={classes.button} onClick={(e) => handleClick(e, 0, 'create')}>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const columns = () => {
    return t('tables:table.columns-contracts-organizations', { returnObjects: true });
  };

  return (
    <>
      {create_permission()}
      <Search
        tableName="contracts"
        items={contracts}
        setItems={setFiltradas}
        loadData={loadData}
        setLoading={setLoading}
      />
      <Table
        columns={columns()}
        rows={countItems}
        changePage={changePage}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      <Avatar
                        alt="Remy Sharp"
                        variant="square"
                        src={row.linkPhoto}
                        className={classes.container__avatar}
                      />
                    </TableCell>
                    <TableCell align="center">{row.nombre}</TableCell>
                    <TableCell align="center">{row.activos}</TableCell>
                    <TableCell align="center">{row.vencidos}</TableCell>
                    <TableCell align="center">{row.pendientes}</TableCell>
                    <TableCell align="center">{row.eliminados}</TableCell>
                    <TableCell align="center">
                      <Tooltip title={t('tables:table.tooltip.view-all')}>
                        <IconButton aria-label="view" onClick={(e) => handleClick(e, row.id, 'view')}>
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : (
            <TableCell align="center" colSpan="8">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'contratos')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
