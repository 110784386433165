const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        user: action.payload,
      };
    case 'LOGOUT_REQUEST':
      return {
        ...state,
        user: action.payload,
      };
    case 'LOGIN_SESION':
      return {
        ...state,
        sesion: action.payload,
      };
    case 'LOGOUT_SESION':
      return {
        ...state,
        sesion: action.payload,
      };
    case 'PAGE':
      return {
        ...state,
        page: action.payload,
      };
    case 'ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    case 'BREADCRUMP':
      return {
        ...state,
        crump: action.payload,
      };
    case 'PERMISSION':
      return {
        ...state,
        permission: action.payload,
      };
    case 'BADGES':
      return {
        ...state,
        badges: action.payload,
      };
    case 'CERTIFICATES':
      return {
        ...state,
        certificates: action.payload,
      };
    case 'CARNETS':
      return {
        ...state,
        carnets: action.payload,
      };
    case 'BADGE':
      return {
        ...state,
        badge: action.payload,
      };
    case 'CERTIFICATE':
      return {
        ...state,
        certificate: action.payload,
      };
    case 'CARNET':
      return {
        ...state,
        carnet: action.payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'ORGANIZATION_ID':
      return {
        ...state,
        organizationId: action.payload,
      };
    case 'CERTIFICATE_FIELDS':
      return {
        ...state,
        certificateFields: action.payload,
      };
    case 'DOCUMENTS_FIELDS':
      return {
        ...state,
        documentsFields: action.payload,
      };
    case 'FORM_DOCUMENTS':
      return {
        ...state,
        formDocuments: action.payload,
      };
    case 'SAVE_DESIGN_DOCUMENTS':
      return {
        ...state,
        saveDesignDocuments: action.payload,
      };
    case 'DOCUMENT':
      return {
        ...state,
        document: action.payload,
      };
    case 'FORM':
      return {
        ...state,
        form: action.payload,
      };
    case 'RETURN_ROUTE':
      return {
        ...state,
        returnRoute: action.payload,
      };
    case 'SAVE_DESIGN':
      return {
        ...state,
        saveDesign: action.payload,
      };
    case 'LENGUAGE':
      return {
        ...state,
        lenguage: action.payload,
      };
    case 'DETAILS':
      return {
        ...state,
        details: action.payload,
      };
    case 'STYLES':
      return {
        ...state,
        styles: action.payload,
      };
    case 'SIGNATORIESINGUP':
      return {
        ...state,
        signatorieSignUp: action.payload,
      };
    case 'SIGNED_DOCUMENTS':
      return {
        ...state,
        signedDocuments: action.payload,
      };
    case 'FORM_DIPLOMAS':
      return {
        ...state,
        formDiplomas: action.payload,
      };
    case 'DIPLOMA_FIELDS':
      return {
        ...state,
        diplomaFields: action.payload,
      };
    case 'SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload,
      };
    case 'VIEW':
      return {
        ...state,
        view: action.payload,
      };
    case 'ACTIVE':
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
