import React from 'react';
import { makeStyles, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { CloseOutlined } from '@mui/icons-material';

const PreviewTemplate = (props) => {
  const { open, setOpen, setImgTemplate, imgTemplate } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setImgTemplate('');
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <DialogActions>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className={`my-2 ${classes.btnClose}`}
          onClick={handleClose}
        >
          <CloseOutlined />
        </Button>
      </DialogActions>
      <DialogContent align="center">
        <img
          alt="img-prev"
          src={imgTemplate.photo}
          className={imgTemplate.width > imgTemplate.height ? classes.img_horizontal : classes.img_vertical}
        />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  btnClose: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
  img_vertical: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
  img_horizontal: {
    maxWidth: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
}));

export default PreviewTemplate;
