import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  makeStyles,
  Card,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { FindInPageOutlined } from '@mui/icons-material';
import Alert from '@material-ui/lab/Alert';
import Table from '../../../../components/Table';
import Template from './Index';
import axios from '../../../../api';
import { changeBreadcrump, sortData, getComparator } from '../../../../actions';
import { decrypt } from '../../../../utils/crypt';
import SkeletonTables from '../../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next';

function History(props) {
  const { page, rowsPerPage, changeBreadcrump } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTemplates, setOpenTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [valueParameters, setValueParameters] = useState([]);
  const [details, setDetails] = useState([
    {
      descripcion: '',
      valor: '',
      nuevoValor: '',
      tipo: '',
      recomendacion: '',
    },
  ]);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [carnetTemplateHistory, setCarnetTemplateHistory] = useState({});
  const [t] = useTranslation(['tables', 'breadCrumps', 'dialogs']);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getCarnetTemplateHistory();
      await getValueParameters();
      setLoading(false);
      const { data } = await axios.post(`/carnetTemplate/getCarnetTemplates`, { estado: 1 });
      setTemplates(data.carnetTemplates);
    } catch (error) {
      props.history.push('/500');
    }
  };

  const getValueParameters = async () => {
    const { data } = await axios.post(`/parameterValueParameter/getParameterValueParameter`);
    setValueParameters(data.parametersValueParameters);
  };

  const getCarnetTemplateHistory = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.post(`/carnetTemplateHistory/getCarnetTemplatesHistory`, {
      id_plantillas_carnets: id,
    });
    setCarnetTemplateHistory(data.carnetTemplatesHistory);
    getCarnetTemplateState(data.carnetTemplatesHistory[0]?.plantillasCarnets?.estado);
  };

  const getCarnetTemplateState = (estado) => {
    if (estado === 1) {
      changeBreadcrump(t('breadCrumps:breadCrumps.carnet-history-published', { returnObjects: true }));
    } else {
      if (estado === 0) {
        changeBreadcrump(t('breadCrumps:breadCrumps.carnet-history-edited', { returnObjects: true }));
      } else {
        changeBreadcrump(t('breadCrumps:breadCrumps.carnet-history-archived', { returnObjects: true }));
      }
    }
  };

  const openDetails = (id) => {
    let actualizacion = '';
    let detail = [];
    // eslint-disable-next-line
    carnetTemplateHistory.map(async (item) => {
      if (item.id === id) {
        if (item.accion === 34) {
          openDialogTemplates(id);
        } else if (item.accion === 36 || item.accion === 37) {
          setDetails([
            {
              descripcion: item.accion === 36 ? 'Publicado' : 'Archivado',
              valor: item.valor,
              nuevoValor: item.nuevoValor,
              tipo: 'info',
            },
          ]);
          setOpenDialog(true);
        } else {
          actualizacion = item.valor.split('¿?');
          if (actualizacion.length > 0) {
            // eslint-disable-next-line
            actualizacion.map(async (datos, index) => {
              if (index !== 0) {
                datos = datos.split('$%&');
                if (datos[0].includes('esta')) {
                  detail.push({
                    descripcion: datos[0],
                    valor: datos[1],
                    nuevoValor: datos[2],
                    tipo: 'info',
                    recomendacion: templates.filter((item) => item.id === parseInt(datos[1])),
                  });
                } else {
                  detail.push({
                    descripcion: datos[0],
                    valor: datos[1],
                    nuevoValor: datos[2],
                    tipo: 'info',
                  });
                }
              }
            });
            setDetails(detail);
            setOpenDialog(true);
          }
        }
      }
    });
  };

  const closeDelails = () => {
    setOpenDialog(false);
  };

  const openDialogTemplates = (id) => {
    // eslint-disable-next-line
    carnetTemplateHistory.map(async (item) => {
      if (item.id === id) {
        if (item.nuevoValor !== '') {
          setDetails([
            {
              descripcion: 'Creación',
              valor: templates.filter((value) => value.id === parseInt(item.nuevoValor)),
            },
          ]);
        } else {
          setDetails([{ descripcion: 'Creación', valor: '' }]);
        }
      }
    });
    setOpenTemplates(true);
  };

  const templateClose = () => {
    setOpenTemplates(false);
  };

  const loadValueParameter = (id) => {
    let valida = 0;
    let value = '';
    // eslint-disable-next-line
    valueParameters.map((data) => {
      if (data.id === parseInt(id)) {
        valida = 1;
        value = data.valor_parametro;
      }
    });
    if (valida === 0) {
      return id;
    } else {
      return value;
    }
  };

  const columns = t('tables:table.columns-history', { returnObjects: true });

  return (
    <Template Index={2} carnetId={decrypt(props.match.params.id)}>
      <Card>
        <Table
          columns={columns}
          rows={carnetTemplateHistory}
          setDirection={setOrdenDirection}
          setOrderBy={setValueToOrderBy}
          loading={loading}
        >
          {loading ? (
            <SkeletonTables columns={columns} photo={0} />
          ) : (
            <TableBody>
              {carnetTemplateHistory.length > 0 ? (
                <>
                  {sortData(carnetTemplateHistory, getComparator(ordenDirection, valueToOrderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">{moment(row.fecha_accion).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="center">{moment(row.fecha_accion).format('HH:mm:ss')}</TableCell>
                        <TableCell align="center">{`${row.usuarios?.primer_nombre} ${row.usuarios?.primer_apellido}`}</TableCell>
                        <TableCell align="center">{loadValueParameter(row.accion)}</TableCell>
                        <TableCell>
                          <Tooltip title={t('tables:table.tooltip.details')}>
                            <IconButton aria-label="detalles" onClick={(e) => openDetails(row.id)}>
                              <FindInPageOutlined />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableCell align="center" colSpan="3">
                  {t('tables:table.empty-table')}
                </TableCell>
              )}
            </TableBody>
          )}
        </Table>
      </Card>

      <Dialog open={openDialog} onClose={closeDelails} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle align="center">{t('dialogs:dialog.details')}</DialogTitle>
        <DialogContent>
          {details.length > 0 ? (
            <>
              {details.map((row) => (
                <Grid container xs={12} className={classes.root}>
                  <Grid item xs={12} lg={12}>
                    <Alert severity={row.tipo}>{row.descripcion}</Alert>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={openTemplates} onClose={templateClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle align="center">{t('dialogs:dialog.details')}</DialogTitle>
        {details.length > 0 ? (
          <>
            {details.map((row) => (
              <DialogContent align="center">
                <Alert severity="info">{row.descripcion}</Alert>
              </DialogContent>
            ))}
          </>
        ) : (
          <TableCell align="center">{t('tables:table.empty-table')}</TableCell>
        )}
      </Dialog>
    </Template>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '2.8px solid #cacaca',
    borderRadius: '10px',
    boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.2)',
    marginTop: '1rem',
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
