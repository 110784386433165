import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, TextField, AppBar, Toolbar } from '@mui/material';
import {
  TerminalOutlined,
  CodeOutlined,
  LinkOutlined,
  PictureAsPdfOutlined,
  VerifiedOutlined,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import { env } from '../config/environment';
import { encrypt } from '../utils/crypt';
import { useTranslation } from 'react-i18next';
import FondoVerificar from '../assets/img/fondo_verificar.png';
import colors from '../assets/styles/colors';
import axios from 'axios';
import Swal from 'sweetalert2';
import Backdrop from '../components/Backdrop';

function Verify(props) {
  const classes = useStyles();
  const [view, setView] = useState(0);
  const [active, setActive] = useState('codigo');
  const [propsDropZoneArea, setPropsDropZoneArea] = useState({});
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [loginStyles, setLoginStyles] = useState({});
  const history = useHistory();

  const [t] = useTranslation(['verify']);

  useEffect(() => {
    const styles = JSON.parse(window.localStorage.state)?.styles;
    setLoginStyles({
      Logo: styles.logo435x149,
      LoginLogo: styles.icono_login,
      LoginBackground: styles.login_background,
    });

    const paramsCode = new URLSearchParams(document.location.search.substring(1)).get('code');
    if (paramsCode) {
      const handleSubmit = (e) => {
        handleSubmitCode(e, paramsCode);
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const vtype = props.match.params.verificationType;
    if (
      vtype === 'code' ||
      vtype === 'document-code' ||
      vtype === 'carnet-code' ||
      vtype === 'certificate-code' ||
      vtype === 'diploma-code'
    ) {
      setView(0);
    } else if (vtype === 'metadata') {
      setView(1);
    } else if (
      vtype === 'proof' ||
      vtype === 'proof-document' ||
      vtype === 'proof-carnet' ||
      vtype === 'proof-certificate' ||
      vtype === 'proof-diploma'
    ) {
      setView(2);
    } else if (
      vtype === 'chainpoint' ||
      vtype === 'chainpoint-document' ||
      vtype === 'chainpoint-carnet' ||
      vtype === 'chainpoint-certificate' ||
      vtype === 'chainpoint-diploma'
    ) {
      setView(3);
    } else {
      setView(4);
    }
  }, [props.match.params.verificationType]);

  useEffect(() => {
    view === 0
      ? setActive('codigo')
      : view === 1
      ? setActive('metadata')
      : view === 2
      ? setActive('proof')
      : view === 3
      ? setActive('chainpoint')
      : setActive('pdf');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleClick = (e) => {
    setActive(e.target.name);
    if (e.target.name === 'codigo') {
      history.push('/verify/code');
      setView(0);
    } else if (e.target.name === 'metadata') {
      history.push('/verify/metadata');
      setView(1);
      setPropsDropZoneArea({ acceptedFiles: ['.json'] });
    } else if (e.target.name === 'proof') {
      history.push('/verify/proof');
      setView(2);
      setPropsDropZoneArea({ acceptedFiles: ['.pdf'] });
    } else if (e.target.name === 'chainpoint') {
      history.push('/verify/chainpoint');
      setView(3);
      setPropsDropZoneArea({ acceptedFiles: ['.json'] });
    } else if (e.target.name === 'pdf') {
      history.push('/verify/pdf');
      setView(4);
      setPropsDropZoneArea({ acceptedFiles: ['.pdf'] });
    }
  };

  const handleSubmitCode = (e, data) => {
    e?.preventDefault();
    const dataCode = code || code !== '' ? code : data;

    if (dataCode !== '') {
      setLoading(true);
      axios({
        method: 'post',
        url: `${env.apiURL}/blockchain/verify/v2/code`,
        data: { code: dataCode },
      }).then(({ data }) => {
        setLoading(false);
        const { isVerified, issuedId, state, type } = data;

        if (isVerified && type === 'documento') {
          history.push(`/verified-documents/code/${encrypt(issuedId)}`);
        } else if (isVerified && type === 'carnet') {
          history.push(`/verified-carnet/code/${encrypt(issuedId)}`);
        } else if (isVerified && (state === 2 || state === 0)) {
          if (type === 'insignia') {
            history.push(`/certificates/verify/code/${encrypt(issuedId)}`);
          } else if (type === 'certificado') {
            history.push(`/verified-certificate/code/${encrypt(issuedId)}`);
          }
        } else if (state === 1) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('verify:verify-file.credential-no-accepted'),
            showConfirmButton: false,
          }).then(() => {
            setCode('');
          });
        } else if (state === 3) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('verify:verify-file.credential-rejected'),
            showConfirmButton: false,
          }).then(() => {
            setCode('');
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('verify:verify-file.credential-no-valid'),
            showConfirmButton: false,
          }).then(() => {
            setCode('');
          });
        }
      });
    }
  };

  const verify = (file) => {
    if (file.length > 0) {
      const form = new FormData();
      form.append('file', file[0]);
      setLoading(true);
      axios({
        method: 'post',
        url: `${env.apiURL}/blockchain/verify/v2/${active}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: form,
      })
        .then(({ data }) => {
          setLoading(false);
          const { isVerified, badgeIssuedId, carnetIssuedId, certificateIssuedId, documentIssuedId } = data;

          if (isVerified) {
            if (badgeIssuedId) {
              history.push(`/certificates/verify/${active}/${encrypt(badgeIssuedId)}`);
            }
            if (certificateIssuedId) {
              if (active === 'pdf') {
                history.push(`/verified-certificate/certificate/${encrypt(certificateIssuedId)}`);
              } else {
                history.push(`/verified-certificate/${active}/${encrypt(certificateIssuedId)}`);
              }
            }
            if (documentIssuedId) {
              history.push(`/verified-documents/${active}/${encrypt(documentIssuedId)}`);
            }
            if (carnetIssuedId) {
              if (active === 'pdf') {
                history.push(`/verified-carnet/carnet/${encrypt(certificateIssuedId)}`);
              } else {
                history.push(`/verified-carnet/${active}/${encrypt(carnetIssuedId)}`);
              }
            }
          } else {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('verify:verify-file.credential-no-valid'),
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('verify:verify-file.message-error'),
            showConfirmButton: false,
          });
        });
    }
  };

  return (
    <Box component="div" sx={styles.root}>
      <Box component="div" sx={styles.container_img}>
        <img alt="logo" src={loginStyles?.Logo} style={{ width: '90%' }} />
      </Box>
      <Typography sx={styles.text_title}>{t('verify:verify-file.message')}</Typography>
      <Box component="div" sx={styles.container__subbar}>
        <Box component="div" sx={styles.container__buttons}>
          <Button
            name="codigo"
            sx={active === 'codigo' ? styles.container__buttons_active : styles.container__buttons_inactive}
            startIcon={<TerminalOutlined />}
            onClick={handleClick}
          >
            {t('verify:verify-file.code')}
          </Button>
          <Button
            name="metadata"
            sx={active === 'metadata' ? styles.container__buttons_active : styles.container__buttons_inactive}
            startIcon={<CodeOutlined />}
            onClick={handleClick}
          >
            Metadata
          </Button>
          <Button
            name="proof"
            sx={active === 'proof' ? styles.container__buttons_active : styles.container__buttons_inactive}
            startIcon={<VerifiedOutlined />}
            onClick={handleClick}
          >
            Proof
          </Button>
          <Button
            name="chainpoint"
            sx={active === 'chainpoint' ? styles.container__buttons_active : styles.container__buttons_inactive}
            startIcon={<LinkOutlined />}
            onClick={handleClick}
          >
            Chainpoint
          </Button>
          <Button
            name="pdf"
            sx={active === 'pdf' ? styles.container__buttons_active : styles.container__buttons_inactive}
            startIcon={<PictureAsPdfOutlined />}
            onClick={handleClick}
          >
            PDF
          </Button>
        </Box>
      </Box>
      {view === 0 ? (
        <Box component="div" sx={styles.container__code}>
          <form className={classes.root} onSubmit={(e) => handleSubmitCode(e)}>
            <TextField
              required
              fullWidth
              color="primary"
              variant="outlined"
              placeholder={t('verify:verify-file.placeholder')}
              onChange={handleChange}
              sx={styles.textField}
            />
            <Button type="submit" color="primary" variant="contained" sx={styles.button} disableElevation>
              {t('verify:blockchain.verify')}
            </Button>
          </form>
        </Box>
      ) : (
        <Box component="div" sx={styles.container__code}>
          <DropzoneArea
            filesLimit={1}
            maxFileSize={10000000}
            acceptedFiles={propsDropZoneArea.acceptedFiles}
            dropzoneText={<Typography sx={styles.text}>{t('verify:verify-file.dropzoneText')}</Typography>}
            dropzoneClass={classes.dropzone}
            dropzoneParagraphClass={styles.dropzoneParagraph}
            showFileNames={true}
            showAlerts={false}
            onChange={(file) => verify(file)}
          />
        </Box>
      )}
      <Box component="div" sx={styles.container__footer}>
        <Typography sx={styles.text_footer}>
          {t('verify:verify-file.footer')}{' '}
          <a
            href={`${
              window.location.origin === env.certikaURL
                ? 'https://certika.co/wp-content/uploads/2023/08/Terminos-y-Condiciones-Acuerdos-de-niveles.pdf'
                : ''
            }`}
            target="_blank"
            rel="noreferrer"
            style={{ color: colors.primary }}
          >
            {t('verify:verify-file.terms')}
          </a>{' '}
          {t('verify:verify-file.learn-about')}{' '}
          <a
            href={`${
              window.location.origin === env.certikaURL ? 'https://certika.co/que-es-la-tecnologia-blockchain' : ''
            }`}
            target="_blank"
            rel="noreferrer"
            style={{ color: colors.primary }}
          >
            blockchain.
          </a>
        </Typography>
      </Box>

      {/* Appbar mobile */}
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#fff', display: { xs: 'flex', sm: 'none' } }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box component="div" sx={styles.container_button_centered}>
            <Button
              name="codigo"
              sx={
                active === 'codigo'
                  ? styles.container__buttons_active_mobile
                  : styles.container__buttons_inactive_mobile
              }
              onClick={handleClick}
            >
              <TerminalOutlined />
              {t('verify:verify-file.code')}
            </Button>
          </Box>
          <Button
            name="metadata"
            sx={
              active === 'metadata'
                ? styles.container__buttons_active_mobile
                : styles.container__buttons_inactive_mobile
            }
            onClick={handleClick}
          >
            <CodeOutlined />
            Metadata
          </Button>
          <Button
            name="proof"
            sx={
              active === 'proof' ? styles.container__buttons_active_mobile : styles.container__buttons_inactive_mobile
            }
            onClick={handleClick}
          >
            <VerifiedOutlined />
            Proof
          </Button>
          <Button
            name="chainpoint"
            sx={
              active === 'chainpoint'
                ? styles.container__buttons_active_mobile
                : styles.container__buttons_inactive_mobile
            }
            onClick={handleClick}
          >
            <LinkOutlined />
            Chainpoint
          </Button>
          <Button
            name="pdf"
            sx={active === 'pdf' ? styles.container__buttons_active_mobile : styles.container__buttons_inactive_mobile}
            onClick={handleClick}
          >
            <PictureAsPdfOutlined />
            PDF
          </Button>
        </Toolbar>
      </AppBar>
      <Backdrop loading={loading} />
    </Box>
  );
}

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundImage: `url(${window.location.origin === env.certikaURL ? FondoVerificar : ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container__subbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 2.5%',
    padding: '0 2% 8px 2%',
    '& p': {
      marginBottom: 0,
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  container__buttons: {
    display: { xs: 'none', sm: 'flex' },
    justifyContent: 'space-between',
    width: { xs: 500, sm: 700 },
    '& button': {
      border: 'none',
      borderRadius: 8,
      padding: { xs: '0px 0px', sm: '2px 20px' },
    },
    '& button:focus': {
      outlineStyle: 'none',
    },
  },
  container__buttons_active: {
    textTransform: 'none',
    fontSize: '18px',
    color: colors.primary,
  },
  container__buttons_active_mobile: {
    textTransform: 'none',
    fontSize: '14px',
    color: colors.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  container__buttons_inactive: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    fontSize: '18px',
    color: '#7E7E7E',
  },
  container__buttons_inactive_mobile: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    fontSize: '14px',
    color: '#7E7E7E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text_title: {
    color: '#7E7E7E',
    fontSize: '18px',
  },
  text: {
    color: '#7E7E7E',
    fontSize: '18px',
  },
  text_footer: {
    color: '#7E7E7E',
    fontSize: '16px',
  },
  container__code: {
    width: { xs: '80%', sm: '46%' },
    marginBottom: '2em',
  },
  container__footer: {
    width: { xs: '90%', sm: '50%' },
  },
  textField: {
    marginBottom: '1em',
    backgroundColor: '#fff',
    '& fieldset': {
      borderRadius: '16px',
      border: `2px solid ${colors.primary}`,
    },
  },
  button: {
    backgroundColor: colors.primary,
    textTransform: 'none',
    borderRadius: '64px',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  container_button_centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

const useStyles = makeStyles((theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderColor: colors.primary,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
    '& .MuiTypography-body1': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '1em',
    },
  },
}));

export default Verify;
