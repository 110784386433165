import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, FormControl, Button, Grid, Card, CardContent, Typography } from '@material-ui/core';
import colors from '../assets/styles/colors';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../api';
import Appbar from '../components/Appbar';
import Swal from 'sweetalert2';
import { decrypt } from '../utils/crypt';
import Backdrop from '../components/Backdrop';
import { useTranslation } from 'react-i18next';

function HelpCenter(props) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    mensaje_reporte: '',
  });
  const [reportsCarnets, setReportsCarnets] = useState([]);

  const [t] = useTranslation(['alerts', 'dialogs', 'buttons']);

  useEffect(() => {
    try {
      const type = props.match.params.type;
      const id = decrypt(props.match.params.id);
      getReports(type, id);
      setForm({ ...form, type, id });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: t('alerts:alerts.error-ocurred'),
        icon: 'error',
        confirmButtonText: 'Ok',
        iconColor: colors.primary,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReports = async (type, id) => {
    const { data } = await axios.post(`/helpCenter/getHelpCenters`, { estado: 1, type, id });
    setReportsCarnets(data.helpCenters);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.mensaje_reporte.length === 0) {
      return Swal.fire({
        title: 'Error',
        text: t('alerts:alerts.no-message'),
        icon: 'error',
        iconColor: colors.primary,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }

    setLoading(true);
    axios
      .post('/helpCenter', form)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: t('alerts:alerts.send-message-success-title'),
          text: t('alerts:alerts.report-send-success'),
          icon: 'success',
          confirmButtonText: 'Ok',
          iconColor: colors.primary,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        }).then(() => {
          history.push('/');
          window.location.reload();
        });
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          title: 'Error',
          text: t('alerts:alerts.error-ocurred'),
          icon: 'error',
          iconColor: colors.primary,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      });
  };

  return (
    <>
      <div className="App">
        <Appbar />
        <div className={`App-header ${classes.app}`}>
          <Card className={classes.card}>
            <CardContent>
              <Typography component="h4" variant="h4">
                {reportsCarnets?.length > 0 ? t('dialogs:dialog.answer-report') : t('dialogs:dialog.new-report')}
              </Typography>
              <form className={classes.root} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl className="w-100">
                      <TextField
                        label={t('dialogs:dialog.message-report')}
                        name="mensaje_reporte"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={8}
                        onChange={handleInput}
                        value={form.mensaje_reporte}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={`text-center ${classes.root}`}>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={`my-2 ${classes.button}`}
                    type="submit"
                  >
                    {t('buttons:buttons.send')}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  app: {
    minHeight: '85vh',
  },
  root: {
    marginTop: '1em',
    [theme.breakpoints.up('sm')]: {
      margin: '1em 6em 0 6em',
    },
  },
  button: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  input: {
    display: 'none',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '4em 0',
    marginTop: '0',
    marginBottom: '0',
    backgroundColor: 'primary',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
      margin: '4em',
      marginBottom: '0',
      marginTop: '0',
    },
  },
}));

export default HelpCenter;
