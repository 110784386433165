import React from 'react';
import { makeStyles } from '@material-ui/core';
import colors from '../assets/styles/colors';

function MenuCertificateItem(props) {
  const classes = useStyles();
  const { title, logo, expand = false, onClick } = props;

  return (
    <div className={classes.menuItem} onClick={onClick}>
      <img src={logo} alt="Logo menu item" />
      <p>{title}</p>
      <span>{expand ? '-' : '+'}</span>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'grid',
    gridTemplateColumns: '15% 80% 5%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    margin: 10,
    padding: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
    '& p': {
      marginBottom: 0,
      color: 'white',
      fontWeight: 'bold',
    },
    '& span': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
}));

export default MenuCertificateItem;
