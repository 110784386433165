import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Avatar,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Box,
  Typography,
} from '@material-ui/core';
import { DescriptionOutlined, CloseOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import Backdrop from '../../../components/Backdrop';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { setFormDocuments, changeBreadcrump, setDocumentsFields, setSaveDesign } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import defaultFields from '../../../utils/defaultFields';
import { env } from '../../../config/environment';
import { useTranslation } from 'react-i18next';
import TemplateDocumentDesing from './DesignDocuments';
import { DropzoneArea } from 'material-ui-dropzone';
import Pagination from '@material-ui/lab/Pagination';
import capitalize from '../../../utils/capitalize';

function CreateTemplate(props) {
  const {
    user,
    organizationId,
    formDocuments,
    returnRoute,
    setFormDocuments,
    documentsFields,
    setDocumentsFields,
    changeBreadcrump,
    saveDesign,
    setSaveDesign,
  } = props;
  const history = useHistory();
  const classes = useStyles({ formDocuments });
  const imageRef = useRef();
  const refImage = useRef([]);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDesignDocuments, setOpenDesignDocuments] = useState(false);
  const [documentsChange, setDocumentsChange] = useState(formDocuments.img_prev ? true : false);
  const isSuperUser = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1;
  const md5OrganizationRoute = md5(`certika-organization-${isSuperUser ? 1 : organizationId}`).toString();
  const md5UserRoute = md5(`certika-user-${isSuperUser ? 1 : user?.data_user?.id}`).toString();

  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'breadCrumps',
    'tables',
  ]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getOrganizations();

      const isModifying = props.match.params.id ? true : false;
      if (isModifying) {
        await getDocumentsTemplate();
        changeBreadcrump(t('breadCrumps:breadCrumps.documents-template-edit', { returnObjects: true }));
        setSaveDesign(true);
        setDocumentsChange(true);
      } else {
        setFormDocuments({
          ...formDocuments,
          id_organizaciones:
            user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
          id_usuarios: user.data_user?.id,
          change_template: 0,
          page: 1,
        });
        changeBreadcrump(t('breadCrumps:breadCrumps.documents-template-create', { returnObjects: true }));
      }
      setLoading(false);
    } catch (error) {
      //history.push('/500');
    }
  };

  const getOrganizations = async () => {
    const { data } = await axios.post(`/organization/getOrganizations/names`);
    setOrganizations(data.organizations);
  };

  const getDocumentsTemplate = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const { data } = await axios.get(`/documentTemplate/${id}`);
      const { documentTemplate, linkPhoto, linkPhotoTemplate } = data.documentTemplate;
      setDocumentsFields(
        data.documentTemplate?.documentTemplate?.documentoCampos.map((field) => ({
          id: field.id,
          fieldTypeConfig: field.nombre === '' || field.nombre === 'certificateCode' ? 51 : 52,
          idSignature: field.nombre.includes('signature') ? field.idSignature : null,
          fieldType: field.tipo_campo_vp,
          exampleText: field.texto_ejemplo,
          fieldName: field.nombre,
          align: field.formato_letra ? field.formato_letra : 'center',
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width === 0 ? 'auto' : field.width,
          height: field.height === 0 ? field.tamano_letra : field.height,
          page: field.page,
        }))
      );
      delete data.documentTemplate?.documentTemplate?.documentoCampos;
      setFormDocuments({
        ...documentTemplate,
        img_prev: linkPhoto,
        img_template: linkPhotoTemplate,
        id_usuarios: user.data_user?.id,
        estado: documentTemplate.estado,
        change_template: 0,
        pages: linkPhoto.length,
        page: 1,
        fileName: 'documento',
        naturalWidthAndHeight: refImage.current,
      });
    } catch (error) {
      history.push('/500');
    }
  };

  const handleInput = (event) => {
    setFormDocuments({
      ...formDocuments,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = async () => {
    setLoading(true);
    const infoDelete = new FormData();
    infoDelete.append('fileName', formDocuments?.fileName);
    infoDelete.append('numPages', formDocuments?.pages);
    infoDelete.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
    await axiosFile({
      method: 'post',
      url: `${env.apiURL}/file/delete-files-local`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: infoDelete,
    });
    setLoading(false);
    setFormDocuments(null);
    setDocumentsFields(null);
    history.push(returnRoute);
  };

  const validImage = async (byFormDocuments) => {
    setFormDocuments({ ...byFormDocuments });
    setDocumentsChange(true);
    setLoading(false);
    setSaveDesign(false);
  };

  const handleFile = async (e) => {
    const file = e[0];
    if (file) {
      const isPdf = file.type === 'application/pdf';
      setLoading(true);
      const Document = new FormData();
      Document.append('file', file);
      Document.append('fileName', file.name);
      Document.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
      if (!isPdf) {
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/upload-certificate`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: Document,
        })
          .then(async () => {
            const { data } = await axios.post('/file/getFile', {
              keyFile: `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`,
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            const byImage = {
              ...formDocuments,
              img_prev: [data?.url],
              nombre: file.name.split('.')[0],
              imagen: 'newImage',
              pages: 1,
              page: 1,
              fileName: file.name,
            };
            setTimeout(() => {
              validImage(byImage);
            }, 3000);
          })
          .catch(() => {
            setLoading(false);
            return Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.error-ocurred'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else if (isPdf) {
        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/upload-documents`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: Document,
        })
          .then(({ data: resInfo }) => {
            const byPdf = {
              ...formDocuments,
              img_prev: resInfo.urls,
              nombre: file.name.split('.')[0],
              imagen: '',
              pages: resInfo.numPages,
              page: 1,
              fileName: file.name,
            };
            setTimeout(() => {
              validImage(byPdf);
            }, 3000);
          })
          .catch(() => {
            setLoading(false);
            return Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.error-ocurred'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    }
  };

  const goToDesignDocuments = async () => {
    const isHorizontal = imageRef.current?.naturalWidth > imageRef.current?.naturalHeight ? true : false;

    if (
      isHorizontal &&
      (imageRef.current?.naturalWidth > 950 ||
        imageRef.current?.naturalHeight > 700 ||
        imageRef.current?.naturalWidth < 768 ||
        imageRef.current?.naturalHeight < 512)
    ) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.horizontal-documents'),
        showConfirmButton: true,
      });
    }
    if (
      !isHorizontal &&
      (imageRef.current?.naturalWidth > 668 ||
        imageRef.current?.naturalHeight > 1000 ||
        imageRef.current?.naturalWidth < 400 ||
        imageRef.current?.naturalHeight < 600)
    ) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.vertical-documents'),
        showConfirmButton: true,
      });
    }
    setFormDocuments({
      ...formDocuments,
      naturalWidthAndHeight: refImage.current,
    });
    if (!documentsFields?.length > 0) {
      const { dataQr, dataImg, dataCode, dataButtonVerify } = defaultFields();
      setDocumentsFields([
        { ...dataQr },
        { ...dataImg },
        { ...dataCode, fieldTypeConfig: 51 },
        { ...dataButtonVerify },
      ]);
    }

    const indexField = documentsFields?.findIndex((item) => item.fieldName === 'certificateCode');
    if (indexField !== undefined && indexField !== -1) {
      documentsFields[indexField].exampleText = documentsFields[indexField].exampleText.includes(':')
        ? documentsFields[indexField].exampleText.split(':')[0]
        : '';
    }

    setSaveDesign(false);
    setOpenDesignDocuments(true);
    setLoading(false);
  };

  const saveTemplate = async (e) => {
    e.preventDefault();

    if (formDocuments?.id_organizaciones === '0') {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-organization'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const haveUploadedAnImage = formDocuments?.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const documentsDesigned = documentsFields?.length > 0;
    if (!documentsDesigned) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-design-documents'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!saveDesign) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-accepted-design-documents'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    let realWidth = formDocuments.naturalWidthAndHeight[0].width;
    let realHeight = formDocuments.naturalWidthAndHeight[0].height;
    const sizes = [];
    // eslint-disable-next-line
    formDocuments.naturalWidthAndHeight.map((item) => {
      if (realWidth < item.width) {
        realWidth = item.width;
      }
      if (realHeight < item.height) {
        realHeight = item.height;
      }
      sizes.push({ width: item.width, height: item.height });
    });

    const isANewTemplate = !formDocuments.id ? true : false;
    delete formDocuments.naturalWidthAndHeight;
    setLoading(true);
    try {
      if (isANewTemplate) {
        delete formDocuments.img_template;
        await axios.post(`/documentTemplate`, {
          ...formDocuments,
          width: realWidth,
          height: realHeight,
          documentsFields,
          sizes: sizes,
        });
      } else {
        await axios.put(`/documentTemplate/${formDocuments.id}`, {
          ...formDocuments,
          width: realWidth,
          height: realHeight,
          documentsFields,
          sizes: sizes,
        });
      }
    } catch (error) {
      formDocuments.naturalWidthAndHeight = refImage.current;
      setFormDocuments(formDocuments);
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setLoading(false);
    Swal.fire({
      icon: 'success',
      iconColor: colors.primary,
      text: t('alerts:alerts.saved'),
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      if (isANewTemplate) {
        history.push('/templates/documents/edited');
      } else {
        history.push(returnRoute);
      }
      setFormDocuments(null);
      setDocumentsFields(null);
    });
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDocuments = async () => {
    setLoading(true);
    const infoDelete = new FormData();
    infoDelete.append('fileName', formDocuments?.fileName);
    infoDelete.append('numPages', formDocuments?.pages);
    infoDelete.append('route', `${md5OrganizationRoute}/documentsTemplate/documents-temp/temp`);
    await axiosFile({
      method: 'post',
      url: `${env.apiURL}/file/delete-files-local`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: infoDelete,
    });
    setLoading(false);
    setFormDocuments({
      ...formDocuments,
      img_prev: null,
      img_template: null,
      id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
      id_usuarios: user.data_user?.id,
      pages: 0,
    });
    documentsFields && setDocumentsFields(documentsFields.map((field) => ({ ...field, x: 50, y: 50 })));
    setDocumentsChange(false);
  };

  const handleChange = (event, value) => {
    setFormDocuments({
      ...formDocuments,
      page: value,
    });
  };

  const addToRef = (el) => {
    if (el && !refImage.current.includes(el)) {
      refImage.current.push(el);
    }
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={saveTemplate}>
            <Grid container spacing={2} className={classes.root}>
              {!documentsChange && (
                <Grid item xs={12}>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={['.pdf', '.png', '.jpg']}
                    dropzoneText={t('certificateBadgeTemplate:badges-certificate-form.documents-dropzoneArea')}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass={classes.dropzoneParagraph}
                    showFileNames={false}
                    showAlerts={true}
                    onChange={handleFile}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  {formDocuments.img_prev &&
                    formDocuments.img_prev.map((item) => {
                      return <Avatar imgProps={{ ref: addToRef }} src={item} style={{ display: 'none' }}></Avatar>;
                    })}
                  <Avatar
                    imgProps={{ ref: imageRef }}
                    alt="img-prev"
                    src={
                      formDocuments?.img_template
                        ? formDocuments?.pages === 1
                          ? formDocuments?.img_template
                          : formDocuments?.img_prev[formDocuments?.page - 1]
                        : formDocuments?.img_prev && formDocuments?.img_prev[formDocuments?.page - 1]
                    }
                    variant="rounded"
                    className={classes.avatar}
                    onClick={() =>
                      setOpen(
                        formDocuments?.img_prev && formDocuments?.img_prev[formDocuments?.page - 1] ? true : false
                      )
                    }
                  >
                    <DescriptionOutlined sx={{ margin: 2, fontSize: '4em' }} />
                  </Avatar>
                  <Box component="span" display="flex" justifyContent="center">
                    <Pagination
                      color="secondary"
                      count={formDocuments?.pages || 0}
                      onChange={handleChange}
                      defaultPage={1}
                      size="small"
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={2}>
                  {documentsChange && (
                    <>
                      <Grid item xs={6}>
                        <Button
                          disableElevation
                          color="primary"
                          variant="outlined"
                          component="span"
                          onClick={handleChangeDocuments}
                        >
                          {t('buttons:buttons.change-documents')}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          disableElevation
                          color="primary"
                          variant="outlined"
                          component="span"
                          onClick={goToDesignDocuments}
                        >
                          {t('buttons:buttons:design-documents')}
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label={t('certificateBadgeTemplate:badges-certificate-form.documents-public-name')}
                        name="nombre"
                        variant="outlined"
                        size="small"
                        onChange={handleInput}
                        value={formDocuments?.nombre ? formDocuments?.nombre : ''}
                        placeholder={t('certificateBadgeTemplate:badges-certificate-form.documents-public-name')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className="w-100">
                      <TextField
                        label={t('certificateBadgeTemplate:badges-certificate-form.documents-private-name')}
                        name="nombre_privado"
                        variant="outlined"
                        size="small"
                        onChange={handleInput}
                        value={formDocuments?.nombre_privado ? formDocuments?.nombre_privado : ''}
                        placeholder={t('certificateBadgeTemplate:badges-certificate-form.documents-private-name')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                      <>
                        <Typography>{t('certificateBadgeTemplate:badges-certificate-form.organization')}*</Typography>
                        <FormControl required variant="outlined" className="w-100" size="small">
                          <Select
                            id="organizacion"
                            name="id_organizaciones"
                            onChange={handleInput}
                            value={formDocuments.id_organizaciones}
                            placeholder={t('certificateBadgeTemplate:badges-certificate-form.organization')}
                          >
                            <MenuItem value="0">
                              <em>{t('selects:selects-autocompletes.select-option-documents')}</em>
                            </MenuItem>
                            {loadFillDataOrganization()}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                type="submit"
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                onClick={handleCancel}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonDialog}`}
            onClick={handleClose}
          >
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={
              formDocuments?.img_template
                ? formDocuments?.pages === 1
                  ? formDocuments?.img_template
                  : formDocuments?.img_prev[formDocuments?.page - 1]
                : formDocuments?.img_prev && formDocuments?.img_prev[formDocuments?.page - 1]
            }
            className={classes.img}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loading} />
      <Dialog open={openDesignDocuments} fullScreen TransitionComponent={Transition}>
        <TemplateDocumentDesing
          setOpenDesignDocuments={setOpenDesignDocuments}
          user={user}
          md5OrganizationRoute={md5OrganizationRoute}
          md5UserRoute={md5UserRoute}
          handleCancelGeneral={handleCancel}
          handleSubmit={saveTemplate}
        />
      </Dialog>
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  img: (props) => ({
    maxWidth: '70vw',
    height: props.imageRef?.current?.naturalHeight,
  }),
  buttonDialog: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    padding: '9px 20px',
  },
  buttonForm: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.primary,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  dropzoneParagraph: {
    color: 'gray',
    fontSize: '1.1em',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    formDocuments: state.formDocuments || [],
    documentsFields: state.documentsFields,
    returnRoute: state.returnRoute,
    saveDesign: state.saveDesign,
  };
};

const mapDispatchToProps = {
  setDocumentsFields,
  changeBreadcrump,
  setFormDocuments,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
