import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Divider, Grid, LinearProgress } from '@material-ui/core';
import colors from '../../assets/styles/colors';
import html2canvas from 'html2canvas';
import { Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  //faFacebookMessenger,
  faTelegram,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import XTwitterIcon from '../../assets/img/twitter-x-square.svg';

const useStyles = makeStyles((theme) => ({
  cortina: {
    padding: '30%',
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    marginTop: '.5em',
  },
  title: {
    marginTop: '.5em',
    textAlign: 'center',
  },
  progress: {
    height: 20,
    backgroundColor: colors.footer,
  },
  bar: {
    backgroundColor: '#000000',
  },
  barFacebook: {
    backgroundSize: '50%',
    backgroundColor: '#4064ac',
  },
  barMessenger: {
    backgroundColor: '#0080f7',
  },
  barTwitter: {
    backgroundColor: '#2f3136',
  },
  barLinkedIn: {
    backgroundColor: '#2563ac',
  },
  barWhatsApp: {
    backgroundColor: '#42e35f',
  },
  barTelegram: {
    backgroundColor: '#34b3f7',
  },
  percentage: {
    marginLeft: '.6em',
    backgroundColor: colors.footer,
    padding: 5,
  },
  icon: {
    color: '#000000',
    marginRight: '.6em',
  },
  iconFacebook: {
    color: '#4064ac',
    marginRight: '.6em',
  },
  iconMessenger: {
    color: '#0080f7',
    marginRight: '.6em',
  },
  iconLinkedin: {
    color: '#2563ac',
    marginRight: '.6em',
  },
  iconTwitter: {
    color: '#34b3f7',
    marginRight: '.6em',
    marginLeft: '-.5em',
  },
  iconWhatsapp: {
    color: '#42e35f',
    marginRight: '.6em',
  },
  iconTelegram: {
    color: '#34b3f7',
    marginRight: '.6em',
  },
  divider: {
    marginTop: '.6em',
  },
  text: {
    width: '5em',
  },
}));

function StatisticsExport(props) {
  const classes = useStyles();
  const datos = [];
  props.match.params.series.split(',').map((item) => datos.push(parseInt(item)));
  const series = [datos[5], datos[1], datos[7], datos[3], datos[23]];
  const [validaCerrar, setValidaCerar] = useState(true);
  const [t] = useTranslation(['analytics']);

  useEffect(() => {
    if (validaCerrar) {
      html2canvas(document.querySelector('#contenedor'))
        .then((canvas) => {
          let enlace = document.createElement('a');
          enlace.download = t('analytics:analytics-statistics.name-document-export-png');
          enlace.href = canvas.toDataURL();
          enlace.click();
        })
        .then(() => {
          setValidaCerar(false);
        });
    } else {
      window.close();
    }
    // eslint-disable-next-line
  }, [validaCerrar]);

  const data = {
    labels: [
      `${
        datos[22] === 0
          ? t('analytics:analytics-statistics.badges-accepted')
          : t('analytics:analytics-statistics.certificates-accepted')
      }: ${series[0]} - ${datos[6]}%`,
      `${t('analytics:analytics-statistics.pending')}: ${series[1]} - ${datos[2]}%`,
      `${
        datos[22] === 0
          ? t('analytics:analytics-statistics.badges-rejected')
          : t('analytics:analytics-statistics.certificates-rejected')
      }: ${series[2]} - ${datos[8]}%`,
      `${
        datos[22] === 0
          ? t('analytics:analytics-statistics.badges-revoked')
          : t('analytics:analytics-statistics.certificates-revoked')
      }: `,
    ],
    datasets: [
      {
        data: series,
        backgroundColor: ['#21C700', '#D3D300', '#C70000', '#ff9900'],
      },
    ],
  };

  const options = {
    animation: false,
    events: [''],
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: true,
          text: t('analytics:analytics-statistics.title-document-export'),
          color: '#000',
          font: {
            size: 30,
            weight: 'bold',
          },
          padding: {
            bottom: 30,
          },
        },
        position: 'top',
        labels: {
          color: '#000',
          font: {
            size: 18,
          },
        },
      },
    },
  };

  return (
    <>
      <div className={classes.cortina}></div>
      <div id="contenedor">
        <div className="d-flex justify-content-center">
          <Doughnut className={classes.chart} data={data} height={400} width={580} options={options} />
        </div>
        <Divider />

        <Grid container spacing={1} justify="space-evenly" className={classes.root}>
          <Grid item xs={12} lg={12}>
            <Typography component="h4" variant="h4" align="center">
              {datos[0]}
            </Typography>
            <Typography align="center" gutterBottom>
              {datos[22] === 0
                ? datos[0] !== 1
                  ? t('analytics:analytics-statistics.badges-issued')
                  : t('analytics:analytics-statistics.badge-issued')
                : datos[22] === 1
                ? datos[0] !== 1
                  ? t('analytics:analytics-statistics.certificates-issued')
                  : t('analytics:analytics-statistics.certificate-issued')
                : datos[0] !== 1
                ? t('analytics:analytics-statistics.carnets-issued')
                : t('analytics:analytics-statistics.carnet-issued')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[5]}
            </Typography>
            <Typography align="center" gutterBottom>
              {datos[22] === 0
                ? t('analytics:analytics-statistics.badges-accepted')
                : t('analytics:analytics-statistics.certificates-accepted')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[6]}%
            </Typography>
            <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.badge-certificate-accepted-porcen')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[1]}
            </Typography>
            <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.pending')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[2]}%
            </Typography>
            <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.pending')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[7]}
            </Typography>
            <Typography align="center" gutterBottom>
              {datos[22] === 0
                ? t('analytics:analytics-statistics.badges-rejected')
                : t('analytics:analytics-statistics.certificates-rejected')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[8]}%
            </Typography>
            <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.badge-certificate-jected-porcen')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[3]}
            </Typography>
            <Typography align="center" gutterBottom>
              {datos[22] === 0
                ? t('analytics:analytics-statistics.badges-revoked')
                : t('analytics:analytics-statistics.certificates-revoked')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[4]}%
            </Typography>
            <Typography align="center" gutterBottom>
              {datos[22] === 0
                ? t('analytics:analytics-statistics.badges-revoked')
                : t('analytics:analytics-statistics.certificates-revoked')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <Grid container spacing={1} justify="space-evenly" className={classes.root}>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[9]}
            </Typography>
            <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.account')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography component="h4" variant="h4" align="center">
              {datos[10]}%
            </Typography>
            <Typography align="center" gutterBottom>
              {t('analytics:analytics-statistics.account-porcen')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <div className="container">
          <Typography component="h6" variant="h6" className={classes.title}>
            {t('times-share')} {datos[11]}
          </Typography>
          {/*
        <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
          <FontAwesomeIcon
            icon={faEnvelope}
            className={classes.icon}
            size="lg"
          />
          <div className={classes.text}>Email</div>
          <LinearProgress
            className={`flex-grow-1 mx-2 ${classes.progress}`}
            classes={{
              bar: classes.bar,
            }}
            variant="determinate"
            value={32}
          />
          <div className="font-weight-bold font-size-lg">7</div>
          <div className={`font-size-lg ${classes.percentage}`}>32%</div>
        </div>
          */}

          <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
            <FontAwesomeIcon icon={faFacebookSquare} className={classes.iconFacebook} size="lg" />
            <div className={classes.text}>Facebook</div>
            <LinearProgress
              className={`flex-grow-1 mx-2 ${classes.progress}`}
              classes={{
                bar: classes.barFacebook,
              }}
              variant="determinate"
              value={((datos[17] - 0) * (100 - 50)) / (100 - 0) + 50}
            />
            <div className="font-weight-bold font-size-lg">{datos[12]}</div>
            <div className={`font-size-lg ${classes.percentage}`}>{datos[17]}%</div>
          </div>

          {/*<Divider className={classes.divider} />
      <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
        <FontAwesomeIcon
          icon={faFacebookMessenger}
          className={classes.iconMessenger}
          size="lg"
        />
        <div className={classes.text}>Messenger</div>
        <LinearProgress
          className={`flex-grow-1 mx-2 ${classes.progress}`}
          classes={{
            bar: classes.barMessenger,
          }}
          variant="determinate"
          value={32}
        />
        <div className="font-weight-bold font-size-lg">7</div>
        <div className={`font-size-lg ${classes.percentage}`}>32%</div>
      </div>
          */}

          <Divider className={classes.divider} />
          <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
            <FontAwesomeIcon icon={faLinkedin} className={classes.iconLinkedin} size="lg" />
            <div className={classes.text}>LinkedIn</div>
            <LinearProgress
              className={`flex-grow-1 mx-2 ${classes.progress}`}
              classes={{
                bar: classes.barLinkedIn,
              }}
              variant="determinate"
              value={((datos[18] - 0) * (100 - 50)) / (100 - 0) + 50}
            />
            <div className="font-weight-bold font-size-lg">{datos[13]}</div>
            <div className={`font-size-lg ${classes.percentage}`}>{datos[18]}%</div>
          </div>

          <Divider className={classes.divider} />
          <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
            <img src={XTwitterIcon} alt="XTwitter" height={23} />
            <div className={classes.text}>Twitter</div>
            <LinearProgress
              className={`flex-grow-1 mx-2 ${classes.progress}`}
              classes={{
                bar: classes.barTwitter,
              }}
              variant="determinate"
              value={((datos[19] - 0) * (100 - 50)) / (100 - 0) + 50}
            />
            <div className="font-weight-bold font-size-lg">{datos[14]}</div>
            <div className={`font-size-lg ${classes.percentage}`}>{datos[19]}%</div>
          </div>

          <Divider className={classes.divider} />
          <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
            <FontAwesomeIcon icon={faWhatsapp} className={classes.iconWhatsapp} size="lg" />
            <div className={classes.text}>WhatsApp</div>
            <LinearProgress
              className={`flex-grow-1 mx-2 ${classes.progress}`}
              classes={{
                bar: classes.barWhatsApp,
              }}
              variant="determinate"
              value={((datos[20] - 0) * (100 - 50)) / (100 - 0) + 50}
            />
            <div className="font-weight-bold font-size-lg">{datos[15]}</div>
            <div className={`font-size-lg ${classes.percentage}`}>{datos[20]}%</div>
          </div>

          <Divider className={classes.divider} />
          <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
            <FontAwesomeIcon icon={faTelegram} className={classes.iconTelegram} size="lg" />
            <div className={classes.text}>Telegram</div>
            <LinearProgress
              className={`flex-grow-1 mx-2 ${classes.progress}`}
              classes={{
                bar: classes.barTelegram,
              }}
              variant="determinate"
              value={((datos[21] - 0) * (100 - 50)) / (100 - 0) + 50}
            />
            <div className="font-weight-bold font-size-lg">{datos[16]}</div>
            <div className={`font-size-lg ${classes.percentage}`}>{datos[21]}%</div>
          </div>
          <Divider className={classes.divider} />
          <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`} />
        </div>
      </div>
    </>
  );
}

export default StatisticsExport;
