import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  FindInPageOutlined,
  ContentCopyOutlined,
  DeleteForeverOutlined,
  VisibilityOutlined,
  LaunchOutlined,
} from '@mui/icons-material';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import { changePage } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Search from '../../../components/SearchBar';
import Table from '../../../components/Table';
import Template from '../Templates';
import SkeletonTables from '../../../components/SkeletonTable';
import axios from '../../../api';
import PreviewDialog from '../../../components/PreviewDialog';
import BadgeCard from '../../../components/BadgeCard';
import Swal from 'sweetalert2';
import EditIcon from '../../../assets/static/icons/EditIcon';
import colors from '../../../assets/styles/colors';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
  buttonForm: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  iconButton: {
    margin: '0em -.4em',
  },
}));

function Published(props) {
  const { page, rowsPerPage, changeBreadcrump, user, organizationId, permission, changePage, searchValue } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [templatesBadges, setTemplateBadges] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [filtro, setFiltradas] = useState(templatesBadges);
  const [vista, setVista] = useState({});
  const [level, setLevel] = useState({});
  const [time, setTime] = useState({});
  const [cost, setCost] = useState({});
  const [open, setOpen] = useState(false);
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [countItems, setCountItems] = useState();

  /* translation */
  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'dialogs', 'breadCrumps']);
  const classes = useStyles();

  useEffect(() => {
    changePage(0);
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    changeBreadcrump(breadCrumps());
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    if (!searchValue || searchValue === '') {
      await getTemplatesBadges();
    }
    setLoading(false);
    await getRecommendations();
    await getLevel();
    await getCosts();
    await getTime();
  };

  const breadCrumps = () => {
    return t('breadCrumps:breadCrumps.badge-edition', { returnObjects: true });
  };

  const getLevel = async () => {
    const { data } = await axios.post(`/others/getLevels`);
    setLevel(data.levels);
  };

  const getCosts = async () => {
    const { data } = await axios.post(`/others/getCosts`);
    setCost(data.costs);
  };

  const getTime = async () => {
    const { data } = await axios.post(`/others/getTime`);
    setTime(data.time);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;
  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;
  const isOrganizationsRelations =
    user?.data_user?.gruposUsuariosOrganizaciones[0]?.organizaciones?.organizacionesRelaciones.length > 0
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    if (isDeparmentCreater || isDeparmentCreaterRoll) params.id_usuarios = user.data_user.id;

    return params;
  };

  const getTemplatesBadges = async () => {
    const params = getParams(0, rowsPerPage);
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesEdited`, params);

    setTemplateBadges(data.templatesBadges?.rows);
    setFiltradas(data.templatesBadges?.rows);
    setCountItems(data.templatesBadges?.count);
  };

  const getRecommendations = async () => {
    if (isSuper) {
      const { data } = await axios.post(`/templateBadge/getTemplatesBadges`);
      setRecommendations(data.templatesBadges);
    } else {
      const { data } = await axios.post(`/templateBadge/getTemplatesBadges`, { id_organizaciones: organizationId });
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        setRecommendations(
          data.templatesBadges.filter(
            (templateBadge) => templateBadge.plantillasInsigniasHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setRecommendations(data.templatesBadges);
      }
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesEdited`, params);

    const array = [
      ...templatesBadges,
      ...data.templatesBadges?.rows.filter((row) => !templatesBadges.find((item) => item.id === row.id)),
    ];

    setTemplateBadges(array);
    setFiltradas(array);
    setLoading(false);
  };

  const linkphoto = (idPlantilla) => {
    let photo = null;
    // eslint-disable-next-line
    recommendations.map((datos) => {
      if (datos.id === idPlantilla) {
        photo = datos.linkPhoto;
      }
    });
    return photo;
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'copy':
        modalCopy(e, id);
        break;
      case 'edit':
        history.push(`/templates/edit/${encrypt(id)}`);
        break;
      case 'publish':
        modalPublish(id);
        break;
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalCopy = (e, id) => {
    Swal.fire({
      text: t('alerts:alerts.copy-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendCopy(id, e);
      }
    });
  };

  const modalPublish = (id) => {
    Swal.fire({
      text: t('alerts:alerts.publish-button'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendPublish(id);
      }
    });
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.delete-button'),
      icon: 'warning',
      showCancelButton: true,
      iconColor: colors.primary,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendCopy = async (id, e) => {
    const template = templatesBadges?.filter((template) => template.id === id);
    const templateCopy = {
      ...template[0],
      nombre: `${template[0]?.nombre} copia`,
      nombre_privado: `${template[0]?.nombre_privado} copia`,
      id_plantillas_recomendadas: template[0]?.recomendaciones.map((item) => ({
        id_plantillas_recomendadas: item.id_plantillas_recomendadas,
      })),
      id_habilidades: template[0]?.plantillasHabilidades.map((item) => ({
        id_habilidades: item.id_habilidades,
      })),
      id_usuarios: user.data_user?.id,
      id_organizaciones: organizationId,
    };

    try {
      const { data } = await axios.post(`/templateHability/`, templateCopy);
      if (data) {
        loadData();
        Swal.fire({
          icon: 'success',
          iconColor: colors.primary,
          text: t('alerts:alerts.copied'),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.copied-error'),
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const sendPublish = async (id) => {
    const { data } = await axios
      .put(`/templateBadge/${id}`, {
        estado: 1,
        id_usuarios: user.data_user?.id,
        fecha_ultima_modificacion: Date.now(),
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.published'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/templates/published');
      });
    }
  };

  const sendDelete = async (id) => {
    const { data } = await axios
      .delete(`/templateBadge/${id}`, {
        data: { id_usuarios: user.data_user?.id },
      })
      .catch(() => {
        history.push('/500');
        window.location.reload();
      });
    if (data) {
      Swal.fire({
        text: t('alerts:alerts.deleted'),
        icon: 'success',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadData();
      });
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.edit')}>
          <IconButton aria-label="edit" onClick={(e) => handleClick(e, id, 'edit')} className={classes.iconButton}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const copy_permission = (id) => {
    if (permission.includes('Copiar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.copy')}>
          <IconButton aria-label="copy" onClick={(e) => handleClick(e, id, 'copy')} className={classes.iconButton}>
            <ContentCopyOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const publish_permission = (id) => {
    if (permission.includes('Publicar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.publish')}>
          <IconButton
            aria-label="publish"
            onClick={(e) => handleClick(e, id, 'publish')}
            className={classes.iconButton}
          >
            <LaunchOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <Tooltip title={t('tables:table.tooltip.delete')}>
          <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')} className={classes.iconButton}>
            <DeleteForeverOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const handleOpenDialog = (id) => {
    const template = templatesBadges?.filter((template) => template.id === id);
    const niveles = level.filter((item) => item.id === template[0].nivel_vp);
    const tiempos = time.filter((item) => item.id === template[0].tiempo_vp);
    const costos = cost.filter((item) => item.id === template[0].costo_vp);
    setVista({
      ...template[0],
      id_habilidades: template[0]?.plantillasHabilidades.map((item) => ({
        id_habilidades: item.id_habilidades,
        descripcion: item.habilidades.descripcion,
      })),
      id_plantillas_recomendadas: template[0]?.recomendaciones,
      criterios: template[0]?.criterios,
      img_prev: template[0]?.linkPhoto,
      niveles: niveles[0]?.valor_parametro,
      tiempos: tiempos[0]?.valor_parametro,
      costos: costos[0]?.valor_parametro,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const organizationColumn = () => {
    if (isSuper || isOrganizationsRelations) {
      return t('tables:table.columns-edited-super', { returnObjects: true });
    } else {
      return t('tables:table.columns-edited', { returnObjects: true });
    }
  };

  const organizationName = (row) => {
    if (isSuper) {
      return <TableCell align="center">{row.organizaciones.nombre}</TableCell>;
    } else if (row.organizacionesHijas) {
      return <TableCell align="center">{row.organizacionesHijas.nombre}</TableCell>;
    }
  };

  return (
    <Template Index={1}>
      <Search
        tableName="templateBadge"
        type="edited"
        items={templatesBadges}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
        deparmentCreater={{ isDeparmentCreater, isDeparmentCreaterRoll, userId: user.data_user.id }}
        setLoading={setLoading}
      />
      <Table
        columns={organizationColumn()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {filtro.length > 0 ? (
            loading ? (
              <>
                <SkeletonTables columns={organizationColumn()} photo={1} />
              </>
            ) : (
              <>
                {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <Avatar variant="rounded" alt="Badges" src={row.linkPhoto} className={classes.large} />
                      </TableCell>
                      <TableCell align="center">{row.nombre}</TableCell>
                      <TableCell align="center">{row.nombre_privado}</TableCell>
                      {organizationName(row)}
                      <TableCell align="center">
                        {moment(row.fecha_ultima_modificacion).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell align="center">{row.estado === 0 ? t('tables:table.state.edited') : ''}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.details')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={() => history.push(`/templates/edited/details/${encrypt(row.id)}`)}
                            className={classes.iconButton}
                          >
                            <FindInPageOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{edit_permission(row.id)}</TableCell>
                      <TableCell align="center">{publish_permission(row.id)}</TableCell>
                      <TableCell align="center">{copy_permission(row.id)}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tables:table.tooltip.preview')}>
                          <IconButton
                            aria-label="detalles"
                            onClick={(e) => handleOpenDialog(row.id)}
                            className={classes.iconButton}
                          >
                            <VisibilityOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{delete_permission(row.id)}</TableCell>
                    </TableRow>
                  ))}
              </>
            )
          ) : loading ? (
            <SkeletonTables columns={organizationColumn()} photo={1} />
          ) : (
            <TableCell align="center" colSpan="6">
              {t('tables:table.empty-table')}
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle align="center" onClose={handleClose}>
          {t('dialogs:dialog.preview-title')}
        </DialogTitle>
        <DialogContent dividers>
          <PreviewDialog vista={vista}>
            {vista?.id_plantillas_recomendadas?.map((recommendation, index) => (
              <BadgeCard
                key={`badge${index}`}
                badgeImage={linkphoto(recommendation.id_plantillas_recomendadas)}
                badgeName={recommendation.plantillasInsigniasRecomendadas.nombre}
                organizationName={vista.organizaciones?.nombre}
                publicBadge={false}
                templateBadge={true}
              />
            ))}
          </PreviewDialog>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={handleClose}
          >
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Template>
  );
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'plantillas')
      .map((item) => item.acciones.nombre),
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
