import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { changeBreadcrump } from '../../../actions';
import { useHistory } from 'react-router-dom';
import {
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { decrypt } from '../../../utils/crypt';
import ModuleHeader from '../../../components/ModuleHeader';
import Backdrop from '../../../components/Backdrop';
import axios from '../../../api';
import Swal from 'sweetalert2';
import colors from '../../../assets/styles/colors';

function FormReminders(props) {
  const { changeBreadcrump } = props;
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation(['analytics', 'alerts', 'buttons']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [form, setForm] = useState({
    tipo_recordatorio: '',
    primer_recordatorio: '',
    segundo_recordatorio: '',
  });

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    changeBreadcrump([{ name: 'Ajustes' }, { name: 'Recordatorios', route: '/reminders' }, { name: 'Gestionar' }]);
  };

  useEffect(() => {
    getReminderParameterByType(form.tipo_recordatorio).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, [form.tipo_recordatorio]);

  const getReminderParameterByType = async (tipo_recordatorio) => {
    const { data } = await axios.post(`/reminderParameter/getReminders`, { tipo_recordatorio });
    if (data.reminders.length > 0) {
      setForm({
        ...form,
        ...data.reminders[0],
      });
    } else {
      setForm({
        tipo_recordatorio: tipo_recordatorio,
        primer_recordatorio: '',
        segundo_recordatorio: '',
      });
    }
  };

  const handleInput = (event) => {
    const num = /^([1-9])$/;

    if (event.target.name === 'primer_recordatorio' || 'segundo_recordatorio') {
      if (num.test(event.target.value)) {
        setError({ ...error, [event.target.name]: false });
      } else {
        setError({ ...error, [event.target.name]: true });
      }
    }

    setForm({
      ...form,
      [event.target.name]: parseInt(event.target.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (form?.primer_recordatorio >= form?.segundo_recordatorio) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: 'Los días del primer recordatorio debe ser menor que el segundo.',
      });
    } else {
      if (!props.match.params.id && !form.id) {
        axios
          .post(`/reminderParameter/`, form)
          .then((res) => {
            setLoading(false);
            history.push('/reminders');
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: 'Creado exitosamente.',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: 'No se ha podido crear.',
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        const id = form.id || decrypt(props.match.params.id);
        axios
          .put(`/reminderParameter/${id}`, form)
          .then((res) => {
            setLoading(false);
            history.push('/reminders');
            Swal.fire({
              icon: 'success',
              iconColor: colors.primary,
              text: 'Editado exitosamente.',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: 'No se ha podido editar.',
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    }
  };
  return (
    <>
      <ModuleHeader create={'false'} />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel component="legend">Tipo de recordatorio</FormLabel>
                <RadioGroup
                  row
                  aria-label="tipo_recordatorio"
                  name="tipo_recordatorio"
                  value={form.tipo_recordatorio}
                  onChange={handleInput}
                >
                  <FormControlLabel
                    required
                    value={0}
                    control={<Radio required />}
                    label={t('analytics:analytics-index.radio-buttons-badges')}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio required />}
                    label={t('analytics:analytics-index.radio-buttons-certificates')}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio required />}
                    label={t('analytics:analytics-index.radio-buttons-carnets')}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Primer recordatorio (# días)"
                    variant="outlined"
                    name="primer_recordatorio"
                    value={form.primer_recordatorio}
                    onChange={handleInput}
                    error={error.primer_recordatorio}
                    helperText={error.primer_recordatorio && 'Solo se permite un carácter numérico.'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Segundo recordatorio (# días)"
                    variant="outlined"
                    name="segundo_recordatorio"
                    value={form.segundo_recordatorio}
                    onChange={handleInput}
                    error={error.segundo_recordatorio}
                    helperText={error.segundo_recordatorio && 'Solo se permite un carácter numérico.'}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={`text-center ${classes.root}`}>
              <Button
                disableElevation
                color="primary"
                disabled={error.primer_recordatorio || error.segundo_recordatorio}
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                Guardar
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={() => history.push('/reminders')}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    margin: '0.5em',
    padding: '8px 40px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormReminders);
