import 'date-fns';
import React, { useState, useEffect } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import ModuleHeader from '../../../components/ModuleHeader';
import {
  makeStyles,
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Select,
  MenuItem,
  CardActions,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Brightness5Outlined, CloseOutlined } from '@mui/icons-material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { changeBreadcrump } from '../../../actions';
import Backdrop from '../../../components/Backdrop';
import PreviewDialog from '../../../components/PreviewDialog';
import BadgeCard from '../../../components/BadgeCard';
import { decrypt } from '../../../utils/crypt';
import { env } from '../../../config/environment';
import { useTranslation } from 'react-i18next';
import capitalize from '../../../utils/capitalize';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  button: {
    //color: colors.primary,
    //borderRadius: '50px',
  },
  buttonForm: {
    padding: '7px 40px',
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  card: {
    backgroundColor: colors.background,
  },
  fieldCard: {
    backgroundColor: '#ffffff',
  },
  contentInput: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      alignItems: 'left',
    },
  },
  text: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  autocomplete: {
    width: 590,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    marginBottom: 10,
  },
  skills: {
    whiteSpace: 'normal',
  },
  chip: {
    height: '100%',
    marginBottom: '0.5em',
  },
}));

function CreateTemplate(props) {
  const { user, organizationId, changeBreadcrump } = props;
  const history = useHistory();
  const classes = useStyles();
  const [activity, setActivity] = useState([{ index: 1 }]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [costs, setCosts] = useState([]);
  const [time, setTime] = useState([]);
  const [typeCriteria, setTypeCriteria] = useState([]);
  const [habilities, setHabilities] = useState([]);
  const [templatesBadges, setTemplatesBages] = useState([]);
  const [organizations, setOrganization] = useState([]);
  const [badgesIssued, setBadgesIssued] = useState([]);
  const [form, setForm] = useState({
    imagen: null,
    nombre: '',
    nombre_privado: '',
    descripcion: '',
    tipo_vp: '',
    nivel_vp: '',
    tiempo_vp: null,
    cantidad_tiempo: null,
    costo_vp: '',
    id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
    id_plantillas_recomendadas: [],
    url: '',
    img_prev: '',
    id_usuarios: user.data_user?.id,
    estado: '',
    id_organizacion_hija: null,
  });
  const [vista, setVista] = useState({
    imagen: null,
    nombre: '',
    nombre_privado: '',
    descripcion: '',
    tipo_vp: '',
    nivel_vp: '',
    tiempo_vp: null,
    cantidad_tiempo: null,
    costo_vp: '',
    id_organizaciones: user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
    id_plantillas_recomendadas: [],
    url: '',
    img_prev: '',
    id_usuarios: user.data_user?.id,
    estado: '',
    id_organizacion_hija: null,
  });
  const [skills, setSkills] = useState([]);
  const [skillValue, setSkillValue] = useState({});
  const patron = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  );

  /* translation */
  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons', 'dialogs', 'selects', 'breadCrumps']);

  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  useEffect(() => {
    loadData().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await getHabilities();
    await getTypes();
    await getLeves();
    await getCosts();
    await getTime();
    await getTypeCriteria();
    await getOrganizations();
    if (props.match.params.id) {
      await getTemplateBadge();
      changeBreadcrump(t('breadCrumps:breadCrumps.badge-template-edit', { returnObjects: true }));
    } else {
      changeBreadcrump(t('breadCrumps:breadCrumps.badge-template-create', { returnObjects: true }));
    }
    setLoading(false);
  };

  useEffect(() => {
    getTemplatesBadges(form.id_organizaciones).catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.id_organizaciones]);

  const getHabilities = async () => {
    const { data } = await axios.post(`/hability/getHabilities`);
    setHabilities(data.habilities.rows);
  };

  const getTypes = async () => {
    const { data } = await axios.post(`/others/getTypes`);
    setTypes(data.types);
  };

  const getLeves = async () => {
    const { data } = await axios.post(`/others/getLevels`);
    setLevels(data.levels);
  };

  const getCosts = async () => {
    const { data } = await axios.post(`/others/getCosts`);
    setCosts(data.costs);
  };

  const getTime = async () => {
    const { data } = await axios.post(`/others/getTime`);
    setTime(data.time);
  };

  const getTypeCriteria = async () => {
    const { data } = await axios.post(`/others/getTypeCriteria`);
    setTypeCriteria(data.typeCriteria);
  };

  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;
  const isDeparmentCreaterRoll =
    user.data_user.gruposUsuariosOrganizaciones[0].gruposUsuarios?.rol === 6 ? true : false;

  const isSuper = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ? true : false;

  //aqui
  const getTemplatesBadges = async (id_organizaciones) => {
    const { data } = await axios.post(`/templateBadge/getTemplatesBadgesPublished/names`, { id_organizaciones });
    if (!props.match.params.id) {
      if (isDeparmentCreater || isDeparmentCreaterRoll) {
        setTemplatesBages(
          data.templatesBadges.filter(
            (templateBadge) => templateBadge.plantillasInsigniasHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setTemplatesBages(data.templatesBadges);
      }
    } else {
      const id = parseInt(decrypt(props.match.params.id), 10);
      if (isDeparmentCreaterRoll) {
        setTemplatesBages(
          data.templatesBadges.filter(
            (templatesBadges) =>
              templatesBadges.id !== id &&
              templatesBadges.plantillasInsigniasHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setTemplatesBages(data.templatesBadges.filter((templatesBadges) => templatesBadges.id !== id));
      }
    }
  };

  const getOrganizations = async () => {
    if (isSuper) {
      const { data } = await axios.post(`/organization/getOrganizations`);
      setOrganization(data.organizations.rows);
    } else {
      const { data } = await axios.get(`/organization/${organizationId}`);
      setOrganization(data.organization.organizations);
    }
  };

  const getTemplateBadge = async () => {
    const id = decrypt(props.match.params.id);
    const { data } = await axios.get(`/templateHability/${id}`);
    const { templateBadge, criterion, templateHability, linkPhoto } = data.templateHability;
    setBadgesIssued(data.templateHability?.templateBadge?.insigniasEmitidas);
    setForm({
      ...templateBadge,
      id_plantillas_recomendadas: data.templateHability?.recommendation.map((item) => ({
        id: item.id,
        linkphoto: item.linkPhoto,
        id_plantillas_recomendadas: item.id_plantillas_recomendadas,
        id_plantillas: item.id_plantillas,
        nombre: item.plantillasInsigniasRecomendadas.nombre,
        nombre_privado: item.plantillasInsigniasRecomendadas.nombre_privado,
      })),
      imagen: linkPhoto,
      img_prev: linkPhoto,
      id_usuarios: user.data_user?.id,
      estado: templateBadge.estado,
    });

    setActivity([
      ...criterion.map((item, index) => ({
        index,
        id: item.id,
        tipo_criterio_vp: item.tipo_criterio_vp,
        descripcion_criterio: item.descripcion_criterio,
        link: item.link,
      })),
    ]);

    setSkills([
      ...templateHability.map((item, index) => ({
        index,
        id: item.id,
        id_habilidades: item.id_habilidades,
        id_plantillas: item.id_plantillas,
        descripcion: item.habilidades.descripcion,
      })),
    ]);
  };

  const nombrePrivado = () => {
    if (badgesIssued !== 0 && props.match.params.id) {
      return true;
    } else {
      return false;
    }
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const onlyNumber = (event) => {
    const value = String(event.target.value);
    // eslint-disable-next-line no-useless-escape
    const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;

    if (!patron.test(value)) {
      event.target.value = value.substr(value.length);
    }
  };

  const handleInputCriteria = (event, index) => {
    activity.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setActivity([...activity]);
  };

  const handleRecommendation = (event, values) => {
    setForm({
      ...form,
      id_plantillas_recomendadas: values,
    });
  };

  const handleCancel = () => {
    if (!props.match.params.id) {
      history.push('/templates/published');
    } else {
      if (form.estado === 0) {
        history.push('/templates/edited');
      } else {
        history.push('/templates/published');
      }
    }
  };

  const handleFile = (e) => {
    const imagen = e.target.files[0];
    setForm({
      ...form,
      imagen: imagen,
      img_prev: URL.createObjectURL(imagen),
    });
  };

  const addActivity = () => {
    const last = activity[activity.length - 1];
    setActivity([...activity, { index: last.index + 1 }]);
  };

  const deleteActivity = (e, index) => {
    const criterionDeleted = activity.filter((item) => item.index !== index);
    setActivity(criterionDeleted);
  };

  const validadLink = (obj) => {
    if ('link' in obj) {
      if (!patron.test(obj.link) && obj.link !== '' && obj.link !== null) {
        if (obj.link?.length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  const privateName = (template) => {
    return template.nombre_privado !== '' && template.nombre_privado !== null
      ? `${template.nombre} (${template.nombre_privado})`
      : template.nombre;
  };

  const linkCriterio = activity.filter(validadLink);

  const cantidadTiempo = (cantidad) => {
    if (cantidad === '' || cantidad < 1) {
      form.cantidad_tiempo = null;
      return form.cantidad_tiempo;
    } else {
      return cantidad;
    }
  };

  const requiredCantidadTiempo = (cantidad) => {
    if (cantidad > 1) {
      return false;
    } else {
      form.cantidad_tiempo = null;
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (skills <= 0) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.skill-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (form.imagen === null) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.img-alert'),
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        if ((!patron.test(form.url) && form.url !== '') || linkCriterio.length > 0) {
          if (!patron.test(form.url) && form.url !== '') {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.url-alert'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (linkCriterio.length > 0) {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              iconColor: colors.primary,
              text: t('alerts:alerts.url-criteria-alert'),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          if (form.url !== '') {
            if (!form.url.includes('https://') && !form.url.includes('http://')) {
              form.url = `https://${form.url}`;
            }
          }

          activity.map((item) => {
            if (item.link !== '' && item.link !== null && item.link !== undefined) {
              if (!item.link.includes('https://') && !item.link.includes('http://')) {
                item.link = `https://${item.link}`;
              }
            }
            return item;
          });

          if (!props.match.params.id) {
            const formPhoto = new FormData();
            formPhoto.append('file', form.imagen);

            axios
              .post(`/templateHability/`, {
                ...form,
                imagen: form.imagen.name,
                criterios: activity,
                id_habilidades: skills,
              })
              .then((res) => {
                const md5OrganizationRoute = md5(`certika-organization-${organizationId}`).toString();
                const md5BadgeRoute = md5(`certika-badge-${res.data.templateHabilityId}`).toString();
                axiosFile({
                  method: 'post',
                  url: `${env.apiURL}/file/upload-badge/${md5OrganizationRoute}/${md5BadgeRoute}`,
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  data: formPhoto,
                }).then((res) => {
                  setLoading(false);
                  history.push('/templates/edited');
                  Swal.fire({
                    icon: 'success',
                    iconColor: colors.primary,
                    text: t('alerts:alerts.created'),
                    showConfirmButton: false,
                    timer: 1500,
                  });
                });
              })
              .catch((error) => {
                setLoading(false);
                Swal.fire({
                  icon: 'error',
                  iconColor: colors.primary,
                  text: t('alerts:alerts.created-error'),
                  showConfirmButton: false,
                  timer: 1500,
                });
                history.push('/500');
                window.location.reload();
              });
          } else {
            const id = decrypt(props.match.params.id);
            const formPhoto = new FormData();
            formPhoto.append('file', form.imagen);
            axios
              .put(`/templateHability/${id}`, {
                ...form,
                imagen: form.imagen.name,
                criterios: activity,
                id_habilidades: skills,
              })
              .then((res) => {
                const md5OrganizationRoute = md5(`certika-organization-${organizationId}`).toString();
                const md5BadgeRoute = md5(`certika-badge-${id}`).toString();
                if (form.imagen.name) {
                  axiosFile({
                    method: 'post',
                    url: `${env.apiURL}/file/upload-badge/${md5OrganizationRoute}/${md5BadgeRoute}`,
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    data: formPhoto,
                  }).then((res) => {
                    if (form.estado === 0) {
                      setLoading(false);
                      history.push('/templates/edited');
                      Swal.fire({
                        icon: 'success',
                        iconColor: colors.primary,
                        text: t('alerts:alerts.edited'),
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      setLoading(false);
                      history.push('/templates/published');
                      Swal.fire({
                        icon: 'success',
                        iconColor: colors.primary,
                        text: t('alerts:alerts.edited'),
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                } else {
                  if (form.estado === 0) {
                    setLoading(false);
                    history.push('/templates/edited');
                    Swal.fire({
                      icon: 'success',
                      iconColor: colors.primary,
                      text: t('alerts:alerts.edited'),
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    setLoading(false);
                    history.push('/templates/published');
                    Swal.fire({
                      icon: 'success',
                      iconColor: colors.primary,
                      text: t('alerts:alerts.edited'),
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                }
              })
              .catch((error) => {
                setLoading(false);
                Swal.fire({
                  icon: 'error',
                  iconColor: colors.primary,
                  text: t('alerts:alerts.edited-error'),
                  showConfirmButton: false,
                  timer: 1500,
                });
                history.push('/500');
                window.location.reload();
              });
          }
        }
      }
    }
  };

  const loadFillDataTypes = () => {
    return types.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataLevels = () => {
    return levels.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataCosts = () => {
    return costs.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataTime = () => {
    return time.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataTypeCriteria = () => {
    return typeCriteria.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const loadFillDataOrganizationsRelations = () => {
    const arrayOrganizations = organizations?.organizacionesRelaciones.map((data) => data.organizacionesRelacionadas);
    arrayOrganizations.push(organizations);
    return arrayOrganizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleOpenDialog = (form) => {
    const tipos = types.filter((tipo) => tipo.id === form.tipo_vp);
    const costos = costs.filter((costo) => costo.id === form.costo_vp);
    const tiempos = form.tiempo_vp ? time.filter((tiempo) => tiempo.id === form.tiempo_vp) : '';
    const niveles = levels.filter((nivel) => nivel.id === form.nivel_vp);
    const organizacion = organizations.id;
    if (form.id) {
      setVista({
        ...form,
        tipos: languajeEn ? tipos[0]?.valor_parametro_en : tipos[0]?.valor_parametro,
        costos: languajeEn ? costos[0]?.valor_parametro_en : costos[0]?.valor_parametro,
        tiempos: languajeEn ? tiempos[0]?.valor_parametro_en : tiempos[0]?.valor_parametro,
        niveles: languajeEn ? niveles[0]?.valor_parametro_en : niveles[0]?.valor_parametro,
        criterios: activity,
        id_habilidades: skills.map((item) => ({
          descripcion: item.descripcion || item,
        })),
      });
    } else {
      setVista({
        ...form,
        tipos: languajeEn ? tipos[0]?.valor_parametro_en : tipos[0]?.valor_parametro,
        costos: languajeEn ? costos[0]?.valor_parametro_en : costos[0]?.valor_parametro,
        tiempos: languajeEn ? tiempos[0]?.valor_parametro_en : tiempos[0]?.valor_parametro,
        niveles: languajeEn ? niveles[0]?.valor_parametro_en : niveles[0]?.valor_parametro,
        imagen: '',
        criterios: activity,
        id_habilidades: skills.map((item) => ({
          descripcion: item.descripcion || item,
        })),
        fecha_creacion: Date.now(),
        organizaciones: {
          nombre: organizacion.nombre,
        },
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <Backdrop loading={loading} />;
  }

  const handleChip = (skill, index) => {
    if (typeof skill === 'string') {
      setOpenEdit(true);
      setSkillValue({ value: skill, index: index });
    }
  };

  const editSkill = () => {
    skills.splice(skillValue.index, 1, skillValue.value);
    setOpenEdit(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSkill = (e, newSkill) => {
    setSkills(newSkill);
    setHabilities(habilities.filter((item) => item.descripcion.trim() !== e.target.innerText));
  };

  const deleteSkill = (hability) => {
    setSkills(skills.filter((item) => item !== hability));
    if (hability.id_habilidades) {
      setHabilities([...habilities, hability]);
    }
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography component="h6" variant="h6">
              {t('certificateBadgeTemplate:badges-certificate-form.details-badge')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Avatar alt="img-prev" src={form?.img_prev} variant="rounded" className={classes.avatar}>
                    <Brightness5Outlined sx={{ margin: 3, fontSize: '4em' }} />
                  </Avatar>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="body2" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.badge-img')}
                </Typography>

                <div className={classes.contentInput}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    name="imagen"
                    type="file"
                    onChange={(e) => handleFile(e)}
                    disabled={nombrePrivado()}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      className={classes.button}
                      disabled={nombrePrivado()}
                    >
                      {t('buttons:buttons.upload-image')}
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.badge-public-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    required
                    label=""
                    name="nombre"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={form?.nombre}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.badge-private-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label=""
                    name="nombre_privado"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={form?.nombre_privado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.description')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    required
                    label=""
                    name="descripcion"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    value={form?.descripcion}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.additional')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    label=""
                    name="url"
                    variant="outlined"
                    size="small"
                    placeholder="http://"
                    onChange={handleInput}
                    value={form?.url}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.skills')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <Autocomplete
                    disabled={nombrePrivado()}
                    multiple
                    disableClearable
                    freeSolo
                    id="tags-filled"
                    className={classes.autocomplete}
                    value={skills}
                    onChange={(e, newSkill) => handleSkill(e, newSkill)}
                    options={habilities
                      .sort((a, b) => (a.descripcion.trim() < b.descripcion.trim() ? -1 : 1))
                      .map((skill) => ({ id_habilidades: skill.id, descripcion: skill.descripcion }))}
                    getOptionLabel={(option) => option.descripcion}
                    renderTags={(value, getTagProps) => setSkills(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        variant="outlined"
                        label={t('selects:selects-autocompletes.skill-select')}
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
                {skills.length > 0 ? (
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      {skills.map((hability, index) => (
                        <Grid>
                          <Chip
                            disabled={nombrePrivado()}
                            key={index}
                            onDelete={() => deleteSkill(hability)}
                            onClick={() => handleChip(hability, index)}
                            variant="outlined"
                            label={
                              <Typography className={classes.skills}>
                                {typeof hability === 'string' ? hability : hability.descripcion}
                              </Typography>
                            }
                            className={classes.chip}
                          />
                        </Grid>
                      ))}
                    </CardContent>
                  </Card>
                ) : (
                  ''
                )}
              </Grid>
              {isSuper && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={nombrePrivado()}
                        id="organizacion"
                        name="id_organizaciones"
                        onChange={handleInput}
                        value={form.id_organizaciones}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganization()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {organizations?.organizacionesRelaciones?.length > 0 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={nombrePrivado()}
                        id="organizacion"
                        name="id_organizacion_hija"
                        onChange={handleInput}
                        value={form.id_organizacion_hija}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganizationsRelations()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider className={classes.root} />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.criteria-title')}
            </Typography>
            {activity.map((item, index) => (
              <Grid container key={item.id} spacing={2} className={classes.root}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1" className={classes.text} align="right">
                    {`${t('certificateBadgeTemplate:badges-certificate-form.criterion')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <Typography variant="body1" align="left">
                        {t('certificateBadgeTemplate:badges-certificate-form.type')}*
                      </Typography>
                      <FormControl required variant="outlined" className={`w-100 ${classes.fieldCard}`} size="small">
                        <Select
                          disabled={nombrePrivado()}
                          name="tipo_criterio_vp"
                          id="tipo_criterio_vp"
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          value={item.tipo_criterio_vp}
                        >
                          <MenuItem value="">
                            <em>{t('selects:selects-autocompletes.select-option')}</em>
                          </MenuItem>

                          {loadFillDataTypeCriteria()}
                        </Select>
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {t('certificateBadgeTemplate:badges-certificate-form.description')}*
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          disabled={nombrePrivado()}
                          required
                          label=""
                          name="descripcion_criterio"
                          variant="outlined"
                          size="small"
                          multiline
                          value={item.descripcion_criterio}
                          rows={4}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {t('certificateBadgeTemplate:badges-certificate-form.criterion-url')}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          disabled={nombrePrivado()}
                          label=""
                          name="link"
                          variant="outlined"
                          size="small"
                          placeholder="http://"
                          value={item.link}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                    </CardContent>

                    <CardActions>
                      {activity.length > 1 && (
                        <Button disabled={nombrePrivado()} size="small" onClick={(e) => deleteActivity(e, item.index)}>
                          <Typography color="textSecondary" variant="body2">
                            <CloseOutlined />
                            {t('buttons:buttons.delete')}
                          </Typography>
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={addActivity}
                  disabled={nombrePrivado()}
                >
                  {t('buttons:buttons.add-criterion')}
                </Button>
              </Grid>
            </Grid>

            <Divider className={classes.root} />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.attributes')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.type')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl required variant="outlined" className="w-100" size="small">
                  <Select
                    disabled={nombrePrivado()}
                    inputProps={{
                      name: 'tipo_vp',
                      id: 'tipo',
                    }}
                    value={form.tipo_vp}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataTypes()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.level')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl required variant="outlined" className="w-100" size="small">
                  <Select
                    disabled={nombrePrivado()}
                    inputProps={{
                      name: 'nivel_vp',
                      id: 'nivel',
                    }}
                    value={form?.nivel_vp}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataLevels()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.time')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    disabled={nombrePrivado()}
                    inputProps={{
                      name: 'tiempo_vp',
                      id: 'tiempo',
                    }}
                    value={form?.tiempo_vp}
                    onChange={handleInput}
                  >
                    <MenuItem value={null}>
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataTime()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.amount-time')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <TextField
                    disabled={requiredCantidadTiempo(form.tiempo_vp) || nombrePrivado()}
                    required={!requiredCantidadTiempo(form.tiempo_vp)}
                    type="number"
                    name="cantidad_tiempo"
                    defaultValue={null}
                    value={cantidadTiempo(form.cantidad_tiempo)}
                    variant="outlined"
                    size="small"
                    onKeyUp={onlyNumber}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.cost')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl required variant="outlined" className="w-100" size="small">
                  <Select
                    disabled={nombrePrivado()}
                    inputProps={{
                      name: 'costo_vp',
                      id: 'costo',
                    }}
                    value={form?.costo_vp}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCosts()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider className={classes.root} />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.recommendation-title')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Autocomplete
                    disabled={nombrePrivado()}
                    multiple
                    filterSelectedOptions={true}
                    id="tags-filled"
                    className={classes.autocomplete}
                    value={form.id_plantillas_recomendadas}
                    onChange={handleRecommendation}
                    options={templatesBadges.map((element) => ({
                      linkphoto: element.linkPhoto,
                      id_plantillas_recomendadas: element.id,
                      nombre: element.nombre,
                      nombre_privado: element.nombre_privado,
                    }))}
                    getOptionLabel={(option) => privateName(option)}
                    getOptionSelected={(option, value) =>
                      option.id_plantillas_recomendadas === value.id_plantillas_recomendadas
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((recommendation, index) => (
                        <Chip
                          variant="outlined"
                          label={privateName(recommendation)}
                          {...getTagProps({ index })}
                          onClick={handleChip}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('selects:selects-autocompletes.recommendation-select')}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Divider className={classes.root} />
            <div className={`text-center ${classes.root}`}>
              <Button
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                type="submit"
                disableElevation
              >
                {t('buttons:buttons.save')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                onClick={(e) => handleOpenDialog(form)}
                disableElevation
              >
                {t('buttons:buttons.preview')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={`my-2 ${classes.buttonForm}`}
                onClick={handleCancel}
                disableElevation
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle align="center" onClose={handleClose}>
          {t('dialogs:dialog.preview-title')}
        </DialogTitle>
        <DialogContent dividers>
          <PreviewDialog vista={vista}>
            {vista.id_plantillas_recomendadas.map((recommendation, index) => (
              <BadgeCard
                key={`badge${index}`}
                badgeImage={recommendation.linkphoto}
                badgeName={recommendation.nombre}
                organizationName={vista.organizaciones?.nombre}
                publicBadge={false}
                templateBadge={true}
              />
            ))}
          </PreviewDialog>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={handleClose}
          >
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth={true} maxWidth={'sm'}>
        <DialogContent dividers>
          <FormControl className="w-100">
            <TextField
              autoFocus
              required
              multiline
              id="nombre"
              label={t('dialogs:dialog.skill')}
              variant="outlined"
              onChange={(e) => setSkillValue({ ...skillValue, value: e.target.value })}
              defaultValue={skillValue.value}
            />
          </FormControl>
        </DialogContent>
        <DialogActions center>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={editSkill}
          >
            {t('buttons:buttons.save')}
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonForm}`}
            onClick={handleCloseEdit}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
