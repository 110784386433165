import React from 'react';
import { makeStyles, Typography, LinearProgress, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTelegram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import colors from '../assets/styles/colors';
import { useTranslation } from 'react-i18next';
import XTwitterIcon from '../assets/img/twitter-x-square.svg';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '.5em',
  },
  title: {
    marginTop: '.5em',
    textAlign: 'center',
  },
  progress: {
    height: 20,
    backgroundColor: colors.footer,
  },
  bar: {
    backgroundColor: '#000000',
  },
  barFacebook: {
    backgroundColor: '#4064ac',
  },
  barMessenger: {
    backgroundColor: '#0080f7',
  },
  barTwitter: {
    backgroundColor: '#2f3136',
  },
  barLinkedIn: {
    backgroundColor: '#2563ac',
  },
  barWhatsApp: {
    backgroundColor: '#42e35f',
  },
  barTelegram: {
    backgroundColor: '#34b3f7',
  },
  percentage: {
    marginLeft: '.6em',
    backgroundColor: colors.footer,
    padding: 5,
  },
  icon: {
    color: '#000000',
    marginRight: '.6em',
  },
  iconFacebook: {
    color: '#4064ac',
    marginRight: '.6em',
  },
  iconMessenger: {
    color: '#0080f7',
    marginRight: '.6em',
  },
  iconLinkedin: {
    color: '#2563ac',
    marginRight: '.6em',
  },
  iconTwitter: {
    color: '#34b3f7',
    marginRight: '.6em',
    marginLeft: '-.5em',
  },
  iconWhatsapp: {
    color: '#42e35f',
    marginRight: '.6em',
  },
  iconTelegram: {
    color: '#34b3f7',
    marginRight: '.6em',
  },
  divider: {
    marginTop: '.6em',
  },
  text: {
    width: '5em',
  },
}));

const Sharing = (props) => {
  const classes = useStyles();
  const { socialNetworks, totalCompartidas } = props;
  const [t] = useTranslation(['analytics']);

  return (
    <>
      <Typography component="h6" variant="h6" className={classes.title}>
        {t('times-share')} {totalCompartidas}
      </Typography>

      <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
        <FontAwesomeIcon icon={faFacebookSquare} className={classes.iconFacebook} size="lg" />
        <div className={classes.text}>Facebook</div>
        <LinearProgress
          className={`flex-grow-1 mx-2 ${classes.progress}`}
          classes={{
            bar: classes.barFacebook,
          }}
          variant="determinate"
          value={socialNetworks?.facebook?.porcen}
        />
        <div className="font-weight-bold font-size-lg">{socialNetworks?.facebook?.cant}</div>
        <div className={`font-size-lg ${classes.percentage}`}>{socialNetworks?.facebook?.porcen}%</div>
      </div>

      <Divider className={classes.divider} />
      <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
        <FontAwesomeIcon icon={faLinkedin} className={classes.iconLinkedin} size="lg" />
        <div className={classes.text}>LinkedIn</div>
        <LinearProgress
          className={`flex-grow-1 mx-2 ${classes.progress}`}
          classes={{
            bar: classes.barLinkedIn,
          }}
          variant="determinate"
          value={socialNetworks?.linkedin?.porcen}
        />
        <div className="font-weight-bold font-size-lg">{socialNetworks?.linkedin?.cant}</div>
        <div className={`font-size-lg ${classes.percentage}`}>{socialNetworks?.linkedin?.porcen}%</div>
      </div>

      <Divider className={classes.divider} />
      <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
        <img src={XTwitterIcon} alt="XTwitter" height={23} className={classes.iconTwitter} />
        <div className={classes.text}>Twitter</div>
        <LinearProgress
          className={`flex-grow-1 mx-2 ${classes.progress}`}
          classes={{
            bar: classes.barTwitter,
          }}
          variant="determinate"
          value={socialNetworks?.twitter?.porcen}
        />
        <div className="font-weight-bold font-size-lg">{socialNetworks?.twitter?.cant}</div>
        <div className={`font-size-lg ${classes.percentage}`}>{socialNetworks?.twitter?.porcen}%</div>
      </div>

      <Divider className={classes.divider} />
      <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
        <FontAwesomeIcon icon={faWhatsapp} className={classes.iconWhatsapp} size="lg" />
        <div className={classes.text}>WhatsApp</div>
        <LinearProgress
          className={`flex-grow-1 mx-2 ${classes.progress}`}
          classes={{
            bar: classes.barWhatsApp,
          }}
          variant="determinate"
          value={socialNetworks?.whatsapp?.porcen}
        />
        <div className="font-weight-bold font-size-lg">{socialNetworks?.whatsapp?.cant}</div>
        <div className={`font-size-lg ${classes.percentage}`}>{socialNetworks?.whatsapp?.porcen}%</div>
      </div>

      <Divider className={classes.divider} />
      <div className={`d-flex flex-grow-1 pl-0 pl-xl-3 align-items-center ${classes.root}`}>
        <FontAwesomeIcon icon={faTelegram} className={classes.iconTelegram} size="lg" />
        <div className={classes.text}>Telegram</div>
        <LinearProgress
          className={`flex-grow-1 mx-2 ${classes.progress}`}
          classes={{
            bar: classes.barTelegram,
          }}
          variant="determinate"
          value={socialNetworks?.telegram?.porcen}
        />
        <div className="font-weight-bold font-size-lg">{socialNetworks?.telegram?.cant}</div>
        <div className={`font-size-lg ${classes.percentage}`}>{socialNetworks?.telegram?.porcen}%</div>
      </div>
    </>
  );
};

export default Sharing;
