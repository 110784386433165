import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, TableCell, TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 'auto',
  },
}));

const SkeletonTable = (props) => {
  const { columns, photo } = props;
  const classes = useStyles();
  return (
    <>
      {columns.map((row, index) => (
        <TableRow key={index}>
          {photo === 1 && (<TableCell> <Skeleton variant="circle"><Avatar className={classes.large} /></Skeleton> </TableCell>)}
          {columns.length > 0 && (
            columns.map((row, index) => (
              <TableCell><Skeleton /></TableCell>
            ))
          )}
        </TableRow>
      ))}
    </>
  );
}

export default (SkeletonTable);
