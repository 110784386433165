import axios from '../api';
import api from 'axios';
import { BroadcastChannel } from 'broadcast-channel';
import { env } from '../config/environment';

const logoutChannel = new BroadcastChannel('logout');

export const loginRequest = (payload) => ({
  type: 'LOGIN_REQUEST',
  payload,
});

export const logoutRequest = (payload) => ({
  type: 'LOGOUT_REQUEST',
  payload,
});

export const loginSesion = (payload) => ({
  type: 'LOGIN_SESION',
  payload,
});

export const logoutSesion = (payload) => ({
  type: 'LOGOUT_SESION',
  payload,
});

export const changePage = (payload) => ({
  type: 'PAGE',
  payload,
});

export const changeRowsPerPage = (payload) => ({
  type: 'ROWS_PER_PAGE',
  payload,
});

export const changeBreadcrump = (payload) => ({
  type: 'BREADCRUMP',
  payload,
});

export const changePermissions = (payload) => ({
  type: 'PERMISSION',
  payload,
});

export const setBadges = (payload) => ({
  type: 'BADGES',
  payload,
});

export const setCertificates = (payload) => ({
  type: 'CERTIFICATES',
  payload,
});

export const setCarnets = (payload) => ({
  type: 'CARNETS',
  payload,
});

export const setBadge = (payload) => ({
  type: 'BADGE',
  payload,
});

export const setCertificate = (payload) => ({
  type: 'CERTIFICATE',
  payload,
});

export const setCarnet = (payload) => ({
  type: 'CARNET',
  payload,
});

export const setLoading = (payload) => ({
  type: 'LOADING',
  payload,
});

export const setError = (payload) => ({
  type: 'ERROR',
  payload,
});

export const setOrganizationId = (payload) => ({
  type: 'ORGANIZATION_ID',
  payload,
});

export const setCertificateFields = (payload) => ({
  type: 'CERTIFICATE_FIELDS',
  payload,
});

export const setDocumentsFields = (payload) => ({
  type: 'DOCUMENTS_FIELDS',
  payload,
});

export const setFormDocuments = (payload) => ({
  type: 'FORM_DOCUMENTS',
  payload,
});

export const setSaveDesignDocuments = (payload) => ({
  type: 'SAVE_DESIGN_DOCUMENTS',
  payload,
});

export const setDocument = (payload) => ({
  type: 'DOCUMENT',
  payload,
});

export const setForm = (payload) => ({
  type: 'FORM',
  payload,
});

export const setReturnRoute = (payload) => ({
  type: 'RETURN_ROUTE',
  payload,
});

export const setSaveDesign = (payload) => ({
  type: 'SAVE_DESIGN',
  payload,
});

export const setLenguage = (payload) => ({
  type: 'LENGUAGE',
  payload,
});

export const setDetails = (payload) => ({
  type: 'DETAILS',
  payload,
});

export const setStyles = (payload) => ({
  type: 'STYLES',
  payload,
});

export const setSignatorieSignUp = (payload) => ({
  type: 'SIGNATORIESINGUP',
  payload,
});

export const setSignedDocuments = (payload) => ({
  type: 'SIGNED_DOCUMENTS',
  payload,
});

export const setFormDiplomas = (payload) => ({
  type: 'FORM_DIPLOMAS',
  payload,
});

export const setDiplomaFields = (payload) => ({
  type: 'DIPLOMA_FIELDS',
  payload,
});

export const setSearchValue = (payload) => ({
  type: 'SEARCH_VALUE',
  payload,
});

export const setView = (payload) => ({
  type: 'VIEW',
  payload,
});

export const setActive = (payload) => ({
  type: 'ACTIVE',
  payload,
});

export const login = (payload, redirectUrl) => {
  return (dispatch) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    axios({
      url: `/user/sign-in`,
      method: 'post',
      auth: {
        username: payload.correo,
        password: payload.password,
      },
    })
      .then(({ data }) => {
        const id_grupo_usuario = data.data_user?.gruposUsuariosOrganizaciones[0].id_grupos_usuarios;
        if (
          id_grupo_usuario !== 1 &&
          id_grupo_usuario !== 3 &&
          window.location.origin === env.mincienciasURL &&
          data.data_user?.gruposUsuariosOrganizaciones[0].id_organizaciones !== env.idMinciencias
        )
          return dispatch(setError('Usuario no autorizado'));
        dispatch(loginRequest(data));
        if (id_grupo_usuario === 1) redirectUrl = '/templates/published';
        axios
          .post(`/permission/getPermissions`)
          .then(({ data }) => dispatch(changePermissions(data.permissions.permissions)))
          .then(() => dispatch(setLoading(false)))
          .then(() => {
            window.location.href = redirectUrl;
            logoutChannel.postMessage('IniciaSesión');
          })
          .catch((error) => {
            dispatch(setLoading(false));
            dispatch(setError(error));
            redirectUrl = '/500';
            window.location.href = redirectUrl;
          });
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setError(error));
      });
  };
};

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    let summer = '';
    let newB = '';
    let newA = '';
    const div = orderBy.split('.');
    if (div.length > 2) {
      summer = div[2];
      const B = b[div[0]];
      const A = a[div[0]];
      newB = B[div[1]];
      newA = A[div[1]];
    } else {
      summer = div[1];
      newB = b[div[0]];
      newA = a[div[0]];
    }
    if (`${newB[summer]}`.toLowerCase() < `${newA[summer]}`.toLowerCase()) {
      return -1;
    }
    if (`${newB[summer]}`.toLowerCase() > `${newA[summer]}`.toLowerCase()) {
      return 1;
    }
    return 0;
  } else {
    if (typeof b[orderBy] && typeof a[orderBy] === 'number') {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else {
      if (`${b[orderBy]}`.toLowerCase() < `${a[orderBy]}`.toLowerCase()) {
        return -1;
      }
      if (`${b[orderBy]}`.toLowerCase() > `${a[orderBy]}`.toLowerCase()) {
        return 1;
      }
    }
    return 0;
  }
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function sortData(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getBackOfficeOrganization = (id) => {
  return (dispatch) => {
    api({
      url: `${env.apiURL}/organization/backoffice/${id}`,
      method: 'get',
    }).then((response) => {
      dispatch(
        setStyles({
          ...response.data.organization.styles,
          organizationName: `${id === env.idMinciencias ? 'Minciencias' : 'Certika'}`,
        })
      );
    });
  };
};
