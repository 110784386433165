import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import { SmartphoneOutlined, PhoneOutlined, LanguageOutlined, MailOutline } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import Appbar from '../components/Appbar';
import api from '../api';
import md5 from 'crypto-js/md5';
import axiosFile from 'axios';
import Swal from 'sweetalert2';
import { env } from '../config/environment';
import { loginRequest } from '../actions/index';
const patron = new RegExp(
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

function ProfileSettings(props) {
  const { user, dispatch } = props;
  const classes = useStyles();
  const history = useHistory();
  const [prefix, setPrefix] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateValid, setDateValid] = useState(false);
  const [form, setForm] = useState({
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    documento: '',
    telefono: '',
    celular: '',
    correo: '',
    fecha_nacimiento: null,
    biografia: '',
    pagina_web: '',
    url_perfil: '',
    id_paises: null,
    id_ciudades: null,
    imagen: null,
    imagen_prev: '',
  });

  useEffect(() => {
    if (!user) {
      history.push('/');
      window.location.reload();
    } else {
      getCountries().catch(() => {
        history.push('/500');
        window.location.reload();
      });
      mount().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.id_paises !== '' ? getCities(form.id_paises) : setCities(['']);
  }, [form.id_paises]);

  const mount = async () => {
    const { data } = await api.post(`/user/profile`);
    setForm({
      ...data.user,
      imagen: data.user.linkPhoto,
      imagen_prev: data.user.linkPhoto,
      url_static: data.user.url_perfil,
    });
    if (data.user?.id_paises) {
      setPrefix({
        ...prefix,
        country: data.user?.paises?.prefijo_telefono,
        city: data.user?.ciudades?.prefijo_telefono,
      });
    }
  };

  const getCountries = async () => {
    const { data } = await api.post(`/others/getCountries`);
    setCountries(data.countries);
  };

  const getCities = async (idPais) => {
    const { data } = await api.post(`/others/getCities`, { idPais });
    setCities(data.cities);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputDateBirth = (event) => {
    let actualDate = new Date();
    let currentDate = event.target.value.split('-');
    let today = new Date();
    actualDate.setFullYear(currentDate[0], currentDate[1] - 1, currentDate[2]);
    if (actualDate >= today) {
      setDateValid(true);
    } else if (currentDate[0] > today.getFullYear() - 18) {
      setDateValid(true);
    } else if (parseInt(currentDate[0]) === today.getFullYear() - 18) {
      if (currentDate[1] - 1 > today.getMonth()) {
        setDateValid(true);
      } else if (currentDate[1] - 1 === today.getMonth()) {
        setDateValid(currentDate[2] > today.getDate());
      } else {
        setDateValid(false);
      }
    } else {
      setDateValid(false);
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCountry = async (event) => {
    if (event.target.value === '') {
      setForm({
        ...form,
        [event.target.name]: '',
        id_ciudades: '',
      });
      setPrefix('');
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
        id_ciudades: '',
      });
      const prefixCountries = countries.find((country) => country.id === event.target.value);
      if (prefixCountries.prefijo_telefono) {
        setPrefix({
          ...prefix,
          country: prefixCountries.prefijo_telefono,
        });
      }
    }
  };

  const handleChangeCity = async (event) => {
    if (event.target.value === '') {
      setForm({
        ...form,
        [event.target.name]: '',
      });
      setPrefix('');
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
      const prefixCities = cities.find((item) => item.id === event.target.value);
      if (prefixCities.prefijo_telefono) {
        setPrefix({
          ...prefix,
          city: prefixCities.prefijo_telefono,
        });
      }
    }
  };

  const loadFillDataCountries = () => {
    return countries.map((data) => {
      return (
        <MenuItem key={`countrie-${data.id}`} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const loadFillDataCities = () => {
    return cities.map((data) => {
      return (
        <MenuItem key={`city-${data.id}`} value={data.id}>
          {data.nombre}
        </MenuItem>
      );
    });
  };

  const handleCancel = () => {
    history.push('/dashboard');
  };

  //Metodo para actualizar los datos en el redux
  const handleUpdateUserRedux = () => {
    dispatch(
      loginRequest({
        ...user,
        data_user: {
          ...user.data_user,
          ...form,
        },
      })
    );
  };

  const handleFile = (e) => {
    const imagen = e.target.files[0];
    setForm({
      ...form,
      imagen: imagen,
      imagen_prev: URL.createObjectURL(imagen),
    });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleToggle();

    const { data } = await api.post(`/user/getUserUrl`, { url_perfil: form.url_perfil });
    const formPhoto = new FormData();
    formPhoto.append('file', form.imagen);

    if (!patron.test(form.pagina_web) && form.pagina_web) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Url De Sitio Web Incorrecto.',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (form.pagina_web && !form.pagina_web.includes('https://')) {
        form.pagina_web = `https://${form.pagina_web}`;
      }
      if (dateValid) {
        handleClose();
        Swal.fire({
          icon: 'error',
          text: 'Fecha de nacimiento inválida.',
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (form.url_static !== form.url_perfil && data) {
        handleClose();
        Swal.fire({
          icon: 'error',
          text: 'Url de perfil se encuentra asignada.',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        api
          .put(`/user`, { ...form, imagen: form.imagen?.name, whatsapp: `+${prefix.country}${form.celular}` })
          .then((res) => {
            if (form.imagen?.name) {
              const md5Route = md5(`certika-user-${res.data.userId}`).toString();
              axiosFile({
                method: 'post',
                url: `${env.apiURL}/file/upload-photo/${md5Route}`,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                data: formPhoto,
              }).then(() => {
                handleClose();
                handleUpdateUserRedux();
                history.push('/dashboard');
                Swal.fire({
                  icon: 'success',
                  text: 'Edición exitosa.',
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
            } else {
              handleClose();
              handleUpdateUserRedux();
              history.push('/dashboard');
              Swal.fire({
                icon: 'success',
                text: 'Edición exitosa.',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((error) => {
            handleClose();
            Swal.fire({
              icon: 'error',
              text: 'No se ha podido editar.',
              showConfirmButton: false,
              timer: 1500,
            });
            history.push('/500');
            window.location.reload();
          });
      }
    }
  };

  const onlyNumber = (event) => {
    const value = String(event.target.value);
    // eslint-disable-next-line no-useless-escape
    const patron = /^([1-9][0-9]*(?:[\.][0-9]*)?|\.[0-9]+)$/;

    if (!patron.test(value)) {
      event.target.value = value.substr(value.length);
    }
  };

  return (
    <>
      <div className="App">
        <Appbar />
        <div className="App-header">
          <Card className={classes.card}>
            <CardContent>
              <Typography component="h6" variant="h6">
                Edición de usuario
              </Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                name="logo"
                type="file"
                onChange={(e) => handleFile(e)}
              />
              <label htmlFor="contained-button-file">
                <Tooltip title="Cambia Tu Imagen">
                  <Avatar src={form.imagen_prev} variant="rounded" className={classes.avatar} />
                </Tooltip>
              </label>
              <Typography component="h6" variant="h6">
                {user?.data_user?.primer_nombre} {user?.data_user?.primer_apellido}
              </Typography>
              <form className={classes.root} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl className="w-100">
                      <TextField
                        label="Agrega una biografía"
                        name="biografia"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={4}
                        onChange={handleInput}
                        value={form.biografia}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label="Primer nombre"
                        variant="outlined"
                        name="primer_nombre"
                        value={form.primer_nombre}
                        onChange={handleInput}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        label="Segundo nombre"
                        variant="outlined"
                        name="segundo_nombre"
                        value={form.segundo_nombre}
                        onChange={handleInput}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label="Primer apellido"
                        variant="outlined"
                        name="primer_apellido"
                        value={form.primer_apellido}
                        onChange={handleInput}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        label="Segundo apellido"
                        variant="outlined"
                        name="segundo_apellido"
                        value={form.segundo_apellido}
                        onChange={handleInput}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        type="number"
                        label="Nº de documento"
                        variant="outlined"
                        name="documento"
                        value={form.documento}
                        onChange={handleInput}
                        disabled={form?.disable}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        label="Fecha de nacimiento"
                        error={dateValid}
                        type="date"
                        variant="outlined"
                        name="fecha_nacimiento"
                        value={form.fecha_nacimiento}
                        onChange={handleInputDateBirth}
                        InputProps={{ startAdornment: <InputAdornment position="start" /> }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label="Correo electrónico"
                        variant="outlined"
                        name="correo"
                        type="email"
                        value={form.correo}
                        onChange={handleInput}
                        disabled={form?.disable}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutline />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        label="Página web"
                        variant="outlined"
                        name="pagina_web"
                        placeholder="https://"
                        onChange={handleInput}
                        value={form.pagina_web}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        label="URL perfil"
                        variant="outlined"
                        name="url_perfil"
                        disabled={form?.disable}
                        onChange={handleInput}
                        value={form.url_perfil}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl variant="outlined" className="w-100">
                      <InputLabel>País *</InputLabel>
                      <Select
                        required
                        id="pais"
                        name="id_paises"
                        label="País"
                        onChange={handleChangeCountry}
                        value={form.id_paises ? form.id_paises : ''}
                      >
                        <MenuItem value="">
                          <em>Seleccione una opción</em>
                        </MenuItem>
                        {loadFillDataCountries()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl variant="outlined" className="w-100">
                      <InputLabel id="ciudadesLabel">Ciudad *</InputLabel>
                      <Select
                        required
                        id="ciudades"
                        name="id_ciudades"
                        labelId="ciudadesLabel"
                        value={form.id_ciudades ? form.id_ciudades : ''}
                        onChange={handleChangeCity}
                        label="Ciudad"
                      >
                        <MenuItem value="">
                          <em>Seleccione una opción</em>
                        </MenuItem>
                        {loadFillDataCities()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        label="Teléfono"
                        variant="outlined"
                        name="telefono"
                        type="number"
                        defaultValue={null}
                        value={form.telefono}
                        onKeyUp={onlyNumber}
                        onChange={handleInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneOutlined />
                              {`(${prefix.city ? prefix.city : ''})`}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl className="w-100">
                      <TextField
                        required
                        label="Celular"
                        variant="outlined"
                        name="celular"
                        type="number"
                        defaultValue={null}
                        value={form.celular}
                        onKeyUp={onlyNumber}
                        onChange={handleInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SmartphoneOutlined />
                              {`(${prefix.country ? prefix.country : ''})`}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={`text-center ${classes.root}`}>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={`my-2 ${classes.button}`}
                    type="submit"
                  >
                    Guardar
                  </Button>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={`my-2 ${classes.button}`}
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    [theme.breakpoints.up('sm')]: {
      margin: '1em 6em 0 6em',
    },
  },
  button: {
    margin: '0.5em',
    padding: '8px 40px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    width: '140px',
    height: '140px',
    borderRadius: '100%',
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    display: 'none',
  },
  icon: {
    fontSize: '4em',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '4em 0',
    marginTop: '0',
    marginBottom: '0',
    backgroundColor: 'primary',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
      margin: '4em',
      marginBottom: '0',
      marginTop: '0',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'profilesettings')
      .map((item) => item.acciones.nombre),
    organizationId: state.organizationId || state.user?.data_user?.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

export default connect(mapStateToProps)(ProfileSettings);
