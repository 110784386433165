import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Typography, makeStyles, withStyles, LinearProgress, Grid } from '@material-ui/core';
import colors from '../assets/styles/colors';
import api from '../api';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import RechargeIssue from './RechargeIssue';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1rem',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  pos: {
    fontSize: 12,
  },
}));

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: colors.secondary,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: colors.primary,
  },
}))(LinearProgress);

function CardContract(props) {
  const classes = useStyles();
  const { user, organizationId } = props;
  const [contract, setContract] = useState([null]);
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation('contracts');
  const { id: idGroupUserOrg, id_grupos_usuarios: rolUser } = user.data_user.gruposUsuariosOrganizaciones.find(
    (item) => item.id_organizaciones === organizationId
  );

  useEffect(() => {
    getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContract = async () => {
    if (rolUser !== 1) {
      await api.post(`/contract/validContract/${organizationId}`);
      const { data } = await api.get(`/contract/card/${organizationId}`);
      if (rolUser === 4 && data.contract) {
        const { data: dataGroup } = await api.get(`/userGroup/organizations/${idGroupUserOrg}`);
        const { limit_issued: limit, user_total_issueds: total_issueds } = dataGroup.group;
        if (limit) {
          setContract({ ...data.contract, user_cantidad_emisiones: limit, user_total_emisiones: total_issueds || 0 });
          setLoading(false);
          return;
        }
      }
      setContract(data.contract);
      setLoading(false);
    } else {
      setContract(null);
      setLoading(false);
    }
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        {contract ? (
          <>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6" component="h6" className={classes.title}>
                  {loading ? (
                    <Skeleton />
                  ) : contract?.id_planes === 5 ? (
                    t('contracts:total-users')
                  ) : (
                    t('contracts:total-issue')
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {loading ? <Skeleton /> : rolUser !== 4 && <RechargeIssue />}
              </Grid>
            </Grid>
            {rolUser !== 4 ? (
              <Typography className={classes.pos} color="textSecondary">
                {loading ? (
                  <Skeleton />
                ) : (
                  `${new Intl.NumberFormat('de-DE').format(contract?.total_emisiones)} ${t(
                    'contracts:of'
                  )} ${new Intl.NumberFormat('de-DE').format(contract?.cantidad_emisiones)}`
                )}
              </Typography>
            ) : (
              <>
                <Typography className={classes.pos} color="textSecondary">
                  {loading ? (
                    <Skeleton />
                  ) : (
                    `${
                      contract?.user_cantidad_emisiones && contract?.user_total_emisiones !== null
                        ? t('contracts:organitation')
                        : ''
                    } ${new Intl.NumberFormat('de-DE').format(contract?.total_emisiones)} ${t(
                      'contracts:of'
                    )} ${new Intl.NumberFormat('de-DE').format(contract?.cantidad_emisiones)}`
                  )}
                </Typography>
                {contract?.user_cantidad_emisiones && contract?.user_total_emisiones !== null && (
                  <Typography className={classes.pos} color="textSecondary">
                    {loading ? (
                      <Skeleton />
                    ) : (
                      `${t('contracts:user')}: ${new Intl.NumberFormat('de-DE').format(
                        contract?.user_total_emisiones
                      )} ${t('contracts:of')} ${new Intl.NumberFormat('de-DE').format(
                        contract?.user_cantidad_emisiones
                      )}`
                    )}
                  </Typography>
                )}
              </>
            )}
            {loading ? (
              <Skeleton />
            ) : (
              <BorderLinearProgress
                variant="determinate"
                value={(contract?.total_emisiones / contract?.cantidad_emisiones) * 100}
              />
            )}
            <Typography className={classes.pos} color="textSecondary">
              {loading ? (
                <Skeleton />
              ) : contract?.vencimiento ? (
                `${t('contracts:expiration')}  ${moment(contract?.vencimiento).format('DD/MM/YYYY')}`
              ) : (
                t('contracts:without-expiration')
              )}
            </Typography>
          </>
        ) : (
          <>
            <Grid container>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                {loading ? <Skeleton /> : <RechargeIssue />}
              </Grid>
            </Grid>
            <Typography align="center" variant="h6" component="h6" className={classes.title} color="textSecondary">
              {t('contracts:without-contract')}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
  };
};

export default connect(mapStateToProps, null)(CardContract);
