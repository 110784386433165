import axios from '../api.js';

const downloadFile = async (keyFile) => {
  const { data } = await axios.post(`/file/getFile`, {
    keyFile,
  });

  window.open(data.url);
};

export default downloadFile;
