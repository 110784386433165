import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import Appbar from '../components/Appbar';
import Menu from '../components/Menu';
import { useHistory } from 'react-router-dom';

function Layout(props) {
  const { children, user, permission } = props;
  const history = useHistory();

  if (user == null || permission == null) {
    history.push('/');
    window.location.reload();
  }

  return (
    <Fragment>
      <Appbar />
      <Container maxWidth="false">
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <div id="sidebar">
              <Menu />
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <Switch>{children}</Switch>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    permission: state.permission,
  };
};

export default connect(mapStateToProps, null)(Layout);
