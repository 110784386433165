import React from 'react';
import { Stage, Layer, Line, Text } from 'react-konva';
import SketchPickerButton from './SketchPickerButton';
import { Box, Button, Grid, IconButton, makeStyles, withStyles, LinearProgress, Typography } from '@material-ui/core';
import { UndoOutlined, RedoOutlined, DeleteOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const SignatureDrawing = (props) => {
  const { closeModalSign, saveSign, progressBar, emailUser } = props;
  const [lines, setLines] = React.useState([]);
  const [linesUndo, setLinesUndo] = React.useState([]);
  const [color, setColor] = React.useState('#000');
  const [guide, setGuide] = React.useState(true);
  const isDrawing = React.useRef(false);
  const stageRef = React.useRef(null);
  const classes = useStyle();
  const [t] = useTranslation(['buttons', 'certifyDocuments']);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x, pos.y], strokeWidth: 3, stroke: color }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([parseInt(point.x), parseInt(point.y)]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const changeColor = (color) => {
    setColor(color.hex);
  };

  const undo = () => {
    if (lines.length !== 0) {
      setLinesUndo([...linesUndo, lines[lines.length - 1]]);
      setLines(lines.slice(0, lines.length - 1));
    }
  };

  const redo = () => {
    if (linesUndo.length > 0) {
      setLines([...lines, linesUndo[linesUndo.length - 1]]);
      setLinesUndo(linesUndo.slice(0, linesUndo.length - 1));
    }
  };

  const clear = () => {
    setLines([]);
    setLinesUndo([]);
  };

  const goToSave = async (stageRef) => {
    await setGuide(false);
    saveSign(stageRef);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <SketchPickerButton color={color} changeColor={changeColor} />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <IconButton onClick={() => undo()} disabled={lines.length === 0}>
            <UndoOutlined size="small" />
          </IconButton>

          <IconButton onClick={() => redo()} disabled={linesUndo.length < 1}>
            <RedoOutlined size="small" />
          </IconButton>

          <IconButton onClick={() => clear()} disabled={lines.length === 0}>
            <DeleteOutline size="small" />
          </IconButton>
        </Grid>
      </Grid>
      {!progressBar.value && (
        <div style={{ backgroundColor: '#f0f0f0', margin: '1em', borderRadius: '1em', cursor: 'crosshair' }}>
          <Stage
            width={520}
            height={150}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            ref={stageRef}
          >
            <Layer>
              <Text
                text={t('certifyDocuments:document.stamp')}
                fontSize={14}
                fontStyle="italic"
                width={510}
                x={1}
                y={2}
                align="right"
              />
              <Text
                text={t('certifyDocuments:add-signatories.signatory')}
                x={1}
                y={10}
                width={520}
                fontSize={20}
                align="center"
              />
              <Text text={emailUser} x={1} y={30} width={520} fontSize={20} align="center" />
              {guide && (
                <Text text={t('certifyDocuments:document.draw-here')} width={520} x={1} y={85} align="center" />
              )}
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.stroke}
                  strokeWidth={line.strokeWidth}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
                />
              ))}
            </Layer>
          </Stage>
        </div>
      )}
      {progressBar.value && (
        <div style={{ margin: '1em', width: 520 }}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progressBar.value} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressBar.value)}%`}</Typography>
            </Box>
          </Box>
        </div>
      )}
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} className={classes.container__button_fields}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => goToSave(stageRef)}
            disabled={lines.length < 1}
          >
            {t('buttons:buttons.save')}
          </Button>{' '}
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => closeModalSign()}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyle = makeStyles(() => ({
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  button: {
    margin: '0.5em',
    padding: '9px 40px',
  },
}));

export default SignatureDrawing;
