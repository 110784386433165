import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  IconButton,
  Link,
  Chip,
  Card,
  CardContent,
  Avatar,
  Menu,
  MenuItem,
  Collapse,
  Box,
  Button,
  Dialog,
  Slide,
} from '@material-ui/core';
import {
  WhatsApp,
  Telegram,
  EmailOutlined,
  AddOutlined,
  RemoveOutlined,
  DescriptionOutlined,
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faFacebookMessenger, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Appbar from '../components/Appbar';
import colors from '../assets/styles/colors';
import axios from '../api';
import { setDocument } from '../actions';
import { env } from '../config/environment';
import Skeleton from '@material-ui/lab/Skeleton';
import PdfIcon from '../assets/img/pdf-icon.png';
import Swal from 'sweetalert2';
import { decrypt } from '../utils/crypt';
import { useTranslation } from 'react-i18next';
import ViewPDF from '../components/PreviewPdfDocument';
import axiosFile from 'axios';
import XTwitterIcon from '../assets/img/icons8-twitterx.svg';

function DocumentView(props) {
  const { documentId, setOpenDialog, styles, user } = props;
  const classes = useStyles();
  const history = useHistory();
  const [copiedLink, setCopiedLink] = useState([false, '']);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [openMoreLinks, setOpenMoreLinks] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const [documentCertify, setDocuemntsCertify] = useState([]);
  const [additionalsDocuments, setAdditionalsDocuments] = useState([]);
  const [additionalsLinks, setAdditionalsLinks] = useState([]);
  const [viewPreview, setViewPreview] = useState(null);
  const [dialogPreview, setDialogPreview] = useState(false);

  const [t] = useTranslation(['certificateBadgeTemplate', 'buttons', 'certifyDocuments']);

  useEffect(() => {
    getDocumentsCertify().catch(() => {
      history.push('/500');
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && window.GS) {
      // eslint-disable-next-line no-undef
      GS.reset();
    }
  }, [loading]);

  const getDocumentsCertify = async () => {
    setLoading(true);
    const id = decrypt(documentId);
    const { data } = await axios.get(`/documents/getDocument/${id}`);
    setDocuemntsCertify(data.data);
    await getAdditionalDocuments(id);
    await getAdditionalLinks(id);
    setLoading(false);
  };

  const getAdditionalDocuments = async (idDocument) => {
    const { data } = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 1,
      id_documentos_emitidos: idDocument,
    });
    const { data: trailFile } = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 3,
      id_documentos_emitidos: idDocument,
    });
    setAdditionalsDocuments([...data?.documentFieldAdditionals, ...trailFile?.documentFieldAdditionals]);
  };

  const getAdditionalLinks = async (idDocument) => {
    const { data } = await axios.post(`/documentsFieldAdditionals/getDocumentFieldAdditionals`, {
      tipo: 2,
      id_documentos_emitidos: idDocument,
    });
    setAdditionalsLinks(data?.documentFieldAdditionals);
  };

  const handleLinkedInMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShare = (document) => {
    handleClose();
    Swal.fire({
      title: t('alerts:alerts.email-send-document'),
      input: 'email',
      inputPlaceholder: 'email@example.com',
      validationMessage: t('alerts:alerts.email-send-invalid'),
      showCancelButton: true,
      confirmButtonText: t('alerts:alerts.send'),
      cancelButtonText: t('alerts:alerts.cancel'),
      showLoaderOnConfirm: true,
      preConfirm: (email_receptor) => {
        const Document = new FormData();
        Document.append('id', document.id);
        Document.append('email_receptor', email_receptor);
        Document.append('nombre', document.nombre);
        Document.append('share', true);
        Document.append('sub', user.data_user.id);
        return axiosFile({
          method: 'post',
          url: `${env.apiURL}/share/sendDocumentsCertify`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: Document,
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.showValidationMessage('El correo electrónico ha rebotado');
            }
            return response.data.mensaje;
          })
          .catch(() => {
            Swal.showValidationMessage(t('alerts:alerts.error-ocurred'));
          });
      },
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: {
            container: classes.mySwal,
          },
          title: t('alerts:alerts.send-successfully'),
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          iconColor: colors.primary,
        }).then(() => {
          setOpenDialog(true);
        });
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  if (copiedLink[0] === true) {
    Swal.fire({
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
      },
      buttonsStyling: false,
      icon: 'success',
      iconColor: colors.primary,
      title: t('certificateBadgeTemplate:details.copied'),
      text: `${copiedLink[1]} ${t('certificateBadgeTemplate:details.copied-document')}`,
    }).then(() => {
      setCopiedLink(false, '');
    });
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handlePreview = (linkPdf) => {
    setViewPreview(linkPdf);
    setDialogPreview(true);
  };

  const handleClosePreview = () => {
    setDialogPreview(false);
  };

  return (
    <>
      <Appbar closeButton={true} handleClose={handleClose} />
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={'text-center'}>
          {loading ? (
            <Skeleton variant="rect" width={500} height={500} className={classes.skeleton} />
          ) : (
            <ViewPDF pdf={documentCertify?.pdfDocument} download={true} />
          )}
        </Grid>
      </Grid>
      <Container className={classes.container} maxWidth={'lg'}>
        <Grid container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h4" gutterBottom className={classes.textName}>
                {loading ? <Skeleton variant="h3" /> : <>{documentCertify?.document?.documentosEmitidos?.nombre}</>}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              className={
                documentCertify?.thereSignatories ? classes.container_redes_signatories : classes.container_redes
              }
            >
              {loading ? (
                <Skeleton variant="circle" width={40} height={40} display="inline" />
              ) : (
                <>
                  {!documentCertify?.thereSignatories && (
                    <>
                      <IconButton
                        className={classes.LinkedInIcon}
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleLinkedInMenuOpen}
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
                      </IconButton>
                      <a
                        id="linkedinShare"
                        href={`https://www.linkedin.com/sharing/share-offsite`}
                        className="gs-foreign-tracking gs-provider-linkedin"
                        data-url={`${env.documentURL}${documentId}`}
                        data-title={`${t('certificateBadgeTemplate:sharing.document-sharing')} ${
                          documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones
                            ?.redesSociales[0]?.username
                        } ${
                          '' ||
                          documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre
                        }${styles.organizationName === 'Certika' ? ' vía @certikaco' : '.'}`}
                        style={{ display: 'none' }}
                      >
                        Linkedin
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet`}
                        className="gs-foreign-tracking gs-provider-twitter"
                        data-url={`${env.documentURL}${documentId}`}
                        data-title={`${t('certificateBadgeTemplate:sharing.document-sharing')} ${
                          documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.redesSociales?.find(
                            (item) => item.id_redes_sociales === 5
                          )?.username ||
                          documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre
                        }${styles.organizationName === 'Certika' ? ' vía @certikaco' : '.'}`}
                      >
                        <IconButton className={classes.xtwitterIcon}>
                          <img src={XTwitterIcon} alt="XTwitter" height={23} />
                        </IconButton>
                      </a>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php`}
                        className="gs-foreign-tracking gs-provider-facebook"
                        data-url={`${env.documentURL}${documentId}`}
                      >
                        <IconButton className={classes.FacebookIcon}>
                          <FontAwesomeIcon icon={faFacebookF} size="sm" />
                        </IconButton>
                      </a>
                      <a
                        href={`https://www.messenger.com/sharer/sharer.php`}
                        className="gs-foreign-tracking gs-provider-fbm"
                        data-url={`${env.documentURL}${documentId}`}
                      >
                        <IconButton className={classes.TwitterIcon}>
                          <FontAwesomeIcon icon={faFacebookMessenger} size="sm" />
                        </IconButton>
                      </a>
                      <a
                        href={`https://api.whatsapp.com/send`}
                        className="gs-foreign-tracking gs-provider-whatsapp"
                        data-url={`${env.documentURL}whatsapp/${documentId}`}
                        data-title={`${t('certificateBadgeTemplate:sharing.document-sharing')} ${
                          documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre
                        }${styles.organizationName === 'Certika' ? ' vía Certika' : '.'}`}
                      >
                        <IconButton className={classes.WhatsAppIcon}>
                          <WhatsApp />
                        </IconButton>
                      </a>
                      <a
                        href={`https://telegram.me/share`}
                        className="gs-foreign-tracking gs-provider-telegram"
                        data-url={`${env.documentURL}${documentId}`}
                        data-title={`${t('certificateBadgeTemplate:sharing.document-sharing')} ${
                          documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.nombre
                        }${styles.organizationName === 'Certika' ? ' vía Certika' : '.'}`}
                      >
                        <IconButton className={classes.TelegramIcon}>
                          <Telegram viewBox="0 0 26 24" />
                        </IconButton>
                      </a>

                      <IconButton
                        className={classes.EmailIcon}
                        onClick={() => handleShare(documentCertify?.document?.documentosEmitidos)}
                      >
                        <EmailOutlined viewBox="0 0 26 24" />
                      </IconButton>
                    </>
                  )}
                  <a
                    href={documentCertify?.pdfDocument}
                    download={documentCertify?.pdfDocument}
                    style={documentCertify?.thereSignatories ? { margin: '6px' } : {}}
                  >
                    <IconButton className={classes.PdfIcon}>
                      <img src={PdfIcon} alt="pdf" className={classes.PdfIconImage} />
                    </IconButton>
                  </a>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={1}>
              {loading ? (
                <Skeleton variant="rect" height={30} />
              ) : (
                <Link
                  href={`/documents/verify/${documentId}`}
                  target="_blank"
                  underline="none"
                  variant="inherit"
                  className={classes.buttonVerificate}
                >
                  {t('buttons:buttons.verify')}
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid container>
              <Grid item xs={12} lg={6} className={classes.container_text_chip_left}>
                {!documentCertify?.thereSignatories && (
                  <>
                    <Typography className={classes.textBolder}>
                      {loading ? (
                        <Skeleton variant="rect" width={500} height={33} />
                      ) : (
                        `${t('certificateBadgeTemplate:details.document-link')}:`
                      )}
                    </Typography>
                    {!loading && (
                      <>
                        <Chip
                          variant="outlined"
                          label={`${window.location.origin}/document/${documentId}`}
                          color="default"
                          className={classes.containerButtonToCopy}
                        />
                        <CopyToClipboard
                          text={`${window.location.origin}/document/${documentId}`}
                          onCopy={() => setCopiedLink([true, 'Link'])}
                          className={classes.button}
                        >
                          <span>{t('certificateBadgeTemplate:details.copy')}</span>
                        </CopyToClipboard>
                      </>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} lg={6} className={classes.container_text_chip_right}>
                <Typography className={classes.textBolder}>
                  {loading ? (
                    <Skeleton variant="rect" width={500} height={33} />
                  ) : (
                    `${t('certificateBadgeTemplate:details.document-code')}:`
                  )}
                </Typography>
                {!loading && (
                  <>
                    <Chip
                      variant="outlined"
                      label={documentCertify?.document?.documentosEmitidos?.codigo_documento}
                      color="default"
                      className={classes.containerButtonToCopy}
                    />
                    <CopyToClipboard
                      text={documentCertify?.document?.documentosEmitidos?.codigo_documento}
                      onCopy={() => setCopiedLink([true, 'Código'])}
                      className={classes.button}
                    >
                      <span>{t('certificateBadgeTemplate:details.copy')}</span>
                    </CopyToClipboard>
                  </>
                )}
              </Grid>
            </Grid>

            {false ? (
              <>
                <Grid item xs={12} md={7} lg={7}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Grid container alignItems="center">
                        <Grid item lg={1}>
                          {loading ? (
                            <Skeleton variant="circle" className={classes.img_user} />
                          ) : (
                            <Avatar
                              aria-label="img_certificate"
                              src={documentCertify.photoUser}
                              className={classes.img_user}
                            />
                          )}
                        </Grid>
                        <Grid item lg={11}>
                          <Typography className={classes.textTitle}>
                            {loading ? (
                              <Skeleton variant="text" width={250} />
                            ) : (
                              `${documentCertify?.document?.cuentas?.usuarios?.primer_nombre} ${
                                documentCertify?.document?.cuentas?.usuarios?.segundo_nombre || ''
                              } ${documentCertify?.document?.cuentas?.usuarios?.primer_apellido} ${
                                documentCertify?.document?.cuentas?.usuarios?.segundo_apellido || ''
                              }`
                            )}
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={150} className={classes.link} />
                          ) : (
                            <Link
                              href={`/dashboard/${documentCertify?.document?.cuentas?.usuarios?.url_perfil}`}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.link}
                            >
                              {t('certificateBadgeTemplate:details.credencials')}
                              <FontAwesomeIcon icon={faShareSquare} size="xs" />
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Typography className={classes.textBolderDate}>
                    {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.issued')}:`}
                  </Typography>
                  <Typography className={classes.text}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      moment(documentCertify?.document?.documentosEmitidos?.fecha_certificacion).format('DD-MM-YYYY')
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Grid container justify="flex-end" alignItems="center">
                        <Grid item xs={1}>
                          {loading ? (
                            <Skeleton variant="circle" className={classes.img_user} />
                          ) : (
                            <Avatar
                              variant="square"
                              aria-label="img_certificate"
                              src={documentCertify.photoOrganization}
                              className={classes.img_org}
                            />
                          )}
                        </Grid>
                        <Grid item xs={11}>
                          <Typography className={classes.textNameOrganization}>
                            {loading ? (
                              <Skeleton variant="text" width={250} />
                            ) : (
                              moment(documentCertify?.document?.documentosEmitidos?.fecha_certificacion).format(
                                'DD-MM-YYYY'
                              )
                            )}
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={150} className={classes.linkOrganization} />
                          ) : (
                            <Link
                              className={classes.linkOrganization}
                              href={`/organization/${documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.url_perfil}`}
                              target="_blank"
                            >
                              {t('certificateBadgeTemplate:details.more-information')}
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12} md={1} lg={1}>
                    <Typography className={classes.textBolderDate}>
                      {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.created')}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9} lg={10}>
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent}>
                        <Grid container justify="flex-end" alignItems="center">
                          <Grid item xs={1}>
                            {loading ? (
                              <Skeleton variant="circle" className={classes.img_user} />
                            ) : (
                              <Avatar
                                variant="square"
                                aria-label="img_certificate"
                                src={documentCertify?.photoOrganization}
                                className={classes.img_org}
                              />
                            )}
                          </Grid>
                          <Grid item xs={11}>
                            <Typography className={classes.textNameOrganization}>
                              {loading ? (
                                <Skeleton variant="text" width={250} />
                              ) : (
                                documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones
                                  ?.nombre
                              )}
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={150} className={classes.linkOrganization} />
                            ) : (
                              <Link
                                className={classes.linkOrganization}
                                href={`/organization/${documentCertify?.document?.documentosEmitidos?.coleccionesDocumentos?.organizaciones?.url_perfil}`}
                                target="_blank"
                              >
                                {t('certificateBadgeTemplate:details.more-information')}
                              </Link>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={2} lg={1} className={'text-center'}>
                    <Typography className={classes.textBolderDate}>
                      {loading ? <Skeleton /> : `${t('certificateBadgeTemplate:details.date-certification')}:`}
                    </Typography>
                    <Typography className={classes.text}>
                      {loading ? (
                        <Skeleton />
                      ) : (
                        moment(documentCertify?.document?.documentosEmitidos?.fecha_certificacion).format('DD-MM-YYYY')
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              additionalsDocuments?.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openMoreInfo ? setOpenMoreInfo(false) : setOpenMoreInfo(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certifyDocuments:document.additional-documents')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openMoreInfo ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openMoreInfo} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {additionalsDocuments?.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography className={'text-left'}>
                          <DescriptionOutlined sx={{ marginRight: '1em' }} />
                          <Button onClick={() => handlePreview(data?.linkPdf)} className={classes.previewTrail}>
                            {data?.descripcion}
                          </Button>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
            </Grid>

            {loading ? (
              <Skeleton variant="rect" width={'100%'} height={50} className={classes.root} />
            ) : (
              additionalsLinks.length > 0 && (
                <Grid
                  container
                  className={classes.menuItem}
                  justify="space-between"
                  onClick={() => (openMoreLinks ? setOpenMoreLinks(false) : setOpenMoreLinks(true))}
                >
                  <Grid item lg={11}>
                    <Typography className={classes.textMenuItem}>
                      {t('certifyDocuments:document.additional-links')}
                    </Typography>
                  </Grid>
                  <Grid item lg={1}>
                    {openMoreLinks ? (
                      <RemoveOutlined sx={expandeMenuItemStyle} />
                    ) : (
                      <AddOutlined sx={expandeMenuItemStyle} />
                    )}
                  </Grid>
                </Grid>
              )
            )}
            <Grid container className={classes.descriptions}>
              <Collapse in={openMoreLinks} timeout="auto">
                <Grid item lg={12} className={classes.content_descriptions}>
                  {additionalsLinks?.map((data, index) => (
                    <Box key={`skill${index}`} display="flex">
                      <Box borderRadius={10} {...defaultPropsUser}>
                        <Typography className={'text-left'}>
                          <Link href={data?.link} target="_blank" rel="noreferrer" className={classes.linkAdditionals}>
                            {data?.descripcion ? data?.descripcion : data?.link}{' '}
                            <FontAwesomeIcon icon={faShareSquare} size="xs" />
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={() => document.getElementById('linkedinShare').click()}>
          <IconButton className={classes.LinkedInIconMenu}>
            <FontAwesomeIcon icon={faLinkedinIn} size="xs" />
          </IconButton>
          <spam className={classes.textLinkedMenu}> Agregar a las actividades</spam>
        </MenuItem>
      </Menu>

      <Grid container className={classes.footer}>
        <Grid item lg={12}>
          <span className={classes.textParagraph}>{t('certificateBadgeTemplate:details.right-reserved')}</span>
          <p className={classes.textName}>{styles.organizationName}</p>
          <span className={classes.textParagraph}>{moment().format('YYYY')}</span>
        </Grid>
      </Grid>

      <Dialog fullScreen open={dialogPreview} TransitionComponent={Transition}>
        <ViewPDF pdf={viewPreview} download={false} handleClose={handleClosePreview} fullView={true} />
      </Dialog>
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const expandeMenuItemStyle = {
  color: '#ffffff',
  float: 'right',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'initial',
  },
  root: {
    marginTop: '2em',
  },
  img_verificado: {
    display: 'inline-block',
    height: theme.spacing(5),
    margin: 'auto',
  },
  img: {
    maxWidth: '-webkit-fill-available',
  },
  textBolder: {
    marginTop: '1em',
    color: '#000000',
    fontWeight: 'bold',
    display: 'inline',
  },
  textTitle: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '2em',
  },
  textName: {
    color: '#000000',
    fontWeight: 'bold',
  },
  textNameOrganization: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '4em',
  },
  text: {
    color: '#000000',
  },
  textParagraph: {
    textAlign: 'justify',
    color: '#000000',
    margin: '1em 0 1em 0',
  },
  link: {
    color: colors.primary,
    marginLeft: '2em',
    textDecoration: 'underline',
  },
  linkAdditionals: {
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  previewTrail: {
    color: 'white',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkOrganization: {
    color: colors.primary,
    marginLeft: '4em',
    textDecoration: 'underline',
  },
  button: {
    margin: '0px 5px',
    display: 'block',
    textAlign: 'center',
    backgroundColor: colors.primary,
    marginBottom: '1em',
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      marginTop: '1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  buttonVerificate: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '1em',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    color: '#ffffff',
    padding: '.4em 1em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.secondary,
      textDecoration: 'none',
      color: '#ffffff',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
      backgroundColor: colors.primary,
      borderRadius: '10px',
      color: '#ffffff',
      padding: '.4em 1em',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
  },
  container_redes: {
    marginBottom: '1em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  container_redes_signatories: {
    display: 'flex',
    justifyContent: 'right',
  },
  containerButtonToCopy: {
    borderColor: colors.primary,
    marginTop: '1em',
    marginBottom: '1em',
    padding: '.4em 3em',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      borderColor: colors.primary,
      margin: '1em',
      padding: '.4em 1em',
    },
  },
  WhatsAppIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00d954',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#009B3C',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#00d954',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#009B3C',
      },
    },
  },
  LinkedInIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#005374',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#018ec5',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#005374',
      },
    },
  },
  LinkedInIconMenu: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#018ec5',
    '&:hover': {
      backgroundColor: '#018ec5',
    },
  },
  textLinkedMenu: {
    fontSize: '.8em',
    marginLeft: '.5em',
  },
  TwitterIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0080A0',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#00ccff',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0080A0',
      },
    },
  },
  xtwitterIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#2f3136',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#000',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  FacebookIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#0080ff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#0080ff',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0055A9',
      },
    },
  },
  TelegramIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: '#00ccff',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0055A9',
      },
    },
  },
  EmailIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#FF5733',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#CF4325',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: '#ffffff',
      backgroundColor: 'FF5733',
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#CF4325',
      },
    },
  },
  PdfIcon: {
    width: theme.spacing(0),
    height: theme.spacing(0),
    color: '#ffffff',
    backgroundColor: '#00ccff',
    marginLeft: '.7em',
    '&:hover': {
      backgroundColor: '#0055A9',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: '.7em',
      '&:hover': {
        backgroundColor: '#0055A9',
      },
    },
  },
  PdfIconImage: {
    height: '35px',
    marginLeft: '8px',
    [theme.breakpoints.up('sm')]: {
      height: '45px',
      marginLeft: 'auto',
    },
  },
  grid: {
    backgroundColor: colors.footer,
    marginBottom: '1em',
  },
  skeleton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    borderRadius: '10px',
    borderColor: '#ffffff',
    backgroundColor: 'white',
    paddingBottom: 2,
    marginTop: '1.5em',
  },
  img_user: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  img_org: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '& img': {
      height: 'min-content',
    },
  },
  cardContent: {
    padding: '0',
  },
  textBolderDate: {
    marginTop: '2.7em',
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '.4em',
  },
  menuItem: {
    borderRadius: 10,
    padding: 10,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  textMenuItem: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  expandeMenuItem: {
    color: '#ffffff',
    float: 'right',
  },
  descriptions: {
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#fafafa',
    marginBottom: 10,
    textAlign: 'center',
  },
  content_descriptions: {
    margin: '1em',
  },
  footer: {
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  linkAdicional: {
    float: 'right',
    marginRight: '5em',
  },
  mySwal: {
    zIndex: 3000,
  },
  container_text_chip_right: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
    },
  },
  container_text_chip_left: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
}));

const defaultPropsUser = {
  bgcolor: colors.secondary,
  borderColor: colors.secondary,
  color: '#ffffff',
  m: 1,
  border: 1,
  padding: 1,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    document: state.document || {},
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  setDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentView);
