import React, { useState } from 'react';
import { makeStyles, Container, Box, IconButton } from '@material-ui/core';
import { FileDownloadOutlined, ZoomInOutlined, ZoomOutOutlined, AspectRatioOutlined } from '@mui/icons-material';
import { Document, pdfjs, Page } from 'react-pdf';
import Pagination from '@material-ui/lab/Pagination';
import Appbar from './Appbar';
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function ViewPDF(props) {
  const { pdf, download, handleClose, fullView } = props;
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [sizePage, setSizePages] = useState(0);
  const [page, setPage] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPage(1);
    setSizePages(window.innerWidth < 960 ? window.innerWidth : 600);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const zoomPage = (zoom) => {
    setSizePages(zoom);
  };

  return (
    <>
      {fullView && <Appbar closeButton={true} handleClose={handleClose} />}
      <Container className={classes.container}>
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
          className={classes.document}
        >
          <Page key={`page_${page}`} pageNumber={page} width={sizePage} />
        </Document>

        <div className={classes.pagination}>
          <Box component="span" display="flex" justifyContent="center">
            {numPages > 1 && (
              <Pagination
                orientation="vertical"
                color="secondary"
                count={numPages}
                page={page}
                onChange={handleChange}
                defaultPage={1}
                size="small"
              />
            )}
            {sizePage !== 0 && (
              <>
                <IconButton size="small">
                  <ZoomInOutlined
                    onClick={() =>
                      zoomPage(sizePage + 100 < window.innerWidth - 50 ? sizePage + 100 : window.innerWidth - 50)
                    }
                  />
                </IconButton>
                <IconButton size="small">
                  <ZoomOutOutlined onClick={() => zoomPage(sizePage - 100 > 600 ? sizePage - 100 : 600)} />
                </IconButton>
                <IconButton size="small">
                  <AspectRatioOutlined onClick={() => zoomPage(600)} />
                </IconButton>
                {download && (
                  <a href={pdf} download={pdf}>
                    <IconButton size="small">
                      <FileDownloadOutlined />
                    </IconButton>
                  </a>
                )}
              </>
            )}
          </Box>
        </div>
      </Container>

      {/* MobileView */}
      <Container className={classes.mobile}>
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
          className={numPages > 1 ? classes.document : classes.documentNoPagination}
        >
          <Page key={`page_${page}`} pageNumber={page} width={sizePage} />
        </Document>

        <div className={classes.pagination}>
          <Box component="span" display="flex" justifyContent="center">
            {numPages > 1 && (
              <Pagination
                orientation="vertical"
                color="secondary"
                count={numPages}
                page={page}
                onChange={handleChange}
                defaultPage={1}
                size="small"
              />
            )}
          </Box>
        </div>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  pagination: {
    marginTop: '3px',
    position: 'absolute',
  },
  mobile: {
    display: 'none',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  img: {
    maxWidth: '80vh',
  },
  document: {
    '& div .react-pdf__Page__annotations': {
      display: 'none',
    },
    '& canvas': {
      marginTop: '2em',
    },
  },
  documentNoPagination: {
    '& div .react-pdf__Page__annotations': {
      display: 'none',
    },
  },
}));

export default ViewPDF;
