import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import colors from '../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'aliceblue',
      backgroundImage: `url(${JSON.parse(window.localStorage.state)?.styles?.bg_Error})`,
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    marginTop: '50%',
    color: colors.primary,
    textAlign: 'center',
    '& img': {
      maxWidth: 300,
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '0',
      marginBottom: '40%',
      '& img': {
        maxWidth: 500,
      },
    },
  },
  button: {
    margin: '1em 5em 1em 5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 10,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  person: {
    display: 'none',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
    },
  },
}));

function Page404(props) {
  const classes = useStyles();
  const [t] = useTranslation(['buttons', 'error404']);
  const [page404, setPage404] = useState({});

  useEffect(
    () => {
      const styles = JSON.parse(window.localStorage.state)?.styles;
      setPage404({ logo: styles.logo435x149, person: styles.error404_pj });
    }, // eslint-disable-next-line
    []
  );

  const toLink = (e, id) => {
    props.history.push(`/`);
  };

  return (
    <>
      <Grid container direction="row" justify="space-between" alignItems="flex-end" className={classes.root}>
        <Grid item xs={0} lg={5}>
          <img className={classes.person} src={page404?.person} alt="404 NOT FOUND" />
        </Grid>
        <Grid item xs={12} lg={7}>
          <div className={classes.text}>
            <p>{t('error404:error404.textMesage')}</p>
            <img src={page404?.logo} alt="404 NOT FOUND"></img>
            <Button variant="outlined" component="span" className={classes.button} onClick={(e) => toLink()}>
              {t('buttons:buttons.go-login')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Page404;
