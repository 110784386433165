import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

function BadgeCard(props) {
  const classes = useStyles();
  const {
    badgeImage,
    badgeName,
    badgePrivateName,
    organizationName,
    issuedId,
    publicBadge,
    templateBadge = false,
    templateCertificate = false,
  } = props;
  const history = useHistory();

  const handleClick = () => {
    if (publicBadge) {
      if (templateBadge) {
        history.push(`/template/${issuedId}`);
        window.location.reload();
      } else {
        history.push(`/badges/${issuedId}`);
      }
    } else {
      if (templateBadge) {
        history.push(`/templates/published/details/${issuedId}`);
        window.location.reload();
      } else if (templateCertificate) {
        history.push(`/certificates/details/${issuedId}`);
        window.location.reload();
      } else {
        history.push(`/badge/${issuedId}`);
      }
    }
  };

  return (
    <div className={classes.badge} onClick={handleClick}>
      <img src={badgeImage} alt="badge" className={classes.badge__image} />
      <p className={classes.badge__organization_name_font_small}>{organizationName}</p>
      <h3 className={badgeName?.length > 30 ? classes.badge__name_font_small : classes.badge__name}>{badgeName}</h3>
      <h6 className={classes.badge__name_private}>{badgePrivateName}</h6>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  badge: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: '10px',
    height: 280,
    width: 230,
    cursor: 'pointer',
  },
  badge__image: {
    width: 120,
    height: 120,
    borderRadius: '100%',
    margin: '25px 0px',
  },
  badge__name: {
    fontSize: '1.3em',
    marginBottom: 0,
    color: '#676767',
    textAlign: 'center',
  },
  badge__name_font_small: {
    fontSize: '0.8em',
    marginBottom: 0,
    color: '#676767',
    textAlign: 'center',
  },
  badge__name_private: {
    marginBottom: 0,
    color: '#676767',
    textAlign: 'center',
  },
  badge__organization_name_font_small: {
    fontSize: '0.8em',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '1em',
  },
}));

export default BadgeCard;
