import React from 'react';
import { env } from '../config/environment';

const FormPayu = ({ formData = null, action, location }) => {
  if (!formData) return null;
  let URL_PAYU = 'https://checkout.payulatam.com/ppp-web-gateway-payu/';

  return (
    <form method="post" action={URL_PAYU} ref={action}>
      <input name="merchantId" type="hidden" value={env.merchantId} />
      <input name="referenceCode" type="hidden" value={formData?.referenceCode} />
      <input name="description" type="hidden" value={`Compra de ${formData?.cantidad} emisiones`} />
      <input name="amount" type="hidden" value={formData?.amount} />
      <input name="tax" type="hidden" value="0" />
      <input name="taxReturnBase" type="hidden" value="0" />
      <input name="signature" type="hidden" value={formData?.signature} />
      <input name="accountId" type="hidden" value={env.accountId} />
      <input name="currency" type="hidden" value={formData?.currency} />
      <input name="buyerFullName" type="hidden" value={formData?.buyerFullName} />
      <input name="buyerEmail" type="hidden" value={formData?.buyerEmail} />
      <input name="confirmationUrl" type="hidden" value={`${env.apiURL}/payment/confirmationPage`} />
      {env.TEST_PAYU && <input name="test" type="hidden" value="1"></input>}
      <input name="responseUrl" type="hidden" value={`${location}`} />
    </form>
  );
};

export default FormPayu;
