function capitalize(string) {
  const words = string.replace(/\s+/g, ' ').trim().split(' ');

  return words
    .map((word) => {
      return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
    })
    .join(' ')
    .trim();
}

export default capitalize;
