import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, Button } from '@material-ui/core';
import ModuleHeader from '../../components/ModuleHeader';
import TabPanel from '../../components/TabPanel';
import colors from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: "50px",
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
}));

function Template(props) {
  const { children, Index, permission } = props;
  const classes = useStyles();
  const history = useHistory();

  /* translation */
  const [t] = useTranslation(['tabs', 'buttons']);

  const tabs = t('tabs:tabs.tabs-badge', { returnObjects: true });

  const create_permission = () => {
    if (permission.includes('Crear')) {
      return (
        <ModuleHeader>
          <Button variant="contained" className={classes.button} onClick={() => history.push('/templates/create')} disableElevation>
            <span>{t('buttons:buttons.create')}</span>
          </Button>
        </ModuleHeader>
      );
    } else {
      return <ModuleHeader />;
    }
  };

  const read_permission = () => {
    if (permission.includes('Leer')) {
      return (
        <>
          <TabPanel Index={Index} TabsHeader={tabs} />
          {children}
        </>
      );
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
  };

  return (
    <>
      {create_permission()}
      {read_permission()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'plantillas')
      .map((item) => item.acciones.nombre),
  };
};

export default connect(mapStateToProps, null)(Template);
