import React from 'react';
import Konva from 'konva';
import { Stage, Layer, Image, Text } from 'react-konva';
import { DropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, makeStyles, withStyles, LinearProgress, Typography } from '@material-ui/core';
import useImage from 'use-image';
import colors from '../assets/styles/colors';

const SignatureFile = (props) => {
  const { closeModalSign, saveSign, progressBar, emailUser } = props;
  const [signature, setSignature] = React.useState(null);
  const stageRef = React.useRef(null);
  const classes = useStyle();
  const [t] = useTranslation(['buttons', 'certificateBadgeTemplate']);

  const SignatureImage = () => {
    const [image] = useImage(signature?.image, 'Anonymous');
    const imageRef = React.useRef();
    React.useEffect(() => {
      if (image) {
        imageRef.current.cache();
      }
    }, [image]);

    return (
      <Image
        ref={imageRef}
        className={classes.img}
        image={image}
        width={520}
        height={100}
        y={50}
        filters={[Konva.Filters.Threshold]}
        threshold={0.6}
      />
    );
  };

  const handleFile = (e) => {
    const file = e[0];
    if (file) {
      setSignature({ image: URL.createObjectURL(file) });
    }
  };

  const goToSave = (stageRef) => {
    saveSign(stageRef);
  };

  const handleChangeFile = () => {
    setSignature(null);
  };

  return (
    <Grid container justifyContent="center">
      {signature?.image && !progressBar?.value && (
        <Grid item xs={12} style={{ marginTop: '1em' }}>
          <Button variant="outlined" color="primary" component="span" onClick={handleChangeFile}>
            {t('buttons:buttons.change-image')}
          </Button>
        </Grid>
      )}
      {!signature && (
        <Grid item xs={12} style={{ textAlign: 'center', margin: '1em' }}>
          <DropzoneArea
            filesLimit={1}
            acceptedFiles={['.png', '.jpg', '.jpeg']}
            dropzoneText={t('certificateBadgeTemplate:badges-certificate-form.signature-dropzoneArea')}
            dropzoneClass={classes.dropzone}
            dropzoneParagraphClass={classes.dropzoneParagraph}
            showFileNames={false}
            showAlerts={true}
            onChange={handleFile}
          />
        </Grid>
      )}
      {signature?.image && !progressBar?.value && (
        <div style={{ backgroundColor: '#f0f0f0', margin: '1em', borderRadius: '1em' }}>
          <Stage width={520} height={150} ref={stageRef} style={{ mixBlendMode: 'multiply' }}>
            <Layer>
              <Text
                text={t('certifyDocuments:document.stamp')}
                fontSize={14}
                fontStyle="italic"
                width={510}
                x={1}
                y={2}
                align="right"
              />
              <Text
                text={t('certifyDocuments:add-signatories.signatory')}
                x={1}
                y={10}
                width={520}
                fontSize={20}
                align="center"
              />
              <Text text={emailUser} x={1} y={30} width={520} fontSize={20} align="center" />
              <SignatureImage />
            </Layer>
          </Stage>
        </div>
      )}
      {progressBar.value && (
        <div style={{ margin: '1em', width: 520 }}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progressBar.value} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressBar.value)}%`}</Typography>
            </Box>
          </Box>
        </div>
      )}
      <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} className={classes.container__button_fields}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => goToSave(stageRef)}
            disabled={signature?.image ? false : true}
          >
            {t('buttons:buttons.save')}
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => closeModalSign()}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyle = makeStyles(() => ({
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  button: {
    margin: '0.5em',
    padding: '9px 40px',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.primary,
    borderRadius: 15,
    '& .MuiGrid-container': {
      justifyContent: 'center',
    },
  },
  dropzoneParagraph: {
    color: 'gray',
    fontSize: '1.1em',
  },
}));

export default SignatureFile;
