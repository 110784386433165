import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import Table from '../../components/Table';
import ModuleHeader from '../../components/ModuleHeader';
import Search from '../../components/SearchBar';
import axios from '../../api';
import { changeBreadcrump } from '../../actions';

function Actions(props) {
  const { page, rowsPerPage, permission, changeBreadcrump } = props;
  const history = useHistory();
  const [actions, setActions] = useState([]);
  const [filtro, setFiltradas] = useState([]);

  useEffect(() => {
    if (permission.includes('Leer')) {
      getActions();
      changeBreadcrump([{ name: 'Ajustes' }, { name: 'Acciones' }]);
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const getActions = async () => {
    const { data } = await axios.post(`/action/getActions`);
    setActions(data.actions);
    setFiltradas(data.actions);
  };

  return (
    <>
      <ModuleHeader />
      <Search items={actions} setItems={setFiltradas} />
      <Table columns={columns} rows={filtro}>
        <TableBody>
          {filtro.length > 0 ? (
            <>
              {filtro.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">{row.nombre}</TableCell>
                  <TableCell align="center">{row.modulos?.nombre}</TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableCell align="center" colSpan="3">
              No hay datos registrados
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

const columns = [
  { id: 'nombre', label: 'Nombre', align: 'center' },
  { id: 'modulos', label: 'Módulo', align: 'center' },
];

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'acciones')
      .map((item) => item.acciones.nombre),
  };
};

const mapDispatchToProps = {
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
